import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { SortDirection } from '@angular/material/sort';
import { utc } from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivityItem, GetActivityQueryParameters, GetActivitySortBy } from 'api/types';
import { DISPLAY_DAY_FORMAT } from 'constants/date-formats';
import { AppointmentsRecentActivityDataService } from 'services/appointments-recent-activity-data.service';
import { DEFAULT_VALUES } from 'services/appointments-recent-activity-filters.service';
import { PaginatorBase } from '../utils/paginator-base.class';

/**
 * Table of recent activity for appointments
 */
@Component({
  selector: 'app-appointments-recent-activity-table',
  templateUrl: './appointments-recent-activity-table.component.html',
  styleUrls: [ './appointments-recent-activity-table.component.scss' ]
})
export class AppointmentsRecentActivityTableComponent extends PaginatorBase implements OnInit {
  /**
   * Toggles table loading state
   */
  public tableLoading = false;

  /**
   * Array of activity items that populate the table
   */
  public activityItems: ActivityItem[] = [];

  /**
   * List of columns (all are sortable)
   */
  public tableColumns: GetActivitySortBy[] = [ 'activity', 'details', 'user', 'timestamp' ];

  /**
   * Currently sorted column
   */
  public sortBy: GetActivitySortBy = DEFAULT_VALUES.sortBy;

  /**
   * The direction of the sorted column
   */
  public sortDirection: SortDirection = DEFAULT_VALUES.direction;

  /**
   * Completes when component is destroyed.
   */
  private destroyed$ = new Subject();

  public constructor(
    private appointmentsRecentActivityDataService: AppointmentsRecentActivityDataService
  ) {
    super(DEFAULT_VALUES);
  }

  public ngOnInit(): void {
    
    this.appointmentsRecentActivityDataService.fetchRecentActivity();
    // Subscribe to data updates
    this.appointmentsRecentActivityDataService.recentActivityData$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((activityData) => {
        if (activityData) {
          const { items, sortBy, direction, limit, offset, total } = activityData;
          this.activityItems = items.map((item) => {
            return {
              ...item,
              timestamp: utc(item.timestamp).format(DISPLAY_DAY_FORMAT)
            };
          });
          this.sortBy = sortBy;
          this.sortDirection = direction;

          this.updatePaginator({ limit, offset, total });
        }
      });

    // Subscribe to table loading
    this.appointmentsRecentActivityDataService.fetchingActivities$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((fetchingActivities) => {
        this.tableLoading = fetchingActivities;
      });
  }

  /**
   * Updates the params based on paginator change
   */
  public paginatorChange(event: PageEvent): void {
    const newParams: Partial<GetActivityQueryParameters> = {
      offset: event.pageSize * event.pageIndex,
      limit: event.pageSize
    };
    this.appointmentsRecentActivityDataService.updateParams(newParams);
  }

  /**
   * Updates the sorted column
   */
  public sort(col: GetActivitySortBy): void {
    const direction = this.sortDirection === 'asc' ? 'desc' : 'asc';
    const newParams: Partial<GetActivityQueryParameters> = {
      sortBy: col,
      direction: direction
    };
    this.appointmentsRecentActivityDataService.updateParams(newParams);
  }
}
