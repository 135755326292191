import { formatDate } from '@angular/common';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RemoveOutageComponent, RemoveOutageInputs, removeOutageConfig } from 'components/common/outage/remove-outage/remove-outage.component';
import { LoadingDialogComponent, LoadingDialogInput, loadingDialogConfig } from 'components/dialogs/loading-dialog/loading-dialog.component';
import { TranslatePipe } from 'pipes/translate.pipe';
import { take } from 'rxjs/operators';
import { OutagesService } from 'services/api/outages.service';
import { DisplayableServerError } from 'types/DisplayableServerError';
import { getDisplayableServerError } from 'utils/get-displayable-server-error';

@Component({
  selector: 'app-outage-menu',
  templateUrl: './outage-menu.component.html',
  styleUrls: ['./outage-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OutageMenuComponent implements OnChanges {
  @Input() outage!: any;

  @Input() timezone!: any;

  currentDate!: string;

  currentTime!: string;

  hideRemoveButton: boolean = false;

  hideEditButton: boolean = false;

  outageId: string = '';

  constructor(private router: Router,
    private dialog: MatDialog,
    private translatePipe: TranslatePipe,
    private outageService: OutagesService) { }

  ngOnInit(): void {
    this.outageId = this.outage.id;
    this.validateDisable();
  }

  ngOnChanges(): void {
    this.currentDate = formatDate(
      new Date().toLocaleString(),
      'yyyy-MM-dd',
      'en-US',
      'UTC'
    );

    this.currentTime = formatDate(
      new Date().toLocaleString(),
      'HH:mm',
      'en-US',
      'UTC'
    );
  }

  validateDisable() {
    this.currentDate = formatDate(
      new Date().toLocaleString(),
      'yyyy-MM-dd',
      'en-US',
      'UTC'
    );

    this.currentTime = formatDate(
      new Date().toLocaleString(),
      'HH:mm',
      'en-US',
      'UTC'
    );

    const splitStartDate = this.outage?.startdate.split('T');
    const StartDateOnly = splitStartDate[0];

    const splitStartTime = splitStartDate[1];
    const shortStartTime = splitStartTime.slice(0, 5);

    var flagRemoveButton = false;
    if (StartDateOnly < this.currentDate) {
      flagRemoveButton = true;
    }
    else if (StartDateOnly == this.currentDate) {
      if (shortStartTime < this.currentTime) {
        flagRemoveButton = true;
      }
    }
    this.hideRemoveButton = flagRemoveButton;

    const splitEndDate = this.outage?.enddate.split('T');
    const EndDateOnly = splitEndDate[0];

    const splitEndTime = splitEndDate[1];
    const shortEndTime = splitEndTime.slice(0, 5);

    var flagEditButton = false;
    if (EndDateOnly < this.currentDate) {
      flagEditButton = true;
    }
    else if (EndDateOnly == this.currentDate) {
      if (shortEndTime < this.currentTime) {
        flagEditButton = true;
      }
    }
    this.hideEditButton = flagEditButton;
  }

  compareDate(date: string): boolean {
    const splitDate = date.split('T');
    const dateOnly = splitDate[0];

    const splitTime = splitDate[1];
    const shortTime = splitTime.slice(0, 5);

    var flag = false;
    if (dateOnly < this.currentDate) {
      flag = true;
    }
    else if (dateOnly == this.currentDate) {
      if (shortTime < this.currentTime) {
        flag = true;
      }
    }

    return flag;
  }

  editOutage() {
    this.router.navigate([`edit-outages/${this.outageId}`]);
  }

  public openRemoveConfirmDialog(): void {
    this.dialog.open<RemoveOutageComponent, RemoveOutageInputs>(RemoveOutageComponent, {
      ...removeOutageConfig,
      data: {
        title: this.translatePipe.transform('outage.remove.dialog.heading'),
        removeOutageCallback: () => {
          // Called when a user clicks "remove" on dialog
          this.removeOutage();
        }
      }
    });
  }

  private removeOutage(): void {
    const loader = this.dialog.open<LoadingDialogComponent, LoadingDialogInput>(LoadingDialogComponent, {
      ...loadingDialogConfig,
      data: {
        title: 'title.deleting',
        subtitle: 'subtitle.pleaseWait',
      },
    });

    this.outageService.deleteOutageById({ id: this.outageId })
      .pipe(take(1))
      .subscribe(() => {
        loader.close();
        this.outageService.outageSuccess$.next({
          outageId: this.outageId,
          type: 'delete'
        });
      }, (error: DisplayableServerError | null) => {
        loader.close();
        var error: DisplayableServerError | null = getDisplayableServerError(error);
        this.outageService.outageDeleteError$.next({
          error: error
        });
      });
  }
}
