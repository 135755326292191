import { Component, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';
import { TranslatePipe } from 'pipes/translate.pipe';
import { VueToastComponent } from 'vue/components/vue-toast/vue-toast.component';

@Component({
  selector: 'app-alert-edit-pc-toast',
  templateUrl: './alert-edit-pc-toast.component.html',
  styleUrls: ['./alert-edit-pc-toast.component.scss']
})
export class AlertEditPcToastComponent  {
  @ViewChild(VueToastComponent) public toast!: VueToastComponent;

  /**
   * Text to display in toast body
   */
  public toastContent = ' ';
  public constructor(private translatePipe: TranslatePipe) {}
  public openToast(poolName:any): void {
    this.translatePipe.loadTranslations([
     
      'toast.message.editalertpool.updated',
      'toast.heading.editalert.updated',
    ]).pipe(take(1))
    .subscribe((translations) => {
      this.toast.heading = translations[ 'toast.message.editalertpool.updated'].replace('{0}', poolName);
      // this.toastContent = translations[ 'toast.heading.editalert.updated' ];
    
        this.toast.open();
    })}
}
