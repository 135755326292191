import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { utc } from 'moment';
import { FULL_DAY_FORMAT } from 'constants/date-formats';
import { Day, daysOfTheWeek } from 'constants/days-of-the-week';
import { AddTemplateState, TemplateAction } from '../add-template-state/add-template-state.service';

/**
 *  Form with hourly allocation fields for each hour of the day
 */
@Component({
  selector: 'app-daily-allocations-form',
  templateUrl: './daily-allocations-form.component.html',
  styleUrls: [ './daily-allocations-form.component.scss' ]
})
export class DailyAllocationsFormComponent implements OnInit, OnChanges {
  /**
   * The day of the week this form displays
   */
  @Input() public dayNumber!: number;

  /**
   * The main formGroup for entire drawer flow
   */
  public formGroup: UntypedFormGroup;

  /**
   * Get the first half of hourly controls for this day
   * E.g. 00:00 through 11:00
   *
   * @returns formControls
   */
  public get firstHalfHourlyControls(): UntypedFormControl[] {
    const formArray: UntypedFormArray = this.state.hourlyAllocations(this.day);
    return formArray.controls.slice(0, 12) as UntypedFormControl[];
  }

  public get setEqualAllocation(): UntypedFormControl {
    const setEqualAllocation: UntypedFormControl = this.state.hourlySetEqualAllocation(this.day);
    return setEqualAllocation;
  }

  
  public get initialHourlyAllocations(): [] {
    const initialHourlyAllocations: [] = this.state.initialHourlyAllocations(this.day);
    return initialHourlyAllocations;
  }

  /**
   * Getter for day of the week
   * Do not use for UI display, use dayName instead
   *
   * @returns day of the week name
   */
  public get day(): Day {
    return daysOfTheWeek[ this.dayNumber ];
  }

  /**
   * Get the second half of hourly controls for this day
   * E.g. 12:00 through 23:00
   *
   * @returns formControls
   */
  public get secondHalfHourlyControls(): UntypedFormControl[] {
    const formArray: UntypedFormArray = this.state.hourlyAllocations(this.day);
    return formArray.controls.slice(12, 24) as UntypedFormControl[];
  }

  /**
   * The day name, e.g. "sunday"
   * Translated via moment locale
   */
  public dayName = ''

  typeOfTemplateAction: TemplateAction = { type: 'new' };

  public constructor(private state: AddTemplateState) {
    this.formGroup = this.state.formGroup;
    this.typeOfTemplateAction = this.state.typeOfTemplateAction;

    if(this.typeOfTemplateAction.type == "new")
    {
      this.state.hourlyAllocations("sunday").enable();
      this.state.hourlyAllocations("monday").enable();
      this.state.hourlyAllocations("tuesday").enable();
      this.state.hourlyAllocations("wednesday").enable();
      this.state.hourlyAllocations("thursday").enable();
      this.state.hourlyAllocations("friday").enable();
      this.state.hourlyAllocations("saturday").enable();
    }
  }

  /**
   * Set proper dayName based on given day number
   */
  public ngOnInit(): void {
    this.setDayName(this.dayNumber);
  }

  /**
   * If dayNumber changes, update the dayName value
   *
   * @param changes SimpleChanges object
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if ('dayNumber' in changes) {
      this.setDayName(changes.dayNumber.currentValue);
    }
  }

  /**
   * @returns number of the total percentage used for current day
   */
  public totalUsed(): number {
    return this.state.dailyTotalUsed(this.day);
  }

  /**
   * @param hourNumber the hour number
   * @returns label as a formatted label
   */
  public hourFieldLabel(hourNumber: number): string {
    return utc().hour(hourNumber).format('HH:00');
  }

  /**
   * Show next step, unless day is saturday then submit
   */
  public onSubmit(): void {
    if (this.day === 'saturday') {
      this.state.submitTemplate();
    } else {
      this.state.showNextStep();
    }
  }

  /**
   * Set local dayName based on given number
   *
   * @param num number of day in the week. Ex: sunday = 0
   */
  private setDayName(num: number): void {
    this.dayName = utc().day(num).format(FULL_DAY_FORMAT);
  }

  public fnSetEqualAllocation(): void {
    this.setEqualAllocation.setValue(!this.setEqualAllocation.value);
    if (this.setEqualAllocation.value) {
      this.firstHalfHourlyControls.forEach(control=>{
        control.disable();
        var value = 100/24;
        control.setValue(value);
      });

      this.secondHalfHourlyControls.forEach(control=>{
        control.disable();
        var value = 100/24;
        control.setValue(value);
      });
    }
    else if (this.typeOfTemplateAction.type == "new" || this.initialHourlyAllocations.length == 0) {
      this.firstHalfHourlyControls.forEach(control=>{
        control.enable();
        control.setValue('');
      });

      this.secondHalfHourlyControls.forEach(control=>{
        control.enable();
        control.setValue('');
      });
    }
    else {
      var i = 0;
      this.firstHalfHourlyControls.forEach(control=>{
        control.enable();
        control.setValue(this.initialHourlyAllocations[i]);
        i++;
      });

      this.secondHalfHourlyControls.forEach(control=>{
        control.enable();
        control.setValue(this.initialHourlyAllocations[i]);
        i++;
      });
    }
  }
}
