<div class="wrapper">
  <div class="top-crumbs">
    <p class="breadcrumb">
      {{ appName }}
    </p>
    <p
      *ngIf="navSection"
      class="breadcrumb divider"
    >
      {{ navSection }}
    </p>
  </div>
  <div class="page-title-wrapper">
    <button
      *ngIf="enableBack && pageTitle"
      class="back-button"
      attr.aria-label="{{ 'button.goBack' | vueTranslate | async }}"
      (click)="navigateBack()"
    >
      <img src="/assets/icons/icon-back-arrow.svg" alt="">
    </button>
    <h1 #heading tabindex="-1">
      {{ pageTitle }}
    </h1>
  </div>
</div>
