import { Injectable } from '@angular/core';
import { GetOutageQueryParameters } from 'api/types';
import { BehaviorSubject } from 'rxjs';

export const DEFAULT_VALUES: GetOutageQueryParameters = {
  limit: 25,
  offset: 0,
  sortBy: 'startdate',
  direction: 'asc',
};

@Injectable({
  providedIn: 'root'
})
export class OutageFiltersService {

  // Init with empty values; the filter component will populate them later
  private readonly outageparams = new BehaviorSubject<GetOutageQueryParameters>(DEFAULT_VALUES)

  // eslint-disable-next-line @typescript-eslint/member-ordering, no-invalid-this
  public params$ = this.outageparams.asObservable()

  public constructor() {
    // Prevent handler from being called in the component's context
    this.updateParams = this.updateParams.bind(this);
  }

  public updateParams(changes: Partial<GetOutageQueryParameters>): void {
    if('numberOfDays' in changes){
      delete changes.numberOfDays
    }
    this.outageparams.next({ ...this.outageparams.getValue(), offset: 0, ...changes });
  }
}
