<div *ngIf="!getDatePickerValues" class="dateformat"><b>{{'label.alertrange'  | translate | async}}:</b> {{label()}} - {{endDatelabel()}}</div>
<div [ngClass]="getDatePickerValues == true ? {
    'date-selection-filter': true,
    'group-position-first': groupPosition === 'first',
    'group-position-last': groupPosition === 'last',
    'customStyleFromCapacityUpload': customStyle
  } : {
    'date-selection-resolve-filter':true,
    'group-position-first': groupPosition === 'first',
    'group-position-last': groupPosition === 'last',
    'customStyleFromCapacityUpload': customStyle
  }">
  <span *ngIf="!getDatePickerValues" class="dateformat" ><b>{{'label.currentselection'  | translate | async}}:</b></span>
  <button mat-button type="button" [attr.aria-label]="backButtonTranslationKey | translate | async"
    (click)="backwardClicked()">
    <vue-arrow *ngIf="getDatePickerValues" direction="backward"></vue-arrow>
     <span *ngIf="!getDatePickerValues" direction="backward"> {{"<<"}} </span>
  </button>
  <app-date-selection-label [timescale]="timescale" [startDate]="startDate" (dateChange)="manualDateChange($event)"
    [customStyle]="customStyle" [alertSpecificData]="alertSpecificData" [getDatePickerValues]="getDatePickerValues"></app-date-selection-label>
  <button mat-button type="button" [attr.aria-label]="forwardButtonTranslationKey | translate | async"
    (click)="forwardClicked()">
    <vue-arrow *ngIf="getDatePickerValues" direction="forward"></vue-arrow>
    <span *ngIf="!getDatePickerValues" direction="forward">{{'>>'}}</span>
  </button>
</div>