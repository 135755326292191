import { Component, HostBinding, Input, OnChanges, OnInit } from '@angular/core';
import { CHART_HEIGHT_REMS, FIRST_COLUMN_WIDTH_REMS } from '../utils/constants';
import { buildYAxisTicks } from './utils/y-axis-ticks';

/**
 *  Custom Y axis to be displayed to the left of the chart area.  Allows more control than stock ChartJS Y axis.
 */
@Component({
  selector: 'app-custom-y-axis',
  templateUrl: './custom-y-axis.component.html',
  styleUrls: [ './custom-y-axis.component.scss' ]
})
export class CustomYAxisComponent implements OnInit, OnChanges {
  /**
   * Primary label
   */
  @Input() public label = ''

  /**
   * Secondary label, optional
   */
  @Input() public secondaryLabel = ''

  /**
   * Overall height of chart area to match
   */
  @Input() public chartHeightRems: number = CHART_HEIGHT_REMS;

  /**
   * Space between gridlines, in chart units (not px)
   */
  @Input() public stepSize?: number;

  /**
   * Max Y-Axis value, in chart units (not px)
   */
  @Input() public max?: number;

  @Input() public isDaily: boolean = false;

  /**
   * Width to occupy, in rems, applied directly to host element
   */
  @Input()
  @HostBinding('style.width.rem')
  public widthRems? = FIRST_COLUMN_WIDTH_REMS;

  /**
   * Labels and values for each line
   */
  public tickLabels: string[] = [];

  /**
   * Height of an individual tick
   */
  public tickHeightRems = 1;

  public ngOnInit(): void {
    this.buildTicks();
  }

  public ngOnChanges(): void {
    this.buildTicks();
  }

  private buildTicks(): void {
    if (!this.max || !this.stepSize) {
      return;
    }

    const { labels, tickHeightRems } = buildYAxisTicks(this.max, this.stepSize, this.chartHeightRems);

    this.tickLabels = labels;
    this.tickHeightRems = tickHeightRems;
  }
}
