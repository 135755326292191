<div>
  <span class="occurs">{{ "label.editPool.occursOn" | translate | async }}</span>
  <div class="day-holder">
    <span
      *ngFor="let day of daysOfWeek"
    >
      <input
        type="checkbox"
        [value]="day.value"
        [id]="day.value"
        [checked]="day.checked"
        (change)="checkboxChange($event)"
      >
      <label matRipple [for]="day.value" [attr.aria-label]="day.display">
        {{ day.display.slice(0, 1).toLocaleUpperCase() }}
      </label>
    </span>
  </div>
</div>
