import { Injectable } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isSafari } from 'utils/is-safari';
import { remToPx } from 'utils/rem-utils';
import { Direction } from '../types/Direction';
import { GetComputedStyleService } from './get-computed-style.service';
import { WindowResizeService } from './window-resize.service';

const DEFAULT_SCROLL_INCREMENT = 8;

/**
 * Service that facilitates scrolls several elements simultaneously.
 *
 * How it works:
 *  - ScrollContainers registerScrollContainer with the service and set some parameters
 *  - SynchedScrollButtons can be placed anywhere and tell the service when to scroll
 *  - The service handles performing the scrolls on the containers
 *
 * Assumptions:
 *  - Only one instance of synched scrolling should be happening at once.
 *  - Callers should call `deregisterAll()` to clear state before they are destroyed.
 *  - All containers should have the same width.
 *
 */
@Injectable({
  providedIn: 'root'
})
export class SynchedScrollService {
  /**
   * Offset scroll position for all of the registered containers.
   *
   * Note: Always a positive number, new RTL scroll positions will be flipped in `scrollAllContainers()`
   */
  private scrollPositionPx = 0;

  /**
   * Array of containers that should be in sync.
   */
  private scrollContainers: HTMLElement[] = [];

  /**
   * Size in rems of a single item within a container.
   */
  private itemSizeRems = 0;

  /**
   * Number of items within a single container
   */
  private itemCount = 0;

  /**
   * Number of items to scroll at once.
   */
  private scrollIncrement = DEFAULT_SCROLL_INCREMENT;

  // Internal backing values that are mutable; public-facing observables are not
  private forwardScrollEnabledValue = new BehaviorSubject(false);
  private backwardScrollEnabledValue = new BehaviorSubject(false);

  /**
   * Emits when the forward button enabled status changes.
   */
  // eslint-disable-next-line no-invalid-this,@typescript-eslint/member-ordering
  public forwardScrollEnabled$ = this.forwardScrollEnabledValue.asObservable()

  /**
   * Emits when the backward button enabled status changes.
   */
  // eslint-disable-next-line no-invalid-this,@typescript-eslint/member-ordering
  public backwardScrollEnabled$ = this.backwardScrollEnabledValue.asObservable()

  /**
   * Emits when the containers are scrolled.
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public scrolled = new Subject();

  /**
   * Terminate subscriptions on destroy.
   */
  private destroyed: Subject<boolean> = new Subject<boolean>();

  /**
   * Array of callbacks that will remove scroll listeners
   */
  private eventListenerRemovers: ReturnType<EventManager['addEventListener']>[] = []

  private chartScroll$ = new BehaviorSubject<any>({});
  scrollfromChart$ = this.chartScroll$.asObservable();

  private apptTableScroll$ = new BehaviorSubject<any>({});
  scrollfromApptTable$ = this.apptTableScroll$.asObservable();

  public constructor(
    private getComputedStyleService: GetComputedStyleService,
    private eventManger: EventManager,
    windowResizeService: WindowResizeService,
  ) {
    windowResizeService.resized$
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
        this.checkButtonEnablement();
      });
  }

  setChartScroll(value: number) {
    this.chartScroll$.next(value);
  }

  setApptTableScroll(value: number) {
    this.apptTableScroll$.next(value);
  }

  /**
   * Scrolls the registered containers one increment in the requested direction.
   */
  public scroll(direction: Direction): void {
    if (this.itemSizeRems === 0 || this.itemCount === 0) {
      // eslint-disable-next-line no-console
      console.warn('Item configuration missing. Did you call .configure()?');
    }
    if (this.scrollContainers.length === 0) {
      // eslint-disable-next-line no-console
      console.warn('No scrollContainers found. Did you call .registerScrollContainers()?');
    }

    if (direction === 'backward') {
      this.scrollBackward();
    } else {
      this.scrollForward();
    }

    // Let listeners know a scroll occurred
    this.scrolled.next();
  }

  /**
   * Terminates all subscriptions & removes listeners
   */
  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
    this.unregisterAll();
  }

  /**
   * Adds a container to be managed.
   */
  public registerScrollContainer(scrollContainer: HTMLElement | undefined): void {
    if (scrollContainer) {
      this.addScrollListener(scrollContainer);
      this.scrollContainers.push(scrollContainer);
    }
  }

  /**
   * Configures the scrolling positions
   *
   * @param itemSizeRems width of a single item in rems
   * @param itemCount total number of items within the scroll container
   * @param scrollIncrement the number of items which should be scrolled for one scroll event
   */
  public configure(itemSizeRems: number, itemCount: number, scrollIncrement?: number): void {
    // last in wins when configured multiple times
    this.itemSizeRems = itemSizeRems;
    this.itemCount = itemCount;
    this.scrollIncrement = scrollIncrement || DEFAULT_SCROLL_INCREMENT;

    // If previous scroll position is now out-of-bounds, reset scroll position
    const lastScrollPositionPx = this.lastUsefulScrollPositionPx();
    this.scrollPositionPx = Math.min(this.scrollPositionPx, lastScrollPositionPx);

    this.checkButtonEnablement(lastScrollPositionPx);
  }

  /**
   * Sets width of an individual item in rems.
   */
  public updateItemWidth(itemSizeRems: number): void {
    this.itemSizeRems = itemSizeRems;
  }

  /**
   * Removes all containers, listeners and resets the scroll position.
   */
  public unregisterAll(): void {
    this.scrollContainers = [];
    this.scrollPositionPx = 0;
    this.clearListeners();
  }

  /**
   * Returns the increment width in pixels for each scroll.
   */
  private incrementWidthPx(itemSizeRems: number): number {
    return this.scrollIncrement * remToPx(itemSizeRems);
  }

  /**
   * Scrolls all containers to the new scroll position.
   */
  private scrollAllContainers(scrollBehavior: 'auto' | 'smooth' = 'smooth'): void {
    //  Remove scroll listeners so `scrollTo` doesn't trigger them
    this.clearListeners();

    /*
     * Safari doesn't support behavior: smooth
     * https://caniuse.com/?search=scrollTo
     */
    const behavior = isSafari() ? 'auto' : scrollBehavior;

    this.scrollContainers.forEach((scrollContainer) => {
      scrollContainer.scrollTo({
        left: document.dir === 'rtl' ? -1 * this.scrollPositionPx : this.scrollPositionPx,
        behavior
      });
    });

    this.checkButtonEnablement();

    // Add listeners back
    this.addAllScrollListeners();
  }

  /**
   * Scrolls all containers backward one increment.
   */
  private scrollBackward(): void {
    const next = this.scrollPositionPx - this.incrementWidthPx(this.itemSizeRems);

    this.scrollPositionPx = next < 0 ? 0 : next;
    this.scrollAllContainers();
  }

  /**
   * Scrolls all containers forward one increment.
   */
  private scrollForward(): void {
    const itemWidthPx = this.incrementWidthPx(this.itemSizeRems);
    const next = this.scrollPositionPx + itemWidthPx;
    const lastUsefulScrollPositionPx = this.lastUsefulScrollPositionPx();

    /* istanbul ignore next */
    this.scrollPositionPx = next > lastUsefulScrollPositionPx ? lastUsefulScrollPositionPx : next;
    this.scrollAllContainers();
  }

  /**
   * Grabs the first scroll container and return its effective width.
   * !!! All scroll containers are assumed to be the same width. !!!
   */
  private containerWidth(): number {
    /* istanbul ignore next */
    if (!this.getComputedStyleService) {
      return 0;
    }

    const styles = this.getComputedStyleService.getComputedStyle(this.scrollContainers[ 0 ]);
    if (styles?.width) {
      return parseFloat(styles.width);
    }
    /* istanbul ignore next */
    return 0;
  }

  /**
   * @returns the last scroll position of the container.
   */
  private lastUsefulScrollPositionPx(): number {
    const itemWidthPx = remToPx(this.itemSizeRems);
    const totalWidthPx = this.itemCount * itemWidthPx;

    return totalWidthPx - this.containerWidth();
  }

  /**
   * Determines if either the scrolling back or forward buttons should be enabled.
   *
   * @param scrollPositionPx - optional scroll position of the container.
   * Passing in the position saves a call to `getComputedStyle`, which forces a browser repaint.
   */
  private checkButtonEnablement(scrollPositionPx?: number): void {
    // eslint-disable-next-line no-undefined
    const lastScrollPositionPx = scrollPositionPx !== undefined ? scrollPositionPx : this.lastUsefulScrollPositionPx();
    const forwardEnabled = this.scrollPositionPx < lastScrollPositionPx;
    const backwardEnabled = this.scrollPositionPx > 0;

    // Emit new values if there are changes
    if (this.forwardScrollEnabledValue.getValue() !== forwardEnabled) {
      this.forwardScrollEnabledValue.next(forwardEnabled);
    }

    if (this.backwardScrollEnabledValue.getValue() !== backwardEnabled) {
      this.backwardScrollEnabledValue.next(backwardEnabled);
    }
  }

  /**
   * Adds a scroll listener the element and stores the removing callback.
   * Scrolling could be triggered by other interactions than the `scroll` method
   */
  private addScrollListener(element: HTMLElement): void {
    const removerCallback = this.eventManger.addEventListener(
      element,
      'scroll',
      this.containerScrollListener.bind(this)
    );

    this.eventListenerRemovers.push(removerCallback);
  }

  /**
   * Scroll listener used when an element isn't scrolled via one of the forward/backward buttons.
   * Aligns all other scroll containers the element that was scrolled.
   */
  private containerScrollListener(event: Event): void {
    const scrollPx = Math.abs((event.target as Element).scrollLeft);
    const itemSizePx = remToPx(this.itemSizeRems);

    // When scrollPx is less than the width of an item, scroll to 0 so an item isn't cut off
    const alignToPx = scrollPx <= itemSizePx ? 0 : scrollPx;

    this.scrollPositionPx = alignToPx;
    this.scrollAllContainers('auto');
  }

  /**
   * Remove all scroll listeners
   */
  private clearListeners(): void {
    this.eventListenerRemovers.forEach((remover) => remover());
    this.eventListenerRemovers = [];
  }

  /**
   * Waits for scrolling to be complete, then adds back the scroll listener to each container
   */
  private async addAllScrollListeners(): Promise<void> {
    this.clearListeners();

    await this.waitForScrolling();

    this.scrollContainers.forEach((container) => {
      this.addScrollListener(container);
    });
  }

  /**
   * Returns when a promise that resolves when all containers are scrolled to `scrollPositionPx`.
   *
   * Why?
   * - Listeners are added / removed when manually triggering the scroll. If they're added before
   *   scrolling is complete they'll be triggered themselves, which can cause unintended side effects.
   */
  private async waitForScrolling(): Promise<void> {
    // To avoid infinite loops with the use of `setInterval`, define how many intervals can occur
    let maxNumberOfIntervalCycles = 6;

    return new Promise((res) => {
      const intervalId = window.setInterval(() => {
        const scrollingComplete = this.isScrollComplete();

        // Resolve the promise if scrolling is complete, or the number of cycles has reached zero.
        if (scrollingComplete || maxNumberOfIntervalCycles <= 0) {
          window.clearInterval(intervalId);
          res();
        }

        maxNumberOfIntervalCycles--;
      }, 100);
    });
  }

  /**
   * Returns if all containers are aligned to `scrollPositionPx`.
   */
  private isScrollComplete(): boolean {
    return this.scrollContainers.reduce((aligned: boolean, container: HTMLElement) => {
      return aligned && (Math.abs(container.scrollLeft) === this.scrollPositionPx);
    }, true);
  }
}
