import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { VUE_DIALOG_CONFIG } from 'vue/utilities/vue-dialog-config';

/**
 *  Dialog that prompts user to archive a pool.
 */
@Component({
  selector: 'app-archive-pool',
  templateUrl: './archive-pool.component.html',
})
export class ArchivePoolComponent {
  public constructor(
  // eslint-disable-next-line @typescript-eslint/no-parameter-properties
    @Inject(MAT_DIALOG_DATA) public data: ArchivePoolInputs,
    private dialogRef: MatDialogRef<ArchivePoolComponent>,
  ) {}

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public archivePool(): void {
    this.data.archivePoolCallback();
    this.closeDialog();
  }
}

export const archivePoolConfig = {
  ...VUE_DIALOG_CONFIG,
};

export interface ArchivePoolInputs {

  /**
   * Name of pool to archive
   */
  poolName: string;

  /**
   * Callback used when archive is chosen by the user
   */
  archivePoolCallback: () => void;
}
