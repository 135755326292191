import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Moment } from 'moment';

/**
 * Daily datepicker for `DateSelectionLabelComponent`
 */
@Component({
  selector: 'app-daily-datepicker',
  templateUrl: './daily-datepicker.component.html',
})
export class DailyDatepickerComponent {
/**
 * The start date in string format YYYY-MM-DD, eg '2021-03-28'
 */
  @Input() public startDate?: string | null;

  /**
   * Emits the date selected
   */
  @Output() public dayChange = new EventEmitter<Moment>();

  /**
   * Reference to the material datepicker
   */
  @ViewChild('picker') public picker?: MatDatepicker<Moment>;

  /**
   * Emit start of the week and close the datepicker when a day is selected
   */
  public dateChange(event: MatDatepickerInputEvent<Moment>): void {
    if (event.value) {
      this.dayChange.emit(event.value);
    }

   this.picker?.close();
  }

  /**
   * Opens the datepicker, used by consuming components
   */
  public open(): void {
   this.picker?.open();
  }
}
