<button
  #openButton
  class="open-select-button"
  type="button"
  [attr.aria-label]="getAriaLabel() | async"
  [class.opened]="overlayOpen"
  [class.selected]="selectedOption"
  (click)="open()"
  aria-haspopup="listbox"
>
  <div class="button-content-wrapper">
    <span *ngIf="!selectedOption" class="empty-selection-area"></span>
    <span *ngIf="selectedOption" class="selected-option">{{ selectedOption.name }}</span>
    <img class="select-carrot" src="/assets/icons/icon-material-select.svg" alt=""/>
  </div>
</button>
<label
  [class.float]="selectedOption || overlayOpen"
  [class.blue]="overlayOpen"
>
  {{ 'label.placeholder.templates' | translate | async }} *
</label>
