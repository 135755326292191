import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { VUE_DIALOG_CONFIG } from 'vue/utilities/vue-dialog-config';

/**
 *  Dialog that prompts user to archive a pool.
 */
@Component({
  selector: 'app-exams-list',
  templateUrl: './exams-list.component.html',
  styleUrls: ['./exams-list.component.scss']
})
export class ExamsListComponent {
  public tableLoading = false;
  public exams : string[] = [];
  public constructor(
  // eslint-disable-next-line @typescript-eslint/no-parameter-properties
    @Inject(MAT_DIALOG_DATA) public data: ExamsListInputs,
    private dialogRef: MatDialogRef<ExamsListComponent>,
  ) {
    this.exams = this.data.exams;
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}

export const examsListConfig = {
  ...VUE_DIALOG_CONFIG,
  panelClass: [...(VUE_DIALOG_CONFIG.panelClass || []), 'exams-list'],
  width: '35.5rem',
};

export interface ExamsListInputs {
  clientName: string;
  exams: string[];
}
