<div style="display: flex; justify-content: space-between;">
  <h1 mat-dialog-title style="font-size: x-large;">{{data.title | async}}</h1>
  <button mat-button mat-dialog-close><mat-icon>{{ "button.Close" | translate | async }}</mat-icon></button>
</div>
<div mat-dialog-content style="padding:0px;">
  <table mat-table [dataSource]="data.dataSource">
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef style="width:25%">Date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.date }}
      </td>
    </ng-container>

    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef style="text-align:center; width:55%">Comment</th>
      <td mat-cell *matCellDef="let element" style="text-align: left; width:55%">{{ element.comment }}</td>
    </ng-container>

    <ng-container matColumnDef="addedBy">
      <th mat-header-cell *matHeaderCellDef style=" text-align: center; width:20%">Added By</th>
      <td mat-cell *matCellDef="let element" style="text-align: center; width:20%">{{ element.addedBy }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>