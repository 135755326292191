<button mat-button vueTableMenuOpen vueOverlayOpen class="menu-trigger" [matMenuTriggerFor]="poolConsumptionMenu">
  <mat-icon>more_horiz</mat-icon>
</button>
<mat-menu #poolConsumptionMenu="matMenu" class="pool-consumption-menu" xPosition="before">
  <a mat-menu-item routerLink="../poolconsumption/{{poolconsumption.id}}" routerLinkActive="active">
    View
  </a>
  <a mat-menu-item (click)="showCopyModal()">
    Copy
  </a>
  <a mat-menu-item *ngIf="poolconsumption.status === 'active' && !isPoolConsumptionEnds()" (click)="showConfirmationModal('deactivate')">
    Deactivate
  </a>
  <a mat-menu-item *ngIf="poolconsumption.status === 'inactive'" (click)="updateStatusPoolConsumption('activate')">
    Activate
  </a>
  <a mat-menu-item *ngIf="!isPoolConsumptionEnds()" (click)="showConfirmationModal('delete')">
    Delete
  </a>
</mat-menu>
<app-copy-pool-consumption-drawer [copyAlertFormData]="copyAlertFormData" ></app-copy-pool-consumption-drawer>