/**
 * Focuses on an element that is queried using the supplied selector
 */
export function focusOnElement(selector: string): void {
  const element = document.querySelector<HTMLElement>(selector);

  if (element) {
    if (typeof element.tabIndex !== 'number') element.tabIndex = -1;

    element.focus();
  }
}
