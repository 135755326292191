import { Timezone, validateTimezoneTranslations } from 'types/Timezone';

/** `displayTranslations` key for IANA display translation */
export const IANA_CODE_DISPLAY_KEY = 'iana-code-display';

/** `displayTranslations` key for timezone change message translation */
export const TIMEZONE_CHANGE_KEY = 'timezone-change';

export const timezones: Omit<Timezone, 'offset'>[] = [
  {
    ianaCode: 'America/Chicago',
    displayTranslations: validateTimezoneTranslations({
      'UTC-6-abbr': 'timezone.america/chicago.standard.abbr',
      'UTC-5-abbr': 'timezone.america/chicago.daylight.abbr',
      [ IANA_CODE_DISPLAY_KEY ]: 'timezone.america/chicago.iana.display',
      [ TIMEZONE_CHANGE_KEY ]: 'timezone.america/chicago.change',
    })
  },
  {
    ianaCode: 'America/New_York',
    displayTranslations: validateTimezoneTranslations({
      'UTC-5-abbr': 'timezone.america/new_york.standard.abbr',
      'UTC-4-abbr': 'timezone.america/new_york.daylight.abbr',
      [ IANA_CODE_DISPLAY_KEY ]: 'timezone.america/new_york.iana.display',
      [ TIMEZONE_CHANGE_KEY ]: 'timezone.america/new_york.change',
    })
  },
  {
    ianaCode: 'Australia/Melbourne',
    displayTranslations: validateTimezoneTranslations({
      'UTC+10-abbr': 'timezone.australia/melbourne.standard.abbr',
      'UTC+11-abbr': 'timezone.australia/melbourne.daylight.abbr',
      [ IANA_CODE_DISPLAY_KEY ]: 'timezone.australia/melbourne.iana.display',
      [ TIMEZONE_CHANGE_KEY ]: 'timezone.australia/melbourne.change',
    })
  },
  {
    ianaCode: 'Asia/Calcutta',
    displayTranslations: validateTimezoneTranslations({
      'UTC+5.5-abbr': 'timezone.asia/calcutta.standard.abbr',
      [ IANA_CODE_DISPLAY_KEY ]: 'timezone.asia/calcutta.iana.display',
      [ TIMEZONE_CHANGE_KEY ]: 'timezone.asia/calcutta.change',
    })
  },
  {
    ianaCode: 'Europe/London',
    displayTranslations: validateTimezoneTranslations({
      'UTC+0-abbr': 'timezone.europe/london.daylight.abbr',
      'UTC+1-abbr': 'timezone.europe/london.standard.abbr',
      [ IANA_CODE_DISPLAY_KEY ]: 'timezone.europe/london.iana.display',
      [ TIMEZONE_CHANGE_KEY ]: 'timezone.europe/london.change',
    })
  },
];
