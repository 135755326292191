import { valueof } from './valueof';

/**
 * In a typed object with mixed-type return values, return a value while preserving its type
 *
 * @param obj an object with heterogeneous return values
 * @param key the key to look up
 * @returns typedValue the value with its original type preserved
 */
export default function prop<T, K extends keyof T>(obj: T, key: K): valueof<T> {
  return obj[ key ];
}
