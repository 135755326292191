import { DisplayableServerError } from '../types/DisplayableServerError';

/**
 * Check to see if a server-side error response includes a displayable message.
 *
 * Criteria for display:
 *  - Status is 400
 *  - Includes a message
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function getDisplayableServerError(error: any): DisplayableServerError | null {
  if (
    error?.error !== null &&
    typeof error?.error === 'object' &&
    error.error.statusCode === 400 &&
    typeof error.error.message === 'string'
  ) {
    return { message: error.error.message, title: error.error.title || '' };
  }
  return null;
}
