import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthService } from './auth.service';

export interface RefreshTokenResponseBody {
  encodedToken: string;
  refreshToken: string;
  idToken: {
    exp: number;
  };
}

/**
 * API service for refreshToken endpoint
 */
@Injectable({
  providedIn: 'root'
})
export class RefreshTokenService {
  /**
   * The pathname for the refreshToken endpoint
   */
  public static REFRESH_TOKEN_PATH = '/rest/login/refreshToken';

  public constructor(
    private authService: AuthService,
    private httpClient: HttpClient
  ) {}

  /**
   * Refresh the Authorization token.
   */
  public refresh(): Observable<RefreshTokenResponseBody> {
    const result$ = new Subject<RefreshTokenResponseBody>();
    const url = this.getRefreshUrl();
    const body = { refreshToken: this.authService.getRefreshToken() };
    const options = {
      headers: new HttpHeaders({

        // Since we are skipping the authorization intercept, add it manually
        Authorization: `Bearer ${this.authService.getAuthToken()}`,
        'Content-Type': 'application/json'
      })
    };

    this.httpClient.post<RefreshTokenResponseBody[]>(url, body, options)
      .pipe(take(1))
      .subscribe(
        (data) => {
          const response = data[ 0 ];

          // Emit the response
          result$.next(response);
        }, (error) => {
          // Let consuming subscriptions handle the error
          result$.error(error);
        }
      );

    return result$.asObservable();
  }

  private getRefreshUrl(): string {
    return this.authService.getRestBaseUrl() + RefreshTokenService.REFRESH_TOKEN_PATH;
  }
}
