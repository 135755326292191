<!--
  mat-datepicker requires an input element to be used,
  it is hidden from the UI and assistive technologies.
-->
<input
  tabIndex="-1"
  aria-hidden="true"
  class="hidden-input"
  [matDatepicker]="picker"
  [value]="startDate"
 [min]="minDate"
 [max]="maxDate"
 
 
>
<mat-datepicker
  #picker
  vueOverlayOpen
  startView="year"
  panelClass="monthly-datepicker"
  (monthSelected)="monthSelected($event)"
  [calendarHeaderComponent]="monthlyDatepickerHeader"
></mat-datepicker>
