import { ScaleOptions, } from 'chart.js';
import palette from './chart-palette';
import { TICK_EXTENSION_HEIGHT_PX } from './constants';

/**
 * Starting values for a displayable X scale
 * Many X axes should just be hidden with `display: false` and do not need this base.
 *
 * This base:
 *  - Displays lines for every datapoint
 *  - Extends those lines above the main chart area to abut our custom X axis labels
 *  - Hides actual text labels
 *
 *  @returns ScaleOptions
 */
export function baseBarXScale(): ScaleOptions {
  return {
    axis: 'x',
    display: true, // Allows vertical gridlines to be displayed
    position: 'top', // Extend grid lines above the axis (see also: tickLength)
    ticks: {
      display: false, // Hide actual axis labels
    },
    grid: {
      tickLength: TICK_EXTENSION_HEIGHT_PX, // Amount to extend gridlines above the axis
      color: palette.grayF,
      borderColor: palette.grayF,
    }
  };
}
