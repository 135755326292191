<vue-filter-select
  ariaLabel="{{ 'label.aria.queue' | translate | async }}"
  [filter]="filters.queue"
  [format]="formatQueueOption.bind(this)"
  (change)="filterChanged('queue', $event.value)"
></vue-filter-select>
<vue-filter-select
  ariaLabel="{{ 'label.aria.timescale' | translate | async }}"
  [filter]="filters.timescale"
  [format]="formatTimescaleOption.bind(this)"
  (change)="filterChanged('timescale', $event.value)"
  groupPosition="first"
></vue-filter-select>
<app-date-selection-filter
  [timescale]="filters.timescale.value"
  [startDate]="filters.startDate.value"
  (change)="filterChanged('startDate', $event.value)"
  groupPosition="last"
></app-date-selection-filter>
<app-timezone-select
  *ngIf="filters.timescale.value === 'daily'"
  [date]="dateForTimezone"
></app-timezone-select>
<vue-filter-select
  ariaLabel="{{ 'label.aria.interval' | translate | async }}"
  [filter]="filters.interval"
  [format]="formatIntervalOption.bind(this)"
  (change)="filterChanged('interval', $event.value)"
></vue-filter-select>
