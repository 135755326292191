import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { Exam, Queue } from 'api/types';
// import { AllPoolsFiltersService } from 'services/all-pools-filters.service';

import { AllAlertFiltersService } from 'services/all-alert-filters.service';
import { DateRangeFilterEvent } from 'components/filters/all-pools-filters/date-range-filter/date-range-filter.component';
import { AddAlertPoolDataService } from 'services/add-alert-pool-data.service';
// import { ExamsFilterComponent } from 'components/filters/alert-consumption-table-filter/exams-filter/exams-filter.component';


@Component({
  selector: 'app-more-filter-pane',
  templateUrl: './more-filter-pane.component.html',
  styleUrls: ['./more-filter-pane.component.scss']
})
export class MoreFilterPaneComponent implements OnInit {

 /**
   * Subject that emits when pane should close
   */
 public closePane$ = new Subject<void>();

 /**
  * Which filter is shown in the pane
  */
 public selectedFilter: 'queue' | 'exams' | 'date-range' = 'queue';

 /**
  * Working list of exams
  * Initialized with exams that are already being filtered on
  */
 public pendingSelectedExams: Exam[] = [];

 /**
  * Working list of selected queues
  * Initialized with queues that are already being filtered on
  */
 public pendingSelectedQueues: Queue[] = [];

 /**
  * Working copy of Open After Date
  * Format: YYYY-MM-DD
  */
 public pendingOpenAfterDate = '';

 /**
  * Working copy of Open Before Date
  * Format: YYYY-MM-DD
  */
 public pendingOpenBeforeDate = '';

 public constructor(private allPoolsFilterService: AllAlertFiltersService,private getDialogueClosedData:AddAlertPoolDataService) {}

 /**
  * Set initial filter selections to the already selected filters
  */
 public ngOnInit(): void {
 
   this.allPoolsFilterService.selectedExams$
     .pipe(take(1))
     .subscribe((selectedExams) => {
       // Initialize working array with already selected exams
       this.pendingSelectedExams = [ ...selectedExams ];
     });

   this.allPoolsFilterService.selectedQueues$
     .pipe(take(1))
     .subscribe((selectedQueues) => {
       // Initialize working array with already selected exams
       this.pendingSelectedQueues = [ ...selectedQueues ];
     });

   this.allPoolsFilterService.selectedOpenAfterDate$
     .pipe(take(1))
     .subscribe((openAfterDate) => {
       // Initialize with existing openAfterDate if it exists
       if (openAfterDate) {
         this.pendingOpenAfterDate = openAfterDate;
       }
     });

   this.allPoolsFilterService.selectedOpenBeforeDate$
     .pipe(take(1))
     .subscribe((openBeforeDate) => {
       // Initialize with existing openBeforeDate if it exists
       if (openBeforeDate) {
         this.pendingOpenBeforeDate = openBeforeDate;
       }
     });
 }

 /**
  * Update working list of selected exams from ExamFilterComponent
  *
  * @param exams new list of exams
  */
 public updatePendingExams(exams: Exam[]): void {
   this.pendingSelectedExams = exams;
 }

 /**
  * Update working list of selected queues from QueueFilterComponent
  *
  * @param queues new list of queues
  */
 public updatePendingQueues(queues: Queue[]): void {
   this.pendingSelectedQueues = queues;
 }

 /**
  * Updates pending date properties
  *
  * @param event specifying after or before and respective date
  */
 public updateDateRange(event: DateRangeFilterEvent): void {
   if (event.type === 'after') {
     this.pendingOpenAfterDate = event.date;
   } else {
     this.pendingOpenBeforeDate = event.date;
   }
 }

 /**
  * Calculate number for Date Range number indicator
  *
  * @returns count of dates defined
  */
 public getDateCount(): number {
   // Filter(Boolean) will remove all empty string entries
   return [ this.pendingOpenAfterDate, this.pendingOpenBeforeDate ].filter(Boolean).length;
 }

 /**
  * Reset working array and close pane
  */
 public cancelFilters(): void {
   this.closePane$.next();
 }

 /**
  * Reset all filters to an empty state
  */
 public clearFilters(): void {
   this.pendingSelectedExams = [];
   this.pendingSelectedQueues = [];
   this.pendingOpenAfterDate = '';
   this.pendingOpenBeforeDate = '';
 }

 /**
  * Emit working list of filters and close pane
  */
 public applyFilters(): void {
  this.getDialogueClosedData.setDisableButton(-1)
  this.getDialogueClosedData.setDisabledButtonValue(true)
   this.allPoolsFilterService.updateMoreFilters(
     this.pendingSelectedExams,
     this.pendingSelectedQueues,
     this.pendingOpenAfterDate,
     this.pendingOpenBeforeDate,
   );
   this.closePane$.next();
 }

}
