import { Component } from '@angular/core';

/**
 * Detail header.  Pairs with VueDetailContent.
 */
@Component({
  selector: 'vue-detail-header',
  templateUrl: './vue-detail-header.component.html',
  styleUrls: [ './vue-detail-header.component.scss' ]
})
export class VueDetailHeaderComponent {}
