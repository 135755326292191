<app-base-chart-popover [title]="title">
  <div *ngIf="loading" class="loading">
    <mat-spinner diameter="32"></mat-spinner>
  </div>
  <div *ngIf="!loading" class="figures">
    <figure *ngFor="let bar of hourlyBars" class="figure">
      <figcaption>{{bar.label}}</figcaption>
      <div role="img" class="bar" [ngStyle]="{'width.%': bar.widthPercent, 'background-color': bar.color }"></div>
    </figure>
  </div>
  <ng-container popover-action>
    <app-appt-weekly-popover-actions
      [timestamp]="timestamp"
    ></app-appt-weekly-popover-actions>
  </ng-container>
</app-base-chart-popover>
