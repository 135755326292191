<mat-form-field [appearance]="appearance">
  <mat-label>{{ label }}</mat-label>
  <mat-select
    [formControl]="control"
    [placeholder]="placeholder"
    [required]="required"
    [errorStateMatcher]="errorMatcher"
    [disableOptionCentering]="true"
    [aria-label]="label"
    panelClass="select-dropdown"
  >
    <mat-option *ngFor="let option of options" [value]="option">
      {{getOptionDisplay(option)}}
    </mat-option>
  </mat-select>
  <mat-error *ngIf="errorMessage && invalid">
    {{errorMessage}}
  </mat-error>
</mat-form-field>
