<mat-form-field [appearance]="appearance">
  <mat-label>{{ label }}</mat-label>
  <input 
    matInput 
    type="text" 
    [formControl]="control"
    [placeholder]="placeholder"
    [required]="required"
    [errorStateMatcher]="errorMatcher"
  >
  <mat-error *ngIf="errorMessage && invalid">
    {{errorMessage}}
  </mat-error>
</mat-form-field>
