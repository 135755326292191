<button
  mat-button
  vueTableMenuOpen
  vueOverlayOpen
  class="menu-trigger"
  [attr.aria-label]="'label.poolsOverview.actionsMenu'| translate:pool.name | async"
  [matMenuTriggerFor]="openPoolsMenu"
>
  <mat-icon>more_horiz</mat-icon>
</button>
<mat-menu #openPoolsMenu="matMenu" class="open-pools-menu">
  <a mat-menu-item routerLink="{{pool.id}}" routerLinkActive="active">
    {{ "label.poolOccurrence.viewPoolDetails" | translate | async }}
  </a>
  <button
    *ngIf="isPoolEditable(pool)"
    mat-menu-item
    (click)="openEditDrawer()"
  >
    {{ "label.poolsOverview.editPool" | translate | async }}
  </button>
</mat-menu>
<app-edit-pool-drawer
  *ngIf="isPoolEditable(pool)"
  [poolId]="pool.id"
  [showOpenButton]="false"
></app-edit-pool-drawer>
