<div class="unsaved-banner"
  [ngStyle]="{'display': numberOfEdited === 0 ? 'none' : 'flex'}"
>
  <div class="message" aria-live="polite">
    <ng-container *ngIf="numberOfEdited === 1">
      <p [innerHTML]="'message.capacity.unsaved.singular' | translate:numberOfEditedString | async"></p>
    </ng-container>
    <ng-container *ngIf="numberOfEdited > 1">
      <p [innerHTML]="'message.capacity.unsaved.plural' | translate:numberOfEditedString | async"></p>
      <vue-image-tooltip
        *ngIf="approachingMaxEdits"
        content="{{ 'tooltip.message.capacity.unsaved' | translate:MAX_NUMBER_OF_EDITS | async }}"
        imageSrc="/assets/icons/icon-exclamation.svg"
      ></vue-image-tooltip>
    </ng-container>
  </div>
  <div>
    <vue-basic-button
      (clicked)="resetCapacities()"
      [attr.aria-label]="'message.capacity.reset' | translate | async"
    >
      {{ "button.cancel" | translate | async }}
    </vue-basic-button>
    <vue-flat-button
      (clicked)="saveEditedCapacities()"
      [attr.aria-label]="'message.capacity.save' | translate | async"
    >
      {{ "button.save" | translate | async }}
    </vue-flat-button>
  </div>
</div>
<vue-toast heading="{{ 'title.capacityUpdated' | translate | async }}" icon="success">
  <span toast-body>{{ "message.changes.saved" | translate | async}}</span>
</vue-toast>
