import { Moment } from 'moment';

const SUNDAY_ISO_WEEKDAY = 7;

/**
 * Given any day of the week, return the first day of that week.
 * Note: Uses **Sunday** as the first day of the week.
 *
 * @param currentDay Any day in the week as a Moment object
 * @returns firstDay the first day of the week as a Moment object
 */
export function getFirstDayOfWeek(currentDay: Moment): Moment {
  let firstDay = currentDay;
  const currentIsoWeekday = currentDay.isoWeekday();

  // Always start the week with a Sunday!
  if (currentIsoWeekday !== SUNDAY_ISO_WEEKDAY) {
    firstDay = firstDay.add(-1 * currentIsoWeekday, 'days');
  }

  return firstDay;
}

