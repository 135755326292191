import { Component, Input } from '@angular/core';
import { EXCHANGE_FORMAT } from 'constants/date-formats';
import { utc } from 'moment';

/**
 * Shows two locks representing the restriction status of a pool and one of the occurrences.
 */
@Component({
  selector: 'app-pool-occurrence-restriction',
  templateUrl: './pool-occurrence-restriction.component.html',
  styleUrls: [ './pool-occurrence-restriction.component.scss' ]
})
export class PoolOccurrenceRestrictionComponent {
  /**
   * Represents when an individual occurrence is restricted
   */
  @Input() public isOccurrenceRestricted!: boolean;

  /**
   * Represents when the parent pool is restricted
   */
  @Input() public isPoolRestricted!: boolean;

  @Input() public isOccurrenceOverride!: boolean;

  @Input() public occurrenceDateHasPassed!: boolean; 
}
