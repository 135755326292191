import { Location } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

/**
 *  VueUI-styled breadcrumbs
 */
@Component({
  selector: 'vue-breadcrumbs',
  templateUrl: './vue-breadcrumbs.component.html',
  styleUrls: [ './vue-breadcrumbs.component.scss' ]
})
export class VueBreadcrumbsComponent implements OnDestroy, AfterViewInit {
  /**
   * App name to display as first breadcrumb
   */
  @Input() public appName = '';

  /**
   * Parent nav section to display as second breadcrumb
   */
  @Input() public navSection = '';

  /**
   * Page title to display as large header
   */
  @Input() public pageTitle = '';

  /**
   * Enables back button preceding the pageTitle
   */
  @Input() public enableBack = false;

  /**
   * Reference to heading element
   */
  @ViewChild('heading') private heading?: ElementRef<HTMLHeadingElement>;

  /*
   * Navigate to parent URL if the current page is
   * the first page on initialization of the app.
   * This avoids calling location.back and navigating outside the application.
   *
   * Otherwise, use location.back to go the browsers last history entry
   *
   */
  private navigateToParent = false;

  /**
   * Terminate subscriptions when subject completes
   */
  private destroyed$ = new Subject();

  public constructor(
    private location: Location,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {
    // Subscribe to router events
    this.router.events
      .pipe(takeUntil(this.destroyed$))
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        if ((event as NavigationEnd).id === 1) {
          this.navigateToParent = true;
        }
      });
  }

  /**
   * Focus on heading after view is initiated for all router events except the first.
   *
   * The first router event will be the initialization of the app.
   */
  public ngAfterViewInit(): void {
    this.router.events
      .pipe(filter((event) => (event as RouterEvent).id !== 1))
      .pipe(take(1))
      .subscribe(() => {
        this.focusOnHeading();
      });
  }

  /**
   * Terminate subscriptions
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Navigate to the parent route or the previous route, depending on the navigateToParent flag
   */
  public navigateBack(): void {
    if (this.navigateToParent) {
      this.router.navigate([ '..' ], { relativeTo: this.activeRoute });
    } else {
      this.location.back();
    }
  }

  /**
   * Focus on heading to bring keyboard focus into the page content
   */
  private focusOnHeading(): void {
    this.heading?.nativeElement.focus();
  }
}
