<div class="more-filters-pane" cdkTrapFocus cdkTrapFocusAutoCapture role="dialog">
    <div class="filters-selection">
      <button
        [class.selected]="selectedFilter === 'queue'"
        (click)="selectedFilter = 'queue'"
      >
        {{ "title.queue" | translate | async }}
        <vue-number-indicator
          [number]="pendingSelectedQueues.length"
        ></vue-number-indicator>
      </button>
      <button
        [class.selected]="selectedFilter === 'exams'"
        (click)="selectedFilter = 'exams'"
      >
        {{ "title.exams" | translate | async }}
        <vue-number-indicator
          [number]="pendingSelectedExams.length"
        ></vue-number-indicator>
      </button>
      <button
        [class.selected]="selectedFilter === 'date-range'"
        (click)="selectedFilter = 'date-range'"
      >
        {{ "title.dateRange" | translate | async }}
        <vue-number-indicator
          [number]="getDateCount()"
        ></vue-number-indicator>
      </button>
    </div>
  
    <div class="filter" aria-live="polite">
      <app-queue-filter
        *ngIf="selectedFilter === 'queue'"
        (updateSelectedQueues)="updatePendingQueues($event)"
        [alreadySelectedQueues]="pendingSelectedQueues"
      ></app-queue-filter>
      <app-alert-consumption-exam-filter
        *ngIf="selectedFilter === 'exams'"
        (updateSelectedExams)="updatePendingExams($event)"
        [alreadySelectedExams]="pendingSelectedExams"
      ></app-alert-consumption-exam-filter>
      <div *ngIf="selectedFilter === 'date-range'">
        <app-date-range-filter
          (dateChanged)="updateDateRange($event)"
          [pendingOpenAfterDate]="pendingOpenAfterDate"
          [pendingOpenBeforeDate]="pendingOpenBeforeDate"
        ></app-date-range-filter>
      </div>
    </div>
  
    <div class="action-buttons-wrapper">
      <vue-basic-button
        class="cancel-button"
        (click)="cancelFilters()"
      >
        {{ "button.cancel" | translate | async }}
      </vue-basic-button>
      <vue-basic-button
        (click)="clearFilters()"
        color="warn"
      >
        {{ "button.clear" | translate | async }}
      </vue-basic-button>
      <vue-flat-button (click)="applyFilters()">
        {{ "button.apply" | translate | async }}
      </vue-flat-button>
    </div>
  </div>
  