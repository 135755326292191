import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GetAlertComments } from 'api/types/endpoints/getAlertCommentById';
import { Observable } from 'rxjs';
import { VUE_DIALOG_CONFIG } from 'vue/utilities/vue-dialog-config';

/**
 *  Component for removing an individual template
 */
@Component({
  selector: 'app-alert-comments-modal',
  templateUrl: './alert-comments-modal.component.html'
})
export class AlertCommentsModalComponent {
  poolName: boolean = false;
  displayedColumns: string[] = [
    'date',
    'comment',
    'addedBy',
  ];

  public constructor(
    // eslint-disable-next-line @typescript-eslint/no-parameter-properties
    @Inject(MAT_DIALOG_DATA) public data: AlertCommentsModalInputs
  ) { }
}

export const alertCommentsModalConfig = {
  ...VUE_DIALOG_CONFIG,
  width: '50rem',
};

export interface AlertCommentsModalInputs {

  /**
   * Title shown on top of dialog
   */
  title: Observable<string>;

  dataSource: GetAlertComments[];
}
