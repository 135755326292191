import { PoolDetails, PoolSchedule } from 'api/types';
import { getOverride } from './get-override';
import { utc } from 'moment';
import { EXCHANGE_FORMAT, FULL_DAY_FORMAT } from 'constants/date-formats';

export interface BaseOccurrenceDetail {
  isReleased: boolean;
  isRestricted: boolean;
  schedule: PoolSchedule;
  isOverride?: boolean;
}

/**
 * Constructs a base occurrence object.
 * Precedence goes to an override that is the same date as occurrenceDate
 * Otherwise general pool detail is used
 *
 * @param occurrenceDate date of the occurrence: Format: YYYY-MM-DD
 * @param poolDetails full pool level detail
 * @param isReleased if pool is released
 * @returns Object with isReleased, isRestricted, schedule
 */
export function getBaseOccurrenceDetails(
  occurrenceDate: string,
  poolDetails: Pick<PoolDetails, 'isRestricted' | 'scheduleOverrides' | 'schedule' | 'daysOfWeekWithRestriction' | 'daysOfWeek'>,
  isReleased = false,
): BaseOccurrenceDetail {
  const occurrenceOverride = getOverride(occurrenceDate, poolDetails);

  /**
   * Use details from existing override
   * Or default to general pool data
   */
  return {
    isReleased: occurrenceOverride?.isReleased ?? isReleased,
    isRestricted: occurrenceOverride?.isRestricted ?? getRestrictionByDate(occurrenceDate, poolDetails, poolDetails.daysOfWeek),
    schedule: {
      type: occurrenceOverride?.schedule?.type ?? poolDetails.schedule.type,
      items: occurrenceOverride?.schedule?.items ?? poolDetails.schedule.items,
    },
    isOverride: occurrenceOverride ? true : false,
  };
}

export function getRestrictionByDate(
  occurrenceDate: string,
  poolDetails: Pick<PoolDetails, 'daysOfWeekWithRestriction'>,
  daysOfWeek: string[]
): boolean {
  const englishDate = utc(occurrenceDate, EXCHANGE_FORMAT).clone().locale('en');
  const { daysOfWeekWithRestriction } = poolDetails;
  const isIncludedDay = daysOfWeek?.includes(englishDate.format(FULL_DAY_FORMAT).toLowerCase());

  const isRestricted = isIncludedDay && daysOfWeekWithRestriction?.length
    ? daysOfWeekWithRestriction?.find(
        (i: any) =>
          i.value === englishDate.format(FULL_DAY_FORMAT).toLowerCase()
      ).checked
    : true;

  return isRestricted;
}
