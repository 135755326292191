<div [formGroup]="form" class="datepickers">
  <h1 class="padding">
    Date
  </h1>
  <vue-section class="components">
    <vue-datepicker
      formControlName="date"
    ></vue-datepicker>
  </vue-section>

  <h1 class="padding">
    Date Formats
  </h1>
  <vue-section class="table">
    <vue-table-container>
      <table mat-table [dataSource]="localeValues">
        <ng-container matColumnDef="name" sticky="true">
          <mat-header-cell *matHeaderCellDef>Locale</mat-header-cell>
          <mat-cell *matCellDef="let locale">{{ locale.name }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="day">
          <mat-header-cell *matHeaderCellDef>Day</mat-header-cell>
          <mat-cell *matCellDef="let locale">{{ locale.day }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="fullDay">
          <mat-header-cell *matHeaderCellDef>Full Day</mat-header-cell>
          <mat-cell *matCellDef="let locale">{{ locale.fullDay }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="month">
          <mat-header-cell *matHeaderCellDef>Abbr Month</mat-header-cell>
          <mat-cell *matCellDef="let locale">{{ locale.month }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="monthYear">
          <mat-header-cell *matHeaderCellDef>Abbr Month Year</mat-header-cell>
          <mat-cell *matCellDef="let locale">{{ locale.monthYear }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="dayMonthYear">
          <mat-header-cell *matHeaderCellDef>Day Month Year</mat-header-cell>
          <mat-cell *matCellDef="let locale">{{ locale.dayMonthYear }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="narrow">
          <mat-header-cell *matHeaderCellDef>Numeric Date</mat-header-cell>
          <mat-cell *matCellDef="let locale">{{ locale.narrow }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="short">
          <mat-header-cell *matHeaderCellDef>Abbr Date</mat-header-cell>
          <mat-cell *matCellDef="let locale">{{ locale.short }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="long">
          <mat-header-cell *matHeaderCellDef>Full Date</mat-header-cell>
          <mat-cell *matCellDef="let locale">{{ locale.long }}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: tableColumns;"></mat-row>
      </table>
    </vue-table-container>
  </vue-section>

  <h1 class="padding">
    Date Components
  </h1>
  <vue-section class="components">
    <vue-select
      label="Locale"
      formControlName="locale"
      [options]="localeOptions"
    ></vue-select>
    <vue-datepicker
      formControlName="datePicker"
    ></vue-datepicker>
    <vue-datepicker-chiplist
      formControlName="dateList"
    ></vue-datepicker-chiplist>
    <app-pool-days-of-week
      formControlName="daysOfWeek"
    ></app-pool-days-of-week>
  </vue-section>
</div>
