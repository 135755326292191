import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { EditOutageState } from './edit-outage-state/edit-outage-state.service';
import { FormArray } from '@angular/forms';
import { DatePickerErrorMatcher } from 'utils/error-matchers/date-picker.error-matcher';
import { SuppressErrorMatcher } from 'utils/error-matchers/suppress.error-matcher';
import { AddUTCToTimePipe } from 'pipes/add-utc-to-time.pipe';
import { UTC_TIMES } from 'data/utc-times';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { DisplayableServerError } from 'types/DisplayableServerError';
import { getDisplayableServerError } from 'utils/get-displayable-server-error';
import { RequestStatus } from 'types/RequestStatus';
import { OutagesService } from 'services/api/outages.service';
import { formatDate } from '@angular/common';
import { removeUTCTimesForOutage } from 'utils/time-utils';
import { utc } from 'moment';
import { UnsavedChangesDialogService } from 'services/unsaved-changes-dialog.service';

@Component({
  selector: 'edit-outages-page',
  templateUrl: './edit-outages-page.component.html',
  styleUrls: ['./edit-outages-page.component.scss']
})
export class EditOutagesPageComponent implements OnInit, OnDestroy {

  /**
   * Track page loading state internally
   */
  public pageLoading = false;

  /**
   * Observable completes when component destroyed
   */
  private destroyed$ = new Subject();

  /**
  * Error matcher for datepicker components
  */
  public datePickerErrorMatcher = new DatePickerErrorMatcher();

  public suppressErrorState = new SuppressErrorMatcher();

  public utcStartTimes = [...UTC_TIMES];

  public utcEndTimes = [...UTC_TIMES];

  public formIsValid = false;

  public isValidData = false;

  public isStartDateTimePassed = false;

  private error?: DisplayableServerError | null;

  public status: RequestStatus = 'initial';

  public outageId: string = '';

  currentDate!: string;

  currentTime!: string;

  private initFormValues: { [key: string]: any };

  public constructor(
    private editOutageState: EditOutageState,
    private addUTCToTimePipe: AddUTCToTimePipe,
    private router: Router,
    private outageService: OutagesService,
    private route: ActivatedRoute,
    private unsavedChangesDialogService: UnsavedChangesDialogService
  ) {
    this.editOutageState.editOutageForm.statusChanges
      .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
      .subscribe((status) => {
        this.formIsValid = status === 'VALID';
      });

    const controls = (this.editOutageState.editOutageForm.get('outages') as FormArray).controls;
    controls?.forEach((control: any) => {
      // Restrict end time based on start time
      control.controls.startTime.valueChanges
        .pipe(takeUntil(this.destroyed$))
        .subscribe((value: any) => {
          //this.utcEndTimes = removeUTCTimesForOutage(value, 'before');
        });

      // Restrict start time based on end time
      control.controls.endTime.valueChanges
        .pipe(takeUntil(this.destroyed$))
        .subscribe((value: any) => {
          //this.utcStartTimes = removeUTCTimesForOutage(value, 'after');
        });
    });

    this.initFormValues = this.editOutageForm.getRawValue();
  }

  /**
   * Fetch outage details
   */
  public ngOnInit(): void {
    this.pageLoading = true;
    this.editOutageState.resetState();
    this.outageId = this.route.snapshot.params.id ?? '';
    const controls = (this.editOutageState.editOutageForm.get('outages') as FormArray).controls;
    this.outageService.getOutageById({ id: this.outageId })
      .pipe(take(1))
      .subscribe((data) => {
        if (data) {
          controls?.forEach((control: any) => {
            control.controls.outageName.patchValue(data.name)
            control.controls.startDate.patchValue(utc(data.startdate))
            control.controls.startTime.patchValue(data.starttime)
            control.controls.endDate.patchValue(utc(data.enddate))
            control.controls.endTime.patchValue(data.endtime)
          });
          this.formIsValid = true;
          this.pageLoading = false;

          if (this.toCheckDateAndTimeIsPast(data.enddate, data.endtime)) {
            this.isValidData = false;
            controls?.forEach((control: any) => {
              if (!control.controls.outageName.disabled) {
                control.controls.outageName.disable();
              }
            });
          }
          else {
            this.isValidData = true;
            controls?.forEach((control: any) => {
              if (control.controls.outageName.disabled) {
                control.controls.outageName.enable();
              }
            });
          }

          if (this.toCheckDateAndTimeIsPast(data.startdate, data.starttime)) {
            this.isStartDateTimePassed = true;
          }
          else {
            this.isStartDateTimePassed = false;
          }
          this.initFormValues = this.editOutageForm.getRawValue();
        }
      }, (error: unknown) => {
        this.pageLoading = false;
        this.isValidData = false;
        this.error = getDisplayableServerError(error);
        this.status = 'error';
      });
  }

  public toCheckDateAndTimeIsPast(startdate: string, starttime: string) {
    var flagIsPast = false;
    if (startdate && starttime) {
      var dateOnly = startdate;
      var shortTime = starttime;

      this.currentDate = formatDate(
        new Date().toLocaleString(),
        'yyyy-MM-dd',
        'en-US',
        'UTC'
      );

      this.currentTime = formatDate(
        new Date().toLocaleString(),
        'HH:mm',
        'en-US',
        'UTC'
      );

      if (dateOnly < this.currentDate) {
        flagIsPast = true;
      }
      else if (dateOnly == this.currentDate) {
        if (shortTime < this.currentTime) {
          flagIsPast = true;
        }
      }
    }
    return flagIsPast;
  }

  /**
   * Terminate subscriptions
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public validateOutage(): void {
  }

  /**
 * @returns editOutageState.editOutageForm
 */
  public get editOutageForm() {
    return this.editOutageState.editOutageForm;
  }

  /**
 * @param time time
 * @returns time with (UTC) added
 */
  public addUTCToTime(time: string): string {
    return this.addUTCToTimePipe.transform(time);
  }

  getControls() {
    return (this.editOutageForm.get('outages') as FormArray).controls;
  }

  getControlValue(control: any, controlName: any) {
    return control.controls[controlName].value;
  }

  getControlError(control: any, controlName: any) {
    return control.controls[controlName].error;
  }

  public saveOutage(): void {
    this.status = 'loading';

    this.editOutageState.submitOutage(this.outageId)
      .pipe(take(1))
      .subscribe(() => {
        this.error = null;
        this.status = 'success';
        this.outageService.outageSuccess$.next({
          outageId: '',
          type: 'edit'
        });
        this.router.navigate([`outages`]);
      }, (error: unknown) => {
        this.error = getDisplayableServerError(error);
        this.status = 'error';
      });
  }

  public closeAddOutage(): void {
    this.router.navigate([`outages`]);
  }

  public get displayableServerError(): DisplayableServerError | null | undefined {
    return this.editOutageState.displayableServerError || this.error;
  }

  /**
   * Closes the drawer if there hasn't been any form changes, otherwise prompts user.
   */
  public closeDrawerIfUnchanged(): void {
    if (JSON.stringify(this.editOutageForm.getRawValue()) !== JSON.stringify(this.initFormValues)) {
      this.unsavedChangesDialogService.open().pipe(take(1)).subscribe((choseToLeave) => {
        if (choseToLeave) {
          this.closeAddOutage();
        }
      });
    } else {
      this.closeAddOutage();
    }
  }
}
