import { AbstractControl, ValidatorFn } from '@angular/forms';
import { isMoment } from 'moment';
import { END_CHRONOLOGICAL_ORDER, INVALID_CHRONOLOGICAL_ORDER, NON_MOMENT_DATE, START_CHRONOLOGICAL_ORDER } from 'constants/datepicker-errors';

/**
 * Creates a validator function that validates two controls are dates are in chronological order
 *
 * Note: Validator should be set at the form level, not the control level
 *
 * @param startDateControlName - name of control for starting date
 * @param endDateControlName - name of control for ending date
 */
export function chronologicalOrderValidator(startDateControlName: string, endDateControlName: string): ValidatorFn {
  return (form: AbstractControl) => {
    const startDate = form.get(startDateControlName);
    const endDate = form.get(endDateControlName);

    if (startDate === null || endDate === null) {
      return { 'missing-controls': { startDate: startDate?.value, endDate: endDate?.value } };
    }

    // Verify that both are moment objects
    if (!isMoment(startDate.value) || !isMoment(endDate.value)) {
      return { [ NON_MOMENT_DATE ]: { startDate: startDate.value, endDate: endDate.value } };
    }

    if (startDate.value.isAfter(endDate.value, 'day')) {
      startDate.setErrors({
        ...startDate.errors,
        [ START_CHRONOLOGICAL_ORDER ]: startDate.value
      });
      endDate.setErrors({
        ...endDate.errors,
        [ END_CHRONOLOGICAL_ORDER ]: endDate.value
      });
      return { [ INVALID_CHRONOLOGICAL_ORDER ]: { startDate: startDate.value, endDate: endDate.value } };
    }

    return null;
  };
}
