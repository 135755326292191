import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CreateOutagePayload, CreateOutageResponse } from 'api/types/endpoints/createOutage';
import { formatDateForPayload } from 'components/common/pools/utils/format-date-for-payload';
import { Observable } from 'rxjs';
import { OutagesService } from 'services/api/outages.service';
import { DisplayableServerError } from 'types/DisplayableServerError';
import { invalidCharactersValidator } from 'utils/validators/invalid-characters.validator';
import { pastDateValidator } from 'utils/validators/past-date.validator';

@Injectable({
  providedIn: 'root',
})
export class AddOutageState {
  /**
   * Add outage form group
   */
  public addOutageForm: UntypedFormGroup;
  public displayableServerError: DisplayableServerError | null = null;
  private initFormValues: { [key: string]: any };

  constructor(private formBuilder: FormBuilder,
    private outageService: OutagesService) {
    // Create form
    this.addOutageForm = this.createOutageForm();
    this.initFormValues = this.addOutageForm.getRawValue();
  }

  public resetState(): void {
    const outages = this.addOutageForm.get('outages') as FormArray;

    if (outages.length > 1) {
      for (var i = outages.length - 1; i > 0; i--) {
        outages.removeAt(i)
      }
    }
    this.addOutageForm.reset(this.initFormValues);
  }

  /**
   * Creates add outage form group
   */
  private createOutageForm(): UntypedFormGroup {
    return this.formBuilder.group({
      outages: this.formBuilder.array([this.createOutageFormGroup()])
    });
  }

  public createOutageFormGroup(): UntypedFormGroup {
    return new FormGroup({
      'outageName': new UntypedFormControl('', {
        validators: [
          Validators.required,
          invalidCharactersValidator,
        ],
      }),
      'startDate': new UntypedFormControl('', { validators: [Validators.required, pastDateValidator], updateOn: 'blur' }),
      'startTime': new UntypedFormControl('', Validators.required),
      'endDate': new UntypedFormControl('', { validators: [Validators.required, pastDateValidator], updateOn: 'blur' }),
      'endTime': new UntypedFormControl('', Validators.required),
    })
  }

  public submitOutage(): Observable<CreateOutageResponse> {
    const payload = this.constructPoolPayload();
    return this.outageService.createOutage(payload);
  }

  private constructPoolPayload(): CreateOutagePayload {
    var controls = (this.addOutageForm.get('outages') as FormArray).controls;
    var outageArry: any = [];
    controls?.forEach((control: any) => {
      var objOutage =
      {
        name: control.controls['outageName'].value.trim(),
        status: "active",
        startdate: formatDateForPayload(control.controls['startDate'].value),
        starttime: control.controls['startTime'].value,
        enddate: formatDateForPayload(control.controls['endDate'].value),
        endtime: control.controls['endTime'].value,
      }
      outageArry.push(objOutage);
    });

    return {
      outage: outageArry
    }
  }

  public hasFormValueChanged(): boolean {
    return JSON.stringify(this.addOutageForm.getRawValue()) !== JSON.stringify(this.initFormValues);
  }
}

