/*
 * Use Moment's localized date formats where possible
 * https://momentjs.com/docs/#/displaying/format/
 */
export const FULL_MONTH_DAY_YEAR_FORMAT = 'LL';
export const DISPLAY_DAY_FORMAT = 'll';
export const MONTH_DAY_YEAR_FORMAT = 'l';

export const EXCHANGE_FORMAT = 'YYYY-MM-DD';
export const POPOVER_DETAIL_FORMAT = 'ddd, MMM DD – H:mm';
export const ABBREV_DAY_FORMAT = 'ddd';
export const FULL_DAY_FORMAT = 'dddd';
export const ABBREV_MONTH_FORMAT = 'MMM';
export const ABBREV_MONTH_YEAR_FORMAT = 'MMM YYYY';
export const ABBREV_YEAR_FORMAT = 'YYYY';
export const FULL_MONTH_YEAR_FORMAT = 'MMMM YYYY';
