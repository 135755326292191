import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Moment, utc } from 'moment';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DISPLAY_DAY_FORMAT } from 'constants/date-formats';
import { TIMEZONE_CHANGE_KEY } from 'constants/timezones';
import { TranslatePipe } from 'pipes/translate.pipe';
import { TimezoneService } from 'services/timezone.service';
import { Timezone } from 'types/Timezone';
import { getOffsetDisplay } from 'utils/format-time';
import { VueToastComponent } from 'vue/components/vue-toast/vue-toast.component';

/**
 * Dropdown that lists options of timezones relative to a given date
 *
 * Includes a reset option when a value is selected
 */
@Component({
  selector: 'app-timezone-select',
  templateUrl: './timezone-select.component.html',
  styleUrls: [ './timezone-select.component.scss' ]
})
export class TimezoneSelectComponent implements OnInit, OnChanges, OnDestroy {
  /**
   * Date to calculate timezones offsets from.
   */
  @Input() public date: Moment = utc();

  /**
   * Reference to the Toast component
   */
  @ViewChild(VueToastComponent) public timezoneToast?: VueToastComponent;

  /**
   * List of timezones to be shown;
   */
  public timezones: Timezone[] = [];

  /**
   * Currently selected timezone.
   *
   * Should be persisted through date updates.
   */
  public selectedTimezone: Timezone | null = null;

  /**
   * Translated message to show in the toast if an offset change occurs.
   * Generated dynamically in `showTimezoneToast`
   */
  public timezoneMessage: Observable<string> = of('')

  /**
   * Completes when the component is destroyed
   */
  private destroyed$ = new Subject<void>();

  public constructor(
    private timezoneService: TimezoneService,
    private translatePipe: TranslatePipe,
  ) {
    // Subscribe to global selected timezone
    this.timezoneService.selectedTimezone$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((tz) => {
        this.selectedTimezone = tz;

        if (tz) {
          this.alertUserForOffsetChange(tz, this.date);
        }
      });
  }

  public ngOnInit(): void {
    // Set initial list of timezones
    this.updateTimezones(this.date);
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // When date changes update the list of timezones
    if ('date' in changes) {
      this.updateTimezones(changes.date.currentValue);

      if (this.selectedTimezone) {
        this.updatedSelectedTimezone();

        this.alertUserForOffsetChange(this.selectedTimezone, changes.date.currentValue);
      }
    }
  }

  /**
   * Handle change event from select component
   */
  public timezoneChange(event: MatSelectChange): void {
    const tz = this.timezones.find((t) => t.ianaCode === event.value) ?? null;

    this.updateGlobalTimezone(tz);
  }

  /**
   * Update list of timezones based on date
   */
  private updateTimezones(date: Moment): void {
    this.timezones = this.timezoneService.generateTimezones(date);
  }

  /**
   * Determines if the list of timezones includes a change in offset for the selected timezone.
   *
   * If there is timezone found with the same code but different offset, emit that a timezone change occurred.
   */
  private updatedSelectedTimezone(): void {
    const newTz = this.timezones.find((t) => {
      return t.ianaCode === this.selectedTimezone?.ianaCode &&
          t.offset !== this.selectedTimezone.offset;
    });

    if (newTz) {
      this.updateGlobalTimezone(newTz);
    }
  }

  /**
   * Sets and emits the selected timezone
   */
  private updateGlobalTimezone(tz: Timezone | null): void {
    this.timezoneService.updateSelectedTimezone(tz);
  }

  /**
   * Conditionally shows an alert to the user if an offset change occurs on the given day.
   */
  private alertUserForOffsetChange(timezone: Timezone, date: Moment): void {
    const offsetResult = this.timezoneService.doesOffsetChangeOnDay(date, timezone);

    if (offsetResult.changed) {
      this.showTimezoneToast(timezone, offsetResult.startOfDayOffset, offsetResult.endOfDayOffset);
    }
  }

  /**
   * Set the timezone message & opens the timezone alert
   */
  private showTimezoneToast(currentTimezone: Timezone, oldOffset: number, newOffset: number): void {
    this.timezoneMessage = this.getTimezoneMessage(currentTimezone, oldOffset, newOffset);
    this.timezoneToast?.open();
  }

  /**
   * Generates timezone alert message by using the current timezone, the new offset and the date.
   */
  private getTimezoneMessage(currentTimezone: Timezone, oldOffset: number, newOffset: number): Observable<string> {
    return this.translatePipe
      .transform(
        currentTimezone.displayTranslations[ TIMEZONE_CHANGE_KEY ],
        getOffsetDisplay(oldOffset),
        getOffsetDisplay(newOffset),
        this.date.format(DISPLAY_DAY_FORMAT)
      );
  }
}
