import { Component } from '@angular/core';
import { VueBaseButton } from '../vue-button';

/**
 *  Basic button with no visible background
 */
@Component({
  selector: 'vue-basic-button',
  templateUrl: './vue-basic-button.component.html',
  styleUrls: [ '../vue-button.scss' ]
})
export class VueButtonComponent extends VueBaseButton {
}
