<mat-slide-toggle
  #matSlideToggle
  [color]="color"
  [checked]="checked"
  [disabled]="disabled"
  [labelPosition]="labelPosition"
  (change)="changed($event)"
>
  <ng-content></ng-content>
</mat-slide-toggle>
