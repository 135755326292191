<div mat-dialog-title>
  <h1>{{data.dialogTitle | async}}</h1>
  <button
    mat-button
    (click)="closeDialog()"
    [attr.aria-label]="'label.aria.closeDialog' | translate | async"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <h3>{{ "title.capacity" | translate | async }}</h3>
  <form id="occurrence" [formGroup]="occurrenceForm" autocomplete="off" (ngSubmit)="handleSubmit()">
    <div class="occurrence-columns">
      <ng-container
        formArrayName="timeSlots"
        *ngFor="let occurrence of occurrenceTimeSlots; let i=index"
      >
          <label *ngIf="data.pageRef == 'create'" [for]="'slot' + i">
            {{occurrence.startTime}} - {{addUTCToTime(occurrence.endTime)}}
          </label>
          <label *ngIf="data.pageRef != 'create'" [for]="'slot' + i">
            {{addUTCToTime(occurrence.startTime)}}
          </label>
          <vue-numeric-input
            [id]="'slot' + i"
            [formControl]="getTimeSlotControl(i)"
            [errorMatcher]="suppressErrorState"
            [maxLength]="5"
            [compact]="true"
          ></vue-numeric-input>
          <p class="registrations">{{ "title.registrations" | translate | async }}<span>{{occurrence.registrations}}</span></p>
      </ng-container>
      <ng-container class="total-container">
        <div class="total-label">
          {{ "title.totalCapacity" | translate | async }} <br />
          <small>{{ "label.poolOccurrence.appointmentsPerDay" | translate | async }}</small>
        </div>
        <p class="total">{{totalCapacity}}</p>
        <div class="total-label registrations">
          {{ "title.registrations" | translate | async }} <span class="total">{{totalRegistrations}}</span><br />
          <small>{{ "label.poolOccurrence.totalRegistrationsToday" | translate | async }}</small>
        </div>
      </ng-container>
    </div>
    <div class="two-columns">
      <vue-select
        label="{{ 'label.placeholder.startTime' | translate | async }}"
        formControlName="startTime"
        [required]="true"
        [options]="utcStartTimes"
        [getOptionDisplay]="addUTCToTime.bind(this)"
        [errorMatcher]="suppressErrorState"
      ></vue-select>
      <vue-select
        label="{{ 'label.placeholder.endTime' | translate | async }}"
        formControlName="endTime"
        [required]="true"
        [options]="utcEndTimes"
        [getOptionDisplay]="addUTCToTime.bind(this)"
        [errorMatcher]="suppressErrorState"
      ></vue-select>
    </div>
    <div class="restriction">
      <app-pool-restriction
        formControlName="isRestricted"
        [isOccurrence]="true"
      ></app-pool-restriction>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <vue-flat-button [disabled]="occurrenceForm.invalid" type="submit" form="occurrence">
    {{ "button.apply" | translate | async }}
  </vue-flat-button>
</div>
