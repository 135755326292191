import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import { CapacityTemplate } from 'api/types';
import { TranslatePipe, TranslationKey } from 'pipes/translate.pipe';
import { CapacityTemplatesService } from 'services/api/capacity-templates.service';
import { TemplatePageService } from 'services/template-page.service';
import { VueToastComponent } from 'vue/components/vue-toast/vue-toast.component';
import { RemoveTemplateComponent, RemoveTemplateInputs, removeTemplateConfig } from 'components/common/template/remove-template/remove-template.component';
import { MatDialog } from '@angular/material/dialog';
import { getDisplayableServerError } from 'utils/get-displayable-server-error';

type Status = 'archived' | 'active';

/**
 *  Template menu menu.  Allows archiving / editing / duplicating
 */
@Component({
  selector: 'app-template-menu',
  templateUrl: './template-menu.component.html',
  styleUrls: [ './template-menu.component.scss' ],
})
export class TemplateMenuComponent implements OnInit {
  /**
   * Template for respective row in templates-table
   */
  @Input() public template!: CapacityTemplate;

  /**
   * Emit event when a template has been updated
   */
  @Output() public templateStatusUpdated = new EventEmitter<void>();

  /**
   * Reference to VueToastComponent
   */
  @ViewChild(VueToastComponent) public toast!: VueToastComponent;

  /**
   * Toast message specific to template action
   */
  public toastMessage = '';

  /**
   * Local copy of translations
   */
  private translations: {[key: string]: string} = {
    'title.template.archived': '',
    'title.template.activated': '',
    'message.template.activated': '',
    'message.template.archived': '',
  }

  public constructor(
    private capacityTemplateService: CapacityTemplatesService,
    private templatePageService: TemplatePageService,
    private translatePipe: TranslatePipe,
    private dialog: MatDialog
  ) {}

  public ngOnInit(): void {
    Object.keys(this.translations).forEach((key) => {
      this.translatePipe.transform(key as TranslationKey, key.includes('message') ? this.template.name : '')
        .pipe(take(1))
        .subscribe((translation) => {
          this.translations[ key ] = translation;
        });
    });
  }

  /**
   * Update template status
   * On success, hide page loader/error states and emit event
   * On failure, subscribe to attemptRetry$ so the request can be repeated
   *
   * @param status new template status
   */
  public updateTemplateStatusConfirmation(status: Status): void {
    this.dialog.open<RemoveTemplateComponent, RemoveTemplateInputs>(RemoveTemplateComponent, {
      ...removeTemplateConfig,
      data: {
        title: this.translatePipe.transform('template.remove.dialog.heading'),
        removeTemplateCallback: () => {
          // Called when a user clicks "remove" on dialog
          this.updateTemplateStatus(status);
        }
      }
    });
  }

  public updateTemplateStatus(status: Status): void {
    this.templatePageService.showSaving();
    this.capacityTemplateService.updateCapacityTemplateStatus(this.template.id, status)
      .pipe(take(1))
      .subscribe(() => {
        this.templatePageService.hideSaving();
        this.templatePageService.hideServerError();
        this.templateStatusUpdated.emit();
        this.showToast(status);
      }, (error: unknown) => {
        this.templatePageService.showServerError(getDisplayableServerError(error)?.message ?? '');
        this.setStatusRetrySubscription(status);
      });
  }

  /**
   * Fetches full template details before emitting event
   * AddTemplateDrawer listens for editTemplate observable
   */
  public editTemplate(): void {
    this.templatePageService.showLoading();
    this.capacityTemplateService.getCapacityTemplateById(this.template.id)
      .pipe(take(1))
      .subscribe((template) => {
        this.templatePageService.hideLoading();
        this.templatePageService.hideServerError();
        this.templatePageService.emitEditTemplate(template);
      }, (error: unknown) => {
        this.templatePageService.showServerError(getDisplayableServerError(error)?.message ?? '', true);
        this.setEditRetrySubscription();
      });
  }

  public activeTemplate(): void {
    this.templatePageService.showLoading();
    this.capacityTemplateService.getCapacityTemplateById(this.template.id)
      .pipe(take(1))
      .subscribe((template) => {
        this.templatePageService.hideLoading();
        this.templatePageService.hideServerError();
        this.templatePageService.emitActiveTemplate(template);
      }, (error: unknown) => {
        this.templatePageService.showServerError(getDisplayableServerError(error)?.message ?? '', true);
        this.setActiveRetrySubscription();
      });
  }

  /**
   * Fetches full template details before emitting duplicate event
   * AddTemplateDrawer listens for duplicateTemplate observable
   */
  public duplicateTemplate(): void {
    this.templatePageService.showLoading();
    forkJoin([
      this.capacityTemplateService.getCapacityTemplateById(this.template.id),
      this.translatePipe.transform('message.addTemplate.copyOf', this.template.name)
    ])
      .pipe(take(1))
      .subscribe(([ template, newTemplateName ]) => {
        this.templatePageService.hideLoading();
        this.templatePageService.hideServerError();
        this.templatePageService.emitDuplicateTemplate({
          ...template,
          name: newTemplateName
        });
      }, (error: unknown) => {
        this.templatePageService.showServerError(getDisplayableServerError(error)?.message ?? '', true);
        this.setDuplicateRetrySubscription();
      });
  }

  /**
   * Subscribe to attemptRetry$ observable to retry updating template status
   * Update Template page service with our subscription to cancel any others
   *
   * @param status status to retry update with
   */
  private setStatusRetrySubscription(status: Status): void {
    const retrySubscription = this.templatePageService.attemptRetry$
      .pipe(take(1))
      .subscribe(() => {
        this.updateTemplateStatus(status);
      });
    this.templatePageService.setRetrySubscription(retrySubscription);
  }

  /**
   * Subscribe to attemptRetry$ observable to retry edit template
   * Update Template page service with our subscription to cancel any others
   */
  private setEditRetrySubscription(): void {
    const retrySubscription = this.templatePageService.attemptRetry$
      .pipe(take(1))
      .subscribe(() => {
        this.editTemplate();
      });
    this.templatePageService.setRetrySubscription(retrySubscription);
  }

  private setActiveRetrySubscription(): void {
    const retrySubscription = this.templatePageService.attemptRetry$
      .pipe(take(1))
      .subscribe(() => {
        this.activeTemplate();
      });
    this.templatePageService.setRetrySubscription(retrySubscription);
  }

  /**
   * Subscribe to attemptRetry$ observable to retry duplicating template
   * Update Template page service with our subscription to cancel any others
   */
  private setDuplicateRetrySubscription(): void {
    const retrySubscription = this.templatePageService.attemptRetry$
      .pipe(take(1))
      .subscribe(() => {
        this.duplicateTemplate();
      });
    this.templatePageService.setRetrySubscription(retrySubscription);
  }

  /**
   * Shows success toast based on action
   *
   * @param status updated status of template
   */
  private showToast(status: Status): void {
    if (!this.toast) {
      return;
    }

    if (status === 'active') {
      this.toast.heading = this.translations[ 'title.template.activated' ];
      this.toastMessage = this.translations[ 'message.template.activated' ];
    } else {
      this.toast.heading = this.translations[ 'title.template.archived' ];
      this.toastMessage = this.translations[ 'message.template.archived' ];
    }

    this.toast.open();
  }
}
