<vue-flat-button (clicked)="openDrawer({type: 'new'})">
  {{ 'button.addTemplate' | translate | async}}
</vue-flat-button>
<vue-drawer #drawer [contentRef]="addTemplate" [drawerConfig]="drawerConfig">
  <ng-template #addTemplate>
    <app-drawer-header
      (close)="closeDrawer()"
      [disableClose]="status === 'loading'"
      [progress]="{totalSteps: steps.length, currentStep: steps.indexOf(step) + 1}"
    >
      <span *ngIf="type === 'new' || type === 'duplicate'">
        {{ 'title.addTemplate' | translate | async}}
      </span>
      <span *ngIf="type === 'edit'">
        {{ 'title.editTemplate' | translate | async}}
      </span>
      <span *ngIf="type === 'active'">
        {{ 'title.activeTemplate' | translate | async}}
      </span>
    </app-drawer-header>
    <div
      #dialogContent
      matDialogContent
      class="mat-typography"
      [class.loading]="status === 'loading'"
    >
      <app-error-message
        *ngIf="status === 'error'"
        [heading]="displayableServerError?.title"
        [message]="displayableServerError?.message"
      ></app-error-message>
      <app-add-template-form
        [type]="type"
        *ngIf="stepNumber === -1"
      ></app-add-template-form>
      <app-daily-allocations-form
        *ngIf="stepNumber >= 0"
        [dayNumber]="stepNumber"
      ></app-daily-allocations-form>
    </div>
    <div matDialogActions>
      <vue-basic-button
        *ngIf="stepNumber === -1"
        (clicked)="closeDrawer()"
      >
        {{ "button.cancel" | translate | async }}
      </vue-basic-button>
      <vue-basic-button
        *ngIf="stepNumber >= 0"
        (clicked)="previousStep()"
        [disabled]="status === 'loading'"
      >
        {{ "button.back" | translate | async }}
      </vue-basic-button>
      <vue-flat-button
        *ngIf="stepNumber <= 5"
        [disabled]="!enableNextButton()"
        type="submit"
        form="add-template-form"
        [loading]="status === 'loading'"
      >
        {{ "button.next" | translate | async }}
      </vue-flat-button>
      <vue-flat-button
        *ngIf="stepNumber === 6"
        [disabled]="!enableNextButton()"
        [loading]="status === 'loading'"
        type="submit"
        form="add-template-form"
      >
        {{ "button.save" | translate | async }}
      </vue-flat-button>
    </div>
  </ng-template>
</vue-drawer>
