import { Component, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { DateAdapter } from '@angular/material/core';
import { Moment, utc } from 'moment';
import { PoolDaysOfWeekComponent } from 'components/drawers/add-pool-drawer/pool-days-of-week/pool-days-of-week.component';

const baseList = {
  'en-US': 'English - US',
  'en-GB': 'English - Great Britain',
  'es-ES': 'Spanish - Spain',
  'fr-CA': 'French - Canada',
  'ja-JP': 'Japanese',
  'ru-RU': 'Russian'
};

/**
 * Test page that demonstrates date localization.  This is an under-the-hood page only – no links point to it.
 */
@Component({
  selector: 'app-moment-test',
  templateUrl: './date-localization-test.component.html',
  styleUrls: [ './date-localization-test.component.scss' ]
})
export class DateLocalizationTest {
  @ViewChild(PoolDaysOfWeekComponent) public daysWeek!: PoolDaysOfWeekComponent;

  public dates: string[] = [];

  public locales: {[key: string]: {[key: string]: string}} = {};

  public form: UntypedFormGroup;

  public tableColumns =[ 'name', 'day', 'fullDay', 'month', 'monthYear', 'dayMonthYear', 'narrow', 'short', 'long' ]

  private oldLocale = 'en-US';

  public constructor(
    private dateAdapter: DateAdapter<MatMomentDateModule>,
    formBuilder: UntypedFormBuilder,
  ) {
    this.form = formBuilder.group({
      locale: new UntypedFormControl('en-US'),
      datePicker: new UntypedFormControl(utc()),
      dateList: new UntypedFormControl([ utc().format('ll') ]),
      daysOfWeek: new UntypedFormControl([]),
      date: new UntypedFormControl(utc())
    });

    const startDate = this.form.value.date;

    this.setDateTable(startDate);

    this.form.controls.date.valueChanges.subscribe((newDate) => {
      this.setDateTable(newDate);
    });

    this.form.controls.locale.valueChanges.subscribe((locale) => {
      this.dateAdapter.setLocale(locale);
      this.form.controls.dateList.setValue(
        this.form.controls.dateList.value.map((date: string) => {
          return utc(date, 'll', this.oldLocale).locale(locale).format('ll');
        })
      );
      this.oldLocale = locale;

      this.daysWeek.daysOfWeek = this.daysWeek.daysOfWeek.map((day, i) => {
        return {
          ...day,
          display: utc().locale(locale).day(i).format('dddd').toLocaleLowerCase()
        };
      });
    });
  }

  public setDateTable(date: Moment): void {
    for (const [ locale, name ] of Object.entries(baseList)) {
      this.locales[ locale ] = {
        name: name,
        day: date.locale(locale).format('ddd'),
        fullDay: date.locale(locale).format('dddd'),
        month: date.locale(locale).format('MMM'),
        monthYear: date.locale(locale).format('MMM YYYY'),
        dayMonthYear: date.locale(locale).format('ddd MMM D YYYY'),
        narrow: date.locale(locale).format('l'),
        short: date.locale(locale).format('ll'),
        long: date.locale(locale).format('LL')
      };
    }
    this.locales[ 'format' ] = {
      name: 'Localized Format?',
      day: 'Yes',
      fullDay: 'Yes',
      month: 'Yes',
      monthYear: 'No',
      dayMonthYear: 'No',
      narrow: 'Yes',
      short: 'Yes',
      long: 'Yes'
    };
  }

  public get localeValues(): {[key: string]: string}[] {
    return Object.values(this.locales);
  }

  public get localeOptions(): string[] {
    return Object.keys(this.locales).filter((key) => key !== 'format');
  }
}
