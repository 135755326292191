import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import {
  HourlyPoolScheduleItem,
  PoolDetails,
  PoolScheduleOverride,
} from 'api/types';
import { UTC_TIMES } from 'data/utc-times';
import { AddUTCToTimePipe } from 'pipes/add-utc-to-time.pipe';
import { SuppressErrorMatcher } from 'utils/error-matchers/suppress.error-matcher';
import { removeUTCTimes } from 'utils/time-utils';
import { VUE_DIALOG_CONFIG } from 'vue/utilities/vue-dialog-config';
import { calculateTimeSlots, calculateTimeSlotsMinuteWise } from '../utils/calculate-time-slots';
import { getOverride } from '../utils/get-override';
import { getPoolStartEndTime } from '../utils/get-pool-start-end-time';
import { ContainsRegistrationMetric, getRegistrationsForTimePeriod } from '../utils/get-registrations-for-time-period';
import { getRestrictionByDate } from '../utils/get-base-occurrence-details';

/**
 *  Component that allows editing of an individual pool occurrence.
 */
@Component({
  selector: 'app-edit-occurrence-dialog',
  templateUrl: './edit-occurrence-dialog.component.html',
  styleUrls: ['./edit-occurrence-dialog.component.scss']
})
export class EditOccurrenceDialogComponent implements OnInit, OnDestroy {
  /** Array of occurrence start times */
  public utcStartTimes = [...UTC_TIMES];

  /** Array of occurrence end times */
  public utcEndTimes = [...UTC_TIMES];

  /** Suppress error state of form fields */
  public suppressErrorState = new SuppressErrorMatcher();

  /** FormGroup for the occurrence */
  public occurrenceForm: UntypedFormGroup;

  /** Array of occurrence time slots */
  public occurrenceTimeSlots: { startTime: string, endTime: string, registrations: number }[] = [];

  /** Total capacity of the occurrence */
  public totalCapacity = 0;

  /** Total registrations for the occurrence */
  public totalRegistrations = 0;

  /**
   * Existing override for occurrence date
   */
  private existingOverride: PoolScheduleOverride | undefined;

  /** Completes when the component is destroyed */
  private destroyed$ = new Subject();

  /** Initial values of `occurrenceForm`, used to compare changes */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private initialFormValues: { [key: string]: any } = {};

  public constructor(
    // eslint-disable-next-line @typescript-eslint/no-parameter-properties
    @Inject(MAT_DIALOG_DATA) public data: EditOccurrenceDialogInputs,
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<EditOccurrenceDialogComponent>,
    private addUTCToTimePipe: AddUTCToTimePipe,
  ) {
    const { date, poolDetails } = this.data;
    this.existingOverride = getOverride(date, poolDetails);

    // Create empty form before `@Input`s are available.
    this.occurrenceForm = new UntypedFormGroup({
      startTime: new UntypedFormControl('', Validators.required),
      endTime: new UntypedFormControl('', Validators.required),
      timeSlots: this.formBuilder.array([]),
      isRestricted: new UntypedFormControl(this.existingOverride?.isRestricted ?? getRestrictionByDate(date, poolDetails, poolDetails.daysOfWeek))
    });
  }

  /**
   * Setup form subscriptions and save initial values
   */
  public ngOnInit(): void {
    this.setInitialValues();

    this.setSubscriptions();

    this.initialFormValues = this.occurrenceForm.value;
  }

  /**
   * Terminate all subscriptions
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Close dialog
   */
  public closeDialog(): void {
    this.dialogRef.close();
  }

  /**
   * Get control of item that tracks capacity
   */
  public getTimeSlotControl(index: number): UntypedFormControl {
    return (this.occurrenceForm.controls.timeSlots as UntypedFormArray).at(index) as UntypedFormControl;
  }

  /**
   * Close dialog and optionally invoke the overrideCallback
   */
  public handleSubmit(): void {
    // If values haven't changed close the dialog
    if (this.sameValuesAsInitial()) {
      this.closeDialog();
    } else {
      this.data.overrideCallback(this.createOutputOverride());
      this.closeDialog();
    }
  }

  /**
   * Returns the time with `(UTC)` added
   */
  public addUTCToTime(time: string): string {
    return this.addUTCToTimePipe.transform(time);
  }

  /**
   * Creates an override object based on the changed values
   */
  private createOutputOverride(): PoolScheduleOverride {
    const { isRestricted } = this.occurrenceForm.value;

    // Define base override, using existing if available
    const override = this.existingOverride ?? {
      date: this.data.date
    };

    // Add `isRestriction` if it has changed
    if (this.hasRestrictionChanged()) {
      override.isRestricted = isRestricted;
    }

    // Add `schedule` if the start/end time or capacities have changed
    if (this.hasDurationChanged() || this.haveCapacitiesChanged()) {
      override.schedule = {
        type: (this.data.pageRef == 'create' ? 'hourly' : '15min'),
        items: this.occurrenceTimeSlots.map((occurrence, index) => {
          return {
            startTime: occurrence.startTime,
            endTime: this.data.pageRef == 'create' ? occurrence.endTime : occurrence.startTime,
            capacity: this.getTimeSlotControl(index).value
          };
        })
      };
    }

    return override;
  }

  /**
   * Returns true when an occurrence has the same values as the initial values
   *
   * Order of conditional matters:
   * Check occurrence params first to avoid checking each individual capacity
   */
  private sameValuesAsInitial(): boolean {
    return !(
      this.hasDurationChanged() ||
      this.hasRestrictionChanged() ||
      this.haveCapacitiesChanged()
    );
  }

  /**
   * Update the time slots based on new start & end times
   */
  private updateTimeSlots(): void {
    const { startTime, endTime, timeSlots } = this.occurrenceForm.controls;
    const { registrations } = this.data;

    if (!startTime?.value || !endTime?.value || !timeSlots?.value) {
      return;
    }

    const timeSlotsWithCapacity = this.occurrenceTimeSlots.map((timeSlot, index) => {
      return {
        ...timeSlot,
        capacity: timeSlots.value[index] || 0
      };
    });

    const newTimeSlots = (this.data.pageRef == 'create') ? calculateTimeSlots(startTime.value, endTime.value, timeSlotsWithCapacity) : calculateTimeSlotsMinuteWise(startTime.value, endTime.value, timeSlotsWithCapacity);

    this.setOccurrenceFormItems(newTimeSlots);

    // Clear Registrations
    this.totalRegistrations = 0;

    this.occurrenceTimeSlots = newTimeSlots.map((slot) => {
      const registrationsNum = getRegistrationsForTimePeriod(slot.startTime, slot.endTime, registrations);
      this.totalRegistrations += registrationsNum;
      return {
        startTime: slot.startTime,
        endTime: slot.endTime,
        registrations: registrationsNum,
      };
    });
  }

  /**
   * Replace old time slot array in the form with new time slots
   */
  private setOccurrenceFormItems(newTimeSlots: HourlyPoolScheduleItem[]): void {
    const { timeSlots } = this.occurrenceForm.controls;
    (timeSlots as UntypedFormArray).clear({ emitEvent: false });
    newTimeSlots.forEach((timeSlot) => {
      (timeSlots as UntypedFormArray).push(
        this.formBuilder.control(timeSlot.capacity, Validators.required),
        { emitEvent: false }
      );
    });

    timeSlots.updateValueAndValidity();
  }

  /**
   * Subscribe to control changes for side effects
   */
  private setSubscriptions(): void {
    const { timeSlots, startTime, endTime } = this.occurrenceForm.controls;

    // Restrict end time based on start time
    startTime.valueChanges
      .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
      .subscribe((value) => {
        this.utcEndTimes = removeUTCTimes(value, 'before');
        this.updateTimeSlots();
      });

    // Restrict start time based on end time
    endTime.valueChanges
      .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
      .subscribe((value) => {
        this.utcStartTimes = removeUTCTimes(value, 'after');
        this.updateTimeSlots();
      });

    // Recalculate total capacity
    timeSlots.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((capacities) => {
        this.totalCapacity = (capacities as number[]).reduce((total, current) => {
          return total + current;
        }, 0);
      });
  }

  /**
   * Returns true when the start or end time of the pool has changed
   */
  private hasDurationChanged(): boolean {
    const { startTime, endTime } = this.occurrenceForm.value;
    return startTime !== this.initialFormValues.startTime || endTime !== this.initialFormValues.endTime;
  }

  /**
   * Returns true when the restriction of the occurrence has changed
   */
  private hasRestrictionChanged(): boolean {
    const { isRestricted } = this.occurrenceForm.value;

    return isRestricted !== this.initialFormValues.isRestricted;
  }

  /**
   * Returns true when any of the capacities have changed from the initial values
   */
  private haveCapacitiesChanged(): boolean {
    const { timeSlots } = this.occurrenceForm.value;
    let capacitiesChanged = false;

    // This case should be handled by `hasDurationChanged` but double check here
    if (this.initialFormValues.timeSlots.length !== timeSlots.length) {
      return true;
    }

    for (const [index, timeSlot] of (timeSlots as number[]).entries()) {
      if (this.initialFormValues.timeSlots[index] !== timeSlot) {
        capacitiesChanged = true;
        break;
      }
    }
    return capacitiesChanged;
  }

  /**
   * Sets data for initial values for each time slot
   */
  private setInitialValues(): void {
    const { poolDetails, registrations: allRegistrations } = this.data;
    const { timeSlots, startTime, endTime } = this.occurrenceForm.controls;
    const schedule = this.existingOverride?.schedule ?? poolDetails.schedule;
    const { poolStartTime, poolEndTime } = getPoolStartEndTime(schedule.items);

    // Set all time slot values
    schedule.items.forEach((item: HourlyPoolScheduleItem) => {
      // Push capacity onto form
      (timeSlots as UntypedFormArray).push(
        this.formBuilder.control(item.capacity),
        { emitEvent: false }
      );

      // Calculate registrations for the current timeslot
      const registrations = getRegistrationsForTimePeriod(item.startTime, item.endTime, allRegistrations);

      // Push occurrence data onto occurrenceTimeSlots
      this.occurrenceTimeSlots.push({
        startTime: item.startTime,
        endTime: item.endTime,
        registrations,
      });

      // Update class values
      this.totalCapacity += item.capacity;
      this.totalRegistrations += registrations;
    });

    // Constrict start and end times based on start & end times
    this.utcStartTimes = removeUTCTimes(poolEndTime, 'after');
    this.utcEndTimes = removeUTCTimes(poolStartTime, 'before');

    // Create form with default values
    startTime.setValue(poolStartTime);
    endTime.setValue(poolEndTime);
  }
}

export const editOccurrenceDialogConfig = {
  ...VUE_DIALOG_CONFIG,
  panelClass: [...(VUE_DIALOG_CONFIG.panelClass || []), 'edit-occurrence-dialog'],
  width: '37.5rem',
};

export interface EditOccurrenceDialogInputs {

  /**
   * Title shown on top of dialog
   */
  dialogTitle: Observable<string>;

  /**
   * Occurrence date
   * Format: YYYY-MM-DD
   */
  date: string;

  pageRef:string;

  /**
   * Full pool details for the associated occurrence.
   */
  poolDetails: Pick<PoolDetails, 'schedule' | 'isRestricted' | 'scheduleOverrides' | 'daysOfWeekWithRestriction' | 'daysOfWeek'>;

  /**
   * Callback that is called if changes are made and to be applied to the occurrence
   */
  overrideCallback: (override: PoolScheduleOverride) => void;

  /**
   * Pool registration metrics at the 15min interval
   * Metrics are estimated within the add-pool-drawer,
   * any other use case should be passing the actual registrations assigned to the pool.
   */
  registrations: ContainsRegistrationMetric;
}
