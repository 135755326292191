import { AbstractControl, UntypedFormArray, ValidationErrors } from '@angular/forms';

/**
 * Validates that a form's percent allocation adds up to 100.
 * Marks the controls that exceed 100% as invalid.
 *
 * @param formArray FormArray of controls
 * @returns errors Validation errors, or null if passed
 */
export function percentAllocationValidator(formArray: AbstractControl): ValidationErrors | null {
  let total = 0;

  (formArray as UntypedFormArray).controls.forEach((control) => {
    const value = parseFloat(control.value || 0);
    total += value;

    // Once total is above 100, mark all following controls as invalid
    if (Number(total.toFixed(2)) > 100 && value) {
      control.setErrors({ 'over-allocated': value });
    } else {
      control.setErrors(null);
    }
  });

  /*
   * Round decimals to hundredths, avoid JavaScript math issues
   * Ex: 0.3 + 0.6 = 0.8999999999999999
   */
  total = Number(total.toFixed(2));

  if (isNaN(total)) {
    return { 'not-a-number': formArray.value };
  } else if (total < 100) {
    return { 'under-allocated': total };
  } else if (total > 100) {
    return { 'over-allocated': total };
  }
  return null;
}
