import { ChartDataset, Color } from 'chart.js';

/**
 * Create a bar dataset for a chart
 *
 * @param label the dataset label
 * @param xAxisID the X axis ID
 * @param colors a primary color or an array of colors (one for each data point) for display
 * @param data the data points to display
 * @param barThickness the thickness of each bar, in px
 * @returns dataset a bar dataset
 */
export function barDataset(
  label: string,
  xAxisID: string,
  colors: Color | Color[],
  data: ChartDataset<'bar'>['data'],
  barThickness = 24): ChartDataset<'bar'> {
  return {
    type: 'bar',
    label: label,
    xAxisID: xAxisID,
    data: data,
    backgroundColor: colors,
    hoverBackgroundColor: colors,
    hoverBorderColor: colors,
    barThickness,
    borderColor: colors,
    borderWidth: 1,
    stack: xAxisID, // datasets with the same `stack` will be shown stacked on top of each other, rather than overlap
  };
}
