<mat-radio-group
  [value]="value"
  (change)="changed($event)"
>
  <ng-container
    *ngFor="let item of items"
  >
    <mat-radio-button 
      [color]="color"
      [value]="item"
      [labelPosition]="labelPosition"
    >
      {{getItemDisplay(item)}}
    </mat-radio-button>
    <div
      *ngIf="value === item && item.selectedTemplateRef" 
      class="radio-selected-content"
    >
      <ng-template [ngTemplateOutlet]="item.selectedTemplateRef"></ng-template>
    </div>
  </ng-container>
</mat-radio-group>
