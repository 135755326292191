<form [formGroup]="scheduleForm" autocomplete="off">
  <h4>Time</h4>
  <div class="two-columns">
    <vue-select
      label="{{ 'label.placeholder.startTime' | translate | async }}"
      formControlName="startTime"
      [required]="true"
      [options]="utcStartTimes"
      [getOptionDisplay]="addUTCToTime.bind(this)"
      [errorMatcher]="suppressErrorState"
    ></vue-select>
    <vue-select
      label="{{ 'label.placeholder.endTime' | translate | async }}"
      formControlName="endTime"
      [required]="true"
      [options]="utcEndTimes"
      [getOptionDisplay]="addUTCToTime.bind(this)"
      [errorMatcher]="suppressErrorState"
    ></vue-select>
  </div>
  <div class="capacity">
    <h4>
      {{ 'title.capacity' | translate | async }}
    </h4>
    <p *ngIf="!scheduleForm.controls.startTime.value || !scheduleForm.controls.endTime.value">
      {{ 'message.addPool.capacity.instructions' | translate | async }}
    </p>
  </div>
  <div class="capacity-columns">
    <ng-container
      formArrayName="items"
      class="capacity-slot"
      *ngFor="let capacity of capacityItems; let i=index">
      <label [for]="capacity.startTime">
        {{capacity.startTime}} – {{addUTCToTime(capacity.endTime)}}
      </label>
      <vue-numeric-input
        [id]="capacity.startTime"
        [formControl]="getItemControl(i)"
        [errorMatcher]="suppressErrorState"
        [compact]="true"
        [maxLength]="5"
      ></vue-numeric-input>
    </ng-container>
    <ng-container class="capacity-columns total-container">
      <p>
        {{ 'title.totalCapacity' | translate | async }} <br />
        <small>{{ 'title.appointmentsPerDay' | translate | async }}</small>
      </p>
      <p class="total">{{totalCapacity}}</p>
    </ng-container>
  </div>
</form>
