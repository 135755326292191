import { Pipe, PipeTransform } from '@angular/core';
import { take } from 'rxjs/operators';
import { PoolInfo } from 'api/types';
import { TranslatePipe } from './translate.pipe';

/**
 * Client display logic for a pool when displayed in a table
 * Displays single client or "Multiple"
 */
@Pipe({
  name: 'poolClientDisplay'
})
export class PoolClientDisplayPipe implements PipeTransform {
  private multipleTranslated = ''

  public constructor(translatePipe: TranslatePipe) {
    translatePipe.transform('label.multiple')
      .pipe(take(1))
      .subscribe((translation) => {
        this.multipleTranslated = translation;
      });
  }

  public transform(pool: PoolInfo): string | number {
    if (pool.clients?.length) {
      return pool.clients.length > 1 ? this.multipleTranslated +
       ' (' + pool.clients.length + ')' : pool.clients[ 0 ].name;
    }
    return '';
  }
}
