<div>
  <div class="filter-wrapper">
    <div class="search-filter-wrapper">
      <vue-filter-search
        placeholder="{{ 'label.placeholder.searchPoolNames' | translate | async }}"
        (searchValueChange)="updateSearchParams($event)"
        [inputValue]="initialSearchTerm"
        [invalidRegex]="invalidRegex"
      ></vue-filter-search>
    </div>
    <app-filter-multi-select
      placeholder="{{ 'title.status' | translate | async }}"
      [includeOptionSearch]="false"
      [options]="statusOptions"
      (change)="statusSelectionUpdated($event)">
    </app-filter-multi-select>
    <app-client-select-filter
      (clientsSelected)="clientSelectionUpdated($event)"
    ></app-client-select-filter>
    <app-more-filters></app-more-filters>
  </div>
  <app-filter-by
   [filters]="selectedFilters"
   (filterRemoved)="filterRemoved($event)"
  ></app-filter-by>
</div>
