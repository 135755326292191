<div class="wrapper">
  <div class="month-header">
    <p><b>{{ getMonthDisplay() }}</b></p>
    <p>{{ "title.utilization" | translate | async }}: <b>{{ monthlyMetricItems.utilization }}</b></p>
    <p>{{ "title.capacity" | translate | async }}: <b>{{ monthlyMetricItems.capacity | number }}</b></p>
    <p>{{ "title.registrations" | translate | async }}: <b>{{ monthlyMetricItems.registrations | number }}</b></p>
  </div>
  <div class="week-and-day-wrapper">
    <div class="week-data">
      <div class="box-above-week-data" aria-hidden="true"></div>
      <app-calendar-item
        *ngFor="let item of weeklyMetricItems"
        [isWeek]="true"
        [metricItem]="item"
      ></app-calendar-item>
    </div>
    <div class="daily-grid">
      <p class="week-day-header">{{ "label.dayOfWeek.sunday" | translate | async }}</p>
      <p class="week-day-header">{{ "label.dayOfWeek.monday" | translate | async }}</p>
      <p class="week-day-header">{{ "label.dayOfWeek.tuesday" | translate | async }}</p>
      <p class="week-day-header">{{ "label.dayOfWeek.wednesday" | translate | async }}</p>
      <p class="week-day-header">{{ "label.dayOfWeek.thursday" | translate | async }}</p>
      <p class="week-day-header">{{ "label.dayOfWeek.friday" | translate | async }}</p>
      <p class="week-day-header">{{ "label.dayOfWeek.saturday" | translate | async }}</p>
      <app-calendar-item
        *ngFor="let item of dailyMetricItems"
        [metricItem]="item"
      ></app-calendar-item>
    </div>
  </div>
</div>
