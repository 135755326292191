import { AfterViewInit, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddPoolState } from 'components/drawers/add-pool-drawer/add-pool-state/add-pool-state.service';
import { PoolNameErrorHandler } from 'components/drawers/add-pool-drawer/pool-name-error-handler/pool-name-error-handler.class';
import { TranslationKey } from 'pipes/translate.pipe';
import { AddAlertPoolDataService } from 'services/add-alert-pool-data.service';
import {  invalidCharactersValidator } from 'utils/validators/invalid-characters.validator';

@Component({
  selector: 'app-edit-pool-consumption-form',
  templateUrl: './edit-pool-consumption-form.component.html',
  styleUrls: ['./edit-pool-consumption-form.component.scss']
})
export class EditPoolConsumptionFormComponent implements OnInit ,AfterViewInit{
  subscribeData: any = null
  checkformValidation:any
  editPoolconsumptionform!: FormGroup
  @Output() getAlertCOnsumption = new EventEmitter()
  @Output() getformStatus = new EventEmitter()
  constructor(public fb: FormBuilder, private addAlertConsumptionCreate: AddAlertPoolDataService,private addPoolState: AddPoolState) { }
  ngOnInit(): void {
    this.getformStatus.emit(false)
    this.editPoolconsumptionform = this.fb.group({
      getDatafromAlert: ['', [Validators.required, invalidCharactersValidator]],
      search_pool: ['', [Validators.required]],
      Threshold: ['', [Validators.required]],
      run_Frequency: ['', [Validators.required]],
    });
    this.addAlertConsumptionCreate.getSubscribeforEditpoolata$.subscribe((ele: any) => {
      this.subscribeData = ele;
      this.editPoolconsumptionform.controls.getDatafromAlert.patchValue(this.subscribeData[0].name);
    });
    this.editPoolconsumptionform.valueChanges.subscribe((ele: any) => {
    if (this.editPoolconsumptionform.get('getDatafromAlert')?.valid == true && this.editPoolconsumptionform.get('search_pool')?.value?.status == true && this.editPoolconsumptionform.get('Threshold')?.value?.status
      && this.editPoolconsumptionform.get('run_Frequency')?.value?.status == true) {
      this.getAlertCOnsumption.emit(this.editPoolconsumptionform.value);
    } else {
      this.getAlertCOnsumption.emit(null);
    }
  })
}

  ngAfterViewInit(): void {
    this.checkformValidation=this.editPoolconsumptionform.getRawValue();
    this.editPoolconsumptionform.valueChanges.subscribe((ele: any) => {
        if (JSON.stringify(this.checkformValidation) !== JSON.stringify(this.editPoolconsumptionform.getRawValue())) {
          this.getformStatus.emit(true);
        }else {
          this.getformStatus.emit(false);  
        }
    })
    
  }

  public get poolNameErrorMatcher(): PoolNameErrorHandler {
    return this.addPoolState.poolNameErrorHandler;
  }

  public poolNameErrorMessageKey(): TranslationKey {
    return 'error.invalidCharacter';
  }

  getrawFormData() {
    return this.editPoolconsumptionform.getRawValue();
  }

 
}
