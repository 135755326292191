import { Directive, ViewChild } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';

/**
 *  Reusable table with client-side sorting of columns
 */
@Directive()
export abstract class ClientSideSortableTable<RowDataType, SortByType> {
  /**
   * Reference to Material Table element
   */
  @ViewChild(MatTable) public table!: MatTable<RowDataType>;

  /** Array of data that populates the table.*/
  public tableData: RowDataType[] = [];

  /** The column that is currently sorted */
  public sortBy: SortByType;

  /** Sorted direction of the table */
  public sortDirection: SortDirection = 'asc';

  public constructor(defaultSortBy: SortByType) {
    this.sortBy = defaultSortBy;
  }

  /**
   * Returns text to be used in aria labels for sort columns
   *
   * @returns ascending or descending based on sorted order
   */
  public getSortAriaText(): 'ascending' | 'descending' {
    return this.sortDirection === 'asc' ? 'ascending' : 'descending';
  }

  /**
   * Sort and rebuild table based on sort column
   *
   * @param column to be sorted
   */
  public sort(column: SortByType): void {
    this.toggleSortOrder(column);
    this.sortByColumn(column, this.sortDirection);
    this.sortBy = column;
    this.table.renderRows();
  }

  /**
   * Update sort direction.  On first clicking a header, the sort will be ascending.
   * If the same header is clicked again, the sort is toggled.
   *
   * @param column to be sorted
   */
  private toggleSortOrder(column: SortByType): void {
    let direction: SortDirection = 'asc';
    if (column === this.sortBy) {
      direction = this.sortDirection === 'asc' ? 'desc' : 'asc';
    }

    this.sortDirection = direction;
  }

  /**
   * Sorts table data by input column.
   * Sort should result in an _ascending_ order. This superClass will reverse the order when order is descending.
   *
   * @param column to be sorted
   * @param sortDirection direction to sort
   */
  public abstract sortByColumn(column: SortByType, sortDirection: SortDirection): void;
}
