import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, timer } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

export interface SubOption {
  name: string;
  route: string;
  subSubOptions?: SubSubOption[];
}

export interface SubSubOption {
  name: string;
  route: string;
}

export interface MenuOption {
  name: string;
  route: string;
  subOptions?: SubOption[];
}

export type MenuOptions = MenuOption[];

/**
 * Nav drawer that fixes to the left side and can be expanded and collapsed.
 */
@Component({
  selector: 'vue-nav-drawer',
  templateUrl: './vue-nav-drawer.component.html',
  styleUrls: [ './vue-nav-drawer.component.scss' ],
})
export class VueNavDrawerComponent implements OnInit, OnDestroy, AfterViewInit {
  /**
   * List of navigation elements to display
   */
  @Input() public menuOptions: MenuOptions = [];

  /**
   * Reference to the side nav element
   */
  @ViewChild('sidenav') public sidenav!: MatSidenav;

  /**
   * Reference to the side nav content element
   */
  @ViewChild('content') public content?: MatSidenavContent;

  /**
   * Current selected route
   */
  public selectedRoute = '';

  /**
   * Enables the CSS transitions for the fixed toggle section.
   *
   * Initially disabled so the section doesn't animate on first render.
   */
  public enableTransitions = false;

  /**
   * Subject completes when component is destroyed
   */
  private destroyed$ = new Subject();

  public constructor(private router: Router) {}

  /**
   * Subscribe to router events
   */
  public ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroyed$)
      )
      .subscribe((event) => {
        this.selectedRoute = (event as NavigationEnd).urlAfterRedirects || '';
      });
  }

  /**
   * Enable toggle section transitions,
   * wait a tick for initial render to complete
   */
  public ngAfterViewInit(): void {
    timer(0).pipe(take(1)).subscribe(() => {
      this.enableTransitions = true;
    });
  }

  /**
   * Terminate subscriptions
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Toggles the sidenav to open or close
   * Triggers a resize event for sidenav content can resize if needed
   */
  public toggle(): void {
    this.sidenav.toggle();

    // Dispatching resize event so content can resize if needed
    window.dispatchEvent(new Event('resize'));
  }

  /**
   * Determines if the specified option is selected
   *
   * @param option current option
   * @returns true if option is selected
   */
  public isSelectedRoute(option: MenuOption | SubOption): boolean {
    if ('subOptions' in option) {
      return this.selectedRoute === option.route && (option.subOptions || []).length === 0;
    }

    if ('subSubOptions' in option) {
      return this.selectedRoute === option.route && (option.subSubOptions || []).length === 0;
    }

    return this.selectedRoute === option.route;
  }

  /**
   * Calculate positioning for fixed toggled section
   *
   * Angular Material maintains the margin for the content automatically,
   * reference that rather than calculating the width of the sidenav manually.
   */
  public toggleSectionStyle(): Partial<CSSStyleDeclaration> {
    if (document.dir === 'rtl') {
      return {
        right: this.content?.getElementRef().nativeElement.style.marginRight ?? '0'
      };
    }

    return {
      left: this.content?.getElementRef().nativeElement.style.marginLeft ?? '0'
    };
  }
}
