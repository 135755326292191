import { Injectable, OnDestroy } from '@angular/core';
import { GetOutageQueryParameters, GetOutageResponse } from 'api/types';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { DEFAULT_VALUES, OutageFiltersService } from './outage-filters.service';
import { OutagesService } from './api/outages.service';
import { PageStatusService } from './status/page-status.service';
import { switchMap, takeUntil, distinctUntilChanged } from 'rxjs/operators';


const emptyResponse: GetOutageResponse = {
  ...DEFAULT_VALUES,
  total: 0,
  items: []
};

@Injectable({
  providedIn: 'root'
})
export class OutagePageDataService implements OnDestroy {

  /**
   * Fill initial response with empty values, filter component will populate on initialization
   * Private BehaviorSubject so only this service can alter the value
   */
  private readonly outageResponse = new BehaviorSubject<GetOutageResponse>(emptyResponse);

  /**
  * Private BehaviorSubject so only this service can alter the value
  */
  private readonly fetchingOutage = new BehaviorSubject(false);

  /**
  * Observable that emits the current state of the outage data
  */
  public outageResponse$ = this.outageResponse.asObservable();

  /**
   * Observable that emits a boolean if outages are being fetched
   */
  public fetchingOutage$ = this.fetchingOutage.asObservable();

  /**
   * Terminates all subscriptions when completed
   */
  private destroyed$ = new Subject();

  /**
   * Emits when new outage data should be fetched
   */
  private fetchOutageData$ = new Subject<GetOutageQueryParameters>();

  constructor(
    private outageFilterService: OutageFiltersService,
    private outageService: OutagesService,
    private pageStatusService: PageStatusService,
  ) {
    this.fetchOutageData$
      .pipe(takeUntil(this.destroyed$))
      .pipe(switchMap((params) => { // switchMap will cancel any in-flight API requests
        this.fetchingOutage.next(true);
        return this.fetchOutage(params);
      }))
      .subscribe((res) => {
        this.outageSuccessHandler(res);
      }, () => {
        this.outageErrorHandler();
      });

    // Update outage data whenever filters change
    this.outageFilterService.params$
      .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
      .subscribe((filters) => {
        if (filters.interval) {
          this.fetchOutageData$.next(filters);
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }


  /**
   * Gets the outage data
   *
   * @param params params to pass to service
   */
  public fetchOutage(params: GetOutageQueryParameters): Observable<GetOutageResponse> {
    this.fetchingOutage.next(true);

    this.pageStatusService.loading(() => {
      this.fetchOutageData$.next(params);
    });
    // transfer params
    return this.outageService.getOutages(params);
  }

  /**
   * GetOutage success handler
   */
  private outageSuccessHandler(response: GetOutageResponse): void {
    this.outageResponse.next(response);
    this.fetchingOutage.next(false);
    this.pageStatusService.success();
  }

  /**
   * GetOutage error handler
   */
  private outageErrorHandler(): void {
    this.pageStatusService.error();
    this.fetchingOutage.next(false);
  }

}
