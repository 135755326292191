<vue-table-container>
  <table mat-table [dataSource]="paginatedTableData">
    <caption class="visually-hidden">
      {{ 'table.caption.occurrences.sortable' | translate | async }}
    </caption>

    <!-- Occurrence Date -->
    <ng-container matColumnDef="date">
      <th
        mat-header-cell
        *matHeaderCellDef
        [tableHeaderAriaSort]="{sortDirection, isSorted: sortBy === 'date' }"
      >
        <app-sortable-table-header
          [tableHasData]="!!paginatedTableData.length"
          (sortColumn)="sort('date')"
        >
          {{ 'title.date' | translate | async }}
        </app-sortable-table-header>
      </th>
      <mat-cell *matCellDef="let occurrence">
        {{displayLocalizedDate(occurrence.date)}}
      </mat-cell>
    </ng-container>

    <!-- Occurrence Status -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>
        {{ "title.status" | translate | async }}
      </th>
      <mat-cell *matCellDef="let occurrence">
        <app-pool-status
          [status]="occurrence.status"
        ></app-pool-status>
      </mat-cell>
    </ng-container>

    <!-- Occurrence Time -->
    <ng-container matColumnDef="time">
      <th
        mat-header-cell
        *matHeaderCellDef
        [tableHeaderAriaSort]="{sortDirection, isSorted: sortBy === 'time' }"
      >
        <app-sortable-table-header
          [tableHasData]="!!paginatedTableData.length"
          (sortColumn)="sort('time')"
        >
          {{ 'title.time.utc' | translate | async }}
        </app-sortable-table-header>
      </th>
      <mat-cell *matCellDef="let occurrence">
        {{occurrence.startTime}} – {{occurrence.endTime}}
      </mat-cell>
    </ng-container>

    <!-- Occurrence Restriction -->
    <ng-container matColumnDef="restriction">
      <th
        mat-header-cell
        *matHeaderCellDef
        [attr.aria-label]="'title.restriction' | translate | async"
      >
        {{ "title.restricted.abbreviated" | translate | async }}
      </th>
      <mat-cell *matCellDef="let occurrence">
        <mat-icon matTooltip="{{ getOccurrenceRestrictionToolTip() | async }}" style="cursor: pointer;margin-left: -4.2px;" *ngIf="occurrenceDateHasPassed(occurrence.date) && !occurrence.isViewRestriction && !occurrence.isLoading"
          (click)="clickViewRestriction(occurrence)">
          autorenew
        </mat-icon>
        <mat-spinner *ngIf="occurrence.isLoading" diameter="18"></mat-spinner>
        <img *ngIf="!occurrenceDateHasPassed(occurrence.date) || occurrence.isViewRestriction"
          src="/assets/icons/icon-{{occurrence.isRestricted ? 'lock' : 'unlock'}}.svg"
          alt="{{ getRestrictionAriaLabel(occurrence) | async }}"
        >
        <vue-image-tooltip *ngIf="occurrence.isOverride && !occurrenceDateHasPassed(occurrence.date) && !occurrence.isReleased" imageSrc="/assets/icons/icon-exclamation.svg"
        content="{{ 'tooltip.message.override' | translate | async }}"></vue-image-tooltip>
      </mat-cell>
    </ng-container>

    <!-- Occurrence Registration / Capacity -->
    <ng-container matColumnDef="capacity">
      <th
        mat-header-cell
        *matHeaderCellDef
        [attr.aria-label]="('title.registrations' | translate | async) + ' / ' + ('title.capacity' | translate | async)"
      >
        {{ "title.registrations.abbreviated" | translate | async }} / {{ "title.capacity" | translate | async }}
      </th>
      <mat-cell *matCellDef="let occurrence">
        {{occurrence | poolOccurrenceDisplay:'capacity'}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="availability">
      <th mat-header-cell *matHeaderCellDef [attr.aria-label]="('title.availability' | translate | async)">
        {{ 'title.availability' | translate | async }}
      </th>
      <mat-cell *matCellDef="let occurrence">
        {{ occurrence | poolOccurrenceDisplay:'availability'}}
      </mat-cell>
    </ng-container>

    <!-- Occurrence Utilization -->
    <ng-container matColumnDef="utilization">
      <th
        mat-header-cell
        *matHeaderCellDef
        [tableHeaderAriaSort]="{sortDirection, isSorted: sortBy === 'utilization' }"
      >
        <app-sortable-table-header
          [tableHasData]="!!paginatedTableData.length"
          (sortColumn)="sort('utilization')"
        >
          {{ 'title.utilization' | translate | async }}
        </app-sortable-table-header>
      </th>
      <mat-cell *matCellDef="let occurrence">
        {{occurrence | poolOccurrenceDisplay:'utilization'}}
      </mat-cell>
    </ng-container>

    <!-- Occurrence menu -->
    <ng-container matColumnDef="menu">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'table.heading.actions' | translate | async }}
      </th>
      <mat-cell *matCellDef="let occurrence; let i = index;">
        <app-occurrence-menu
          *ngIf="isOccurrenceEditable(occurrence)"
          [poolDate]="occurrence.date"
          [pool]="occurrence"
          [hideViewDetails]="true"
          [registrations]="getRegistrationsForDate(occurrence.date)"
          [isOverride]="occurrence.isOverride"
          (occurrenceUpdated)="occurrenceUpdated(occurrence.date)"
          (occurrenceReleaseChange)="occurrenceUpdated(occurrence.date)"
          (occurrenceRemoved)="occurrenceUpdated()"
          (applyDefaultPoolSettings)="occurrenceUpdated(occurrence.date)"
        ></app-occurrence-menu>
      </mat-cell>
    </ng-container>

    <!-- Populate table with data -->
    <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: tableColumns;"
      [ngClass]="{'released': row.isReleased, 'override': row.isOverride && !occurrenceDateHasPassed(row.date)}"
      [appBackgroundFade]="row.toggleBackgroundFade"
    ></mat-row>

    <tr class="mat-row no-content" *matNoDataRow>
      <td class="mat-cell" colspan="9999">
        {{ 'message.poolOccurrence.noOccurrences' | translate | async }}
      </td>
    </tr>
  </table>

  <mat-paginator
    *ngIf="paginatedTableData.length !== 0"
    [length]="tableData.length"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    previousPageIndex="0"
    [pageSizeOptions]="pageSizeOptions"
    [showFirstLastButtons]="true"
    (page)="paginatorHandler($event)"
  ></mat-paginator>
</vue-table-container>
