<vue-flat-button (click)="drawer.open()">{{ 'label.Create' | translate | async }}</vue-flat-button>
<vue-drawer #drawer [contentRef]="addAlert" [drawerConfig]="drawerConfig">
  <ng-template #addAlert>
    <app-drawer-header class="header" (close)="closeDrawerbtn()">{{ 'label.alertsconfiguration' | translate | async }}
    </app-drawer-header>
    <div matDialogContent class="mat-typography">
      <app-error-message *ngIf="status === 'error'" [heading]="displayableServerError?.title"
        [message]="displayableServerError?.message"></app-error-message>
      <app-add-pool-consumption-form (getAlertCOnsumption)="getdatafromchild($event)"
        (getformStatus)="getStatusofchild($event)"></app-add-pool-consumption-form>
    </div>
    <br>
    <div matDialogActions>
      <ng-container>
        <vue-basic-button (click)="closeDrawerbtn()" [disabled]="this.disabledAtresponsegetting">{{ "button.cancel" |
          translate | async }}
        </vue-basic-button>
        <vue-flat-button [disabled]="this.getDataForCreateAlert == null ? true : false " (click)="SavePool()"
          [loading]="status === 'loading'">{{ "button.save" | translate | async }}
        </vue-flat-button>
      </ng-container>
    </div>
  </ng-template>
</vue-drawer>