<mat-sidenav-container class="wrapper" hasBackdrop="false">
  <mat-sidenav #sidenav mode="side" role="navigation" opened="true" fixedInViewport>
    <div class="option-section-wrapper" *ngFor="let option of menuOptions">
      <a class="option" [routerLink]="option.route" [class.selected]="isSelectedRoute(option)">
        {{ option.name }}
      </a>
      <div class="sub-option-section-wrapper" *ngFor="let subOption of option.subOptions">
        <a class="sub-option" [ngStyle]="{'font-weight': subOption?.subSubOptions?.length ? '700' : 'normal'}" routerLinkActive="active" [routerLink]="subOption.route"
          [routerLinkActiveOptions]="{ exact: true }" [class.selected]="isSelectedRoute(subOption)">
          {{ subOption.name }}
        </a>
        <div class="sub-option-section-wrapper" *ngFor="let subSubOption of subOption.subSubOptions">
          <a class="sub-sub-option" routerLinkActive="active" [routerLink]="subSubOption.route"
            [routerLinkActiveOptions]="{ exact: true }" [class.selected]="isSelectedRoute(subSubOption)">
            {{ subSubOption.name }}
          </a>
        </div>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content #content class="sidenav-content-wrapper" scrollable>
    <div class="toggle-sidenav-section" [class.enable-transitions]="enableTransitions"
      [style]="sidenav.opened ? toggleSectionStyle() : ''">
      <button class="toggle-sidenav-button" (click)="toggle()"
        attr.aria-label="{{ (sidenav.opened ? 'label.aria.closeNavMenu' : 'label.aria.openNavMenu') | vueTranslate | async }}">
        <img [ngClass]="sidenav.opened ? '' : 'collapsed'" src="/assets/icons/icon-side-arrow.svg" alt="" />
      </button>
    </div>
    <div class="ng-content-wrapper">
      <ng-content></ng-content>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>