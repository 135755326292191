import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SortDirection } from '@angular/material/sort';

/**
 * Sortable table header component.  Mostly presentational; callers implement the sorting and manage state.
 */
@Component({
  selector: 'app-sortable-table-header',
  templateUrl: './sortable-table-header.component.html',
  styleUrls: [ './sortable-table-header.component.scss' ]
})
export class SortableTableHeaderComponent {
  /**
   * Sort direction
   */
  @Input() public sortDirection!: SortDirection;

  /**
   * Boolean to show the sorting arrow
   */
  @Input() public isSorted!: boolean;

  /**
   * When the table doesn't have data, only the text will be displayed.
   * This prevents the user from sorting data that isn't there.
   */
  @Input() public tableHasData!: boolean;

  /**
   * Event emitter when a column should be sorted
   */
  @Output() public sortColumn = new EventEmitter<void>();
}
