<button
  class="menu-trigger"
  mat-button
  vueTableMenuOpen
  [attr.aria-label]="'label.poolOccurrence.dialogActionsMenu'| translate:displayLocalizedDate(date) | async"
  [matMenuTriggerFor]="menu"
>
  <mat-icon>more_horiz</mat-icon>
</button>
<mat-menu #menu="matMenu" class="occurrence-menu">
  <button mat-menu-item *ngIf="!isReleased && isOverride && !occurrenceDateHasPassed" (click)="applyPoolSettings()">{{ "label.poolOccurrence.applypoolsettings" | translate | async }}</button>
  <button mat-menu-item *ngIf="!isReleased" (click)="openOccurrenceEdit()">{{ "label.poolOccurrence.edit" | translate | async }}</button>
  <button *ngIf="!isReleased" mat-menu-item (click)="releaseOccurrence()">{{ "label.poolOccurrence.release" | translate | async }}</button>
  <button *ngIf="isReleased" mat-menu-item (click)="unreleaseOccurrence()">{{ "label.poolOccurrence.unrelease" | translate | async }}</button>
  <button mat-menu-item (click)="removePoolOccurrence()">
    {{ "button.remove" | translate | async }}
  </button>
</mat-menu>
