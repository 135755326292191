import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject, Subscription, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'services/authentication/auth.service';

/**
 * Dialog component to display a warning after user has been inactive.
 * Counts down from WARNING_DURATION_SECONDS, then logs the user out.
 * The user can cancel the countdown by clicking the "continue" button.
 */
@Component({
  selector: 'app-inactivity-dialog',
  templateUrl: './inactivity-dialog.component.html',
})
export class InactivityDialogComponent implements OnInit, OnDestroy {
  /**
   * How many seconds to display the warning dialog before logging the user out.
   */
  public static WARNING_DURATION_SECONDS = 60;

  /**
   *  One second in millis
   */
  public static ONE_SECOND_MILLIS = 1000;

  /**
   * The main message displayed in the dialog
   */
  public formattedTimeRemaining = '<b>0:60</b>';

  /**
   * How many seconds remain in the countdown before logging out
   */
  public secondsRemaining = InactivityDialogComponent.WARNING_DURATION_SECONDS;

  /**
   * Reference to the countdownTimer subscription;
   */
  private countdownTimer?: Subscription;

  private destroyed$ = new Subject();

  public constructor(
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) private data: InactivityDialogComponentData,
  ) {
  }

  public ngOnInit(): void {
    this.startCountdown();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Logout button clicked
   */
  public logoutClicked(): void {
    this.logout();
  }

  /**
   * Continue button clicked
   */
  public continueClicked(): void {
    this.data.continueClicked();
  }

  /**
   * Start counting down the seconds until the user will be automatically logged out
   */
  private startCountdown(): void {
    if (this.countdownTimer) {
      this.countdownTimer.unsubscribe();
    }
    this.countdownTimer = timer(
      InactivityDialogComponent.ONE_SECOND_MILLIS, InactivityDialogComponent.ONE_SECOND_MILLIS
    ).pipe(takeUntil(this.destroyed$)).subscribe(() => {
      if (this.secondsRemaining > 0) {
        this.secondsRemaining -= 1;
        this.formattedTimeRemaining = this.formatTimeRemaining();
      } else {
        this.logout();
      }
    });
  }

  /*
   * Because the TranslatePipe is async and the "seconds" display requires replacing part of the string,
   * we use the raw translation string and replace the "seconds" part on the fly
   */
  private formatTimeRemaining(): string {
    // Prefix single-digit with zero, wrap in `<b>`
    let formattedSeconds = String(this.secondsRemaining);
    if (formattedSeconds.length === 1) {
      formattedSeconds = `0${formattedSeconds}`;
    }
    return `<b>0:${formattedSeconds}</b>`;
  }

  private logout(): void {
    this.authService.logout();
  }
}

export interface InactivityDialogComponentData {
  continueClicked(): void;
}
