<vue-page-header appName="{{ 'title.applicationName' | translate | async }}" pageTitle="{{ 'title.outages' | translate | async }}"
navSection="{{ 'title.outages' | translate | async }}">
</vue-page-header>
<app-page-body>
  <app-error-message *ngIf="status === 'error'" [heading]="displayableServerError?.title"
    [message]="displayableServerError?.message">
  </app-error-message>
  <app-filter-container>
    <app-outages-filters></app-outages-filters>
    <vue-flat-button (clicked)="addOutages()">{{
      'title.addOutage' | translate | async
      }}</vue-flat-button>
  </app-filter-container>
  <vue-section>
    <app-outages-table></app-outages-table>
  </vue-section>
</app-page-body>
<!-- <vue-toast heading="{{ toastHeadingKey | translate | async }}"  icon="success">
  <span toast-body>{{ toastMessageKey | translate | async }}</span>
</vue-toast> -->
<vue-toast icon="success">
  <span toast-body>{{ toastMessage }}</span>
</vue-toast>