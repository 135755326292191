<app-base-chart-popover [title]="title">
  <app-chart-elements-details [details]="details"></app-chart-elements-details>
  <ng-container
    *ngIf="!showWeeklyActions"
    popover-action
  >
    <button (click)="openDownloadDialog()">
      {{ 'button.downloadCandidates' | translate | async }}
    </button>
  </ng-container>
  <ng-container
    *ngIf="showWeeklyActions"
    popover-action
  >
    <app-appt-weekly-popover-actions
      [timestamp]="timestamp"
    ></app-appt-weekly-popover-actions>
  </ng-container>
</app-base-chart-popover>
