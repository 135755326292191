import { AfterContentInit, Component, Input, OnInit, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormArray, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import moment from 'moment';

@Component({
  selector: 'app-add-pool-consumption-runfrequency',
  templateUrl: './add-pool-consumption-runfrequency.component.html',
  styleUrls: ['./add-pool-consumption-runfrequency.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AddPoolConsumptionRunfrequencyComponent),
    multi: true
  }]
})

export class AddPoolConsumptionRunfrequencyComponent implements ControlValueAccessor, OnInit, AfterContentInit {
  @Input() editformData: any;
  @Input() copyAlertFormData: any;
  private onTouched = (value: any) => { };
  private onChanged = (value: any) => { };
  maxnumber=10
  addrunFrequencydata: any[] = [{ "value": "sunday", "checked": false }, { "value": "monday", "checked": false },
  { "value": "tuesday", "checked": false },
  { "value": "wednesday", "checked": false },
  { "value": "thursday", "checked": false },
  { "value": "friday", "checked": false },
  { "value": "saturday", "checked": false }]
  option: any = ['Yes', 'No']
  disabled = false;
  updatedtooltip='Maximum of 10 Run-frequency allowed'
  addPoolconsumptionrunfrequency!: FormGroup;
  patchData: any[] = [];
  priorityOption: any = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']
  public translations: { [key: string]: string } = {};
  getStoreWeekdaysobj: any[] = [];
  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.addPoolconsumptionrunfrequency = this.fb.group({
      Time: this.fb.array([this.updatevalueInFFormarray()]),
    });
    this.addPoolconsumptionrunfrequency.valueChanges.subscribe((ele: any) => {
      this.updaedTimeData(ele);
      this.onChanged(ele);
    });
    this.addPoolconsumptionrunfrequency.statusChanges.subscribe(() => {
      this.onChanged(this.addPoolconsumptionrunfrequency.valid ? { 'FormGroup': this.addPoolconsumptionrunfrequency.value, 'status': this.addPoolconsumptionrunfrequency.valid } : false)
    });
    const updatedDatainWeekDaysList = this.runFrequrncyGetter.at(0);
    this.getValidation(updatedDatainWeekDaysList.get('weekDays'), 0)
    if (this.editformData) {
      this.markAllAsTouched();
      this.getutcData();
      this.editformData[0].runFrequency.items.forEach((ele: any, index: any) => {
        if (this.runFrequrncyGetter != undefined) {
          this.runFrequrncyGetter.at(index).get('weekDays')?.value.forEach((data: any, index: any) => {
            ele.runDaysOfWeek.forEach((elem: any) => {
              if (data.value == elem) {
                data.checked = true;
              }
            })
          })
          this.runFrequrncyGetter.at(index).patchValue({
            Time: ele.runTime, Priority: ele.runPriority.toString(), Critical: ele.isCritical == 0 ? "No" : "Yes"
            , weekSelection: 'weekly'
          });
          this.getValidation(this.runFrequrncyGetter.at(index).get('weekDays'), index)
          if (this.editformData[0].runFrequency.items.length - 1 > index) {
            this.pushDatainGetter();
          }
        }
      })
    }
    this.getCopyconsumptionData();
  }

  ngAfterContentInit() {
    if (this.editformData != undefined || this.copyAlertFormData != undefined) {
      this.onChanged(this.addPoolconsumptionrunfrequency.valid ? { 'FormGroup': this.addPoolconsumptionrunfrequency.value, 'status': this.addPoolconsumptionrunfrequency.valid } : false)
    }
  }

  writeValue(selected: any) { }

  registerOnChange(fn: any) {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  updatevalueInFFormarray() {
    return this.fb.group({
      Time: ['', [Validators.required]],
      Priority: ['', [Validators.required]],
      Critical: ['', [Validators.required]],
      weekDays: [this.addrunFrequencydata, [Validators.required]],
      weekSelection: ['weekly', [Validators.required]],
    })
  }

  get runFrequrncyGetter() {
    return this.addPoolconsumptionrunfrequency.get('Time') as FormArray;
  }
  pushDatainGetter() {
    this.getStoreWeekdaysobj = []
    this.runFrequrncyGetter.push(this.updatevalueInFFormarray());
    const addUpdtedValueinrunfreq = this.runFrequrncyGetter.at(this.runFrequrncyGetter.length - 1);
    addUpdtedValueinrunfreq.get('weekDays')?.setValue([{ "value": "sunday", "checked": false }, { "value": "monday", "checked": false }, { "value": "tuesday", "checked": false }, { "value": "wednesday", "checked": false }, { "value": "thursday", "checked": false }, { "value": "friday", "checked": false }, { "value": "saturday", "checked": false }])
    this.getValidation(this.runFrequrncyGetter.at(this.runFrequrncyGetter.length - 1)?.get('weekDays'), this.runFrequrncyGetter.length - 1)
  }

  markAllAsTouched() {
    this.runFrequrncyGetter.controls.forEach(control => {
      const group = control as FormGroup
      Object.values(group.controls).forEach(field => {
        if (field) {
          field.markAllAsTouched()
          field.markAsDirty()
        }
      })
    })
  }

  removeRunfrequencyListitems(i: number) {
    if (this.runFrequrncyGetter.length > 1) {
      this.runFrequrncyGetter.removeAt(i);
    } else {
      this.runFrequrncyGetter.at(0)?.get('Critical')?.reset()
      this.runFrequrncyGetter.at(0)?.get('Priority')?.reset()
      this.runFrequrncyGetter.at(0)?.get('Time')?.reset()
      const checkValueinrunfreq = this.runFrequrncyGetter.at(0);
      checkValueinrunfreq.get('weekDays')?.setValue([{ "value": "sunday", "checked": false }, { "value": "monday", "checked": false }, { "value": "tuesday", "checked": false }, { "value": "wednesday", "checked": false }, { "value": "thursday", "checked": false }, { "value": "friday", "checked": false }, { "value": "saturday", "checked": false }])
      checkValueinrunfreq.get('weekSelection')?.setValue('weekly');
      this.getValidation(this.runFrequrncyGetter.at(0)?.get('weekDays'), 0)
    }
  }
  getutcData() {
    const cutoffset = 330;
    const currenttime = moment.utc();
    const localtime = currenttime.utcOffset(cutoffset);
    const startTime = localtime.clone().startOf('day');
    this.patchData=[];
      for (let i = 0; i < 24 * 4; i++) {
        const timeoption = startTime.clone().add(i * 15, 'minutes')
        const formatedTime = timeoption.format("HH:mm")
        this.patchData.push(formatedTime);
      }
  }

  getDatafromDailytask(event: any, i: any) {
    const lasttime = this.runFrequrncyGetter.at(i);
    lasttime.get('weekSelection')?.setValue(event.value);
    const getWeekdaysFormGroup = this.runFrequrncyGetter.at(i).value;
    if (event.value == "daily") {
      getWeekdaysFormGroup?.weekDays.forEach((ele: any) => {
        ele.checked = true;
        this.getValidation(this.runFrequrncyGetter.at(i)?.get('weekDays'), i);
      })
    } else if (event.value == "weekly") {
      getWeekdaysFormGroup?.weekDays.forEach((ele: any) => {
        ele.checked = false;
        this.getValidation(this.runFrequrncyGetter.at(i)?.get('weekDays'), i);
      })
    }
  }

  isformDirty() {
    let control1;
    let control2;
    let control3;
    let control4;
    if (this.editformData || this.copyAlertFormData) {
      const getformGroup = this.runFrequrncyGetter.at(this.runFrequrncyGetter.length - 1);
      control1 = getformGroup.get('Time')?.valid;
      control2 = getformGroup.get('Priority')?.valid;
      control3 = getformGroup.get('Critical')?.valid;
      control4 = getformGroup.get('weekDays')?.valid;
    } else {
      const getformGroup = this.runFrequrncyGetter.at(this.runFrequrncyGetter.length - 1);
      control1 = getformGroup.get('Time')?.dirty;
      control2 = getformGroup.get('Priority')?.dirty;
      control3 = getformGroup.get('Critical')?.dirty;
      control4 = getformGroup.get('weekDays')?.valid;
    }

    if (control1 && control2 && control3 && control4 && this.runFrequrncyGetter.value.length != 10 ) {
      return false;
    } else {
      return true;
    }
  }

  editData(index: any) {
    let getrunFrequencyControl = this.addPoolconsumptionrunfrequency.get('Time') as FormArray;
    const getrunFrequencyFormGroup = getrunFrequencyControl.controls[index] as FormGroup;
    return getrunFrequencyFormGroup;
  }

  checkboxChange(i: any, data: any): void {
    const getWeekdaysFormGroup = this.runFrequrncyGetter.at(i).value;
    getWeekdaysFormGroup?.weekDays.forEach((ele: any) => {
      if (ele.value == data && !ele.checked) {
        ele.checked = true;
        this.getValidation(this.runFrequrncyGetter.at(i)?.get('weekDays'), i);
      } else if (ele.value == data && ele.checked) {
        ele.checked = false;
        this.getValidation(this.runFrequrncyGetter.at(i)?.get('weekDays'), i);
      }
    })
  }

  getValidation(event: any, i: any) {
    let result = event;
    let getreunFreqEvent = false;
    getreunFreqEvent = result?.value?.some((ele: any) => ele.checked == true)
    if (getreunFreqEvent == false) {
      const control = this.runFrequrncyGetter.at(i).get('weekDays') as FormControl;
      control.setValidators([Validators.required, this.getControlValue()]);
      control.updateValueAndValidity();
    } else {
      const control = this.runFrequrncyGetter.at(i).get('weekDays') as FormControl;
      control.clearValidators();
      control.updateValueAndValidity();
    };

  }

   updaedTimeData(event: any) {
    let result = event.Time 
    const getrunFrequncycontrols = this.runFrequrncyGetter.at(this.runFrequrncyGetter.length - 1);
    const control1 = getrunFrequncycontrols.get('Time')?.value ;
    if (control1) {
      if (result.length != 1) {
        result.slice(0, result.length - 1).forEach((ele: any) => {
          if (ele.Time == control1) {
            // getrunFrequncycontrols.get('Time')?.setValue('');
          }
        })
      }
    }
  }

  getControlValue() {
    return (control: AbstractControl) => {
      let getEventValue = control.value.some((ele: any) => ele.checked == false);
      const isValaid = control.value && getEventValue;
      return isValaid ? { 'containsTest': true } : null;
    }
  }

  getCopyconsumptionData() {
    if (this.copyAlertFormData) {
      for (let key in this.copyAlertFormData.Status) {
        if (key == 'runFrequency' && this.copyAlertFormData.Status[key] == true) {
          this.markAllAsTouched();
          this.getutcData();
          this.copyAlertFormData.Data.runFrequency.items.forEach((ele: any, index: any) => {
            if (this.runFrequrncyGetter != undefined) {
              this.runFrequrncyGetter.at(index).get('weekDays')?.value.forEach((data: any, index: any) => {
                ele.runDaysOfWeek.forEach((elem: any) => {
                  if (data.value == elem) {
                    data.checked = true;
                  } }) })
              this.runFrequrncyGetter.at(index).patchValue({
                Time: ele.runTime, Priority: ele.runPriority.toString(), Critical: ele.isCritical == 0 ? "No" : "Yes"
                , weekSelection: 'weekly'
              })
              this.getValidation(this.runFrequrncyGetter.at(index).get('weekDays'), index)
              if (this.copyAlertFormData.Data.runFrequency.items.length - 1 > index) {
                this.pushDatainGetter()
              } } })
          break;
        }}}
  }
}

