import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslationKey } from 'pipes/translate.pipe';
import { VUE_DIALOG_CONFIG } from 'vue/utilities/vue-dialog-config';

/**
 * Component that shows a loading spinner on a modal
 */
@Component({
  selector: 'app-information-dialog',
  templateUrl: './information-dialog.component.html',
  styleUrls: [ './information-dialog.component.scss' ]
})
export class InformationDialogComponent {
  /**
   * The title to be displayed at the top of the dialog
   */
  public dialogTitle: TranslationKey = 'title.loading';

  public constructor(
  // eslint-disable-next-line @typescript-eslint/no-parameter-properties
    @Inject(MAT_DIALOG_DATA) public data: InformationDialogInput,
    private dialogRef: MatDialogRef<InformationDialogComponent>
  ) {}

  /**
   * Closes dialog
   */
  public closeDialog(): void {
    this.dialogRef.close(true);
  }
}

/**
 * Config to be passed to the dialog when opening it from a popover
 */
export const informationDialogConfig = {
  ...VUE_DIALOG_CONFIG,
};

export interface InformationDialogInput {

  /**
   * Title for the dialog
   */
  title: TranslationKey;
}
