import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { GetQueuesResponse, Queue } from 'api/types';
import { PoolsMicroservice } from './microservices/pools.microservice';

/**
 * API service for Queues domain
 */
@Injectable({
  providedIn: 'root'
})
export class QueuesService {
  /**
   * Store queues to avoid calling the API an excessive amount of times
   */
  private queues: Queue[] = [];

  public constructor(private api: PoolsMicroservice) { }

  /**
   * Returns a default queue to be selected first where applicable.
   *
   * If a queue with the name 'ENU Default' is not found the first queue will be returned
   *
   * !!! This will break if queue names are ever translated into a different language !!!
   */
  public static getDefaultQueue(queues: Queue[]): Queue {
    return queues.find((q) => q.name === 'English Default') ?? queues[0];
  }

  /**
   * Calls GetQueues API
   *
   * @returns GetQueuesResponse Observable
   */
  public getQueues(): Observable<GetQueuesResponse> {
    const path = 'queues';

    // return queues if they're already available
    if (this.queues.length) {
      // Delay one tick so the cached state works similarly to true API call
      return of(this.queues).pipe(delay(0));
    }

    return this.api.get<GetQueuesResponse>(path).pipe(map((queues) => {
      let queuesData = queues.sort((a, b) => a.name.localeCompare(b.name.toLowerCase()));
      this.queues = [];
      let EnglishDefaultIndex = queuesData.findIndex((q) => q.name === 'English Default');
      if (EnglishDefaultIndex != -1) {
        let EnglishDefaultObj = JSON.parse(JSON.stringify(queuesData.find((q) => q.name === 'English Default')));
        this.queues.push(EnglishDefaultObj);
        queuesData.splice(EnglishDefaultIndex, 1);
      }

      queuesData.forEach(ele => {
        this.queues.push(ele);
      });

      return this.queues;
    }));
  }
}
