import { Injectable } from '@angular/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DISPLAY_DAY_FORMAT, EXCHANGE_FORMAT, MONTH_DAY_YEAR_FORMAT } from 'constants/date-formats';

/**
 * Extend MomentDateAdapter for custom implementation
 */
@Injectable()
export class VueDateAdapter extends MomentDateAdapter {
  /**
   * Override default getDayOfWeekNames
   * The only change from the default implementation is when locale is english
   * and style is narrow, only the first letter of the day should be returned.
   * This comes into play with week names on the calendar of a datepicker.
   *
   * @param style 'long' | 'short' | 'narrow': style of week name to get
   * @returns string array of week names based on input style
   */
  public getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    if (style === 'narrow' && this.locale === 'en-US') {
      return this[ '_localeData' ].narrowDaysOfWeek.map((day: string) => {
        return day.charAt(0);
      });
    } else if (style === 'narrow') {
      return this[ '_localeData' ].narrowDaysOfWeek;
    } else if (style === 'short') {
      return this[ '_localeData' ].shortDaysOfWeek;
    }
    return this[ '_localeData' ].longDaysOfWeek;
  }
}

// Specify date input format
export const VUE_DATE_FORMATS: typeof MAT_MOMENT_DATE_FORMATS = {
  ...MAT_MOMENT_DATE_FORMATS,
  parse: {
    ...MAT_MOMENT_DATE_FORMATS.parse,
    dateInput: [ DISPLAY_DAY_FORMAT, EXCHANGE_FORMAT, MONTH_DAY_YEAR_FORMAT ]
  },
  display: {
    ...MAT_MOMENT_DATE_FORMATS.display,
    dateInput: DISPLAY_DAY_FORMAT,
  }
};

export type DatePickerInputEvent = MatDatepickerInputEvent<VueDateAdapter>
