import { utc } from 'moment';
import { FULL_DAY_FORMAT } from './date-formats';

/**
 * Gets the lowercase name of the day of the week for the specified index.
 *
 * @param i The zero-based index to use.
 * @returns The day of the week (i.e. 'sunday').
 */
function getDayOfWeek(i: number): string {
  return utc().day(i).format(FULL_DAY_FORMAT).toLocaleLowerCase();
}

/**
 * Localized days of the week array based on moment locale
 *
 * @returns localized days of the week array
 */
export function daysOfTheWeekLocaleDisplay(): string[] {
  return [
    getDayOfWeek(0),
    getDayOfWeek(1),
    getDayOfWeek(2),
    getDayOfWeek(3),
    getDayOfWeek(4),
    getDayOfWeek(5),
    getDayOfWeek(6),
  ];
}

/**
 * Array list of days of the week, used for data points
 * Do not use for display, these are not translated.
 * Use daysOfTheWeekLocaleDisplay()
 */
export const daysOfTheWeek = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
] as const;

export const dayNumber: { [key: string]: number } = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6
} as const;

/**
 * Get localized day of the week name
 *
 * @param day day of the week
 * @returns localized day name
 */
export function getDayLocaleDisplay(day: Day): string {
  return daysOfTheWeekLocaleDisplay()[ dayNumber[ day ] ];
}

/**
 * Union type of days of the week
 * Do no use for UI display
 */
export type Day = typeof daysOfTheWeek[number];
