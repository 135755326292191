import { ErrorStateMatcher } from '@angular/material/core';

/**
 *  Suppress error state for form field
 *  Used when submitting is disabled until all fields are valid
 */
export class SuppressErrorMatcher implements ErrorStateMatcher {
  /**
   * @returns false
   */
  public isErrorState(): boolean {
    return false;
  }
}
