<div>
  <div class="filter-wrapper">
    <mat-form-field appearance="outline">
      <mat-label>Queue</mat-label>
      <mat-select [formControl]="queue" multiple disableOptionCentering>
        <mat-option *ngFor="let queueData of queuesList"
          [value]="queueData.id">{{getQueueDisplay(queueData)}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Type</mat-label>
      <mat-select [formControl]="type" disableOptionCentering>
        <mat-option *ngFor="let type of typeList" [value]="type">{{type}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Prority</mat-label>
      <mat-select [formControl]="priorities" multiple disableOptionCentering>
        <mat-option *ngFor="let priority of priorityList" [value]="priority">
          {{priority}}
          <span class="dot" [ngClass]="setColorCode(priority)"></span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Status</mat-label>
      <mat-select [formControl]="status" disableOptionCentering>
        <mat-option *ngFor="let status of statusList" [value]="status">{{status}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Date Range</mat-label>
      <mat-date-range-input [formGroup]="dateRange" [rangePicker]="startDatePicker">
        <input matStartDate placeholder="Start date" formControlName="start">
        <input matEndDate placeholder="End date" formControlName="end">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="startDatePicker">
        <mat-icon matDatepickerToggleIcon>
          <img src="/assets/icons/icon-calendar.svg" alt="">
        </mat-icon>
      </mat-datepicker-toggle>
      <mat-date-range-picker #startDatePicker></mat-date-range-picker>
    </mat-form-field>
  </div>
  <app-filter-by [filters]="selectedFilters" (filterRemoved)="filterRemoved($event)"></app-filter-by>
</div>