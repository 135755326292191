import { EventEmitter, ViewChild } from '@angular/core';
import { Component, Input, Output } from '@angular/core';
import { UntypedFormControl, NgModel } from '@angular/forms';

/**
 *  Filter component for search
 */
@Component({
  selector: 'vue-filter-search',
  templateUrl: './vue-filter-search.component.html',
  styleUrls: [ './vue-filter-search.component.scss' ]
})
export class VueFilterSearchComponent {
  /**
   * Placeholder for the search input
   */
  @Input() public placeholder = '';

  /**
   * Initial search value
   */
  @Input() public inputValue = '';

  /**
   * Regular expression that matches on invalid characters to show error state
   */
  @Input() public invalidRegex?: RegExp;

  /**
   * Reference to the underlying NgModel
   */
  @ViewChild('inputModel') public ngModel?: NgModel;

  /**
   * event to emit on key up
   */
  @Output() public searchValueChange = new EventEmitter<string>();

  /**
   * Inline error state shows error when the control has any errors
   *
   * Only `valueChange` should set errors
   */
  public errorMatcher = {
    isErrorState: (control: UntypedFormControl): boolean => {
      return Boolean(control.errors);
    }
  }

  /**
   * Validate and emit new search value
   */
  public valueChange(newVal: string): void {
    if (this.invalidRegex?.test(newVal)) {
      this.ngModel?.control.setErrors({ 'invalid-character': newVal });
      this.ngModel?.control.markAsTouched();
    } else {
      this.ngModel?.control.setErrors(null);
      this.ngModel?.control.markAsTouched();
      this.searchValueChange.emit(newVal);
    }
  }

  /**
   * Clears input and emits an empty value
   */
  public clearInput(): void {
    this.ngModel?.reset('');
    this.searchValueChange.emit('');
  }
}
