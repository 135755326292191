import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  AuthErrorDialogContentComponent,
} from 'components/dialogs/auth-error-dialog-content/auth-error-dialog-content.component';
import { VUE_DIALOG_CONFIG } from 'vue/utilities/vue-dialog-config';

/**
 * Service that displays auth errors.  Modal content comes from AuthErrorComponent.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthErrorDialogService {
  public constructor(private dialog: MatDialog) {}

  /**
   * Open the auth error dialog
   */
  public open(): void {
    this.dialog.open<AuthErrorDialogContentComponent>(
      AuthErrorDialogContentComponent, {
        ...VUE_DIALOG_CONFIG,
      });
  }
}
