import { Component, Input } from '@angular/core';
import { Day } from 'constants/days-of-the-week';
import { TranslationKey } from 'pipes/translate.pipe';
import { AddTemplateState } from '../add-template-state/add-template-state.service';

interface CopyOption {
  translationKey: TranslationKey;
  value: Day;
}

/**
 * Allow daily allocations to be copied from previous days
 */
@Component({
  selector: 'app-copy-allocations',
  templateUrl: './copy-allocations.component.html',
  styleUrls: [ './copy-allocations.component.scss' ]
})
export class CopyAllocationsComponent {
  /**
   * Number of current day for allocation
   */
  @Input() public dayNumber!: number;

  /**
   * All days of the week
   */
  private readonly allDayOptions: CopyOption[] = [
    { translationKey: 'label.dayOfWeek.sunday', value: 'sunday' },
    { translationKey: 'label.dayOfWeek.monday', value: 'monday' },
    { translationKey: 'label.dayOfWeek.tuesday', value: 'tuesday' },
    { translationKey: 'label.dayOfWeek.wednesday', value: 'wednesday' },
    { translationKey: 'label.dayOfWeek.thursday', value: 'thursday' },
    { translationKey: 'label.dayOfWeek.friday', value: 'friday' },
    { translationKey: 'label.dayOfWeek.saturday', value: 'saturday' },
  ];

  public constructor(private state: AddTemplateState) {}

  /**
   * Get all days before the defined dayNumber
   *
   * @returns array of
   */
  public get availableDays(): CopyOption[] {
    return this.allDayOptions.slice(0, this.dayNumber);
  }

  /**
   * Trigger copy via AddTemplateState
   *
   * @param day day to copy from
   */
  public copyDay(day: Day): void {
    this.state.copyAllocation(day, this.allDayOptions[ this.dayNumber ].value);
  }
}
