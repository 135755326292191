import { Pipe, PipeTransform } from '@angular/core';
import { take } from 'rxjs/operators';
import { PoolInfo } from 'api/types';
import { TranslatePipe } from './translate.pipe';

/**
 * Exam display logic for a pool when displayed in a table
 * Displays single exam or comma separated list of exams
 */
@Pipe({
  name: 'poolExamDisplay'
})
export class PoolExamDisplayPipe implements PipeTransform {
  private multipleTranslated = ''

  public constructor(translatePipe: TranslatePipe) {
    translatePipe.transform('label.multiple')
      .pipe(take(1))
      .subscribe((translation) => {
        this.multipleTranslated = translation;
      });
  }
  public transform(pool: PoolInfo): string | number {
    if (pool.exams.length) {
      return pool.exams.length > 1 ? this.multipleTranslated +
      ' (' + pool.exams.length + ')' : pool.exams[ 0 ].seriescode;
    }
    return '–';
  }
}
