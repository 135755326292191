import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { DateRange, MatDatepickerInputEvent, MatDateRangePicker } from '@angular/material/datepicker';
import { Moment, utc } from 'moment';
import { getFirstDayOfWeek } from 'utils/get-first-day-of-week';

/**
 * Selector for weekly timescale, used by `DateSelectionLabelComponent`
 */
@Component({
  selector: 'app-weekly-datepicker',
  templateUrl: './weekly-datepicker.component.html',
  styleUrls: [ './weekly-datepicker.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class WeeklyDatepickerComponent {
  /**
   * The start date in string format YYYY-MM-DD, eg '2021-03-28'
   */
  @Input() public startDate?: string | null;

  /**
   * Emits the first date (sunday) within the new week that is selected
   */
  @Output() public weekChange = new EventEmitter<Moment>();

  /**
   * Reference to the material datepicker
   */
  @ViewChild('picker') public picker?: MatDateRangePicker<Moment>;

  /**
   * Getter for the first day of the week (sunday) based on the `startDate`
   */
  public get startOfWeek(): Moment | null {
    return this.startDate ? getFirstDayOfWeek(utc(this.startDate)) : null;
  }

  /**
   * Getter for the last day of the week (saturday) based on the `startDate`
   */
  public get endOfWeek(): Moment | null {
    return this.startDate ? getFirstDayOfWeek(utc(this.startDate)).endOf('week') : null;
  }

  /**
   * Emit start of the week and close the datepicker when a date is selected
   */
  public dateChange(event: MatDatepickerInputEvent<Moment, DateRange<Moment>>): void {
    if (event.value) {
      this.weekChange.emit(event.value.clone().startOf('week'));
    }

    this.picker?.close();
  }

  /**
   * Opens the datepicker, used by consuming components
   */
  public open(): void {
    this.picker?.open();
  }
}
