import { Component, Input } from '@angular/core';

/**
 *  Displays percent totals
 */
@Component({
  selector: 'app-percent-total',
  templateUrl: './percent-total.component.html',
  styleUrls: [ './percent-total.component.scss' ]
})
export class PercentTotalComponent {
  /**
   * The total percentage to display as a number
   */
  @Input() public total = 0;
}
