<app-chart-legend [categories]="legendCategories">
  <mat-button-toggle-group
    legend-action
    [ngModel]="showQueueCapacity$ | async"
    (ngModelChange)="showQueueCapacity$.next($event)"
  >
    <mat-button-toggle
      [value]="true"
      title="{{ 'label.aria.showQueueCapacity' | translate | async }}"
      aria-label="{{ 'label.aria.showQueueCapacity' | translate | async }}"
      class="button-toggle-icon"
    >
      <img src="/assets/icons/bar-line-chart-{{showQueueCapacity$.value ? 'blue' : 'gray'}}.svg" alt="">
    </mat-button-toggle>
    <mat-button-toggle
      [value]="false"
      title="{{ 'label.aria.showQueueCapacity' | translate | async }}"
      aria-label="{{ 'label.aria.hideQueueCapacity' | translate | async }}"
      class="button-toggle-icon"
    >
      <img src="/assets/icons/bar-chart-{{showQueueCapacity$.value ? 'gray' : 'blue'}}.svg" alt="">
    </mat-button-toggle>
  </mat-button-toggle-group>
</app-chart-legend>
<app-chart-with-custom-y-axis [compactHeader]="true">
  <app-custom-y-axis
    [label]="filterDate"
    [widthRems]="firstColumnWidthRems"
    [max]="yAxisMax"
    [stepSize]="yAxisStepSize"
    [chartHeightRems]="chartHeightRems"
  ></app-custom-y-axis>
  <div class="scroll-container" #scrollContainer>
    <app-custom-x-axis
      [labels]="xAxisLabels"
      [columnWidthRems]="columnWidthRems"
      [ngStyle]="{ 'width.rem': chartWidthRems}"
    ></app-custom-x-axis>
    <app-chart-canvas-wrapper [chartWidthRems]="chartWidthRems" [chartHeightRems]="chartHeightRems">
      <canvas
        baseChart
        role="img"
        [datasets]="datasets"
        [labels]="xAxisLabels"
        [options]="options"
        (chartClick)="chartClick($event)"
        [vueChartPopover]="popoverTemplate"
        [attr.aria-label]="'label.aria.chartShowingPoolAvailability' | translate | async"
        [positionWithHighestDataset]="true"
      ></canvas>
    </app-chart-canvas-wrapper>
  </div>
  <app-synched-scroll-button direction="forward"></app-synched-scroll-button>
</app-chart-with-custom-y-axis>
<ng-template #popoverTemplate>
  <app-pools-chart-popover
    *ngIf="popoverEvent"
    [event]="popoverEvent"
    [metricItems]="metrics">
  </app-pools-chart-popover>
</ng-template>
