import { Moment } from 'moment';
import { UTC_TIMES } from '../data/utc-times';

/**
 * Format moment object by hours and minutes
 *
 * @param time a Moment object
 * @returns formattedTime the formatted time, eg '13:45'
 */
export function formatTimeSlot(time: Moment): string {
  return time.format('HH:mm');
}

/**
 * Get start of next hour
 *
 * @param time a Moment object
 * @returns nextHour a Moment object representing the start of the next hour
 */
export function getNextHour(time: Moment): Moment {
  const nextHour = time.clone();
  nextHour.add(1, 'h').startOf('h');
  return nextHour;
}

export function getNextHourMinuteWise(time: Moment): Moment {
  const nextHour = time.clone();
  nextHour.add(15, 'm').startOf('m');
  return nextHour;
}

/**
 * Gets end time of a time slot
 *
 * @param time a Moment object
 * @returns endTime a Moment object representing 45 minutes into the same hour
 */
 export function getEndTime(time: Moment): Moment {
  const endTime = time.clone();
  endTime.startOf('h').add(45, 'minutes');
  return endTime;
}

/**
 * Remove times either before or after the input time
 * Used for pool start and end time so a user cannot enter invalid times
 *
 * @param inputThreshold the time to begin the selection, eg '00:30'
 * @param removeTimes whether to select times before or after the threshold
 * @param listOfTimes the list times to filter
 * @returns utcTimes the filtered list of times
 */
export function removeUTCTimes(
  inputThreshold: string,
  removeTimes: 'after' | 'before',
  listOfTimes = UTC_TIMES
): string[] {
  const utcTimes = [ ...listOfTimes ];
  const index = utcTimes.findIndex((time) => time === inputThreshold);
  if (index !== -1 && removeTimes === 'after') {
    // Remove times after inputThreshold
    utcTimes.splice(index, utcTimes.length - index);
  } else if (index !== -1 && removeTimes === 'before') {
    // Remove times before inputThreshold
    utcTimes.splice(0, index + 1);
  }
  return utcTimes;
}

export function removeUTCTimesForOutage(
  inputThreshold: string,
  removeTimes: 'after' | 'before',
  listOfTimes = UTC_TIMES
): string[] {
  const utcTimes = [ ...listOfTimes ];
  const index = utcTimes.findIndex((time) => time === inputThreshold);
  if (index !== -1 && removeTimes === 'after') {
    // Remove times after inputThreshold
    return utcTimes.filter((time) => time <= inputThreshold)
  } else if (index !== -1 && removeTimes === 'before') {
    // Remove times before inputThreshold
    return utcTimes.filter((time) => time >= inputThreshold)
  }
  return utcTimes;
}

/**
 * Function to be applied when filtering a list of times
 * For filtering out non-top of the hour times
 *
 * @param t time string e.g. 12:00, 14:00
 * @returns true if time is the top of the hour
 */
export function isTopOfHour(t: string): boolean {
  return (/:00$/).test(t);
}

/**
 * Function to be applied when filtering a list of times
 * For filtering out non-top 45 minute interval
 *
 * @param t time string e.g. 12:45, 14:45
 * @returns true if time is the 45 minute interval
 */
export function is45Minute(t: string): boolean {
  return (/:45$/).test(t);
}
