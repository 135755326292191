interface TickConfig {
  labels: string[];
  tickHeightRems: number;
}

/**
 * Generate labels and height of ticks for Y-Axis
 *
 * @param max the max Y value
 * @param stepSize the step size
 * @param chartHeightRems the chart height in rems
 * @returns TickConfig
 */
export function buildYAxisTicks(max: number, stepSize: number, chartHeightRems: number): TickConfig {
  let currentValue = max;
  const labels = [];

  // Start at the highest value and work down to zero
  while (currentValue >= 0) {
    labels.push(String(currentValue));
    currentValue -= stepSize;
  }

  // Take one pixel off the total height to nail the alignment
  const height = chartHeightRems - (1 / 16);
  const tickHeightRems = height / (labels.length - 1);

  return { labels, tickHeightRems };
}
