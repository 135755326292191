import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  Component, ComponentRef, DoCheck, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { VueOverlayOpenDirective } from 'vue/directives/vue-overlay-open/vue-overlay-open.directive';
import { OptionsPaneComponent } from './options-pane/options-pane.component';

export interface MultiSelectOption {
  value: string;
  display: string;
  checked: boolean;
}

/**
 *  Filter multi-select component
 */
@Component({
  selector: 'app-filter-multi-select',
  templateUrl: 'filter-multi-select.component.html',
  styleUrls: [ 'filter-multi-select.component.scss' ]
})
export class FilterMultiSelectComponent implements DoCheck, OnDestroy {
  /**
   * Reference to the button that opens the `OptionsPane`
   */
  @ViewChild('openButton', { read: ElementRef }) public openButton!: ElementRef;

  /**
   * Placeholder shown as button text
   */
  @Input() public placeholder = '';

  /**
   * Placeholder for search input when `includeOptionSearch` is true
   */
  @Input() public searchPlaceholder = '';

  /**
   * Toggles the visibility of the search input within the `OptionsPane`
   */
  @Input() public includeOptionSearch = true;

  /**
   * Array of options that can be shown within the options pane
   */
  @Input() public options: MultiSelectOption[] = [];

  /**
   * Emits when a change is applied from the `OptionsPane`, emits an array of all selected values
   */
  @Output() public change = new EventEmitter<MultiSelectOption[]>();

  /**
   * Emits when the `OptionsPane` closes
   */
  public closePaneEvent: EventEmitter<void> = new EventEmitter<void>();

  /**
   * The number of selected options, shown using `VueNumberIndicator`
   */
  public selectedAmount = 0;

  /**
   * Overlay reference to attach OptionsPane
   */
  public overlayRef: OverlayRef;

  /**
   * Reference to `OptionsPane`.
   */
  public componentRef!: ComponentRef<OptionsPaneComponent>;

  /**
   * Completes when component is destroyed
   */
  private destroyed$ = new Subject();

  public constructor(
    private overlay: Overlay,
    private vueOverlayOpen: VueOverlayOpenDirective,
  ) {
    // Create overlay
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'multi-select-overlay',
      scrollStrategy: this.overlay.scrollStrategies.reposition()
    });

    // Close `OptionsPane` when the backdrop is clicked
    this.overlayRef.backdropClick()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.close();
      });

    // Close `OptionsPane` when escape is pressed
    this.overlayRef.keydownEvents()
      .pipe(filter((event) => event.key === 'Escape'))
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.close();
      });

    // Close `OptionsPane` when `closePaneEvent` emits
    this.closePaneEvent
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.close();
      });
  }

  /**
   * Terminate all subscriptions
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Using ngDoCheck because it is called after every change, and ngOnChanges did not detect
   * when fields within objects in the passed in options array were changed, only if any option was added or removed
   */
  public ngDoCheck(): void {
    const checkedItemsAmount = this.options.filter((option) => option.checked).length;
    if (checkedItemsAmount !== this.selectedAmount) {
      this.selectedAmount = checkedItemsAmount;
    }
  }

  /**
   * Open `OptionsPane` as an overlay
   */
  public open(): void {
    const strategy = this.overlay
      .position()
      .flexibleConnectedTo(this.openButton)
      .withPush(false)
      .withFlexibleDimensions(false)
      .withPositions([ {
        overlayX: 'start',
        overlayY: 'top',
        originX: 'start',
        originY: 'bottom'
      }, ]);
    this.overlayRef.updatePositionStrategy(strategy);
    const portal = new ComponentPortal(OptionsPaneComponent);
    this.componentRef = this.overlayRef.attach(portal);

    // Set `OptionsPane` properties and methods
    this.componentRef.instance.options = this.options;
    this.componentRef.instance.searchPlaceholder = this.searchPlaceholder;
    this.componentRef.instance.includeOptionSearch = this.includeOptionSearch;
    this.componentRef.instance.checkboxChange = this.change;
    this.componentRef.instance.closePane = this.closePaneEvent;

    // Add overlay class name
    this.vueOverlayOpen.addClassName();
  }

  private close(): void {
    this.vueOverlayOpen.removeClassName();
    this.overlayRef.detach();
  }
}
