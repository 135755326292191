import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '../../app-config.service';
import { ApiHttpClient } from '../http-client/api.http-client';

/**
 * Service that extends the ApiHttpClient with the capacity microservice base URL
 */
@Injectable({
  providedIn: 'root'
})
export class CapacityMicroservice extends ApiHttpClient {
  public constructor(
    http: HttpClient,
    appConfigService: AppConfigService,
  ) {
    // Create ApiHttpClient with the proper microservice base URL
    super(http, appConfigService.getCapacityMicroserviceURL());
  }
}

/**
 * Creates CapacityMicroservice
 *
 * @param http HttpClient
 * @param appConfigService AppConfigService
 * @returns CapacityMicroservice Instance
 */
export function capacityMicroserviceCreator(
  http: HttpClient,
  appConfigService: AppConfigService,
): CapacityMicroservice {
  return new CapacityMicroservice(http, appConfigService);
}
