import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Moment, utc } from 'moment';
import { ABBREV_MONTH_FORMAT, ABBREV_MONTH_YEAR_FORMAT, ABBREV_YEAR_FORMAT, DISPLAY_DAY_FORMAT, EXCHANGE_FORMAT } from 'constants/date-formats';
import { Timescale } from 'types/AppointmentFilters';
import { getFirstDayOfWeek } from 'utils/get-first-day-of-week';
import { DailyDatepickerComponent } from '../daily-datepicker/daily-datepicker.component';
import { MonthlyDatepickerComponent } from '../monthly-datepicker/monthly-datepicker.component';
import { WeeklyDatepickerComponent } from '../weekly-datepicker/weekly-datepicker.component';
import { YearlyDatepickerComponent } from '../yearly-datepicker/yearly-datepicker.component';

/**
 * Component to manage the label and datepicker the current timescale
 *
 * Used by `DateSelectionFilterComponent`
 */
@Component({
  selector: 'app-date-selection-label',
  templateUrl: './date-selection-label.component.html',
  styleUrls: [ './date-selection-label.component.scss' ]
})
export class DateSelectionLabelComponent {

  @Input() public alertSpecificData: any

  
  @Input() public getDatePickerValues: any
  /**
   * Current timescale selected for the filter
   * 
   * 
   */
  @Input() public timescale?: Timescale | null = Timescale.daily

  /**
   * The start date in string format YYYY-MM-DD, eg '2021-03-28
   */
  @Input() public startDate?: string | null;

  /**
   * Custom styling
   */
  @Input() public customStyle: boolean = false;

  /**
   * Emits date selected, upstream is aware of the timescale and will re-calculate
   * the proper dates.
   *
   * This component always calculates the start of the timescale
   */
  @Output() public dateChange = new EventEmitter<Moment>();

  /**
   * Reference to the monthly date selection picker
   */
  @ViewChild(MonthlyDatepickerComponent) public monthlySelector?: MonthlyDatepickerComponent;

  /**
   * Reference to the weekly date selection picker
   */
  @ViewChild(WeeklyDatepickerComponent) public weeklySelector?: WeeklyDatepickerComponent;

  /**
   * Reference to the daily date selection picker
   */
  @ViewChild(DailyDatepickerComponent) public dailySelector?: DailyDatepickerComponent;

   /**
   * Reference to the daily date selection picker
   */
   @ViewChild(YearlyDatepickerComponent) public yearlySelector?: YearlyDatepickerComponent;


  /**
   * Format label based on the timescale selected
   */
  public label(): string {
    if (!this.startDate) {
      return '';
    }

    const momentDate = utc(this.startDate, EXCHANGE_FORMAT);
    // const momentstartDate=utc(this.alertSpecificData?.date, EXCHANGE_FORMAT);

    switch (this.timescale) {
      case 'weekly':
        return this.getWeeklyLabel();
      case 'monthly':
        // console.log(momentDate.format(DISPLAY_DAY_FORMAT));
        
        return this.getDatePickerValues == false ? momentDate.format(ABBREV_MONTH_FORMAT): momentDate.format(ABBREV_MONTH_YEAR_FORMAT);
        // return momentDate.format(ABBREV_MONTH_YEAR_FORMAT);
      case 'outagesmonthly':
        return momentDate.format(ABBREV_MONTH_YEAR_FORMAT);
      case 'outagesyearly':
        return momentDate.format(ABBREV_YEAR_FORMAT);
      default:
        return momentDate.format(DISPLAY_DAY_FORMAT);
    }
  }

  public endDatelabel(){
    const momentDate = utc(this.alertSpecificData?.endDate, EXCHANGE_FORMAT);
    return momentDate.format(DISPLAY_DAY_FORMAT)
  }

  /**
   * Emit the change in date from any of the child date selectors
   */
  public manualDateChange(newDate: Moment): void {
    this.dateChange.emit(newDate.clone());
  }

  /**
   * Opens the correct datepicker for the current timescale
   */
  public openDatePicker(): void {
    switch (this.timescale) {
      case Timescale.monthly:
        this.monthlySelector?.open();
        break;
      case Timescale.outagesmonthly:
        this.monthlySelector?.open();
        break;
      case Timescale.outagesyearly:
        this.yearlySelector?.open();
        break;
      case Timescale.weekly:
        this.weeklySelector?.open();
        break;
      default:
        this.dailySelector?.open();
    }
  }

  /**
   * Return weekly label.
   *
   * Note: Weekly range always starts with the first _Sunday_ of the week
   */
  private getWeeklyLabel(): string {
    const startDate = getFirstDayOfWeek(utc(this.startDate, EXCHANGE_FORMAT));

    return `${startDate.format(DISPLAY_DAY_FORMAT)} – ${startDate.add(6, 'days').format(DISPLAY_DAY_FORMAT)}`;
  }
}
