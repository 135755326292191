import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { utc } from 'moment';
import { forkJoin, of, Subject, Subscription } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import {
  ExamName,
  GetQueuesResponse
} from 'api/types';
import { PoolConsumptionDetails } from 'api/types/endpoints/getPoolConsumptionById';
import { FULL_MONTH_DAY_YEAR_FORMAT } from 'constants/date-formats';
import { QueuesService } from 'services/api/queues.service';
import { PageStatusService } from 'services/status/page-status.service';
import { LOADING } from 'types/RequestStatus';
import { PoolConsumptionService } from 'services/api/poolconsumption.service';
import { isPoolConsumptionEditable } from 'components/common/pools/utils/is-poolconsumption-editable';
import { AlertRunFrequencyItems, AlertScheduleItems, AlertThresholdItems, ClientName } from 'api/types/endpoints/getPoolConsumption';
import { AddAlertPoolDataService } from 'services/add-alert-pool-data.service';
import { isPoolConsumptionEnds } from 'components/common/pools/utils/is-poolconsumption-editable';
import { AlertEditPcToastComponent } from 'components/common/alerts/alert-edit-pc-toast/alert-edit-pc-toast.component';

/**
 *  Pool consumption details page
 */
@Component({
  selector: 'app-poolconsumption-details-page',
  templateUrl: './poolconsumption-details-page.component.html',
  styleUrls: ['./poolconsumption-details-page.component.scss']
})
export class PoolConsumptionDetailsPageComponent implements OnInit, OnDestroy {
  @ViewChild(AlertEditPcToastComponent) public editPoolToast!: AlertEditPcToastComponent;
  private subscription!:Subscription;
  public poolConsumptionDetails: PoolConsumptionDetails = {} as PoolConsumptionDetails;

  /**
   * Start and end date of the poolconsumption alert in 'Month Day, Year' format
   */
  public formattedAlertStartDate = '';
  public formattedAlertEndDate = '';

  public formattedPoolStartDate = '';
  public formattedPoolEndDate = '';
  
  /**
   * Name of the queue associated with the pool
   */
  public queueName = '';

  /**
   * An array of the exams that should show in the current view
   */
  public examsToDisplay: ExamName[] = [];

  public alertTimeRangeToDisplay: AlertScheduleItems[] = [];

  /**
   * Determines how many exams to show in the current view
   */
  public displayAllExams = false;

  public displayAllAlertTimeRange = false;

  /**
   * Display an error when an occurrence action fails
   */
  public showOccurrenceActionError = false;

  /**
   * Track page loading state internally
   */
  public pageLoading = false;

  private refreshSubscription!:Subscription

  /**
   * Observable completes when component destroyed
   */
  private destroyed$ = new Subject();

  public poolName: string = '';

  public clients: ClientName[] = [];

  public poolStartTime: string = '';

  public poolEndTime: string = '';

  public runFrequency: AlertRunFrequencyItems[] = [];

  public threshold: AlertThresholdItems[] = [];

  public displaycolumnRunFequency: string[] = ['TIME', 'DAYS', 'PRIORITY', 'CRITICAL'];
  public displayedColumnThreshold: string[] = ['PRIORITY', 'TARGET', 'EMAIL'];
  newPoolId: any;
  toastMessage: any;

  public constructor(
    private queuesService: QueuesService,
    private route: ActivatedRoute,
    private pageStatusService: PageStatusService,
    private poolConsumptionService: PoolConsumptionService,
    private editPoolApi:AddAlertPoolDataService
  ) {
  }

  public ngOnInit(): void {
    this.refreshSubscription=this.editPoolApi.refreshNeeded$.subscribe(()=>{
      this.fetchPageData();
    })
    this.pageStatusService.status$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((status) => {
        this.pageLoading = status === LOADING;
      });

    this.fetchPageData();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.refreshSubscription.unsubscribe();
  }

  public moreThanFiveExams(): boolean {
    return (this.poolConsumptionDetails?.poolDetails.exams?.length || 0) > 5;
  }

  public moreThanFiveAlertTimeRange(): boolean {
    return (this.poolConsumptionDetails?.alertSchedule?.items.length || 0) > 5;
  }

  public toggleExamsDisplay(): void {
    this.displayAllExams = !this.displayAllExams;
    this.examsToDisplay = this.displayAllExams ? this.poolConsumptionDetails.poolDetails.exams : this.poolConsumptionDetails.poolDetails.exams.slice(0, 5);
  }

  public toggleAlertTimeRangeDisplay(): void {
    this.displayAllAlertTimeRange = !this.displayAllAlertTimeRange;
    this.alertTimeRangeToDisplay = this.displayAllAlertTimeRange ? this.poolConsumptionDetails.alertSchedule.items : this.poolConsumptionDetails.alertSchedule.items.slice(0, 5);
  }

  public isPoolConfigurationEditable(): boolean {
    return isPoolConsumptionEditable(this.poolConsumptionDetails);
  }

  public isPoolConsumptionEnds(): boolean {
    return isPoolConsumptionEnds(this.poolConsumptionDetails);
  }

  public refreshPoolDetails(): void {
    const id = this.route.snapshot.params.id ?? '';

    this.pageStatusService.loading();

    this.poolConsumptionService.getPoolConsumptionById({ id: id })
      .pipe(take(1))
      .subscribe((poolConsumption: any) => {
        this.poolConsumptionDetails = poolConsumption;
        this.pageStatusService.success();
      }, () => {
        this.pageStatusService.error();
      });
  }

  private fetchPageData(): void {
    const id = this.route.snapshot.params.id ?? '';

    this.pageStatusService.loading();

    this.poolConsumptionService.getPoolConsumptionById({ id: id })
      .pipe(switchMap((pool) => {
        return forkJoin([
          of(pool),
          this.queuesService.getQueues()
        ]);
      }), take(1)).subscribe((responses) => {
        this.editPoolApi.getdataforEditpool(responses);
        const [pool, queues] = responses;
        this.callsSucceeded(pool, queues);
      }, () => {
        this.pageStatusService.error();
      });
  }

  private callsSucceeded(
    poolConsumption: PoolConsumptionDetails, queues: GetQueuesResponse): void {
    const currentQueue = queues.find((queue) => queue.id === poolConsumption.queueId);
    const exams = poolConsumption.poolDetails?.exams ?? [];
    const alertTimeRange = poolConsumption.alertSchedule.items;

    this.poolConsumptionDetails = poolConsumption;

    this.poolName = poolConsumption.poolDetails?.poolName ?? '';

    this.clients = poolConsumption.poolDetails?.clients ?? [];

    this.queueName = currentQueue?.name ?? '';

    this.examsToDisplay = exams.length > 5 ? exams.slice(0, 5) : exams;

    this.alertTimeRangeToDisplay = alertTimeRange.length > 5 ? alertTimeRange.slice(0, 5) : alertTimeRange;

    this.threshold = poolConsumption.threshold.items;

    this.runFrequency = poolConsumption.runFrequency.items;

    this.formattedAlertStartDate = poolConsumption?.startDate ? utc(poolConsumption.startDate).format(FULL_MONTH_DAY_YEAR_FORMAT) : '';
    this.formattedAlertEndDate = poolConsumption?.endDate ? utc(poolConsumption.endDate).format(FULL_MONTH_DAY_YEAR_FORMAT) : '';

    this.formattedPoolStartDate = poolConsumption?.poolDetails?.poolStartDate ? utc(poolConsumption.poolDetails.poolStartDate).format(FULL_MONTH_DAY_YEAR_FORMAT) : '';
    this.formattedPoolEndDate = poolConsumption?.poolDetails?.poolEndDate ? utc(poolConsumption.poolDetails.poolEndDate).format(FULL_MONTH_DAY_YEAR_FORMAT) : '';

    this.poolStartTime = poolConsumption?.poolDetails?.poolStartTime ?? '';
    this.poolEndTime = poolConsumption?.poolDetails?.poolEndTime ?? '';

    this.pageStatusService.success();
  }

  setCritical(code: string) {
    switch (code) {
      case "0":
        return 'No'
      case "1":
        return 'Yes'
      case "3":
      default:
        return '-'
    }
  }

  formatRunDaysOfWeek(data: any) {
    var daysOfWeek: any[] = [];
    data.forEach((item : any) => {
      daysOfWeek.push(item.split("sunday").join("sun").split("monday").join("mo").split("tuesday").join("tu").split("wednesday").join("we").split("thursday").join("th").split("friday").join("fr").split("saturday").join("sa"));
    });
    return daysOfWeek;
  }

}
