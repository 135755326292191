<div class="date-filters">
  <vue-datepicker
    label="{{ 'label.placeholder.openAfterDate' | translate | async }}"
    placeholder="{{ 'placeholder.datepicker' | translate | async }}"
    (dateChanged)="dateChange('after', $event)"
    [selectedDate]="openAfterMoment"
    [maxDate]="openBeforeMoment"
  ></vue-datepicker>
  <vue-datepicker
    label="{{ 'label.placeholder.openBeforeDate' | translate | async }}"
    placeholder="{{ 'placeholder.datepicker' | translate | async }}"
    (dateChanged)="dateChange('before', $event)"
    [selectedDate]="openBeforeMoment"
    [minDate]="openAfterMoment"
  ></vue-datepicker>
  <p>
    {{ "message.pools.dateRangeFilter" | translate | async }}
  </p>
</div>
