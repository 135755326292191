import { Injectable, } from '@angular/core';
import { WindowLocationService } from 'services/window-location.service';
import { AuthErrorDialogService } from './auth-error-dialog.service';
import { AutoLogoutService } from './auto-logout.service';
import { CookieService } from './cookie.service';
import { RefreshTokenResponseBody } from './refresh-token.service';

/**
 * Service to manage authorization
 * - Leans on cookies set by the portal prior to app launch
 * - The cookies provide keys, important context and some simple user information
 * - After launch, sessionStorage is used for the cookie data and portal cookies are removed
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  /**
   * The number of milliseconds before expiration to try refreshing
   */
  public static REFRESH_BUFFER_MILLIS = 30 * 1000; // 30s

  /**
   * Storage keys we use
   * - `cookie` represents a cookie name we expect the portal to set prior to app launch
   * - `sessionStorage` represents a sessionStorage key we write ourselves
   */
  public static STORAGE_KEYS = {
    exp: { cookie: 'VUE-exp', sessionStorage: 'VUE-exp' },
    idToken: { cookie: 'idToken', sessionStorage: 'VUE-encodedToken' },
    logoutUrl: { cookie: 'VUE-logoutURL', sessionStorage: 'VUE-logoutURL' },
    name: { cookie: 'VUE-name', sessionStorage: 'VUE-name' },
    refreshToken: { cookie: 'VUE-refreshToken', sessionStorage: 'VUE-refreshToken' },
    restBaseURL: { cookie: 'VUE-restBaseURL', sessionStorage: 'VUE-restBaseURL' },
  }

  public constructor(
    private cookieService: CookieService,
    private authErrorDialogService: AuthErrorDialogService,
    private autoLogoutService: AutoLogoutService,
    private windowLocationService: WindowLocationService,
  ) {}

  /**
   * Store auth details and portal cookies in SessionStorage and then delete the cookies.
   * Start the inactivity timer.
   * This should be called before any API calls are made so the authorization token can be set.
   */
  public init(): void {
    // If cookies are present, assume they contain the most current portal auth information
    if (this.requiredCookiesPresent()) {
      this.savePortalCookiesToSessionStorage();
      this.deletePortalCookies();
    }

    // By now, session storage should be populated (or cookies were missing)
    if (!this.sessionStorageIsPopulated()) {
      this.authErrorDialogService.open();
      this.deletePortalCookies();
      return;
    }

    // Kick off the auto-logout / inactivity timer
    this.autoLogoutService.init();
  }

  /**
   * Update the token, refreshToken and exp values from a refreshToken call
   *
   * @param refreshResponse data from the refreshToken endpoint
   */
  public storeRefreshedTokens(refreshResponse: RefreshTokenResponseBody): void {
    sessionStorage.setItem(AuthService.STORAGE_KEYS.idToken.sessionStorage, refreshResponse.encodedToken);
    sessionStorage.setItem(AuthService.STORAGE_KEYS.refreshToken.sessionStorage, refreshResponse.refreshToken);
    sessionStorage.setItem(AuthService.STORAGE_KEYS.exp.sessionStorage, String(refreshResponse.idToken.exp));
  }

  /**
   * Determine whether the auth token should be refreshed.  Can be expired, or just soon to expire.
   *
   * @returns true if should be refreshed
   */
  public authTokenIsStale(): boolean {
    return this.getExp() - Date.now() < AuthService.REFRESH_BUFFER_MILLIS;
  }

  /**
   * Get the current Authorization token for use in API request headers
   *
   * @returns string auth token
   */
  public getAuthToken(): string {
    return sessionStorage.getItem(AuthService.STORAGE_KEYS.idToken.sessionStorage) || '';
  }

  /**
   * Get the current refresh token
   *
   * @returns string refresh token
   */
  public getRefreshToken(): string {
    return sessionStorage.getItem(AuthService.STORAGE_KEYS.refreshToken.sessionStorage) || '';
  }

  /**
   * Get the base REST URL for portal-orientated auth calls.
   * Not relevant for standard API calls!
   *
   * @returns string rest URL, or empty string if missing
   */
  public getRestBaseUrl(): string {
    return sessionStorage.getItem(AuthService.STORAGE_KEYS.restBaseURL.sessionStorage) || '';
  }

  /**
   * Get the expiration timestamp for the current auth token
   *
   * @returns number exp timestamp or 0 if missing
   */
  public getExp(): number {
    return parseInt(sessionStorage.getItem(AuthService.STORAGE_KEYS.exp.sessionStorage) || '0', 10);
  }

  /**
   * Get the user's full name from session storage (originating from portal cookie), eg "Homer Simpson"
   *
   * @returns name the user's full name, or an empty string if missing
   */
  public getUserFullName(): string {
    return sessionStorage.getItem(AuthService.STORAGE_KEYS.name.sessionStorage) || '';
  }

  /**
   * Logs the user out by clearing their session and navigating to the logout URL.
   */
  public logout(): void {
    const logoutUrl = this.getLogoutUrl();
    this.clearSession();
    this.windowLocationService.setHref(logoutUrl);
  }

  /**
   * Determines if a logout is possible based on the presence of a logout URL.
   *
   * An edge case (missing cookies upon launch) could occur where a logout URL is not available.
   */
  public canLogout(): boolean {
    return Boolean(this.getLogoutUrl());
  }

  /**
   * Remove all keys we added to sessionStorage.  This should be called as part of logging users out.
   */
  private clearSession(): void {
    Object.values(AuthService.STORAGE_KEYS).forEach((keys) => {
      sessionStorage.removeItem(keys.sessionStorage);
    });
  }

  /**
   * Get the logoutUrl, if possible.  Tries harder than other getters – checking both sessionStorage and cookies -
   * because we use this to help steer users when things fail.
   *
   * @returns logoutUrl or an empty string if missing
   */
  private getLogoutUrl(): string {
    return sessionStorage.getItem(AuthService.STORAGE_KEYS.logoutUrl.sessionStorage) ||
      this.cookieService.getCookie(AuthService.STORAGE_KEYS.logoutUrl.cookie) ||
      '';
  }

  /**
   * Ensure that all required sessionStorage keys are present
   */
  private sessionStorageIsPopulated(): boolean {
    const requiredKeys = Object.values(AuthService.STORAGE_KEYS).map((keys) => keys.sessionStorage);

    return requiredKeys.reduce<boolean>((allPresentSoFar, key) => {
      if (allPresentSoFar) {
        return sessionStorage.getItem(key) !== null;
      }
      return allPresentSoFar;
    }, true);
  }

  /**
   * Ensure that all required portal cookies are present
   */
  private requiredCookiesPresent(): boolean {
    const portalCookieNames = Object.values(AuthService.STORAGE_KEYS).map((keys) => keys.cookie);
    const allCookies = this.cookieService.getAllCookies();

    return portalCookieNames.reduce<boolean>((allPresentSoFar, cookieName) => {
      if (allPresentSoFar) {
        return Boolean(allCookies[ cookieName ]);
      }
      return allPresentSoFar;
    }, true);
  }

  /**
   * Save all required portal cookies to their configured sessionStorage keys
   */
  private savePortalCookiesToSessionStorage(): void {
    Object.values(AuthService.STORAGE_KEYS).forEach((keys) => {
      const cookieValue = this.cookieService.getCookie(keys.cookie);
      if (cookieValue) {
        sessionStorage.setItem(keys.sessionStorage, cookieValue);
      }
    });
  }

  /**
   * Delete all expected cookies and any that have the `VUE-` prefix
   */
  private deletePortalCookies(): void {
    // Delete expect cookies
    Object.values(AuthService.STORAGE_KEYS).forEach((keys) => {
      this.cookieService.removeCookie(keys.cookie);
    });

    // Delete any other `VUE-` cookies
    Object.keys(this.cookieService.getAllCookies())
      .filter((cookieName) => cookieName.indexOf('VUE-') === 0)
      .forEach((cookie) => {
        this.cookieService.removeCookie(cookie);
      });
  }
}
