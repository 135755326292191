import { Component, AfterContentInit, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-pool-consumption-threshold',
  templateUrl: './add-pool-consumption-threshold.component.html',
  styleUrls: ['./add-pool-consumption-threshold.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AddPoolConsumptionThresholdComponent),
    multi: true
  }]
})

export class AddPoolConsumptionThresholdComponent implements ControlValueAccessor, OnInit, AfterContentInit {
  @Input() editformData: any;
  @Input() copyAlertFormData: any;
  addPoolConsumptionThreshld!: FormGroup
  private onTouched = () => { };
  private onChanged = (value: any) => { };
  disabled = false;
  maxnumber = 4;
  updatedtooltip = 'Maximum of 4 threshold allowed'
  option = [1, 2, 3, 4]
  constructor(private fb: FormBuilder,) { }

  ngOnInit() {
    this.addPoolConsumptionThreshld = this.fb.group({
      Threshold: this.fb.array([
        this.Thresholdkeys()
      ])
    });
    this.addPoolConsumptionThreshld.valueChanges.subscribe((ele: any) => {
      this.addPoolConsumptionThreshld.touched
      this.updaedTimeData(ele);
      if (!this.addPoolConsumptionThreshld.valid) {
        this.markAllAsTouched();
      }
      this.onChanged(ele);
    });
    this.addPoolConsumptionThreshld.statusChanges.subscribe(() => {
      this.onChanged(this.addPoolConsumptionThreshld.valid ? { 'FormGroup': this.addPoolConsumptionThreshld.value, 'status': this.addPoolConsumptionThreshld.valid } : false)
    });
    this.thresholdcontrolsGetter.valueChanges.subscribe((ele: any) => {
      this.onChanged(this.addPoolConsumptionThreshld.valid ? { 'FormGroup': this.addPoolConsumptionThreshld.value, 'status': this.addPoolConsumptionThreshld.valid } : false)
    });
  }

  ngAfterContentInit() {
    if (this.editformData != undefined || this.copyAlertFormData != undefined) {
      this.onChanged(this.addPoolConsumptionThreshld.valid ? { 'FormGroup': this.addPoolConsumptionThreshld.value, 'status': this.addPoolConsumptionThreshld.valid } : false)
    }
  }

  markAllAsTouched() {
    Object.keys(this.addPoolConsumptionThreshld.controls).forEach((field) => {
      const control = this.addPoolConsumptionThreshld.get(field);
      control?.markAllAsTouched();
    })
  }

  writeValue(selected: any) {
    if (this.editformData != undefined) {
      this.setValues();
    }
    else if (this.copyAlertFormData != undefined) {
      this.setCopyConfiguration();
    }
  }

  registerOnChange(fn: any) {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setCopyConfiguration() {
    if (this.copyAlertFormData) {
      for (let key in this.copyAlertFormData.Status) {
        if (key == 'threshold' && this.copyAlertFormData.Status[key] == true) {
          this.copyAlertFormData.Data.threshold.items.forEach((ele: any, index: any) => {
            this.thresholdcontrolsGetter.at(index).patchValue({ percentage: ele.percentage, priority: ele.priority, email: ele.email })
            if (this.copyAlertFormData.Data.threshold.items.length - 1 > index) {
              this.UpdateValueinthreshold();
            }
          })
          break;
        }
      }
    }
  }

  setValues() {
    this.editformData[0].threshold.items.forEach((ele: any, index: any) => {
      this.thresholdcontrolsGetter.at(index).patchValue({ percentage: ele.percentage, priority: ele.priority, email: ele.email })
      if (this.editformData[0].threshold.items.length - 1 > index) {
        this.UpdateValueinthreshold();
      }
    })
  }

  updaedTimeData(event: any) {
    let result = event.Threshold;
    const seenAges=new Set();
    const dulicates=new Set();
    result.forEach((result:any,index:any)=>{
      if(result.priority === "" || result.priority === null){
        return;
      }
      if(seenAges.has(result.priority)){
        if(!dulicates.has(result.priority)){
          //  console.log(result);
           const getrunFrequncycontrols = this.thresholdcontrolsGetter.at(index);
           getrunFrequncycontrols.get('priority')?.setValue('');
           dulicates.add(result.priority);
           
        }
      }else{
        seenAges.add(result.priority);
      }
    })
    
  }

  Thresholdkeys() {
    return this.fb.group({
      percentage: ['', [Validators.required, Validators.pattern('^[0-9]*$'), this.validateValue.bind(this)]],
      priority: ['', [Validators.required]],
      email: ['', [Validators.required,]]
    })
  }

  get thresholdcontrolsGetter() {
    return this.addPoolConsumptionThreshld.get('Threshold') as FormArray;
  }

  validateValue(controls: any) {
    if (controls.value && controls.value > 100 || controls.value < 0) {
      return { 'invalidValue': true };
    }
    return null;
  }

  UpdateValueinthreshold() {
    return this.thresholdcontrolsGetter.push(this.Thresholdkeys());
  }

  removedatafromArrayList(i: number) {
    if (this.thresholdcontrolsGetter.length > 1) {
      this.thresholdcontrolsGetter.removeAt(i);
    }
    else {
      const getThresholditemslist = this.thresholdcontrolsGetter.at(0);
      getThresholditemslist?.reset();
    }
  }

  isformDirty() {
    let control1;
    let control2;
    let control3;
    if (this.editformData || this.copyAlertFormData) {
      const getThresholdCOntrols = this.thresholdcontrolsGetter.at(this.thresholdcontrolsGetter.length - 1);
      control1 = getThresholdCOntrols.get('percentage')?.valid;
      control2 = getThresholdCOntrols.get('priority')?.valid;
      control3 = getThresholdCOntrols.get('email')?.valid;
    }
    else {
      const getThresholdCOntrols = this.thresholdcontrolsGetter.at(this.thresholdcontrolsGetter.length - 1);
      control1 = getThresholdCOntrols.get('percentage')?.dirty;
      control2 = getThresholdCOntrols.get('priority')?.dirty;
      control3 = getThresholdCOntrols.get('email')?.dirty;

    }
    if (control1 && control2 && control3 && this.addPoolConsumptionThreshld.status == 'VALID' && this.thresholdcontrolsGetter.value.length != 4) {
      return false;
    }
    else {
      return true;
    }
  }

  editData(index: any) {
    let getthresholdgettercontrols = this.addPoolConsumptionThreshld.get('Threshold') as FormArray;
    const getthresholdgetterFromGroup = getthresholdgettercontrols.controls[index] as FormGroup;
    return getthresholdgetterFromGroup;
  }
}
