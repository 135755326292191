import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { PageStatusService } from 'services/status/page-status.service';
import { LOADING, RequestStatus } from 'types/RequestStatus';
import { fadeInOut } from 'utils/fade-in-out.animation';

/**
 *  General purpose filter container, providing some consistent styles and a loading state
 */
@Component({
  selector: 'app-filter-container',
  templateUrl: './filter-container.component.html',
  styleUrls: [ './filter-container.component.scss' ],
  animations: [ fadeInOut() ],
})
export class FilterContainerComponent implements OnInit{
  /**
   * Internal reference to the page status
   */
  public status: RequestStatus = 'initial';

  @Input() setDatePickerValues?: any;

  /**
   * Flag to track whether the page has already loaded
   */
  public alreadyLoaded = false;

  /**
   * Track destroyed for cleanup
   */
  private destroyed$ = new Subject();
  getDatePickerValues: any;

  public constructor(pageStatusService: PageStatusService) {
    pageStatusService.status$
      .pipe(
        takeUntil(this.destroyed$),
        distinctUntilChanged(),

        /*
         * Only display loading effect on initial page load
         * (Subsequent loading treatments should be handled by lower-level components)
         */
        filter((status) => {
          if (status === LOADING) {
            if (!this.alreadyLoaded) {
              this.alreadyLoaded = true;
              return true;
            }
            return false;
          }

          return true;
        }))
      .subscribe((status) => {
        this.status = status;
      });
  }

  ngOnInit(): void {
    this.getDatePickerValues = this.setDatePickerValues == true ? false : true;
   
   }

  /**
   * Clean up on destroy
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
