/**
 * Scrolling Utils
 *
 * Constructed as a class with static methods, so consuming components can spyOn methods when testing.
 */
export class ScrollUtils {
  public static scrollIntoViewIfNeeded = (element: HTMLElement): void => {
    const elementTop = element.getBoundingClientRect().top;
    const scrollingParentElement = getScrollingParent(element);
    const scrollingTop = scrollingParentElement?.getBoundingClientRect().top || Number.MIN_SAFE_INTEGER;

    if (elementTop < scrollingTop) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
}

/**
 * Finds the parent of an element that is scrollable, returns null if none found
 */
function getScrollingParent(element: HTMLElement | null): HTMLElement | null {
  if (!element) {
    return null;
  }

  // Return the element when its scrolling height is greater than the client height and overflow isn't hidden
  if (element.scrollHeight > element.clientHeight && element.style.overflow !== 'hidden') {
    return element;
  }

  return getScrollingParent(element.parentElement);
}
