import { Component } from '@angular/core';
import { AuthService } from 'services/authentication/auth.service';

/**
 * Display an error dialog when there has been an auth error.  Optionally, display a button to log in again.
 */
@Component({
  selector: 'app-error-dialog-content',
  templateUrl: './auth-error-dialog-content.component.html'
})

export class AuthErrorDialogContentComponent {
  /**
   * When a logout URL is available show logout action
   */
  public canLogout: boolean;

  public constructor(private authService: AuthService) {
    this.canLogout = this.authService.canLogout();
  }

  /**
   * The button/link was clicked
   */
  public linkClicked(): void {
    if (this.canLogout) {
      this.authService.logout();
    }
  }
}
 