import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CalendarOccuranceService } from 'services/api/calendar-occurance.service';
import { VUE_DIALOG_CONFIG } from 'vue/utilities/vue-dialog-config';

@Component({
  selector: 'app-alert-calendarpoup',
  templateUrl: './alert-calendarpoup.component.html',
  styleUrls: ['./alert-calendarpoup.component.scss']
})
export class AlertCalendarpoupComponent {
  loadingStatus = false;
  constructor(private dialog: MatDialogRef<AlertCalendarpoupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private capacityapi: CalendarOccuranceService,
  ) { }

  public closeDialog(): void {
    this.dialog.close();
  }

  confirmDialogue() {
    this.loadingStatus = true;
    this.capacityapi.updateCalendarCapacity(this.data.id, this.data.records).subscribe((res: any) => {
      this.loadingStatus = false;
      this.dialog.close(this.data);
    }, (error: unknown) => {
      this.loadingStatus = false;
      this.closeDialog();
    })
  }
}
export const confirmationModalConfigs = {
  ...VUE_DIALOG_CONFIG,
};


