
/**
 * Checks type to be appended to query string
 *
 * @param value any value
 * @returns boolean if value is valid type
 */
function isValidType(value: unknown): value is number | string | boolean {
  return Boolean(typeof value === 'string' || typeof value === 'boolean' || typeof value === 'number');
}

/**
 * Creates full query string from given object
 * Accepts strings, booleans, numbers and string[]
 * Values of empty string, or nested objects are not included
 *
 * @param obj object of key value pairs
 * @returns query string or empty string
 */
export function createQueryString(obj: Record<string, unknown>): string {
  const keys = Object.keys(obj);

  if (keys.length === 0) {
    return '';
  }

  let queryString = '';

  keys.forEach((key) => {
    let value = obj[ key ];

    if (Array.isArray(value)) {
      value = value.filter((item) => {
        return typeof item === 'string';
      }).reduce((accum: string, item: string) => {
        return accum ? `${accum},${item}` : item;
      }, '');
    }

    if (isValidType(value) && value !== '') {
      const pair = `${key}=${encodeURIComponent(value)}`;
      queryString += !queryString ? pair : `&${pair}`;
    }
  });

  return queryString ? `?${queryString}` : '';
}
