import { Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { TranslatePipe, TranslationLookup } from 'pipes/translate.pipe';
import { take } from 'rxjs/operators';
import { CapacityService } from 'services/api/capacity.service';
import { VueToastComponent } from 'vue/components/vue-toast/vue-toast.component';
import { UploadedCapacityFileLog } from 'api/types';
import { utc } from 'moment';
import { DISPLAY_DAY_FORMAT, EXCHANGE_FORMAT } from 'constants/date-formats';

@Component({
  selector: 'app-capacity-file-table',
  templateUrl: './capacity-file-table.component.html',
  styleUrls: ['./capacity-file-table.component.scss'],
})
export class CapacityFileTableComponent implements OnInit, OnChanges {
  months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  loading: boolean = false;

  private translations: TranslationLookup = {};

  public toastMessage = '';

  @Input() public selectedQueueId?: any | null;

  @ViewChild(VueToastComponent) public toast!: VueToastComponent;

  displayedColumns: string[] = [
    'fileName',
    // 'queue',
    'month_year',
    'date',
    'performedBy',
  ];
  dataSource!: UploadedCapacityFileLog[];
  constructor(
    private capacityService: CapacityService,
    private translatePipe: TranslatePipe
  ) {}

  ngOnInit(): void {
    this.getUploadedCapacityfiles();
    this.translatePipe
      .loadTranslations(['title.fileDownload', 'message.file.downloaded'])
      .pipe(take(1))
      .subscribe((translations) => {
        this.translations = translations;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('selectedQueueId' in changes) {
      if (changes.selectedQueueId.currentValue) {
        this.getUploadedCapacityfiles();
      }
    }
  }

  public getUploadedCapacityfiles() {
    if (this.selectedQueueId) {
      this.loading = true;
      this.capacityService
        .getUploadedCapacityFiles(this.selectedQueueId)
        .pipe(take(1))
        .subscribe(
          (files) => {
            console.log(files);
            this.loading = false;
            this.addTooltip(files);
          },
          (error: unknown) => {
            this.loading = false;
            console.log(error);
          }
        );
    }
  }

  public addTooltip(files: any): void {
    files.map((data: any) => {
      data.tooltip = `${data.filename}
      Comment: ${data.comment}`;
      data.month = this.months[Number(data.month) - 1]; // convert month number to month name
    });
    this.dataSource = files;
  }

  public downloadFile(selectedFile: UploadedCapacityFileLog) {
    console.log(selectedFile);
    this.capacityService
      .downloadCapacityFile(selectedFile.id)
      .pipe(take(1))
      .subscribe(
        (data: Blob) => {
          const file = new Blob([data], { type: 'text/csv' });
          const fileURL = URL.createObjectURL(file);

          // if you want to open PDF in new tab
          //window.open(fileURL);
          const a = document.createElement('a');
          a.href = fileURL;
          // a.target = '_blank';
          a.download = `${selectedFile.filename}`;
          document.body.appendChild(a);
          a.click();
          setTimeout(() => {
            this.toastMessage = this.translations['message.file.downloaded'];
            this.toast.heading = this.translations['title.fileDownload'];
            this.toast.open();
          }, 1500);
        },
        (error: unknown) => {
          console.log(error);
        }
      );
  }

  public formatDate(date: string | null): string {
    return date ? utc(date, EXCHANGE_FORMAT).format(DISPLAY_DAY_FORMAT) : '';
  }
}
