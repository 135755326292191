import { Component } from '@angular/core';

/**
 *  Appointments recent activity page
 */
@Component({
  selector: 'app-appointments-recent-activity-page',
  templateUrl: './appointments-recent-activity-page.component.html',
})
export class AppointmentsRecentActivityPageComponent { }
