import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddAlertPoolDataService } from 'services/add-alert-pool-data.service';

@Component({
  selector: 'app-add-pool-consumption-dialogbox',
  templateUrl: './add-pool-consumption-dialogbox.component.html',
  styleUrls: ['./add-pool-consumption-dialogbox.component.scss']
})

export class AddPoolConsumptionDialogboxComponent implements OnInit {
  setValuetoButton: any = true;
  getvaluefromFilter: any;
  constructor(public dialogRef: MatDialogRef<AddPoolConsumptionDialogboxComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private getDialogueClosedData: AddAlertPoolDataService) {
  }
  public ngOnInit(): void {
    this.getDialogueClosedData.selectedbuttonDisabled$.subscribe((ele: any) => {
      this.setValuetoButton = ele;
    })
  }

  onCloseClick(event: any, status: any) {
    if (status == 'close') {
      this.dialogRef.close();
      if(this.getvaluefromFilter && this.data){
        this.getDialogueClosedData.setDisabledButtonValue(true);
      }}
    else {
      this.getDialogueClosedData.selectedRow$.subscribe((ele: any) => {
        if (ele == null) {  }
        else {
          this.dialogRef.close(ele);  
        }
      })
    }
  }

  getdatafromfilter(event:any){
    this.getvaluefromFilter=event;
  }

}
