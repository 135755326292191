<mat-form-field
  appearance="outline"
  *ngIf="filter?.enabled"
  [ngClass]="{
    'group-position-first': groupPosition === 'first',
    'group-position-last': groupPosition === 'last'
    }">
  <mat-select
    vueOverlayOpen
    [disableOptionCentering]="true"
    panelClass="select-dropdown"
    [value]="filter?.value"
    [aria-label]="ariaLabel || ''"
    (selectionChange)="selectionChanged($event.value)"
  >
    <mat-option *ngIf="resetOptions?.includeReset" [value]="undefined">
      {{ resetOptions?.resetText }}
    </mat-option>
    <mat-option *ngFor="let option of filter?.options" [value]="option">
      {{format ? format(option) : option}}
    </mat-option>
  </mat-select>
</mat-form-field>
