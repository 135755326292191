/**
 * Calculates the utilization based on registrations and capacity.
 * Returns zero if either are zero
 *
 * @param registrations number of registrations
 * @param capacity number of capacity
 * @returns utilization percentage
 */
export function calculateAlertUtilizationPercentage(registrations: number, capacity: number): number {
  // avoid NaN
  if (registrations === 0 || (registrations + capacity) === 0) {
    return 0;
  }

  return ((registrations / (registrations + capacity)) * 100);
}
