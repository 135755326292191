<button
  [ngClass]="{'wrapper': true, 'week': isWeek, 'outside-month': (isOutsideMonth || metricItem.isHide ), 'cursor': (!isWeek && !metricItem.isHide && metricItem.occrStatus != 'released' && metricItem.occrStatus != 'NA' && metricItem.occrStatus != 'removed')}"
  (click)="openPopover(popoverDate)">

  <p [ngClass]="{'date': true, 'today': isTodaysDate}" aria-hidden="true">
    <span *ngIf="!metricItem.isHide || !isWeek">{{ titleDisplay }}</span>
  </p>
  <p class="visually-hidden">
    <span>{{ screenReaderLabel }}</span>
  </p>
  <dl class="key-value-pairs">
    <div class="pair">
      <dd><b><span *ngIf="metricItem.isHide && !isWeek" class="isHidematrics">{{ "label.calenderBlankdetails" |
            translate | async}}</span></b></dd>
      <dt><span *ngIf="!metricItem.isHide"> {{ "title.net/queue" | translate | async }}:</span></dt>&nbsp;
      <dd><b><span *ngIf="!metricItem.isHide">{{this.metricItem.netQueueAva || 0 }}</span></b></dd>
    </div>
    <div class="pair">
      <dt><span *ngIf="!metricItem.isHide">{{ "title.pool/Availablility" | translate | async }}:</span></dt>&nbsp;
      <dd *ngIf="metricItem.occrStatus != 'released' && metricItem.occrStatus != 'NA' && metricItem.occrStatus != 'removed'"><b><span *ngIf="!metricItem.isHide">{{this.metricItem.poolAvail || 0 }}</span></b></dd>
      <dd *ngIf="metricItem.occrStatus == 'released'"><b><I><span *ngIf="!metricItem.isHide">{{ "label.released" | translate | async }}</span></I></b></dd>
      <dd *ngIf="metricItem.occrStatus == 'NA' || metricItem.occrStatus == 'removed'"><b><I><span *ngIf="!metricItem.isHide">{{ "label.removed" | translate | async }}</span></I></b></dd>
    </div>
    <div class="pair">
      <dt><span *ngIf="!metricItem.isHide">{{ "title.pool/utilization" | translate | async }}</span></dt>&nbsp;
      <dd *ngIf="metricItem.occrStatus != 'released' && metricItem.occrStatus != 'NA' && metricItem.occrStatus != 'removed'"><b><span *ngIf="!metricItem.isHide">{{ utilization }}</span></b></dd>
      <dd *ngIf="(metricItem.occrStatus == 'released' || metricItem.occrStatus == 'NA' || metricItem.occrStatus == 'removed')"><b><span *ngIf="!metricItem.isHide">-</span></b></dd>
    </div>
  </dl>
</button>
<ng-template #dailyPopoverTemplate>
  <app-appt-monthly-chart-popover *ngIf="!isWeek" [date]="popoverDate"></app-appt-monthly-chart-popover>
  <app-appt-weekly-summary-popover *ngIf="isWeek" [date]="popoverDate"></app-appt-weekly-summary-popover>
</ng-template>