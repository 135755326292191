import { utc } from 'moment';

/**
 * Interface that matches both the response from `/metrics` & /pool/id/registrations`,
 * allowing for registrations to be calculated with either
 */
export interface ContainsRegistrationMetric {
  date: string;
  items: {
    timestamp: string;
    registrations: number;
  }[];
}

/**
 * Returns number of registrations and capacity between startTime and endTime
 * Based on input CapacityMetric
 *
 * @param startTime the start time in 'HH:mm' format
 * @param endTime the end time in 'HH:mm' format
 * @param registrations the registration or capacity metric for the time period
 * @returns results an object with registrations and capacity properties
 */
export function getRegistrationsForTimePeriod<R extends ContainsRegistrationMetric>(
  startTime: string,
  endTime: string,
  registrations: R | undefined): number {
  if (registrations) {
    const startMoment = utc(startTime, 'HH:mm');
    const endMoment = utc(endTime, 'HH:mm');
    const minutesOfStart = startMoment.minutes() + (startMoment.hours() * 60);
    const minutesOfEnd = endMoment.minutes() + (endMoment.hours() * 60);

    return registrations.items.filter((item) => {
      const timeStampMoment = utc(item.timestamp);
      const minutesOfDay = timeStampMoment.minutes() + (timeStampMoment.hours() * 60);

      /**
       * Moment doesn't have a great way to compare only hours and minutes excluding the date
       * Comparing minutes in the day to ignore day
       */
      return minutesOfStart <= minutesOfDay && minutesOfDay <= minutesOfEnd;
    }).reduce((acc, currentItem) => {
      return acc + currentItem.registrations;
    }, 0);
  }

  return 0;
}
