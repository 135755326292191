<mat-form-field appearance="outline">
  <mat-select
    vueOverlayOpen
    panelClass="select-dropdown"
    placeholder="{{ 'label.placeholder.selectTimezone' | translate | async }}"
    aria-label="{{ 'label.placeholder.selectTimezone' | translate | async }}"
    [disableOptionCentering]="true"
    [value]="selectedTimezone?.ianaCode || null"
    (selectionChange)="timezoneChange($event)"
  >
    <mat-option *ngIf="selectedTimezone" [value]="null">
      {{ 'label.none' | translate | async }}
    </mat-option>
    <mat-option *ngFor="let timezone of timezones" [value]="timezone.ianaCode">
      {{ timezone | timezoneDisplay | async }}
    </mat-option>
  </mat-select>
</mat-form-field>
<vue-toast
  icon="warn"
  heading="{{ 'alert.timezoneShift' | translate | async }}"
  [includeDismiss]="true"
>
  <span toast-body>
    {{ timezoneMessage | async }}
  </span>
</vue-toast>
