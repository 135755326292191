<h1 mat-dialog-title>
  {{ "title.systemTimeout" | translate | async }}
</h1>
<p mat-dialog-content [innerHTML]="'message.systemTimeout.countdown' | translate: formattedTimeRemaining | async"></p>
<div mat-dialog-actions>
  <vue-basic-button (clicked)="logoutClicked()">
    {{ "button.logout" | translate | async }}
  </vue-basic-button>
  <vue-flat-button (clicked)="continueClicked()">
    {{ "button.continue" | translate | async }}
  </vue-flat-button>
</div>
