import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PoolsPageService } from 'services/pools-page.service';
import { Timescale } from 'types/AppointmentFilters';
import { formatQueueDisplay } from 'utils/format-queue';
import { getInitialPoolsFilter, PoolsFilters, PoolsFiltersValue } from './pools-filters.config';

/**
 *  Filters for the pools page.
 */
@Component({
  selector: 'app-pools-filters',
  templateUrl: './pools-filters.component.html',
  styleUrls: [ './pools-filters.component.scss' ]
})
export class PoolsFiltersComponent implements OnInit, OnDestroy {
  public formatQueueOption = formatQueueDisplay;
  public filters: PoolsFilters = getInitialPoolsFilter();

  public monthly = Timescale.monthly;

  private destroyed = new Subject();

  public constructor(private poolsPageService: PoolsPageService) {}

  public ngOnInit(): void {
    this.poolsPageService.poolsFilters$
      .pipe(takeUntil(this.destroyed))
      .subscribe((poolsFilters) => {
        const { queue, startDate } = poolsFilters;
        this.filters.queue.options = queue.options;
        this.filters.queue.value = queue.value;
        this.filters.startDate.value = startDate.value;
      });
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public filterChanged(name: keyof PoolsFilters, value?: PoolsFiltersValue): void {
    /*
     * It shouldn't occur, but the filter select can return undefined,
     * but pool filters should always be defined, skip the update.
     */
    if (value) {
      this.poolsPageService.updatePoolFilter(name, value);
    }
  }
}
