import { Injectable } from '@angular/core';
import { BaseStatus } from './status.class';

/**
 * Service for storing and distributing status for all occurrence actions
 * Because only one action occurs at a time, only one observable is used for all actions
 */
@Injectable({
  providedIn: 'root'
})
export class OccurrenceActionsStatusService extends BaseStatus {}
