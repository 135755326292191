import { PoolScheduleOverride } from 'api/types';

/**
 * Retrieves an override if it exists
 */
export function getOverride(
  occurrenceDate: string,
  poolDetails: {scheduleOverrides: PoolScheduleOverride[]},
): PoolScheduleOverride | undefined {
  return poolDetails.scheduleOverrides?.find((override) => override.date === occurrenceDate);
}
