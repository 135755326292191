/* eslint-disable no-invalid-this,@typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, take } from 'rxjs/operators';
import { Interval, UpdateCapacityItem } from 'api/types';
import { DisplayableServerError } from '../types/DisplayableServerError';
import { ERROR, LOADING, RequestStatus, SUCCESS } from '../types/RequestStatus';
import { getDisplayableServerError } from '../utils/get-displayable-server-error';
import { CapacityService } from './api/capacity.service';
import { AppointmentsFiltersService } from './appointments-filters.service';
import { MatDialog } from '@angular/material/dialog';
import { LoadingDialogComponent, LoadingDialogInput, loadingDialogConfig } from 'components/dialogs/loading-dialog/loading-dialog.component';

export const MAX_NUMBER_OF_EDITS = 96;

/**
 * Service that handles FormGroup integration for the Appointments page.
 * Applicable to daily views
 */
@Injectable({
  providedIn: 'root'
})
export class AppointmentsInlineCapacityService {
  /**
   * FormGroup to be consumed by Daily Appointments table
   */
  public inlineCapacityEditForm: UntypedFormGroup;

  /**
   * Observable that emits the number of controls that have been edited
   */
  public numberOfEditedControls$ = new Subject<number>();

  /**
   * Internal backing value for maxEditsReached$
   */
  private maxEditsReached = new BehaviorSubject(false);

  /**
   * Observable that emits when the max number of edits has been reached
   */
  public maxEditsReached$ = this.maxEditsReached.asObservable();

  /**
   * Observable that emits during the process of submitting capacity edits
   */
  public editSubmissionStatus$ = new Subject<RequestStatus>();

  /**
   * Observable that emits when an error occurs
   */
  public editSubmissionError: DisplayableServerError | null = null;

  /**
   * Emits array of the indices for which capacities have changed
   */
  public updatedCapacityIndices$ = new Subject<number[]>();

  /**
   * Emits array of the indices for the edited capacities when service fails
   */
  public erroredCapacityIndices$ = new Subject<number[]>();

  /**
   * Subscription result of subscription to capacities FormArray
   */
  private numberOfEditedSubscription: Subscription | null = null;

  /**
   * Initial Capacity Form values
   * Used to reset form to original state if user selects cancel
   */
  private initialValues: {[key: string]: unknown};

  /**
   * Array of timestamps associated with capacities
   * Order of capacities & timestamps are aligned
   * timestamps[0] is the timestamp for capacityControls[0]
   */
  private timestamps: string[] = [];

  public constructor(
    private appointmentFilterService: AppointmentsFiltersService,
    private capacityService: CapacityService,
    formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
  ) {
    // Create empty form group
    this.inlineCapacityEditForm = formBuilder.group({
      capacities: formBuilder.array([])
    });

    // Save initial values for reset
    this.initialValues = this.inlineCapacityEditForm.value;
  }

  /**
   * Getter for capacities FormArray
   *
   * @returns capacities the current capacity controls
   */
  public get capacityControls(): UntypedFormArray {
    return this.inlineCapacityEditForm.controls.capacities as UntypedFormArray;
  }

  /**
   * Create form array for each capacity
   * Resets existing capacities FormArray to be empty first, clearing existing controls
   *
   * @param capacityObject Object of key value pairs, key is timestamp, value is capacity
   */
  public constructForm(capacityObject: CapacityTableObject): void {
    this.capacityControls.clear();
    this.timestamps = [];

    for (const [ timestamp, capacity ] of Object.entries(capacityObject)) {
      /**
       * First and second key value pairs are the row metadata, skip them
       */
      if (timestamp !== 'key' && timestamp !== 'category') {
        this.timestamps.push(timestamp);
        this.capacityControls.push(new UntypedFormControl(capacity));
      }
    }

    // Set initial values to include all capacity values
    this.initialValues = this.inlineCapacityEditForm.value;

    this.subscribeToFormChanges();
  }

  /**
   * Resets inlineCapacityEditForm to original state
   * Includes original capacity values if they were set
   */
  public resetCapacities(): void {
    this.inlineCapacityEditForm.reset(this.initialValues);

    // Enable all of the capacity controls
    this.inlineCapacityEditForm.enable();
    this.maxEditsReached.next(false);
  }

  /**
   * Gather needed queue and interval details for saving capacities
   * Builds capacity requests array
   * Waits until all observables are complete to handle responses
   */
  public saveEditedCapacities(): void {
    this.dialog.open<
            LoadingDialogComponent,
            LoadingDialogInput
          >(LoadingDialogComponent, {
            ...loadingDialogConfig,
            data: {
              title: 'title.loading',
              subtitle: 'subtitle.pleaseWait',
            },
          });
    const params = this.appointmentFilterService.getCurrentValue();
    const queueId = params.queueId;
    const interval = params.interval;

    if (!queueId) {
      // eslint-disable-next-line no-console
      console.warn('Missing Queue ID for saveEditedCapacities()');
      return;
    }

    const { capacityItems, updatedIndices } = this.buildCapacityRequest(interval);

    // this.editSubmissionStatus$.next(LOADING);
    this.editSubmissionError = null;

    this.capacityService.updateQueueCapacity(queueId, capacityItems).pipe(take(1)).subscribe(() => {
      this.capacityControls.markAsPristine();
      this.numberOfEditedControls$.next(this.numberOfDirtyControls());
      this.editSubmissionStatus$.next(SUCCESS);
      this.updatedCapacityIndices$.next(updatedIndices);
      this.dialog.closeAll();
    }, (error: unknown) => {
      this.editSubmissionError = getDisplayableServerError(error);
      this.editSubmissionStatus$.next(ERROR);
      this.erroredCapacityIndices$.next(updatedIndices);
      this.dialog.closeAll();
    });
  }

  /**
   * Subscribe to form changes to calculate the number of dirty controls
   * If subscription already exists, unsubscribe and reset subscription
   */
  private subscribeToFormChanges(): void {
    if (this.numberOfEditedSubscription) {
      this.numberOfEditedSubscription.unsubscribe();
      this.maxEditsReached.next(false);
    }

    this.numberOfEditedSubscription = this.capacityControls.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        const numberOfEdits = this.numberOfDirtyControls();
        this.numberOfEditedControls$.next(numberOfEdits);

        // Disable unedited form controls if max edit count has been reached
        const maxJustReached = !this.maxEditsReached.value &&
          AppointmentsInlineCapacityService.isMaxEditsReached(numberOfEdits);
        if (maxJustReached) {
          this.maxEditsReached.next(true);
          this.capacityControls.controls.forEach((control) => {
            if (!control.dirty) {
              control.disable();
            }
          });
        }
      });
  }

  /**
   * Determine if the max number of edits has been reached.
   *
   * @param numberOfEdits the count of dirty controls
   * @returns boolean whether the max has been reached
   */
  private static isMaxEditsReached(numberOfEdits: number): boolean {
    return numberOfEdits >= MAX_NUMBER_OF_EDITS;
  }

  /**
   * Calculate by iterating through list and tallying total number of edited controls
   * Dirty controls are ones that have had their value edited
   *
   * @returns number of dirty controls
   */
  private numberOfDirtyControls(): number {
    return this.capacityControls.controls.reduce((totalNumber, control) => {
      return control.dirty ? totalNumber + 1 : totalNumber;
    }, 0);
  }

  /**
   * Builds capacity request array
   *
   * @param interval interval selected in appointment filters
   * @returns Array of observables, and the indices of which capacities have updated
   */
  private buildCapacityRequest(interval: Interval): {
    capacityItems: UpdateCapacityItem[];
    updatedIndices: number[];
  } {
    const updateCapacityArray: UpdateCapacityItem[] = [];
    const updatedIndices: number[] = [];

    this.capacityControls.controls.forEach((control, index) => {
      if (control.dirty) {
        updateCapacityArray.push(
          {
            interval,
            timestamp: this.timestamps[ index ],
            capacity: parseInt((control.value || '0'), 10)
          }
        );
        updatedIndices.push(index);
      }
    });

    return {
      capacityItems: updateCapacityArray,
      updatedIndices
    };
  }
}

interface CapacityTableObject {
  [timestamp: string]: string | null;
}
