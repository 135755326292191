import { Component, OnInit } from '@angular/core';
import { AuthService } from 'services/authentication/auth.service';
import { CookieService } from 'services/authentication/cookie.service';
import { WindowLocationService } from 'services/window-location.service';

/**
 * Displays a Privacy and Terms banner on the bottom of the screen
 *
 * Only displays if the privacy cookie isn't found
 */
@Component({
  selector: 'app-privacy-banner',
  styleUrls: [ './privacy-banner.component.scss' ],
  templateUrl: './privacy-banner.component.html'
})
export class PrivacyBannerComponent implements OnInit {
  /**
   * True if the cookie is found or if the user clicks I understand button
   */
  public isCookieSet = false;

  /**
   * Name of cookie, based on hostname
   */
  private cookieName = '';

  /**
   * Value of cookie
   */
  private cookieValue = 'active';

  public constructor(
    private cookieService: CookieService,
    private windowService: WindowLocationService,
    private authService: AuthService,
  ) {}

  /**
   * 1. Set cookieName based on the hostname of the provided restBaseURL.
   * The Assist portal and other VUEFramework applications use the hostname to set the cookie name,
   * in order to use the same value, use the hostname of the restBaseURL. This won't show the banner
   * if the user already dismissed it outside of OnVUE Capacity.
   *
   * 2. Using the URL constructor can thrown an error, in that scenario use the current hostname
   * as a fallback.
   */
  public ngOnInit(): void {
    try {
      // constructing a new URL can throw an error if an invalid URL is passed to the constructor
      const { hostname } = new URL(this.authService.getRestBaseUrl());
      this.cookieName = `ctbar:${hostname}`;
    } catch {
      // fallback to hostname in event of an error
      this.cookieName = `ctbar:${this.windowService.getHostname()}`;
    }

    // When privacy cookie is already set, hide the banner
    if (this.isPrivacyCookieSet()) {
      this.isCookieSet = true;
    }
  }

  /**
   * Hides cookie banner by setting privacy cookie
   */
  public hideCookieBannerBar(): void {
    this.setPrivacyCookie();
  }

  /**
   * Returns the expiration date 3 months from now of the privacy cookie
   *
   * NOTE: Safari limits the expiration date of cookies to a max of 7 days.
   * https://webkit.org/blog/8613/intelligent-tracking-prevention-2-1/
   */
  private getExpireDate(): Date {
    const date = new Date();
    date.setMonth(date.getMonth() + 3);
    return date;
  }

  /**
   * Returns only the domain from the hostname
   * Ex: subdomain.domain.com -> domain.com
   *
   * Note: localhost will return empty string
   */
  private getDomain(): string {
    const host = this.windowService.getHostname();
    return host === 'localhost' ? '' : host.split('.').slice(1).join('.');
  }

  /**
   * Sets cookie to signify that the privacy banner have been dismissed
   */
  private setPrivacyCookie(): void {
    this.cookieService.setCookie(this.cookieName, this.cookieValue, {
      domain: this.getDomain(),
      expires: this.getExpireDate(),
      path: '/'
    });

    this.isCookieSet = true;
  }

  /**
   * Returns true if the cookie is already set.
   */
  private isPrivacyCookieSet(): boolean {
    return this.cookieService.getCookie(this.cookieName) === this.cookieValue;
  }
}
