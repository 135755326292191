import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { BulkEditPayload, Queue } from 'api/types';
import { EXCHANGE_FORMAT } from 'constants/date-formats';
import { UTC_TIMES } from 'data/utc-times';
import { AddUTCToTimePipe } from 'pipes/add-utc-to-time.pipe';
import { CapacityService } from 'services/api/capacity.service';
import { QueuesService } from 'services/api/queues.service';
import { DrawerStatusService } from 'services/status/drawer-status.service';
import { DisplayableServerError } from 'types/DisplayableServerError';
import { DatePickerErrorMatcher } from 'utils/error-matchers/date-picker.error-matcher';
import { SuppressErrorMatcher } from 'utils/error-matchers/suppress.error-matcher';
import { formatQueueDisplay } from 'utils/format-queue';
import { getDisplayableServerError } from 'utils/get-displayable-server-error';
import { is45Minute, isTopOfHour, removeUTCTimes } from 'utils/time-utils';
import { chronologicalOrderValidator } from 'utils/validators/chronological-order.validator';
import { pastDateValidator } from 'utils/validators/past-date.validator';
import { bulkEditCapacityValidator } from './bulk-capacity-edit/bulk-capacity-edit.validator';
import { dailyExceptionValidator } from './daily-time-exceptions/daily-time-exceptions.validator';
import { MatDialog } from '@angular/material/dialog';
import { InformationDialogComponent, informationDialogConfig, InformationDialogInput } from 'components/dialogs/information-dialog/information-dialog.component';

/**
 * Bulk Edit form for editing capacity on the Queue level
 */
@Component({
  selector: 'app-bulk-edit-form',
  templateUrl: './bulk-edit-form.component.html',
  styleUrls: [ './bulk-edit-form.component.scss' ],
})
export class BulkEditFormComponent implements OnInit, OnDestroy {
  /**
   * Emit event when status of form changes
   * True is emitted if the form is valid, false if not
   */
  @Output() public formStatusChange = new EventEmitter<boolean>();

  /**
   * Event emitted after successful completion of a bulk edit
   */
  @Output() public success = new EventEmitter<void>();

  /**
   * An error returned from a failed submission
   */
  public displayableServerError: DisplayableServerError | null = null;

  // Formatting functions passed to child components
  public getQueueDisplay = formatQueueDisplay;

  /**
   * List of available queues
   */
  public queues: Queue[] = [];

  /**
   * Form Group for all fields on the bulk edit form
   */
  public form = new UntypedFormGroup({
    queue: new UntypedFormControl('', Validators.required),
    startDate: new UntypedFormControl('', { validators: [ Validators.required, pastDateValidator ], updateOn: 'blur' }),
    endDate: new UntypedFormControl('', { validators: [ Validators.required, pastDateValidator ], updateOn: 'blur' }),
    startTime: new UntypedFormControl('', Validators.required),
    endTime: new UntypedFormControl('', Validators.required),
    allDay: new UntypedFormControl(false, Validators.required),
    capacity: new UntypedFormControl(null, bulkEditCapacityValidator),
    exceptions: new UntypedFormControl([], dailyExceptionValidator),
  }, {
    validators: [ chronologicalOrderValidator('startDate', 'endDate') ],
  });

  /**
   * Custom error matcher for required form fields
   * Never show error state
   * Submitting form is disabled until fields are valid
   */
  public suppressErrorState = new SuppressErrorMatcher();

  /**
   * Error matcher for date picker components
   */
  public datePickerErrorMatcher = new DatePickerErrorMatcher();

  /**
   * Array of start times
   * All of which are at the top of the hour
   * e.g 13:00, 14:00
   */
  public startTimes = [ ...UTC_TIMES ].filter(isTopOfHour);

  /**
   * Array of end times
   * All of which are the 45 minute
   * e.g 13:45, 14:45
   */
  public endTimes = [ ...UTC_TIMES ].filter(is45Minute);

  /**
   * Array of times that are valid exception times
   * Inclusive of the selected startTime and endTime
   * All of which are the at the top of the hour
   * e.g 13:00, 14:00
   *
   * @default [] empty array
   */
  public exceptionTimes: string[] = [];

  /**
   * Initialized form values
   * Used to reset the form to a blank slate after submit
   */
  private initialValues: { [key: string]: unknown };

  private destroyed = new Subject();

  public constructor(
    private queuesService: QueuesService,
    private capacityService: CapacityService,
    private addUTCToTimePipe: AddUTCToTimePipe,
    private drawerStatusService: DrawerStatusService,
    private dialog: MatDialog
  ) {
    // Fetch queues
    this.queuesService
      .getQueues()
      .pipe(take(1))
      .subscribe((queueResponse) => {
        this.queues = queueResponse;
      });

    // Set initial values for form reset
    this.initialValues = this.form.value;
  }

  public ngOnInit(): void {
    const { allDay, startTime, endTime } = this.form.controls;

    // Subscribe to allDay value
    allDay.valueChanges
      .pipe(takeUntil(this.destroyed))
      .subscribe((isChecked: boolean) => {
        if (isChecked) {
          // Set default values when all day is selected
          this.form.controls.startTime.disable();
          this.form.controls.startTime.setValue('00:00');
          this.form.controls.endTime.disable();
          this.form.controls.endTime.setValue('23:45');
        } else {
          // re-enable start and end time if all day is not selected
          this.form.controls.startTime.enable();
          this.form.controls.startTime.setValue('');
          this.form.controls.endTime.enable();
          this.form.controls.endTime.setValue('');
        }
      });

    // Emit event when status of form changes
    this.form.statusChanges
      .pipe(takeUntil(this.destroyed), distinctUntilChanged())
      .subscribe((status) => {
        this.formStatusChange.emit(status === 'VALID');
      });

    // Restrict end time based on start time
    startTime.valueChanges
      .pipe(takeUntil(this.destroyed))
      .subscribe((value) => {
        this.endTimes = removeUTCTimes(value, 'before').filter(is45Minute);

        this.setExceptionTimes(value, endTime.value);
      });

    // Restrict start time based on end time
    endTime.valueChanges.pipe(takeUntil(this.destroyed)).subscribe((value) => {
      this.startTimes = removeUTCTimes(value, 'after').filter(isTopOfHour);

      this.setExceptionTimes(startTime.value, value);
    });
  }

  /**
   * Terminate subscriptions
   */
  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  /**
   * Construct and submit bulk edit
   * On success reset form and emit success event
   */
  public submit(): void {
    let dialogRef = this.dialog.open<InformationDialogComponent, InformationDialogInput>(InformationDialogComponent, {
      ...informationDialogConfig,
      data: {
        title: 'title.pastHourCapacityNotUpdated'
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.drawerStatusService.loading();
        const payload = this.constructPayload();
        this.capacityService.editBulkCapacity(payload).pipe(take(1)).subscribe(
          () => {
            this.form.reset(this.initialValues);
            this.success.emit();
            this.drawerStatusService.success();
            this.displayableServerError = null;
          },
          (error: unknown) => {
            this.displayableServerError = getDisplayableServerError(error);
            this.drawerStatusService.error();
          }
        );
      }
    });
  }

  /**
   * @returns time with (UTC) added
   */
  public addUTCToTime(time: string): string {
    return this.addUTCToTimePipe.transform(time);
  }

  /**
   * Update list of valid exception times
   * Times should be after start time and before end time of the edit (inclusive)
   *
   * @param startTime filter our times before start time
   * @param endTime filter our times after start time
   */
  private setExceptionTimes(startTime: string, endTime: string): void {
    let exceptionTimes = UTC_TIMES;

    if (startTime) {
      exceptionTimes = [
        startTime,
        ...removeUTCTimes(startTime, 'before', exceptionTimes),
      ];
    }
    if (endTime) {
      exceptionTimes = [
        ...removeUTCTimes(endTime, 'after', exceptionTimes),
        endTime,
      ];
    }

    this.exceptionTimes = exceptionTimes.filter(isTopOfHour);
  }

  /**
   * Construct BulkEditPayload from form values
   */
  private constructPayload(): BulkEditPayload {
    const {
      queue,
      startDate,
      endDate,
      allDay,
      startTime,
      endTime,
      capacity,
      exceptions,
    } = this.form.value;

    const hours = allDay === false ? { hours: { startTime, endTime } } : {};
    return {
      queueId: queue.id,
      startDate: startDate.format(EXCHANGE_FORMAT),
      endDate: endDate.format(EXCHANGE_FORMAT),
      isAllDay: allDay,
      capacity,
      exceptions,
      ...hours,
    };
  }
}
