<vue-page-header appName="{{ 'title.applicationName' | translate | async }}"
  navSection="{{ 'title.outages' | translate | async }}" [enableBack]="true"
  [pageTitle]="'Edit Outage'"></vue-page-header>

<vue-section class="components" [loading]="pageLoading">
  <app-error-message *ngIf="status === 'error'" [heading]="displayableServerError?.title"
    [message]="displayableServerError?.message">
  </app-error-message>
  <form id="form" [formGroup]="editOutageForm" (ngSubmit)="validateOutage()" autocomplete="off">
    <div formArrayName="outages" class="form-container">
      <div class="row" *ngFor="let outage of getControls(); let i = index" [formGroupName]="i">
        <vue-text-input label="{{ 'title.outagename' | translate | async }}" formControlName="outageName"
          [required]="true"></vue-text-input>
        <div class="four-columns">
          <vue-datepicker label="{{ 'label.placeholder.startDate' | translate | async }}"
            placeholder="{{ 'placeholder.datepicker' | translate | async }}" formControlName="startDate"
            [required]="true" [errorMatcher]="datePickerErrorMatcher" [minDateIsToday]="true"
            [maxDate]="getControlValue(outage,'endDate')"
            [disable]="isStartDateTimePassed || !isValidData"
            [errorMessage]="getControlError(outage,'startDate') | datepickerError | translate | async"></vue-datepicker>

          <vue-select label="{{ 'label.placeholder.startTime' | translate | async }}" formControlName="startTime"
            [required]="true" [options]="utcStartTimes" [getOptionDisplay]="addUTCToTime.bind(this)"
            [errorMatcher]="suppressErrorState" [disable]="isStartDateTimePassed || !isValidData"></vue-select>

          <vue-datepicker label="{{ 'label.placeholder.endDate' | translate | async }}"
            placeholder="{{ 'placeholder.datepicker' | translate | async }}" formControlName="endDate" [required]="true"
            [errorMatcher]="datePickerErrorMatcher"
            [minDateIsToday]="true"
            [minDate]="getControlValue(outage,'startDate')"
            [disable]="!isValidData"
            [errorMessage]="getControlError(outage,'endDate') | datepickerError | translate | async"></vue-datepicker>

          <vue-select label="{{ 'label.placeholder.endTime' | translate | async }}" formControlName="endTime"
            [required]="true" [options]="utcEndTimes" [getOptionDisplay]="addUTCToTime.bind(this)"
            [disable]="!isValidData"
            [errorMatcher]="suppressErrorState"></vue-select>
        </div>
      </div>
    </div>
  </form>
  <div class="footer-btns">
    <vue-basic-button (clicked)="closeDrawerIfUnchanged()" class="cancel" [disabled]="status === 'loading'">
      {{ "button.cancel" | translate | async }}
    </vue-basic-button>
    <vue-flat-button (clicked)="saveOutage()" [disabled]="!formIsValid || !isValidData"
      [loading]="status === 'loading'">
      {{ "button.save" | translate | async }}
    </vue-flat-button>
  </div>
</vue-section>