import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslationKey } from 'pipes/translate.pipe';

/**
 * Server error message component that includes retry
 */
@Component({
  selector: 'app-server-error-unsaved',
  templateUrl: './server-error-unsaved.component.html',
})
export class ServerErrorUnsavedComponent implements OnInit {
  /**
   * Include unsaved verbiage in error message
   */
  @Input() public includeUnsaved = true;

  /**
   * Custom error message to be used instead of the default
   */
  @Input() public message = '';

  /**
   * Retry event when try again is clicked
   */
  @Output() public retry = new EventEmitter();

  /**
   * Translation key for error message
   */
  public translationKey: TranslationKey = 'error.message.server.unsaved';

  /**
   * Set generic message if includeUnsaved is false
   */
  public ngOnInit(): void {
    if (!this.includeUnsaved) {
      this.translationKey = 'error.message.server';
    }
  }
}
