import { Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

/** Duration of fade background to appear */
const fadeInDurationMS = 600;

/** Duration of fade background to be removed, duration duplicated in CSS transition */
const fadeOutDurationMS = 2000;

/**
 * Adds green background color to element on initialization
 * Then slowly fades to background color white
 * Stylesheet: background-fade-directive.scss
 * Styles are included globally in styles.scss
 */
@Directive({
  selector: '[appBackgroundFade]',
  exportAs: 'appBackgroundFade'
})
export class BackgroundFadeDirective implements OnInit, OnChanges {
  /**
   * Boolean to determine if fade should be applied
   */
  @Input() public appBackgroundFade?: boolean;

  /**
   * Use element reference's first child rather than the element reference itself
   */
  @Input() public useFirstChild = false;

  /**
   * Apply 6px of border radius to the fade element
   */
  @Input() public borderRadius = false;

  /**
   * Class name applied to host element first
   */
  private readonly initialClass = 'background-fade-initial';

  /**
   * Class name applied to host element after fade completes
   */
  private readonly endClass = 'background-fade-end';

  /**
   * Class name applied to host element to have 6px of border-radius
   */
  private readonly borderRadiusClass = 'border-radius';

  /**
   * @param elRef reference to the element that directive was placed on
   */
  public constructor(private elRef: ElementRef) { }

  /**
   * Show background fade if truthy
   */
  public ngOnInit(): void {
    if (this.appBackgroundFade) {
      this.addBackgroundFade();
    }
  }

  /**
   * Apply background fade if input is now truthy
   *
   * @param simpleChanges Changes object of inputs
   */
  public ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.appBackgroundFade?.currentValue === true) {
      this.addBackgroundFade();
    }
  }

  /**
   * Manually trigger background fade
   */
  public triggerBackgroundFade(): void {
    this.addBackgroundFade();
  }

  /**
   * Adds classes to host element to show fade effect
   * Then after timeout removes those styles
   */
  private addBackgroundFade(): void {
    const hostElement: HTMLElement = this.useFirstChild ?
      this.elRef.nativeElement.firstChild :
      this.elRef.nativeElement;

    // Reset class name
    this.removeClasses(hostElement);

    if (this.borderRadius) {
      hostElement.classList.add(this.borderRadiusClass);
    }
    hostElement.classList.add(this.initialClass);

    // Add fade classes
    window.setTimeout(() => {
      hostElement.classList.add(this.endClass);

      // Remove fade classes
      window.setTimeout(() => {
        this.removeClasses(hostElement);
      }, fadeOutDurationMS);
    }, fadeInDurationMS);
  }

  /**
   * Removes all directive related classes from host element
   */
  private removeClasses(element: HTMLElement): void {
    element.classList.remove(this.initialClass, this.endClass, this.borderRadiusClass);
  }
}
