import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GetPoolNameResponse } from 'api/types';
import { PoolNameErrorHandler } from 'components/drawers/add-pool-drawer/pool-name-error-handler/pool-name-error-handler.class';
import { TranslationKey } from 'pipes/translate.pipe';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { PoolsService } from 'services/api/pools.service';
import { DisplayableServerError } from 'types/DisplayableServerError';
import { RequestStatus } from 'types/RequestStatus';
import { getDisplayableServerError } from 'utils/get-displayable-server-error';
import { INVALID_CHARACTERS_ERROR, invalidCharactersValidator } from 'utils/validators/invalid-characters.validator';
import { VUE_DIALOG_CONFIG } from 'vue/utilities/vue-dialog-config';

/**
 *  Component for removing an individual template
 */
@Component({
  selector: 'app-change-poolname',
  templateUrl: './change-poolname.component.html'
})
export class ChangePoolNameComponent {
  poolName: boolean = false;
  public changePoolNameForm: UntypedFormGroup;
  public poolNameErrorHandler = new PoolNameErrorHandler();
  public formIsValid = false;
  private destroyed$ = new Subject();
  public isLoading = false;
  private error?: DisplayableServerError | null;
  public status: RequestStatus = 'initial';

  public constructor(
    // eslint-disable-next-line @typescript-eslint/no-parameter-properties
    @Inject(MAT_DIALOG_DATA) public data: changePoolNameModalInputs,
    private dialogRef: MatDialogRef<ChangePoolNameComponent>,
    private poolService: PoolsService
  ) {
    this.changePoolNameForm = this.createPoolNameForm();

    this.changePoolNameForm.statusChanges
      .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
      .subscribe((status) => {
        this.formIsValid = status === 'VALID';
      });

    this.changePoolNameForm.setValue({
      poolName: data.poolName
    });

    this.changePoolNameForm.updateValueAndValidity();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public closeDialog(): void {
    this.dialogRef.close(null);
  }

  public validatePoolName(): void {
    this.status = 'loading';
    this.changePoolNameForm.markAsPristine();
    const { poolName } = this.changePoolNameForm.controls;
    const name = poolName.value;

    if (name.toString().replace(/^\s+/g, '').replace(/\s+$/g, '').toLowerCase() != this.data.poolName.toLowerCase()) {
      this.isLoading = true;
      this.poolService.getPoolNames({ startsWith: name.toString().replace(/^\s+/g, '').replace(/\s+$/g, '') })
        .pipe(take(1))
        .subscribe((pools) => {
          this.checkPoolNamesForEdit(pools);
        }, (error: unknown) => {
          this.status = 'error';
          this.error = getDisplayableServerError(error);
        });
    }
    else {
      this.poolNameErrorMatcher.setDuplicateNameError(true);
      poolName.updateValueAndValidity();
    }
  }

  private checkPoolNamesForEdit(pools: GetPoolNameResponse): void {
    const { poolName } = this.changePoolNameForm.controls;
    const name = poolName.value;

    // Check for duplicate name
    const duplicateName = Boolean(pools.find((p) => p.name.toLowerCase() === name.toString().replace(/^\s+/g, '').replace(/\s+$/g, '').toLowerCase()));

    // Update error state
    this.poolNameErrorMatcher.setDuplicateNameError(duplicateName);
    poolName.updateValueAndValidity();

    if (!duplicateName) {
      this.poolService.unarchivedPool({ id: this.data.poolId }, name)
        .pipe(take(1))
        .subscribe(() => {
          this.isLoading = false;
          this.dialogRef.close(
            {
              updatedPoolName: name
            }
          );
        }, (error: unknown) => {
          this.status = 'error';
          this.error = getDisplayableServerError(error);
          this.isLoading = false;
        });
    }
  }

  public get displayableServerError(): DisplayableServerError | null | undefined {
    return this.error;
  }

  private createPoolNameForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      poolName: new UntypedFormControl('', {
        validators: [
          Validators.required,
          invalidCharactersValidator,
          this.poolNameErrorMatcher.duplicateNameValidator.bind(this.poolNameErrorMatcher)
        ]
      })
    });
  }

  public get poolNameErrorMatcher(): PoolNameErrorHandler {
    return this.poolNameErrorHandler;
  }

  public poolNameErrorMessageKey(): TranslationKey {
    if (this.changePoolNameForm.controls.poolName.hasError(INVALID_CHARACTERS_ERROR)) {
      return 'error.invalidCharacter';
    }
    return 'error.message.poolNameAlreadyExists';
  }
}

export const changePoolNameModalConfig = {
  ...VUE_DIALOG_CONFIG,
  width: '30rem',
};

export interface changePoolNameModalInputs {
  title: Observable<string>;
  poolName: string;
  poolId: string;
}


