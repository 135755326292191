<vue-page-header appName="{{ 'title.applicationName' | translate | async }}"
  pageTitle="{{ 'title.poolconsumptionpage' | translate | async }}"
  navSection="{{ 'title.alerts' | translate | async }}">
  <app-filter-container>
    <app-poolconsumption-filters></app-poolconsumption-filters>
    <app-add-pool-consumption-drawer (success)="getaddPoolconsumption($event)"></app-add-pool-consumption-drawer>
  </app-filter-container>
</vue-page-header>
<app-page-body>
  <app-error-message *ngIf="status === 'error'" [heading]="displayableServerError?.title"
    [message]="displayableServerError?.message">
  </app-error-message>
  <vue-section>
    <app-poolconsumption-table></app-poolconsumption-table>
  </vue-section>
</app-page-body>
<vue-toast icon="success">
  <span toast-body>
    {{toastMessage}}
  </span>
  <a *ngIf="showViewInToast" (click)="viewNewPool()" toast-action>
    {{ 'button.viewPoolConsumption' | translate | async }}
  </a>
</vue-toast>