import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { utc } from 'moment';
import { CandidatesDownloadDialogComponent, CandidatesDownloadInput, downloadCandidatesConfig } from 'components/dialogs/candidates-download-dialog/candidates-download-dialog.component';
import { POPOVER_DETAIL_FORMAT } from 'constants/date-formats';
import { AppointmentsFiltersService } from 'services/appointments-filters.service';
import { ChartClickEvent } from 'types/ChartClickEvent';
import { FlaggedCapacityMetricItem } from 'types/FlaggedCapacityMetricItem';
import { isSupportedChartType, SupportedChartType } from 'types/SupportedChartType';

/**
 * Popover with details of the active time in the chart.  Includes a button to view daily details
 * Displayed on the daily and weekly appointment charts when a bar or line is clicked.
 */
@Component({
  selector: 'app-appt-detail-chart-popover',
  templateUrl: './appt-detail-chart-popover.component.html',
})
export class ApptDetailChartPopoverComponent implements OnInit, OnChanges {
  /**
   * Shows weekly oriented actions
   */
  @Input() public showWeeklyActions = false;

  /**
   * Metric items associated with the chart
   */
  @Input() public metricItems?: FlaggedCapacityMetricItem[]

  /**
   * Click event passed from the chart
   */
  @Input() public event?: ChartClickEvent

  /**
   * Title of popover, formatted date
   */
  public title?: string;

  /**
   * Timestamp of the date for the popover
   */
  public timestamp = '';

  public details: {
    type: SupportedChartType;
    color: string;
    label: string;
    quantity: string;
  }[] = [];

  public constructor(
    private filterService: AppointmentsFiltersService,
    private dialog: MatDialog,
  ) { }

  public ngOnInit(): void {
    this.buildContent();
  }

  public ngOnChanges(): void {
    this.buildContent();
  }

  /*
   * Opens download status dialog
   */
  public openDownloadDialog(): void {
    this.dialog.open<CandidatesDownloadDialogComponent, CandidatesDownloadInput>(CandidatesDownloadDialogComponent, {
      ...downloadCandidatesConfig,
      data: {
        candidateDownloadPayload: {
          timestamp: this.timestamp,
          queueId: this.filterService.getCurrentValue().queueId,
          interval: this.filterService.getCurrentValue().interval
        }
      }
    });
  }

  /**
   * Construct chart & popover content
   */
  private buildContent(): void {
    if (!this.event || !this.metricItems) {
      return;
    }
    const chart = this.event.event.chart;
    const index = (this.event.active || [])[0].index;
    const datasets = chart.data.datasets;
    const metricItem = this.metricItems[index];

    this.timestamp = utc(metricItem.timestamp).format();
    this.title = utc(metricItem.timestamp).format(POPOVER_DETAIL_FORMAT);

    /* this.details = (this.event.active || []).map((active) => {
      const { datasetIndex } = active;
      const dataset = datasets[ datasetIndex ];
      const { data } = dataset;
      const label = dataset.label || '';
      const type: SupportedChartType = isSupportedChartType(dataset.type) ? dataset.type : 'line';
      const quantity = String(data[ index ]);
      const color = Array.isArray(dataset.backgroundColor) ? dataset.backgroundColor[ index ] : dataset.backgroundColor;

      return { type, color, label, quantity };
    }); */

    this.details = datasets.map((data) => {
      const label = data.label || '';
      const type: SupportedChartType = isSupportedChartType(data.type) ? data.type : 'line';
      const quantity = String(data?.data[index]);
      const color = Array.isArray(data.backgroundColor) ? data.backgroundColor[index] : data.backgroundColor;

      return{ type, color, label, quantity };
    })

  }
}
