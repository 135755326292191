import { Injectable } from '@angular/core';
import { BaseStatus } from './status.class';

/**
 * Service to track when the state of auto complete service calls
 */
@Injectable({
  providedIn: 'root'
})
export class AutoCompleteStatusService extends BaseStatus {}
