<app-chart-legend [categories]="legendCategories"></app-chart-legend>
<app-chart-with-custom-y-axis>
  <app-custom-y-axis
    label="{{ timezoneLabel | async }}"
    [secondaryLabel]="secondaryTimezoneLabel"
    [widthRems]="firstColumnWidthRems"
    [max]="yAxisMax"
    [stepSize]="yAxisStepSize"
    [chartHeightRems]="chartHeightRems"
    [isDaily]="true">
  </app-custom-y-axis>
  <div style="overflow-x: scroll" id="dailyscroll" #dailyscroll (scroll)="onScrolldaily($event)">
    <app-custom-x-axis
      [labels]="xAxisLabels"
      [secondaryLabels]="secondaryXAxisLabels"
      [ngStyle]="{ 'width.rem': chartWidthRems }">
    </app-custom-x-axis>
    <app-chart-canvas-wrapper [chartWidthRems]="chartWidthRems" [chartHeightRems]="chartHeightRems">
      <canvas
        baseChart
        [datasets]="datasets"
        [labels]="xAxisLabels"
        [options]="options"
        [vueChartPopover]="popoverTemplate"
        (popoverWillOpen)="setPopoverEvent($event)"
        [attr.aria-label]="'label.aria.chartShowingAppointmentCapacity.seeBelow' | translate | async"
        role="img">
      </canvas>
    </app-chart-canvas-wrapper>
  </div>
  <!-- <app-synched-scroll-button direction="forward"></app-synched-scroll-button> -->
</app-chart-with-custom-y-axis>
<ng-template #popoverTemplate>
  <app-appt-detail-chart-popover
    *ngIf="popoverEvent"
    [event]="popoverEvent"
    [metricItems]="metricItems">
  </app-appt-detail-chart-popover>
</ng-template>
