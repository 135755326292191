// Error key constants used for datepicker errors

export const PAST_DATE = 'past-date';
export const START_CHRONOLOGICAL_ORDER = 'start-chronological-order';
export const END_CHRONOLOGICAL_ORDER = 'end-chronological-order';
export const INVALID_CHRONOLOGICAL_ORDER = 'invalid-chronological-order';
export const INVALID_DATE = 'invalid-date';
export const NON_SUNDAY_DATE = 'non-sunday-date';
export const NON_SATURDAY_DATE = 'non-saturday-date';
export const NON_MOMENT_DATE = 'non-moment-dates';
export const EXCEEDS_END_DATE = 'exceeds-end-date';

/** Matches error key given by material datepicker */
export const DATE_PARSE_ERROR = 'matDatepickerParse';
