import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { PoolStep } from 'components/drawers/add-pool-drawer/add-pool-state/add-pool-state.service';
import { AlertConsumptionPoolService } from './api/alert-consumption-pool.service';
import { stringify } from 'postcss';

@Injectable({
  providedIn: 'root'
})
export class AddAlertPoolDataService {
  getStatusforweedays: any
  public step$ = new BehaviorSubject<PoolStep>('form');
  private SelectedButtonDisable = new BehaviorSubject<any>(-1);
  private SelectedBtndisabled = new BehaviorSubject<any>(true);
  private copyaddconsumption = new Subject<any>();
  private SelectedRowSubject = new BehaviorSubject<any>(null);
  private CreateAlertPool = new BehaviorSubject<any>(null);
  private editPoolData = new BehaviorSubject<any>(null);
  private getcreateAlertconsumptionApi = new BehaviorSubject<any>(null);
  private getcreatealerterrorMessage = new BehaviorSubject<any>(null);
  private tableFilterChange = new BehaviorSubject<any>(false);
  private calendarObject = new BehaviorSubject<any>(null);
  private refresh = new Subject<any>();
  private errorobject = new BehaviorSubject<any>(null);

  selectedRow$ = this.SelectedRowSubject.asObservable();
  copyaddconsumptions$ = this.copyaddconsumption.asObservable();
  selectedbutton$ = this.SelectedButtonDisable.asObservable();
  selectedAlertConsumption$ = this.CreateAlertPool.asObservable();
  selectedbuttonDisabled$ = this.SelectedBtndisabled.asObservable();
  getSubscribeforEditpoolata$ = this.editPoolData.asObservable();
  getcreateAlertconsumption$ = this.getcreateAlertconsumptionApi.asObservable();
  getcreatealerterrorMessageob$ = this.getcreatealerterrorMessage.asObservable();
  gettableFilterUpdate$ = this.tableFilterChange.asObservable();
  refreshNeeded$ = this.refresh.asObservable();
  calendarObjects$ = this.calendarObject.asObservable();
  errorobjects$ = this.errorobject.asObservable();

  constructor(private poolsService: AlertConsumptionPoolService,) { }

  setSelectedRow(rowData: any) {
    this.SelectedRowSubject.next(rowData);
  }

  setDisabledButtonValue(disabled: any) {
    this.SelectedBtndisabled.next(disabled);
  }

  getupdatedlist(data: any) {
    this.errorobject.next(data);
  }

  public resetState(): void {
    this.showStep('form');
  }

  public showStep(step: PoolStep): void {
    this.step$.next(step);
  }

  getcalendarDataobject(id: any) {
    this.calendarObject.next(id);
  }

  setDisableButton(checkbox: any) {
    this.SelectedButtonDisable.next(checkbox);
  }

  createAlertConsumption(event: any): Observable<any> {
    const payload: any = this.constructPoolPayload(event);
    return this.poolsService.createPool(payload);
  }

  editAlertConsumption(event: any, id: any) {
    const payload: any = this.constructPoolPayload(event);
    return this.poolsService.createEditPool(payload, id);
  }

  constructPoolPayload(dataJson: any) {
    let value: any = []
    dataJson.run_Frequency.FormGroup.Time.forEach((ele: any) => {
      let arraylist: any = [];
      ele.weekDays.forEach((data: any) => {
        if (data.checked == true)
          arraylist.push(data.value);
      })
      value.push(
        {
          "runTime": ele.Time,
          "runPriority": ele.Priority,
          "isCritical": ele.Critical,
          "daysFlag": arraylist.length == 7 ? "daily" : "weekly",
          "runDaysOfWeek": arraylist
        })
    });

    let newStartFormattedDate;
    let newEndFormattedDate;
    if ((typeof dataJson?.search_pool.FormGroup.start._i !== 'string') && (typeof dataJson?.search_pool.FormGroup.EndDate._i !== 'string')) {
      newStartFormattedDate = new Date(Date.UTC(dataJson?.search_pool.FormGroup.start._i.year, dataJson?.search_pool.FormGroup.start._i.month, dataJson?.search_pool.FormGroup.start._i.date)).toISOString().split('T')[0];
      newEndFormattedDate = new Date(Date.UTC(dataJson?.search_pool.FormGroup.EndDate._i.year, dataJson?.search_pool.FormGroup.EndDate._i.month, dataJson?.search_pool.FormGroup.EndDate._i.date)).toISOString().split('T')[0];
    }
    else if ((typeof dataJson?.search_pool.FormGroup.start._i !== 'string') || (typeof dataJson?.search_pool.FormGroup.EndDate._i !== 'string')) {
      newStartFormattedDate = (typeof dataJson?.search_pool.FormGroup.start._i !== 'string') ? new Date(Date.UTC(dataJson?.search_pool.FormGroup.start._i.year, dataJson?.search_pool.FormGroup.start._i.month, dataJson?.search_pool.FormGroup.start._i.date)).toISOString().split('T')[0] : dataJson?.search_pool.FormGroup.start._i;
      newEndFormattedDate = (typeof dataJson?.search_pool.FormGroup.EndDate._i !== 'string') ? new Date(Date.UTC(dataJson?.search_pool.FormGroup.EndDate._i.year, dataJson?.search_pool.FormGroup.EndDate._i.month, dataJson?.search_pool.FormGroup.EndDate._i.date)).toISOString().split('T')[0] : dataJson?.search_pool.FormGroup.EndDate;
    };
    let startdate = (typeof dataJson?.search_pool.FormGroup.start._i === 'string') ? dataJson?.search_pool.FormGroup.start._i : newStartFormattedDate;
    let enDate = (typeof dataJson?.search_pool.FormGroup.EndDate._i === 'string') ? dataJson?.search_pool.FormGroup.EndDate._i : newEndFormattedDate;
    let payload = {
      "name": dataJson?.getDatafromAlert,
      "type": "Pool Consumption",
      "startDate": startdate,
      "endDate": enDate,
      "poolId": dataJson?.search_pool.poolId,
      "queueId": dataJson?.search_pool.QueueId,
      "isTimeRangeCheck": dataJson?.search_pool?.FormGroup.isTimeRangeCheck,
      "isDateRangeCheck": dataJson?.search_pool?.FormGroup.isDateRangeCheck,
      "PC_Schedule": {
        "items": dataJson?.search_pool?.FormGroup.startTime
      },
      "threshold": {
        "items": dataJson?.Threshold?.FormGroup.Threshold
      },
      "runFrequency": {
        "items": value
      },
    }

    return payload;
  }

  getdataforEditpool(poolData: any) {
    this.editPoolData.next(poolData);
  }

  getErrorMessages(error: any) {
    this.getcreatealerterrorMessage.next(error);
  }

  getFilterSubchildChanges(filter: any) {
    this.tableFilterChange.next(filter);

  }

  getDatafromSubmtform(submit: any, status: any) {
    this.copyaddconsumption.next({ key: submit, value: status });
  }

  triggerRefresh() {
    this.refresh.next();
  }
}