<button
  class="sort-button"
  (click)="sortColumn.emit()"
  [disabled]="!tableHasData"
>
  <ng-content></ng-content>
  <img
    *ngIf="tableHasData && isSorted"
    [ngClass]="sortDirection"
    src="assets/icons/icon-sort-arrow.svg"
    alt=""
  >
</button>
