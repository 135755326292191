<vue-page-header
  appName="{{ 'title.applicationName' | translate | async }}"
  navSection="{{ 'title.pools' | translate | async }}"
  [enableBack]="true"
  [pageTitle]="poolDetails.name"
></vue-page-header>
<app-page-body>
  <vue-section-header class="details-header-wrapper">
    <h2 class="details-header-text">{{ "title.poolDetails" | translate | async }}</h2>
    <app-edit-pool-drawer
      *ngIf="isPoolEditable()"
      [poolId]="poolDetails.id"
    ></app-edit-pool-drawer>
    <vue-flat-button *ngIf="isPoolArchived() && !isPoolEnds()" (clicked)="poolUnarchived()">
      {{ "label.poolOccurrence.unarchived" | translate | async }}
    </vue-flat-button>
  </vue-section-header>
  <app-edit-pool-toast></app-edit-pool-toast>
  <app-server-error-unsaved
    *ngIf="showFetchingPoolError"
    [includeUnsaved]="false"
    (retry)="retryFetchingPool()"
  ></app-server-error-unsaved>
  <vue-section  [loading]="pageLoading">
    <div class="details-wrapper">
      <div class="detail-column-wrapper">
        <h3 class="detail-column-header">{{ "title.overview" | translate | async }}</h3>
        <div class="detail-column-section">
          <vue-detail-header>{{ "title.poolDetails" | translate | async }}</vue-detail-header>
          <vue-detail-content>{{ poolDetails.name }}</vue-detail-content>
        </div>
        <div class="detail-column-section">
          <vue-detail-header>{{ "title.client" | translate | async }}</vue-detail-header>
          <vue-detail-content *ngFor="let client of poolDetails.clients">{{ client.name }}</vue-detail-content>
        </div>
        <div class="detail-column-section">
          <vue-detail-header>{{ "title.exams" | translate | async }}</vue-detail-header>
          <ng-container *ngIf="examsToDisplay.length; else dash">
            <p *ngFor="let exam of examsToDisplay" class="exam-name">{{ exam.examCode }}</p>
            <button *ngIf="moreThanFiveExams()" (click)="toggleExamsDisplay()" class="exam-show-toggle-button">
              <ng-container *ngIf="examsToDisplay.length > 5">
                {{'button.showLess' | translate | async }}
              </ng-container>
              <ng-container *ngIf="examsToDisplay.length <= 5">
                {{'button.showMore' | translate | async }}
              </ng-container>
            </button>
          </ng-container>
          <ng-template #dash>
            <p>–</p>
          </ng-template>
        </div>
        <div class="detail-column-section">
          <vue-detail-header>{{ "title.queue" | translate | async }}</vue-detail-header>
          <vue-detail-content>{{ queueName }}</vue-detail-content>
        </div>
        <div class="detail-column-section">
          <vue-detail-header>{{ "title.candidateCode" | translate | async }}</vue-detail-header>
          <vue-detail-content>{{ poolDetails.candidateCode || '–'}}</vue-detail-content>
        </div>
      </div>
      <div class="detail-column-wrapper">
        <h3 class="detail-column-header">{{ "title.date" | translate | async }}</h3>
        <div class="detail-column-section">
          <vue-detail-header>{{ "title.dateRange" | translate | async }}</vue-detail-header>
          <vue-detail-content>{{ formattedStartDate }} – {{ formattedEndDate }}</vue-detail-content>
        </div>
        <div class="detail-column-section">
          <vue-detail-header>{{ "title.status" | translate | async }}</vue-detail-header>
          <div class="chip-wrapper">
            <app-pool-status
              [status]="poolDetails.status"
            ></app-pool-status>
          </div>
        </div>
      </div>
      <div class="detail-column-wrapper">
        <h3 class="detail-column-header">{{ "title.time" | translate | async }}</h3>
        <div class="detail-column-section">
          <vue-detail-header>{{ "title.time.utc" | translate | async }}</vue-detail-header>
          <vue-detail-content>{{ poolTimeRange }}</vue-detail-content>
        </div>
      </div>
      <div class="detail-column-wrapper">
        <h3 class="detail-column-header">{{ "title.capacity" | translate | async }}</h3>
        <div class="detail-column-section">
          <vue-detail-header>
            {{ "title.defaultAppointmentsPerDay" | translate | async }}
            <vue-image-tooltip
              imageSrc="/assets/icons/icon-exclamation.svg"
              content="{{ 'message.poolDetails.tooltip.appointments' | translate | async }}"
            ></vue-image-tooltip>
          </vue-detail-header>
          <vue-detail-content>{{ poolDetails.capacity }}</vue-detail-content>
        </div>
        <div class="detail-column-section">
          <vue-detail-header>{{ "title.restriction" | translate | async }}</vue-detail-header>
          <vue-detail-content>
            <span *ngIf="poolDetails.isRestricted">
              {{ 'title.restricted' | translate | async }}
            </span>
            <span *ngIf="!poolDetails.isRestricted">
              {{ 'title.unrestricted' | translate | async }}
            </span>
          </vue-detail-content>
        </div>
        <div class="detail-column-section">
          <vue-detail-header>{{ "title.autoRelease" | translate | async }}</vue-detail-header>
          <vue-detail-content>{{ formattedAutorelease | async }}</vue-detail-content>
        </div>
      </div>
    </div>
  </vue-section>
  <vue-section-header class="details-header-wrapper">
    <h2 class="details-header-text">{{ "title.occurrences" | translate | async }}</h2>
  </vue-section-header>
  <app-server-error-unsaved
    *ngIf="showOccurrenceActionError"
    (retry)="retryOccurrenceAction()"
  ></app-server-error-unsaved>
  <vue-section  [loading]="pageLoading">
    <app-pool-detail-occurrence-table
      (refreshPool)="refreshPoolDetails()"
      [pool]="poolDetails"
      [registrations]="registrations"
    ></app-pool-detail-occurrence-table>
  </vue-section>
</app-page-body>
