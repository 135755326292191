import { Component, Input } from '@angular/core';

/**
 *  Standard presentation for table headers
 */
@Component({
  selector: 'app-table-header-cell',
  templateUrl: './table-header-cell.component.html',
  styleUrls: [ './table-header-cell.component.scss' ]
})
export class TableHeaderCellComponent {
  @Input()
  public label = '';

  @Input()
  public secondaryLabel = '';

  @Input()
  public alignLeft = false;
}
