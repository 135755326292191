/* eslint-disable @typescript-eslint/no-use-before-define */
import { Component, Inject, Input } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { VueFormFieldBaseComponent } from '../../utilities/vue-form-field-base/vue-form-field-base.component';

/**
 * An input for collecting percentage values. Uses ngx-mask to format input.
 */
@Component({
  selector: 'vue-percent-input',
  templateUrl: './vue-percent-input.component.html',
  styleUrls: [ './vue-percent-input.component.scss' ],
  providers: [ {
    provide: NG_VALUE_ACCESSOR, useExisting: VuePercentInputComponent, multi: true
  } ]
})
export class VuePercentInputComponent extends VueFormFieldBaseComponent {
  /**
   * An ID to apply to the <input>
   */
  @Input() public id = '';

  /**
   * Test cases need injected container
   */
  public constructor(@Inject(ControlContainer) controlContainer: ControlContainer) {
    super(controlContainer);
  }

  /**
   * On blur, if value does not include two decimal places, add them now, eg: 5 -> 5.00
   */
  public handleBlur(): void {
    if (this.control && this.control.value) {
      this.control.setValue(parseFloat(this.control.value).toFixed(2));
    }
  }
}
