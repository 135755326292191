/* eslint-disable no-invalid-this */
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { utc } from 'moment';
import { CandidatesDownloadDialogComponent, CandidatesDownloadInput, downloadCandidatesConfig } from 'components/dialogs/candidates-download-dialog/candidates-download-dialog.component';
import { EXCHANGE_FORMAT } from 'constants/date-formats';
import { INTERVAL_1HR } from 'constants/intervals';
import { TranslationKey } from 'pipes/translate.pipe';
import { AppointmentsFiltersService } from 'services/appointments-filters.service';
import { Timescale } from 'types/AppointmentFilters';

// Internal type of an individual action
interface PopoverAction {

  /** Translation key of action copy */
  translationKey: TranslationKey;

  /** Method to be invoked on action selection */
  action: () => void;
}

/**
 * Actions for Weekly Appointment Popovers
 */
@Component({
  selector: 'app-appt-weekly-popover-actions',
  templateUrl: './appt-weekly-popover-actions.component.html',
  styleUrls: [ './appt-weekly-popover-actions.component.scss' ],
})
export class ApptWeeklyPopoverActionsComponent {
  /**
   * Timestamp of the day in context
   * In UTC format.
   */
  @Input() public timestamp = '';

  /**
   * Index of the active popover action button
   */
  public activeButton = 0;

  public popoverActions: PopoverAction[] = [
    { translationKey: 'button.dailyView', action: this.goToDailyView.bind(this) },
    { translationKey: 'button.downloadCandidates', action: this.openDownloadDialog.bind(this) },
  ]

  public constructor(
    private filterService: AppointmentsFiltersService,
    private dialog: MatDialog,
  ) {}

  /**
   * Show previous action
   */
  public previousAction(event: Event): void {
    event?.stopPropagation(); // stop popover from closing

    if (this.activeButton === 0) return;

    this.activeButton--;
  }

  /**
   * Show next action
   */
  public nextAction(event: Event): void {
    event?.stopPropagation(); // stop popover from closing

    if (this.activeButton === this.popoverActions.length - 1) return;

    this.activeButton++;
  }

  /*
   * Opens download status dialog
   */
  public openDownloadDialog(): void {
    this.dialog.open<CandidatesDownloadDialogComponent, CandidatesDownloadInput>(CandidatesDownloadDialogComponent, {
      ...downloadCandidatesConfig,
      data: {
        candidateDownloadPayload: {
          timestamp: this.timestamp,
          queueId: this.filterService.getCurrentValue().queueId,
          interval: this.filterService.getCurrentValue().interval
        }
      }
    });
  }

  /**
   * Switches the view to a daily view of the day clicked on
   */
  public goToDailyView(): void {
    this.filterService.updateTimescale({
      timescale: Timescale.daily,
      interval: INTERVAL_1HR,
      startDate: this.timestamp ?
        utc(this.timestamp).format(EXCHANGE_FORMAT) :
        utc().format(EXCHANGE_FORMAT)
    });
  }
}
