<h3><b>{{ 'title.resolve' | translate | async }}</b> - {{this.data.name}}</h3>
<mat-dialog-content>
  <p>
    {{"messages.confirmation" | translate | async }}
  </p>
  <div>
    <form id="form" [formGroup]="uploadCapacityForm" autocomplete="off">
    <vue-text-area label="{{ 'title.alert.resolve.comment' | translate | async }}" formControlName="comment"
      [required]="true" errorMessage="{{ commentErrorMessageKey() | translate | async }}"></vue-text-area>
    </form>
  </div>
</mat-dialog-content>
<div mat-dialog-actions>
  <vue-basic-button (clicked)="closeDialog()">{{ "button.cancel" |translate | async }} </vue-basic-button>
  <vue-flat-button [loading]="loadingStatus" [disabled]="!formIsValid" (clicked)="confirmDialogue()"> {{ "button.confirm" | translate | async
    }}</vue-flat-button>
</div>