import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButton } from '@angular/material/button';

/**
 * A base class for Vue buttons.  Should not be used directly! Use subclasses instead.
 */
@Component({
  template: ''
})
export class VueBaseButton {
  /**
   * Disables ripple effect when interaction occurs
   */
  @Input() public disableRipple = false;

  /**
   * Disables button
   */
  @Input() public disabled = false;

  /**
   * Buttons of type submit can be passed a form string
   */
  @Input() public form?: string;

  /**
   * Type passed to underlying button element
   */
  @Input() public type: 'button' | 'submit' | 'reset' = 'button';

  /**
   * Material color palette to apply
   */
  @Input() public color?: MatButton['color'];

  /**
   * Loading state.  Disables the button and displays a spinner instead of the content
   */
  @Input() public loading = false;

  /**
   * Button click event
   */
  @Output() public clicked = new EventEmitter<MouseEvent>();

  /**
   * Handle click on button
   *
   * @param event Event from click
   */
  public handleClick(event: MouseEvent): void {
    this.clicked.emit(event);
  }
}
