<app-error-message
  *ngIf="displayableServerError"
  [heading]="displayableServerError.title"
  [message]="displayableServerError.message"
></app-error-message>
<form id="form" [formGroup]="form" (ngSubmit)="submit()" autocomplete="off">
  <vue-select
    label="{{ 'label.placeholder.queues' | translate | async }}"
    formControlName="queue"
    [required]="true"
    [options]="queues"
    [getOptionDisplay]="getQueueDisplay"
    [errorMatcher]="suppressErrorState"
  ></vue-select>
  <h4>{{ 'title.date' | translate | async }}</h4>
  <div class="two-columns">
    <vue-datepicker
      label="{{ 'label.placeholder.startDate' | translate | async }}"
      placeholder="{{ 'placeholder.datepicker' | translate | async }}"
      formControlName="startDate"
      [required]="true"
      [errorMatcher]="datePickerErrorMatcher"
      [minDateIsToday]="true"
      [maxDate]="form.controls.endDate.value"
      [errorMessage]="form.controls.startDate.errors | datepickerError | translate | async"
    ></vue-datepicker>
    <vue-datepicker
      label="{{ 'label.placeholder.endDate' | translate | async }}"
      placeholder="{{ 'placeholder.datepicker' | translate | async }}"
      formControlName="endDate"
      [required]="true"
      [errorMatcher]="datePickerErrorMatcher"
      [minDate]="form.controls.startDate.value"
      [minDateIsToday]="true"
      [errorMessage]="form.controls.endDate.errors | datepickerError | translate | async"
    ></vue-datepicker>
  </div>
  <div class="date-range-title">
    <h4>{{ 'title.dateTimeRange' | translate | async }}</h4>
    <p>{{ 'message.setCapacity.bulkEdit.dateRangeInstructions' | translate | async }}</p>
  </div>

  <div class="two-columns no-padding-bottom">
    <vue-select
      label="{{ 'label.placeholder.startTime' | translate | async }}"
      formControlName="startTime"
      [required]="true"
      [options]="startTimes"
      [getOptionDisplay]="addUTCToTime.bind(this)"
      [errorMatcher]="suppressErrorState"
    ></vue-select>
    <vue-select
      label="{{ 'label.placeholder.endTime' | translate | async }}"
      formControlName="endTime"
      [required]="true"
      [options]="endTimes"
      [getOptionDisplay]="addUTCToTime.bind(this)"
      [errorMatcher]="suppressErrorState"
    ></vue-select>
  </div>
  <mat-checkbox formControlName="allDay">{{ "title.allDay" | translate | async }}</mat-checkbox>
  <app-bulk-capacity-edit
    formControlName="capacity"
  ></app-bulk-capacity-edit>
  <app-daily-time-exceptions
    formControlName="exceptions"
    [times]="exceptionTimes"
  ></app-daily-time-exceptions>
</form>
