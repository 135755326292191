import { Component } from '@angular/core';
import { ClientsPageDataService } from 'services/clients-page-data.service';

/**
 *  Clients page
 */
@Component({
  selector: 'app-clients-page',
  templateUrl: './clients-page.component.html',
})
export class ClientsPageComponent {
  public constructor(
    private clientsPageDataService: ClientsPageDataService) {
  }
  public ngOnInit(): void {
    this.clientsPageDataService.fetchClientsData();
  }
}
