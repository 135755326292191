import { Injectable, RendererFactory2 } from '@angular/core';

/**
 *  Service that detects user activity by listening for scroll, mouse and keyboard events.
 */
@Injectable({
  providedIn: 'root'
})
export class ActivityDetectorService {
  /**
   * Internally track the last time an activity event was triggered, for simple debouncing
   */
  private lastTriggered = 0;

  public constructor(private rendererFactory2: RendererFactory2) {}

  /**
   * Listen for app-wide scroll, mouse or keydown events and update lastActivityTimestamp.
   *
   * Remains active for the life of the app.
   */
  public init(): void {
    const renderer = this.rendererFactory2.createRenderer(null, null);
    const handler = (): void => this.activityDetected();
    renderer.listen('document', 'click', handler);
    renderer.listen('document', 'keydown', handler);

    // Provide an initial value
    this.activityDetected();
  }

  /**
   * Get the timestamp indicating the last time activity was detected from sessionStorage
   */
  public lastActivityTimestamp(): number {
    return parseInt(sessionStorage.getItem('lastActivityTimestamp') || '0', 10);
  }

  /**
   * Update the lastActivityTimestamp in sessionStorage.
   * We use sessionStorage to allow multiple tabs to share the most current value.
   */
  private activityDetected(): void {
    // Simple debouncing; only record if it's been more than a second since the last trigger
    if (Date.now() > (this.lastTriggered + 1000)) {
      this.lastTriggered = Date.now();
      sessionStorage.setItem('lastActivityTimestamp', String(this.lastTriggered));
    }
  }
}
