import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isMoment } from 'moment';
import { NON_MOMENT_DATE, NON_SATURDAY_DATE, NON_SUNDAY_DATE } from 'constants/datepicker-errors';

/**
 * Validation logic for apply capacity template dates
 *
 * Note: Validator should be set at the form level, not the control level
 */
export function applyCapacityDateValidator(form: AbstractControl): ValidationErrors | null {
  const errors: ValidationErrors = {};
  const startDate = form.get('startDate');
  const endDate = form.get('endDate');

  if (startDate === null || endDate === null) {
    return { 'missing-controls': { startDate: startDate?.value, endDate: endDate?.value } };
  }

  // Verify that both are moment objects
  if (!isMoment(startDate.value) || !isMoment(endDate.value)) {
    errors[ NON_MOMENT_DATE ] = { startDate: startDate.value, endDate: endDate.value };
  }

  if (isMoment(startDate.value) && startDate.value.day() !== 0) {
    errors[ NON_SUNDAY_DATE ] = startDate.value;
    startDate.setErrors({ ...startDate.errors, [ NON_SUNDAY_DATE ]: startDate.value });
  }

  if (isMoment(endDate.value) && endDate.value.day() !== 6) {
    errors[ NON_SATURDAY_DATE ] = endDate.value;
    endDate.setErrors({ ...endDate.errors, [ NON_SATURDAY_DATE ]: endDate.value });
  }

  return Object.keys(errors).length ? errors : null;
}
