import { ConnectionPositionPair, Overlay } from '@angular/cdk/overlay';
import { FlexibleConnectedPositionStrategyOrigin } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector, TemplateRef } from '@angular/core';
import { VueOverlayOpenDirective } from 'vue/directives/vue-overlay-open/vue-overlay-open.directive';

import { VuePopoverConfig } from './vue-popover-config';
import { VuePopoverRef } from './vue-popover-ref';
import { VuePopoverComponent } from './vue-popover/vue-popover.component';

export const defaultConfig: VuePopoverConfig = {
  backdropClass: '',
  disableClose: false,
  panelClass: '',
  arrowOffset: 30,
  arrowSize: 16
};

/**
 * Service to open modal and manage popovers.
 */
@Injectable({
  providedIn: 'root'
})
export class VuePopoverService {
  // Internal registry of any open popovers
  private popoverRefs: VuePopoverRef[] = [];

  public constructor(
    private overlay: Overlay,
    private injector: Injector,
    private vueOverlayOpen: VueOverlayOpenDirective,
  ) {}

  // Mostly this is split off for easier mocking
  private static buildComponentPortal(
    popoverRef: VuePopoverRef, parent: Injector
  ): ComponentPortal<VuePopoverComponent> {
    return new ComponentPortal<VuePopoverComponent>(
      VuePopoverComponent,
      null,
      Injector.create({
        parent,
        providers: [ { provide: VuePopoverRef, useValue: popoverRef } ]
      }));
  }

  // Preferred popover positions, in order of priority
  private static getPositions(popoverConfig: VuePopoverConfig): ConnectionPositionPair[] {
    const arrowSize = popoverConfig.arrowSize || 0;
    const arrowOffset = popoverConfig.arrowOffset || 0;
    const panelOffset = arrowSize / 2;
    return [

      // top center
      {
        overlayX: 'center',
        overlayY: 'bottom',
        originX: 'center',
        originY: 'top',
        panelClass: [ 'bottom', 'center' ],
        offsetY: -1 * panelOffset
      },

      // top left
      {
        overlayX: 'start',
        overlayY: 'bottom',
        originX: 'center',
        originY: 'top',
        panelClass: [ 'bottom', 'left' ],
        offsetX: -1 * arrowOffset,
        offsetY: -1 * panelOffset
      },

      // top right
      {
        overlayX: 'end',
        overlayY: 'bottom',
        originX: 'center',
        originY: 'top',
        panelClass: [ 'bottom', 'right' ],
        offsetX: arrowOffset,
        offsetY: -1 * panelOffset
      },

      // bottom center
      {
        overlayX: 'center',
        overlayY: 'top',
        originX: 'center',
        originY: 'bottom',
        panelClass: [ 'top', 'center' ],
        offsetY: panelOffset
      },

      // bottom left
      {
        overlayX: 'start',
        overlayY: 'top',
        originX: 'center',
        originY: 'bottom',
        panelClass: [ 'top', 'left' ],
        offsetX: -1 * arrowOffset,
        offsetY: panelOffset
      },

      // bottom right
      {
        overlayX: 'end',
        overlayY: 'top',
        originX: 'center',
        originY: 'bottom',
        panelClass: [ 'top', 'right' ],
        offsetX: arrowOffset,
        offsetY: panelOffset
      }
    ];
  }

  /**
   * Open a popover
   *
   * @param templateRef The template to be rendered in the popover
   * @param origin Where the popover should be displayed.  Can be an elementRef or the coordinates of a rectangle.
   * @param config Options for the popover.
   * @returns popoverRef the reference to the opened popover
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public open<D = any>(
    templateRef: TemplateRef<D>,
    origin: FlexibleConnectedPositionStrategyOrigin,
    config: Partial<VuePopoverConfig> = {}): VuePopoverRef<D> {
    const popoverConfig: VuePopoverConfig = Object.assign({}, defaultConfig, config);

    // Possible positions the popover can take
    const positions: ConnectionPositionPair[] = VuePopoverService.getPositions(popoverConfig);

    // How the popover decides which position to use
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(origin)
      .withPush(false)
      .withFlexibleDimensions(false)
      .withPositions(positions);

    const overlayRef = this.overlay.create({
      hasBackdrop: false,
      panelClass: config.panelClass,
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition()
    });

    const popoverRef = new VuePopoverRef(overlayRef, positionStrategy, popoverConfig, this.vueOverlayOpen);

    // Instantiate a VuePopoverComponent and attach it to the overlay
    const popoverPortal = VuePopoverService.buildComponentPortal(popoverRef, this.injector);
    const popoverComponent = overlayRef.attach(popoverPortal).instance;

    // Give the popover a template to render
    popoverComponent.attachTemplateRef(templateRef);

    this.popoverRefs.push(popoverRef);

    // Add class to body, usually used for navigation bars to adjust z-index
    this.vueOverlayOpen.addClassName();

    return popoverRef;
  }

  /**
   * Close all open popovers
   */
  public closeAll(): void {
    this.popoverRefs.forEach((p) => p.close());
    this.popoverRefs = [];
  }
}
