<h1 mat-dialog-title>{{data.title | async}}</h1>
<div mat-dialog-content style="overflow: hidden;">
  <div *ngIf="isPoolConfigurationEditable()">
    <mat-checkbox style="font-weight: bold;margin-top: 2%;" [(ngModel)]="poolName">{{ "title.pool" | translate | async }}
    </mat-checkbox>
    <!-- <div style="padding-left: 29px;">{{data.poolName}}</div> -->
  </div>
  <div>
    <mat-checkbox style="font-weight: bold;margin-top: 2%;" [(ngModel)]="dateRange">{{ "title.dateRange" | translate | async
      }}</mat-checkbox>
    <!-- <div style="padding-left: 29px;">{{ data.formattedPoolStartDate }} – {{ data.formattedPoolEndDate }}</div> -->
  </div>
  <div>
    <mat-checkbox style="font-weight: bold;margin-top: 2%;" [(ngModel)]="timeRange">{{ "title.timeRange" | translate | async
      }}</mat-checkbox>
    <!-- <div style="padding-left: 29px;">{{ data.poolStartTime }} – {{ data.poolEndTime }}</div> -->
  </div>
  <div>
    <mat-checkbox style="font-weight: bold;margin-top: 2%;" [(ngModel)]="threshold">{{ "title.threshold" | translate | async
      }}</mat-checkbox>
    <!-- <ng-container *ngIf="data.threshold.length; else dashThreshold">
      <div style="padding-left: 29px;" *ngFor="let element of data.threshold">
        {{ element.percentage }}%, P{{ element.priority }}, {{ element.email }}</div>
    </ng-container>
    <ng-template #dashThreshold>
      <p style="padding-left: 29px;">–</p>
    </ng-template> -->
  </div>
  <div>
    <mat-checkbox style="font-weight: bold;margin-top: 2%;" [(ngModel)]="runFrequency">{{ "title.runFrequency" | translate | async
      }}</mat-checkbox>
    <!-- <ng-container *ngIf="data.runFrequency.length; else dashRunFrequency">
      <div style="padding-left: 29px;" *ngFor="let element of data.runFrequency">
        {{ formatRunDaysOfWeek(element.runDaysOfWeek) }} @ {{ element.runTime }} (UTC), P{{ element.runPriority }},
        {{setCritical(element.isCritical)}}</div>
    </ng-container>
    <ng-template #dashRunFrequency>
      <p style="padding-left: 29px;">–</p>
    </ng-template> -->
  </div>
</div>
<div mat-dialog-actions>
  <vue-basic-button (clicked)="closeDialog()">
    {{ "button.cancel" | translate | async }}
  </vue-basic-button>
  <vue-flat-button (clicked)="action()">
    {{ "button.copy" | translate | async }}
  </vue-flat-button>
</div>