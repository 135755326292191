<div class="success" *ngIf="status === 'success'" [@fadeInOut]>
  <ng-content></ng-content>
</div>
<div class="loading" *ngIf="status === 'loading'" [@fadeInOut]>
  <mat-spinner diameter="32"></mat-spinner>
</div>
<div class="error" *ngIf="status === 'error'" [@fadeInOut]>
  <div class="error-block">
    <h2>{{ 'title.error' | translate | async }}</h2>
    <p>{{ 'message.pageStatus.error' | translate | async }}</p>
    <vue-flat-button (click)="this.retry()">{{ 'label.pageStatus.retry' | translate | async }}</vue-flat-button>
  </div>
</div>
