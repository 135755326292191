import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Moment, utc } from 'moment';
import { EXCHANGE_FORMAT } from 'constants/date-formats';

export type DateRangerFilterType = 'after' | 'before'

export interface DateRangeFilterEvent {
  type: DateRangerFilterType;
  date: string;
}

/**
 *  Filter component for setting date ranges
 */
@Component({
  selector: 'app-date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: [ './date-range-filter.component.scss' ]
})
export class DateRangeFilterComponent implements OnChanges {
  /**
   * Pending open after date
   * Format: YYYY-MM-DD
   */
  @Input() public pendingOpenAfterDate = '';

  /**
   * Pending open before date
   * Format: YYYY-MM-DD
   */
  @Input() public pendingOpenBeforeDate = '';

  /**
   * Event of which date type changed and the date itself
   * Type: 'after' | 'before'
   * Date format: YYYY-MM-DD
   */
  @Output() public dateChanged = new EventEmitter<DateRangeFilterEvent>();

  /**
   * Moment representation of pendingOpenAfterDate
   */
  public openAfterMoment: Moment | null = null;

  /**
   * Moment representation of pendingOpenBeforeDate
   */
  public openBeforeMoment: Moment | null = null;

  /**
   * pendingOpenAfterDate & pendingOpenBeforeDate are of type string
   * Convert them to moment objects so the date picker can handle them
   *
   * @param changes change object containing updated values
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if ('pendingOpenAfterDate' in changes) {
      const { currentValue } = changes.pendingOpenAfterDate;
      this.openAfterMoment = currentValue ? utc(currentValue) : null;
    }
    if ('pendingOpenBeforeDate' in changes) {
      const { currentValue } = changes.pendingOpenBeforeDate;
      this.openBeforeMoment = currentValue ? utc(currentValue) : null;
    }
  }

  /**
   * Handle change event from datepicker
   * If date exists convert it to YYYY-MM-DD format otherwise pass empty string
   *
   * @param type either 'after' or 'before' distinguishing between the inputs
   * @param date Moment object or null if blank
   */
  public dateChange(type: DateRangerFilterType, date: Moment | null): void {
    this.dateChanged.emit({
      type,
      date: date ? date.format(EXCHANGE_FORMAT) : ''
    });
  }
}
