import { UntypedFormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

/**
 *  Handle invalid date errors
 */
export class DatePickerErrorMatcher implements ErrorStateMatcher {
  /**
   * Only display errors if the form has a value or a date parsing error from the material date picker.
   */
  public isErrorState(control: UntypedFormControl | null): boolean {
    return Boolean(
      (control?.value || control?.errors?.matDatepickerParse) &&
      control?.touched &&
      control?.invalid
    );
  }
}
