import { Injectable, OnDestroy } from '@angular/core';
import { GetAlertsQueryParameters, GetAlertsResponse } from 'api/types/endpoints/getAlerts';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';

import { ALERTS_DEFAULT_VALUES, AlertsFiltersService } from './alerts-filters.service';
import { AlertsService } from './api/alerts.service';
import { PageStatusService } from './status/page-status.service';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { QueuesService } from './api/queues.service';
import { Queue } from 'api/types';
import { TranslatePipe } from 'pipes/translate.pipe';

const emptyResponse: GetAlertsResponse = {
  ...ALERTS_DEFAULT_VALUES,
  total: 0,
  items: []
};

@Injectable({
  providedIn: 'root'
})
export class AlertsPageDataService implements OnDestroy {

  /**
   * Fill initial response with empty values, filter component will populate on initialization
   * Private BehaviorSubject so only this service can alter the value
   */
  private readonly alertsResponse = new BehaviorSubject<GetAlertsResponse>(emptyResponse);

  /**
  * Private BehaviorSubject so only this service can alter the value
  */
  private readonly fetchingAlerts = new BehaviorSubject(false);

  private readonly queues = new BehaviorSubject<Queue[]>([]);

  /**
  * Observable that emits the current state of the alerts data
  */
  public alertsResponse$ = this.alertsResponse.asObservable();

  /**
   * Observable that emits a boolean if alerts are being fetched
   */
  public fetchingAlerts$ = this.fetchingAlerts.asObservable();

  public queues$ = this.queues.asObservable();

  /**
   * Terminates all subscriptions when completed
   */
  private destroyed$ = new Subject();

  /**
   * Emits when new outage data should be fetched
   */
  private fetchAlertsData$ = new Subject<GetAlertsQueryParameters>();

  private filtersData: any;

  private static readonly ALL_QUEUES_CODE = 'All';

  constructor(
    private alertFilterService: AlertsFiltersService,
    private alertsService: AlertsService,
    private pageStatusService: PageStatusService,
    private queueService: QueuesService,
    private translatePipe: TranslatePipe
  ) {
    this.fetchAlertsData$
      .pipe(takeUntil(this.destroyed$))
      .pipe(switchMap((params) => { // switchMap will cancel any in-flight API requests
        return this.fetchAlerts(params);
      }))
      .subscribe((res) => {
        this.alertsSuccessHandler(res);
      }, () => {
        this.alertsErrorHandler();
      });

    this.alertFilterService.params$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((filters) => {
        var allArray = ['All'];

        if (filters.queueIds?.find((e: any) => e == 'all')) {
          filters.queueIds = [];
          filters.queueIds = allArray;
        }

        if (filters.priorities?.find((e: any) => e == 'All')) {
          filters.priorities = [];
          filters.priorities = allArray;
        }

        this.filtersData = filters;
      });

    combineLatest([
      this.queueService.getQueues(),
      this.translatePipe.loadTranslations(['option.all']),
    ])
      .pipe(take(1))
      .subscribe(([queueResponse, translations]) => {
        // Create a filter option for all queues
        const allQueuesOption: Queue = {
          id: 'all',
          code: AlertsPageDataService.ALL_QUEUES_CODE,
          name: translations['option.all']
        };

        this.queues.next([allQueuesOption, ...queueResponse]);
        this.alertFilterService.updateDefaultQueueFilter([allQueuesOption]);
        this.alertFilterService.updateDefaultPriorityFilter(['All']);
      });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Gets the alerts data
   *
   * @param params params to pass to service
   */
  public fetchAlerts(params: GetAlertsQueryParameters): Observable<GetAlertsResponse> {
    this.pageStatusService.loading(() => {
      this.fetchAlertsData$.next(params);
    });

    this.fetchingAlerts.next(true);
    return this.alertsService.getAlerts(params);
  }

  /**
   * GetAlerts success handler
   */
  private alertsSuccessHandler(response: any): void {
    this.alertsResponse.next(response);
    this.fetchingAlerts.next(false);
    this.pageStatusService.success();
  }

  /**
   * GetOutage error handler
   */
  private alertsErrorHandler(): void {
    this.fetchingAlerts.next(false);
    this.pageStatusService.error();
  }

  public searchAlerts() {
    this.fetchAlertsData$.next(this.filtersData);
  }
}
