import { Pipe, PipeTransform } from '@angular/core';

/**
 *  A pipe to highlight display text that matches a given string
 */
@Pipe({
  name: 'boldMatchingText'
})
export class BoldMatchingTextPipe implements PipeTransform {
  public transform(text: string, matching: string): string {
    console.log('matching',matching)
    if (text && matching && typeof text === 'string' && typeof matching === 'string') {
      const regex = new RegExp(matching, 'i');
      let splittedText = text.split(':');
      console.log('regex1', regex);
      console.log('regex', text.match(regex));
      if(splittedText?.length == 2)
      {
        return splittedText[0] + ": " + splittedText[1].split(regex).join(`<b>${splittedText[1].match(regex)}</b>`);
      }
      return text.split(regex).join(`<b>${text.match(regex)}</b>`);
    }
    return text;
  }
}
