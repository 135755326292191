<vue-table-container [loading]="tableLoading">
  <table mat-table [dataSource]="activityItems">
    <caption class="visually-hidden">
      {{ 'table.caption.poolsRecentActivity' | translate | async }}
    </caption>

    <!-- Activity -->
    <ng-container matColumnDef="activity">
      <th
        mat-header-cell
        *matHeaderCellDef
        [tableHeaderAriaSort]="{sortDirection, isSorted: sortBy === 'activity' }"
      >
        <app-sortable-table-header
          [tableHasData]="!!activityItems.length"
          (sortColumn)="sort('activity')"
        >
          {{ 'title.activity' | translate | async }}
        </app-sortable-table-header>
      </th>
      <mat-cell *matCellDef="let activity">
        {{activity.activity}}
      </mat-cell>
    </ng-container>

    <!-- Activity Details -->
    <ng-container matColumnDef="details">
      <th
        mat-header-cell
        *matHeaderCellDef
      >
          {{ 'title.details' | translate | async }}
      </th>
      <mat-cell *matCellDef="let activity">
        {{activity.details}}
      </mat-cell>
    </ng-container>

    <!-- Activity Performed By -->
    <ng-container matColumnDef="user">
      <th
        mat-header-cell
        *matHeaderCellDef
        [tableHeaderAriaSort]="{sortDirection, isSorted: sortBy === 'user' }"
      >
        <app-sortable-table-header
          [tableHasData]="!!activityItems.length"
          (sortColumn)="sort('user')"
        >
          {{ 'title.performedBy' | translate | async }}
        </app-sortable-table-header>
      </th>
      <mat-cell *matCellDef="let activity">
        {{activity.user}}
      </mat-cell>
    </ng-container>

    <!-- Activity Date -->
    <ng-container matColumnDef="timestamp">
      <th
        mat-header-cell
        *matHeaderCellDef
        [tableHeaderAriaSort]="{sortDirection, isSorted: sortBy === 'timestamp' }"
      >
        <app-sortable-table-header
          [tableHasData]="!!activityItems.length"
          (sortColumn)="sort('timestamp')"
        >
          {{ 'title.date' | translate | async }}
        </app-sortable-table-header>
      </th>
      <mat-cell *matCellDef="let activity">
        {{activity.timestamp}}
      </mat-cell>
    </ng-container>

    <!-- Populate table with data -->
    <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
    <mat-row
      *matRowDef="let activity; columns: tableColumns; let i = index"
    ></mat-row>

    <!-- No data available -->
    <tr class="mat-row no-content" *matNoDataRow>
      <td class="mat-cell" colspan="9999">
        {{ 'message.recentActivity.noActivities' | translate | async }}
      </td>
    </tr>
  </table>

  <mat-paginator
    *ngIf="activityItems.length !== 0"
    previousPageIndex="0"
    (page)="paginatorChange($event)"
    [length]="totalItemCount"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    [showFirstLastButtons]="showFirstLastButtons"
  ></mat-paginator>
</vue-table-container>
