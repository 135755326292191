<app-base-chart-popover>
  <div class="legend">
    <div class="legend-item" *ngFor="let item of legendItems">
      <app-legend-marker [color]="item.color" [type]="item.type"></app-legend-marker>
      {{item.label}}
    </div>
  </div>
  <div *ngIf="loading;else chart" class="loading">
    <mat-spinner diameter="32"></mat-spinner>
  </div>
  <ng-template #chart>
    <div class="canvas-wrapper" [ngStyle]="canvasWrapperStyle">
      <canvas
        [height]="chartHeightPx"
        baseChart
        [datasets]="chartDatasets"
        [labels]="chartLabels"
        [options]="chartOptions"
        [attr.aria-label]="'label.aria.chartShowingAppointmentCapacity.selectedDate' | translate: screenReaderDate | async"
        role="img"
      ></canvas>
    </div>
  </ng-template>
  <app-custom-bottom-x-axis-labels [labels]="bottomXAxisLabels" [chartWidthRems]="chartWidthRems">
  </app-custom-bottom-x-axis-labels>
  <ng-container popover-action>
    <button (click)="goToDailyView()">
      {{ 'button.dailyView' | translate | async }}
    </button>
    <button (click)="downloadCandidates()">
      {{ 'button.downloadCandidates' | translate | async }}
    </button>
  </ng-container>
</app-base-chart-popover>
