import { Component, Input } from '@angular/core';

/**
 * A simple, presentational component to define the header area of a page
 */
@Component({
  selector: 'vue-page-header',
  templateUrl: './vue-page-header.component.html',
  styleUrls: [ './vue-page-header.component.scss' ]
})
export class VuePageHeaderComponent {
  /**
   * Application name
   */
  @Input() public appName = '';

  /**
   * Navigation Title
   */
  @Input() public navSection = '';

  /**
   * Page Title
   */
  @Input() public pageTitle = '';

  /**
   * Enable back button preceding pageTitle
   */
  @Input() public enableBack = false;
}
