<div
  cdkTrapFocus
  class="wrapper"
  role="dialog"
  aria-live="assertive"
  cdkTrapFocusAutoCapture
>
  <div class="title" *ngIf="title">{{title}}</div>
  <div class="body">
    <ng-content></ng-content>
  </div>
  <div class="action-wrapper">
    <ng-content select="[popover-action]"></ng-content>
  </div>
</div>
