<div class="wrapper">
  <vue-filter-select
    ariaLabel="{{ 'label.aria.timescale' | translate | async }}"
    [filter]="filters.timescale"
    [format]="formatTimescaleOption.bind(this)"
    (change)="filterChanged('timescale', $event.value)"
    groupPosition="first"
  ></vue-filter-select>
  <app-date-selection-filter
    [timescale]="filters.timescale.value"
    [startDate]="filters.startDate.value"
    (change)="filterChanged('startDate', $event.value)"
    groupPosition="last"
  ></app-date-selection-filter>
  <app-timezone-select [date]="dateForTimezone"></app-timezone-select>
</div>
