import { Component, Input } from '@angular/core';
import { PoolConsumptionStatus } from 'api/types/endpoints/getPoolConsumption';
import { VueChipColor } from 'vue/components/vue-chip/vue-chip.component';

/**
 * Wraps vue-chip for pool status display
 */
@Component({
  selector: 'app-poolconsumption-status',
  templateUrl: './poolconsumption-status.component.html',
})
export class PoolConsumptionStatusComponent {
  /**
   * Determines the color of the chip based on status
   */
  @Input() public status: PoolConsumptionStatus = 'open'

  /**
   * Determine chip color based on pool status
   */
  public get chipColor(): VueChipColor {
    switch (this.status) {
      case 'inactive':
      case 'active':
        return 'blue';
      default:
        return 'blue';
    }
  }
}
