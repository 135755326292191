/* eslint-disable @typescript-eslint/no-use-before-define, @typescript-eslint/no-explicit-any */
import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop, Subject, timer } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { BulkEditCapacity, BulkEditException } from 'api/types';
import { AddUTCToTimePipe } from 'pipes/add-utc-to-time.pipe';
import { TranslatePipe, TranslationLookup } from 'pipes/translate.pipe';
import { SuppressErrorMatcher } from 'utils/error-matchers/suppress.error-matcher';
import { focusOnElement } from 'utils/focus-on-element';

/**
 * Handle list of Daily Time Exceptions for BulkEditForm
 * Passes exception array back to the BulkEditForm
 */
@Component({
  selector: 'app-daily-time-exceptions',
  templateUrl: './daily-time-exceptions.component.html',
  styleUrls: [ './daily-time-exceptions.component.scss' ],
  providers: [ {
    provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(
      /* istanbul ignore next */
      () => DailyTimeExceptionsComponent,
    ),
    multi: true
  } ]
})
export class DailyTimeExceptionsComponent implements ControlValueAccessor, OnInit, OnDestroy {
  /**
   * Array of available times for exception
   * All of which are at the top of the hour
   * e.g 13:00, 14:00
   *
   * @default [] empty array
   */
  @Input() public times: string[] = [];

  /**
   * Form to track each exception as a separate control
   */
  public exceptionForm: UntypedFormGroup;

  /**
   * Logic options for altering capacity
   */
  public logicOptions: BulkEditCapacity['type'][] = [ 'new', 'add', 'subtract' ];

  /**
   * Hide error states of the form components
   * The form save button will stay disabled until the form is valid
   */
  public suppressErrorState = new SuppressErrorMatcher();

  /**
   * Change callback passed by the BulkEditForm
   */
  public onChangeCallback: (_: any) => void = noop;

  /**
   * Touched callback passed by the BulkEditForm
   */
  public onTouchedCallback: () => void = noop;

  private destroyed$ = new Subject();

  /**
   * A localized string lookup.
   */
  private translations: TranslationLookup = {};

  public constructor(
    private formBuilder: UntypedFormBuilder,
    private translatePipe: TranslatePipe,
    private addUTCToTimePipe: AddUTCToTimePipe,
  ) {
    // Create form without validation, validation will be handled by the BulkEditForm
    this.exceptionForm = formBuilder.group({
      exceptions: formBuilder.array([])
    });
  }

  /**
   * Update BulkEditForm with exception values
   */
  public ngOnInit(): void {
    this.exceptionForm.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.onChangeCallback(value.exceptions);
        this.onTouchedCallback();
      });

    this.translatePipe.loadTranslations(
      [
        'label.setCapacity.addTo',
        'label.setCapacity.new',
        'label.setCapacity.subtractFrom',
      ])
      .pipe(take(1))
      .subscribe((translations) => {
        this.translations = translations;
      });
  }

  /**
   * Terminate all subscriptions
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Getter function for direct access to exceptions FormArray
   *
   * @returns exceptions
   */
  public get exceptions(): UntypedFormArray {
    return this.exceptionForm.controls.exceptions as UntypedFormArray;
  }

  /**
   * Retrieves an individual exception FormGroup
   *
   * @param index index of exception control
   * @returns FormGroup representing an individual exception
   */
  public getExceptionControl(index: number): UntypedFormGroup {
    return this.exceptions.controls[ index ] as UntypedFormGroup;
  }

  /**
   * Add empty exception FormGroup the the exceptions array
   */
  public addException(): void {
    const newException = this.formBuilder.group({
      time: [ null ],
      capacity: this.formBuilder.group({
        type: [ null ],
        count: [ null ],
      })
    });

    this.exceptions.push(newException);

    // Focus on the last exception after giving time for render
    timer(0).pipe(take(1)).subscribe(() => {
      focusOnElement('.exception-list-item:last-child mat-select');
    });
  }

  /**
   * Remove exception FormGroup from FormArray
   *
   * @param index index to remove
   */
  public removeException(index: number): void {
    this.exceptions.removeAt(index);
  }

  /**
   * Determines display text of the logic options for logic dropdown
   *
   * @param item dropdown item that is a type of bulk edit
   * @returns text to be displayed in dropdown
   */
  public getLogicOptionText(item: BulkEditCapacity['type']): string {
    switch (item) {
      case 'add':
        return this.translations[ 'label.setCapacity.addTo' ];
      case 'subtract':
        return this.translations[ 'label.setCapacity.subtractFrom' ];
      default:
        return this.translations[ 'label.setCapacity.new' ];
    }
  }

  /**
   * Get list of times for specific control
   * Removes already-selected times, then adds back the control's selected time if it exists
   *
   * @param index index of control to retrieve options
   * @returns array of times
   */
  public getTimeOptionsForControl(index: number): string[] {
    const currentTimeSelected = this.getExceptionControl(index).value.time;
    const existingExceptionTimes = this.exceptions.value.map((exception: BulkEditException) => {
      return exception.time;
    });
    const timesForControl = [
      ...this.times.filter((time) => !existingExceptionTimes.includes(time)),
    ];
    if (currentTimeSelected) {
      timesForControl.push(currentTimeSelected);
      timesForControl.sort();
    }
    return timesForControl;
  }

  /**
   * Disable add exception button when the number of exceptions
   * is equal to the amount of input time intervals
   *
   * @returns true if button should be disabled
   */
  public disableAddException(): boolean {
    return this.times.length <= this.exceptions.controls.length;
  }

  /**
   * @param time time
   * @returns time with (UTC) added
   */
  public addUTCToTime(time: string): string {
    return this.addUTCToTimePipe.transform(time);
  }

  /**
   * BulkEditForm form shouldn't be passing any initial value
   * Implementation of writeValue is needed for ControlValueAccessor
   */
  public writeValue(): void {
    return;
  }

  /**
   * Stores BulkEditForm callback that will update the BulkEditForm form value
   *
   * @param func BulkEditForm callback
   */
  public registerOnChange(func: (_: any) => void): void {
    this.onChangeCallback = func;
  }

  /**
   * Stores BulkEditForm callback that will update the control when it has been touched
   *
   * @param func BulkEditForm touched callback
   */
  public registerOnTouched(func: () => void): void {
    this.onTouchedCallback = func;
  }
}
