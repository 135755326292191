import { utc } from 'moment';
import {GetMetricsMonthlyParam } from 'api/types';
import {  Timescale } from 'types/AppointmentFilters';
import { AlertResolveFilters } from 'types/AlertResolveFilters';
import { inject } from '@angular/core';
import { Location } from '@angular/common';


/**
 * Convert filter states to getMetricParams
 *
 * @param filters the current appointment filters state
 * @returns params GetMetricsParams that represent the filter state
 * 
 * 
 */
let setcalendarData:any;

// export function resolve(elemet:any){
//   let demo=inject(Location).subscribe((ele:any)=>{
//     if(setcalendarData == null || undefined){
//       ele.back()
//     }else{
//     setcalendarData=elemet?.key || elemet
//     }
//   })
  
// }
export function filtersToParams(filters: AlertResolveFilters): GetMetricsMonthlyParam {
 
  const { startDate } = filters;

// let getcalenderData:any=filters.startDate.value 

// if(getcalenderData < setcalendarData.endDate ){
//   filters.startDate.value =getcalenderData
// }else{
//   filters.startDate.value=setcalendarData.startDate
// }
  return {
    startDate: startDate.value || '',
    numberOfDays: getNumberOfDays(filters.timescale.value || null, filters.startDate.value || null),
  
  };
}

/**
 * @param timescale the Timescale in question
 * @param startDate the start date - only relevant for monthly timescale
 * @returns numberOfDays the number of days to display
 */
export function getNumberOfDays(timescale: Timescale | null, startDate: string | null): number {
  if (!timescale || !startDate) {
    return 1;
  }

  switch (timescale) {
    case Timescale.weekly:
      return 7;
    case Timescale.monthly:
      return utc(startDate).daysInMonth();
    default:
      return 1;
  }
}

/**
 * @returns the timescale associated with the number of days
 * - 1 -> daily
 * - 7 -> weekly
 * - all other numbers -> monthly
 */
export function getTimescale(numberOfDays: number): Timescale {
  switch (numberOfDays) {
    case 1:
      return Timescale.daily;
    case 7:
      return Timescale.weekly;
    default:
      return Timescale.monthly;
  }
}
