<vue-table-container [loading]="tableLoading">
  <table mat-table [dataSource]="clientData">

    <caption class="visually-hidden">
      {{ 'table.caption.allClients' | translate | async }}
    </caption>

    <!-- Client Name -->
    <ng-container matColumnDef="name">
      <th
        mat-header-cell
        *matHeaderCellDef
        [tableHeaderAriaSort]="{sortDirection, isSorted: sortBy === 'name' }"
      >
        <app-sortable-table-header
          [tableHasData]="!!clientData.length"
          (sortColumn)="sort('name')"
        >
          {{ 'title.clients' | translate | async }}
        </app-sortable-table-header>
      </th>
      <mat-cell *matCellDef="let client">
        {{client.name}}
      </mat-cell>
    </ng-container>

    <!-- Client Exams -->
    <ng-container matColumnDef="exam-count">
      <th
        mat-header-cell
        *matHeaderCellDef
        [tableHeaderAriaSort]="{sortDirection, isSorted: sortBy === 'exam-count' }"
      >
        <app-sortable-table-header
          [tableHasData]="!!clientData.length"
          (sortColumn)="sort('exam-count')"
        >
          {{ 'title.exams' | translate | async }}
        </app-sortable-table-header>
      </th>
      <mat-cell *matCellDef="let client">
        <div *ngIf="client.examCount <= 0" (click)="openExamsListDialog(client)">
          {{client.examCount}}
         </div>
         <div *ngIf="client.examCount > 0" class="clickable" [matTooltip]="translations['tooltop.message.client.viewexams']" (click)="openExamsListDialog(client)">
          {{client.examCount}}
         </div>
      </mat-cell>
    </ng-container>

    <!-- Client Pools -->
    <ng-container matColumnDef="has-pools">
      <th
        mat-header-cell
        *matHeaderCellDef
        [tableHeaderAriaSort]="{sortDirection, isSorted: sortBy === 'has-pools' }"
      >
        <app-sortable-table-header
          [tableHasData]="!!clientData.length"
          (sortColumn)="sort('has-pools')"
        >
          {{ 'title.pools' | translate | async }}
        </app-sortable-table-header>
      </th>
      <mat-cell *matCellDef="let client">
        <div *ngIf="client.poolCount <= 0" (click)="openPoolsListDialog(client)">
          {{client.poolCount}}
        </div>
        <div *ngIf="client.poolCount > 0" class="clickable" [matTooltip]="translations['tooltop.message.client.viewpools']" (click)="openPoolsListDialog(client)">
          {{client.poolCount}}
        </div>
      </mat-cell>
    </ng-container>

    <!-- Populate table with data -->
    <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
    <mat-row
      *matRowDef="let client; columns: tableColumns; let i = index"
    ></mat-row>

    <!-- No data available -->
    <tr class="mat-row no-content" *matNoDataRow>
      <td class="mat-cell" colspan="9999">
        {{ 'message.clients.noClients' | translate | async }}
      </td>
    </tr>
  </table>

  <mat-paginator
    *ngIf="clientData.length !== 0"
    (page)="paginatorChange($event)"
    previousPageIndex="0"
    [length]="totalItemCount"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    [showFirstLastButtons]="showFirstLastButtons"
  ></mat-paginator>
</vue-table-container>
