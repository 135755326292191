/* eslint-disable
  @typescript-eslint/no-empty-function,
  @typescript-eslint/no-explicit-any,
  @typescript-eslint/member-ordering,
  no-invalid-this
*/
import { BehaviorSubject } from 'rxjs';
import { ERROR, INITIAL, LOADING, RequestStatus, SUCCESS } from 'types/RequestStatus';

export type RetryHandler = () => any

/**
 * Base class for any status related services to extend
 */
export class BaseStatus {
  /**
   * Retry handler to call after an error occurs. Callers set this when calling loading().
   */
  public retry: RetryHandler = () => {};

  /**
   * Private, mutable backing data for the status
   */
  protected status = new BehaviorSubject<RequestStatus>(INITIAL)

  /**
   * Publicly consumable observable for tracking status
   */
  public status$ = this.status.asObservable();

  /**
   * Set the status to loading, and specify a retry handler.
   *
   * @param retryHandler the handler to call if loading fails
   */
  public loading(retryHandler?: RetryHandler): void {
    this.status.next(LOADING);

    if (retryHandler) {
      this.retry = retryHandler;
    }
  }

  /**
   * Set the status to error
   */
  public error(): void {
    this.status.next(ERROR);
  }

  /**
   * Set the status to success
   */
  public success(): void {
    this.status.next(SUCCESS);
  }

  /**
   * Reset to initial status
   */
  public reset(): void {
    this.status.next(INITIAL);
  }
}
