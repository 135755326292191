import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GetActivityQueryParameters } from 'api/types';

export const DEFAULT_VALUES: GetActivityQueryParameters = {
  limit: 25,
  offset: 0,
  sortBy: 'timestamp',
  direction: 'desc'
};

/**
 * Service that distributes filters (which results in GetActivity params) for the Appointments Recent Activity page.
 */
@Injectable({
  providedIn: 'root'
})
export class AppointmentsRecentActivityFiltersService {
  // Init with empty values; the filter component will populate them later
  private readonly params = new BehaviorSubject<GetActivityQueryParameters>(DEFAULT_VALUES)

  // eslint-disable-next-line @typescript-eslint/member-ordering, no-invalid-this
  public params$ = this.params.asObservable()

  public constructor() {
    // Prevent handler from being called in the component's context
    this.updateParams = this.updateParams.bind(this);
  }

  public updateParams(changes: Partial<GetActivityQueryParameters>): void {
    this.params.next({ ...this.params.getValue(), offset: 0, ...changes });
  }
}
