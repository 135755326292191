<vue-page-header
  appName="{{ 'title.applicationName' | translate | async }}"
  pageTitle="{{ 'title.overview' | translate | async }}"
  navSection="{{ 'title.pools' | translate | async }}"
>
  <app-filter-container>
    <app-pools-filters></app-pools-filters>
    <app-add-pool-drawer (success)="poolCreated($event)"></app-add-pool-drawer>
  </app-filter-container>
</vue-page-header>
<app-page-body>
  <vue-section-header>
    <h2 class="mat-title">{{ "title.poolCapacity" | translate | async }}</h2>
  </vue-section-header>
  <vue-section [loading]="pageLoading">
    <app-pools-chart
      [metrics]="poolMetrics"
      (dateIndexClick)="dateIndexClick($event)"
    ></app-pools-chart>
  </vue-section>
  <vue-section-header>
    <h2 class="mat-title">{{occurrenceSectionTitle}}</h2>
  </vue-section-header>
  <app-server-error-unsaved
    *ngIf="showOccurrenceActionError"
    (retry)="retryOccurrenceAction()"
  ></app-server-error-unsaved>
  <vue-section [loading]="pageLoading">
    <app-occurrence-table
      [pools]="poolsForSelectedDate"
      [poolDate]="selectedDate"
    ></app-occurrence-table>
  </vue-section>
  <vue-section-header>
    <h2 class="mat-title">{{ "label.poolsOverview.openPoolsFor" | translate | async }} {{filterDate}}</h2>
  </vue-section-header>
  <app-server-error-unsaved
    *ngIf="showFetchingPoolError"
    [includeUnsaved]="false"
    (retry)="retryFetchingPool()"
  ></app-server-error-unsaved>
  <vue-section [loading]="pageLoading">
    <app-open-pools-table></app-open-pools-table>
  </vue-section>
  <vue-toast heading="{{ 'label.poolsOverview.createPool.toast.heading' | translate | async }}" icon="success">
    <span toast-body>{{ 'label.poolsOverview.createPool.toast.body' | translate:newPoolName | async }}</span>
    <a (click)="viewNewPool()" toast-action>
      {{ "title.viewPool" | translate | async }}
    </a>
  </vue-toast>
  <app-edit-pool-toast></app-edit-pool-toast>
</app-page-body>
