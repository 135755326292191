import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiHttpClient } from 'services/api/http-client/api.http-client';
import { AppConfigService } from '../../app-config.service';

/**
 * Service that extends the ApiHttpClient with the pools microservice base URL
 */
@Injectable({
  providedIn: 'root'
})
export class PoolsMicroservice extends ApiHttpClient {
  public constructor(
    http: HttpClient,
    appConfigService: AppConfigService,
  ) {
    // Create ApiHttpClient with the proper microservice base URL
    super(http, appConfigService.getPoolsMicroserviceURL());
  }
}

/**
 * Creates PoolsMicroservice
 *
 * @param http HttpClient
 * @param appConfigService AppConfigService
 * @returns PoolsMicroservice Instance
 */
export function poolsMicroserviceCreator(
  http: HttpClient,
  appConfigService: AppConfigService,
): PoolsMicroservice {
  return new PoolsMicroservice(http, appConfigService);
}
