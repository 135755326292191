/* eslint-disable no-undefined */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Queue } from 'api/types';
import { AlertsFiltersService, GetAlertsFilterChip, GetAlertsFilterChipType } from 'services/alerts-filters.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AlertsPageDataService } from 'services/alerts-page-data.service';
import { formatQueueDisplay } from 'utils/format-queue';
import { EXCHANGE_FORMAT } from 'constants/date-formats';
import utc from 'moment';

/**
 *  Filters for the alerts page
 */
@Component({
  selector: 'app-alerts-filters',
  templateUrl: './alerts-filters.component.html',
  styleUrls: ['./alerts-filters.component.scss']
})

export class AlertsFiltersComponent implements OnInit, OnDestroy {
  public queuesList: Queue[] = [];

  public typeList = [
    'All', 'Pool Consumption'
  ];

  public priorityList = [
    'All', '1', '2', '3', '4'
  ];

  public statusList = [
    'All', 'In Process', 'Resolved'
  ];

  queue = new UntypedFormControl([]);
  type = new UntypedFormControl('');
  priorities = new UntypedFormControl([]);
  status = new UntypedFormControl('');

  dateRange = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });

  /**
   * Terminate subscriptions
   */
  private destroyed$ = new Subject();

  public selectedFilters: GetAlertsFilterChip[] = [];

  public getQueueDisplay = formatQueueDisplay;

  public constructor(
    private alertsService: AlertsFiltersService,
    private alertsPageDataService: AlertsPageDataService
  ) {
  }

  public ngOnInit(): void {
    const { start, end } = this.dateRange.controls;

    this.alertsPageDataService.queues$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((queues) => {
        this.queuesList = queues;
      });

    this.alertsService.selectedFilters$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((selectedFilters) => {
        this.selectedFilters = [...selectedFilters];
      });

    this.alertsService.selectedQueues$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((selectedQueues) => {
        if (JSON.stringify(this.queue.value) != JSON.stringify(selectedQueues.map((c) => c.id))) {
          var queueIds = selectedQueues.map((c) => c.id);
          this.queue.setValue(queueIds);
        }
      });

    this.alertsService.selectedStatus$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((selectedStatus) => {
        if (this.status.value != selectedStatus) {
          this.status.setValue(selectedStatus)
        }
      });

    this.alertsService.selectedType$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((selectedType) => {
        if (this.type.value != selectedType) {
          this.type.setValue(selectedType)
        }
      });

    this.alertsService.selectedPriorities$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((selectedPriorities) => {
        if (JSON.stringify(this.priorities.value) != JSON.stringify(selectedPriorities)) {
          this.priorities.setValue(selectedPriorities);
        }
      });

    this.alertsService.selectedOpenBeforeDate$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((selectedOpenBeforeDate) => {
        var controlOpenBeforeDate = start.value ? new Date(start.value).getTime() : null;
        var openBeforeDate = selectedOpenBeforeDate ? new Date(selectedOpenBeforeDate).getTime() : null;

        if (controlOpenBeforeDate != openBeforeDate) {
          start.setValue(selectedOpenBeforeDate ? utc(selectedOpenBeforeDate) : null);
        }
      });

    this.alertsService.selectedOpenAfterDate$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((selectedOpenAfterDate) => {
        var controlOpenAfterDate = end.value ? new Date(end.value).getTime() : null;
        var openAfterDate = selectedOpenAfterDate ? new Date(selectedOpenAfterDate).getTime() : null;

        if (controlOpenAfterDate != openAfterDate) {
          end.setValue(selectedOpenAfterDate ? utc(selectedOpenAfterDate) : null);
        }
      });

    this.queue.valueChanges.subscribe((value: any) => {
      var queuedata: Queue[] = this.queuesList.filter(x => value.find((e: any) => e == x.id));

      if (!this.alertsService.isAllQueueSelected && (queuedata.find((e: any) => e.id == 'all') || (queuedata.filter((x: any) => x.id != 'all').length == this.queuesList.filter((x: any) => x.id != 'all').length))) {
        this.alertsService.isAllQueueSelected = true;
        queuedata = this.queuesList;
      }

      if (queuedata.filter((x: any) => x.id != 'all').length != this.queuesList.filter((x: any) => x.id != 'all').length) {
        queuedata = queuedata.filter((x: any) => x.id != 'all');
        this.alertsService.isAllQueueSelected = false;
      }

      if (this.alertsService.isAllQueueSelected && !queuedata.find((e: any) => e.id == 'all')) {
        queuedata = [];
      }

      this.alertsService.updateQueueFilter(queuedata);
    });

    this.type.valueChanges.subscribe((value: any) => {
      this.alertsService.updateTypeFilter(value);
    });

    this.priorities.valueChanges.subscribe((value: any) => {
      var priorities = [];
      priorities = JSON.parse(JSON.stringify(value));

      if (!this.alertsService.isAllPrioritySelected && (priorities.find((e: any) => e == 'All') || (priorities.filter((x: any) => x != 'All').length == this.priorityList.filter((x: any) => x != 'All').length))) {
        this.alertsService.isAllPrioritySelected = true;
        priorities = this.priorityList;
      }

      if (priorities.filter((x: any) => x != 'All').length != this.priorityList.filter((x: any) => x != 'All').length) {
        priorities = priorities.filter((x: any) => x != 'All');
        this.alertsService.isAllPrioritySelected = false;
      }

      if (this.alertsService.isAllPrioritySelected && !priorities.find((e: any) => e == 'All')) {
        priorities = [];
      }

      this.alertsService.updatePrioritiesFilter(priorities);
    });

    this.status.valueChanges.subscribe((value: any) => {
      this.alertsService.updateStatusFilter(value);
    });

    this.dateRange.valueChanges.subscribe((value: any) => {
      this.alertsService.updateDateRangeFilter((value.start ? value.start.format(EXCHANGE_FORMAT) : null), (value.end ? value.end.format(EXCHANGE_FORMAT) : null));
    });
  }

  /**
   * Complete all subscriptions
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public filterRemoved(removedFilter: GetAlertsFilterChipType): void {
    switch (removedFilter.filterKey) {
      case 'status':
        this.alertsService.removeStatus();
        break;
      case 'priorities':
        this.alertsService.removePriorities(removedFilter.value as string);
        break;
      case 'type':
        this.alertsService.removeType();
        break;
      case 'queueIds':
        this.alertsService.removeQueue(removedFilter.value as string);
        break;
      case 'start':
      case 'end':
        this.alertsService.removeDate(removedFilter.filterKey);
        break;
    }
  }

  setColorCode(code: string) {
    switch (code) {
      case "1":
        return 'red'
      case "2":
        return 'orange'
      case "3":
        return 'yellow'
      case "4":
        return 'blue'
      default:
        return 'grey'
      // code block
    }
  }
}
