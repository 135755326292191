<mat-form-field [appearance]="appearance">
  <mat-label>{{label}}</mat-label>
  <mat-chip-list
    #chipList
    [required]="required"
    [formControl]="control"
    [errorStateMatcher]="errorMatcher"
  >
    <mat-chip
      *ngFor="let item of control.value"
      [ngClass]="{'invalid': !isValid(item)}"
      (removed)="remove(item)"
      [removable]="removable"
    >
    <span *ngIf="!isValid(item)" [matTooltip]="translations['tooltop.message.pool.invalidexam']">
      {{getItemDisplay(item)}}
    </span>
    <span *ngIf="isValid(item)">
      {{getItemDisplay(item)}}
    </span>
    <button
      *ngIf="removable"
      matChipRemove
      [attr.aria-label]="'label.aria.remove' | translate:getItemDisplay(item)  | async"
    >
      <mat-icon>
        close
      </mat-icon>
    </button>
    </mat-chip>
    <input
      #autoCompleteInput
      [placeholder]="placeholder"
      [formControl]="inputControl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    >
  </mat-chip-list>
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="select($event)"
    [autoActiveFirstOption]="true"
  >
    <app-auto-complete-loader>
      <mat-option
        *ngFor="let item of filteredItems | async"
        [value]="item"
        data-testid="option"
      >
      <span [innerHTML]="getItemDisplay(item) | boldMatchingText: inputControl.value" [matTooltip]="getItemDisplay(item)"></span>
      </mat-option>

      <!-- Include an invisible option so when no items are present, mat-autocomplete still renders the panel and we can show the spinner -->
      <mat-option style="height: 0px;" tabindex="-1" disabled aria-hidden="true"></mat-option>
    </app-auto-complete-loader>
  </mat-autocomplete>
</mat-form-field>
