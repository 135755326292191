import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  BulkEditPayload,
  BulkEditResponse,
  CreateCapacityRequestBody,
  CreateCapacityResponse,
  UpdateCapacityBody,
  UpdateCapacityResponse,
  UploadAppointmentCapacityPayload,
  UploadAppointmentCapacityResponse
} from 'api/types';
import { CapacityMicroservice } from './microservices/capacity.microservice';
import { GetUploadedCapacityFileResponse } from 'api/types/endpoints/uploadedCapacityFileLog';
import { HttpHeaders } from '@angular/common/http';

/**
 * API service for creating capacity
 */
@Injectable({
  providedIn: 'root',
})
export class CapacityService {
  private saveTrigger$ = new BehaviorSubject<any>({});
  capacityUploadSaveTrigger$ = this.saveTrigger$.asObservable();

  public constructor(private api: CapacityMicroservice) {}

  setTriggerValue(value: string) {
    this.saveTrigger$.next(value);
  }

  /**
   * Calls CreateCapacity API
   *
   * @param payload the request body to send with the request
   * @returns CreateCapacityResponse Observable
   */
  public createCapacity(payload: CreateCapacityRequestBody): Observable<CreateCapacityResponse> {
    const path = 'capacity';
    return this.api.post<CreateCapacityResponse, CreateCapacityRequestBody>(path, payload);
  }

  /**
   * PATCH call to submit Bulk Capacity Edit
   *
   * @param payload payload for bulk capacity edit
   * @returns BulkEditResponse Observable
   */
  public editBulkCapacity(payload: BulkEditPayload): Observable<BulkEditResponse> {
    return this.api.patch<BulkEditResponse, BulkEditPayload>('capacity', payload);
  }

  /**
   * PATCH call to edit individual time interval's capacity for a queue
   *
   * @param queueId queueId of selected queue to update
   * @param payload payload of timestamp, capacity and interval
   * @returns UpdateCapacityResponse Observable
   */
  public updateQueueCapacity(queueId: string, payload: UpdateCapacityBody): Observable<UpdateCapacityResponse> {
    const path = `capacity/${queueId}`;

    return this.api.patch<UpdateCapacityResponse, UpdateCapacityBody>(path, payload);
  }

  /**
   * Calls UploadCapacity API.
   * NOTE: `Content-Type:`multipart/form-data` is automatically appended by the http.post with FormData
   *
   * @param payload the request body to send with the request
   * @returns UploadCapacityResponse Observable
   */
  public uploadCapacity(payload: UploadAppointmentCapacityPayload): Observable<UploadAppointmentCapacityResponse> {
    const path = 'capacity/uploads';

    return this.api.post<UploadAppointmentCapacityResponse, UploadAppointmentCapacityPayload>(
      path,
      payload,
    );
  }

  /**
   * Calls getUploadFileLog API
   *
   * @returns uploaded capacity file log Observable
   */
  public getUploadedCapacityFiles(selectedQueueId: any): Observable<GetUploadedCapacityFileResponse> {
    const path = `capacity/uploadlogs/${selectedQueueId}`;
    return this.api.get<GetUploadedCapacityFileResponse>(path);
  }

  /**
   * Calls downloadCapacityFile API
   *
   * @returns uploaded capacity file log Observable
   */
  public downloadCapacityFile(id: number): Observable<Blob> {
    const path = `capacity/uploads/${id}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      responseType: 'blob',
    });
    return this.api.get<Blob>(path, {
      headers: headers,
      responseType: 'blob' as 'json',
    });
  }
}
