import { FlexibleConnectedPositionStrategyOrigin } from '@angular/cdk/overlay';
import { Component, ElementRef, Input, OnInit } from '@angular/core';

/**
 * Hidden button for use as the origin for chart popovers
 * - Accepts left and top inputs to position it over the chart
 * - Exposes the inner button element for use as that actual popover origin
 *
 */
@Component({
  template: `
    <button class="hidden-popover-button" [ngStyle]="{'left.px': left, 'top.px': top }"></button>
  `,
  styleUrls: [ './vue-chart-popover-hidden-button.component.scss' ]
})
export class VueChartPopoverHiddenButtonComponent implements OnInit {
  @Input() public left = 20;
  @Input() public top = 20;

  public elementRef?: ElementRef;
  public nativeButtonElem?: FlexibleConnectedPositionStrategyOrigin;

  public constructor(element: ElementRef) {
    this.elementRef = element;
  }

  public ngOnInit(): void {
    // Expose the native button elementRef for use in opening the overlay
    this.nativeButtonElem = this.elementRef?.nativeElement.childNodes[ 0 ];
  }
}
