<div>
    <span class="occurs">{{ "label.editPool.resOrUnres" | translate | async }}</span>
    <div class="day-holder">
        <span *ngFor="let day of daysOfWeek; let i=index">
            <vue-slide-toggle 
            class="occur"
            [disabled]="!restrictedDays.includes(i)"
            [checked]="restrictedDays.includes(i) && day.checked" (change)="checkboxChange(day.value,$event)"
            matTooltip="{{ getToolTip(day.checked) | async }}"
            >            
            </vue-slide-toggle>
        </span>
        
    </div>
</div>