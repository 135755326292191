<footer>
  <div class="footer-links">
    <a href="https://home.pearsonvue.com/terms" target="blank">
      {{ 'footer.terms' | vueTranslate | async }}
    </a>
    <div class="divider"></div>
    <a href="https://home.pearsonvue.com/privacy" target="blank">
      {{ 'footer.privacy' | vueTranslate | async }}
    </a>
  </div>
  <span>{{ 'footer.copyright' | vueTranslate:year() | async }}</span>
</footer>
