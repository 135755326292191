import { AbstractControl, ValidationErrors } from '@angular/forms';
import { invalidCharactersRegex } from 'constants/invalid-characters-regex';

export const INVALID_CHARACTERS_ERROR = 'invalid-characters';

/**
 * Validates that a control doesn't contain any invalid characters
 */
export function invalidCharactersValidator(control: AbstractControl): ValidationErrors | null {
  if (!control.value) {
    return null;
  }

  if (control.value.toString().replace(/^\s+/g, '').replace(/\s+$/g, '').length <= 0) {
    return { [INVALID_CHARACTERS_ERROR]: control.value };
  }

  if (invalidCharactersRegex.test(control.value)) {
    return { [INVALID_CHARACTERS_ERROR]: control.value };
  }

  return null;
}
