import { Queue } from 'api/types';

/**
 * Formats queue object for display
 *
 * @param queue The queue to format
 * @returns a formatted string for display, eg: 'English/US'
 */
export function formatQueueDisplay(queue?: Queue): string {
  return queue?.name || '';
}
