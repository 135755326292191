import { Component, Input } from '@angular/core';
import { DailyCapacityCalculation } from 'api/types';

/**
 * Table to show hourly allocations
 */
@Component({
  selector: 'app-hourly-allocation-table',
  templateUrl: './hourly-allocation-table.component.html',
  styleUrls: [ './hourly-allocation-table.component.scss' ]
})
export class HourlyAllocationTableComponent {
  /**
   * Single day of capacity calculations
   */
  @Input() public dailyCapacityCalculation!: DailyCapacityCalculation

  /**
   * Array of column names
   */
  public tableColumns = [ 'startTime', 'capacity' ]
}
