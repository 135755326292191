import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { VUE_DIALOG_CONFIG } from 'vue/utilities/vue-dialog-config';

/**
 *  Component for restrict pool occurrence edit.
 */
@Component({
  selector: 'app-pool-dialog',
  templateUrl: './pool-dialog.component.html',
  styleUrls: [ './pool-dialog.component.scss' ]
})
export class PoolDialogComponent {
  public constructor(private dialogRef: MatDialogRef<PoolDialogComponent>) {
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}

export const poolOccurrenceConfig = {
  ...VUE_DIALOG_CONFIG,
};
