import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UnsavedChangesGuard } from 'components/guards/unsaved-changes.guard';
import { AllPoolsPageComponent } from 'components/pages/all-pools-page/all-pools-page.component';
import { AppointmentsPageComponent } from 'components/pages/appointments-page/appointments-page.component';
import { AppointmentsRecentActivityPageComponent } from 'components/pages/appointments-recent-activity-page/appointments-recent-activity-page.component';
import { ClientsPageComponent } from 'components/pages/clients-page/clients-page.component';
import { DateLocalizationTest } from 'components/pages/date-localization-test/date-localization-test.component';
import { PageNotFoundComponent } from 'components/pages/page-not-found/page-not-found.component';
import { PoolDetailsPageComponent } from 'components/pages/pool-details-page/pool-details-page.component';
import { PoolsPageComponent } from 'components/pages/pools-page/pools-page.component';
import { PoolsRecentActivityPageComponent } from 'components/pages/pools-recent-activity-page/pools-recent-activity-page.component';
import { TemplatesPageComponent } from 'components/pages/templates-page/templates-page.component';
import { OutagesPageComponent } from 'components/pages/outages-page/outages-page.component';
import { AddOutagesPageComponent } from 'components/pages/add-outages-page/add-outages-page.component';
import { EditOutagesPageComponent } from 'components/pages/edit-outages-page/edit-outages-page.component';
import { AlertsPageComponent } from 'components/pages/alerts-page/alerts-page.component';
import { PoolConsumptionPageComponent } from 'components/pages/poolconsumption-page/poolconsumption-page.component';
import { PoolConsumptionDetailsPageComponent } from 'components/pages/poolconsumption-details-page/poolconsumption-details-page.component';
import { AlertDetailsPageComponent } from 'components/pages/alert-details-page/alert-details-page.component';
import { AlertsResolvePageComponent } from 'components/pages/alerts-resolve-page/alerts-resolve-page.component';

const routes: Routes = [
  { path: '', redirectTo: '/appointments', pathMatch: 'full' },
  { path: 'appointments', component: AppointmentsPageComponent, canDeactivate: [ UnsavedChangesGuard ] },
  { path: 'appointments/recent-activity', component: AppointmentsRecentActivityPageComponent },
  { path: 'appointments/templates', component: TemplatesPageComponent },
  { path: 'clients', component: ClientsPageComponent },
  { path: 'pools', component: PoolsPageComponent },
  { path: 'pools/all', component: AllPoolsPageComponent },
  { path: 'pools/recent-activity', component: PoolsRecentActivityPageComponent },
  { path: 'outages', component: OutagesPageComponent },
  { path: 'add-outages', component: AddOutagesPageComponent },
  { path: 'edit-outages/:id', component: EditOutagesPageComponent },
  { path: 'alerts', component: AlertsPageComponent },
  { path: 'alerts/configurations/poolconsumption', component: PoolConsumptionPageComponent },
  // Testing
  { path: 'date-localization-test', component: DateLocalizationTest },

  // Pool details is the last defined /pools* route so other sub routes aren't mistaken for an id
  { path: 'pools/:id', component: PoolDetailsPageComponent },
  { path: 'alerts/:id', component: AlertDetailsPageComponent },
  { path: 'alerts/:id/resolve', component: AlertsResolvePageComponent },
  { path: 'alerts/configurations/poolconsumption/:id', component: PoolConsumptionDetailsPageComponent },
  { path: '**', component: PageNotFoundComponent }
];

/**
 *  App routing module
 */
@NgModule({
  exports: [ RouterModule ],
  imports: [ RouterModule.forRoot(routes) ]
})
export class AppRoutingModule { }
