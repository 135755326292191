import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CalendarOccuranceService } from 'services/api/calendar-occurance.service';
import { VUE_DIALOG_CONFIG } from 'vue/utilities/vue-dialog-config';
import { Location } from '@angular/common';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { INVALID_CHARACTERS_ERROR, invalidCharactersValidator } from 'utils/validators/invalid-characters.validator';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
  loadingStatus = false;
  resolvedStatus = "resolved";
  formIsValid = false;
  public uploadCapacityForm: UntypedFormGroup;
  constructor(private dialogRef: MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private capacityapi: CalendarOccuranceService, private location: Location, private formBuilder: UntypedFormBuilder) {
    this.uploadCapacityForm = this.createForm();
  }

  public ngOnInit(): void {
    this.uploadCapacityForm.controls.comment.valueChanges.subscribe((value) => {
      this.formIsValid = false;
      if (value) {
        if (this.uploadCapacityForm.controls.comment.valid) {
          this.formIsValid = true;
        }
      }
    });
  }

  private createForm(): UntypedFormGroup {
    return this.formBuilder.group({
      comment: new UntypedFormControl('', [
        Validators.maxLength(250),
        invalidCharactersValidator
      ])
    });
  }

  public commentErrorMessageKey() {
    if (
      this.uploadCapacityForm.controls.comment.hasError(
        INVALID_CHARACTERS_ERROR
      )
    ) {
      return 'error.invalidCharacter';
    }
    if (this.uploadCapacityForm.controls.comment.hasError('maxlength')) {
      return 'error.maxlength';
    }
    return null;
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  confirmDialogue() {
    if (this.formIsValid) {
      this.loadingStatus = true;
      let payload = {
        "status": this.resolvedStatus,
        "comment": this.uploadCapacityForm.controls.comment.value
      }
      this.capacityapi.resolveUpdatedById(this.data.id, payload).subscribe((result: any) => {
        this.loadingStatus = false;
        this.dialogRef.close();
        this.location.back()

      }, (error: unknown) => {
        this.loadingStatus = false;
        this.location.back();
      });
    }
  }

}
export const confirmationModalConfig = {
  ...VUE_DIALOG_CONFIG,
};
