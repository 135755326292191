const IDEAL_LINE_COUNT = 7;
const STEP_SIZES = [ 25, 50, 100, 200, 500, 1000, 2500, 5000, 10000, 25000, 50000, 100000, 250000, 500000, 1000000 ];

interface YAxisConfig {
  max: number;
  stepSize: number;
}

/**
 * Tailor the Y axis config based on the data and an ideal number of lines to display
 *
 * @param maxValue the max Y value from the dataset
 * @param lineCount the number of lines (or ticks) to aim for
 * @param stepSizes a set of possible step sizes to choose from
 * @returns config an object with the max and stepSize for the Y axis to use
 */
export function getYAxisConfig(maxValue: number, lineCount = IDEAL_LINE_COUNT, stepSizes = STEP_SIZES): YAxisConfig {
  let max = maxValue;

  // Find the stepSize that is closest to ideal
  const idealRatio = max / lineCount;
  let distanceFromIdeal = Infinity;
  let stepSize = stepSizes[ 0 ];

  stepSizes.forEach((s) => {
    const d = Math.abs(idealRatio - s);
    if (d < distanceFromIdeal) {
      distanceFromIdeal = d;
      stepSize = s;
    }
  });

  // Round up to nearest step
  max = stepSize * (Math.round(max / stepSize) + 1);

  return { max, stepSize };
}
