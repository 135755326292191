import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from 'services/authentication/auth.service';

/**
 * Service to receive the Help URL for OnVUE Capacity
 */
@Injectable({
  providedIn: 'root'
})
export class HelpUrlService {
  /** Id of the OnVUE Capacity application */
  public static CAPACITY_ID = 191;

  /**  Path to the `applications/forLoggedInUser` API */
  public static GET_APPLICATIONS_PATH = '/rest/portal/applications/forLoggedInUser';

  /** Store helpURL so API is only called once */
  private helpURL: string | null = null;

  public constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
  ) {}

  public getHelpUrl(): Observable<string | null> {
    // Return helpURL if it has already been fetched
    if (this.helpURL) {
      return of(this.helpURL);
    }

    const restBaseURL = this.authService.getRestBaseUrl();

    if (restBaseURL) {
      return this.httpClient.get<ApplicationResponse>(`${restBaseURL}${HelpUrlService.GET_APPLICATIONS_PATH}`)
        .pipe(take(1))
        .pipe(map((response) => {
          const helpURL = this.parseApplicationResponse(response);
          this.helpURL = helpURL;
          return helpURL;
        }));
    }

    return of(null);
  }

  /**
   * Returns the helpURl for capacity from the array of applications otherwise null
   */
  private parseApplicationResponse(applications: ApplicationResponse): string | null {
    const onVUECapacity = applications.find((app) =>
      app.id === HelpUrlService.CAPACITY_ID
    );

    if (!onVUECapacity) {
      // eslint-disable-next-line no-console
      console.error('[HelpUrlService] Unable to find OnVUE Capacity application to retrieve help URL.');
    }

    return onVUECapacity?.helpURL ? onVUECapacity.helpURL : null;
  }
}

/**
 * Individual application detail from `/rest/portal/applications/forLoggedInUser` endpoint
 *
 * The API returns more attributes than these, but `id` & `helpURL` are the only ones of relevance here.
 */
interface Application {

  /** Id of the application */
  id: number;

  /** Name of the application */
  name: string;

  /** URL to the help documentation for the application */
  helpURL: string;
}

export type ApplicationResponse = Application[];
