import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { VUE_DIALOG_CONFIG } from 'vue/utilities/vue-dialog-config';

/**
 * Dialog that prompts users to release all occurrences of a pool
 */
@Component({
  selector: 'app-release-all-occurrences',
  templateUrl: './release-all-occurrences.component.html',
})
export class ReleaseAllOccurrencesComponent {
  public constructor(
  // eslint-disable-next-line @typescript-eslint/no-parameter-properties
    @Inject(MAT_DIALOG_DATA) public data: ReleaseAllOccurrencesInputs,
    private dialogRef: MatDialogRef<ReleaseAllOccurrencesComponent>
  ) {}

  /**
   * Closes the dialog
   */
  public closeDialog(): void {
    this.dialogRef.close();
  }

  /**
   * Invoke the callback to release all occurrences & close the dialog
   */
  public releaseOccurrences(): void {
    this.data.releaseOccurrencesCallback();
    this.closeDialog();
  }
}

export const releaseAllOccurrenceConfig = {
  ...VUE_DIALOG_CONFIG,
};

export interface ReleaseAllOccurrencesInputs {

  /**
   * Pool name shown in dialog content
   */
  poolName: string;

  /**
   * Callback used when release is chosen by the user
   */
  releaseOccurrencesCallback: () => void;
}
