import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetMetricsParameters, GetMetricsResponse ,GetMetricsMonthlyParam } from 'api/types';
import { createQueryString } from 'utils/create-query-string';
import { CapacityMicroservice } from './microservices/capacity.microservice';

/**
 * API service for getting metrics
 */
@Injectable({
  providedIn: 'root'
})
export class MetricsService {
  public constructor(private api: CapacityMicroservice) { }

  /**
   * Calls GetMetrics API
   *
   * @param params optional params for get Metrics call
   * @returns GetMetricsResponse Observable
   */
  public getMetrics(params: Partial<GetMetricsParameters >): Observable<GetMetricsResponse> {
    const queryString = createQueryString(params);
    
    const path = `metrics${queryString}`;

    return this.api.get<GetMetricsResponse>(path);
  }

  public getMetric(params: Partial<GetMetricsMonthlyParam >,id:any): Observable<GetMetricsResponse> {
    const queryString = createQueryString(params);
    
    const path = `metrics/${id}${queryString}`;

    return this.api.get<GetMetricsResponse>(path);
  }
}
