import { formatDate } from '@angular/common';
import { EXCHANGE_FORMAT } from 'constants/date-formats';
import { INTERVAL_15MIN, INTERVAL_1HR, INTERVAL_6HR, INTERVAL_Monthly, INTERVAL_Yearly } from 'constants/intervals';
import { utc } from 'moment';
import { AlertResolveFilters } from 'types/AlertResolveFilters';
import { Timescale } from 'types/AppointmentFilters';
import { Location } from '@angular/common';
import {muVariable} from 'components/pages/alerts-resolve-page/alerts-resolve-page.component'
import { inject, Inject } from '@angular/core';
import { AddAlertPoolDataService } from 'services/add-alert-pool-data.service';


/**
 * Configs represent a subset of filter properties to be applied by default when a given timescale is selected.
 * Conversely, properties absent from the config, like, queue.value, are provided by the filter component.
 *
 * @returns alertResolveFilters the filter configuration for "daily" mode
 */
let setcalendarData:any;
export function resolve(elemet:any){
   setcalendarData=elemet?.key || elemet
   
}
export function dailyFilterConfig(): AlertResolveFilters {
  return {
    timescale: {
      enabled: true,
      value: Timescale.daily,
      options: [ Timescale.daily, Timescale.weekly, Timescale.monthly ],
    },
   
    startDate: {
      enabled: true,
    },
  };
}

/**
 * @returns appointmentFilters the filter configuration for "weekly" mode
 */
export function weeklyFilterConfig(): AlertResolveFilters {
  const daily = dailyFilterConfig();
  return {
    ...daily,
    timescale: {
      ...daily.timescale,
      value: Timescale.weekly,
    },
   
  };
}

/**
 * @returns appointmentFilters the filter configuration for "monthly" mode
 */
export function monthlyFilterConfig(): AlertResolveFilters {
  const daily = dailyFilterConfig();
  return {
    ...daily,
    timescale: {
      ...daily.timescale,
      value: Timescale.monthly,
    },
   
  };
}

/**
 * Return the base filter configuration; no user state included
 *
 * @param timescale the timescale on which to base the config
 * @param includeStartDate whether the startDate value should be set
 * @returns appointmentFilters the appropriate filter config
 */
export function getFilterConfig(timescale: Timescale | null, includeStartDate = false): AlertResolveFilters {
  let fetchDates:any
  const sharedDataservice=inject(Location)
  if(setcalendarData == null || undefined){
    sharedDataservice.back()
  }
  
  let filters: AlertResolveFilters;
  switch (timescale) {
    case Timescale.weekly:
      filters = weeklyFilterConfig();
      break;
    case Timescale.monthly:
      filters = monthlyFilterConfig();
      break;
    case Timescale.outagesmonthly:
      filters = outagesMonthlyFilterConfig();
      break;
    case Timescale.outagesyearly:
      filters = outagesYearlyFilterConfig();
      break;
    default:
      filters = dailyFilterConfig();
      break;
  }

  if (includeStartDate) {
    let todayDate=formatDate(Date.now(), 'YYYY-MM-dd', 'en-US', 'UTC');
    if(todayDate < setcalendarData.endDate){
      filters.startDate.value =todayDate;
    }else{
      filters.startDate.value=setcalendarData.startDate
    }
    
  }


  return filters;
}

/**
 * Utility to apply filter configuration defaults for a given timescale.
 *
 * @param timescale the timescale on which to base the config
 * @param filters the current filter state to alter
 * @returns appointmentFilters: the updated filter config
 */
export function applyFilterConfig(timescale: Timescale, filters: AlertResolveFilters): AlertResolveFilters {
  const config = getFilterConfig(timescale);

  // Original properties are retained unless the config supplies overrides
  const applied = {
    timescale: { ...filters.timescale, ...config.timescale },

    startDate: { ...filters.startDate, ...config.startDate },
  };

  // Set startDate to today if not already present
  if (!applied.startDate.value) {
   
    applied.startDate.value = formatDate(Date.now(), 'YYYY-MM-dd', 'en-US', 'UTC');
  }

  return applied;
}

export function outagesMonthlyFilterConfig(): AlertResolveFilters {
  const startDate = formatDate(Date.now(), 'YYYY-MM-dd', 'en-US', 'UTC');
  return {
    timescale: {
      enabled: true,
      value: Timescale.outagesyearly,
      options: [Timescale.outagesyearly, Timescale.outagesmonthly],
    },
    
    startDate: {
      enabled: false,
      value : startDate
    },
  };
}

export function outagesYearlyFilterConfig(): AlertResolveFilters {
  const daily = outagesMonthlyFilterConfig();
  const startDate = formatDate(Date.now(), 'YYYY-MM-dd', 'en-US', 'UTC');
  const momentDate = utc(startDate, EXCHANGE_FORMAT);
  
  return {
    ...daily,
    timescale: {
      ...daily.timescale,
      value: Timescale.outagesyearly,
    },
    
    startDate: {
      enabled: false,
      value : momentDate.startOf('year').format(EXCHANGE_FORMAT)
    },
  };
}