/* eslint-disable no-useless-escape */

/**
 * Regular expression that matches on characters that are not allowed in some form fields
 *
 * Characters allowed:
 * - Alphanumeric
 * - Commas
 * - Spaces
 * - Parentheses and brackets
 * - Hyphens and underscores
 * - Apostrophes
 */
export const invalidCharactersRegex = RegExp(/[^a-z0-9,()_\'\s\-\[\]]/, 'i');
