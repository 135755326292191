/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-use-before-define */
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  Component, ComponentRef, ElementRef, EventEmitter, forwardRef, OnDestroy, ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop, Observable, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { CapacityTemplate, GetCapacityTemplatesParameters, GetCapacityTemplatesResponse } from 'api/types';
import { TranslatePipe } from 'pipes/translate.pipe';
import { CapacityTemplatesService } from 'services/api/capacity-templates.service';
import { AutoCompleteStatusService } from 'services/status/auto-complete-status.service';
import { TemplateOptionsPaneComponent } from './template-options-pane/template-options-pane.component';

/**
 * Select template from given option list
 * Button is made to look like a material form field
 * That triggers TemplateOptionsPaneComponent
 */
@Component({
  selector: 'app-template-select-searchable',
  templateUrl: 'template-select-searchable.component.html',
  styleUrls: [ 'template-select-searchable.component.scss' ],
  providers: [ {
    provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(
      /* istanbul ignore next */
      () => TemplateSelectSearchableComponent
    ),
    multi: true
  } ]
})
export class TemplateSelectSearchableComponent implements OnDestroy, ControlValueAccessor {
  /**
   * Reference to the open button
   * Passed to overlay pane as reference element
   */
  @ViewChild('openButton', { read: ElementRef }) public openButton!: ElementRef;

  /**
   * Available options for template dropdown
   */
  public options: CapacityTemplate[] = [];

  /**
   * Event emitted when pane should be closed
   */
  public closePaneEvent: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Reference to the created CDK overlay
   */
  public overlayRef: OverlayRef;

  /**
   * Selected template option
   */
  public selectedOption: CapacityTemplate | null = null;

  /**
   * Reference to the TemplateOptionsPaneComponent
   */
  public componentRef!: ComponentRef<TemplateOptionsPaneComponent>;

  /**
   * Boolean tracking state of overlay
   * Used to apply specific styles to pane and input
   */
  public overlayOpen = false;

  /**
   * Change event attached to the Template options pane
   */
  public change = new EventEmitter<CapacityTemplate>();

  /**
   * Observable that completes when component is destroyed
   */
  private destroyed$ = new Subject();

  /**
   * Change callback to be called to update ApplyCapacityTemplate form
   */
  private onChangeCallback: (_: any) => void = noop;

  /**
   * Touched callback to be called to alter ApplyCapacityTemplate form
   * that form has been touched
   */
  private onTouchedCallback = noop;

  public constructor(
    private overlay: Overlay,
    private capacityTemplatesService: CapacityTemplatesService,
    private autoCompleteStatusService: AutoCompleteStatusService,
    private translatePipe: TranslatePipe,
  ) {
    // Fetch initial templates
    this.fetchInitialTemplates();

    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'searchable-select-overlay',
      scrollStrategy: this.overlay.scrollStrategies.reposition()
    });

    this.overlayRef.backdropClick()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.close();
      });

    this.overlayRef.keydownEvents()
      .pipe(filter((event) => event.key === 'Escape'))
      .pipe(takeUntil(this.destroyed$)).subscribe(() => {
        this.close();
      });

    this.closePaneEvent
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.close();
      });

    this.change
      .pipe(takeUntil(this.destroyed$))
      .subscribe((option) => {
        this.selectedOption = option;
        this.onChangeCallback(option);
        this.onTouchedCallback();
      });
  }

  /**
   * Terminate all subscriptions
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Create and open CDK overlay
   * Also set TemplateOptionsPaneComponent inputs and outputs
   */
  public open(): void {
    const strategy = this.overlay
      .position()
      .flexibleConnectedTo(this.openButton)
      .withPush(false)
      .withFlexibleDimensions(false)
      .withPositions([ {
        overlayX: 'start',
        overlayY: 'top',
        originX: 'start',
        originY: 'bottom'
      }, ]);
    this.overlayRef.updatePositionStrategy(strategy);
    this.overlayRef.updateSize({ width: this.openButton.nativeElement.offsetWidth });
    const portal = new ComponentPortal(TemplateOptionsPaneComponent);
    this.componentRef = this.overlayRef.attach(portal);
    this.componentRef.instance.options = this.options;
    this.componentRef.instance.selectedValue = this.selectedOption ? this.selectedOption.id : '';
    this.componentRef.instance.selectionChange = this.change;
    this.componentRef.instance.closePane = this.closePaneEvent;
    this.overlayOpen = true;

    // Update template list based on search value
    this.componentRef.instance.getNewTemplateList$
      .pipe(
        switchMap((searchString) => {
          this.autoCompleteStatusService.loading();
          return this.fetchTemplates(searchString);
        })
      )
      .subscribe((res) => {
        this.handleTemplateResponse(res.items);
        this.autoCompleteStatusService.success();
      }, () => {
        this.handleTemplateResponse([]);
        this.autoCompleteStatusService.error();
      });
  }

  /**
   * Removes overlayRef and sets overlayOpen to false to update styles
   */
  public close(): void {
    this.overlayRef.detach();
    this.overlayOpen = false;
  }

  /**
   * Provides Aria label for template select button
   */
  public getAriaLabel(): Observable<string> {
    if (this.selectedOption) {
      return this.translatePipe.transform('label.aria.selectedTemplate', this.selectedOption.name);
    }

    return this.translatePipe.transform('label.aria.selectATemplate');
  }

  /**
   * ApplyCapacityTemplate form will not update template value
   */
  public writeValue(): void {
    return;
  }

  /**
   * ApplyCapacityTemplate form passes a callback that will update
   * the form value when template is selected
   *
   * @param func change callback
   */
  public registerOnChange(func: (_: any) => void): void {
    this.onChangeCallback = func;
  }

  /**
   * ApplyCapacityTemplate form passes a touched callback
   * to be called when form has been touched
   *
   * @param func touch callback
   */
  public registerOnTouched(func: () => void): void {
    this.onTouchedCallback = func;
  }

  private fetchInitialTemplates(): void {
    this.autoCompleteStatusService.loading();

    this.fetchTemplates('')
      .subscribe((res) => {
        this.handleTemplateResponse(res.items);
        this.autoCompleteStatusService.success();
      }, () => {
        this.handleTemplateResponse([]);
        this.autoCompleteStatusService.error();
      });
  }

  /**
   * Get templates for template dropdown
   */
  private fetchTemplates(searchTerm: string): Observable<GetCapacityTemplatesResponse> {
    const params: GetCapacityTemplatesParameters = {
      searchTerm,
      sortBy: 'name',
      direction: 'asc',
      limit: 25,
      offset: 0,
      status: 'active',
    };

    return this.capacityTemplatesService.getCapacityTemplates(params);
  }

  /**
   * Handle response of fetching templates
   */
  private handleTemplateResponse(templates: GetCapacityTemplatesResponse['items']): void {
    this.options = templates;

    if (this.componentRef) {
      this.componentRef.instance.options = templates;
    }
  }
}
