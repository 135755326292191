import { Component, Input } from '@angular/core';

/**
 * A simple, presentational component to define sections of a page
 */
@Component({
  selector: 'vue-section',
  templateUrl: './vue-section.component.html',
  styleUrls: [ './vue-section.component.scss' ]
})
export class VueSectionComponent {
  /**
   * Optionally trigger loading state
   */
  @Input() public loading = false;
}
