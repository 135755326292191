<vue-slide-toggle
  [checked]="isRestricted"
  (change)="restrictionChange($event)"
  (focus)="focus()"
  #tooltip="matTooltip"
  matTooltip="{{ getToolTip() | async }}"
  matTooltipPosition="above">
  {{ getLabel() | async }}
  <img src="/assets/icons/icon-{{isRestricted ? 'lock' : 'unlock'}}.svg" alt=""/>
</vue-slide-toggle>
