<vue-flat-button (click)="drawer.open()">{{ "title.setCapacity" | translate | async }}</vue-flat-button>
<vue-drawer #drawer [contentRef]="setCapacity" (drawerClosed)="reset()">
  <ng-template #setCapacity>
    <app-drawer-header
      (close)="closeDrawer('close')"
      [disableClose]="status === 'loading'"
    >
      {{ "title.setCapacity" | translate | async }}
    </app-drawer-header>
    <div
      matDialogContent
      class="mat-typography top-border-on-scroll"
      [class.loading]="status === 'loading'"
    >
      <mat-button-toggle-group
        name="chooseCurrentCapacityStrategy"
        [attr.aria-label]="'label.aria.chooseSetCapacityStrategy' | translate | async"
        [ngModel]="currentCapacityStrategy"
        (ngModelChange)="updateCapacityStrategy($event)"
      >
        <mat-button-toggle value="bulk-edit">{{ "title.bulkEdit" | translate | async }}</mat-button-toggle>
        <mat-button-toggle value="apply-capacity-template">{{ "title.applyCapacityTemplate" | translate | async }}</mat-button-toggle>
        <mat-button-toggle value="capacity-file-upload">{{ "title.capacityFileUpload" | translate | async }}</mat-button-toggle>
      </mat-button-toggle-group>

      <app-bulk-edit-form
        *ngIf="currentCapacityStrategy === 'bulk-edit'"
        (formStatusChange)="formStatusChange($event)"
        (success)="showBulkEditSuccess()"
      ></app-bulk-edit-form>

      <app-apply-capacity-template-form
        *ngIf="currentCapacityStrategy === 'apply-capacity-template'"
        (formStatusChange)="formStatusChange($event)"
        (success)="appliedCapacityTemplate($event)"
      ></app-apply-capacity-template-form>

      <app-capacity-file-upload
        *ngIf="currentCapacityStrategy === 'capacity-file-upload'"
        (formStatusChange)="formStatusChange($event)"
        (success)="successfulCapacityUploaded()"
        [triggerSave]="enableSaveTrigger"
      ></app-capacity-file-upload>
    </div>
    <div matDialogActions>
      <ng-container>
        <vue-basic-button
          [disabled]="status === 'loading'"
          (clicked)="closeDrawer('')"
        >
          {{ "button.cancel" | translate | async }}
        </vue-basic-button>
        <vue-flat-button *ngIf="currentCapacityStrategy !== 'capacity-file-upload'"
          type="submit"
          form="form"
          [disabled]="!formIsValid"
          [loading]="status === 'loading'"
        >{{ "button.save" | translate | async }}
        </vue-flat-button>
      </ng-container>
      <ng-container *ngIf="currentCapacityStrategy === 'capacity-file-upload'">
        <!-- due to flex layout, this empty div is required to push the done button to the right -->
        <div></div>
        <vue-flat-button
          type="submit"
          form="form"
          [disabled]="!formIsValid || status === 'loading' || !enableSaveTrigger"
          (clicked)="closeDrawer('submit')"
          [loading]="isLoading"
        >{{ "button.save" | translate | async }}
        </vue-flat-button>
      </ng-container>
    </div>
  </ng-template>
</vue-drawer>
<vue-toast icon="success">
  <span toast-body>{{ toastMessage }}</span>
</vue-toast>
