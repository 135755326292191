<app-error-message
  *ngIf="displayableServerError"
  [heading]="displayableServerError.title"
  [message]="displayableServerError.message"
></app-error-message>
<form id="form" [formGroup]="form" (ngSubmit)="applyCapacityTemplate()" autocomplete="off">
  <app-template-select-searchable
    formControlName="template"
  ></app-template-select-searchable>
  <vue-select
    label="{{ 'label.placeholder.queues' | translate | async }}"
    formControlName="queue"
    [required]="true"
    [options]="queues"
    [getOptionDisplay]="getQueueDisplay"
    [errorMatcher]="suppressErrorState"
  ></vue-select>
  <div class="two-columns">
    <vue-datepicker
      label="{{ 'label.placeholder.startDate' | translate | async }}"
      placeholder="{{ 'placeholder.datepicker' | translate | async }}"
      formControlName="startDate"
      [required]="true"
      [errorMatcher]="datePickerErrorMatcher"
      [minDateIsToday]="true"
      [filterDaysOfWeek]="[ 0 ]"
      [maxDate]="form.controls.endDate.value"
      [errorMessage]="form.controls.startDate.errors | datepickerError | translate | async"
    ></vue-datepicker>
    <vue-datepicker
      label="{{ 'label.placeholder.endDate' | translate | async }}"
      placeholder="{{ 'placeholder.datepicker' | translate | async }}"
      formControlName="endDate"
      [required]="true"
      [errorMatcher]="datePickerErrorMatcher"
      [minDate]="form.controls.startDate.value"
      [minDateIsToday]="true"
      [filterDaysOfWeek]="[ 6 ]"
      [errorMessage]="form.controls.endDate.errors | datepickerError | translate | async"
    ></vue-datepicker>
  </div>
  <div class="two-columns">
    <vue-numeric-input
      label="{{ 'label.placeholder.totalAppointments' | translate | async }}"
      formControlName="totalAppointments"
      alignText="left"
      errorMessage="{{ 'error.message.submission' | translate | async }}"
      [required]="true"
      [maxLength]="10"
      [errorMatcher]="getCalculationsErrorMatcher"
      (focusout)="appointmentCalculateAllocations();"
    >
      <button
        *ngIf="getCalculationsErrorMatcher.hasError"
        suffix
        mat-icon-button
        type="button"
        [attr.aria-label]="'label.aria.retryCalculations' | translate | async"
        (click)="retryCalculations()"
      >
        <mat-icon>refresh</mat-icon>
      </button>
    </vue-numeric-input>
  </div>
</form>
<section class="two-columns">
  <div class="appointment-allocation-wrapper">
    <h4>{{ "title.appointmentAllocation" | translate | async }}</h4>
    <div class="data-wrapper">
      <table class="appointment-table">
        <caption class="visually-hidden">
          {{ 'table.caption.appointmentAllocation' | translate | async }}
        </caption>

        <thead>
          <tr>
            <!--
              Including aria-label so screen reader doesn't read DAY as D-A-Y.
              The screen reader can interpret all caps three letter words as acronyms.
            -->
            <th attr.aria-label="{{ 'title.day' | translate | async }}">
              <vue-detail-header>{{ "title.day" | translate | async }}</vue-detail-header>
            </th>
            <th>
              <vue-detail-header>{{ "title.capacity" | translate | async }}</vue-detail-header>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let day of daysOfWeek, let i = index">
            <td class="day-of-week">
              {{
                calculationsDisplayed() ?
                getLocalizedDay(dailyCalculations[ i ].dayOfWeek) :
                day
              }}
            </td>
            <td class="allocation-value">
              {{ calculationsDisplayed() ? (dailyCalculations[ i ].capacity | number) : '–' }}
            </td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="calculationsDisplayed()" class="total">
        <span class="day-of-week">{{ "title.total" | translate | async }}</span>
        <span class="total-value">{{ totalForWeek | number }}</span>
      </div>
      <div *ngIf="calculationsDisplayed()" class="hourly-allocations-button-wrapper">
        <button
          class="hourly-allocations-button"
          (click)="openHourlyDialog()"
          aria-haspopup="dialog"
        >
          {{ "button.viewHourlyAllocations" | translate | async }}
        </button>
      </div>
    </div>
  </div>
</section>
