<div class="privacy-banner" *ngIf="!isCookieSet">
  <div>
    <p class="title">{{ 'privacy-banner.title' | translate | async }}</p>
    <p class="message">
      {{ 'privacy-banner.message' | translate | async }}
      <a href="https://home.pearsonvue.com/Legal/Privacy-and-cookies-policy.aspx#cookies" target="_blank">
        {{ 'privacy-banner.learnMore' | translate | async }}
      </a>
    </p>
  </div>
  <button mat-button class="understand-button" (click)="hideCookieBannerBar()">
    {{ 'privacy-banner.iUnderstand' | translate | async }}
  </button>
</div>
