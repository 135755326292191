import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Moment, utc } from 'moment';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { TranslatePipe, TranslationKey, TranslationLookup } from 'pipes/translate.pipe';
import { Timescale } from 'types/AppointmentFilters';
import { getFilterConfig, applyFilterConfig } from './alert-resolve-filter-config';
import { filtersToParams } from './alert-resolve-filters-to-params';
import { AlertResolveFilters, AlertResolveFilterValue } from 'types/AlertResolveFilters';
import { AlertResolveFiltersService } from 'services/alert-resolve-filters.service';



@Component({
  selector: 'app-alert-resolve-filters',
  templateUrl: './alert-resolve-filters.component.html',
  styleUrls: ['./alert-resolve-filters.component.scss']
})

export class AlertResolveFiltersComponent implements OnInit, OnDestroy {
  @Input() setDatePickerValues?: any;
  @Input() public alertSpecificData: any;

  /**
   * State for all appointment filters
   * 
   */
  
  public filters: AlertResolveFilters = getFilterConfig(Timescale.monthly, true);


  /**
   * Date object that is used to determine correct offsets for timezones
   */
  public dateForTimezone: Moment = utc();

  /**
   * The localization keys to look up.
   */
  private translationKeys: TranslationKey[] = [
    'title.monthly',
    'title.oneDay',
    'title.oneHour',
    'title.sixHours',
    'title.utc',
    'title.weekly',
  ]

  /**
   * A localized string lookup.
   */
  private translations: TranslationLookup = {};

  /**
   * Terminate subscriptions
   */
  private destroyed$ = new Subject();

  public constructor(
    private filterService: AlertResolveFiltersService,
    private translatePipe: TranslatePipe,
  ) { }

  public ngOnInit(): void {
  
    this.filterService.params$
      .pipe(take(1))
      .subscribe(() => {
        this.setInitialFilters();
      });

    // Load the localization translations
    this.translatePipe.loadTranslations(this.translationKeys)
      .pipe(take(1))
      .subscribe((result) => {
        this.translations = result;
      });
  }

  /**
   * Complete all subscriptions
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Update a single filter and trigger fetch of new metrics
   */
  public filterChanged(name: keyof AlertResolveFilters, value: AlertResolveFilterValue): void {
    if (this.filters[name]) {
      this.filters[name].value = value;
      this.filterService.updateParams(filtersToParams(this.filters));
    }
  }

  /**
   * Set initial value of the filters based on existing params
   */
  private setInitialFilters(): void {
    // If timescale is not the default, update the configuration
    const paramTimescale = Timescale.monthly;
    if (this.filters.timescale.value !== paramTimescale) {
      this.filters.timescale.value = paramTimescale;
      this.filters = applyFilterConfig(this.filters.timescale.value, this.filters);
    }
    this.filters.startDate.value = this.filters.startDate.value;
    this.filterService.updateParams(filtersToParams(this.filters));
  }
}
