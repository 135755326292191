import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isMoment, utc } from 'moment';
import { EXCEEDS_END_DATE, INVALID_DATE, PAST_DATE } from 'constants/datepicker-errors';

/**
 * Validates a pool release from the parent form level
 *
 * Validation occurs at the form level because the pool end date is compared.
 */
export function poolReleaseValidator(poolReleaseControlName: string, endDateControlName: string): ValidatorFn {
  return (form: AbstractControl) => {
    const poolRelease = form.get(poolReleaseControlName);
    const endDate = form.get(endDateControlName);

    if (poolRelease === null || endDate === null) {
      return { 'missing-controls': { poolRelease: poolRelease?.value, endDate: endDate?.value } };
    }

    // Pool does not have to be auto released
    if (poolRelease.value === null) {
      return null;
    }

    // Release pool by date requires a date
    if (poolRelease.value?.type === 'date') {
      return validatePoolReleaseDate(poolRelease, endDate);
    }

    // Timed release needs a value for days, hours, and before
    if (poolRelease.value?.type === 'time') {
      return validatePoolReleaseTime(poolRelease);
    }

    return null;
  };
}

/**
 * Validate pool release by date
 * Validations:
 * 1. Date is required
 * 2. Date is a valid date
 * 3. Date is not in the past
 * 4. Date does not exceed the pool end date
 */
function validatePoolReleaseDate(
  poolReleaseControl: AbstractControl,
  endDateControl: AbstractControl
): ValidationErrors | null {
  if (!poolReleaseControl.value?.date) {
    return { 'missing-release-date': poolReleaseControl.value };
  }

  const date = utc(poolReleaseControl.value.date);

  if (!date.isValid()) {
    return { [ INVALID_DATE ]: poolReleaseControl.value };
  }

  if (date.isBefore(utc().startOf('d'), 'd')) {
    return { [ PAST_DATE ]: poolReleaseControl.value };
  }

  if (isMoment(endDateControl?.value) && date.isAfter(endDateControl.value.utc().startOf('d'), 'd')) {
    return { [ EXCEEDS_END_DATE ]: poolReleaseControl.value };
  }

  return null;
}

/**
 * Validate a timed pool release
 */
function validatePoolReleaseTime(poolReleaseControl: AbstractControl): ValidationErrors | null {
  const { value } = poolReleaseControl;

  // eslint-disable-next-line no-undefined
  if (value?.time === null || value?.time === undefined) {
    return { 'invalid-release-time': value };
  }

  if (value.interval !== 'hours' && value.interval !== 'days') {
    return { 'invalid-release-interval': value };
  }

  if (value.before !== 'start-time' && value.before !== 'end-time') {
    return { 'invalid-release-before': value };
  }

  return null;
}
