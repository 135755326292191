<div>
    <div class="filter-wrapper">
      <div class="search-filter-wrapper">
        <vue-filter-search
          placeholder="{{ 'label.placeholder.searchPoolNames' | translate | async }}"
          (searchValueChange)="updateSearchParams($event)"
          [inputValue]="initialSearchTerm"
          [invalidRegex]="invalidRegex"
        ></vue-filter-search>
      </div>
      <!-- <app-filter-multi-select
        placeholder="{{ 'title.status' | translate | async }}"
        [includeOptionSearch]="false"
        [options]="statusOptions"
        (change)="statusSelectionUpdated($event)">
      </app-filter-multi-select> -->
      <app-alert-selectfilter
        (clientsSelected)="clientSelectionUpdated($event,'')"
      ></app-alert-selectfilter>
      <app-alert-more-filters></app-alert-more-filters>
    </div>
    <app-filter-by
     [filters]="selectedFilters"
     (filterRemoved)="filterRemoved($event)"
    ></app-filter-by>
  </div>
  