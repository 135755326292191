<button
  mat-button
  color="primary"
  type="button"
  [matMenuTriggerFor]="menu"
>
  {{ 'label.addTemplate.copyFromPrevious' | translate | async }}
  <img class="select-carrot" src="/assets/icons/icon-open-select-blue.svg" alt="" />
</button>
<mat-menu
  #menu="matMenu"
  class="day-of-week-menu"
  overlapTrigger="false"
  xPosition="before"
>
  <ng-container *ngFor="let option of availableDays">
    <button
      mat-menu-item
      type="button"
      (click)="copyDay(option.value)"
    >
      {{ option.translationKey | translate | async }}
    </button>
  </ng-container>
</mat-menu>
