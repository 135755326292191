const midNight = '#003057'; // match $vue-midnight-blue
const blue = '#6db9da';
const lightBlue = '#eaeef2';
const grayD = '#ddd';
const grayE = '#eee';
const grayF = '#dfe1e1'; // match $vue-grey-3
const red = '#cd2424'; // match $vue-attention-red

export const palette = {
  midNight, blue, lightBlue, grayD, grayE, grayF, red
};

export default palette;
