import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { VueArrowComponent } from './components/vue-arrow/vue-arrow.component';
import { VueBreadcrumbsComponent } from './components/vue-breadcrumbs/vue-breadcrumbs.component';
import { VueButtonComponent, VueFlatButtonComponent } from './components/vue-button';
import { VueChipComponent } from './components/vue-chip/vue-chip.component';
import { VueDateRangePickerComponent } from './components/vue-date-range-picker/vue-date-range-picker.component';
import { VueDatePickerChipListComponent } from './components/vue-datepicker-chiplist/vue-datepicker-chiplist.component';
import { VueDatePickerComponent } from './components/vue-datepicker/vue-datepicker.component';
import { VueDetailContentComponent } from './components/vue-detail-content/vue-detail-content.component';
import { VueDetailHeaderComponent } from './components/vue-detail-header/vue-detail-header.component';
import { VueDrawerComponent } from './components/vue-drawer/vue-drawer.component';
import { VueFilterSearchComponent } from './components/vue-filter-search/vue-filter-search.component';
import { VueFilterSelectComponent } from './components/vue-filter-select/vue-filter-select.component';
import { VueFooterComponent } from './components/vue-footer/vue-footer.component';
import { VueImageTooltipComponent } from './components/vue-image-tooltip/vue-image-tooltip.component';
import { VueNavDrawerComponent } from './components/vue-nav-drawer/vue-nav-drawer.component';
import { VueNumberIndicatorComponent } from './components/vue-number-indicator/vue-number-indicator.component';
import { VueNumericInputComponent } from './components/vue-numeric-input/vue-numeric-input.component';
import { VuePageHeaderComponent } from './components/vue-page-header/vue-page-header.component';
import { VuePercentInputComponent } from './components/vue-percent-input/vue-percent-input.component';
import { VueChartPopoverHiddenButtonComponent } from './components/vue-popover-module/vue-chart-popover-hidden-button/vue-chart-popover-hidden-button.component';
import { VueChartPopoverDirective } from './components/vue-popover-module/vue-chart-popover/vue-chart-popover.directive';
import { VuePopoverModule } from './components/vue-popover-module/vue-popover.module';
import { VueRadioGroupComponent } from './components/vue-radio-group/vue-radio-group.component';
import { VueSectionHeaderActionsComponent } from './components/vue-section-header-actions/vue-section-header-actions.component';
import { VueSectionHeaderComponent } from './components/vue-section-header/vue-section-header.component';
import { VueSectionComponent } from './components/vue-section/vue-section.component';
import { VueSelectComponent } from './components/vue-select/vue-select.component';
import { VueSlideToggleComponent } from './components/vue-slide-toggle/vue-slide-toggle.component';
import { VueTableContainerComponent } from './components/vue-table-container/vue-table-container.component';
import { VueTextInputComponent } from './components/vue-text-input/vue-text-input.component';
import { VueToastComponent } from './components/vue-toast/vue-toast.component';
import { VueNumberInputDirective } from './directives/vue-number-input/vue-number-input.directive';
import { VueOverlayOpenDirective } from './directives/vue-overlay-open/vue-overlay-open.directive';
import { VueTableMenuOpenDirective } from './directives/vue-table-menu-open/vue-table-menu-open.directive';
import { VueTranslatePipe } from './pipes/vue-translate.pipe';
import { VueFormFieldBaseComponent } from './utilities/vue-form-field-base/vue-form-field-base.component';
import { VueTextAreaComponent } from './components/vue-text-area/vue-text-area.component';

/**
 * VUEUI related components
 */
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatChipsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatTooltipModule,
    ReactiveFormsModule,
    RouterModule,
    VuePopoverModule,
  ],
  declarations: [

    // Directives
    VueChartPopoverDirective,
    VueNumberInputDirective,
    VueOverlayOpenDirective,
    VueTableMenuOpenDirective,

    // Components
    VueArrowComponent,
    VueBreadcrumbsComponent,
    VueButtonComponent,
    VueChartPopoverHiddenButtonComponent,
    VueChipComponent,
    VueDatePickerComponent,
    VueDatePickerChipListComponent,
    VueDateRangePickerComponent,
    VueDetailContentComponent,
    VueDetailHeaderComponent,
    VueDrawerComponent,
    VueFilterSearchComponent,
    VueFilterSelectComponent,
    VueFlatButtonComponent,
    VueFooterComponent,
    VueFormFieldBaseComponent,
    VueNavDrawerComponent,
    VueNumberIndicatorComponent,
    VueNumericInputComponent,
    VuePageHeaderComponent,
    VuePercentInputComponent,
    VueRadioGroupComponent,
    VueSectionComponent,
    VueSectionHeaderComponent,
    VueSectionHeaderActionsComponent,
    VueSelectComponent,
    VueSlideToggleComponent,
    VueTableContainerComponent,
    VueTextInputComponent,
    VueToastComponent,
    VueImageTooltipComponent,
    VueTextAreaComponent,
    
    // Pipes
    VueTranslatePipe,
  ],
  exports: [

    // Directives
    VueChartPopoverDirective,
    VueNumberInputDirective,
    VueOverlayOpenDirective,
    VueTableMenuOpenDirective,

    // Components
    VueArrowComponent,
    VueBreadcrumbsComponent,
    VueButtonComponent,
    VueChartPopoverHiddenButtonComponent,
    VueChipComponent,
    VueDatePickerComponent,
    VueDatePickerChipListComponent,
    VueDateRangePickerComponent,
    VueDetailContentComponent,
    VueDetailHeaderComponent,
    VueDrawerComponent,
    VueFilterSearchComponent,
    VueFilterSelectComponent,
    VueFlatButtonComponent,
    VueFooterComponent,
    VueFormFieldBaseComponent,
    VueNavDrawerComponent,
    VueNumberIndicatorComponent,
    VueNumericInputComponent,
    VuePageHeaderComponent,
    VuePercentInputComponent,
    VueRadioGroupComponent,
    VueSectionComponent,
    VueSectionHeaderComponent,
    VueSectionHeaderActionsComponent,
    VueSelectComponent,
    VueSlideToggleComponent,
    VueTableContainerComponent,
    VueTextInputComponent,
    VueToastComponent,
    VueImageTooltipComponent,
    VueTextAreaComponent
  ],
  providers: [

    // Set default color of checkboxes to primary palette instead of accent
    { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { color: 'primary' } }
  ]
})
export class VueModule { }
