/* eslint-disable @typescript-eslint/member-ordering, no-invalid-this */
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { DEFAULT_VALUES } from './poolconsumption-filters.service';
import { PageStatusService } from './status/page-status.service';
import { GetPoolConsumptionParameters, GetPoolConsumptionResponse } from 'api/types/endpoints/getPoolConsumption';
import { PoolConsumptionService } from './api/poolconsumption.service';

const emptyResponse: GetPoolConsumptionResponse = {
  ...DEFAULT_VALUES,
  total: 0,
  items: []
};

/**
 * Service that stores all client data for the Clients page.
 */
@Injectable({
  providedIn: 'root'
})
export class PoolConsumptionPageDataService implements OnDestroy {
  private readonly params = new BehaviorSubject<GetPoolConsumptionParameters>(DEFAULT_VALUES)

  // eslint-disable-next-line @typescript-eslint/member-ordering, no-invalid-this
  public params$ = this.params.asObservable()
  /**
   * Fill initial response with empty values, filter component will populate on initialization
   * Private BehaviorSubject so only this service can alter the value
   */
  private readonly poolConsumptionResponse = new BehaviorSubject<GetPoolConsumptionResponse>(emptyResponse);

  /**
   * Private BehaviorSubject so only this service can alter the value
   */
  private readonly fetchingPoolConsumption = new BehaviorSubject(false);

  /**
   * Observable that emits the current state of the client data
   */
  public poolConsumptionResponse$ = this.poolConsumptionResponse.asObservable();

  /**
   * Observable that emits a boolean if pool consumption are being fetched
   */
  public fetchingPoolConsumption$ = this.fetchingPoolConsumption.asObservable();

  /**
   * Terminates all subscriptions when completed
   */
  private destroyed$ = new Subject();

  /**
   * Emits when new pool consumption data should be fetched
   */
  private fetchPoolConsumptionData$ = new Subject<GetPoolConsumptionParameters>();

  public constructor(
    private poolConsumptionService: PoolConsumptionService,
    private pageStatusService: PageStatusService,
  ) {
    this.fetchPoolConsumptionData$
      .pipe(takeUntil(this.destroyed$))
      .pipe(switchMap(() => { // switchMap will cancel any in-flight API requests
        return this.fetchPoolConsumption();
      }))
      .subscribe((res) => {
        this.poolConsumptionSuccessHandler(res);
      }, () => {
        this.poolConsumptionErrorHandler();
      });
  }

  /**
   * Terminates all subscriptions.
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Gets the client data
   *
   * @param params params to pass to service
   */
  public fetchPoolConsumption(): Observable<GetPoolConsumptionResponse> {
    this.fetchingPoolConsumption.next(true);

    this.pageStatusService.loading(() => {
      this.fetchPoolConsumptionData$.next();
    });

    return this.poolConsumptionService.getPoolConsumption(this.params.getValue());
  }

  /**
   * GetClients success handler
   */
  private poolConsumptionSuccessHandler(response: GetPoolConsumptionResponse): void {
    this.poolConsumptionResponse.next(response);
    this.fetchingPoolConsumption.next(false);
    this.pageStatusService.success();
  }

  /**
   * GetPoolConsumption error handler
   */
  private poolConsumptionErrorHandler(): void {
    this.pageStatusService.error();
    this.fetchingPoolConsumption.next(false);
  }

  public updateParams(changes: Partial<GetPoolConsumptionParameters>): void {
    this.params.next({ ...this.params.getValue(), offset: 0, ...changes });
    this.fetchPoolConsumptionData$.next();
  }

  public fetchPoolConsumptionData() {
    this.fetchPoolConsumptionData$.next();
  }
}
