import { Component, Input } from '@angular/core';

/**
 * Component to display X-axis labels underneath the main chart area.
 * Creating our own labels gives us more control over layout and display compared with stock ChartJS labels.
 */
@Component({
  selector: 'app-custom-bottom-x-axis-labels',
  templateUrl: './custom-bottom-x-axis-labels.component.html',
  styleUrls: [ './custom-bottom-x-axis-labels.component.scss' ]
})
export class CustomBottomXAxisLabelsComponent {
  /**
   * Labels to display
   */
  @Input() public labels: string[] = [];

  /**
   * Width of chart to match
   */
  @Input() public chartWidthRems = 0;
}
