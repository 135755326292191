import { Component } from '@angular/core';

/**
 *  "Page not found" page
 */
@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
})
export class PageNotFoundComponent {}
