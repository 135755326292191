import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { CapacityTemplate } from 'api/types';

/**
 * CDK overlay pane for TemplateSelectSearchableComponent
 */
@Component({
  selector: 'app-template-options-pane',
  templateUrl: './template-options-pane.component.html',
  styleUrls: [ './template-options-pane.component.scss' ]
})
export class TemplateOptionsPaneComponent {
  /**
   * List of template options to display
   */
  @Input() public options?: CapacityTemplate[];

  /**
   * ID of selected template
   */
  @Input() public selectedValue = '';

  /**
   * Emitted event when template selection changes
   */
  @Output() public selectionChange = new EventEmitter<CapacityTemplate>();

  /**
   * Emitted event when pane should be closed
   */
  @Output() public closePane = new EventEmitter<void>();

  /**
   * Reference to `ul` element of options
   */
  @ViewChild('optionsList') public optionsList?: ElementRef<HTMLOptionElement>

  /**
   * Subject that emits with search parameter to fetch new client list
   */
  public getNewTemplateList$ = new Subject<string>();

  /**
   * Value of search input, used to bold matching text in results
   */
  public searchTerm = '';

  /**
   * Update template selection and close the pane
   *
   * @param option selected template
   */
  public selectionUpdate(option: CapacityTemplate): void {
    this.selectionChange.emit(option);
    this.closePane.emit();
  }

  /**
   * Emit observable searchTerm for list of templates
   */
  public updateTemplateSearch(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.getNewTemplateList$.next(searchTerm);
  }

  /**
   * To mimic a native keyboard navigation for an option element
   * move through template options when arrow keys are used
   */
  public changeFocus(newFocusIndex: number, event: Event): void {
    // stop keyboard from scrolling the pane
    event.stopImmediatePropagation();
    event.preventDefault();

    let nextIndex = newFocusIndex;

    // loop around navigation if needed
    if (newFocusIndex < 0 && this.options) {
      nextIndex = this.options.length - 1;
    } else if (this.options && newFocusIndex >= this.options.length) {
      nextIndex = 0;
    }

    // focus on next option item
    (this.optionsList?.nativeElement.querySelector(`.option-index-${nextIndex}`) as HTMLElement)?.focus();
  }
}
