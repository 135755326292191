<h4>
  {{ "title.dailyTimeExceptions" | translate | async }}
</h4>
<ul [formGroup]="exceptionForm">
  <ng-container formArrayName="exceptions">
    <li *ngFor="let exceptionForm of exceptions.controls; let i = index;" class="exception-list-item">
      <div class="exception-container" [formGroup]="getExceptionControl(i)">
        <vue-select
          label="{{ 'title.time' | translate | async }}"
          formControlName="time"
          [options]="getTimeOptionsForControl(i)"
          [getOptionDisplay]="addUTCToTime.bind(this)"
          [errorMatcher]="suppressErrorState"
        ></vue-select>
        <ng-container formGroupName="capacity">
          <vue-select
            formControlName="type"
            label="{{ 'title.logic' | translate | async }}"
            [options]="logicOptions"
            [getOptionDisplay]="getLogicOptionText.bind(this)"
            [errorMatcher]="suppressErrorState"
          ></vue-select>
          <vue-numeric-input
            formControlName="count"
            label="{{ 'title.capacity' | translate | async }}"
            alignText="left"
            [errorMatcher]="suppressErrorState"
            [maxLength]="5"
          ></vue-numeric-input>
        </ng-container>
        <button
          mat-icon-button
          type="button"
          [attr.aria-label]="'label.aria.removeDailyException' | translate | async"
          class="close-button"
          (click)="removeException(i)"
        >
          <img
            alt=""
            class="close-icon"
            src="../../../../assets/icons/icon-grey-close-x.svg"
          />
        </button>
      </div>
    </li>
  </ng-container>
</ul>
<button
  type="button"
  class="add-exception-button"
  (click)="addException()"
  [disabled]="disableAddException()"
>
  <div class="addition"></div>
  {{ "title.addException" | translate | async }}
</button>
