import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpRequestOptions } from './http-options.type';

/**
 * During initial frontend-to-backend integration, we will be using a static, hard-coded authorization token.
 * This is a temporary, minimal-security setup that will be replaced with full user authentication once we integrate
 * with the assist portal.
 */
export const TEMP_AUTH_HEADER_KEY = 'Ocp-Apim-Subscription-Key';
export const TEMP_AUTH_HEADER_VALUE = 'bff8d7c1180a4da0a4625e66eafe723b';

/**
 * HTTP Client class that is used to make api requests
 * Consumed by the Microservice Injectables
 */
export abstract class ApiHttpClient {
  /**
   * Base URL from Config
   */
  public baseUrl: string;

  protected constructor(
    private http: HttpClient,
    baseUrl: string
  ) {
    this.baseUrl = baseUrl;
  }

  /**
   * Add temporary authorization header, required only for non-Portal (non OAuth) environments
   *
   * @param options HttpRequestOptions, optional
   * @returns HttpRequestOptions
   */
  private static addTemporaryAuthHeader(options?: HttpRequestOptions): HttpRequestOptions {
    const opts = options || {};
    if (!opts.headers) {
      opts.headers = new HttpHeaders();
    }

    // headers is immutable; set() returns a clone of the original set
    opts.headers = opts.headers.set(TEMP_AUTH_HEADER_KEY, TEMP_AUTH_HEADER_VALUE);
    return opts;
  }

  /**
   * Get Request to API
   *
   * @param path Path of operation desired
   * @param options HTTP Options
   * @returns Observable to result
   */
  public get<T>(path: string, options?: HttpRequestOptions): Observable<T> {
    const url = this.baseUrl + path;
    return this.http.get<T>(url, ApiHttpClient.addTemporaryAuthHeader(options));
  }

  /**
   * Post Request to API
   *
   * @param path Path of operation desired
   * @param body Body object attached to POST
   * @param options HTTP Options
   * @returns Observable to result
   */
  public post<T, B>(path: string, body: B, options?: HttpRequestOptions): Observable<T> {
    const url = this.baseUrl + path;
    return this.http.post<T>(url, body, ApiHttpClient.addTemporaryAuthHeader(options));
  }

  /**
   * Put Request to API
   *
   * @param path Path of operation desired
   * @param body Body object attached to POST
   * @param options HTTP Options
   * @returns Observable to result
   */
  public put<T, B>(path: string, body: B, options?: HttpRequestOptions): Observable<T> {
    const url = this.baseUrl + path;
    return this.http.put<T>(url, body, ApiHttpClient.addTemporaryAuthHeader(options));
  }

  /**
   * Patch Request to API
   *
   * @param path Path of operation desired
   * @param body Body object attached to POST
   * @param options HTTP Options
   * @returns Observable to result
   */
  public patch<T, B>(path: string, body: B, options?: HttpRequestOptions): Observable<T> {
    const url = this.baseUrl + path;
    return this.http.patch<T>(url, body, ApiHttpClient.addTemporaryAuthHeader(options));
  }

  public delete<T>(path: string, options?: HttpRequestOptions): Observable<T> {
    const url = this.baseUrl + path;
    return this.http.delete<T>(url, ApiHttpClient.addTemporaryAuthHeader(options));
  }
}
