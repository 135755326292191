import { ChangeDetectorRef, Directive, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { VuePopoverRef } from './vue-popover-ref';

/**
 * Internal directive that shows the popover arrow.
 */
@Directive({
  selector: '[popoverArrow]'
})
export class VuePopoverArrowDirective implements OnInit, OnDestroy {
  @HostBinding('style.width.px')
  @HostBinding('style.height.px')
  public arrowSize?: number;

  @HostBinding('style.top.px')
  public top?: number;

  @HostBinding('style.right.px')
  public right?: number;

  @HostBinding('style.bottom.px')
  public bottom?: number;

  @HostBinding('style.left.px')
  public left?: number;

  private subscription = new Subscription();

  public constructor(private popoverRef: VuePopoverRef, private cd: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.arrowSize = this.popoverRef.config.arrowSize;

    this.subscription = this.popoverRef.positionChanges().subscribe((p) => {
      const { offsetX, offsetY } = p.connectionPair;

      this.clearOffsets();

      /**
       * The offsets given are calculated from this "origin" of the popover.
       * Since the arrow is positioned "outside in" (with left right top bottom) we may need to flip the offsets
       */
      if (offsetX) {
        if (offsetX < 0) {
          this.left = offsetX * -1;
        } else {
          this.right = offsetX;
        }
      }

      if (offsetY) {
        if (offsetY >= 0) {
          this.top = offsetY * -1;
        } else {
          this.bottom = offsetY;
        }
      }

      this.cd.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private clearOffsets(): void {
    delete this.left;
    delete this.right;
    delete this.top;
    delete this.bottom;
  }
}
