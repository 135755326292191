import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { GetPoolsParameters, GetPoolsResponse, Queue } from 'api/types';
import { AllPoolsFiltersService } from './all-pools-filters.service';
import { PoolsService } from './api/pools.service';
import { QueuesService } from './api/queues.service';
import { PageStatusService } from './status/page-status.service';
import { AllAlertFiltersService } from './all-alert-filters.service';

@Injectable({
  providedIn: 'root'
})
export class AllAlertDataService {



  private readonly allPoolsData = new BehaviorSubject<GetPoolsResponse | null>(null);
  private readonly queues = new BehaviorSubject<Queue[]>([]);
  private readonly fetchingPools = new BehaviorSubject(false);

  /**
   * Emits all pool details each time they are updated
   */
  public allPoolsData$ = this.allPoolsData.asObservable();

  /**
   * Emits queues array
   */
  public queues$ = this.queues.asObservable();

  /**
   * Emits when pools are being fetched
   */
  public fetchingPools$ = this.fetchingPools.asObservable();

  /**
   * Terminates all subscriptions when completed
   */
  private destroyed$ = new Subject();

  /**
   * Subject that emits when all pool data should be fetched
   */
  private fetchPoolData$ = new Subject<GetPoolsParameters>();

  public constructor(
    private allPoolsFilterService: AllAlertFiltersService,
    private poolsService: PoolsService,
    private queueService: QueuesService,
    private pageStatusService: PageStatusService,
  ) {
    // Fetch pools
    this.fetchPoolData$
      .pipe(takeUntil(this.destroyed$))
      .pipe(switchMap((params) => { // switchMap will cancel any in-flight API requests
      
        this.pageStatusService.loading(() => {
          this.fetchPoolData$.next(params);
        });

        this.fetchingPools.next(true);
        return this.poolsService.getPools(params);
      })).subscribe((res) => {
       
        
        this.fetchPoolsSuccess(res);
      }, () => {
        this.fetchPoolsError();
      });

    // Update pool data whenever filters change
    this.allPoolsFilterService.params$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((filters) => {
      
        
        this.fetchPoolData$.next(filters);
      });

    // Fetch queues
    this.queueService.getQueues()
      .pipe(take(1))
      .subscribe((queues) => {
        this.queues.next([ ...queues ]);
      });
  }

  /**
   * Complete all subscriptions
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Allow for manual update of data after a pool has been edited or created
   */
  public updatePoolData(): void {
    this.allPoolsFilterService.params$
      .pipe(take(1))
      .subscribe((filters) => {
        console.log(filters);
        
        this.fetchPoolData$.next(filters);
      });
  }

  /**
   * Success handler for fetching pools
   */
  private fetchPoolsSuccess(response: GetPoolsResponse): void {
    this.allPoolsData.next(response);
    this.fetchingPools.next(false);
    this.pageStatusService.success();
  }

  /**
   * Error handler for fetching pools
   */
  private fetchPoolsError(): void {
    this.fetchingPools.next(false);
    this.pageStatusService.error();
  }
}
