import { Directive, ElementRef, HostListener } from '@angular/core';

/**
 * Directive to be applied to menus within table rows.
 * When the menu is opened or closed, the `row-menu-open` class name is added or removed.
 * Styles, defined in vue-tables.scss, highlight the active row corresponding with the open menu.
 */
@Directive({
  selector: '[vueTableMenuOpen]'
})
export class VueTableMenuOpenDirective {
  /**
   * Class to be added/removed when menu toggles.
   * Styles are defined in `vue-tables.scss`
   */
  private openClass = 'row-menu-open';

  public constructor(private menuElement: ElementRef) {}

  @HostListener('menuOpened') public addClass(): void {
    this.getRow()?.classList.add(this.openClass);
  }

  @HostListener('menuClosed') public removeClass(): void {
    this.getRow()?.classList.remove(this.openClass);
  }

  private getRow(): Element | null {
    return (this.menuElement.nativeElement as HTMLElement).closest('mat-row');
  }
}
