import { Component, Input } from '@angular/core';
import { PoolStatus } from 'api/types';
import { VueChipColor } from 'vue/components/vue-chip/vue-chip.component';

/**
 * Wraps vue-chip for pool status display
 */
@Component({
  selector: 'app-pool-status',
  templateUrl: './pool-status.component.html',
})
export class PoolStatusComponent {
  /**
   * Determines the color of the chip based on status
   */
  @Input() public status: PoolStatus = 'open'

  /**
   * Determine chip color based on pool status
   */
  public get chipColor(): VueChipColor {
    switch (this.status) {
      case 'archived':
      case 'completed':
        return 'gray';
      case 'scheduled':
        return 'blue';
      default:
        return 'green';
    }
  }
}
