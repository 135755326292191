/* eslint-disable
  @typescript-eslint/no-use-before-define,
  @typescript-eslint/no-explicit-any,
  @typescript-eslint/explicit-module-boundary-types
*/
import { Component } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from 'rxjs';
import { Day, daysOfTheWeek, daysOfTheWeekLocaleDisplay } from 'constants/days-of-the-week';

/**
 *  Displays a set of checkboxes for determining which days of the week the pool is active
 */
@Component({
  selector: 'app-pool-days-of-week',
  templateUrl: './pool-days-of-week.component.html',
  styleUrls: [ './pool-days-of-week.component.scss' ],
  providers: [ {
    provide: NG_VALUE_ACCESSOR, useExisting: PoolDaysOfWeekComponent, multi: true
  } ]
})
export class PoolDaysOfWeekComponent implements ControlValueAccessor {
  public daysOfWeek: {value: Day, display: string, checked: boolean}[] = daysOfTheWeek.map((day, i) => {
    return {
      value: day,
      display: daysOfTheWeekLocaleDisplay()[ i ],
      checked: false
    };
  });

  // Form functions
  private onChangeCallback: (_: any) => void = noop;
  private onTouchedCallback = noop;

  // Handle occurrence change
  public checkboxChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.daysOfWeek.forEach((day) => {
      if (day.value === target.value) {
        day.checked = target.checked;
      }
    });
    this.onTouchedCallback();
    this.onChangeCallback(this.getSelectedDays());
  }

  // Update value from parent
  public writeValue(selectedDays: Day[]): void {
    this.daysOfWeek = this.daysOfWeek.map((day) => {
      return {
        ...day,
        checked: selectedDays.includes(day.value)
      };
    });
  }

  // Update parent form value
  public registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  // Update parent form control has been touched
  public registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  private getSelectedDays(): Day[] {
    return this.daysOfWeek.filter((day) => day.checked).map((day) => day.value);
  }
}
