import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ClientInfo, GetPoolsParameters, PoolStatus } from 'api/types';
import { invalidCharactersRegex } from 'constants/invalid-characters-regex';
import { TranslatePipe } from 'pipes/translate.pipe';
import { AllPoolsFiltersService, GetPoolFilterChip, GetPoolsFilterChipType } from 'services/all-pools-filters.service';
import { MultiSelectOption } from '../filter-multi-select/filter-multi-select.component';

/**
 * List of multi-select options
 * Display values are defined by translate service
 */
export const initialStatusOptions: MultiSelectOption[] = [
  { display: '', value: 'completed', checked: false },
  { display: '', value: 'archived', checked: false },
  { display: '', value: 'open', checked: false },
  { display: '', value: 'scheduled', checked: false },
];

/**
 *  Filters for the all pools page
 */
@Component({
  selector: 'app-all-pools-filters',
  templateUrl: './all-pools-filters.component.html',
  styleUrls: [ './all-pools-filters.component.scss' ],
})

export class AllPoolsFiltersComponent implements OnInit, OnDestroy {
  /**
   * Initial search term
   */
  public initialSearchTerm = '';

  /**
   * Array of status options passed to multi select component
   */
  public statusOptions: MultiSelectOption[] = initialStatusOptions;

  /**
   * Array of filter objects that represent what is shown as dismissible chips
   */
  public selectedFilters: GetPoolFilterChip[] = [];

  /**
   * Characters blacklisted from search field
   */
  public invalidRegex = invalidCharactersRegex;

  private destroyed$ = new Subject();

  public constructor(
    private allPoolsFilterService: AllPoolsFiltersService,
    private translatePipe: TranslatePipe,
  ) {
    this.translatePipe.loadTranslations([
      'label.completed',
      'label.archived',
      'label.open',
      'label.scheduled'
    ])
      .pipe(take(1))
      .subscribe((translations) => {
        this.statusOptions = this.statusOptions.map((status) => {
          return {
            ...status,
            display: translations[ `label.${status.value}` ]
          };
        });
      });
  }

  public ngOnInit(): void {
    this.allPoolsFilterService.params$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((filters) => {
        this.initialSearchTerm = filters.searchTerm || '';

        if (filters.status) {
          // Update statuses based on service params
          this.updateStatuses(filters.status);
        }
      });

    this.allPoolsFilterService.selectedFilters$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((selectedFilters) => {
        this.selectedFilters = [ ...selectedFilters ];
      });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Handles when user clicks "apply" on status filter
   *
   * @param selectedStatuses array of selected statuses
   */
  public statusSelectionUpdated(selectedStatuses: MultiSelectOption[]): void {
    // Get values of selectedStatuses then filter based on accepted status types
    const selectedStatus = selectedStatuses
      .map((status) => status.value)
      .filter((status): status is PoolStatus => this.isValidStatus(status));

    // Updated filters
    this.allPoolsFilterService.updateParams({ status: selectedStatus });
  }

  /**
   * Handles when user clicks "apply" on client filter
   *
   * @param selectedClients array of selected clients
   */
  public clientSelectionUpdated(selectedClients: ClientInfo[]): void {
    
    this.allPoolsFilterService.addClientsToFilter(selectedClients);
  }

  /**
   * Callback to wire between the FilterByComponent and AllPoolsFiltersService
   *
   * @param removedFilter filter to remove
   */
  public filterRemoved(removedFilter: GetPoolsFilterChipType): void {
    switch (removedFilter.filterKey) {
      case 'status':
        this.allPoolsFilterService.removeStatus(removedFilter.value as PoolStatus);
        break;
      case 'clientIds':
        this.allPoolsFilterService.removeClient(removedFilter.value as string);
        break;
      case 'examIds':
        this.allPoolsFilterService.removeExam(removedFilter.value as string);
        break;
      case 'queueIds':
        this.allPoolsFilterService.removeQueue(removedFilter.value as string);
        break;
      case 'after':
      case 'before':
        this.allPoolsFilterService.removeDate(removedFilter.filterKey);
        break;
    }
  }

  /**
   * Updates search term
   *
   * @param inputValue value from search input
   */
  public updateSearchParams(inputValue: string): void {
    const newParams: Partial<GetPoolsParameters> = {
      searchTerm: inputValue,
    };
    this.allPoolsFilterService.updateParams(newParams);
  }

  /**
   * Update local status object to match params
   * Passed to FilterMultiSelectComponent
   *
   * @param statuses updated statuses that are selected
   */
  private updateStatuses(statuses: PoolStatus[]): void {
    this.statusOptions = this.statusOptions.map((statusOption) => {
      const isSelected = (statuses as string[]).includes(statusOption.value);

      return {
        ...statusOption,
        checked: isSelected
      };
    });
  }

  /**
   * Makes typescript happy with string to PoolStatus conversion and won't allow
   * non-acceptable status to be passed to the backend
   *
   * @param status status to validate against
   * @returns true if status is valid
   */
  private isValidStatus(status: string): status is PoolStatus {
    return [ 'completed', 'archived', 'open', 'scheduled' ].includes(status);
  }
}
