<vue-table-container [loading]="loading">
  <table mat-table [dataSource]="tableData">
    <caption class="visually-hidden">
      {{ 'table.caption.allPools' | translate | async }}
    </caption>

    <!-- Pool Name -->
    <ng-container matColumnDef="name">
      <th
        mat-header-cell
        *matHeaderCellDef
        [tableHeaderAriaSort]="{sortDirection, isSorted: sortBy === 'name' }"
      >
        <app-sortable-table-header
          [tableHasData]="!!tableData.length"
          (sortColumn)="sort('name')"
        >
          {{ 'title.poolName' | translate | async }}
        </app-sortable-table-header>
      </th>
      <mat-cell *matCellDef="let pool">
        <span class="ellipsis" [matTooltip]="pool.name">
          {{pool.name}}
        </span>
      </mat-cell>
    </ng-container>

    <!-- Pool Status -->
    <ng-container matColumnDef="status">
      <th
        mat-header-cell
        *matHeaderCellDef
        [tableHeaderAriaSort]="{sortDirection, isSorted: sortBy === 'status' }"
      >
        <app-sortable-table-header
          [tableHasData]="!!tableData.length"
          (sortColumn)="sort('status')"
        >
          {{ 'title.status' | translate | async }}
        </app-sortable-table-header>
      </th>
      <mat-cell *matCellDef="let pool">
        <app-pool-status
          [status]="pool.status"
        ></app-pool-status>
      </mat-cell>
    </ng-container>

    <!-- Pool Client -->
    <ng-container matColumnDef="client">
      <th mat-header-cell *matHeaderCellDef>
        {{ "title.client" | translate | async }}
      </th>
      <mat-cell *matCellDef="let pool">
        {{pool | poolClientDisplay}}
      </mat-cell>
    </ng-container>

    <!-- Pool Exams -->
    <ng-container matColumnDef="exams">
      <th mat-header-cell *matHeaderCellDef>
        {{ "title.exams" | translate | async }}
      </th>
      <mat-cell *matCellDef="let pool">
        <span class="ellipsis">
          {{pool | poolExamDisplay}}
        </span>
      </mat-cell>
    </ng-container>

    <!-- Pool Queue -->
    <ng-container matColumnDef="queue">
      <th
        mat-header-cell
        *matHeaderCellDef
        [tableHeaderAriaSort]="{sortDirection, isSorted: sortBy === 'queue' }"
      >
        <app-sortable-table-header
          [tableHasData]="!!tableData.length"
          (sortColumn)="sort('queue')"
        >
          {{ 'title.queue' | translate | async }}
        </app-sortable-table-header>
      </th>
      <mat-cell *matCellDef="let pool">
        <span class="ellipsis" [matTooltip]="getQueueDisplay(pool)">
          {{ getQueueDisplay(pool) }}
        </span>
      </mat-cell>
    </ng-container>

    <!-- Pool Start Date -->
    <ng-container matColumnDef="start-date">
      <th
        mat-header-cell
        *matHeaderCellDef
        [tableHeaderAriaSort]="{sortDirection, isSorted: sortBy === 'start-date' }"
      >
        <app-sortable-table-header
          [tableHasData]="!!tableData.length"
          (sortColumn)="sort('start-date')"
        >
          {{ 'title.startDate' | translate | async }}
        </app-sortable-table-header>
      </th>
      <mat-cell *matCellDef="let pool"> {{ formatPoolDate(pool.startDate) }} </mat-cell>
    </ng-container>

    <!-- Pool End Date -->
    <ng-container matColumnDef="end-date">
      <th
        mat-header-cell
        *matHeaderCellDef
        [tableHeaderAriaSort]="{sortDirection, isSorted: sortBy === 'end-date' }"
      >
        <app-sortable-table-header
          [tableHasData]="!!tableData.length"
          (sortColumn)="sort('end-date')"
        >
          {{ 'title.endDate' | translate | async }}
        </app-sortable-table-header>
      </th>
      <mat-cell *matCellDef="let pool"> {{ formatPoolDate(pool.endDate) }} </mat-cell>
    </ng-container>

    <!-- Pool menu -->
    <ng-container matColumnDef="menu">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'table.heading.actions' | translate | async }}
      </th>
      <mat-cell *matCellDef="let pool">
        <app-all-pools-menu
          [pool]="pool"
        ></app-all-pools-menu>
      </mat-cell>
    </ng-container>

    <!-- Populate table with data -->
    <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: tableColumns"
    ></mat-row>

    <!-- No pools available -->
    <tr class="mat-row no-content" *matNoDataRow>
      <td class="mat-cell" colspan="9999">
        {{ 'message.allPools.noPools' | translate | async }}
      </td>
    </tr>
  </table>

  <mat-paginator
    *ngIf="tableData.length !== 0"
    previousPageIndex="0"
    (page)="paginatorChange($event)"
    [length]="totalItemCount"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    [showFirstLastButtons]="showFirstLastButtons"
  ></mat-paginator>
</vue-table-container>
