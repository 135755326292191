import { A11yModule } from '@angular/cdk/a11y';
import { PortalModule } from '@angular/cdk/portal';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { TitleCasePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgChartsModule } from 'ng2-charts';
import { NgxMaskModule } from 'ngx-mask';

import { AppointmentsChartDailyComponent } from 'components/charts/appointments/appointments-chart-daily/appointments-chart-daily.component';
import { AppointmentsChartMonthlyComponent } from 'components/charts/appointments/appointments-chart-monthly/appointments-chart-monthly.component';
import { CalendarItemComponent } from 'components/charts/appointments/appointments-chart-monthly/calendar-item/calendar-item.component';
import { AppointmentsChartWeeklyComponent } from 'components/charts/appointments/appointments-chart-weekly/appointments-chart-weekly.component';
import { ChartCanvasWrapperComponent } from 'components/charts/chart-canvas-wrapper/chart-canvas-wrapper.component';
import { ChartLegendComponent } from 'components/charts/chart-legend/chart-legend.component';
import { ChartWithCustomYAxisComponent } from 'components/charts/chart-with-custom-y-axis/chart-with-custom-y-axis.component';
import { CustomBottomXAxisLabelsComponent } from 'components/charts/custom-bottom-x-axis-labels/custom-bottom-x-axis-labels.component';
import { CustomXAxisComponent } from 'components/charts/custom-x-axis/custom-x-axis.component';
import { CustomYAxisComponent } from 'components/charts/custom-y-axis/custom-y-axis.component';
import { LegendMarkerComponent } from 'components/charts/legend-marker/legend-marker.component';
import { PoolsChartComponent } from 'components/charts/pools-chart/pools-chart.component';
import { AutoCompleteLoaderComponent } from 'components/common/auto-complete-loader/auto-complete-loader.component';
import { AutoCompleteComponent } from 'components/common/auto-complete/auto-complete.component';
import { DismissibleChipComponent } from 'components/common/dismissible-chip/dismissible-chip.component';
import { ErrorMessageComponent } from 'components/common/errors/error-message/error-message.component';
import { NetworkErrorComponent } from 'components/common/errors/network-error/network-error.component';
import { ServerErrorUnsavedComponent } from 'components/common/errors/server-error-unsaved/server-error-unsaved.component';
import { NavBarComponent } from 'components/common/nav-bar/nav-bar.component';
import { PageBodyComponent } from 'components/common/page-body/page-body.component';
import { EditOccurrenceDialogComponent } from 'components/common/pools/edit-occurrence-dialog/edit-occurrence-dialog.component';
import { EditPoolToastComponent } from 'components/common/pools/edit-pool-toast/edit-pool-toast.component';
import { OccurrenceMenuComponent } from 'components/common/pools/occurrence-menu/occurrence-menu.component';
import { PoolReleaseComponent } from 'components/common/pools/pool-release/pool-release.component';
import { PoolStatusComponent } from 'components/common/pools/pool-status/pool-status.component';
import { RemoveOccurrenceComponent } from 'components/common/pools/remove-occurrence/remove-occurrence.component';
import { PrivacyBannerComponent } from 'components/common/privacy-banner/privacy-banner.component';
import { SynchedScrollButtonComponent } from 'components/common/synched-scroll-button/synched-scroll-button.component';
import { TimezoneSelectComponent } from 'components/common/timezone-select/timezone-select.component';
import { AuthErrorDialogContentComponent } from 'components/dialogs/auth-error-dialog-content/auth-error-dialog-content.component';
import { CandidatesDownloadDialogComponent } from 'components/dialogs/candidates-download-dialog/candidates-download-dialog.component';
import { InactivityDialogComponent } from 'components/dialogs/inactivity-dialog/inactivity-dialog.component';
import { LoadingDialogComponent } from 'components/dialogs/loading-dialog/loading-dialog.component';
import { UnsavedChangesComponent } from 'components/dialogs/unsaved-changes/unsaved-changes.component';
import { AddPoolDrawerComponent } from 'components/drawers/add-pool-drawer/add-pool-drawer.component';
import { AddPoolFormComponent } from 'components/drawers/add-pool-drawer/add-pool-form/add-pool-form.component';
import { PoolDaysOfWeekComponent } from 'components/drawers/add-pool-drawer/pool-days-of-week/pool-days-of-week.component';
import { PoolRestrictionComponent } from 'components/drawers/add-pool-drawer/pool-restriction/pool-restriction.component';
import { PoolScheduleComponent } from 'components/drawers/add-pool-drawer/pool-schedule/pool-schedule.component';
import { AddTemplateDrawerComponent } from 'components/drawers/add-template-drawer/add-template-drawer.component';
import { AddTemplateFormComponent } from 'components/drawers/add-template-drawer/add-template-form/add-template-form.component';
import { CopyAllocationsComponent } from 'components/drawers/add-template-drawer/copy-allocations/copy-allocations.component';
import { DailyAllocationsFormComponent } from 'components/drawers/add-template-drawer/daily-allocations-form/daily-allocations-form.component';
import { PercentTotalComponent } from 'components/drawers/add-template-drawer/percent-total/percent-total.component';
import { DrawerHeaderComponent } from 'components/drawers/drawer-header/drawer-header.component';
import { ArchivePoolComponent } from 'components/drawers/edit-pool-drawer/archive-pool/archive-pool.component';
import { EditPoolDrawerComponent } from 'components/drawers/edit-pool-drawer/edit-pool-drawer.component';
import { EditPoolFormComponent } from 'components/drawers/edit-pool-drawer/edit-pool-form/edit-pool-form.component';
import { ReleaseAllOccurrencesComponent } from 'components/drawers/edit-pool-drawer/release-all-occurrences/release-all-occurrences.component';
import { ApplyCapacityTemplateFormComponent } from 'components/drawers/set-capacity-drawer/apply-capacity-template-form/apply-capacity-template-form.component';
import { HourlyAllocationDialogComponent } from 'components/drawers/set-capacity-drawer/apply-capacity-template-form/hourly-allocation-dialog/hourly-allocation-dialog.component';
import { TemplateOptionsPaneComponent } from 'components/drawers/set-capacity-drawer/apply-capacity-template-form/template-select-searchable/template-options-pane/template-options-pane.component';
import { TemplateSelectSearchableComponent } from 'components/drawers/set-capacity-drawer/apply-capacity-template-form/template-select-searchable/template-select-searchable.component';
import { BulkCapacityEditComponent } from 'components/drawers/set-capacity-drawer/bulk-edit-form/bulk-capacity-edit/bulk-capacity-edit.component';
import { BulkEditFormComponent } from 'components/drawers/set-capacity-drawer/bulk-edit-form/bulk-edit-form.component';
import { DailyTimeExceptionsComponent } from 'components/drawers/set-capacity-drawer/bulk-edit-form/daily-time-exceptions/daily-time-exceptions.component';
import { CapacityFileUploadComponent } from 'components/drawers/set-capacity-drawer/capacity-file-upload/capacity-file-upload.component';
import { SetCapacityDrawerComponent } from 'components/drawers/set-capacity-drawer/set-capacity-drawer.component';
import { AllPoolsFiltersComponent } from 'components/filters/all-pools-filters/all-pools-filters.component';
import { ClientSelectFilterPaneComponent } from 'components/filters/all-pools-filters/client-select-filter/client-select-filter-pane/client-select-filter-pane.component';
import { ClientSelectFilterComponent } from 'components/filters/all-pools-filters/client-select-filter/client-select-filter.component';
import { DateRangeFilterComponent } from 'components/filters/all-pools-filters/date-range-filter/date-range-filter.component';
import { ExamsFilterComponent } from 'components/filters/all-pools-filters/exams-filter/exams-filter.component';
import { MoreFiltersPaneComponent } from 'components/filters/all-pools-filters/more-filters/more-filters-pane/more-filters-pane.component';
import { MoreFiltersComponent } from 'components/filters/all-pools-filters/more-filters/more-filters.component';
import { QueueFilterComponent } from 'components/filters/all-pools-filters/queue-filter/queue-filter.component';
import { AppointmentsFiltersComponent } from 'components/filters/appointments-filters/appointments-filters.component';
import { AppointmentsRecentActivityFiltersComponent } from 'components/filters/appointments-recent-activity-filters/appointments-recent-activity-filters.component';
import { ClientsFiltersComponent } from 'components/filters/clients-filters/clients-filters.component';
import { DailyDatepickerComponent } from 'components/filters/date-selection-filter/daily-datepicker/daily-datepicker.component';
import { DateSelectionFilterComponent } from 'components/filters/date-selection-filter/date-selection-filter.component';
import { DateSelectionLabelComponent } from 'components/filters/date-selection-filter/date-selection-label/date-selection-label.component';
import { MonthlyDatepickerHeaderComponent } from 'components/filters/date-selection-filter/monthly-datepicker/monthly-datepicker-header/monthly-datepicker-header.component';
import { MonthlyDatepickerComponent } from 'components/filters/date-selection-filter/monthly-datepicker/monthly-datepicker.component';
import { WeeklyDatepickerComponent } from 'components/filters/date-selection-filter/weekly-datepicker/weekly-datepicker.component';
import { FilterByComponent } from 'components/filters/filter-by/filter-by.component';
import { FilterContainerComponent } from 'components/filters/filter-container/filter-container.component';
import { FilterMultiSelectComponent } from 'components/filters/filter-multi-select/filter-multi-select.component';
import { OptionsPaneComponent } from 'components/filters/filter-multi-select/options-pane/options-pane.component';
import { PoolsFiltersComponent } from 'components/filters/pools-filters/pools-filters.component';
import { PoolsRecentActivityFiltersComponent } from 'components/filters/pools-recent-activity-filters/pools-recent-activity-filters.component';
import { TemplateFiltersComponent } from 'components/filters/template-filters/template-filters.component';
import { AllPoolsPageComponent } from 'components/pages/all-pools-page/all-pools-page.component';
import { AppointmentsPageComponent } from 'components/pages/appointments-page/appointments-page.component';
import { CapacityUnsavedBannerComponent } from 'components/pages/appointments-page/capacity-unsaved-banner/capacity-unsaved-banner.component';
import { AppointmentsRecentActivityPageComponent } from 'components/pages/appointments-recent-activity-page/appointments-recent-activity-page.component';
import { ClientsPageComponent } from 'components/pages/clients-page/clients-page.component';
import { DateLocalizationTest } from 'components/pages/date-localization-test/date-localization-test.component';
import { PageNotFoundComponent } from 'components/pages/page-not-found/page-not-found.component';
import { PoolDetailsPageComponent } from 'components/pages/pool-details-page/pool-details-page.component';
import { PoolsPageComponent } from 'components/pages/pools-page/pools-page.component';
import { PoolsRecentActivityPageComponent } from 'components/pages/pools-recent-activity-page/pools-recent-activity-page.component';
import { TemplatesPageComponent } from 'components/pages/templates-page/templates-page.component';
import { ApptDetailChartPopoverComponent } from 'components/popovers/appt-detail-chart-popover/appt-detail-chart-popover.component';
import { ApptMonthlyChartPopoverComponent } from 'components/popovers/appt-monthly-chart-popover/appt-monthly-chart-popover.component';
import { ApptSixHourChartPopoverComponent } from 'components/popovers/appt-six-hour-chart-popover/appt-six-hour-chart-popover.component';
import { ApptWeeklyPopoverActionsComponent } from 'components/popovers/appt-weekly-popover-actions/appt-weekly-popover-actions.component';
import { ApptWeeklySummaryPopoverComponent } from 'components/popovers/appt-weekly-summary-popover/appt-weekly-summary-popover.component';
import { BaseChartPopoverComponent } from 'components/popovers/base-chart-popover/base-chart-popover.component';
import { ChartElementsDetailsComponent } from 'components/popovers/chart-elements-details/chart-elements-details.component';
import { PoolsChartPopoverComponent } from 'components/popovers/pools-chart-popover/pools-chart-popover.component';
import { AllPoolsMenuComponent } from 'components/tables/all-pools-table/all-pools-menu/all-pools-menu.component';
import { AllPoolsTableComponent } from 'components/tables/all-pools-table/all-pools-table.component';
import { AppointmentsRecentActivityTableComponent } from 'components/tables/appointments-recent-activity-table/appointments-recent-activity-table.component';
import { AppointmentsTableComponent } from 'components/tables/appointments-table/appointments-table.component';
import { ClientTableComponent } from 'components/tables/client-table/client-table.component';
import { HourlyAllocationTableComponent } from 'components/tables/hourly-allocation-table/hourly-allocation-table.component';
import { OccurrenceTableComponent } from 'components/tables/occurrence-table/occurrence-table.component';
import { PoolOccurrenceRestrictionComponent } from 'components/tables/occurrence-table/pool-occurrence-restriction/pool-occurrence-restriction.component';
import { OpenPoolsMenuComponent } from 'components/tables/open-pools-table/open-pools-menu/open-pools-menu.component';
import { OpenPoolsTableComponent } from 'components/tables/open-pools-table/open-pools-table.component';
import { PoolDetailOccurrenceTableComponent } from 'components/tables/pool-detail-occurrence-table/pool-detail-occurrence-table.component';
import { PoolDrawerOccurrenceMenuComponent } from 'components/tables/pool-drawer-occurrence-table/pool-drawer-occurrence-menu/pool-drawer-occurrence-menu.component';
import { PoolDrawerOccurrenceTableComponent } from 'components/tables/pool-drawer-occurrence-table/pool-drawer-occurrence-table.component';
import { PoolsRecentActivityTableComponent } from 'components/tables/pools-recent-activity-table/pools-recent-activity-table.component';
import { TemplateMenuComponent } from 'components/tables/templates-table/template-menu/template-menu.component';
import { TemplatesTableComponent } from 'components/tables/templates-table/templates-table.component';
import { SortableTableHeaderComponent } from 'components/tables/utils/sortable-table-header/sortable-table-header.component';
import { TableHeaderCellComponent } from 'components/tables/utils/table-header-cell/table-header-cell.component';
import { BackgroundFadeDirective } from 'directives/background-fade/background-fade.directive';
import { DatepickerWeekRangeDirective } from 'directives/datepicker-week-range/datepicker-week-range.directive';
import { DragAndDropDirective } from 'directives/drag-and-drop/drag-and-drop.directive';
import { TableHeaderAriaSortDirective } from 'directives/table-header-aria-sort/table-header-aria-sort.directive';
import { AddUTCToTimePipe } from 'pipes/add-utc-to-time.pipe';
import { BoldMatchingTextPipe } from 'pipes/bold-matching-text.pipe';
import { DatepickerErrorPipe } from 'pipes/datepicker-error.pipe';
import { PoolClientDisplayPipe } from 'pipes/pool-client-display.pipe';
import { PoolExamDisplayPipe } from 'pipes/pool-exam-display.pipe';
import { PoolOccurrenceDisplayPipe } from 'pipes/pool-occurrence-display.pipe';
import { TimezoneDisplayPipe } from 'pipes/timezone-display.pipe';
import { TranslatePipe } from 'pipes/translate.pipe';
import { CapacityMicroservice, capacityMicroserviceCreator } from 'services/api/microservices/capacity.microservice';
import { PoolsMicroservice, poolsMicroserviceCreator } from 'services/api/microservices/pools.microservice';
import { AppConfigService } from 'services/app-config.service';
import { AuthInterceptor } from 'services/authentication/auth.interceptor';
import { MatPaginatorLocalizationService } from 'services/mat-paginator-localization.service';
import { VueOverlayOpenDirective } from 'vue/directives/vue-overlay-open/vue-overlay-open.directive';
import { VUE_DATE_FORMATS, VueDateAdapter } from 'vue/utilities/vue-datepicker-common/vue-datepicker-adapter';
import { VueModule } from 'vue/vue.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PoolDialogComponent } from './components/dialogs/pool-dialog/pool-dialog.component';
import { PoolDaysOfWeekWithRestrictionComponent } from './components/drawers/add-pool-drawer/pool-days-of-week-with-restriction/pool-days-of-week-with-restriction.component';
import { CapacityFileTableComponent } from './components/drawers/set-capacity-drawer/capacity-file-upload/capacity-file-table/capacity-file-table.component';
import { OutagesPageComponent } from 'components/pages/outages-page/outages-page.component';
import { OutagesFiltersComponent } from 'components/filters/outages-filters/outages-filters.component';
import { YearlyDatepickerHeaderComponent } from 'components/filters/date-selection-filter/yearly-datepicker/yearly-datepicker-header/yearly-datepicker-header.component';
import { YearlyDatepickerComponent } from 'components/filters/date-selection-filter/yearly-datepicker/yearly-datepicker.component';
import { OutagesTableComponent } from 'components/tables/outages-table/outages-table.component';
import { DateToDayPipe } from './pipes/date-to-day.pipe';
import { OutageMenuComponent } from './components/tables/outages-table/outage-menu/outage-menu.component';
import { AddOutagesPageComponent } from 'components/pages/add-outages-page/add-outages-page.component';
import { EditOutagesPageComponent } from 'components/pages/edit-outages-page/edit-outages-page.component';
import { RemoveOutageComponent } from 'components/common/outage/remove-outage/remove-outage.component';
import { RemoveTemplateComponent } from 'components/common/template/remove-template/remove-template.component';
import { ExamsListComponent } from 'components/tables/client-table/exams-list/exams-list.component';
import { AlertsPageComponent } from 'components/pages/alerts-page/alerts-page.component';
import { AlertsTableComponent } from 'components/tables/alerts-table/alerts-table.component';
import { AlertsFiltersComponent } from 'components/filters/alerts-filters/alerts-filters.component';
import { PoolConsumptionPageComponent } from 'components/pages/poolconsumption-page/poolconsumption-page.component';
import { PoolConsumptionFiltersComponent } from 'components/filters/poolconsumption-filters/poolconsumption-filters.component';
import { PoolConsumptionTableComponent } from 'components/tables/poolconsumption-table/poolconsumption-table.component';
import { PoolConsumptionMenuComponent } from 'components/tables/poolconsumption-table/pool-consumption-menu/pool-consumption-menu.component';
import { ConfirmationModalComponent } from 'components/common/poolconsumption/confirmation-modal/confirmation-modal.component';
import { PoolConsumptionDetailsPageComponent } from 'components/pages/poolconsumption-details-page/poolconsumption-details-page.component';
import { PoolConsumptionStatusComponent } from 'components/common/poolconsumption/poolconsumption-status/poolconsumption-status.component';
import { MatTabsModule } from '@angular/material/tabs';
import { AlertDetailsPageComponent } from 'components/pages/alert-details-page/alert-details-page.component';
import { CopyModalComponent } from 'components/common/poolconsumption/copy-modal/copy-modal.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatRadioModule } from '@angular/material/radio';
import { MatDividerModule } from '@angular/material/divider';
import { AddAlertsConsumptionTableComponent } from './components/tables/add-alerts-consumption-table/add-alerts-consumption-table.component';
import { AlertConsumptionTableFilterComponent } from './components/filters/alert-consumption-table-filter/alert-consumption-table-filter.component';
import { AlertSelectfilterComponent } from './components/filters/alert-consumption-table-filter/alert-selectfilter/alert-selectfilter.component';
import { AlertSelectPaneComponent } from './components/filters/alert-consumption-table-filter/alert-selectfilter/alert-select-pane/alert-select-pane.component';
import { AlertMoreFiltersComponent } from './components/filters/alert-consumption-table-filter/alert-more-filters/alert-more-filters.component';
import { MoreFilterPaneComponent } from './components/filters/alert-consumption-table-filter/alert-more-filters/more-filter-pane/more-filter-pane.component';
import { AddPoolConsumptionDrawerComponent } from './components/drawers/add-pool-consumption-drawer/add-pool-consumption-drawer.component';
import { AddPoolConsumptionDialogboxComponent } from './components/drawers/add-pool-consumption-drawer/add-pool-consumption-dialogbox/add-pool-consumption-dialogbox.component';
import { AddPoolConsumptionFormComponent } from './components/drawers/add-pool-consumption-drawer/add-pool-consumption-form/add-pool-consumption-form.component';
import { AddPoolConsumptionRunfrequencyComponent } from './components/drawers/add-pool-consumption-drawer/add-pool-consumption-runfrequency/add-pool-consumption-runfrequency.component';
import { AddPoolConsumptionSearchpoolComponent } from './components/drawers/add-pool-consumption-drawer/add-pool-consumption-searchpool/add-pool-consumption-searchpool.component';
import { AddPoolConsumptionThresholdComponent } from './components/drawers/add-pool-consumption-drawer/add-pool-consumption-threshold/add-pool-consumption-threshold.component';
import { AddPCFilterContainerComponent } from './components/filters/add-pc-filter-container/add-pc-filter-container.component';
import { EditPoolConsumptionDrawerComponent } from './components/drawers/edit-pool-consumption-drawer/edit-pool-consumption-drawer.component';
import { EditPoolConsumptionFormComponent } from './components/drawers/edit-pool-consumption-drawer/edit-pool-consumption-form/edit-pool-consumption-form.component';
import { CopyPoolConsumptionDrawerComponent } from './components/drawers/copy-pool-consumption-drawer/copy-pool-consumption-drawer.component';
import { CopyPoolConsumptionFormComponent } from './components/drawers/copy-pool-consumption-drawer/copy-pool-consumption-form/copy-pool-consumption-form.component';
import { ChangePoolNameComponent } from 'components/common/pools/change-poolname/change-poolname.component';
import { AlertsResolvePageComponent } from './components/pages/alerts-resolve-page/alerts-resolve-page.component';
import { AppAlertResolveChartMonthlyComponent } from './components/charts/alertResolve/alertResolve-chart-monthly/app-alert-resolve-chart-monthly/app-alert-resolve-chart-monthly.component';
import { AlertResolveCalendarItemComponent } from './components/charts/alertResolve/alertResolve-chart-monthly/app-alert-resolve-chart-monthly/alert-resolve-calendar-item/alert-resolve-calendar-item.component';
import { AlertResolveFiltersComponent } from './components/filters/alert-resolve-filters/alert-resolve-filters.component';
import { AlertEditPcToastComponent } from './components/common/alerts/alert-edit-pc-toast/alert-edit-pc-toast.component';
import { AlertResolveComponent } from './components/common/poolconsumption/alert-resolve/alert-resolve.component';
import { InformationDialogComponent } from 'components/dialogs/information-dialog/information-dialog.component';
import { AlertConsumptionExamFilterComponent } from './components/filters/alert-consumption-table-filter/alert-consumption-exam-filter/alert-consumption-exam-filter.component';
import { ConfirmationDialogComponent } from './components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { AlertCalendarpoupComponent } from './components/dialogs/alert-calendarpoup/alert-calendarpoup.component';
import { AlertCommentsModalComponent } from 'components/common/alerts/alert-comments-modal/alert-comments-modal.component';
/**
*  App module
*/
@NgModule({
  bootstrap: [AppComponent],
  declarations: [

    // Components
    AddPoolDrawerComponent,
    AddPoolFormComponent,
    AddTemplateDrawerComponent,
    AddTemplateFormComponent,
    AllPoolsFiltersComponent,
    AllPoolsMenuComponent,
    AllPoolsPageComponent,
    AllPoolsTableComponent,
    AppComponent,
    ApplyCapacityTemplateFormComponent,
    AppointmentsChartDailyComponent,
    AppointmentsChartMonthlyComponent,
    AppointmentsChartWeeklyComponent,
    AppointmentsFiltersComponent,
    AppointmentsPageComponent,
    AppointmentsRecentActivityFiltersComponent,
    AppointmentsRecentActivityPageComponent,
    AppointmentsRecentActivityTableComponent,
    AppointmentsTableComponent,
    ApptDetailChartPopoverComponent,
    ApptMonthlyChartPopoverComponent,
    ApptSixHourChartPopoverComponent,
    ApptWeeklyPopoverActionsComponent,
    ApptWeeklySummaryPopoverComponent,
    ArchivePoolComponent,
    AuthErrorDialogContentComponent,
    AutoCompleteComponent,
    AutoCompleteLoaderComponent,
    BaseChartPopoverComponent,
    BulkCapacityEditComponent,
    BulkEditFormComponent,
    CalendarItemComponent,
    CandidatesDownloadDialogComponent,
    CapacityFileUploadComponent,
    CapacityUnsavedBannerComponent,
    ChartCanvasWrapperComponent,
    ChartCanvasWrapperComponent,
    ChartElementsDetailsComponent,
    ChartLegendComponent,
    ChartWithCustomYAxisComponent,
    ClientSelectFilterComponent,
    ClientSelectFilterPaneComponent,
    ClientTableComponent,
    ClientsFiltersComponent,
    ClientsPageComponent,
    CopyAllocationsComponent,
    CustomBottomXAxisLabelsComponent,
    CustomXAxisComponent,
    CustomYAxisComponent,
    DailyAllocationsFormComponent,
    DailyDatepickerComponent,
    DailyTimeExceptionsComponent,
    DateLocalizationTest,
    DateRangeFilterComponent,
    DateSelectionFilterComponent,
    DateSelectionLabelComponent,
    DismissibleChipComponent,
    DrawerHeaderComponent,
    EditOccurrenceDialogComponent,
    EditPoolDrawerComponent,
    EditPoolFormComponent,
    EditPoolToastComponent,
    ErrorMessageComponent,
    ExamsFilterComponent,
    FilterByComponent,
    FilterContainerComponent,
    FilterMultiSelectComponent,
    HourlyAllocationDialogComponent,
    HourlyAllocationTableComponent,
    InactivityDialogComponent,
    LegendMarkerComponent,
    LoadingDialogComponent,
    MonthlyDatepickerComponent,
    MonthlyDatepickerHeaderComponent,
    MoreFiltersComponent,
    MoreFiltersPaneComponent,
    NavBarComponent,
    NetworkErrorComponent,
    OccurrenceMenuComponent,
    OccurrenceTableComponent,
    OpenPoolsMenuComponent,
    OpenPoolsTableComponent,
    OptionsPaneComponent,
    PageBodyComponent,
    PageNotFoundComponent,
    PercentTotalComponent,
    PoolDaysOfWeekComponent,
    PoolDetailOccurrenceTableComponent,
    PoolDetailsPageComponent,
    PoolDrawerOccurrenceMenuComponent,
    PoolDrawerOccurrenceTableComponent,
    PoolReleaseComponent,
    PoolRestrictionComponent,
    PoolScheduleComponent,
    PoolScheduleComponent,
    PoolStatusComponent,
    PoolsChartComponent,
    PoolsChartPopoverComponent,
    PoolsFiltersComponent,
    PoolOccurrenceRestrictionComponent,
    PoolsPageComponent,
    PoolsRecentActivityFiltersComponent,
    PoolsRecentActivityPageComponent,
    PoolsRecentActivityTableComponent,
    PrivacyBannerComponent,
    QueueFilterComponent,
    ReleaseAllOccurrencesComponent,
    RemoveOccurrenceComponent,
    RemoveOccurrenceComponent,
    ServerErrorUnsavedComponent,
    SetCapacityDrawerComponent,
    SortableTableHeaderComponent,
    SynchedScrollButtonComponent,
    TableHeaderCellComponent,
    TableHeaderCellComponent,
    TemplateFiltersComponent,
    TemplateMenuComponent,
    TemplateOptionsPaneComponent,
    TemplateSelectSearchableComponent,
    TemplatesPageComponent,
    TemplatesTableComponent,
    TimezoneSelectComponent,
    UnsavedChangesComponent,
    WeeklyDatepickerComponent,
    OutagesPageComponent,
    OutagesFiltersComponent,
    YearlyDatepickerHeaderComponent,
    YearlyDatepickerComponent,
    OutagesTableComponent,
    OutageMenuComponent,
    AddOutagesPageComponent,
    EditOutagesPageComponent,
    RemoveOutageComponent,
    RemoveTemplateComponent,
    ExamsListComponent,
    AlertsPageComponent,
    AlertsTableComponent,
    AlertsFiltersComponent,
    PoolConsumptionPageComponent,
    PoolConsumptionFiltersComponent,
    PoolConsumptionTableComponent,
    PoolConsumptionMenuComponent,
    ConfirmationModalComponent,
    PoolConsumptionDetailsPageComponent,
    PoolConsumptionStatusComponent,
    AlertDetailsPageComponent,
    CopyModalComponent,
    ChangePoolNameComponent,
    InformationDialogComponent,
    AlertCommentsModalComponent,

    // Directives
    BackgroundFadeDirective,
    DatepickerWeekRangeDirective,
    DragAndDropDirective,
    TableHeaderAriaSortDirective,

    // Pipes
    AddUTCToTimePipe,
    BoldMatchingTextPipe,
    DatepickerErrorPipe,
    PoolClientDisplayPipe,
    PoolExamDisplayPipe,
    PoolOccurrenceDisplayPipe,
    TimezoneDisplayPipe,
    TranslatePipe,
    PoolDialogComponent,
    PoolDaysOfWeekWithRestrictionComponent,
    CapacityFileTableComponent,
    DateToDayPipe,

    AddAlertsConsumptionTableComponent,
    AlertConsumptionTableFilterComponent,
    AlertSelectfilterComponent,
    AlertSelectPaneComponent,
    AlertMoreFiltersComponent,
    MoreFilterPaneComponent,

    AddPoolConsumptionDrawerComponent,
    AddPoolConsumptionDialogboxComponent,
    AddPoolConsumptionFormComponent,
    AddPoolConsumptionRunfrequencyComponent,
    AddPoolConsumptionSearchpoolComponent,
    AddPoolConsumptionThresholdComponent,
    AddPCFilterContainerComponent,
    EditPoolConsumptionDrawerComponent,
    EditPoolConsumptionFormComponent,
    CopyPoolConsumptionDrawerComponent,
    CopyPoolConsumptionFormComponent,
    AlertsResolvePageComponent,
    AppAlertResolveChartMonthlyComponent,
    AlertResolveCalendarItemComponent,
    AlertResolveFiltersComponent,
    AlertEditPcToastComponent,
    AlertResolveComponent,
    AlertConsumptionExamFilterComponent,
    ConfirmationDialogComponent,
    AlertCalendarpoupComponent,

  ],
  imports: [
    A11yModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CdkStepperModule,
    FormsModule,
    FormsModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatMomentDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTableModule,
    MatTooltipModule,
    NgChartsModule,
    NgxMaskModule.forRoot(),
    PortalModule,
    ReactiveFormsModule,
    ReactiveFormsModule,
    VueModule,
    MatTabsModule,
    MatSidenavModule,
    MatRadioModule,
    MatDividerModule
  ],
  providers: [
    HttpClientModule,
    {
      provide: CapacityMicroservice,
      useFactory: capacityMicroserviceCreator,
      deps: [ HttpClient, AppConfigService ],
    },
    {
      provide: PoolsMicroservice,
      useFactory: poolsMicroserviceCreator,
      deps: [ HttpClient, AppConfigService ]
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    TitleCasePipe,
    TranslatePipe,
    AddUTCToTimePipe,
    DatepickerErrorPipe,
    TimezoneDisplayPipe,
    VueOverlayOpenDirective,
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { strict: true, useUtc: true } },
    { provide: DateAdapter, useClass: VueDateAdapter, deps: [ MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS ] },
    { provide: MAT_DATE_FORMATS, useValue: VUE_DATE_FORMATS },
    { provide: MatPaginatorIntl, useClass: MatPaginatorLocalizationService },
    { provide: MAT_PAGINATOR_DEFAULT_OPTIONS, useValue: { formFieldAppearance: 'outline' } },
  ]
})
export class AppModule { }
