import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { isMoment, Moment, utc } from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  CapacityMetric,
  CreatePoolPayload,
  CreatePoolResponse,
  GetMetricsResponse,
  MetricsResultItem,
  PoolScheduleOverride,
  Queue
} from 'api/types';
import { convertToPayload } from 'components/common/pools/pool-release/utils/pool-release.utils';
import { formatDateForPayload } from 'components/common/pools/utils/format-date-for-payload';
import { isSameSchedule } from 'components/common/pools/utils/is-same-schedule';
import { EXCHANGE_FORMAT, FULL_DAY_FORMAT } from 'constants/date-formats';
import { INTERVAL_15MIN } from 'constants/intervals';
import { MetricsService } from 'services/api/metrics.service';
import { PoolsService } from 'services/api/pools.service';
import { QueuesService } from 'services/api/queues.service';
import { EditPoolOccurrencesService } from 'services/edit-pool-occurrences.service';
import { DrawerStatusService } from 'services/status/drawer-status.service';
import { DisplayableServerError } from 'types/DisplayableServerError';
import { getDisplayableServerError } from 'utils/get-displayable-server-error';
import { chronologicalOrderValidator } from 'utils/validators/chronological-order.validator';
import { invalidCharactersValidator } from 'utils/validators/invalid-characters.validator';
import { pastDateValidator } from 'utils/validators/past-date.validator';
import { PoolNameErrorHandler } from '../pool-name-error-handler/pool-name-error-handler.class';
import { poolScheduleValidator } from '../pool-schedule/pool-schedule.validator';
import { daysOfTheWeek } from 'constants/days-of-the-week';

export type PoolStep = 'form' | 'occurrence';

/**
 *  Service to maintain state for add pools form, current step and other shared data relevant to the app pools flow.
 */
@Injectable({
  providedIn: 'root'
})
export class AddPoolState {
  /**
   * Add pool form group
   */
  public addPoolForm: UntypedFormGroup;

  /**
   * Current step of the add pool drawer
   */
  public step$ = new BehaviorSubject<PoolStep>('form');

  /**
   * Total capacity of the pool
   */
  public totalCapacity = 0;

  /**
   * Server drive error message
   */
  public displayableServerError: DisplayableServerError | null = null;

  /**
   * Registration estimates for the entire pool
   */
  public poolMetrics: CapacityMetric[] = [];

  /**
   * Custom error matcher for pool name
   * Only show error after name has been validated
   */
  public poolNameErrorHandler = new PoolNameErrorHandler();

  /**
   * List of available queues
   */
  public queues: Queue[] = [];

  /**
   * Initial values of the add pool form, used to reset form
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private initFormValues: { [ key: string ]: any };

  public constructor(
    private formBuilder: UntypedFormBuilder,
    private getMetricsService: MetricsService,
    private poolsService: PoolsService,
    private editOccurrencesService: EditPoolOccurrencesService,
    private queuesService: QueuesService,
    private drawerStatusService: DrawerStatusService
  ) {
    // Create form
    this.addPoolForm = this.createPoolForm();

    // Save initial values for form reset
    this.initFormValues = this.addPoolForm.getRawValue();

    // Load queues
    this.queuesService.getQueues().pipe(take(1)).subscribe((queueResponse) => {
      this.queues = queueResponse;
    });
  }

  /** Reset state to initial values. */
  public resetState(): void {
    this.totalCapacity = 0;
    this.addPoolForm.reset(this.initFormValues);
    this.editOccurrencesService.clearPoolOccurrenceData();
    this.showStep('form');
  }

  /** Shows a given step in the add pool drawer */
  public showStep(step: PoolStep): void {
    this.step$.next(step);
  }

  /**
   * Retrieves the estimated registrations based on the pool details.
   *
   * Note: cannot use `getPoolRegistration` here because the pool hasn't been created yet.
   */
  public getRegistrations(): void {
    const { startDate, queueId, clientIds, examIds } = this.addPoolForm.value;
    const numberOfDays = this.getNumberOfDaysForPool();
    this.drawerStatusService.loading();

    this.getMetricsService.getMetrics({
      startDate: formatDateForPayload(startDate),
      numberOfDays: numberOfDays,
      interval: INTERVAL_15MIN,
      queueId: queueId.id || '',
      clientIds: Array.isArray(clientIds) ? clientIds.map((c) => c.id) : [],
      examIds: Array.isArray(examIds) ? examIds.map((e) => e.id) : []
    }).pipe(take(1))
      .subscribe((res) => {
        this.getMetricsSuccess(res);
      }, (error: unknown) => {
        this.getMetricsError(error);
      });
  }

  public isValidPoolDate(date: Moment): boolean {
    const { daysOfWeek, dateExceptions, dateAdditions } = this.addPoolForm.value;

    const isIncludedDay = daysOfWeek.includes(date.format(FULL_DAY_FORMAT).toLowerCase());
    const isAnException = dateExceptions.includes(date.format(EXCHANGE_FORMAT));
    const isAnAddition = dateAdditions.includes(date.format(EXCHANGE_FORMAT));

    return !isAnException && (isIncludedDay || isAnAddition);
  }

  /**
   * Submit pool
   */
  public submitPool(): Observable<CreatePoolResponse> {
    const payload = this.constructPoolPayload();
    return this.poolsService.createPool(payload);
  }

  /**
   * Filters overrides to only include occurrence overrides that
   * deviate from the pool schedule, release or restriction
   *
   * @returns overrides An array of pool schedule overrides
   */
  public filterOverrides(): PoolScheduleOverride[] {
    const { poolSchedule, isRestricted } = this.addPoolForm.value;
    const { scheduleOverrides$ } = this.editOccurrencesService;

    return scheduleOverrides$.value.filter((override) => {
      return Boolean(
        !isSameSchedule(poolSchedule.items, override.schedule?.items) ||
        override.isReleased ||
        (typeof override.isRestricted !== 'undefined' && override.isRestricted !== isRestricted)
      );
    });
  }

  /**
   * Compares current value of the add pool form versus the initial values
   *
   * NOTE: Using JSON.stringify to compare objects is fast but can fail if object properties are out of order.
   * Luckily this is comparing the same object structure so it shouldn't fail in those cases.
   */
  public hasFormValueChanged(): boolean {
    return JSON.stringify(this.addPoolForm.getRawValue()) !== JSON.stringify(this.initFormValues);
  }

  /**
   * Stores all pool metrics that are from days that are valid pool occurrences.
   *
   *
   */
  private setPoolMetrics(metricItem: MetricsResultItem): void {
    this.poolMetrics = metricItem.metrics.filter((metric) => {
      const metricMoment = utc(metric.date);
      return this.isValidPoolDate(metricMoment);
    });
  }

  /**
   * Handle success side effects after metrics are received
   */
  private getMetricsSuccess(response: GetMetricsResponse): void {
    // Save metrics for occurrence table
    this.setPoolMetrics(response);

    // Clear existing edit occurrence data
    this.editOccurrencesService.clearPoolOccurrenceData();

    // Show occurrence step
    this.showStep('occurrence');

    // Reset status & error states
    this.displayableServerError = null;
    this.drawerStatusService.success();
  }

  /**
   * Handle metric service errors
   */
  private getMetricsError(error: unknown): void {
    this.displayableServerError = getDisplayableServerError(error);
    this.drawerStatusService.error();
  }

  private getNumberOfDaysForPool(): number {
    const { startDate, endDate } = this.addPoolForm.controls;
    if (isMoment(startDate.value) || isMoment(endDate.value)) {
      return endDate.value.diff(startDate.value, 'days') + 1;
    }
    return utc(endDate.value).diff(utc(startDate.value), 'days') + 1;
  }

  /**
   * Creates add pool form group
   */
  private createPoolForm(): UntypedFormGroup {
    return this.formBuilder.group({
      poolName: new UntypedFormControl('', {
        validators: [
          Validators.required,
          invalidCharactersValidator,
          this.poolNameErrorHandler.duplicateNameValidator.bind(this.poolNameErrorHandler)
        ]
      }),
      clientIds: new UntypedFormControl([], {
        validators: [
          Validators.required,
          Validators.minLength(1)
        ]
      }),
      examIds: new UntypedFormControl([]),
      queueId: new UntypedFormControl('', Validators.required),
      candidateCode: new UntypedFormControl(''),
      startDate: new UntypedFormControl('', { validators: [ Validators.required, pastDateValidator ], updateOn: 'blur' }),
      endDate: new UntypedFormControl('', { validators: [ Validators.required, pastDateValidator ], updateOn: 'blur' }),
      daysOfWeek: new UntypedFormControl(['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'], {
        validators: [
          Validators.required,
          Validators.minLength(1)
        ]
      }),
      daysOfWeekWithRestriction: new UntypedFormControl([]),
      dateExceptions: new UntypedFormControl([]),
      dateAdditions: new UntypedFormControl([]),
      poolSchedule: new UntypedFormControl({}, { validators: [ Validators.required, poolScheduleValidator ] }),
      poolRelease: new UntypedFormControl(null),
      isRestricted: new UntypedFormControl(true, Validators.required)
    }, {
      validators: [ chronologicalOrderValidator('startDate', 'endDate') ],
    });
  }

  /**
   * Based on AddPoolForm construct payload for submission
   */
  private constructPoolPayload(): CreatePoolPayload {
    const {
      poolName,
      candidateCode,
      startDate,
      endDate,
      daysOfWeek,
      daysOfWeekWithRestriction,
      dateExceptions,
      dateAdditions,
      poolSchedule,
      poolRelease,
      isRestricted,
      clientIds,
      examIds,
      queueId
    } = this.addPoolForm.value;

    const overrides = this.filterOverrides();
    const { occurrenceRemovedDates$ } = this.editOccurrencesService;

    const exceptions: string[] = [ ...occurrenceRemovedDates$.value, ...dateExceptions ];

    const release = convertToPayload(poolRelease);

    return {

      // required
      name: poolName,
      startDate: formatDateForPayload(startDate),
      endDate: formatDateForPayload(endDate),
      daysOfWeek: daysOfWeek,
      daysOfWeekWithRestriction: this.getDaysOfWeekWithRestriction(daysOfWeek, daysOfWeekWithRestriction),
      schedule: poolSchedule,
      isRestricted: isRestricted,
      clientIds: Array.isArray(clientIds) ? clientIds.map((client) => client.id) : [],
      queueId: queueId?.id || '',
      scheduleOverrides: overrides.length ? overrides : [],

      // optional
      ...(candidateCode ? { candidateCode: candidateCode.toString().replace(/^\s+/g, '').replace(/\s+$/g, '') } : {}),
      ...(exceptions.length ?
        { dateExceptions: exceptions.map((d: string) => formatDateForPayload(utc(d, EXCHANGE_FORMAT))) } :
        {}
      ),
      ...(dateAdditions.length ?
        { dateAdditions: dateAdditions.map((d: string) => formatDateForPayload(utc(d, EXCHANGE_FORMAT))) } :
        {}
      ),
      ...(release ?
        { autorelease: release } : {}
      ),
      ...(Array.isArray(examIds) && examIds.length ? { examIds: examIds.map((exam) => exam.id) } : {})
    };
  }

  public getDaysOfWeekWithRestriction(daysOfWeek: any, daysOfWeekWithRestriction: any[]) {
    if (daysOfWeekWithRestriction?.length) {
      daysOfWeekWithRestriction = daysOfWeekWithRestriction.map((day) => {
        return {
          ...day,
          checked: daysOfWeek?.length && daysOfWeek?.find((i: any) => i === day.value) ? day.checked : false
        };
      });

      return daysOfWeekWithRestriction;
    }
    else {
      return this.pushDefaultDataInJson(daysOfWeek)
    }
  }

  public pushDefaultDataInJson(daysOfWeek : any) {
    return daysOfTheWeek.map((day, i) => {
     return {
       value: day,
       checked: daysOfWeek?.length && daysOfWeek?.find((i: any) => i === day) ? true : false
     };
   });
 }
}
