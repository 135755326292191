import { Component } from '@angular/core';
import { isMoment, Moment, utc } from 'moment';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { GetActivityQueryParameters } from 'api/types';
import { ChipContent } from 'components/common/dismissible-chip/dismissible-chip.component';
import { EXCHANGE_FORMAT } from 'constants/date-formats';
import { invalidCharactersRegex } from 'constants/invalid-characters-regex';
import { TranslatePipe } from 'pipes/translate.pipe';
import { UsersService } from 'services/api/users.service';
import { MultiSelectOption } from '../filter-multi-select/filter-multi-select.component';
import { PoolsRecentActivityDataService } from 'services/pools-recent-activity-data.service';

/**
 *  Filters for the pools recent activity page
 */
@Component({
  selector: 'app-pools-recent-activity-filters',
  templateUrl: './pools-recent-activity-filters.component.html',
  styleUrls: [ './pools-recent-activity-filters.component.scss' ]
})

export class PoolsRecentActivityFiltersComponent {
  /**
   * Initial values for filters from service
   * Note: `Performed By` users handled separately
   */
  public initialFilters: {searchTerm: string, startDate: Moment | null, endDate: Moment | null} = {
    searchTerm: '',
    startDate: null,
    endDate: null
  }

  /**
   * List of all available user ids
   */
  public userIds: string[] = [];

  /**
   * List of all selected users to pass to the dismissible chips
   */
  public selectedUsersChips: ChipContent<MultiSelectOption>[] = [];

  /**
   * List of all selected users to pass to the dismissible chips
   */
  public selectedUsersList: MultiSelectOption[] = [];

  /**
   * List of users to pass to multi select
   */
  public multiSelectOptions: MultiSelectOption[] = [];

  /**
   * Characters blacklisted from search field
   */
  public invalidRegex = invalidCharactersRegex;

  /**
   * Translated text for Performed By chips
   */
  private performedByTranslation = ''

  public constructor(
    private filtersService: PoolsRecentActivityDataService,
    private getUsersService: UsersService,
    private translatePipe: TranslatePipe,
  ) {
    // Get latest emitted value from the needed observables
    combineLatest([
      this.getUsersService.getUsers(),
      this.filtersService.params$,
      this.translatePipe.transform('label.performedBy')
    ])
      .pipe(take(1))
      .subscribe(([ usersResponse, params, performedBy ]) => {
        this.initialFilters = {
          searchTerm: params?.searchTerm || '',
          startDate: params?.after ? utc(params.after) : null,
          endDate: params?.before ? utc(params.before) : null,
        };

        this.performedByTranslation = performedBy;
        this.userIds = usersResponse.map((q) => q.id);
        this.multiSelectOptions = usersResponse.map((user) => ({
          value: user.id,
          display: user.name,
          checked: (params?.userIds || []).includes(user.id)
        }));

        this.setSelectedUserChips(this.multiSelectOptions.filter((o) => o.checked));
      });
  }

  /**
   * Update filter service with new search term
   *
   * @param inputValue value from input
   */
  public updateSearchParams(inputValue: string): void {
    const newParams: Partial<GetActivityQueryParameters> = {
      searchTerm: inputValue,
    };
    this.filtersService.updateParams(newParams);
  }

  /**
   * Handles date change for date range picker
   *
   * @param date updated date or null if empty
   * @param dateType start | end
   */
  public filterDateChanged(date: Moment | null, dateType: string): void {
    // eslint-disable-next-line no-undefined
    const formattedDate = isMoment(date) ? date.format(EXCHANGE_FORMAT) : undefined;
    const newParams: Partial<GetActivityQueryParameters> =
        dateType === 'start' ? { after: formattedDate } : { before: formattedDate };
    this.filtersService.updateParams(newParams);
  }

  /**
   * Updates service and chip array with new array of users to filter by
   *
   * @param filteredUsers array of filtered users
   */
  public filteredUsersUpdated(filteredUsers: MultiSelectOption[]): void {
    this.setSelectedUserChips(filteredUsers);

    this.multiSelectOptions.forEach((option) => {
      option.checked = this.selectedUsersList.includes(option);
    });

    const newParams: Partial<GetActivityQueryParameters> = {
      userIds: filteredUsers.map((user) => user.value),
    };
    this.filtersService.updateParams(newParams);
  }

  /**
   * Removes the given user from the filters list
   *
   * @param removedUser user to remove
   */
  public selectedUserRemoved(removedUser: MultiSelectOption): void {
    const active = this.selectedUsersList.filter((user) => user.value !== removedUser.value);
    this.filteredUsersUpdated(active);
  }

  /**
   * Set chip list based on the users selected
   */
  private setSelectedUserChips(filteredUsers: MultiSelectOption[]): void {
    this.selectedUsersList = filteredUsers;
    this.selectedUsersChips = filteredUsers.map((user) => ({
      content: user.display,
      contentLabel: this.performedByTranslation,
      item: this.multiSelectOptions.filter((currentUser) => currentUser.value === user.value)[ 0 ]
    }));
  }
}
