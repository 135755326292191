import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CreatePoolPayload,
  CreatePoolResponse,
  GetPoolCapacityMetricsParameters,
  GetPoolCapacityMetricsResponse,
  GetPoolDetailsParameters,
  GetPoolDetailsResponse,
  GetPoolNameParameters,
  GetPoolNameResponse,
  GetPoolRegistrationsParameters,
  GetPoolRegistrationsResponse,
  GetPoolsParameters,
  GetPoolsResponse,
  PoolDetails,
  UpdatePoolPayload,
  UpdatePoolResponse,
  UpdatePoolStatusBody,
  UpdatePoolStatusResponse,
  UpdatePoolStatusType
} from 'api/types';
import { createQueryString } from 'utils/create-query-string';
import { PoolsMicroservice } from './microservices/pools.microservice';
import { UnarchivePoolPayload, UnarchivePoolResponse } from 'api/types/endpoints/unarchivePool';
import { CreatealertPoolPayload, CreatealertPoolResponse } from 'api/types/endpoints/alert-pool-consumption';

@Injectable({
  providedIn: 'root'
})
export class AlertConsumptionPoolService {

  constructor(private api: PoolsMicroservice) { }

  public createPool(payload: CreatealertPoolPayload): Observable<CreatealertPoolResponse> {
    const path = 'poolconsumption';
    return this.api.post<CreatealertPoolResponse, CreatealertPoolPayload>(path, payload)
   
  }
  public createEditPool(payload: CreatealertPoolPayload,id:any): Observable<CreatealertPoolResponse> {
    const path = `poolconsumption/${id}`;
    return this.api.patch<CreatealertPoolResponse, CreatealertPoolPayload>(path, payload)
   
  }
}
