import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { utc } from 'moment';
import { Observable, Subject, timer } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import {
  CreateCandidatesExportRequestBody,
  GetCandidatesExportParams,
  GetCandidatesExportResponse,
  Queue
} from 'api/types';
import { TranslatePipe } from 'pipes/translate.pipe';
import { TranslationKey } from 'pipes/translate.pipe';
import { CandidatesService } from 'services/api/candidates.service';
import { QueuesService } from 'services/api/queues.service';
import { VUE_DIALOG_CONFIG } from 'vue/utilities/vue-dialog-config';

/**
 * Component that downloads candidates and displays loading state while doing it
 */
@Component({
  selector: 'app-candidates-download-dialog',
  templateUrl: './candidates-download-dialog.component.html',
  styleUrls: [ './candidates-download-dialog.component.scss' ]
})
export class CandidatesDownloadDialogComponent implements OnDestroy, OnInit {
  /**
   * The title to be displayed at the top of the dialog
   */
  public dialogTitle = '';

  /**
   * The subtitle to be displayed at the top of the dialog
   */
  public dialogSubtitle = '';

  /**
   * The loading state of the download
   */
  public loading = true;

  /**
   * True if the download was a success, false if not
   */
  public success = false;

  /**
   * True if the download was a failure, false if not
   */
  public failure = false;

  /**
   * Completes when component is destroyed
   */
  public destroyed$ = new Subject();

  public constructor(
  // eslint-disable-next-line @typescript-eslint/no-parameter-properties
    @Inject(MAT_DIALOG_DATA) public data: CandidatesDownloadInput,
    private dialogRef: MatDialogRef<CandidatesDownloadDialogComponent>,
    private candidatesService: CandidatesService,
    private translatePipe: TranslatePipe,
    private queuesService: QueuesService,
  ) {}

  /**
   * Wrap the CSV string into a file and trigger a download.
   *
   * @param csvContent the CSV string
   * @param fileName the name of the file
   */
  private static downloadCsvFile(csvContent: string, fileName: string): void {
    const tempLink = document.createElement('a');
    const blob = new Blob([ csvContent ], { type: 'text/csv' });

    tempLink.href = URL.createObjectURL(blob);
    tempLink.download = fileName;
    tempLink.click();
  }

  public ngOnInit(): void {
    this.exportCandidates();
    this.updateDialogTitle('title.generating');
    this.updateDialogSubtitle('subtitle.generatingDownload');
    this.candidatesService.status$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      if (value.status === 'success') {
        this.loading = false;
        this.success = true;
        this.updateDialogTitle('title.downloading');
        this.updateDialogSubtitle('subtitle.beingDownloaded');
      } else if (value.status === 'failure') {
        this.loading = false;
        this.failure = true;
        this.updateDialogTitle('title.error');
        this.updateDialogSubtitle('subtitle.errorDownloading');
      }
    });
  }

  /**
   * Closes dialog
   */
  public closeDialog(): void {
    this.dialogRef.close();
  }

  /**
   * Resets everything back to loading states and reruns the download
   */
  public retry(): void {
    this.loading = true;
    this.success = false;
    this.failure = false;
    this.updateDialogTitle('title.generating');
    this.updateDialogSubtitle('subtitle.generatingDownload');
    this.exportCandidates();
  }

  /**
   * Updates the dialog title
   *
   * @param key translation key to pass to translatePipe
   */
  public updateDialogTitle(key: TranslationKey): void {
    this.translatePipe.transform(key)
      .pipe(take(1))
      .subscribe((message) => {
        this.dialogTitle = message;
      });
  }

  /**
   * Updates the dialog subtitle
   *
   * @param key translation key to pass to translatePipe
   */
  public updateDialogSubtitle(key: TranslationKey): void {
    this.translatePipe.transform(key)
      .pipe(take(1))
      .subscribe((message) => {
        this.dialogSubtitle = message;
      });
  }

  /**
   * Trigger the api calls for exporting candidates and ultimately downloading the CSV
   */
  public exportCandidates(): void {
    this.candidatesService.createCandidatesExport(this.data.candidateDownloadPayload)
      .pipe(take(1))
      .subscribe((createResp) => {
        this.candidatesService.getCandidatesExport(createResp)
          .pipe(take(1))
          .subscribe((getResp) => {
            this.handleGetCandidatesResponse(getResp, createResp);
          });
      });
  }

  /**
   * Calls getCandidatesExport api call with the passed in request params
   *
   * @param requestParams the params to pass to getCandidatesExport api call
   */
  public callGetCandidatesExport(requestParams: GetCandidatesExportParams): void {
    this.candidatesService.getCandidatesExport(requestParams)
      .pipe(take(1))
      .subscribe((getResp) => {
        this.handleGetCandidatesResponse(getResp, requestParams);
      });
  }

  /**
   * Handles the response of getCandidateExport and decides if it needs to be run again
   *
   * @param getResp the response of the getCandidatesExport call
   * @param params the parameters to pass to getCandidatesExport call if it needs to be called again
   */
  public handleGetCandidatesResponse(
    getResp: GetCandidatesExportResponse,
    params: GetCandidatesExportParams): void {
    switch (getResp.status) {
      case 'success':
        this.getFileName().pipe(take(1)).subscribe((fileName) => {
          if (getResp.csvContent) {
            CandidatesDownloadDialogComponent.downloadCsvFile(getResp.csvContent, fileName);
            this.candidatesService.updateStatus(getResp);
          } else {
            // eslint-disable-next-line no-console
            console.error('Export failed: CSV content missing.');
            this.candidatesService.updateStatus({ status: 'failure' });
          }
        });
        break;
      case 'failure':
        this.candidatesService.updateStatus(getResp);
        break;
      default:
        // Wait a second, then check if the export is ready again
        timer(1000).pipe(take(1)).subscribe(() => {
          this.callGetCandidatesExport(params);
        });
    }
  }

  /**
   * Completes the destroyed$ subject
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Use request details to create an informative file name.
   * eg: `candidates_2022-09-20-1600_1hr_English-Universal.csv`
   */
  private getFileName(): Observable<string> {
    const { timestamp, interval, queueId } = this.data.candidateDownloadPayload;

    // Custom, filename-safe and easy to read format, eg: 2022-09-20-1600
    const dateTimeString = utc(timestamp).format('YYYY-MM-DD-HHmm');

    // Look up the queue name from queueId
    return this.queuesService.getQueues()
      .pipe(
        take(1),
        map((queues: Queue[]): string => {
          const queue = queues.find((q) => q.id === queueId);

          // Use kebab-case name; fallback to queue ID
          const queueLabel = queue ? queue.name.replace(/\s+/, '-') : queueId;

          return `candidates_${dateTimeString}_${interval}_${queueLabel}.csv`;
        }));
  }
}

/**
 * Config to be passed to the dialog when opening it from a popover
 */
export const downloadCandidatesConfig = {
  ...VUE_DIALOG_CONFIG
};

export interface CandidatesDownloadInput {

  /**
   * Payload to pass to the createCandidatesDownload api call
   */
  candidateDownloadPayload: CreateCandidatesExportRequestBody;
}
