import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { SortDirection } from '@angular/material/sort';
import { utc } from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GetPoolsParameters, GetPoolsSortBy, PoolInfo, Queue } from 'api/types';
import { DISPLAY_DAY_FORMAT, EXCHANGE_FORMAT } from 'constants/date-formats';
import { PoolClientDisplayPipe } from 'pipes/pool-client-display.pipe';
import { AllPoolDataService } from 'services/all-pool-data.service';
import { AllPoolsFiltersService, DEFAULT_VALUES } from 'services/all-pools-filters.service';
import { formatQueueDisplay } from 'utils/format-queue';
import { PaginatorBase } from '../utils/paginator-base.class';
import { SelectionModel } from '@angular/cdk/collections';
import { AddAlertPoolDataService } from 'services/add-alert-pool-data.service';
import { AllAlertFiltersService } from 'services/all-alert-filters.service';
import { AllAlertDataService } from 'services/all-alert-data.service';

@Component({
  selector: 'app-add-alerts-consumption-table',
  templateUrl: './add-alerts-consumption-table.component.html',
  styleUrls: ['./add-alerts-consumption-table.component.scss']
})
export class AddAlertsConsumptionTableComponent extends PaginatorBase implements OnInit, OnDestroy  {

   /**
   * Queues array for display
   */
   data=false
   public queues: Queue[] = [];
   selectRowindex:any=-1
   /**
    * Table properties
    */
   public tableData: PoolInfo[] = [];
   public tableColumns = [ 'select','name', 'status', 'client', 'exams', 'queue', 'start-date', 'end-date' ];
   public sortBy: GetPoolsSortBy = DEFAULT_VALUES.sortBy;
   public sortDirection: SortDirection = DEFAULT_VALUES.direction;
   selection = new SelectionModel(true, []);
 
   /**
    * Internal reference to the page status
    */
   public loading = false;
   TableCheckboxStatus:any=false;
   /**
    *
    * Completes when component is destroyed
    * 
    */
   private destroyed$ = new Subject();
  selecedRowId: any;
 
   public constructor(
     private allPoolsFilterService: AllAlertFiltersService,
     private allPoolsDataService: AllAlertDataService,
     private AddAlertDataService:AddAlertPoolDataService
   ) {
     super(DEFAULT_VALUES);
   }
 
   public ngOnInit(): void {
  
    
     this.AddAlertDataService.selectedbutton$.subscribe((data:any)=>{
     
      
     this.selectRowindex=data;
     })
     // Subscribe to pool data updates
     this.allPoolsDataService.allPoolsData$
       .pipe(takeUntil(this.destroyed$))
       .subscribe((allPoolData) => {
         if (allPoolData) {
           const { items, sortBy, direction, limit, offset, total } = allPoolData;
         
           this.tableData = items;
           this.sortBy = this.convertSortBy(sortBy);
           this.sortDirection = direction;
           this.updatePaginator({ limit, offset, total });
         }
       });
 
     // Subscribe to page status
     this.allPoolsDataService.fetchingPools$
       .pipe(takeUntil(this.destroyed$))
       .subscribe((isFetching) => {
       
         this.loading = isFetching;
       });
 
     // Subscribe to queue updates
     this.allPoolsDataService.queues$
       .pipe(takeUntil(this.destroyed$))
       .subscribe((queues) => {
         this.queues = queues;
        
         
       });
   }
 
   public ngOnDestroy(): void {
     this.destroyed$.next();
     this.destroyed$.complete();
   }
 
   // Display queue code
   public getQueueDisplay(pool: PoolInfo): string {
     const queue = this.queues.find((q) => q.id === pool.queueId);
     return formatQueueDisplay(queue);
   }
 
   /**
    * Update filter service with new params
    *
    * @param col column to sort
    */
   public sort(col: GetPoolsSortBy): void {
     const direction = this.sortDirection === 'asc' ? 'desc' : 'asc';
     const newParams: Partial<GetPoolsParameters> = {
       sortBy: col,
       direction: direction
     };
     this.allPoolsFilterService.updateParams(newParams);
   }
 
   /**
    * Update filter service with new offsets
    *
    * @param event event from paginator
    */
   public paginatorChange(event: PageEvent): void {
     const newParams: Partial<GetPoolsParameters> = {
       offset: event.pageSize * event.pageIndex,
       limit: event.pageSize
     };
     this.allPoolsFilterService.updateParams(newParams);
   }
 
   /**
    * @param date pool date in YYYY-MM-DD format
    * @returns date in localized display format
    */
   public formatPoolDate(date: string | null): string {
     return date ? utc(date, EXCHANGE_FORMAT).format(DISPLAY_DAY_FORMAT) : '';
   }
 
   /**
    * Converts the returned `sort-by` start & end date keys to column keys.
    */
   private convertSortBy(sortBy: string): GetPoolsSortBy {
     if (sortBy === 'startDate') {
       return 'start-date';
     }
     if (sortBy === 'endDate') {
       return 'end-date';
     }
     return sortBy as GetPoolsSortBy;
   }
 
  
   public formatPooltime(time:any) {
    return time;
  }
   
   handlecheckboxSelection(event:any,rowData:any,data:any,index:any){;
   
    // this.selecedRowId=rowData.id
    
    if(event.checked == false){
      this.AddAlertDataService.setDisabledButtonValue(true)
    }
    else if(event.checked == true){
      this.AddAlertDataService.setDisabledButtonValue(false)
    }
     
    if(this.selectRowindex === index){
      this.selectRowindex= -1
    }
    else{
      this.selectRowindex=rowData.id;
     
     
      
    }
   this.AddAlertDataService.setSelectedRow({'table':rowData,'Queue':data,'index':index})
   }

}
