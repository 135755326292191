/* eslint-disable
  @typescript-eslint/no-use-before-define,
  @typescript-eslint/no-explicit-any,
  @typescript-eslint/explicit-module-boundary-types,
*/
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { isMoment, Moment, utc } from 'moment';
import { noop, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AutoReleaseBeforeType, AutoReleaseScheduleType } from 'api/types';
import { formatDateForPayload } from 'components/common/pools/utils/format-date-for-payload';
import { EXCEEDS_END_DATE } from 'constants/datepicker-errors';
import { TranslatePipe, TranslationKey, TranslationLookup } from 'pipes/translate.pipe';
import { DatePickerErrorMatcher } from 'utils/error-matchers/date-picker.error-matcher';
import { pastDateValidator } from 'utils/validators/past-date.validator';
import { WithTemplateRef } from 'vue/components/vue-radio-group/vue-radio-group.component';
import { convertToPoolRelease, PoolRelease, poolReleaseDefaultValues } from './utils/pool-release.utils';

export type IntervalOption = 'days' | 'hours';
const intervalOptions: IntervalOption[] = [ 'days', 'hours' ];

export type ReleaseRadioButton = {
  type: AutoReleaseScheduleType;
  label: string;
} & WithTemplateRef

/**
 *  Form controls to set pool release options
 */
@Component({
  selector: 'app-pool-release',
  templateUrl: './pool-release.component.html',
  styleUrls: [ './pool-release.component.scss' ],
  providers: [ {
    provide: NG_VALUE_ACCESSOR, useExisting: PoolReleaseComponent, multi: true
  } ]
})
export class PoolReleaseComponent implements ControlValueAccessor, OnInit, OnDestroy, OnChanges {
  /**
   * Start date of pool, used as maximum date for release
   */
  @Input() public poolEndDate?: Moment;

  /**
   * Reference to the content shown when timed release is selected
   */
  @ViewChild('timeRef', { static: true }) public timeReleaseContent!: TemplateRef<unknown>;

  /**
   * Reference to the content shown when date release is selected
   */
  @ViewChild('dateRef', { static: true }) public dateReleaseContent!: TemplateRef<unknown>;

  /**
   * Error matcher for datepicker components
   */
  public datePickerErrorMatcher = new DatePickerErrorMatcher();

  /**
   * Array of each radio button and their content
   */
  public radioItems: ReleaseRadioButton[] = [];

  /**
   * Options for auto-release interval
   */
  public autoReleaseIntervalOptions = intervalOptions;

  /**
   * Options for auto-release type
   */
  public autoReleaseStartOptions: AutoReleaseBeforeType[] = [ 'start-time', 'end-time' ];

  /**
   * FormGroup for the pool release
   */
  public poolReleaseForm: UntypedFormGroup = new UntypedFormGroup({
    type: new UntypedFormControl(null)
  });

  /**
   * Method to update parent form of the pool release
   *
   * Set within `registerOnChange`
   */
  private onChangeCallback: (_: PoolRelease) => void = noop;

  /**
   * Method to update parent form that the control has been touched
   *
   * Set within `registerOnTouched`
   */
  private onTouchedCallback = noop;

  /**
   * Save all translations returned during ngOnInit for later use
   */
  private translations: TranslationLookup = {};

  /**
   * Completes when component is destroyed
   */
  private destroyed: Subject<boolean> = new Subject<boolean>();

  public constructor(private translatePipe: TranslatePipe) {
    // Update parent form to be in sync with local form
    this.poolReleaseForm.valueChanges
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
        this.updateParentForm();

        // Check for valid pool release date
        this.validatePoolReleaseDate();
      });
  }

  public ngOnInit(): void {
    const keys: TranslationKey[] = [
      'label.addPool.releaseAllOccurrencesOnDate',
      'label.addPool.releaseOccurrences.interval.days',
      'label.addPool.releaseOccurrences.interval.hours',
      'label.addPool.releaseOccurrences.releaseType.ends',
      'label.addPool.releaseOccurrences.releaseType.starts',
      'label.addPool.releaseOccurrencesByTime',
    ];

    // Load all translations
    this.translatePipe.loadTranslations(keys)
      .pipe(take(1))
      .subscribe((translations) => {
        this.translations = translations;

        // Set radio items with translated text
        this.radioItems = [
          {
            type: 'time',
            label: translations[ 'label.addPool.releaseOccurrencesByTime' ],
            selectedTemplateRef: this.timeReleaseContent
          }, {
            type: 'date',
            label: translations[ 'label.addPool.releaseAllOccurrencesOnDate' ],
            selectedTemplateRef: this.dateReleaseContent
          }
        ];
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // Validate pool release date if poolEndDate changes
    if ('poolEndDate' in changes) {
      this.validatePoolReleaseDate(changes.poolEndDate.currentValue);
    }
  }

  /**
   * Complete all subscriptions
   */
  public ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  /**
   * Toggle pool having a release schedule
   */
  public toggleAutomaticRelease(event: MatSlideToggleChange): void {
    if (event.checked) {
      this.setPoolRelease('time');
    } else {
      this.resetReleaseForm();

      // reset type to null
      this.poolReleaseForm.controls.type.setValue(null);
    }
  }

  /**
   * Display item label in the radio button
   */
  public getRadioDisplay(item: ReleaseRadioButton): string {
    return item.label;
  }

  /**
   * Set the type of release
   */
  public setAutoReleaseType(event: MatRadioChange): void {
    // istanbul ignore else
    if (event?.value?.type) {
      this.setPoolRelease(event.value.type);
    }
  }

  /**
   * Returns the type of release
   */
  public getSelectedType(): ReleaseRadioButton | undefined {
    const selectedType = this.poolReleaseForm.controls.type.value;
    return this.radioItems.find((item) => item.type === selectedType);
  }

  /**
   * Returns the translated display for each interval
   */
  public releaseIntervalDisplay(item: string): string {
    return item === 'hours' ?
      this.translations[ 'label.addPool.releaseOccurrences.interval.hours' ] :
      this.translations[ 'label.addPool.releaseOccurrences.interval.days' ];
  }

  /**
   * Returns the translated display for each before type
   */
  public releaseStartDisplay(item: AutoReleaseBeforeType): string {
    return item === 'start-time' ?
      this.translations[ 'label.addPool.releaseOccurrences.releaseType.starts' ] :
      this.translations[ 'label.addPool.releaseOccurrences.releaseType.ends' ];
  }

  // Update initial value from parent
  public writeValue(parentValue: any): void {
    if (!parentValue) return;

    // Update form with controls + values
    // eslint-disable-next-line no-undefined
    this.setPoolRelease(parentValue?.type, convertToPoolRelease(parentValue) || undefined);
  }

  // Update parent form value
  public registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  // Update parent form control has been touched
  public registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  /**
   * Validate the pool release date against the pool end date
   * Release date cannot exceed pool end date
   *
   * Sets control error so error message is shown to the user
   */
  private validatePoolReleaseDate(poolEndDate?: Moment): void {
    const endDate = poolEndDate || this.poolEndDate;
    const { date, type } = this.poolReleaseForm.controls;

    if (
      type?.value === 'date' &&
      endDate &&
      isMoment(date?.value) &&
      date.value.utc().isAfter(endDate.utc().startOf('d'), 'd')
    ) {
      date.setErrors({ [ EXCEEDS_END_DATE ]: date.value });
    }
  }

  /**
   * Set FormGroup based on Release type
   */
  private setPoolRelease(type?: AutoReleaseScheduleType, initValues = poolReleaseDefaultValues): void {
    this.resetReleaseForm();

    if (!type) return;

    if (type === 'time') {
      this.poolReleaseForm.addControl('interval', new UntypedFormControl(initValues.interval), { emitEvent: false });
      this.poolReleaseForm.addControl('time', new UntypedFormControl(initValues.time), { emitEvent: false });
      this.poolReleaseForm.addControl('before', new UntypedFormControl(initValues.before), { emitEvent: false });
      this.poolReleaseForm.controls.type.setValue('time');
    } else {
      // Datepicker form outputs a moment object, to be consistent convert initial date to moment object
      this.poolReleaseForm.addControl('date', new UntypedFormControl(initValues.date === null ? null : utc(initValues.date), {
        validators: [ pastDateValidator ],
        updateOn: 'blur'
      }), { emitEvent: false });
      this.poolReleaseForm.controls.type.setValue('date');
    }
  }

  /*
   * Build pool release value then update parent form
   */
  private updateParentForm(): void {
    const poolReleaseValue = { ...this.poolReleaseForm.value };

    // create string version of date if it exists
    if (poolReleaseValue.date) {
      poolReleaseValue.date = formatDateForPayload(poolReleaseValue.date);
    }

    this.onTouchedCallback();
    this.onChangeCallback(poolReleaseValue);
  }

  /**
   * clear all controls from poolRelease except type
   * {type: null} is an acceptable value
   */
  private resetReleaseForm(): void {
    // remove all nested controls except type
    Object.keys(this.poolReleaseForm.controls).forEach((control) => {
      if (control !== 'type') {
        this.poolReleaseForm.removeControl(control, { emitEvent: false });
      }
    });
  }
}
