import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { ClientInfo, GetClientsParameters, GetClientsResponse } from 'api/types';
import { AllPoolsFiltersService } from 'services/all-pools-filters.service';
import { ClientsService } from 'services/api/clients.service';
import { AutoCompleteStatusService } from 'services/status/auto-complete-status.service';
import { VueOverlayOpenDirective } from 'vue/directives/vue-overlay-open/vue-overlay-open.directive';
import { ClientSelectFilterPaneComponent } from './client-select-filter-pane/client-select-filter-pane.component';

/**
 *  Component that displays selected Clients and opens a flyout to edit the client list when clicked
 */
@Component({
  selector: 'app-client-select-filter',
  templateUrl: 'client-select-filter.component.html',
  styleUrls: [ 'client-select-filter.component.scss' ]
})
export class ClientSelectFilterComponent implements OnInit, OnDestroy {
  /**
   * Event emitted with set of selected clients
   */
  @Output() public clientsSelected = new EventEmitter<ClientInfo[]>();

  /**
   * Array of clients to display as options from filterable service
   */
  public clientsForDisplay: ClientInfo[] = [];

  /**
   * Number of selected clients
   */
  public selectedClients = 0;

  // Overlay properties
  public overlayRef: OverlayRef;
  public componentRef!: ComponentRef<ClientSelectFilterPaneComponent>;

  /**
   * Emits new searchTerm to fetch clients
   */
  private searchTerm$ = new BehaviorSubject<string>('');

  /**
   * Completes when component is destroyed
   */
  private destroyed$ = new Subject();

  public constructor(
    private overlay: Overlay,
    private hostElement: ElementRef,
    private clientsService: ClientsService,
    private allPoolFiltersService: AllPoolsFiltersService,
    private autoCompleteStatusService: AutoCompleteStatusService,
    private vueOverlayOpen: VueOverlayOpenDirective,
  ) {
    // Create overlay
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'multi-select-overlay',
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.hostElement)
        .withPush(false)
        .withFlexibleDimensions(false)
        .withPositions([ {
          overlayX: 'start',
          overlayY: 'top',
          originX: 'start',
          originY: 'bottom'
        }, ])
    });

    // Close overlay for a click on the backdrop
    this.overlayRef.backdropClick()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.closeOverlay();
      });

    // Close overlay on press of escape
    this.overlayRef.keydownEvents()
      .pipe(takeUntil(this.destroyed$))
      .pipe(filter((event) => event.key === 'Escape'))
      .subscribe(() => {
        this.closeOverlay();
      });

    this.searchTerm$.pipe(
      switchMap((searchTerm) => {
        this.autoCompleteStatusService.loading();
        return this.fetchClients(searchTerm);
      }),
      takeUntil(this.destroyed$)
    ).subscribe((res) => {
      this.updateClientsToDisplay(res.items);
      this.autoCompleteStatusService.success();
    }, () => {
      this.updateClientsToDisplay([]);
      this.autoCompleteStatusService.error();
    });
  }

  public ngOnInit(): void {
    this.allPoolFiltersService.selectedClients$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((selectedClients) => {
        // Update number of clients selected
        this.selectedClients = selectedClients.length;
      });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Create and open ClientSelectPane in ComponentPortal
   */
  public open(): void {
    const portal = new ComponentPortal(ClientSelectFilterPaneComponent);
    this.componentRef = this.overlayRef.attach(portal);

    // Update ClientSelectPane class properties
    this.componentRef.instance.clientsForDisplay = this.clientsForDisplay;

    // Subscribe to ClientSelectPaneComponent observables
    this.componentRef.instance.clientsSelected$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((clients) => {
        this.updateFiltersWithClients(clients);
      });

    // Close overlay
    this.componentRef.instance.closePane$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.closeOverlay();
      });

    // Update client list when searchTerm is updated
    this.componentRef.instance.getNewClientList$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((searchTerm) => {
        this.searchTerm$.next(searchTerm);
      });

    // Add vue class name when overlay open
    this.vueOverlayOpen.addClassName();
  }

  /**
   * Emit event of selected clients
   *
   * @param clients array of selected clients
   */
  private updateFiltersWithClients(clients: ClientInfo[]): void {
    this.clientsSelected.emit(clients);
  }

  /**
   * Reset client list and close overlay
   */
  private closeOverlay(): void {
    this.searchTerm$.next('');
    this.vueOverlayOpen.removeClassName();
    this.overlayRef.detach();
  }

  /**
   * Update local & client-select-pane with new clients to display
   */
  private updateClientsToDisplay(clients: ClientInfo[]): void {
    this.clientsForDisplay = clients;

    if (this.componentRef) {
      this.componentRef.instance.clientsForDisplay = clients;
    }
  }

  /**
   * Get clients for client filter
   *
   * @param searchTerm client name passed service
   */
  private fetchClients(searchTerm: string): Observable<GetClientsResponse> {
    const params: GetClientsParameters = {
      searchTerm,
      sortBy: 'name',
      direction: 'asc',
      limit: 50,
      offset: 0,
    };

    return this.clientsService.getClients(params);
  }
}
