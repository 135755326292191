import { isMoment, Moment, } from 'moment';
import { EXCHANGE_FORMAT } from 'constants/date-formats';

/**
 * @param date a moment object
 * @returns dateString a formatted date string
 */
export function formatDateForPayload(date: Moment): string {
  if (isMoment(date)) {
    return date.format(EXCHANGE_FORMAT);
  }

  return date;
}
