import { DOCUMENT } from '@angular/common';
import {
  Directive,
  HostListener,
  Inject,
} from '@angular/core';

/**
 * Directive that adds a vue-overlay-open class to the body element
 *
 * Used so nav-bar can adjust z-index to show on top of overlay
 */
@Directive({
  selector: '[vueOverlayOpen]',
})
export class VueOverlayOpenDirective {
  private readonly overlayOpenClassName = 'vue-overlay-open'

  public constructor(@Inject(DOCUMENT) private document: Document) {}

  /**
   * Toggling listener that adds or removes the class name based on the given boolean
   */
  @HostListener('openedChange')
  public toggle(toggled: boolean): void {
    // eslint-disable-next-line no-unused-expressions
    toggled ? this.addClassName() : this.removeClassName();
  }

  /**
   * Adds class to body element
   */
  @HostListener('menuOpened')
  @HostListener('opened')
  public addClassName(): void {
    this.document.body.classList.add(this.overlayOpenClassName);
  }

  /**
   * Removes class to body element
   */
  @HostListener('menuClosed')
  @HostListener('closed')
  public removeClassName(): void {
    this.document.body.classList.remove(this.overlayOpenClassName);
  }
}
