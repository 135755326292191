<vue-page-header appName="{{ 'title.applicationName' | translate | async }}"
  navSection="{{ 'title.alerts' | translate | async }}" [enableBack]="true"
  [pageTitle]="poolConsumptionDetails.name"></vue-page-header>
<app-page-body>
  <vue-section-header class="details-header-wrapper">
    <h2 class="details-header-text">{{ "title.configurationDetails" | translate | async }}</h2>
    <app-edit-pool-consumption-drawer *ngIf="isPoolConfigurationEditable() && !isPoolConsumptionEnds()" ></app-edit-pool-consumption-drawer>
  </vue-section-header>

  <vue-section [loading]="pageLoading">
    <div class="details-wrapper">
      <div class="detail-column-wrapper">
        <h3 class="detail-column-header">{{ "title.poolDetails" | translate | async }}</h3>
        <div class="detail-column-section">
          <vue-detail-header>{{ "title.poolName" | translate | async }}</vue-detail-header>
          <vue-detail-content>{{ poolName }}</vue-detail-content>
        </div>
        <div class="detail-column-section">
          <vue-detail-header>{{ "title.client" | translate | async }}</vue-detail-header>
          <ng-container *ngIf="clients?.length; else clientdash">
            <vue-detail-content *ngFor="let client of clients">
              {{ client.name }}
            </vue-detail-content>
          </ng-container>
          <ng-template #clientdash>
            <p>–</p>
          </ng-template>
        </div>
        <div class="detail-column-section">
          <vue-detail-header>{{ "title.exams" | translate | async }}</vue-detail-header>
          <ng-container *ngIf="examsToDisplay.length; else dash">
            <p *ngFor="let exam of examsToDisplay" class="exam-name">{{ exam.name }}</p>
            <button *ngIf="moreThanFiveExams()" (click)="toggleExamsDisplay()" class="exam-show-toggle-button">
              <ng-container *ngIf="examsToDisplay.length > 5">
                {{'button.showLess' | translate | async }}
              </ng-container>
              <ng-container *ngIf="examsToDisplay.length <= 5">
                {{'button.showMore' | translate | async }}
              </ng-container>
            </button>
          </ng-container>
          <ng-template #dash>
            <p>–</p>
          </ng-template>
        </div>
        <div class="detail-column-section">
          <vue-detail-header>{{ "title.dateRange" | translate | async }}</vue-detail-header>
          <vue-detail-content>{{ formattedPoolStartDate }} – {{ formattedPoolEndDate }}</vue-detail-content>
        </div>
        <div class="detail-column-section">
          <vue-detail-header>{{ "title.timeRange.utc" | translate | async }}</vue-detail-header>
          <vue-detail-content>{{ poolStartTime }} – {{ poolEndTime }}</vue-detail-content>
        </div>
        <div class="detail-column-section">
          <vue-detail-header>{{ "title.queue" | translate | async }}</vue-detail-header>
          <vue-detail-content>{{ queueName }}</vue-detail-content>
        </div>
      </div>
      <div class="detail-column-wrapper">
        <h3 class="detail-column-header">{{ "title.alertDetails" | translate | async }}</h3>
        <div class="detail-column-section">
          <vue-detail-header>{{ "title.alertDateRange" | translate | async }}</vue-detail-header>
          <vue-detail-content>{{ formattedAlertStartDate }} – {{ formattedAlertEndDate }}</vue-detail-content>
        </div>
        <div class="detail-column-section">
          <vue-detail-header>{{ "title.alertTimeRange.utc" | translate | async }}</vue-detail-header>
          <ng-container *ngIf="alertTimeRangeToDisplay.length; else alerttimedash">
            <p *ngFor="let alertTimeRange of alertTimeRangeToDisplay" class="exam-name">{{ alertTimeRange.startTime }} -
              {{ alertTimeRange.endTime }}</p>
            <button *ngIf="moreThanFiveAlertTimeRange()" (click)="toggleAlertTimeRangeDisplay()"
              class="exam-show-toggle-button">
              <ng-container *ngIf="alertTimeRangeToDisplay.length > 5">
                {{'button.showLess' | translate | async }}
              </ng-container>
              <ng-container *ngIf="alertTimeRangeToDisplay.length <= 5">
                {{'button.showMore' | translate | async }}
              </ng-container>
            </button>
          </ng-container>
          <ng-template #alerttimedash>
            <p>–</p>
          </ng-template>
        </div>
        <div class="detail-column-section">
          <vue-detail-header>{{ "title.status" | translate | async }}</vue-detail-header>
          <div class="chip-wrapper">
            <app-poolconsumption-status [status]="poolConsumptionDetails.status"></app-poolconsumption-status>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <mat-card class="header">
        <mat-tab-group fitInkBarToContent>
          <mat-tab label="Threshold">
            <div [ngClass]="this.threshold.length < 4 ?  'example-containerheight' : 'example-container' ">
            <table mat-table [dataSource]="threshold" class="mat-elevation-z8 mat-equal-with-table">
              <ng-container matColumnDef="PRIORITY">
                <th mat-header-cell *matHeaderCellDef> PRIORITY </th>
                <td mat-cell *matCellDef="let element"> {{element.priority}} </td>
              </ng-container>

              <ng-container matColumnDef="TARGET">
                <th mat-header-cell *matHeaderCellDef> %(TARGET CONSUMPTION) </th>
                <td mat-cell *matCellDef="let element"> {{element.percentage}} </td>
              </ng-container>

              <ng-container matColumnDef="EMAIL">
                <th mat-header-cell *matHeaderCellDef> EMAIL </th>
                <td mat-cell *matCellDef="let element"> {{element.email}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnThreshold"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnThreshold;"></tr>

              <tr class="mat-row no-content" *matNoDataRow>
                <td class="mat-cell" colspan="9999">
                  {{ 'message.alerts.noThresholdData' | translate | async }}
                </td>
              </tr>
            </table>
          </div>
          </mat-tab>

          <mat-tab label="Run Frequency">
            <div [ngClass]="this.runFrequency.length < 4 ?  'example-containerheight' : 'example-container' ">
            <table mat-table [dataSource]="runFrequency" class="mat-elevation-z8">
              <ng-container matColumnDef="TIME">
                <th mat-header-cell *matHeaderCellDef> TIME </th>
                <td mat-cell *matCellDef="let element"> {{element.runTime}} (UTC) </td>
              </ng-container>

              <ng-container matColumnDef="DAYS">
                <th mat-header-cell *matHeaderCellDef> DAYS </th>
                <td mat-cell *matCellDef="let element"> {{formatRunDaysOfWeek(element.runDaysOfWeek)}} </td>
              </ng-container>

              <ng-container matColumnDef="PRIORITY">
                <th mat-header-cell *matHeaderCellDef> PRIORITY </th>
                <td mat-cell *matCellDef="let element"> {{element.runPriority}} </td>
              </ng-container>

              <ng-container matColumnDef="CRITICAL">
                <th mat-header-cell *matHeaderCellDef> CRITICAL </th>
                <td mat-cell *matCellDef="let element"> {{setCritical(element.isCritical)}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displaycolumnRunFequency"></tr>
              <tr mat-row *matRowDef="let row; columns: displaycolumnRunFequency;"></tr>

              <tr class="mat-row no-content" *matNoDataRow>
                <td class="mat-cell" colspan="9999">
                  {{ 'message.alerts.noRunFrequencyData' | translate | async }}
                </td>
              </tr>
            </table>
            </div>
          </mat-tab>
        </mat-tab-group>
      </mat-card>
    </div>
    
  </vue-section>
</app-page-body>