/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-use-before-define */
import { Component, forwardRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { noop, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { BulkEditCapacity } from 'api/types';
import { TranslatePipe, TranslationLookup } from 'pipes/translate.pipe';
import { SuppressErrorMatcher } from 'utils/error-matchers/suppress.error-matcher';
import { WithTemplateRef } from 'vue/components/vue-radio-group/vue-radio-group.component';

/**
 * Select which strategy to use for updating capacity values in the BulkEditForm
 * Options: New capacity, Add to capacity, Subtract from capacity
 */
@Component({
  selector: 'app-bulk-capacity-edit',
  templateUrl: './bulk-capacity-edit.component.html',
  styleUrls: [ './bulk-capacity-edit.component.scss' ],
  providers: [ {
    provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(
      /* istanbul ignore next */
      () => BulkCapacityEditComponent,
    ),
    multi: true
  } ]
})
export class BulkCapacityEditComponent implements ControlValueAccessor, OnInit, OnDestroy {
  /**
   * Template shown when Set New Capacity radio button is selected
   */
  @ViewChild('newCapacityRef', { static: true }) public newCapacityContent!: TemplateRef<unknown>;

  /**
   * Template shown when Add or Subtract from current capacity is selected
   */
  @ViewChild('alterCapacityRef', { static: true }) public alterCapacityContent!: TemplateRef<unknown>;

  /**
   * Array of radios buttons to display
   * Includes templateRefs that are defined in ngAfterViewInit
   */
  public capacityRadioButtons: CapacityRadioButton[] = [];

  /**
   * Determines which radio button is selected
   */
  public selectedRadioButton?: CapacityRadioButton;

  /**
   * Options to display for each exception logic dropdown
   */
  public alterCapacityOptions: BulkEditCapacity['type'][] = [ 'add', 'subtract' ];

  /**
   * Hide error states of the form components
   * The form save button will stay disabled until the form is valid
   */
  public suppressErrorState = new SuppressErrorMatcher();

  /**
   * Local form to handle the current value of capacity selections
   * type: 'add' | 'subtract' | 'new'
   */
  public capacityForm: UntypedFormGroup = new UntypedFormGroup({
    type: new UntypedFormControl(null),
    count: new UntypedFormControl(null)
  });

  // Form functions
  private onChangeCallback: (_: any) => void = noop;
  private onTouchedCallback: () => void = noop;

  private destroyed$ = new Subject();

  /**
   * A localized string lookup.
   */
  private translations: TranslationLookup = {};

  public constructor(private translatePipe: TranslatePipe) {}

  public ngOnInit(): void {
    /*
     * Set values of radio buttons
     * Include templates to display below radio buttons
     */
    this.translatePipe.loadTranslations(
      [
        'label.setCapacity.addTo',
        'label.setCapacity.subtractFrom',
        'radioButton.bulkEdit.hourlyCapacity',
        'radioButton.bulkEdit.addSubtract',
      ])
      .pipe(take(1))
      .subscribe((translations) => {
        this.translations = translations;
        this.capacityRadioButtons = [
          {
            type: 'new',
            label: translations[ 'radioButton.bulkEdit.hourlyCapacity' ],
            selectedTemplateRef: this.newCapacityContent
          },
          {
            type: 'add-subtract',
            label: translations[ 'radioButton.bulkEdit.addSubtract' ],
            selectedTemplateRef: this.alterCapacityContent
          },
        ];
      });

    // Subscribe to form value changes to update the parent bulk edit form group
    this.capacityForm.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((formValue) => {
        this.onChangeCallback(formValue);
        this.onTouchedCallback();
      });
  }

  /**
   * Cancel all subscriptions
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Set local value of which radio button is selected
   *
   * @param event Radio button event value is radio button object or undefined
   */
  public toggleSelectedRadioButton(event: MatRadioChange): void {
    // Check for value
    if (event.value) {
      this.selectedRadioButton = this.capacityRadioButtons.find(
        (button) => button.type === event.value.type
      );

      this.setControlsBasedOnSelectedRadioButton();
    }
  }

  /**
   * Return display text for each radio button
   *
   * @param item individual radio button object
   * @returns radio button label
   */
  public getRadioDisplay(item: CapacityRadioButton): string {
    return item.label;
  }

  /**
   * Determines display text of the logic options for altering capacity
   *
   * @param item dropdown item that is a type of bulk edit
   * @returns text to be displayed in dropdown
   */
  public getAlterOptionText(item: BulkEditCapacity['type']): string {
    return item === 'add' ?
      this.translations[ 'label.setCapacity.addTo' ] :
      this.translations[ 'label.setCapacity.subtractFrom' ];
  }

  /**
   * Update local value of capacity from BulkEditForm form
   *
   * @param capacity capacity object from BulkEditForm form
   */
  public writeValue(capacity: BulkEditCapacity | null): void {
    if (capacity?.type) {
      this.capacityForm.controls.type.setValue(capacity.type);
    }
    if (capacity && capacity.count !== null) {
      this.capacityForm.controls.count.setValue(capacity.count);
    }
  }

  /**
   * Stores BulkEditForm callback that will update the BulkEditForm form value
   *
   * @param func BulkEditForm callback
   */
  public registerOnChange(func: (_: any) => void): void {
    this.onChangeCallback = func;
  }

  /**
   * Stores BulkEditForm callback that will update the control when it has been touched
   *
   * @param func BulkEditForm touched callback
   */
  public registerOnTouched(func: () => void): void {
    this.onTouchedCallback = func;
  }

  /**
   * Set default control values when radio buttons are switched
   * For add and subtract, add is the default option in the dropdown
   */
  private setControlsBasedOnSelectedRadioButton(): void {
    const { type, count } = this.capacityForm.controls;
    if (this.selectedRadioButton?.type === 'new') {
      type.setValue('new', { emitEvent: false });
    } else {
      type.setValue(null, { emitEvent: false });
    }
    count.setValue(null);
  }
}

/**
 * Types
 */
type RadioButtonType = 'new' | 'add-subtract'

export type CapacityRadioButton = {
  type: RadioButtonType;
  label: string;
} & WithTemplateRef
