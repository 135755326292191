import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 *  Header subcomponent for the start-date datepicker that only allows navigation between years
 *  ChangeDetection set to OnPush to help manage Angular re-rendering
 */
@Component({
  selector: 'app-monthly-datepicker-header',
  templateUrl: './monthly-datepicker-header.component.html',
  styleUrls: [ './monthly-datepicker-header.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MonthlyDatepickerHeaderComponent implements OnDestroy {
  /**
   * Subject that completes when the component is destroyed
   */
  private destroyed$ = new Subject<void>();

  public constructor(
    @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats,
    private calendar: MatCalendar<MatMomentDateModule>,
    private dateAdapter: DateAdapter<MatMomentDateModule>,
    cdr: ChangeDetectorRef,
  ) {
    // Trigger change detection whenever the calendar is updated
    this.calendar.stateChanges
      ?.pipe(takeUntil(this.destroyed$))
      .subscribe(() => cdr.markForCheck());
  }

  /**
   * Terminate all subscriptions
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Returns the label
   */
  public getYearLabel(): string {
    const monthYear = this.dateAdapter
      .format(this.calendar.activeDate, this.dateFormats.display?.monthYearLabel)
      .toLocaleUpperCase();
    const dateTuple = monthYear.split(' ');
    return dateTuple[ 1 ];
  }

  /**
   * Moves the calendar to the previous month or year
   */
  public previousClicked(): void {
    const date = this.dateAdapter.addCalendarYears(this.calendar.activeDate, -1);
    this.calendar.activeDate = date;

    this.triggerReposition();
  }

  /**
   * Moves the calendar to the next month or year
   */
  public nextClicked(): void {
    const date = this.dateAdapter.addCalendarYears(this.calendar.activeDate, 1);
    this.calendar.activeDate = date;

    this.triggerReposition();
  }

  /**
   * Trigger resize so angular internals can reposition the datepicker if needed
   */
  private triggerReposition(): void {
    window.dispatchEvent(new Event('resize'));
  }
}
