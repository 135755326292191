import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { take } from 'rxjs/operators';
import { TranslatePipe, TranslationKey } from 'pipes/translate.pipe';

/**
 * Service to translate Mat-Paginator related text
 */
@Injectable({
  providedIn: 'root'
})
export class MatPaginatorLocalizationService extends MatPaginatorIntl {
  /**
   * Translated 'of' used by getLocalizedRangeLabel
   */
  private ofLabel = '';

  /**
   * Translation keys of paginator related text
   */
  private translationKeys: TranslationKey[] = [
    'paginator.itemsPerPage',
    'paginator.nextPage',
    'paginator.previousPage',
    'paginator.firstPage',
    'paginator.lastPage',
    'paginator.ofLabel',
  ];

  /**
   * Fetch translated text for paginator then set local properties
   * Replace MatPaginatorIntl.getRangeLabel with getLocalizedRangeLabel
   */
  public constructor(private translatePipe: TranslatePipe) {
    super();

    this.getRangeLabel = this.getLocalizedRangeLabel;

    this.translatePipe.loadTranslations(this.translationKeys)
      .pipe(take(1))
      .subscribe((translations) => {
        this.itemsPerPageLabel = translations[ 'paginator.itemsPerPage' ];
        this.nextPageLabel = translations[ 'paginator.nextPage' ];
        this.previousPageLabel = translations[ 'paginator.previousPage' ];
        this.firstPageLabel = translations[ 'paginator.firstPage' ];
        this.lastPageLabel = translations[ 'paginator.lastPage' ];
        this.ofLabel = translations[ 'paginator.ofLabel' ];
      });
  }

  /**
   * Override of MatPaginatorIntl getRangeLabel so the 'of' can be translated
   *
   * @param page Current page number
   * @param pageSize Current page size
   * @param length length of page
   * @returns range label: Ex: 1 - 10 of 50
   */
  public getLocalizedRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.ofLabel} ${length}`;
    }

    const pageLength = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < pageLength ?
        Math.min(startIndex + pageSize, pageLength) :
        startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${this.ofLabel} ${pageLength}`;
  }
}
