import { Component, Input } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { VueBaseButton } from '../vue-button';

/**
 *  Button based on the "flat" material style
 */
@Component({
  selector: 'vue-flat-button',
  templateUrl: './vue-flat-button.component.html',
  styleUrls: [ '../vue-button.scss' ]
})
export class VueFlatButtonComponent extends VueBaseButton {
  /**
   * Material color palette to apply
   */
  @Input() public color: MatButton['color'] = 'primary';
}
