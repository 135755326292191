import { Directive } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ClientSideSortableTable } from './client-side-sortable-table.class';

/**
 * Reusable table with client-side pagination
 *
 * Extends `ClientSideSortableTable` for client side sorting.
 *
 * Important: after sorting implementing components must call `resetPagination()`.
 */
@Directive()
export abstract class ClientSidePaginatedTable<RowDataType, SortByType>
  extends ClientSideSortableTable<RowDataType, SortByType> {
  /** Current page of the paginator */
  public pageIndex = 0;

  /** Number of rows shown in single page of the paginated table */
  public pageSize = 5;

  /** Default page sizes for the paginator  */
  public pageSizeOptions = [ 5, 10, 15, 20, 25 ];

  public constructor(defaultSortBy: SortByType) {
    super(defaultSortBy);
  }

  /**
   * @returns the visible table array for the current page.
   */
  public get paginatedTableData(): RowDataType[] {
    const start = this.pageIndex * this.pageSize;
    const end = start + this.pageSize;
    return this.tableData.slice(start, end);
  }

  /**
   * Resets page index to zero.
   *
   * Must be called after a column is sorted.
   */
  public resetPagination(): void {
    this.pageIndex = 0;
  }

  /**
   * Handles an event from paginator.
   *
   * @param event Pagination Event.
   */
  public paginatorChange(event: PageEvent): void {
    const { pageIndex, pageSize } = event;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
  }
}
