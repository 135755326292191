import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

export type EnvironmentType = typeof environment

/**
 * Injectable wrapper for environment.ts
 */
@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  /**
   * Get the environment object
   *
   * @returns environment the configuration object from environment.ts
   */
  public environment(): EnvironmentType {
    return environment;
  }
}
