/* eslint-disable
  @typescript-eslint/no-use-before-define,
  @typescript-eslint/no-explicit-any
*/
import { ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { TranslatePipe } from 'pipes/translate.pipe';
import { Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, startWith, switchMap, take } from 'rxjs/operators';
import { AutoCompleteStatusService } from 'services/status/auto-complete-status.service';
import { VueFormFieldBaseComponent } from 'vue/utilities/vue-form-field-base/vue-form-field-base.component';

/**
 *  Reusable autocomplete component based on mat-autocomplete.
 */
@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: [ './auto-complete.component.scss' ],
  providers: [ {
    provide: NG_VALUE_ACCESSOR, useExisting: AutoCompleteComponent, multi: true
  } ]
})
export class AutoCompleteComponent<T extends { [key: string]: any }>
  extends VueFormFieldBaseComponent implements OnInit {
  /**
   * Can options selected be removed
   */
  @Input() public removable = true;

  /**
   * Minimum number of characters before autocomplete list is fetched
   */
  @Input() public minCharacters = 1;

  /**
   * How an item should be displayed in the autocomplete list
   */
  @Input() public getItemDisplay!: (item: T) => string;

  /**
   * Distinguishes uniqueness from all other items in form of some identifier
   * Typically an id, name, etc.
   */
  @Input() public getItemIdentifier!: (item: T) => string | number;

  /**
   * Function that retrieves the list to display in the auto complete list
   *
   * @param input string from autocomplete
   */
  @Input() public retrieveAutoCompleteList!: (input?: string) => Observable<T[]>;

  @Input() public isValid!: (item: T) => boolean;

  /**
   * Reference to HTML input
   */
  @ViewChild('autoCompleteInput') public autoCompleteInput!: ElementRef<HTMLInputElement>;

  /**
   * List of key codes that can invoke the add method while user is typing
   */
  public separatorKeysCodes: number[] = [ ENTER ];

  /**
   * Filtered items shown to user in autocomplete dropdown
   */
  public filteredItems: Observable<T[]> = new Observable();

  /**
   * Use separate control for input
   */
  public inputControl = new UntypedFormControl('');

  public translations: {[key: string]: string} = {};

  public constructor(
  //  Test cases need injected container
  @Inject(ControlContainer) controlContainer: ControlContainer,
    private autoCompleteStatusService: AutoCompleteStatusService,
    private translatePipe: TranslatePipe
  ) {
    super(controlContainer);

    this.translatePipe.loadTranslations([ 'tooltop.message.pool.invalidexam'])
    .pipe(take(1))
    .subscribe((translations) => {
      this.translations = translations;
    });
  }

  /**
   * Subscribe to changes of input
   */
  public ngOnInit(): void {
    this.filteredItems = this.inputControl.valueChanges.pipe(
      startWith(''),
      distinctUntilChanged(),
      switchMap((val) => {
        if (val.length < this.minCharacters || val.id) {
          this.autoCompleteStatusService.success();
          return of([]);
        }
        this.autoCompleteStatusService.loading();
        return this.fetchList(val);
      })
    );
  }

  /**
   * Triggered when either a user selects an option from the autocomplete dropdown
   * or a keycode defined by separatorKeysCodes is pressed
   *
   * @param event Selected event from autocomplete dropdown
   */
  public select(event: MatAutocompleteSelectedEvent): void {
    const selectedItems = [ ...this.control.value ];
    this.control.setValue([ ...selectedItems, event.option.value ]);
    this.autoCompleteInput.nativeElement.value = '';
  }

  /**
   * Remove item from selected list
   *
   * @param value Item to remove
   */
  public remove(value: T): void {
    const index = (this.control.value as T[]).findIndex((item) => {
      return this.getItemIdentifier(item) === this.getItemIdentifier(value);
    });

    if (index >= 0) {
      const items = [ ...this.control.value ];
      items.splice(index, 1);
      this.control.setValue([ ...items ]);
    }
  }

  /**
   * Retrieve list of items to display in autocomplete
   *
   * @param value Input value that can be used with retrieve method
   * @returns Observable to list of items
   */
  public fetchList(value: string): Observable<T[]> {
    return this.retrieveAutoCompleteList(value)
      .pipe(map((items) => {
        this.autoCompleteStatusService.success();
        return this.filterOutSelectedItems(items);
      }))
      .pipe(catchError(() => {
        this.autoCompleteStatusService.error();
        return of([]);
      }))
    ;
  }

  /**
   * Filters out already selected items from list
   *
   * @param items retrieved from items
   * @returns list of items without selected items
   */
  private filterOutSelectedItems(items: T[]): T[] {
    return items.filter((item) => {
      let keepInList = true;
      this.control.value?.forEach((selectedItem: T) => {
        if (this.getItemIdentifier(selectedItem) === this.getItemIdentifier(item)) {
          keepInList = false;
        }
      });
      return keepInList;
    });
  }
}
