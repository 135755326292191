<h1 mat-dialog-title>
  {{ 'label.editPool.releaseAllOccurrences' | translate | async }}
</h1>
<p mat-dialog-content>
  {{ 'message.pool.releaseAllOccurrences' | translate:data.poolName | async }}
</p>
<div mat-dialog-actions>
  <vue-basic-button (clicked)="closeDialog()">
    {{ "button.cancel" | translate | async }}
  </vue-basic-button>
  <vue-flat-button (clicked)="releaseOccurrences()">
    {{ "button.release" | translate | async }}
  </vue-flat-button>
</div>
