/* eslint-disable multiline-comment-style */
import { Injectable } from '@angular/core';
import { utc } from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { GetMetricsParameters } from 'api/types';
import { Interval } from 'api/types';
import { EXCHANGE_FORMAT } from 'constants/date-formats';
import { INTERVAL_15MIN } from 'constants/intervals';
import { Timescale } from '../types/AppointmentFilters';
import { HttpClient } from '@angular/common/http';


export interface TimescaleUpdate {
  timescale: Timescale;
  interval: Interval;
  startDate: string;
}

export const DEFAULT_TIMESCALE_VALUES: TimescaleUpdate = {
  timescale: Timescale.monthly,
  interval: INTERVAL_15MIN,
  startDate: utc().format(EXCHANGE_FORMAT)
};

export const EMPTY_VALUES: GetMetricsParameters = {
  queueId: '',
  numberOfDays: 0,
  startDate: '',
  interval: DEFAULT_TIMESCALE_VALUES.interval,
};

/**
 * Service that distributes filters (which results in GetMetrics params) for the Appointments page.
 */
@Injectable({
  providedIn: 'root'
})
export class AppointmentsFiltersService {
  // Init with empty values; the filter component will populate them later
  private readonly params = new BehaviorSubject<GetMetricsParameters>(EMPTY_VALUES);

  // Init with monthly timescale value as default
  private readonly timescale = new BehaviorSubject<TimescaleUpdate>(DEFAULT_TIMESCALE_VALUES);

  // eslint-disable-next-line @typescript-eslint/member-ordering, no-invalid-this
  public params$ = this.params.asObservable()

  // eslint-disable-next-line @typescript-eslint/member-ordering, no-invalid-this
  public timescale$ = this.timescale.asObservable()

  public constructor(private http:HttpClient) {
   
    // Prevent handler from being called in the component's context
    this.updateParams = this.updateParams.bind(this);
  }

  /**
   * Updates anywhere from 1 to all of the params$ observable object's fields
   *
   * @param changes partial GetMetricsParameters object containing the fields to update in params$
   */
  public updateParams(changes: Partial<GetMetricsParameters>): void {
    
    // Changing numberOfDays changes the view for the user
    // Scroll to the top of the page so the user doesn't start at the bottom
    if (changes[ 'numberOfDays' ] && changes[ 'numberOfDays' ] !== this.params.getValue()[ 'numberOfDays' ]) {
      window.scrollTo(0, 0);
    }
    this.params.next({ ...this.params.getValue(), ...changes });
  }

  /**
   * Updates the timescale$ observable with new filter values so that anything subscribed to that observable can
   * run the appropriate functions with the updated values
   *
   * @param updatedValues object containing fields for timescale, interval, and startDate to update the filter with
   */
  public updateTimescale(updatedValues: TimescaleUpdate): void {
    this.timescale.next(updatedValues);
  }

  /**
   * Get the current params, synchronously
   * Helpful for callers who do not need to track updates
   *
   * @returns params the current value
   */
  public getCurrentValue(): GetMetricsParameters {
    return this.params.getValue();
  }

  
}
