/* eslint-disable @typescript-eslint/member-ordering, no-invalid-this */
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { GetClientsParameters, GetClientsResponse } from 'api/types';
import { ClientsService } from './api/clients.service';
import { DEFAULT_VALUES } from './clients-filters.service';
import { PageStatusService } from './status/page-status.service';

const emptyResponse: GetClientsResponse = {
  ...DEFAULT_VALUES,
  total: 0,
  items: []
};

/**
 * Service that stores all client data for the Clients page.
 */
@Injectable({
  providedIn: 'root'
})
export class ClientsPageDataService implements OnDestroy {
  private readonly params = new BehaviorSubject<GetClientsParameters>(DEFAULT_VALUES)

  // eslint-disable-next-line @typescript-eslint/member-ordering, no-invalid-this
  public params$ = this.params.asObservable()

  /**
   * Fill initial response with empty values, filter component will populate on initialization
   * Private BehaviorSubject so only this service can alter the value
   */
  private readonly clientResponse = new BehaviorSubject<GetClientsResponse>(emptyResponse);

  /**
   * Private BehaviorSubject so only this service can alter the value
   */
  private readonly fetchingClients = new BehaviorSubject(false);

  /**
   * Observable that emits the current state of the client data
   */
  public clientResponse$ = this.clientResponse.asObservable();

  /**
   * Observable that emits a boolean if clients are being fetched
   */
  public fetchingClients$ = this.fetchingClients.asObservable();

  /**
   * Terminates all subscriptions when completed
   */
  private destroyed$ = new Subject();

  /**
   * Emits when new client data should be fetched
   */
  private fetchClientData$ = new Subject<GetClientsParameters>();

  public constructor(
    private clientService: ClientsService,
    private pageStatusService: PageStatusService,
  ) {
    this.fetchClientData$
      .pipe(takeUntil(this.destroyed$))
      .pipe(switchMap(() => { // switchMap will cancel any in-flight API requests
        return this.fetchClients();
      }))
      .subscribe((res) => {
        this.clientSuccessHandler(res);
      }, () => {
        this.clientErrorHandler();
      });
  }

  /**
   * Terminates all subscriptions.
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Gets the client data
   *
   * @param params params to pass to service
   */
  public fetchClients(): Observable<GetClientsResponse> {
    this.fetchingClients.next(true);

    this.pageStatusService.loading(() => {
      this.fetchClientData$.next();
    });

    return this.clientService.getClients(this.params.getValue());
  }

  /**
   * GetClients success handler
   */
  private clientSuccessHandler(response: GetClientsResponse): void {
    this.clientResponse.next(response);
    this.fetchingClients.next(false);
    this.pageStatusService.success();
  }

  /**
   * GetClients error handler
   */
  private clientErrorHandler(): void {
    this.pageStatusService.error();
    this.fetchingClients.next(false);
  }

  public updateParams(changes: Partial<GetClientsParameters>): void {
    this.params.next({ ...this.params.getValue(), offset: 0, ...changes });
    this.fetchClientData$.next();
  }

  public fetchClientsData() {
    this.fetchClientData$.next();
  }
}
