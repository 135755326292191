/* eslint-disable @typescript-eslint/no-explicit-any */
import { AutoReleaseBeforeType, AutoReleaseByDateSchedule, AutoReleaseByTimeSchedule, AutoReleaseScheduleType } from 'api/types';
import { IntervalOption } from '../pool-release.component';

/**
 * Type with the same structure of the form used for pool release component
 *
 * Includes interval & AutoReleaseByDateSchedule & AutoReleaseByTimeSchedule attributes
 */
export interface PoolRelease {
  interval: IntervalOption;
  time: number;
  before: AutoReleaseBeforeType;
  date: string | null;
  type: AutoReleaseScheduleType | null;
}

/**
 * Default values for pool release
 */
export const poolReleaseDefaultValues: PoolRelease = {
  interval: 'hours',
  time: 0,
  before: 'start-time',
  date: null,
  type: null,
};

/**
 * Create a API compliant payload for `autorelease` from the pool release configuration
 */
export function convertToPayload(release?: PoolRelease):
AutoReleaseByDateSchedule | AutoReleaseByTimeSchedule | null {
  if (!release?.type) {
    return null;
  }

  if (release.type === 'date') {
    return {
      type: 'date',
      date: release.date || ''
    };
  }

  return {
    type: 'time',
    hours: release.interval === 'hours' ? release.time : null,
    days: release.interval === 'days' ? release.time : null,
    before: release.before,
  };
}

/**
 * Converts an `autorelease` object from `getPool` API into structure that `PoolReleaseComponent` can consume
 */
export function convertToPoolRelease(
  payload?: Record<string, any> | null
): PoolRelease | null {
  if (!payload?.type) return null;

  const { time, interval } = getTimeAndInterval(payload);

  return {
    before: payload.type === 'time' ? payload.before : poolReleaseDefaultValues.before,
    time: time,
    interval: interval,
    date: payload.type === 'date' ? payload.date : poolReleaseDefaultValues.date,
    type: payload.type,
  };
}

/**
 * Based on the input object return the respective time & interval for the releasing of a pool
 */
function getTimeAndInterval(obj: Record<string, any>):
{time: number, interval: IntervalOption} {
  if ('hours' in obj || 'days' in obj) {
    // When obj.days & obj.hours are both zero, default to hours
    return (obj.days !== null && obj.days >= 0) ? {
      time: obj.days,
      interval: 'days',
    } : {
      time: obj.hours,
      interval: 'hours'
    };
  }

  return {
    time: 'time' in obj ? obj.time : 0,
    interval: 'interval' in obj ? obj.interval : poolReleaseDefaultValues.interval
  };
}
