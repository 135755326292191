/* eslint-disable @typescript-eslint/member-ordering, no-invalid-this */
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { GetActivityPathParameters, GetActivityQueryParameters, GetActivityResponse } from 'api/types';
import { ActivityService } from './api/activity.service';
import { PageStatusService } from './status/page-status.service';

export const DEFAULT_VALUES: GetActivityQueryParameters = {
  limit: 25,
  offset: 0,
  sortBy: 'timestamp',
  direction: 'desc'
};

/**
 * Data provider for appointments recent activity.
 */
@Injectable({
  providedIn: 'root'
})
export class AppointmentsRecentActivityDataService implements OnDestroy {
  private readonly params = new BehaviorSubject<GetActivityQueryParameters>(DEFAULT_VALUES)

  public params$ = this.params.asObservable()

  private readonly recentActivityData = new BehaviorSubject<GetActivityResponse | null>(null);

  /**
   * Recent activity data for appointments
   */
  public recentActivityData$ = this.recentActivityData.asObservable();

  /**
   * Observable that represents when activities are being fetched.
   */
  public fetchingActivities$ = new Subject<boolean>()

  /**
   * Observables that completes when the service is destroyed.
   */
  private destroyed$ = new Subject();

  /**
   * Emits when activity should be fetched based on new parameters
   */
  private fetchActivityData$ = new Subject<GetActivityQueryParameters>();

  public constructor(
    private pageStatusService: PageStatusService,
    private activityService: ActivityService,
  ) {
    this.fetchActivityData$
      .pipe(takeUntil(this.destroyed$))
      .pipe(switchMap(() => { // switchMap will cancel any in-flight API requests
        return this.fetchData();
      }))
      .subscribe((res) => {
        this.activityDataSuccess(res);
      }, () => {
        this.activityDataError();
      });
  }

  public fetchRecentActivity(): void {
    this.fetchActivityData$.next();
  }

  /**
   * Terminate all subscriptions.
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Fetch recent activity data
   */
  private fetchData(): Observable<GetActivityResponse> {
    const capacityType: GetActivityPathParameters = { type: 'capacity' };

    this.pageStatusService.loading(() => {
      this.fetchActivityData$.next();
    });

    this.fetchingActivities$.next(true);

    return this.activityService.getActivity(capacityType, this.params.getValue());
  }

  /**
   * Success handler for activity request
   */
  private activityDataSuccess(response: GetActivityResponse): void {
    this.recentActivityData.next(response);
    this.pageStatusService.success();
    this.fetchingActivities$.next(false);
  }

  /**
   * Error handler for activity request
   */
  private activityDataError(): void {
    this.pageStatusService.error();
    this.fetchingActivities$.next(false);
  }

  public updateParams(changes: Partial<GetActivityQueryParameters>): void {
    this.params.next({ ...this.params.getValue(), offset: 0, ...changes });

    this.fetchActivityData$.next();
  }
}
