<div class="capacity">
  <h4>
    {{ "title.capacity" | translate | async }}
  </h4>
  <p>{{ 'message.setCapacity.bulkEdit.capacityInstructions' | translate | async }}</p>
</div>
<vue-radio-group
  (change)="toggleSelectedRadioButton($event)"
  [value]="selectedRadioButton"
  [items]="capacityRadioButtons"
  [getItemDisplay]="getRadioDisplay"
></vue-radio-group>
<div [formGroup]="capacityForm">
  <ng-template #newCapacityRef>
    <div class="radio-button-ref">
      <vue-numeric-input
        formControlName="count"
        label="{{ 'title.capacity' | translate | async }}"
        alignText="left"
        [errorMatcher]="suppressErrorState"
        [maxLength]="5"
      ></vue-numeric-input>
    </div>
  </ng-template>
  <ng-template #alterCapacityRef>
    <div class="radio-button-ref">
      <vue-select
        formControlName="type"
        label="{{ 'title.logic' | translate | async }}"
        [options]="alterCapacityOptions"
        [getOptionDisplay]="getAlterOptionText.bind(this)"
        [errorMatcher]="suppressErrorState"
      ></vue-select>
      <vue-numeric-input
        formControlName="count"
        label="{{ 'title.capacity' | translate | async }}"
        alignText="left"
        [errorMatcher]="suppressErrorState"
        [maxLength]="5"
      ></vue-numeric-input>
    </div>
  </ng-template>
</div>
