import { Component } from '@angular/core';

/**
 * A simple, presentational component to define section headers within a page
 */
@Component({
  selector: 'vue-section-header',
  template: '<ng-content></ng-content>',
  styleUrls: [ './vue-section-header.component.scss' ]
})
export class VueSectionHeaderComponent {}
