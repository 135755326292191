/* eslint-disable no-undefined */
import { TitleCasePipe } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Moment, utc } from 'moment';
import { Subject } from 'rxjs';
import { skip, take, takeUntil } from 'rxjs/operators';
import { GetMetricsParameters, GetQueuesResponse, Interval, Queue } from 'api/types';
import { INTERVAL_15MIN, INTERVAL_1DAY, INTERVAL_1HR, INTERVAL_6HR } from 'constants/intervals';
import { TranslatePipe, TranslationKey, TranslationLookup } from 'pipes/translate.pipe';
import { QueuesService } from 'services/api/queues.service';
import { AppointmentsFiltersService, TimescaleUpdate } from 'services/appointments-filters.service';
import { AppointmentFilters, AppointmentFilterValue, Timescale } from 'types/AppointmentFilters';
import { formatQueueDisplay } from 'utils/format-queue';
import { applyFilterConfig, getFilterConfig } from './filter-config';
import { filtersToParams, getTimescale } from './filters-to-params';

/**
 *  Filters for the appointments page
 */
@Component({
  selector: 'app-appointments-filters',
  templateUrl: './appointments-filters.component.html',
  styleUrls: [ './appointments-filters.component.scss' ]
})

export class AppointmentsFiltersComponent implements OnInit, OnDestroy, OnChanges {
  /**
   * Array of queues to be listed as options
   */
  @Input() public queues?: Queue[];

  /**
   * State for all appointment filters
   */
  public filters: AppointmentFilters = getFilterConfig(Timescale.monthly, true);

  /**
   * Formatting function for queue display, passed to queue select component
   */
  public formatQueueOption = formatQueueDisplay;

  /**
   * Date object that is used to determine correct offsets for timezones
   */
  public dateForTimezone: Moment = utc();

  /**
   * The localization keys to look up.
   */
  private translationKeys: TranslationKey[] = [
    'title.daily',
    'title.fifteenMinutes',
    'title.monthly',
    'title.oneDay',
    'title.oneHour',
    'title.sixHours',
    'title.utc',
    'title.weekly',
  ]

  /**
   * A localized string lookup.
   */
  private translations: TranslationLookup = {};

  /**
   * Terminate subscriptions
   */
  private destroyed$ = new Subject();

  public constructor(
    private filterService: AppointmentsFiltersService,
    private titleCasePipe: TitleCasePipe,
    private translatePipe: TranslatePipe,
  ) { }

  public ngOnInit(): void {
    this.filterService.params$
      .pipe(take(1))
      .subscribe((params) => {
        // Only set initial filters if queues are available
        if (this.queues?.length) {
          this.filters.queue.options = this.queues;
          this.setInitialFilters(params, this.queues);
        }
      });

    /*
     * Change timescale view if filter service timescale is updated
     * Skip the initial emission, initial filters are handled by `setInitialFilters`
     */
    this.filterService.timescale$
      .pipe(takeUntil(this.destroyed$), skip(1))
      .subscribe((updated) => {
        this.updateTimescale(updated);
      });

    // Load the localization translations
    this.translatePipe.loadTranslations(this.translationKeys)
      .pipe(take(1))
      .subscribe((result) => {
        this.translations = result;
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // Update filters if queues change
    if ('queues' in changes && changes.queues.currentValue.length) {
      this.filters.queue.options = changes.queues.currentValue;

      this.filterService.params$
        .pipe(take(1))
        .subscribe((params) => {
          this.setInitialFilters(params, changes.queues.currentValue);
        });
    }
  }

  /**
   * Complete all subscriptions
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Translate & format individual timescale within the select
   */
  public formatTimescaleOption(t: Timescale): string {
    const translation = this.translations[ 'title.' + t ];
    return this.titleCasePipe.transform(translation);
  }

  /**
   * Get translation for an interval
   */
  public formatIntervalOption(i: Interval): string {
    switch (i) {
      case INTERVAL_15MIN:
        return this.translations[ 'title.fifteenMinutes' ];
      case INTERVAL_1HR:
        return this.translations[ 'title.oneHour' ];
      case INTERVAL_6HR:
        return this.translations[ 'title.sixHours' ];
      case INTERVAL_1DAY:
        return this.translations[ 'title.oneDay' ];
    }
    return '';
  }

  /**
   * Update a single filter and trigger fetch of new metrics
   */
  public filterChanged(name: keyof AppointmentFilters, value: AppointmentFilterValue): void {
   
    if (this.filters[ name ]) {
      // Update filter config based on the timescale change
      if (name === 'timescale') {
        this.filters = applyFilterConfig(value as Timescale, this.filters);
      }

      if (name === 'startDate' && typeof value === 'string') {
        this.dateForTimezone = utc(value);
      }

      // Apply latest update
      this.filters[ name ].value = value;

      this.filterService.updateParams(filtersToParams(this.filters));
    }
  }

  /**
   * Update multiple filters based on the new timescale
   * Avoids trigger multiple network calls for consecutive filter changes
   */
  private updateTimescale(changes: TimescaleUpdate): void {
    const newFilters = applyFilterConfig(changes.timescale, this.filters);
    newFilters.timescale.value = changes.timescale;
    newFilters.interval.value = changes.interval;
    newFilters.startDate.value = changes.startDate;

    this.filterService.updateParams(filtersToParams(newFilters));
    this.filters = newFilters;
  }

  /**
   * Set initial value of the filters based on existing params
   */
  private setInitialFilters(params: GetMetricsParameters, queues: GetQueuesResponse): void {
    // Set existing queue selection if it exists
    const selectedQueue = params?.queueId ? queues.find((q) => q.id === params.queueId) : undefined;
    this.filters.queue.value = selectedQueue ?? QueuesService.getDefaultQueue(queues);

    // If timescale is not the default, update the configuration
    const paramTimescale = Timescale.monthly;
    if (this.filters.timescale.value !== paramTimescale) {
      this.filters.timescale.value = paramTimescale;
      this.filters = applyFilterConfig(this.filters.timescale.value, this.filters);
    }

    this.filters.startDate.value = this.filters.startDate.value;
    this.filters.interval.value = this.filters.interval.value;

    this.filterService.updateParams(filtersToParams(this.filters));
  }
}
