import { AfterContentInit, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChartDataset, ChartOptions } from 'chart.js';
import { utc } from 'moment';
import { take } from 'rxjs/operators';
import { GetMetricsParameters, MetricsResultItem } from 'api/types';
import { CandidatesDownloadDialogComponent, CandidatesDownloadInput, downloadCandidatesConfig } from 'components/dialogs/candidates-download-dialog/candidates-download-dialog.component';
import { DISPLAY_DAY_FORMAT, EXCHANGE_FORMAT } from 'constants/date-formats';
import { INTERVAL_1DAY, INTERVAL_1HR } from 'constants/intervals';
import { TranslatePipe, TranslationKey, TranslationLookup } from 'pipes/translate.pipe';
import { MetricsService } from 'services/api/metrics.service';
import { AppointmentsFiltersService } from 'services/appointments-filters.service';
import { Timescale } from 'types/AppointmentFilters';
import { FlaggedCapacityMetricItem } from 'types/FlaggedCapacityMetricItem';
import { LegendCategoryConfig } from 'types/LegendCategoryConfig';
import { addCriticalFlags } from 'utils/add-critical-flags';
import { formatTime } from 'utils/format-time';
import { pxToRem } from 'utils/rem-utils';
import { basePopoverChartOptions } from '../../charts/utils/base-popover-chart-options';
import palette from '../../charts/utils/chart-palette';
import { CAPACITY_ID, getChartDatasets, REGISTRATIONS_ID } from './appt-monthly-chart-popover.datasets';

// Add 5 percent to chart max to ensure no rendered lines are clipped
export const MAX_DATA_BUFFER = 1.05;
const CHART_WIDTH_REMS = pxToRem(284);

/**
 *  Popover displayed when a cell is clicked on the monthly appointment chart
 */
@Component({
  selector: 'app-appt-monthly-chart-popover',
  templateUrl: './appt-monthly-chart-popover.component.html',
  styleUrls: [ './appt-monthly-chart-popover.component.scss' ]
})
export class ApptMonthlyChartPopoverComponent implements AfterContentInit {
  /**
   * Date string to display details for in YYYY-MM-HH
   */
  @Input() public date?: string;

  /**
   * Shows the loading spinner within the popover
   */
  public loading = true;

  /**
   * Chart dimensions
   */
  public chartWidthRems = CHART_WIDTH_REMS;

  /**
   * For very short charts, the height must be set in px, directly on the canvas.
   */
  public chartHeightPx = 80;

  /**
   * Account for internal chart padding
   */
  public canvasWrapperStyle = {
    'width.rem': CHART_WIDTH_REMS + pxToRem(7),
    'margin-left.rem': pxToRem(-7),
  }

  /**
   * Chart configuration
   */
  public chartDatasets: ChartDataset[] = [];
  public chartLabels: string[] = [];
  public chartOptions: ChartOptions = {};
  public bottomXAxisLabels: string[] = [];

  /**
   * Legend configuration
   */
  public legendItems: LegendCategoryConfig[] = [];

  /**
   * A localized string lookup.
   */
  private translations: TranslationLookup = {};

  public constructor(
    private getMetricsService: MetricsService,
    private filterService: AppointmentsFiltersService,
    private translatePipe: TranslatePipe,
    private dialog: MatDialog,
  ) {}

  public ngOnInit(): void {
    const keys: TranslationKey[] = [
      'title.registrations',
      'title.capacity',
      'title.critical',
      'button.dailyView',
      'button.downloadCandidates'
    ];

    // Load the localization translations
    this.translatePipe.loadTranslations(keys)
      .pipe(take(1))
      .subscribe((translations) => {
        this.translations = translations;
        this.legendItems = [
          { type: 'bar', color: palette.blue, label: translations[ 'title.registrations' ] },
          { type: 'bar', color: palette.red, label: translations[ 'title.critical' ] },
        ];
      });
  }

  // AfterContentInit ensures container layout is stable before rendering chart
  public ngAfterContentInit(): void {
    this.buildContent();
  }

  /**
   * Download all of the candidates for the selected day.
   */
  public downloadCandidates(): void {
    const beginningOfDay = utc(this.date).startOf('day').format();
    this.dialog.open<CandidatesDownloadDialogComponent, CandidatesDownloadInput>(CandidatesDownloadDialogComponent, {
      ...downloadCandidatesConfig,
      data: {
        candidateDownloadPayload: {
          timestamp: beginningOfDay,
          queueId: this.filterService.getCurrentValue().queueId,
          interval: INTERVAL_1DAY
        }
      }
    });
  }

  /**
   * Switches the view to a daily view of the day clicked on
   */
  public goToDailyView(): void {
    this.filterService.updateTimescale({
      timescale: Timescale.daily,
      interval: INTERVAL_1HR,
      startDate: this.date ?? utc().format(EXCHANGE_FORMAT)
    });
  }

  /**
   * Date formatted to be read by a screen reader
   */
  public get screenReaderDate(): string {
    return utc(this.date).format(DISPLAY_DAY_FORMAT);
  }

  private buildContent(): void {
    this.loading = true;

    if (!this.date) {
      return;
    }

    // Fetch hourly details
    const params: GetMetricsParameters = {
      ...this.filterService.getCurrentValue(),
      startDate: this.date,
      numberOfDays: 1,
      interval: INTERVAL_1HR,
    };
    this.getMetricsService.getMetrics(params).pipe(take(1)).subscribe((metrics) => {
      this.buildChart(metrics);
    });
  }

  private buildChart(metricItem: MetricsResultItem): void {
    const flaggedMetrics = addCriticalFlags(metricItem.metrics[ 0 ].items);
    const maxCapacity = (flaggedMetrics.reduce((max, m) => {
      return Math.max(max, m.capacity || 0);
    }, 0)) * MAX_DATA_BUFFER;

    this.chartOptions = basePopoverChartOptions(maxCapacity, CAPACITY_ID, REGISTRATIONS_ID);
    this.chartDatasets = getChartDatasets(flaggedMetrics, this.translations);
    this.chartLabels = flaggedMetrics.map((m) => formatTime(m.timestamp));
    this.bottomXAxisLabels = this.getBottomXAxisLabels(flaggedMetrics);

    this.loading = false;
  }

  private getBottomXAxisLabels(metrics: FlaggedCapacityMetricItem[]): string[] {
    const labels = metrics.map((m) => {
      return formatTime(m.timestamp);
    });

    // Only display hours key parts of the day
    const keepers = [ '0:00', '12:00', '23:00' ];
    return labels.map((label) => {
      return keepers.includes(label) ? label : ''; // Use an empty string so we can still use empty labels for layout
    });
  }
}
