import { utc } from 'moment';
import { HourlyPoolScheduleItem } from 'api/types';
import { formatTimeSlot, getEndTime, getNextHour, getNextHourMinuteWise } from 'utils/time-utils';

interface TimeSlot {
  startTime: string;
  endTime: string;
  capacity: number;
}

/**
 * If user changes start or end time, apply old capacities
 *
 * @param timeSlotStart the start time in 'hh:mm' format
 * @param timeSlotEnd the end time in 'hh:mm' format
 * @param oldCapacityItems existing capacity items
 * @returns capacity the found capacity or 0
 */
function getExistingCapacity<T extends TimeSlot>(
  timeSlotStart: string,
  timeSlotEnd: string,
  oldCapacityItems: T[],
): number {
  const oldCapacityItem = oldCapacityItems.find((item) =>
    timeSlotEnd === item.endTime && timeSlotStart === item.startTime
  );
  return oldCapacityItem ? oldCapacityItem.capacity : 0;
}

/**
 * Calculate each time slot between the pool start and end time
 * Partial hours will always be the first or last hour
 * Times are inclusive of the start time plus 15 minutes
 *
 * @param poolStartTime the start time in 'hh:mm' format
 * @param poolEndTime the end time in 'hh:mm' format
 * @param oldCapacityItems the existing capacity items
 * @returns hourlyItems an array of time slots
 */
export function calculateTimeSlots<T extends TimeSlot>(
  poolStartTime: string,
  poolEndTime: string,
  oldCapacityItems: T[],
): HourlyPoolScheduleItem[] {
  if (!poolStartTime || !poolEndTime) {
    return [];
  }

  const timeSlots: HourlyPoolScheduleItem[] = [];
  const scheduleEndTime = utc(poolEndTime, 'hh:mm');
  let time = utc(poolStartTime, 'hh:mm');

  while (time.hour() !== scheduleEndTime.hour()) {
    const endTime = getEndTime(time);
    const timeSlotStart = formatTimeSlot(time);
    const timeSlotEnd = formatTimeSlot(endTime);

    // Get old capacity the value isn't lost
    const capacity = getExistingCapacity(timeSlotStart, timeSlotEnd, oldCapacityItems);

    timeSlots.push({
      startTime: timeSlotStart,
      endTime: timeSlotEnd,
      capacity: capacity
    });

    time = getNextHour(time);
  }

  if (scheduleEndTime.minute() === 0) {
    // Time ends on top of hour add extra slot
    const timeSlotStartAndEnd = formatTimeSlot(time);
    timeSlots.push({
      startTime: timeSlotStartAndEnd,
      endTime: timeSlotStartAndEnd,
      capacity: getExistingCapacity(timeSlotStartAndEnd, timeSlotStartAndEnd, oldCapacityItems)
    });
  } else {
    // Partial end time
    const timeSlotStart = formatTimeSlot(time);
    const timeSlotEnd = formatTimeSlot(scheduleEndTime);
    timeSlots.push({
      startTime: timeSlotStart,
      endTime: timeSlotEnd,
      capacity: getExistingCapacity(timeSlotStart, timeSlotEnd, oldCapacityItems)
    });
  }

  return timeSlots;
}

export function calculateTimeSlotsMinuteWise<T extends TimeSlot>(
  poolStartTime: string,
  poolEndTime: string,
  oldCapacityItems: T[],
): HourlyPoolScheduleItem[] {
  if (!poolStartTime || !poolEndTime) {
    return [];
  }

  const timeSlots: HourlyPoolScheduleItem[] = [];
  const scheduleEndTime = utc(poolEndTime, 'hh:mm');
  let time = utc(poolStartTime, 'hh:mm');

  while (time <= scheduleEndTime) {
    const timeSlotStart = formatTimeSlot(time);
    const timeSlotEnd = formatTimeSlot(time);

    // Get old capacity the value isn't lost
    const capacity = getExistingCapacity(timeSlotStart, timeSlotEnd, oldCapacityItems);

    timeSlots.push({
      startTime: timeSlotStart,
      endTime: timeSlotEnd,
      capacity: capacity
    });

    time = getNextHourMinuteWise(time);
  }

  return timeSlots;
}