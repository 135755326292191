import { AbstractControl, UntypedFormControl, FormGroupDirective, NgForm, ValidationErrors } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { INVALID_CHARACTERS_ERROR } from 'utils/validators/invalid-characters.validator';

/**
 *  Handle pool name validation errors.
 */
export class TemplateNameErrorHandler implements ErrorStateMatcher {
  /**
   * Used to show error if pool name is already used
   */
  private isDuplicateName = false;

  /**
   * Only display error after form submission for pool name error or when there is an invalid character
   */
  public isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    if (control?.value && control?.invalid && control.errors?.[ INVALID_CHARACTERS_ERROR ]) {
      return true;
    }

    return Boolean(control?.value && control?.invalid && control?.pristine && form?.submitted);
  }

  /**
   * Passed as a validator for pool name control
   *
   * @param control pool name control
   * @returns validation error or null for no error
   */
  public duplicateNameValidator(control: AbstractControl): ValidationErrors | null {
    if (control.pristine && this.isDuplicateName) {
      return {
        'duplicate-template-name': control.value
      };
    }
    return null;
  }

  /**
   * Set validator for duplicate name error
   *
   * @param toggle whether pool name is a duplicate
   */
  public setDuplicateNameError(toggle: boolean): void {
    this.isDuplicateName = toggle;
  }
}
