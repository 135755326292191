<mat-form-field appearance="fill">
  <mat-date-range-input [rangePicker]="picker">
    <input
      matStartDate
      (dateChange)="startDateChange.emit($event.value)"
      [value]="initialStartDate"
      [placeholder]="startDatePlaceholder"
    >
    <input
      matEndDate
      (dateChange)="endDateChange.emit($event.value)"
      [value]="initialEndDate"
      [placeholder]="endDatePlaceholder"
    >
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker">
    <mat-icon matDatepickerToggleIcon>
      <img src="/assets/icons/icon-calendar.svg" alt="">
    </mat-icon>
  </mat-datepicker-toggle>
  <mat-date-range-picker
    #picker
    vueOverlayOpen
  ></mat-date-range-picker>
</mat-form-field>
