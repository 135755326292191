import { ChartOptions } from 'chart.js';
import { baseChartOptions } from './base-chart-options';

/**
 *  Chart options for a stripped down, mini-chart appropriate for popovers.  No axes, no gridlines.
 *
 * @param yAxisMax the highest Y value to include in the chart area
 * @param xAxisIdOne the ID of the first X axis
 * @param xAxisIdTwo the ID of the second X axis
 * @returns ChartOptions
 */
export function basePopoverChartOptions(yAxisMax: number, xAxisIdOne: string, xAxisIdTwo: string): ChartOptions {
  // Since we aren't showing gridlines / ticks, use maxCapacity for the "stepSize"
  const baseConfig = baseChartOptions(yAxisMax, yAxisMax);
  const yScale = baseConfig.scales?.y || {};
  return {
    ...baseConfig,

    // Disable ChartJS auto-layout functionality
    responsive: false,
    maintainAspectRatio: false,

    scales: {
      ...baseConfig.scales,
      y: {
        ...yScale,
        grid: {

          // Hide all outer lines
          display: false,
          borderWidth: 0,
        }
      },

      // No gridlines, no axis ticks!
      [ xAxisIdOne ]: {
        axis: 'x',
        display: false,
      },
      [ xAxisIdTwo ]: {
        axis: 'x',
        display: false,
      },
    },

    // Disable hover effect
    // eslint-disable-next-line no-undefined
    onHover: undefined,
  };
}
