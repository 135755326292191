<!--
  mat-datepicker requires an input element to be used,
  it is hidden from the UI and assistive technologies.
-->
<mat-date-range-input
  [rangePicker]="picker"
  class="hidden-input"
>
  <input
    matStartDate
    (dateChange)="dateChange($event)"
    [value]="startOfWeek"
    aria-hidden="true"
    tabindex="-1"
  >
  <input
    matEndDate
    [value]="endOfWeek"
    aria-hidden="true"
    tabindex="-1"
  >
</mat-date-range-input>
<mat-date-range-picker
  #picker
  appDatepickerWeekRange
  vueOverlayOpen
  panelClass="weekly-datepicker"
></mat-date-range-picker>
