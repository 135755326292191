import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CapacityTemplateStatus } from 'api/types';
import { invalidCharactersRegex } from 'constants/invalid-characters-regex';
import { TranslatePipe, TranslationKey } from 'pipes/translate.pipe';
import { TemplatePageDataService } from 'services/template-page-data.service';
import { Filter } from 'types/Filter';

export interface TemplateStatus {
  text: string;
  translationKey: TranslationKey;
  value: CapacityTemplateStatus | 'all';
}

export interface TemplatesFilters {
  status: Filter<TemplateStatus>;
  search: Filter<string>;
}

/**
 * Filter components for templates page
 */
@Component({
  selector: 'app-template-filters',
  templateUrl: './template-filters.component.html',
  styleUrls: [ './template-filters.component.scss' ]
})
export class TemplateFiltersComponent implements OnDestroy {
  /**
   * List of statuses for the dropdown
   */
  public statuses: TemplateStatus[] = [
    { text: '', translationKey: 'template.status.all', value: 'all' },
    { text: '', translationKey: 'template.status.active', value: 'active' },
    { text: '', translationKey: 'template.status.archived', value: 'archived' },
  ];

  /**
   * Status filter
   */
  public filters: TemplatesFilters = {
    status: {
      enabled: true,
    },
    search: {
      enabled: true,
      value: '',
    }
  };

  /**
   * Characters blacklisted from search field
   */
  public invalidRegex = invalidCharactersRegex;

  /**
   * Completes when component is destroyed
   */
  private destroyed$ = new Subject();

  public constructor(
    private templatePageDataService: TemplatePageDataService,
    translatePipe: TranslatePipe,
  ) {
    /**
     * Load translations for status dropdown
     */
    translatePipe.loadTranslations(this.statuses.map((status) => status.translationKey))
      .pipe(take(1))
      .subscribe((translations) => {
        this.statuses = this.statuses.map((status) => {
          return {
            ...status,
            text: translations[ status.translationKey ]
          };
        });
        this.filters.status.options = this.statuses;
      });

    /**
     * Update status dropdown based on service params
     */
    templatePageDataService.templateParams$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((params) => {
        if (params.status) {
          this.filters.status.value = this.statuses.find((status) =>
            params.status === status.value
          );
        } else {
          this.filters.status.value = this.statuses.find((status) => status.value === 'all');
        }
        this.filters.search.value = params.searchTerm || '';
      });
  }

  /**
   * Terminate all subscriptions
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Updates template service with updated searchTerm param.
   *
   * @param inputValue the users input value
   */
  public search(inputValue: string): void {
    this.templatePageDataService.updateTemplatesParams({
      searchTerm: inputValue
    });
  }

  /**
   * Updates template service with updated status param.
   * When all is selected, set param as undefined
   *
   * @param status the new chosen status
   */
  public statusChanged(status?: TemplateStatus): void {
    this.templatePageDataService.updateTemplatesParams({
      // eslint-disable-next-line no-undefined
      status: status && status.value !== 'all' ? status.value : undefined
    });
  }

  /**
   * Display status value in dropdown
   *
   * @param status current status
   * @returns status value
   */
  public displayStatus(status: TemplateStatus): string {
    return status.text;
  }
}
