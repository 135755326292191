import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { utc } from 'moment';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import {
  AutoReleaseByDateSchedule,
  ExamName,
  GetPoolRegistrationsResponse,
  GetQueuesResponse,
  PoolDetails,
  RegistrationForDate
} from 'api/types';
import { FetchesPoolBaseClass } from 'components/common/fetches-pool-base/fetches-pool-base.class';
import { EditPoolToastComponent } from 'components/common/pools/edit-pool-toast/edit-pool-toast.component';
import { getPoolStartEndTime } from 'components/common/pools/utils/get-pool-start-end-time';
import { isPoolEditable, isPoolArchived, isPoolEnds } from 'components/common/pools/utils/is-pool-editable';
import { FULL_MONTH_DAY_YEAR_FORMAT } from 'constants/date-formats';
import { TranslatePipe, TranslationKey } from 'pipes/translate.pipe';
import { PoolsService } from 'services/api/pools.service';
import { QueuesService } from 'services/api/queues.service';
import { EditPoolService } from 'services/edit-pool.service';
import { FetchingPoolStatusService } from 'services/status/fetching-pool-status.service';
import { OccurrenceActionsStatusService } from 'services/status/occurrence-actions-status.service';
import { PageStatusService } from 'services/status/page-status.service';
import { ERROR, LOADING } from 'types/RequestStatus';
import { LoadingDialogComponent, LoadingDialogInput, loadingDialogConfig } from 'components/dialogs/loading-dialog/loading-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ChangePoolNameComponent, changePoolNameModalConfig, changePoolNameModalInputs } from 'components/common/pools/change-poolname/change-poolname.component';
/**
 *  Pool details page
 */
@Component({
  selector: 'app-pool-details-page',
  templateUrl: './pool-details-page.component.html',
  styleUrls: ['./pool-details-page.component.scss']
})
export class PoolDetailsPageComponent extends FetchesPoolBaseClass implements OnInit, OnDestroy {
  @ViewChild(EditPoolToastComponent) public toast!: EditPoolToastComponent;

  public poolDetails: PoolDetails = {} as PoolDetails;

  /**
   * The start time of the first exam and the end time of the last
   */
  public poolTimeRange = '';

  /**
   * Start and end date of the pool in 'Month Day, Year' format
   */
  public formattedStartDate = '';
  public formattedEndDate = '';

  /**
   * Auto release display value
   */
  public formattedAutorelease: Observable<string> = of('');

  /**
   * Name of the queue associated with the pool
   */
  public queueName = '';

  /**
   * An array of the exams that should show in the current view
   */
  public examsToDisplay: ExamName[] = [];

  /**
   * Determines how many exams to show in the current view
   */
  public displayAllExams = false;

  /**
   * Array of registrations for the pool
   */
  public registrations: RegistrationForDate[] = [];

  /**
   * Display an error when an occurrence action fails
   */
  public showOccurrenceActionError = false;

  /**
   * Track page loading state internally
   */
  public pageLoading = false;

  /**
   * Observable completes when component destroyed
   */
  private destroyed$ = new Subject();

  public constructor(
    private queuesService: QueuesService,
    private poolsService: PoolsService,
    private route: ActivatedRoute,
    private editPoolService: EditPoolService,
    private pageStatusService: PageStatusService,
    private translatePipe: TranslatePipe,
    private occurrenceActionsStatusService: OccurrenceActionsStatusService,
    private dialog: MatDialog,
    fetchingPoolStatusService: FetchingPoolStatusService,
    private poolService: PoolsService
  ) {
    super(fetchingPoolStatusService);
  }

  /**
   * Fetch pool details and subscribe to updates
   */
  public ngOnInit(): void {
    this.fetchPageData();

    // If pool is edited, update the page
    this.editPoolService.poolUpdated$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((poolUpdated) => {
        this.fetchPageData(() => {
          this.toast.openToast(poolUpdated.poolName, poolUpdated.status);
        });
      });

    this.occurrenceActionsStatusService.status$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((status) => {
        this.showOccurrenceActionError = status === ERROR;
      });

    // Listen for page status changes
    this.pageStatusService.status$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((status) => {
        this.pageLoading = status === LOADING;
      });
  }

  /**
   * Terminate subscriptions
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.fetchingPoolStatusComplete();
  }

  /**
   * Generate auto release message based on pool release
   *
   * @returns translated auto release message
   */
  public getAutoReleaseMsg(): Observable<string> {
    const autorelease = this.poolDetails.autorelease;
    if (autorelease) {
      // Return translated date
      if ('date' in autorelease) {
        return of(utc((autorelease as AutoReleaseByDateSchedule).date).format(FULL_MONTH_DAY_YEAR_FORMAT));
      }

      // Auto release is before occurrence starts
      if (autorelease.before === 'start-time') {
        const startKey: TranslationKey = autorelease.days === 1 ?
          'label.auto-release.starts.singular' :
          'label.auto-release.starts.plural';
        return this.translatePipe.transform(
          startKey,
          (Number(autorelease.days) || 0).toString(),
          (Number(autorelease.hours) || 0).toString()
        );
      }

      // Auto release is before occurrence ends
      const endKey: TranslationKey = autorelease.days === 1 ?
        'label.auto-release.ends.singular' :
        'label.auto-release.ends.plural';
      return this.translatePipe.transform(
        endKey,
        (Number(autorelease.days) || 0).toString(),
        (Number(autorelease.hours) || 0).toString()
      );
    }
    return this.translatePipe.transform('label.none');
  }

  /**
   * Retries the failed occurrence action.
   */
  public retryOccurrenceAction(): void {
    this.occurrenceActionsStatusService.retry();
  }

  /**
   * @returns true if the pool has more than 5 exams
   */
  public moreThanFiveExams(): boolean {
    return (this.poolDetails?.exams?.length || 0) > 5;
  }

  /**
   * Toggles the display of more exams when there are more than 5
   */
  public toggleExamsDisplay(): void {
    this.displayAllExams = !this.displayAllExams;
    this.examsToDisplay = this.displayAllExams ? this.poolDetails.exams : this.poolDetails.exams.slice(0, 5);
  }

  /**
   * Show Edit pool button for pools that are not archived or complete
   *
   * @returns true if a pool does not have the status of archived or complete
   */
  public isPoolEditable(): boolean {
    return isPoolEditable(this.poolDetails);
  }

  public isPoolArchived(): boolean {
    return isPoolArchived(this.poolDetails);
  }


  public isPoolEnds(): boolean {
    return isPoolEnds(this.poolDetails);
  }


  /**
   * Fetch pool details only
   */
  public refreshPoolDetails(): void {
    const id = this.route.snapshot.params.id ?? '';

    this.pageStatusService.loading();

    this.poolsService.getPoolById({ id: id })
      .pipe(take(1))
      .subscribe((pool) => {
        this.poolDetails = pool;
        this.pageStatusService.success();
      }, () => {
        this.pageStatusService.error();
      });
  }

  /**
   * @param completionCallback callback after subscription completes
   */
  private fetchPageData(completionCallback?: () => void): void {
    const id = this.route.snapshot.params.id ?? '';

    this.pageStatusService.loading(() => {
      this.fetchPageData(completionCallback);
    });

    this.poolsService.getPoolById({ id: id })
      .pipe(switchMap((pool) => {
        return forkJoin([
          of(pool),
          this.queuesService.getQueues(),
          this.getRegistrationsForPool(pool),
        ]);
      }), take(1)).subscribe((responses) => {
        const [pool, queues, registrationResponse] = responses;
        this.callsSucceeded(pool, queues, registrationResponse.registrations, completionCallback);
      }, () => {
        this.pageStatusService.error();
      });
  }

  /**
   * Handles success state of all of the needed calls to fill the detail page
   */
  private callsSucceeded(
    pool: PoolDetails, queues: GetQueuesResponse, registrations: RegistrationForDate[], completionCallback?: () => void
  ): void {
    const { poolStartTime, poolEndTime } = getPoolStartEndTime(pool.schedule.items);
    const currentQueue = queues.find((queue) => queue.id === pool.queueId);
    const exams = pool.exams;

    this.poolDetails = pool;
    this.registrations = registrations;
    this.queueName = currentQueue?.name ?? '';
    this.examsToDisplay = exams.length > 5 ? exams.slice(0, 5) : exams;
    this.poolTimeRange = `${poolStartTime} – ${poolEndTime}`;
    this.formattedStartDate = utc(pool.startDate).format(FULL_MONTH_DAY_YEAR_FORMAT);
    this.formattedEndDate = utc(pool.endDate).format(FULL_MONTH_DAY_YEAR_FORMAT);
    this.formattedAutorelease = this.getAutoReleaseMsg();

    this.pageStatusService.success();

    if (completionCallback) {
      completionCallback();
    }
  }

  /**
   * Fetch registrations based on the pool Id
   */
  private getRegistrationsForPool(pool: PoolDetails): Observable<GetPoolRegistrationsResponse> {
    return this.poolsService.getPoolRegistrations({ id: pool.id });
  }

  public poolUnarchived(): void {
    const loader = this.dialog.open<LoadingDialogComponent, LoadingDialogInput>(LoadingDialogComponent, {
      ...loadingDialogConfig,
      data: {
        title: 'title.loading',
        subtitle: 'subtitle.pleaseWait',
      },
    });

    this.poolService.getPoolNames({ startsWith: this.poolDetails.name.replace(/^\s+/g, '').replace(/\s+$/g, '') })
      .pipe(take(1))
      .subscribe((pools) => {
        const duplicateName = Boolean(pools.find((p) => p.name.toLowerCase() === this.poolDetails.name.toString().replace(/^\s+/g, '').replace(/\s+$/g, '').toLowerCase()));

        if (duplicateName) {
          loader.close();

          let dialogRef = this.dialog.open<ChangePoolNameComponent, changePoolNameModalInputs>(ChangePoolNameComponent, {
            ...changePoolNameModalConfig,
            data: {
              title: this.translatePipe.transform('message.pool.change.poolname.modal'),
              poolName: this.poolDetails.name,
              poolId: this.poolDetails.id
            }
          });
          
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.editPoolService.emitPoolUpdate(result.updatedPoolName, 'unarchived');
            }
          });
        }
        else {
          this.poolService.unarchivedPool({ id: this.poolDetails.id }, null)
            .pipe(take(1))
            .subscribe(() => {
              loader.close();
              this.editPoolService.emitPoolUpdate(this.poolDetails.name, 'unarchived');
            }, (error: unknown) => {
              loader.close();
            });
        }
      }, () => {
      });
  }
}
