<div class="header" >
  <div class="drawer-title-container">
    <h1 mat-dialog-title>
      <ng-content></ng-content>
    </h1>
    <small *ngIf="progress">{{ "label.drawerHeader.step" | translate | async }} {{progress.currentStep}} {{ "label.drawerHeader.of" | translate | async }} {{progress.totalSteps}}</small>
  </div>
  <button
    mat-icon-button
    class="close-button"
    attr.aria-label="{{ 'label.aria.closeDrawer' | translate | async }}"
    (click)="close.emit()"
    [disabled]="disableClose"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-progress-bar *ngIf="progress" mode="determinate" [value]="getProgress()"></mat-progress-bar>
