import { formatDate } from '@angular/common';
import { Queue } from 'api/types';
import { Filter } from 'types/Filter';

export interface PoolsFilters {
  queue: Filter<Queue>;
  startDate: Filter<string>;
}

export type PoolsFiltersValue = Queue | string;

/**
 * @returns poolsFilters the initial state of the pools filters
 */
export function getInitialPoolsFilter(): PoolsFilters {
  return {
    queue: { enabled: true },
    startDate: {
      enabled: true,
      value: formatDate(Date.now(), 'YYYY-MM-dd', 'en-US', 'UTC')
    }
  };
}
