/* eslint-disable no-invalid-this, @typescript-eslint/member-ordering */
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { GetCapacityTemplatesParameters, GetCapacityTemplatesResponse } from 'api/types';
import { CapacityTemplatesService } from './api/capacity-templates.service';
import { PageStatusService } from './status/page-status.service';

export const DEFAULT_VALUES: GetCapacityTemplatesParameters = {
  limit: 10,
  offset: 0,
  sortBy: 'name',
  direction: 'asc',
  status: 'active'
};

/**
 * Stores & updates all data for the templates page
 */
@Injectable({
  providedIn: 'root'
})
export class TemplatePageDataService implements OnDestroy {
  private templateParams = new BehaviorSubject<GetCapacityTemplatesParameters>(DEFAULT_VALUES);

  /**
   * Observable of the params passed to GetCapacityTemplates service
   */
  public templateParams$ = this.templateParams.asObservable();

  /**
   * Observable to list of templates
   */
  public templateResponse$ = new Subject<GetCapacityTemplatesResponse>();

  /**
   * Emits when new template details should be fetched
   */
  private fetchTemplates$ = new Subject<void>()

  /**
   * Completes when service is destroyed
   */
  private destroyed$ = new Subject();

  public constructor(
    private capacityTemplatesService: CapacityTemplatesService,
    private pageStatusService: PageStatusService,
  ) {
    this.fetchTemplates$
      .pipe(takeUntil(this.destroyed$))
      .pipe(switchMap(() => { // switchMap will cancel any in-flight API requests
        return this.fetchTemplates();
      }))
      .subscribe((res) => {
        this.fetchTemplateSuccess(res);
      }, () => {
        this.fetchTemplateError();
      });
  }

  /**
   * Remove any stored subscriptions
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Trigger update of templates with same params
   */
  public updateTemplates(): void {
    this.fetchTemplates$.next();
  }

  /**
   * Updates params observable based on name value pair
   *
   * @param params updated params for get templates call
   */
  public updateTemplatesParams(params: Partial<GetCapacityTemplatesParameters>): void {
    this.templateParams.next({
      ...this.templateParams.getValue(),
      offset: 0,
      ...params
    });

    this.fetchTemplates$.next();
  }

  /**
   * Calls the getCapacityTemplates with current template params
   */
  private fetchTemplates(): Observable<GetCapacityTemplatesResponse> {
    this.pageStatusService.loading(() => {
      this.fetchTemplates$.next();
    });

    return this.capacityTemplatesService.getCapacityTemplates(this.templateParams.getValue());
  }

  /**
   * Fetch templates success handler
   */
  private fetchTemplateSuccess(res: GetCapacityTemplatesResponse): void {
    this.templateResponse$.next(res);
    this.pageStatusService.success();
  }

  /**
   * Fetch templates error handler
   */
  private fetchTemplateError(): void {
    this.pageStatusService.error();
  }
}
