import { Injectable } from '@angular/core';
import appConfig from '../../config.json';

/**
 * Retrieve app config file
 */
@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  /**
   * Application configuration which stores the URL's required for application
   */
  private appConfig: typeof appConfig;

  /**
   * Assign json object to local property
   */
  public constructor() {
    this.appConfig = appConfig;
  }

  /**
   * Returns Capacity microservice base URL
   *
   * @returns url string
   */
  public getCapacityMicroserviceURL(): string {
    return this.appConfig.capacityMicroserviceURL;
  }

  /**
   * Returns Pools microservice base URL
   *
   * @returns url string
   */
  public getPoolsMicroserviceURL(): string {
    return this.appConfig.poolsMicroserviceURL;
  }

  /**
   * @returns suffix for HTML `<title>`.
   */
  public getTitleSuffix(): string {
    return this.appConfig.pageTitleSuffix;
  }
}
