import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isMoment, utc } from 'moment';
import { INVALID_DATE, PAST_DATE } from 'constants/datepicker-errors';

/**
 * Validates that a date is not in the past (in UTC time)
 */
export function pastDateValidator(control: AbstractControl): ValidationErrors | null {
  if (!isMoment(control.value)) {
    return { [ INVALID_DATE ]: control.value };
  }

  if (control.value.utc().isBefore(utc().startOf('d'), 'd')) {
    return { [ PAST_DATE ]: control.value };
  }

  return null;
}
