import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { createQueryString } from 'utils/create-query-string';
import { PoolsMicroservice } from './microservices/pools.microservice';
import { GetPoolConsumptionParameters, GetPoolConsumptionResponse } from 'api/types/endpoints/getPoolConsumption';
import { MatDialog } from '@angular/material/dialog';
import { LoadingDialogComponent, LoadingDialogInput, loadingDialogConfig } from 'components/dialogs/loading-dialog/loading-dialog.component';
import { GetPoolConsumptionByIdQueryParameters, GetPoolConsumptiontByIdResponse } from 'api/types/endpoints/getPoolConsumptionById';
import { UpdatePoolConStatusBody, UpdatePoolConStatusResponse, UpdatePoolConStatusType } from 'api/types/endpoints/updatePoolConsumptionStatus';

/**
 * API service for clients
 */
@Injectable({
  providedIn: 'root'
})
export class PoolConsumptionService {
  private hasError = new BehaviorSubject<{ message: string, type: 'generic' | 'unsaved' | null }>({ message: '', type: null });

  public showServerError$ = this.hasError.asObservable();

  public attemptRetry$ = new Subject();

  /**
   * Keep track of subscriptions to attemptRetry$
   * There should only be one subscription at a time so only the most
   * recent retry should occur
   */
  private retrySubscription?: Subscription;

  /**
   * Default params that can be overridden by input
   */
  private defaultParams: GetPoolConsumptionParameters = {
    limit: 25,
    offset: 0,
    sortBy: 'alertname',
    direction: 'asc'
  }

  public constructor(private api: PoolsMicroservice,
    private dialog: MatDialog) { }

  /**
   * Calls GetPoolConsumption API
   *
   * @param params optional params for get clients call
   * @returns GetPoolConsumptionResponse Observable
   */
  public getPoolConsumption(params?: Partial<GetPoolConsumptionParameters>): Observable<GetPoolConsumptionResponse> {
    const queryString = createQueryString({
      ...this.defaultParams,
      ...params
    });
    const path = `poolconsumption${queryString}`;

    return this.api.get<GetPoolConsumptionResponse>(path);
  }

  public getPoolConsumptionById(params: Partial<GetPoolConsumptionByIdQueryParameters>): Observable<GetPoolConsumptiontByIdResponse> {
    const path = `poolconsumption/${params.id}`;

    return this.api.get<GetPoolConsumptiontByIdResponse>(path);
  }

  public updatePoolConStatus(id: string, status: UpdatePoolConStatusType): Observable<UpdatePoolConStatusResponse> {
    const path = `poolconsumption/${id}/statusupdate`;
    return this.api.patch<UpdatePoolConStatusResponse, UpdatePoolConStatusBody>(path, { status : status });
  }

  public deletePoolConById(params: Partial<GetPoolConsumptionByIdQueryParameters>): Observable<GetPoolConsumptiontByIdResponse> {
    const path = `poolconsumption/${params.id}`;

    return this.api.delete<GetPoolConsumptiontByIdResponse>(path);
  }

  /**
   * Emit attemptRetry$ observable
   */
  public triggerRetry(): void {
    this.attemptRetry$.next();
  }

  /**
   * Cancel any existing subscriptions to attemptRetry$
   * Then set the new one
   *
   * @param newSubscription incoming subscription
   */
  public setRetrySubscription(newSubscription: Subscription): void {
    if (this.retrySubscription) {
      this.retrySubscription.unsubscribe();
    }
    this.retrySubscription = newSubscription;
  }

  public showServerError(errorMessage: string = '', isGenericError?: boolean): void {
    this.hideSaving();
    this.hasError.next({ message: errorMessage, type: isGenericError ? 'generic' : 'unsaved' });
  }

  /**
   * Hides server error
   */
  public hideServerError(): void {
    this.hasError.next({ message: '', type: null });
  }

  /**
   * Shows saving dialog
   */
  public showSaving(): void {
    this.openSavingDialog();
  }

  /**
   * Hides saving dialog
   */
  public hideSaving(): void {
    this.dialog.closeAll();
  }

  /**
   * Shows loading dialog
   */
  public showLoading(): void {
    this.openLoadingDialog();
  }

  /**
   * Hides loading dialog
   */
  public hideLoading(): void {
    this.dialog.closeAll();
  }

  /**
   * Open Saving Dialog
   */
  private openSavingDialog(): void {
    this.dialog.open<LoadingDialogComponent, LoadingDialogInput>(
      LoadingDialogComponent, {
        ...loadingDialogConfig,
        data: {
          title: 'title.saving',
          subtitle: 'subtitle.pleaseWait',
        },
      });
  }

  /**
   * Open Loading Dialog
   */
  private openLoadingDialog(): void {
    this.dialog.open<LoadingDialogComponent, LoadingDialogInput>(
      LoadingDialogComponent, { ...loadingDialogConfig, data: {} });
  }
}
