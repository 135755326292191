import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { CapacityTemplate, DailyCapacityCalculations, Queue } from 'api/types';
import { EXCHANGE_FORMAT } from 'constants/date-formats';
import { Day, daysOfTheWeek, getDayLocaleDisplay } from 'constants/days-of-the-week';
import { CapacityTemplatesService } from 'services/api/capacity-templates.service';
import { CapacityService } from 'services/api/capacity.service';
import { QueuesService } from 'services/api/queues.service';
import { DrawerStatusService } from 'services/status/drawer-status.service';
import { DisplayableServerError } from 'types/DisplayableServerError';
import { DatePickerErrorMatcher } from 'utils/error-matchers/date-picker.error-matcher';
import { SuppressErrorMatcher } from 'utils/error-matchers/suppress.error-matcher';
import { formatQueueDisplay } from 'utils/format-queue';
import { getDisplayableServerError } from 'utils/get-displayable-server-error';
import { chronologicalOrderValidator } from 'utils/validators/chronological-order.validator';
import { pastDateValidator } from 'utils/validators/past-date.validator';
import { applyCapacityDateValidator } from './apply-capacity-date-validator/apply-capacity-date.validator';
import { GetCalculationsErrorHandler } from './get-calculations-error-matcher/get-calculations-error-matcher';
import { HourlyAllocationDialogComponent, hourlyAllocationDialogConfig, HourlyAllocationDialogInputs } from './hourly-allocation-dialog/hourly-allocation-dialog.component';
import { InformationDialogComponent, informationDialogConfig, InformationDialogInput } from 'components/dialogs/information-dialog/information-dialog.component';

/**
 * Edit capacity using an existing template structure & queue
 * Daily allocations are determined by getCalculations API
 */
@Component({
  selector: 'app-apply-capacity-template-form',
  templateUrl: './apply-capacity-template-form.component.html',
  styleUrls: [ './apply-capacity-template-form.component.scss' ],
})
export class ApplyCapacityTemplateFormComponent implements OnDestroy, OnInit {
  /**
   * Event emitted when form status changes
   * True if form is valid
   * False if form is invalid
   */
  @Output() public formStatusChange = new EventEmitter<boolean>();

  /**
   * Success event that is emitted with the template name
   * when a template is successfully applied
   */
  @Output() public success = new EventEmitter<string>();

  /**
   * Used to display the properly formatted queue name
   * for each dropdown option
   */
  public getQueueDisplay = formatQueueDisplay;

  /**
   * Error matcher for date picker components
   */
  public datePickerErrorMatcher = new DatePickerErrorMatcher();

  /**
   * Error matcher for the Total Appointments field,
   * only shows errors when getCalculations call fails.
   */
  public getCalculationsErrorMatcher = new GetCalculationsErrorHandler();

  /**
   * List of available queues
   */
  public queues: Queue[] = [];

  /**
   * List of capacity templates
   */
  public capacityTemplates: CapacityTemplate[] = [];

  /**
   * The appointment allocations for each day of the week
   * with the currently selected template and total appointments value
   */
  public dailyCalculations: DailyCapacityCalculations = [];

  /**
   * The total number of appointment allocations for the week
   */
  public totalForWeek = 0;

  /**
   * List of form field control names
   */
  public form = new UntypedFormGroup({
    template: new UntypedFormControl(null, Validators.required),
    queue: new UntypedFormControl('', Validators.required),
    startDate: new UntypedFormControl('', { validators: [ Validators.required, pastDateValidator ], updateOn: 'blur' }),
    endDate: new UntypedFormControl('', { validators: [ Validators.required, pastDateValidator ], updateOn: 'blur' }),
    totalAppointments: new UntypedFormControl('', {
      validators: [
        Validators.required,
        // eslint-disable-next-line no-invalid-this
        this.getCalculationsErrorMatcher.getCalculationsValidator.bind(this.getCalculationsErrorMatcher)
      ]
    }),
  }, {
    validators: [ chronologicalOrderValidator('startDate', 'endDate'), applyCapacityDateValidator ],
  });

  /**
   * Custom error matcher for required form fields
   * Never show error state
   * Submitting form is disabled until fields are valid
   */
  public suppressErrorState = new SuppressErrorMatcher();

  /**
   * Days of week to list under appointment allocation
   */
  public daysOfWeek: string[];

  /**
   * An error returned from a failed submission
   */
  public displayableServerError: DisplayableServerError | null = null;

  /**
   * For validating appointment calculation
   */
  public appointmentCallculationCalled = false;

  /**
   * Observable that is completed once the component is destroyed
   */
  private destroyed$ = new Subject();

  public constructor(
    private queuesService: QueuesService,
    private capacityTemplatesService: CapacityTemplatesService,
    private capacityService: CapacityService,
    private dialog: MatDialog,
    private drawerStatusService: DrawerStatusService,
  ) {
    // Fetch queues
    this.queuesService
      .getQueues()
      .pipe(take(1))
      .subscribe((queueResponse) => {
        this.queues = queueResponse;
      });

    this.daysOfWeek = daysOfTheWeek.map((day) => {
      return this.getLocalizedDay(day);
    });
  }

  public ngOnInit(): void {
    const { totalAppointments, template } = this.form.controls;

    // Update calculations based on template
    combineLatest([ template.valueChanges ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([ currentTemplate ]) => {
        if (totalAppointments.value) {
          this.drawerStatusService.loading();
        }
        this.calculateAllocations(currentTemplate, totalAppointments.value);
      });

    // based on total appoinment value change disable the save button for daily calculation.
    combineLatest([ totalAppointments.valueChanges ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.appointmentCallculationCalled = false;
        this.formStatusChange.emit(false);
      });

    // Enable save button when form is valid
    this.form.statusChanges
      .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
      .subscribe((status) => {
        if (this.appointmentCallculationCalled) {
          this.formStatusChange.emit(status === 'VALID');
        }
      });
  }

  /**
   * Terminate all subscriptions
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /*
   * Opens hourly dialog to view hourly allocation
   */
  public openHourlyDialog(): void {
    this.dialog.open<HourlyAllocationDialogComponent, HourlyAllocationDialogInputs>(
      HourlyAllocationDialogComponent,
      {
        ...hourlyAllocationDialogConfig,
        data: {
          dailyCalculations: this.dailyCalculations,
        },
      }
    );
  }

  /**
   * Determine whether or not calculations should be displayed based on
   * having dailyCalculations & totalAppointments
   *
   * @returns boolean if total calculations can be shown
   */
  public calculationsDisplayed(): boolean {
    const positiveApptsTotal =
      parseInt(
        this.form.controls.totalAppointments.value,
        10
      ) > 0;
      
    // if totalappointment have null value should empty the dailycalculation
    if (!positiveApptsTotal) {
      this.dailyCalculations = [];
      this.totalForWeek = 0;
    }
    return this.dailyCalculations.length > 0 && positiveApptsTotal;
  }

  /**
   * Creates payload and calls create capacity service
   * On success of service, success event is emitted
   */
  public applyCapacityTemplate(): void {
    let dialogRef = this.dialog.open<InformationDialogComponent, InformationDialogInput>(InformationDialogComponent, {
      ...informationDialogConfig,
      data: {
        title: 'title.pastHourCapacityNotUpdated'
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.drawerStatusService.loading();
        const { template, queue, startDate, endDate, totalAppointments } =
          this.form.value;

        const capacityPayload = {
          templateId: template.id,
          queueId: queue.id,
          startDate: startDate.format(EXCHANGE_FORMAT),
          endDate: endDate.format(EXCHANGE_FORMAT),
          totalCapacity: parseInt(totalAppointments, 10),
        };

        this.capacityService
          .createCapacity(capacityPayload)
          .pipe(take(1))
          .subscribe(() => {
            this.success.emit(template.name);
            this.drawerStatusService.success();
          },
            (error: unknown) => {
              this.displayableServerError = getDisplayableServerError(error);
              this.drawerStatusService.error();
            });
      }
    });
  }

  /**
   * @param day day to localize
   * @returns localized day name
   */
  public getLocalizedDay(day: Day): string {
    return getDayLocaleDisplay(day);
  }

  /**
   * Handler for the refresh button within the total appointments field when getCalculations fails.
   *
   * Used to trigger the same request without having to alter the total appointment field.
   */
  public retryCalculations(): void {
    const { template, totalAppointments } = this.form.value;

    this.calculateAllocations(template, totalAppointments);
  }

  /**
   * daily calculation calculated based on total appointment value change.
   */
  public appointmentCalculateAllocations(): void {
    const { totalAppointments, template } = this.form.controls;
    if (template.value && totalAppointments.value) {
      this.drawerStatusService.loading();
    }
    this.calculateAllocations(template.value, totalAppointments.value);
  }

  /**
   * Manually trigger totalAppointments validator, it doesn't run after the error state changes.
   *
   * Update form value afterwards so it is properly validated with the updated totalAppointment validation.
   */
  private silentlyUpdateAppointmentsValidity(): void {
    // `emitEvent: false` is used to stop any subscriptions from firing
    this.form.controls.totalAppointments.updateValueAndValidity({ emitEvent: false });
    this.form.updateValueAndValidity();
  }

  /**
   * Calculates the daily allocations given the selected template and total appointments value
   *
   * @param template currently selected template
   * @param totalAppointments total number of appointments
   */
  private calculateAllocations(
    template: CapacityTemplate | null,
    totalAppointments: string
  ): void {
    const { queue, startDate, endDate } = this.form.controls;
    this.formStatusChange.emit(false);
    this.getCalculationsErrorMatcher.setError(false);
    const appointments = parseInt(totalAppointments, 10);
    if (template && !isNaN(appointments)) {
      this.capacityTemplatesService
        .getCalculations(template.id, appointments)
        .pipe(take(1))
        .subscribe((res) => {
          this.dailyCalculations = res.dailyCalculations;
          this.totalForWeek = res.dailyCalculations
            .map((item) => item.capacity)
            .reduce((prev, next) => prev + next);
          this.drawerStatusService.success();
          if (queue.valid && startDate.valid && endDate.valid) {
            this.formStatusChange.emit(true);
          }
          this.appointmentCallculationCalled = true;
          this.silentlyUpdateAppointmentsValidity();
        }, () => {
          this.getCalculationsErrorMatcher.setError(true);
          this.dailyCalculations = [];
          this.totalForWeek = 0;

          this.silentlyUpdateAppointmentsValidity();
        });
    } else {
      this.dailyCalculations = [];
      this.totalForWeek = 0;
    }
  }
}
