<vue-page-header appName="{{ 'title.applicationName' | translate | async }}"
  navSection="{{ 'title.outages' | translate | async }}" [enableBack]="true"
  [pageTitle]="'Add Outage'"></vue-page-header>

<vue-section class="components" [loading]="pageLoading">
  <app-error-message *ngIf="status === 'error'" [heading]="displayableServerError?.title"
    [message]="displayableServerError?.message">
  </app-error-message>
  <form id="form" [formGroup]="addOutageForm" (ngSubmit)="validateOutage()" autocomplete="off">
    <div formArrayName="outages" class="form-container">
      <div class="row" *ngFor="let outage of getControls(); let i = index" [formGroupName]="i">
        <div class="form-elements" [ngClass]="{'hr-top': i>0}">
        <vue-text-input label="{{ 'title.outagename' | translate | async }}" formControlName="outageName"
          [required]="true"></vue-text-input>
        <div class="four-columns">
          <vue-datepicker label="{{ 'label.placeholder.startDate' | translate | async }}"
            placeholder="{{ 'placeholder.datepicker' | translate | async }}" formControlName="startDate"
            [required]="true" [errorMatcher]="datePickerErrorMatcher" [minDateIsToday]="true"
            [maxDate]="getControlValue(outage,'endDate')"
            [errorMessage]="getControlError(outage,'startDate') | datepickerError | translate | async"></vue-datepicker>

          <vue-select label="{{ 'label.placeholder.startTime' | translate | async }}" formControlName="startTime"
            [required]="true" [options]="utcStartTimes" [getOptionDisplay]="addUTCToTime.bind(this)"
            [errorMatcher]="suppressErrorState"></vue-select>

          <vue-datepicker label="{{ 'label.placeholder.endDate' | translate | async }}"
            placeholder="{{ 'placeholder.datepicker' | translate | async }}" formControlName="endDate" [required]="true"
            [errorMatcher]="datePickerErrorMatcher" [minDate]="getControlValue(outage,'startDate')"
            [minDateIsToday]="true"
            [errorMessage]="getControlError(outage,'endDate') | datepickerError | translate | async"></vue-datepicker>

          <vue-select label="{{ 'label.placeholder.endTime' | translate | async }}" formControlName="endTime"
            [required]="true" [options]="utcEndTimes" [getOptionDisplay]="addUTCToTime.bind(this)"
            [errorMatcher]="suppressErrorState"></vue-select>
        </div>
      </div>
      <div class="delete-div" [ngClass]="{'realign-btn': i>0}">
        <button mat-icon-button type="button" (click)="removeOutageFormGroup(i,outage)" class="delete-btn" [disabled]="status === 'loading'">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </div>
      <div class="eventButtons">
        <button type="button" class="add-exception-button" (click)="addOutageFormGroup()"
          [disabled]="!formIsValid || status === 'loading'">
          <div class="addition"></div>
          {{ "title.addAnotherOutage" | translate | async }}
        </button>
      </div>
    </div>
  </form>
  <div class="footer-btns">
    <vue-basic-button (clicked)="closeDrawerIfUnchanged()" class="cancel" [disabled]="status === 'loading'">
      {{ "button.cancel" | translate | async }}
    </vue-basic-button>
    <vue-flat-button (clicked)="saveOutage()" [disabled]="!formIsValid" [loading]="status === 'loading'">
      {{ "button.save" | translate | async }}
    </vue-flat-button>
  </div>
</vue-section>