import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetClientsParameters, GetClientsResponse } from 'api/types';
import { createQueryString } from 'utils/create-query-string';
import { PoolsMicroservice } from './microservices/pools.microservice';
import { GetClientMstParameters, GetClientMstResponse } from 'api/types/endpoints/getClientMst';

/**
 * API service for clients
 */
@Injectable({
  providedIn: 'root'
})
export class ClientsService {
  /**
   * Default params that can be overridden by input
   */
  private defaultParams: GetClientsParameters = {
    limit: 25,
    offset: 0,
    sortBy: 'name',
    direction: 'asc'
  }

  public constructor(private api: PoolsMicroservice) { }

  /**
   * Calls GetClients API
   *
   * @param params optional params for get clients call
   * @returns GetClientsResponse Observable
   */
  public getClients(params?: Partial<GetClientsParameters>): Observable<GetClientsResponse> {
    const queryString = createQueryString({
      ...this.defaultParams,
      ...params
    });
    const path = `clients${queryString}`;

    return this.api.get<GetClientsResponse>(path);
  }

  public getClientsMst(params?: Partial<GetClientMstParameters>): Observable<GetClientMstResponse> {
    const queryString = createQueryString({
      ...params
    });
    const path = `clients/names${queryString}`;

    return this.api.get<GetClientMstResponse>(path);
  }
}
