import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AddTemplateState } from 'components/drawers/add-template-drawer/add-template-state/add-template-state.service';
import { TranslationKey } from 'pipes/translate.pipe';
import { TemplatePageDataService } from 'services/template-page-data.service';
import { TemplatePageService } from 'services/template-page.service';
import { VueToastComponent } from 'vue/components/vue-toast/vue-toast.component';

/**
 *  Templates page
 */
@Component({
  templateUrl: './templates-page.component.html',
})
export class TemplatesPageComponent implements OnInit, OnDestroy {
  /**
   * Reference to toast element to show when toast is updated/created
   */
  @ViewChild(VueToastComponent) public toast!: VueToastComponent;

  /**
   * Details of a newly created Template to be celebrated with a toast
   */
  public updatedTemplateName = ''

  /**
   * Translation key for the heading of the template success toast
   * Altered after templateSuccess$ emission
   */
  public toastHeadingKey: TranslationKey = 'title.templateCreated'

  /**
   * Translation key for the message of the template success toast
   * Altered after templateSuccess$ emission
   */
  public toastMessageKey: TranslationKey = 'message.addTemplate.created'

  /**
   * Toggle to show or hide the server error message
   */
  public showServerError: 'generic' | 'unsaved' | null = null;

  /**
   * Completes to terminate subscriptions
   */
  private destroyed$ = new Subject();

  public serverErrorMsg : string = '';

  public constructor(
    private templatesPageDataService: TemplatePageDataService,
    private addTemplateState: AddTemplateState,
    private templatePageService: TemplatePageService,
  ) {}

  /**
   * Fetch templates and subscribe to any successes or errors
   */
  public ngOnInit(): void {
    this.templatesPageDataService.updateTemplates();

    this.templatePageService.showServerError$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((error) => {
        this.showServerError = error.type;
        this.serverErrorMsg = error.message;
      });

    /**
     * Show toast and fetch new template list
     */
    this.addTemplateState.templateSuccess$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(({ templateName, type }) => {
        if (type === 'new') {
          this.toastHeadingKey = 'title.templateCreated';
          this.toastMessageKey = 'message.addTemplate.created';
        } else if (type === 'active') {
          this.toastHeadingKey = 'title.templateActivated';
          this.toastMessageKey = 'message.addTemplate.activated';
        } else {
          this.toastHeadingKey = 'title.templateUpdated';
          this.toastMessageKey = 'message.addTemplate.updated';
        }
        this.updatedTemplateName = templateName;
        this.toast.open();
        this.templatesPageDataService.updateTemplates();
      });
  }

  /**
   * Terminate all subscriptions
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.templatePageService.hideServerError();
  }

  /**
   * Trigger retry through Template Page service
   */
  public triggerRetry(): void {
    this.templatePageService.triggerRetry();
  }
}
