import { CdkPortalOutlet, TemplatePortal } from '@angular/cdk/portal';
import {
  Component,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';

/**
 * Internal component that wraps user-provided popover content.
 */
@Component({
  templateUrl: './vue-popover.component.html',
  styleUrls: [ './vue-popover.component.scss' ]
})
export class VuePopoverComponent {
  @ViewChild(CdkPortalOutlet)
  public portalOutlet!: CdkPortalOutlet;

  public templatePortal!: TemplatePortal;

  public constructor(private viewContainerRef: ViewContainerRef) {}

  public attachTemplateRef<C>(templateRef: TemplateRef<C>): void {
    this.templatePortal = new TemplatePortal(templateRef, this.viewContainerRef);
  }
}
