import { Injectable } from '@angular/core';
import { BaseStatus } from './status.class';

/**
 * Service to track when the state of service calls needed to populate an entire page
 */
@Injectable({
  providedIn: 'root'
})
export class PageStatusService extends BaseStatus {}
