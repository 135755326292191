<h1 mat-dialog-title>
  {{ "title.authenticationProblem" | translate | async }}
</h1>
<p mat-dialog-content>
  {{ "error.message.authentication" | translate | async }}
</p>
<div mat-dialog-actions *ngIf="canLogout">
  <vue-flat-button (clicked)="linkClicked()">
    {{ "button.logIn" | translate | async }}
  </vue-flat-button>
</div>
