/* eslint-disable
  @typescript-eslint/no-use-before-define,
  @typescript-eslint/no-explicit-any,
  @typescript-eslint/explicit-module-boundary-types
*/
import { Component, OnDestroy } from '@angular/core';
import { ControlValueAccessor, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HourlyPoolSchedule, HourlyPoolScheduleItem } from 'api/types';
import { calculateTimeSlots } from 'components/common/pools/utils/calculate-time-slots';
import { getPoolStartEndTime } from 'components/common/pools/utils/get-pool-start-end-time';
import { UTC_TIMES } from 'data/utc-times';
import { AddUTCToTimePipe } from 'pipes/add-utc-to-time.pipe';
import { SuppressErrorMatcher } from 'utils/error-matchers/suppress.error-matcher';
import { removeUTCTimes } from 'utils/time-utils';
import { AddPoolState } from '../add-pool-state/add-pool-state.service';

/**
 *  Form controls for setting pool schedule
 */
@Component({
  selector: 'app-pool-schedule',
  templateUrl: './pool-schedule.component.html',
  styleUrls: [ './pool-schedule.component.scss' ],
  providers: [ {
    provide: NG_VALUE_ACCESSOR, useExisting: PoolScheduleComponent, multi: true
  } ]
})
export class PoolScheduleComponent implements ControlValueAccessor, OnDestroy {
  public suppressErrorState = new SuppressErrorMatcher();
  public utcStartTimes = [ ...UTC_TIMES ];
  public utcEndTimes = [ ...UTC_TIMES ];

  public capacityItems: HourlyPoolScheduleItem[] = [];
  public scheduleForm: UntypedFormGroup;

  // Form functions
  private onChangeCallback: (_: any) => void = noop;
  private onTouchedCallback = noop;

  private destroyed = new Subject();

  public constructor(
    private formBuilder: UntypedFormBuilder,
    private addPoolState: AddPoolState,
    private addUTCToTimePipe: AddUTCToTimePipe
  ) {
    // Build internal form
    this.scheduleForm = formBuilder.group({
      startTime: new UntypedFormControl(''),
      endTime: new UntypedFormControl(''),
      items: formBuilder.array([])
    });

    // Subscribe to changes in hourly capacity to calculate total
    this.scheduleForm.controls.items.valueChanges
      .pipe(takeUntil(this.destroyed))
      .subscribe((items) => {
        this.addPoolState.totalCapacity = (items as number[]).reduce((total, curr) => {
          return total + (curr || 0);
        }, 0);
        this.updateParentForm();
      });

    // Restrict end time based on start time
    this.scheduleForm.controls.startTime.valueChanges
      .pipe(takeUntil(this.destroyed))
      .subscribe((value) => {
        this.utcEndTimes = removeUTCTimes(value, 'before');
        this.updateTimeSlots();
      });

    // Restrict start time based on end time
    this.scheduleForm.controls.endTime.valueChanges
      .pipe(takeUntil(this.destroyed))
      .subscribe((value) => {
        this.utcStartTimes = removeUTCTimes(value, 'after');

        this.updateTimeSlots();
      });
  }

  // Terminate subscriptions
  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public get items(): UntypedFormArray {
    return this.scheduleForm.controls.items as UntypedFormArray;
  }

  public get totalCapacity(): number {
    return this.addPoolState.totalCapacity;
  }

  // Replace old form array with a new one
  public setCapacityFormItems(scheduleItems: HourlyPoolScheduleItem[]): void {
    const { items } = this.scheduleForm.controls;
    (items as UntypedFormArray).clear({ emitEvent: false });
    scheduleItems.forEach((scheduleItem) => {
      (items as UntypedFormArray).push(
        this.formBuilder.control(scheduleItem.capacity),
        { emitEvent: false }
      );
    });

    items.updateValueAndValidity();
  }

  // Get control of item that tracks capacity
  public getItemControl(index: number): UntypedFormControl {
    return (this.scheduleForm.controls.items as UntypedFormArray).at(index) as UntypedFormControl;
  }

  // Handle form change
  public updateParentForm(): void {
    const newSchedule = this.buildSchedule();
    this.onTouchedCallback();
    this.onChangeCallback(newSchedule);
  }

  // Update local form from parent
  public writeValue(initialValue: any): void {
    if (Array.isArray(initialValue.items) && initialValue.items.length) {
      const { startTime, endTime } = this.scheduleForm.controls;
      const { poolStartTime, poolEndTime } = getPoolStartEndTime(initialValue.items);
      startTime.setValue(poolStartTime);
      endTime.setValue(poolEndTime);
      this.setCapacityFormItems(initialValue.items);
      this.capacityItems = initialValue.items;
      this.updateTimeSlots();
    }
  }

  /**
   * Update parent form control has been change
   *
   * @param fn function from parent
   */
  public registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  /**
   * Update parent form control has been touched
   *
   * @param fn function from parent
   */
  public registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  /**
   * @param time time
   * @returns time with (UTC) added
   */
  public addUTCToTime(time: string): string {
    return this.addUTCToTimePipe.transform(time);
  }

  // Builds hourly schedule object
  private buildSchedule(): HourlyPoolSchedule {
    return {
      type: 'hourly',
      items: this.capacityItems.map((item, index) => {
        return {
          startTime: item.startTime,
          endTime: item.endTime,
          capacity: this.getItemControl(index)?.value || 0
        };
      })
    };
  }

  // Recalculate time slots and update form controls
  private updateTimeSlots(): void {
    const { startTime, endTime, items } = this.scheduleForm.controls;

    const timeSlotsWithCapacity = this.capacityItems.map((item, index) => {
      return {
        ...item,
        capacity: items.value[ index ] || 0
      };
    });

    const timeSlots = calculateTimeSlots(startTime.value, endTime.value, timeSlotsWithCapacity);

    this.setCapacityFormItems(timeSlots);
    this.capacityItems = timeSlots;
    this.updateParentForm();
  }
}
