import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Moment, utc } from 'moment';
import { Observable, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { ClientInfo, Exam, GetPoolNameResponse, Queue } from 'api/types';
import { poolReleaseValidator } from 'components/common/pools/pool-release/validator/pool-release.validator';
import { dayNumber } from 'constants/days-of-the-week';
import { TranslatePipe, TranslationKey } from 'pipes/translate.pipe';
import { ClientsService } from 'services/api/clients.service';
import { ExamsService } from 'services/api/exams.service';
import { PoolsService } from 'services/api/pools.service';
import { DrawerStatusService } from 'services/status/drawer-status.service';
import { DatePickerErrorMatcher } from 'utils/error-matchers/date-picker.error-matcher';
import { SuppressErrorMatcher } from 'utils/error-matchers/suppress.error-matcher';
import { formatQueueDisplay } from 'utils/format-queue';
import { INVALID_CHARACTERS_ERROR } from 'utils/validators/invalid-characters.validator';
import { AddPoolState } from '../add-pool-state/add-pool-state.service';
import { PoolNameErrorHandler } from '../pool-name-error-handler/pool-name-error-handler.class';
import { DisplayableServerError } from 'types/DisplayableServerError';
/**
 *  Form for adding a new pool.
 */
@Component({
  selector: 'app-add-pool-form',
  templateUrl: './add-pool-form.component.html',
  styleUrls: [ './add-pool-form.component.scss' ]
})
export class AddPoolFormComponent implements OnInit {
  /**
   * Represents selected occurrence days by their number
   * Ex: sunday -> 0, monday -> 1, etc.
   */
  public selectedDayNumbers: number[] = [];

  /**
   * Custom error matcher for required form fields
   * Never show error state
   * Submitting form is disabled until fields are valid
   */
  public suppressErrorState = new SuppressErrorMatcher();

  /**
   * Error matcher for datepicker components
   */
  public datePickerErrorMatcher = new DatePickerErrorMatcher();

  public getQueueDisplay = formatQueueDisplay;

  /**
   * Terminate all subscriptions on destroy
   */
  private destroyed: Subject<boolean> = new Subject<boolean>();

  /**
   * Pool release validator that is applied at the form level.
   *
   * If control names for `poolRelease` & `endDate` change they should be updated here as well.
   */
  private poolReleaseValidator = poolReleaseValidator('poolRelease', 'endDate');

  public translations: {[key: string]: string} = {};

  public constructor(
    private getClientsService: ClientsService,
    private getExamsService: ExamsService,
    private addPoolState: AddPoolState,
    private poolService: PoolsService,
    private drawerStatusService: DrawerStatusService,
    private translatePipe: TranslatePipe
  ) {
    this.translatePipe.loadTranslations(['error.message.invalidclientexammapping', 'error.title.invalidclientexammapping', 'error.title.invalidDateExceptions', 'error.message.invalidDateExceptions', 'error.title.invalidDateAdditions', 'error.message.invalidDateAdditions', 'error.title.outOfRangeDateExceptions', 'error.message.outOfRangeDateExceptions', 'error.title.outOfRangeDateAdditions', 'error.message.outOfRangeDateAdditions'])
      .pipe(take(1))
      .subscribe((translations) => {
        this.translations = translations;
      });
  }

  /**
   * Setup subscriptions
   */
  public ngOnInit(): void {
    const { examIds, clientIds, daysOfWeek, poolRelease, daysOfWeekWithRestriction } = this.addPoolForm.controls;

    // Enable/Disable exam ids based on if a client is selected
    clientIds.valueChanges.pipe(
      takeUntil(this.destroyed)
    ).subscribe((selectedIds) => {
      if (selectedIds.length) {
        examIds.enable();

        selectedIds.forEach((client: ClientInfo) => {
          examIds.value?.forEach((exam: Exam) => {
            if (exam.isValid && selectedIds.findIndex((i: any) => i.id === exam.clientId) < 0) {
              exam.isValid = false;
            }
            if (exam.clientId == client.id) {
              exam.isValid = true;
            }
          });
        });
      } else {
        examIds.value?.forEach((exam: Exam) => {
          exam.isValid = false;
        });
      }
    });

    // Set selectedDayNumbers when daysOfWeek gets set
    daysOfWeek.valueChanges.pipe(
      takeUntil(this.destroyed)
    ).subscribe((selectedDays) => {
      this.selectedDayNumbers = selectedDays.map((day: string) => {
        return dayNumber[ day ];
      });
    });

    daysOfWeekWithRestriction.valueChanges.pipe(
      takeUntil(this.destroyed)
    ).subscribe((restrictedDays) => {
      //console.log(restrictedDays);
    });

    // Change validation based on release selection
    poolRelease.valueChanges.pipe(
      takeUntil(this.destroyed)
    ).subscribe((release) => {
      if (release === null) {
        // remove pool release validator if a pool isn't auto-released
        this.addPoolForm.removeValidators(this.poolReleaseValidator);
        this.addPoolForm.updateValueAndValidity({ emitEvent: false });
      } else if (!this.addPoolForm.hasValidator(this.poolReleaseValidator)) {
        // add pool release validator if a pool is auto released
        this.addPoolForm.addValidators(this.poolReleaseValidator);
        this.addPoolForm.updateValueAndValidity({ emitEvent: false });
      }
    });

    // Trigger value change for side effects of initial values
    clientIds.updateValueAndValidity();
    daysOfWeek.updateValueAndValidity();
  }

  /**
   * Terminate all subscriptions
   */
  public ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  /**
   * @returns addPoolState.addPoolForm
   */
  public get addPoolForm(): UntypedFormGroup {
    return this.addPoolState.addPoolForm;
  }

  /**
   * @returns addPoolState.poolNameErrorHandler
   */
  public get poolNameErrorMatcher(): PoolNameErrorHandler {
    return this.addPoolState.poolNameErrorHandler;
  }

  /**
   * @returns addPoolState.queues
   */
  public get queues(): Queue[] {
    return this.addPoolState.queues;
  }

  /**
   * Get name from respective object
   *
   * @param obj Exam | ClientInfo
   * @returns object name property
   */
  public getClientName(obj: ClientInfo): string {
    return obj.name;
  }

  public getExamName(obj: Exam): string {
    return obj.clientCode + ': ' + obj.name;
  }

  public getClientIsValidFlag(obj: ClientInfo): boolean {
    return true;
  }

  public getExamIsValidFlag(obj: Exam): boolean {
    if(obj.isValid == undefined) {
      return obj.isValid = true;
    }
    return obj.isValid;
  }

  /**
   * Get id from respective object
   *
   * @param obj Individual exam
   * @returns object id property
   */
  public getId(obj: Exam | Queue | ClientInfo): string {
    return obj.id;
  }

  /**
   * Passed to autocomplete to retrieve list of exams
   *
   * @returns Observable to getExams call
   */
  public getExams(startsWith?: string): Observable<Exam[]> {
    const { clientIds } = this.addPoolForm.controls;
    return this.getExamsService.getExams({
      startsWith,
      clientIds: clientIds.value.map((client: ClientInfo) => client.id)
    });
  }

  /**
   * Passed to autocomplete to retrieve list of clients
   *
   * @param searchTerm optional search term from autocomplete
   * @returns Observable to getClients call
   */
  public getClients(searchTerm = ''): Observable<ClientInfo[]> {
    return this.getClientsService.getClientsMst({ searchTerm }).pipe(map((value) => {
      return value;
    }));
  }

  /**
   * Returns the translation key for the pool name based on the error
   */
  public poolNameErrorMessageKey(): TranslationKey {
    if (this.addPoolForm.controls.poolName.hasError(INVALID_CHARACTERS_ERROR)) {
      return 'error.invalidCharacter';
    }
    return 'error.message.poolNameAlreadyExists';
  }

  /**
   * Disables dates that haven't been selected in "occurs on" field
   * Opposite of filterDatesForExceptions
   *
   * @param date from date picker
   * @returns true if date should be selectable
   */
  public filterDatesForExceptions(date: Moment): boolean {
    return this.selectedDayNumbers.includes(date.day());
  }

  /**
   * Enables dates that haven't been selected in "occurs on" field
   * Opposite of filterDatesForExceptions
   *
   * @param date from date picker
   * @returns true if date should be selectable
   */
  public filterDatesForAdditions(date: Moment): boolean {
    return !this.selectedDayNumbers.includes(date.day());
  }

  /**
   * Validate that pool name is unique
   */
  public validatePool(): void {
    // Mark form as pristine, so we can check user interaction in error state
    this.addPoolForm.markAsPristine();
    const { poolName } = this.addPoolForm.value;
    this.drawerStatusService.loading();

    this.poolService.getPoolNames({ startsWith: poolName.replace(/^\s+/g, '').replace(/\s+$/g, '') })
      .pipe(take(1))
      .subscribe((pools) => {
        this.checkPoolNames(pools);
      }, () => {
        this.drawerStatusService.error();
      });
  }

  /**
   * Checks to see if an existing name for a pool is the same as new pool name
   *
   * @param pools list of pools from validatePool()
   */
  private checkPoolNames(pools: GetPoolNameResponse): void {
    const { poolName, clientIds, examIds, dateAdditions, dateExceptions } = this.addPoolForm.controls;
    const name = poolName.value;

    // Check for duplicate name
    const duplicateName = Boolean(pools.find((p) => p.name.toLowerCase() === name.toString().replace(/^\s+/g, '').replace(/\s+$/g, '').toLowerCase()));

    // Update error state
    this.poolNameErrorMatcher.setDuplicateNameError(duplicateName);
    poolName.updateValueAndValidity();

    if (!duplicateName) {
      this.checkPoolValidations();
    } else {
      this.drawerStatusService.reset();
    }
  }

  private checkPoolValidations() {
    const { clientIds, examIds, dateAdditions, dateExceptions, startDate, endDate } = this.addPoolForm.controls;

    var isValidClientExamMapping = true;
    examIds.value?.forEach((exam: Exam) => {
      if (clientIds.value?.findIndex((i: any) => i.id === exam.clientId) < 0) {
        isValidClientExamMapping = false;
      }
    });

    if (!isValidClientExamMapping) {
      var errorObj: DisplayableServerError = { title: this.translations['error.title.invalidclientexammapping'], message: this.translations['error.message.invalidclientexammapping'] };
      this.addPoolState.displayableServerError = errorObj;
      this.drawerStatusService.error();
      return;
    }

    var isValidDateException = true;
    var isDateOutOfRangeDateException = false;
    dateExceptions.value?.forEach((date: string) => {
      if (!this.filterDatesForExceptions(utc(date))) {
        isValidDateException = false;
      }

      if (utc(date) < startDate.value || utc(date) > endDate.value) {
        isDateOutOfRangeDateException = true;
      }
    });

    if (isDateOutOfRangeDateException) {
      var errorObj: DisplayableServerError = { title: this.translations['error.title.outOfRangeDateExceptions'], message: this.translations['error.message.outOfRangeDateExceptions'] };
      this.addPoolState.displayableServerError = errorObj;
      this.drawerStatusService.error();
      return;
    }

    if (!isValidDateException) {
      var errorObj: DisplayableServerError = { title: this.translations['error.title.invalidDateExceptions'], message: this.translations['error.message.invalidDateExceptions'] };
      this.addPoolState.displayableServerError = errorObj;
      this.drawerStatusService.error();
      return;
    }

    var isValidDateAddition = true;
    var isDateOutOfRangeDateAddition = false;
    dateAdditions.value?.forEach((date: string) => {
      if (!this.filterDatesForAdditions(utc(date))) {
        isValidDateAddition = false;
      }

      if (utc(date) < startDate.value || utc(date) > endDate.value) {
        isDateOutOfRangeDateAddition = true;
      }
    });

    if (isDateOutOfRangeDateAddition) {
      var errorObj: DisplayableServerError = { title: this.translations['error.title.outOfRangeDateAdditions'], message: this.translations['error.message.outOfRangeDateAdditions'] };
      this.addPoolState.displayableServerError = errorObj;
      this.drawerStatusService.error();
      return;
    }

    if (!isValidDateAddition) {
      var errorObj: DisplayableServerError = { title: this.translations['error.title.invalidDateAdditions'], message: this.translations['error.message.invalidDateAdditions'] };
      this.addPoolState.displayableServerError = errorObj;
      this.drawerStatusService.error();
      return;
    }

    this.addPoolState.displayableServerError = null;
    // Process to fetch registrations for pool
    this.addPoolState.getRegistrations();
  }
}
