import { Injectable } from '@angular/core';
import { GetAlertsQueryParameters, GetAlertsResponse } from 'api/types/endpoints/getAlerts';
import { PoolsMicroservice } from './microservices/pools.microservice';
import { Observable, Subject } from 'rxjs';
import { createQueryString } from 'utils/create-query-string';
import { GetAlertByIdQueryParameters, GetAlertByIdResponse } from 'api/types/endpoints/getAlertById';
import { GetAlertCommentsByIdQueryParameters, GetAlertCommentsByIdResponse } from 'api/types/endpoints/getAlertCommentById';

@Injectable({
  providedIn: 'root',
})
export class AlertsService {
  public alertsSuccess = new Subject<{configurationId: string,configurationName: string, type: 'activate' | 'deactivate' | 'delete'}>();
  alertsSuccess$ = this.alertsSuccess.asObservable();
  /**
   * Default params that can be overridden by input
   */
  private defaultParams: GetAlertsQueryParameters = {
    limit: 10,
    offset: 0,
    sortBy: 'priority',
    direction: 'asc',
    status: 'all',
    type: 'all'
  };

  public constructor(private api: PoolsMicroservice) {}

  /**
   * Calls GetOutage API
   *
   * @param params optional params for get Outage call
   * @returns GetOutageResponse Observable
   */
  public getAlerts(
    params?: Partial<GetAlertsQueryParameters>
  ): Observable<GetAlertsResponse> {
    const queryString = createQueryString({
      ...this.defaultParams,
      ...params,
    });
    const path = `alerts${queryString}`;
    return this.api.get<GetAlertsResponse>(path);
  }

  public getAlertsById(params: Partial<GetAlertByIdQueryParameters>): Observable<GetAlertByIdResponse> {
    const path = `alerts/${params.id}`;

    return this.api.get<GetAlertByIdResponse>(path);
  }

  public getAlertsCommentsById(params: Partial<GetAlertCommentsByIdQueryParameters>): Observable<GetAlertCommentsByIdResponse> {
    const path = `alerts/comments/${params.id}`;

    return this.api.get<GetAlertCommentsByIdResponse>(path);
  }
}
