import { ChartType } from 'chart.js';

// The subset of chart types that many of our components expect
export type SupportedChartType = Extract<ChartType, 'line' | 'bar'>

/**
 * A type guard to narrow general ChartTypes to our supported list
 *
 * @param type the ChartType to check
 * @returns boolean whether the type is supported
 */
export function isSupportedChartType(type?: ChartType): type is SupportedChartType {
  return [ 'line', 'bar' ].includes(type as SupportedChartType);
}
