import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslatePipe } from 'pipes/translate.pipe';
import { SynchedScrollService } from 'services/synched-scroll.service';
import { Direction } from 'types/Direction';

/**
 * Specialized button that displays an arrow and triggers scroll events via SynchedScrollService
 *
 * Since SynchedScrollButtons can be a child of a component that uses `onPush` change detection,
 * we can miss changes unexpectedly.
 * For better reliability, we manage our own `onPush` change detection, trigger by the `enabled` flag.
 */
@Component({
  selector: 'app-synched-scroll-button',
  templateUrl: './synched-scroll-button.component.html',
  styleUrls: [ './synched-scroll-button.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SynchedScrollButtonComponent implements AfterContentInit {
  /**
   * Direction of scroll button
   */
  @Input() public direction: Direction = 'backward';

  /**
   * If scroll button is enabled
   */
  public enabled = true;

  /**
   * Terminate subscriptions
   */
  private destroyed: Subject<boolean> = new Subject<boolean>();

  public constructor(
    private scrollService: SynchedScrollService,
    private translatePipe: TranslatePipe,
    private cdr: ChangeDetectorRef
  ) {}

  /**
   * Set up observable for scrolling backward or forward
   */
  public ngAfterContentInit(): void {
    // Listen to service to determine whether this button should be enabled
    const enabledObservable$ = this.direction === 'backward' ?
      this.scrollService.backwardScrollEnabled$ :
      this.scrollService.forwardScrollEnabled$;

    enabledObservable$
      .pipe(takeUntil(this.destroyed))
      .subscribe((enabled) => {
        this.enabled = enabled;

        // Trigger change detection - see component level comment
        this.cdr.detectChanges();
      });
  }

  /**
   * Handle click event for scrolling
   */
  public handleClick(): void {
    this.scrollService.scroll(this.direction);
  }

  /**
   * Terminate subscription
   */
  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  /**
   * @returns observable for translated aria label
   */
  public getAriaLabel(): Observable<string> {
    if (this.direction === 'backward') {
      return this.translatePipe.transform('label.aria.scroll.backward');
    }
    return this.translatePipe.transform('label.aria.scroll.forward');
  }
}
