import { AfterContentInit, ContentChild, Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { SortableTableHeaderComponent } from 'components/tables/utils/sortable-table-header/sortable-table-header.component';

/**
 * Handles `aria-sort` attribute for a table heading element
 *
 * Should be used with `app-sortable-table-header` as a child, so sorting attributes stay in sync.
 */
@Directive({
  selector: '[tableHeaderAriaSort]'
})
export class TableHeaderAriaSortDirective implements OnChanges, AfterContentInit {
  /**
   * Configuration object for the sorting details
   * - sortDirection determines which direction of sorting is applied
   * - isSorted dictates whether `aria-sort` is applied to the heading element
   */
  @Input() public tableHeaderAriaSort!: {sortDirection: SortDirection, isSorted: boolean};

  /**
   * Reference to the child `SortableTableHeaderComponent`,
   * used to keep properties in sync between `th` and `app-sortable-table-header
   */
  @ContentChild(SortableTableHeaderComponent) public sortableHeader?: SortableTableHeaderComponent;

  /**
   * @param elRef reference to the element that directive was placed on
   */
  public constructor(private elRef: ElementRef) { }

  /**
   * Set initial values, once child content has initialized
   */
  public ngAfterContentInit(): void {
    const { isSorted, sortDirection } = this.tableHeaderAriaSort;
    this.updateHostElement(isSorted, sortDirection);

    // Warn user if a SortableTableHeaderComponent cannot be found
    if (!this.sortableHeader) {
      // eslint-disable-next-line no-console
      console.warn('`TableHeaderAriaSortDirective` does not have a `SortableTableHeaderComponent` child!');
    }
  }

  /**
   * Update host element with sorting changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if ('tableHeaderAriaSort' in changes) {
      const { isSorted, sortDirection } = changes.tableHeaderAriaSort.currentValue;
      this.updateHostElement(isSorted, sortDirection);
    }
  }

  /**
   * Update host `th` element & child `SortableTableHeaderComponent` with sorting attributes
   */
  private updateHostElement(isSorted: boolean, direction: SortDirection): void {
    // only set `aria-sort` if the column is currently sorted, otherwise remove it
    if (isSorted) {
      const ariaSort = direction === 'asc' ? 'ascending' : 'descending';
      this.elRef.nativeElement.setAttribute('aria-sort', ariaSort);
    } else {
      this.elRef.nativeElement.removeAttribute('aria-sort');
    }

    if (this.sortableHeader) {
      this.sortableHeader.isSorted = isSorted;
      this.sortableHeader.sortDirection = direction;
    }
  }
}
