import { Component, ElementRef, Input, TemplateRef, ViewChild } from '@angular/core';
import { utc } from 'moment';
import { take } from 'rxjs/operators';
import { calculateUtilizationPercentage } from 'components/common/pools/utils/calculate-utilization-percentage';
import { ABBREV_MONTH_FORMAT, EXCHANGE_FORMAT } from 'constants/date-formats';
import { TranslatePipe } from 'pipes/translate.pipe';
import { VuePopoverService } from 'vue/components/vue-popover-module/vue-popover.service';
import { DailyMetricItem } from '../appointments-chart-monthly.component';

/**
 *  Component for displaying individual items in the monthly appointment calendar
 */
@Component({
  selector: 'app-calendar-item',
  templateUrl: './calendar-item.component.html',
  styleUrls: [ './calendar-item.component.scss' ]
})

export class CalendarItemComponent {
  /**
   * Shows weekly summary item rather than daily
   */
  @Input() public isWeek = false;

  /**
   * Metric details for the individual day
   */
  @Input() public metricItem: DailyMetricItem = {
    capacity: 0,
    timestamp: '0:00',
    registrations: 0,
    inCurrentMonth: true,
  }

  @ViewChild('dailyPopoverTemplate') public popoverTemplate!: TemplateRef<unknown>;

  /**
   * Date title to display
   */
  public titleDisplay = ''

  /**
   * Utilization calculation
   */
  public utilization = ''

  /**
   * Boolean to toggle outside of month related styles
   */
  public isOutsideMonth = false

  /**
   * Is the current item's timestamp today's date
   */
  public isTodaysDate = false

  /**
   * Current day formatted as a date for the popover content
   */
  public popoverDate = '';

  /**
   * Label for the calendar item, read out by screen readers instead of `titleDisplay`
   */
  public screenReaderLabel = '';

  public constructor(
    private popoverService: VuePopoverService,
    private hostElement: ElementRef,
    private translatePipe: TranslatePipe,
  ) {}

  public ngOnInit(): void {
    const { timestamp, registrations, capacity } = this.metricItem;
    const itemMoment = utc(timestamp);
    const utilizationPercentage = calculateUtilizationPercentage(registrations, capacity || 0);

    this.popoverDate = itemMoment.format(EXCHANGE_FORMAT);
    this.utilization = utilizationPercentage.toFixed(2) + '%';

    this.screenReaderLabel = `${itemMoment.format(ABBREV_MONTH_FORMAT)} ${itemMoment.date()}`;

    // Set week class & display
    if (this.isWeek) {
      this.translatePipe.transform('title.weekOf', itemMoment.week().toString())
        .pipe(take(1))
        .subscribe((titleDisplay) => {
          this.titleDisplay = titleDisplay;
          this.screenReaderLabel = titleDisplay;
        });
      return;
    }

    if (!this.metricItem.inCurrentMonth) {
      // If an item is not part of the currently selected month, add the month abbreviation to it. Ex. Apr 1, Feb 28
      const itemDay = itemMoment.date();
      const itemMonthEnd = itemMoment.clone().endOf('month').date();
      const isFirstDay = itemDay === 1;
      const isLastDay = itemDay === itemMonthEnd;

      this.titleDisplay = isFirstDay || isLastDay ?
        `${itemMoment.format(ABBREV_MONTH_FORMAT)} ${itemDay}` :
        String(itemDay);
      this.isOutsideMonth = true;
    } else {
      this.titleDisplay = itemMoment.format('D');
      this.isTodaysDate = itemMoment.isSame(utc(), 'day');
    }
  }

  /**
   * Closes any existing popovers and opens a new one
   */
  public openPopover(): void {
    this.popoverService.closeAll();
    this.popoverService.open(this.popoverTemplate, this.hostElement);
  }
}
