<button
  mat-button
  vueTableMenuOpen
  vueOverlayOpen
  class="menu-trigger"
  [attr.aria-label]="'label.poolOccurrence.actionsMenu'| translate:pool.name | async"
  [matMenuTriggerFor]="menu"
>
  <mat-icon>more_horiz</mat-icon>
</button>
<mat-menu #menu="matMenu" class="occurrence-menu">
  <button mat-menu-item *ngIf="!pool.isReleased && isOverride && !occurrenceDateHasPassed" (click)="applyPoolSettings()">{{ "label.poolOccurrence.applypoolsettings" | translate | async }}</button>
  
  <button
    mat-menu-item
    *ngIf="!pool.isReleased && !occurrenceDateHasPassed"
    (click)="openOccurrenceEdit()"
  >
    {{ "label.poolOccurrence.edit" | translate | async }}
  </button>
  <button
    *ngIf="!pool.isReleased && !occurrenceDateHasPassed"
    mat-menu-item
    (click)="updateOccurrenceRelease(true)"
  >
    {{ "label.poolOccurrence.release" | translate | async }}
  </button>
  <button
    *ngIf="pool.isReleased && !occurrenceDateHasPassed"
    mat-menu-item
    (click)="updateOccurrenceRelease(false)"
  >
    {{ "label.poolOccurrence.unrelease" | translate | async }}
  </button>
  <a
    *ngIf="!hideViewDetails"
    mat-menu-item
    routerLink="{{pool.id}}"
    routerLinkActive="active"
  >
    {{ "label.poolOccurrence.viewPoolDetails" | translate | async }}
  </a>
  <button
    *ngIf="!occurrenceDateHasPassed"
    mat-menu-item
    (click)="openRemoveConfirmDialog()"
  >
    {{ "button.remove" | translate | async }}
  </button>
</mat-menu>
<vue-toast icon="success">
  <span toast-body>{{ "label.poolOccurrence.toast" | translate | async }}</span>
</vue-toast>
