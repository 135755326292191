import { Component, OnDestroy } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { SortDirection } from '@angular/material/sort';
import { CapacityTemplate } from 'api/types/endpoints/getCapacityTemplates';
import { utc } from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GetCapacityTemplatesParameters, GetCapacityTemplatesSortBy } from 'api/types';
import { DISPLAY_DAY_FORMAT } from 'constants/date-formats';
import { PageStatusService } from 'services/status/page-status.service';
import { DEFAULT_VALUES, TemplatePageDataService } from 'services/template-page-data.service';
import { LOADING } from 'types/RequestStatus';
import { PaginatorBase } from '../utils/paginator-base.class';

/**
 * Templates table
 */
@Component({
  selector: 'app-templates-table',
  templateUrl: './templates-table.component.html',
  styleUrls: [ './templates-table.component.scss' ],
})
export class TemplatesTableComponent extends PaginatorBase implements OnDestroy {
  /**
   * Table properties
   */
  public tableData: CapacityTemplate[] = [];
  public tableColumns = [ 'name', 'created-at', 'created-by', 'menu' ];
  public sortBy: GetCapacityTemplatesSortBy = DEFAULT_VALUES.sortBy;
  public sortDirection: SortDirection = DEFAULT_VALUES.direction;

  /**
   * Index variable to track which row should show highlighted background
   */
  public highlightedRow = -1;

  /**
   * Display the loading state
   */
  public loading = false;

  /**
   * Terminate all subscriptions when completed
   */
  private destroyed$ = new Subject();

  public constructor(
    private templatePageDataService: TemplatePageDataService,
    private pageStatusService: PageStatusService,
  ) {
    super(DEFAULT_VALUES);

    // Get Pool Data from service
    this.templatePageDataService.templateResponse$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        const { items, total, offset, limit } = res;

        this.highlightedRow = -1;
        this.tableData = items;
        this.updatePaginator({ total, offset, limit });
      });

    // Update columns based on params
    this.templatePageDataService.templateParams$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((params) => {
        const { sortBy, direction } = params;

        this.sortBy = sortBy;
        this.sortDirection = direction;
      });

    /**
     * Subscribe to page status to determine loading state
     */
    this.pageStatusService.status$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((status) => {
        this.loading = status === LOADING;
      });
  }

  /**
   * Terminate all subscriptions
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Update filter service with new params
   *
   * @param col column to sort
   */
  public sort(col: GetCapacityTemplatesSortBy): void {
    const direction = this.sortDirection === 'asc' ? 'desc' : 'asc';
    const newParams: Partial<GetCapacityTemplatesParameters> = {
      sortBy: col,
      direction: direction
    };
    this.templatePageDataService.updateTemplatesParams(newParams);
  }

  /**
   * Refresh data when a template has been archived or activated
   */
  public templateStatusUpdated(): void {
    this.templatePageDataService.updateTemplates();
  }

  /**
   * Update template service with new offsets
   *
   * @param date template created date in YYYY-MM-DD format
   * @returns date in localized display format
   */
  public formatCreatedAtDate(date: string | null): string {
    return date ? utc(date).format(DISPLAY_DAY_FORMAT) : '';
  }

  /**
   * Generate table date from templates response
   *
   * @param event event from paginator
   */
  public paginatorChange(event: PageEvent): void {
    const newParams: Partial<GetCapacityTemplatesParameters> = {
      offset: event.pageSize * event.pageIndex,
      limit: event.pageSize
    };
    this.templatePageDataService.updateTemplatesParams(newParams);
  }
}
