<button
  mat-button
  type="button"
  class="synched-scroll-button {{direction}}"
  attr.aria-label="{{ getAriaLabel() | async }}"
  title="{{ getAriaLabel() | async }}"
  [disabled]="!enabled"
  (click)="handleClick()"
>
  <vue-arrow [direction]="direction"></vue-arrow>
  <span class="shadow"></span>
</button>
