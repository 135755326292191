import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddPoolState } from 'components/drawers/add-pool-drawer/add-pool-state/add-pool-state.service';
import { PoolNameErrorHandler } from 'components/drawers/add-pool-drawer/pool-name-error-handler/pool-name-error-handler.class';
import { TranslationKey } from 'pipes/translate.pipe';
import { AddAlertPoolDataService } from 'services/add-alert-pool-data.service';
import { INVALID_CHARACTERS_ERROR, invalidCharactersValidator } from 'utils/validators/invalid-characters.validator';

@Component({
  selector: 'app-copy-pool-consumption-form',
  templateUrl: './copy-pool-consumption-form.component.html',
  styleUrls: ['./copy-pool-consumption-form.component.scss']
})
export class CopyPoolConsumptionFormComponent implements OnInit {
  @Input() copyAlertFormData:any
  checkformValidation:any;
  subscribeData: any = null
  copyPoolconsumptionform!: FormGroup
  @Output() getAlertCOnsumption = new EventEmitter()
  @Output() getformStatus = new EventEmitter()
  constructor(public fb: FormBuilder, private addAlertConsumptionCreate: AddAlertPoolDataService,private addPoolState: AddPoolState) { }
  ngOnInit(): void {
    this.getformStatus.emit(false)

    this.copyPoolconsumptionform = this.fb.group({
      getDatafromAlert: ['', [Validators.required, invalidCharactersValidator]],
      search_pool: ['', [Validators.required]],
      Threshold: ['', [Validators.required]],
      run_Frequency: ['', [Validators.required]],
    });
    this.copyPoolconsumptionform.controls.getDatafromAlert.patchValue("Copy"+this.copyAlertFormData.Data.name);
    this.copyPoolconsumptionform.valueChanges.subscribe((ele: any) => {
      if (this.copyPoolconsumptionform.get('getDatafromAlert')?.valid == true && this.copyPoolconsumptionform.get('search_pool')?.value?.status == true && this.copyPoolconsumptionform.get('Threshold')?.value?.status
        && this.copyPoolconsumptionform.get('run_Frequency')?.value?.status == true) {
        this.getAlertCOnsumption.emit(this.copyPoolconsumptionform.getRawValue());
      }
      else {
        this.getAlertCOnsumption.emit(null);
      } });
  }

  ngAfterViewInit(): void {
    this.checkformValidation=this.copyPoolconsumptionform.getRawValue();
    this.copyPoolconsumptionform.valueChanges.subscribe((ele: any) => {
        if (JSON.stringify(this.checkformValidation) !== JSON.stringify(this.copyPoolconsumptionform.getRawValue())) {
          this.getformStatus.emit(true);
        }else {
          this.getformStatus.emit(false); 
        }});
  }

  public get poolNameErrorMatcher(): PoolNameErrorHandler {
    return this.addPoolState.poolNameErrorHandler;
  }

  public poolNameErrorMessageKey(): TranslationKey {
    return 'error.invalidCharacter';
  }

  getrawFormData() {
    return this.copyPoolconsumptionform.getRawValue();
  }

  markAllAsTouched() {
    Object.keys(this.copyPoolconsumptionform.controls).forEach((field) => {
      const control = this.copyPoolconsumptionform.get(field);
      control?.markAsDirty({ onlySelf: true });
    });
  }
}
