<h1 mat-dialog-title>{{data.title | async}}</h1>
<p mat-dialog-content>
  {{data.message | async}}
</p>
<div mat-dialog-actions>
  <vue-basic-button (clicked)="closeDialog()">
    {{ "button.cancel" | translate | async }}
  </vue-basic-button>
  <vue-flat-button (clicked)="action()">
    <span *ngIf="data.confirmationType == 'deactivate'">
      {{ "button.deactivate" | translate | async }}
    </span>
    <span *ngIf="data.confirmationType == 'delete'">
      {{ "button.delete" | translate | async }}
    </span>
  </vue-flat-button>
</div>