import { Moment } from 'moment';
import { PoolDetails } from 'api/types';
import { EXCHANGE_FORMAT, FULL_DAY_FORMAT } from 'constants/date-formats';

type NeededDateProperties = Pick<PoolDetails, 'dateExceptions' | 'dateAdditions'> & {daysOfWeek: string[]}

/**
 * Determines if the passed date is a valid based on the pool criteria
 *
 * @param poolDetails details of the pool for valid date information
 * @param date date to be checked
 * @returns true if date is valid
 */
export function isValidPoolDate<P extends NeededDateProperties>(
  poolDetails: P, date: Moment
): boolean {
  // Set moment locale to english for comparison to server data points which are always in english
  const englishDate = date.clone().locale('en');
  const { daysOfWeek, dateExceptions, dateAdditions } = poolDetails;

  const isIncludedDay = daysOfWeek.includes(englishDate.format(FULL_DAY_FORMAT).toLowerCase());
  const isAnException = (dateExceptions || []).includes(englishDate.format(EXCHANGE_FORMAT));
  const isAnAddition = (dateAdditions || []).includes(englishDate.format(EXCHANGE_FORMAT));

  return !isAnException && (isIncludedDay || isAnAddition);
}
