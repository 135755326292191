import { formatDate } from '@angular/common';
import { EXCHANGE_FORMAT } from 'constants/date-formats';
import { INTERVAL_15MIN, INTERVAL_1HR, INTERVAL_6HR, INTERVAL_Monthly, INTERVAL_Yearly } from 'constants/intervals';
import { utc } from 'moment';
import { AppointmentFilters, Timescale } from 'types/AppointmentFilters';

/**
 * Configs represent a subset of filter properties to be applied by default when a given timescale is selected.
 * Conversely, properties absent from the config, like, queue.value, are provided by the filter component.
 *
 * @returns appointmentFilters the filter configuration for "daily" mode
 */
export function dailyFilterConfig(): AppointmentFilters {
  return {
    queue: {
      enabled: true,
    },
    timescale: {
      enabled: true,
      value: Timescale.daily,
      options: [ Timescale.daily, Timescale.weekly, Timescale.monthly ],
    },
    interval: {
      enabled: true,
      value: INTERVAL_15MIN,
      options: [ INTERVAL_15MIN, INTERVAL_1HR ],
    },
    startDate: {
      enabled: true,
    },
  };
}

/**
 * @returns appointmentFilters the filter configuration for "weekly" mode
 */
export function weeklyFilterConfig(): AppointmentFilters {
  const daily = dailyFilterConfig();
  return {
    ...daily,
    timescale: {
      ...daily.timescale,
      value: Timescale.weekly,
    },
    interval: {
      ...daily.interval,
      value: INTERVAL_1HR,
      options: [ INTERVAL_1HR, INTERVAL_6HR ]
    },
  };
}

/**
 * @returns appointmentFilters the filter configuration for "monthly" mode
 */
export function monthlyFilterConfig(): AppointmentFilters {
  const daily = dailyFilterConfig();
  return {
    ...daily,
    timescale: {
      ...daily.timescale,
      value: Timescale.monthly,
    },
    interval: {
      ...daily.interval,
      enabled: false,
    },
  };
}

/**
 * Return the base filter configuration; no user state included
 *
 * @param timescale the timescale on which to base the config
 * @param includeStartDate whether the startDate value should be set
 * @returns appointmentFilters the appropriate filter config
 */
export function getFilterConfig(timescale: Timescale | null, includeStartDate = false): AppointmentFilters {
  let filters: AppointmentFilters;

  switch (timescale) {
    case Timescale.weekly:
      filters = weeklyFilterConfig();
      break;
    case Timescale.monthly:
      filters = monthlyFilterConfig();
      break;
    case Timescale.outagesmonthly:
      filters = outagesMonthlyFilterConfig();
      break;
    case Timescale.outagesyearly:
      filters = outagesYearlyFilterConfig();
      break;
    default:
      filters = dailyFilterConfig();
      break;
  }

  if (includeStartDate) {
    filters.startDate.value = formatDate(Date.now(), 'YYYY-MM-dd', 'en-US', 'UTC');
  }

  return filters;
}

/**
 * Utility to apply filter configuration defaults for a given timescale.
 *
 * @param timescale the timescale on which to base the config
 * @param filters the current filter state to alter
 * @returns appointmentFilters: the updated filter config
 */
export function applyFilterConfig(timescale: Timescale, filters: AppointmentFilters): AppointmentFilters {
  const config = getFilterConfig(timescale);

  // Original properties are retained unless the config supplies overrides
  const applied = {
    queue: { ...filters.queue, ...config.queue },
    timescale: { ...filters.timescale, ...config.timescale },
    interval: { ...filters.interval, ...config.interval },
    startDate: { ...filters.startDate, ...config.startDate },
  };

  // Set startDate to today if not already present
  if (!applied.startDate.value) {
    applied.startDate.value = formatDate(Date.now(), 'YYYY-MM-dd', 'en-US', 'UTC');
  }

  return applied;
}

export function outagesMonthlyFilterConfig(): AppointmentFilters {
  const startDate = formatDate(Date.now(), 'YYYY-MM-dd', 'en-US', 'UTC');
  return {
    queue: {
      enabled: false,
    },
    timescale: {
      enabled: true,
      value: Timescale.outagesyearly,
      options: [Timescale.outagesyearly, Timescale.outagesmonthly],
    },
    interval: {
      enabled: false,
      value: INTERVAL_Monthly,
      options: [INTERVAL_Monthly, INTERVAL_Yearly],
    },
    startDate: {
      enabled: false,
      value : startDate
    },
  };
}

export function outagesYearlyFilterConfig(): AppointmentFilters {
  const daily = outagesMonthlyFilterConfig();
  const startDate = formatDate(Date.now(), 'YYYY-MM-dd', 'en-US', 'UTC');
  const momentDate = utc(startDate, EXCHANGE_FORMAT);
  
  return {
    ...daily,
    timescale: {
      ...daily.timescale,
      value: Timescale.outagesyearly,
    },
    interval: {
      ...daily.interval,
      value: INTERVAL_Yearly,
      options: [ INTERVAL_Monthly, INTERVAL_Yearly ]
    },
    startDate: {
      enabled: false,
      value : momentDate.startOf('year').format(EXCHANGE_FORMAT)
    },
  };
}