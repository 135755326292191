import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CreatePoolResponse } from 'api/types';
import { FetchesPoolBaseClass } from 'components/common/fetches-pool-base/fetches-pool-base.class';
import { EditPoolToastComponent } from 'components/common/pools/edit-pool-toast/edit-pool-toast.component';
import { AllPoolDataService } from 'services/all-pool-data.service';
import { PoolsService } from 'services/api/pools.service';
import { EditPoolService } from 'services/edit-pool.service';
import { FetchingPoolStatusService } from 'services/status/fetching-pool-status.service';
import { VueToastComponent } from 'vue/components/vue-toast/vue-toast.component';

/**
 *  All pools page
 */
@Component({
  selector: 'app-all-pools-page',
  templateUrl: './all-pools-page.component.html',
})
export class AllPoolsPageComponent extends FetchesPoolBaseClass implements OnInit, OnDestroy {
  @ViewChild(VueToastComponent) public toast!: VueToastComponent;
  @ViewChild(EditPoolToastComponent) public editPoolToast!: EditPoolToastComponent;

  /**
   * Pool Name passed to success toast
   */
  public newPoolName = '';

  /**
   * Pool ID passed to success toast
   */
  public newPoolId = '';

  /**
   * Display an error when fetching a pool for editing fails
   */
  public showFetchingPoolError = false;

  /**
   * Cancel subscriptions on destroy
   */
  private destroyed$ = new Subject();

  public constructor(
    private poolsService: PoolsService,
    private router: Router,
    private editPoolService: EditPoolService,
    private allPoolDataService: AllPoolDataService,
    fetchingPoolStatusService: FetchingPoolStatusService,
  ) {
    super(fetchingPoolStatusService);
  }

  public ngOnInit(): void {
    this.allPoolDataService.updatePoolData();
    // Listen for pool updates
    this.editPoolService.poolUpdated$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((poolUpdated) => {
        this.allPoolDataService.updatePoolData();
        this.editPoolToast.openToast(poolUpdated.poolName, poolUpdated.status);
      });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.fetchingPoolStatusComplete();
  }

  /**
   * Fetches new pool details and opens success toast
   *
   * @param response Response from CreatePool network call
   */
  public poolCreated(response: CreatePoolResponse): void {
    this.newPoolId = response.id;
    this.poolsService.getPoolById({ id: response.id })
      .pipe(take(1))
      .subscribe((pool) => {
        this.newPoolName = pool.name;
        this.toast.open();
        this.allPoolDataService.updatePoolData();
      });
  }

  /**
   * Opens new pool detail page
   * Called from new pool toast action
   */
  public viewNewPool(): void {
    this.toast.close();
    this.router.navigate([ `pools/${this.newPoolId}` ]);
  }
}
