
import { FlexibleConnectedPositionStrategyOrigin } from '@angular/cdk/overlay';
import {
  ComponentFactoryResolver, ComponentRef,
  Directive,
  EventEmitter, Input, OnChanges,
  OnInit, Output, SimpleChanges, TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { ChartDataset } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SynchedScrollService } from 'services/synched-scroll.service';
import { WindowResizeService } from 'services/window-resize.service';
import { ChartClickEvent, isChartClickEvent, UndifferentiatedChartEvent } from 'types/ChartClickEvent';
import { VueChartPopoverHiddenButtonComponent } from '../vue-chart-popover-hidden-button/vue-chart-popover-hidden-button.component';
import { VuePopoverRef } from '../vue-popover-ref';
import { VuePopoverService } from '../vue-popover.service';

/**
 *  Directive to do the heavy lifting of setting up a popover on a `baseChart`
 *  - Takes a templateRef to render in the popover
 *  - Opens a popover when a chart elementRef is clicked
 *  - Closes popovers when:
 *    - Empty chart space is clicked
 *    - Chart is scrolled
 *    - Chart data updates
 *    - Component is destroyed
 */
@Directive({

  // Only allowed in conjunction with `baseChart`
  selector: '[baseChart][vueChartPopover]'
})
export class VueChartPopoverDirective implements OnInit, OnChanges {
  /**
   * The template to render inside the popover.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input('vueChartPopover') public templateRef!: TemplateRef<any>;

  /**
   * Take the datasets attribute on baseChart as an Input so we can listen for chart data changes
   */
  @Input('datasets') public chartDatasets!: ChartDataset[];

  /**
   * Use the highest value from the datasets for popover position
   */
  @Input() public positionWithHighestDataset?: boolean;

  @Output() public popoverWillOpen = new EventEmitter<ChartClickEvent>();

  private hiddenButton?: ComponentRef<VueChartPopoverHiddenButtonComponent>

  private destroyed = new Subject();

  /**
   *  Reference to the open popover
   */
  private openPopoverRef?: VuePopoverRef;

  public constructor(
    private baseChart: BaseChartDirective,
    private componentFactoryResolver: ComponentFactoryResolver,
    private parentContainer: ViewContainerRef,
    private popoverService: VuePopoverService,
    private scrollService: SynchedScrollService,
    private windowResizeService: WindowResizeService,
  ) {
  }

  public ngOnInit(): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      VueChartPopoverHiddenButtonComponent
    );

    if (!this.hiddenButton) {
      this.hiddenButton = this.parentContainer.createComponent<VueChartPopoverHiddenButtonComponent>(componentFactory);
    }

    // Subscribe to chart clicks
    this.baseChart.chartClick.pipe(takeUntil(this.destroyed)).subscribe((event: UndifferentiatedChartEvent) => {
      if (isChartClickEvent(event)) {
        this.chartClicked(event);
      }
    });

    // Close popover whenever scrolled
    this.scrollService.scrolled?.pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.closePopover();
    });

    // Close popover when window resizes
    this.windowResizeService.resized$.pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.closePopover();
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // Close popover when chart data changes
    if (changes.chartDatasets) {
      this.closePopover();
    }
  }

  public ngOnDestroy(): void {
    this.closePopover();
    this.destroyed.next();
    this.destroyed.complete();
  }

  private chartClicked(event: ChartClickEvent): void {
    const { active } = event;

    // Always close open popovers when chart clicked anywhere
    this.closePopover();

    // Empty part of chart clicked or button not ready
    if (!active || !active?.length || !this.hiddenButton || !this.hiddenButton.instance.nativeButtonElem) {
      return;
    }

    // Emit event to let callers set up popover content
    this.popoverWillOpen.emit(event);

    // Index of dataset to reference for the origin element
    let originElementIndex = 0;

    // Position `hiddenButton` on top of the highest dataset
    if (this.positionWithHighestDataset) {
      let greatestDataPoint = Number.MAX_SAFE_INTEGER;
      active.forEach((obj, index) => {
        /*
         * Set index for _lowest_ y value between the elements, which is opposite of intuition.
         * This is because the y value gets set as the `top` position of the `hiddenButton` element,
         * which is relative to the top of the screen, not the bottom.
         */
        if (obj.element.y < greatestDataPoint) {
          originElementIndex = index;
          greatestDataPoint = obj.element.y;
        }
      });
    }

    // Point from the line chart is the first elementRef; locate the popover above it
    const originElement = active[ originElementIndex ].element;
    const { x, y } = originElement;

    // Reposition button over the active chart elementRef
    this.hiddenButton.instance.left = x;
    this.hiddenButton.instance.top = y;

    // Show the popover, using the button as the "origin" for positioning
    this.showPopover(this.hiddenButton.instance.nativeButtonElem);
  }

  private showPopover(origin: FlexibleConnectedPositionStrategyOrigin): void {
    this.openPopoverRef = this.popoverService.open(this.templateRef, origin);
  }

  private closePopover(): void {
    this.openPopoverRef?.close();
  }
}
