/* eslint-disable @typescript-eslint/no-use-before-define */
import { Component } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { VueFormFieldBaseComponent } from '../../utilities/vue-form-field-base/vue-form-field-base.component';

/**
 * A convenient wrapper around a standard material text input, included a label and error display
 */
@Component({
  selector: 'vue-text-input',
  templateUrl: './vue-text-input.component.html',
  styleUrls: [ './vue-text-input.component.scss' ],
  providers: [ {
    provide: NG_VALUE_ACCESSOR, useExisting: VueTextInputComponent, multi: true
  } ]
})
export class VueTextInputComponent extends VueFormFieldBaseComponent { }
