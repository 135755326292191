import { Injectable } from '@angular/core';
import { GetOutageQueryParameters, GetOutageResponse } from 'api/types';
import { PoolsMicroservice } from './microservices/pools.microservice';
import { Observable, Subject } from 'rxjs';
import { createQueryString } from 'utils/create-query-string';
import { CreateOutagePayload, CreateOutageResponse } from 'api/types/endpoints/createOutage';
import { EditOutagePayload, EditOutageResponse } from 'api/types/endpoints/editOutage';
import { GetOutageByIdQueryParameters, GetOutageByIdResponse } from 'api/types/endpoints/getOutageById';
import { DisplayableServerError } from 'types/DisplayableServerError';

@Injectable({
  providedIn: 'root',
})
export class OutagesService {
  public outageSuccess$ = new Subject<{outageId: string, type: 'new' | 'edit' | 'delete'}>();
  
  public outageDeleteError$ = new Subject<{error: DisplayableServerError | null}>();
  
  /**
   * Default params that can be overridden by input
   */
  private defaultParams: GetOutageQueryParameters = {
    limit: 10,
    offset: 0,
    sortBy: 'startdate',
    direction: 'asc',
  };

  public constructor(private api: PoolsMicroservice) {}

  /**
   * Calls GetOutage API
   *
   * @param params optional params for get Outage call
   * @returns GetOutageResponse Observable
   */
  public getOutages(
    params?: Partial<GetOutageQueryParameters>
  ): Observable<GetOutageResponse> {
    const queryString = createQueryString({
      ...this.defaultParams,
      ...params,
    });
    const path = `outages${queryString}`;
    // outages?offset=0&sortBy=startdate&direction=asc&limit=25&date=2023-07-01&interval=monthly
    // outages?limit=25&offset=0&sortBy=startdate&direction=asc&interval=Yearly
    // outages?limit=10&offset=0&sortBy=name&direction=asc&startDate=2023-01-01&numberOfDays=1&interval=Yearly
    //const path = `outage/2023-05-30/yearly`;

    return this.api.get<GetOutageResponse>(path);
  }

  public createOutage(payload: CreateOutagePayload): Observable<CreateOutageResponse> {
    const path = 'outages';
    return this.api.post<CreateOutageResponse, CreateOutagePayload>(path, payload);
  }

  public editOutage(id: string, payload: EditOutagePayload): Observable<EditOutageResponse> {
    const path = `outages/${id}`;
    return this.api.patch<EditOutageResponse, EditOutagePayload>(path, payload);
  }

  public getOutageById(params: Partial<GetOutageByIdQueryParameters>): Observable<GetOutageByIdResponse> {
    const path = `outages/${params.id}`;

    return this.api.get<GetOutageByIdResponse>(path);
  }

  public deleteOutageById(params: Partial<GetOutageByIdQueryParameters>): Observable<GetOutageByIdResponse> {
    const path = `outages/${params.id}`;

    return this.api.delete<GetOutageByIdResponse>(path);
  }
}
