<vue-table-container>
  <table mat-table [dataSource]="paginatedTableData">
    <caption class="visually-hidden">
      {{ 'table.caption.occurrences.sortable' | translate | async}}
    </caption>

    <!-- Pool Name -->
    <ng-container matColumnDef="pool">
      <th
        mat-header-cell
        *matHeaderCellDef
        [tableHeaderAriaSort]="{sortDirection, isSorted: sortBy === 'pool' }"
      >
        <app-sortable-table-header
          [tableHasData]="!!tableData.length"
          (sortColumn)="sort('pool')"
        >
          {{ 'title.pool' | translate | async }}
        </app-sortable-table-header>
      </th>
      <mat-cell *matCellDef="let pool">
        <span class="ellipsis" [matTooltip]="pool.name">
          {{pool.name}}
        </span>
      </mat-cell>
    </ng-container>

    <!-- Pool Time -->
    <ng-container matColumnDef="time">
      <th
        mat-header-cell
        *matHeaderCellDef
        [tableHeaderAriaSort]="{sortDirection, isSorted: sortBy === 'time' }"
      >
        <app-sortable-table-header
          [tableHasData]="!!tableData.length"
          (sortColumn)="sort('time')"
        >
          {{ 'title.time' | translate | async }}
        </app-sortable-table-header>
      </th>
      <mat-cell *matCellDef="let pool">
        {{pool.startTime}} – {{pool.endTime}}
      </mat-cell>
    </ng-container>

    <!-- Pool Restriction -->
    <ng-container matColumnDef="restriction">
      <th
        mat-header-cell
        *matHeaderCellDef
        [attr.aria-label]="'table.heading.occurrence' | translate | async"
        [innerHTML]="'table.heading.occurrence.abbr' | translate | async"
      ></th>
      <mat-cell *matCellDef="let pool">
        <app-pool-occurrence-restriction
          [isOccurrenceRestricted]="pool.isOccurrenceRestricted"
          [isPoolRestricted]="pool.isPoolRestricted"
          [isOccurrenceOverride]="pool.isOccurrenceOverride"
          [occurrenceDateHasPassed]="occurrenceDateHasPassed()"
        ></app-pool-occurrence-restriction>
      </mat-cell>
    </ng-container>

    <!-- Pool Registration / Capacity -->
    <ng-container matColumnDef="capacity">
      <th
        mat-header-cell
        *matHeaderCellDef
        [attr.aria-label]="('title.registrations' | translate | async) + ' / ' + ('title.capacity' | translate | async)"
      >
        {{'title.registrations.abbreviated' | translate | async }} / {{ 'title.capacity' | translate | async }}
      </th>
      <mat-cell *matCellDef="let pool">
        {{ pool | poolOccurrenceDisplay:'capacity'}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="availability">
      <th mat-header-cell *matHeaderCellDef [attr.aria-label]="('title.availability' | translate | async)">
        {{ 'title.availability' | translate | async }}
      </th>
      <mat-cell *matCellDef="let pool">
        {{ pool | poolOccurrenceDisplay:'availability'}}
      </mat-cell>
    </ng-container>


    <!-- Pool Utilization -->
    <ng-container matColumnDef="utilization">
      <th
        mat-header-cell
        *matHeaderCellDef
        [tableHeaderAriaSort]="{sortDirection, isSorted: sortBy === 'utilization' }"
      >
        <app-sortable-table-header
          [tableHasData]="!!tableData.length"
          (sortColumn)="sort('utilization')"
        >
          {{ 'title.utilization' | translate | async }}
        </app-sortable-table-header>
      </th>
      <mat-cell *matCellDef="let pool">
        {{pool | poolOccurrenceDisplay:'utilization'}}
      </mat-cell>
    </ng-container>

    <!-- Pool menu -->
    <ng-container matColumnDef="menu">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'table.heading.actions' | translate | async }}
      </th>
      <mat-cell *matCellDef="let pool; let i = index;">
        <app-occurrence-menu
        [pool]="pool"
        [poolDate]="poolDate"
        [isOverride]="pool.isOccurrenceOverride"
        (occurrenceUpdated)="occurrenceUpdated(pool.name)"
        (occurrenceReleaseChange)="occurrenceUpdated(pool.name)"
        (applyDefaultPoolSettings)="occurrenceUpdated(pool.name)"
      ></app-occurrence-menu>
    </mat-cell>
    </ng-container>

    <!-- Populate table with data -->
    <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: tableColumns;"
      [ngClass]="{'released': row.isReleased, 'override': row.isOccurrenceOverride && !occurrenceDateHasPassed()}"
      [appBackgroundFade]="row.toggleBackgroundFade"
    ></mat-row>

    <!-- No data available -->
    <tr class="mat-row no-content" *matNoDataRow>
      <td class="mat-cell" colspan="9999">
        {{ "message.poolOccurrence.selectDateAbove" | translate | async }}
      </td>
    </tr>
  </table>

  <mat-paginator
  *ngIf="tableData.length !== 0"
  [length]="tableData.length"
  [pageIndex]="pageIndex"
  [pageSize]="pageSize"
  previousPageIndex="0"
  [pageSizeOptions]="pageSizeOptions"
  [showFirstLastButtons]="true"
  (page)="paginatorChange($event)"
></mat-paginator>
</vue-table-container>
