import { AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * @param control the form control to validate
 * @returns ValidationErrors if applicable
 */
export function bulkEditCapacityValidator(control: AbstractControl): ValidationErrors | null {
  // Edit capacity type & count needs to be defined
  if (control.value === null) {
    return {
      'missing-edit-capacity': control.value
    };
  }

  // Edit capacity type must be defined
  if (control.value.type === null) {
    return {
      'missing-edit-capacity-type': control.value
    };
  }

  // Edit capacity count must be defined
  if (control.value.count === null) {
    return {
      'missing-edit-capacity-count': control.value
    };
  }

  return null;
}
