/* eslint-disable no-undefined */
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { SortDirection } from '@angular/material/sort';
import { Moment, utc } from 'moment';
import { Observable } from 'rxjs';
import { PoolDetails, PoolStatus, RegistrationForDate } from 'api/types';
import { calculateUtilizationPercentage } from 'components/common/pools/utils/calculate-utilization-percentage';
import { BaseOccurrenceDetail, getBaseOccurrenceDetails } from 'components/common/pools/utils/get-base-occurrence-details';
import { getPoolStartEndTime } from 'components/common/pools/utils/get-pool-start-end-time';
import { getRegistrationsForTimePeriod } from 'components/common/pools/utils/get-registrations-for-time-period';
import { isPoolEditable } from 'components/common/pools/utils/is-pool-editable';
import { isValidPoolDate } from 'components/common/pools/utils/is-valid-pool-date';
import { DISPLAY_DAY_FORMAT, EXCHANGE_FORMAT } from 'constants/date-formats';
import { TranslatePipe, TranslationKey } from 'pipes/translate.pipe';
import { PoolDetailOccurrence } from 'types/Occurrence';
import { byDateString, byNumber, byString } from 'utils/sort-utils';
import { ClientSidePaginatedTable } from '../utils/client-side-paginated-table.class';
import { EditPoolOccurrencesService } from 'services/edit-pool-occurrences.service';
import { take } from 'rxjs/operators';

const tableColumns = [ 'date', 'status', 'time', 'restriction', 'capacity', 'availability', 'utilization', 'menu' ] as const;
const sortableColumns = [ 'date', 'time', 'utilization' ] as const;
type SortBy = typeof sortableColumns[number];

/**
 *  Displays occurrences for a specific pool
 */
@Component({
  selector: 'app-pool-detail-occurrence-table',
  templateUrl: './pool-detail-occurrence-table.component.html',
  styleUrls: [ './pool-detail-occurrence-table.component.scss' ],
})
export class PoolDetailOccurrenceTableComponent
  extends ClientSidePaginatedTable<PoolDetailOccurrence, SortBy> implements OnChanges {
  /**
   * Full pool details
   */
  @Input() public pool!: PoolDetails;

  @Input() public registrations: RegistrationForDate[] = [];

  /**
   * Emits when the pool details should be updated
   */
  @Output() public refreshPool = new EventEmitter<void>();

  /**
   * Only show the edit menu if occurrence is editable
   */
  public isOccurrenceEditable = isPoolEditable;

  // Table attributes
  public tableColumns = tableColumns;

  /**
   * Date of the occurrence that has been updated.
   * Ex: YYYY-MM-DD
   * Used for `togglingBackgroundFade` when table data is generated
   */
  private updatedDate: string | null = null;

  public constructor(private translatePipe: TranslatePipe,
    private editOccurrencesService: EditPoolOccurrencesService) {
    super('date');
  }

  /**
   * Return the occurrence status based date of the occurrence and today's date.
   */
  private static getOccurrenceStatus(pool: PoolDetails, date: Moment): PoolStatus {
    const today = utc();
    switch (true) {
      case pool.status === 'archived':
        return pool.status;
      case date.isBefore(today, 'day'):
        return 'completed';
      case date.isSame(today, 'day'):
        return 'open';
      default:
        return 'scheduled';
    }
  }

  /**
   * Based on the column to be sorted, return the proper sort method.
   */
  private static getSortMethod(column: SortBy): (a: PoolDetailOccurrence, b: PoolDetailOccurrence) => number {
    switch (column) {
      case 'time':
        return byString((o: PoolDetailOccurrence) => o.startTime);
      case 'utilization':
        return byNumber((o: PoolDetailOccurrence) => o.utilization);
      default:
        return byDateString((o: PoolDetailOccurrence) => o.date);
    }
  }

  /**
   * Regenerate table data based on new pools value
   *
   * @param changes change object containing updated input values
   */
  public ngOnChanges(changes: SimpleChanges): void {
    /* istanbul ignore else */
    if ('pool' in changes) {
      this.generateTableData(changes.pool.currentValue);

      // Reset pagination if table data no longer fits the current parameters
      this.pageIndex = this.getPageIndexForCurrentDate();
      if (this.tableData.length <= (this.pageIndex * this.pageSize)) {
        this.resetPagination();
      }
    }
  }

  getPageIndexForCurrentDate() {
    let pageIndex = 0;
    let currentDate = utc();
    let tableDataPastDates = this.tableData.filter(item => utc(item.date).format(EXCHANGE_FORMAT) <= currentDate.format(EXCHANGE_FORMAT));
    if ((tableDataPastDates.length - this.pageSize) > 0) {
      pageIndex = Math.ceil((tableDataPastDates.length - this.pageSize) / this.pageSize);
    }

    return pageIndex;
  }

  /**
   * Call correct sort function based on column
   *
   * @param column column to sort
   * @param sortDirection direction to sort
   */
  public sortByColumn(column: SortBy, sortDirection: SortDirection): void {
    this.tableData.sort(PoolDetailOccurrenceTableComponent.getSortMethod(column));

    if (sortDirection === 'desc') {
      this.tableData.reverse();
    }

    this.resetPagination();
  }

  /**
   * Occurrence updated handler
   * Optionally sets `updatedDate` to highlight the row on next render
   */
  public occurrenceUpdated(date?: string): void {
    if (date !== undefined) {
      this.updatedDate = date;
    }

    // Tell pool detail page to updated pool data to get new pool details from the server
    this.refreshPool.emit();
  }

  /**
   * @param date date to localize in YYYY-MM-DD format
   * @returns localized date through moments locale
   */
  public displayLocalizedDate(date: string): string {
    return utc(date, EXCHANGE_FORMAT).format(DISPLAY_DAY_FORMAT);
  }

  /**
   * @param occurrence occurrence instance.
   * @returns The aria label text.
   */
  public getRestrictionAriaLabel(occurrence: PoolDetailOccurrence): Observable<string> {
    const key = occurrence.isRestricted ?
      'message.occurrence.restricted' :
      'message.occurrence.unrestricted';
    return this.translatePipe.transform(key);
  }

  /**
   * Reset `toggleBackgroundFade` so it doesn't activate twice,
   * then call `ClientSidePaginatedTable.paginatorChange`
   */
  public paginatorHandler(event: PageEvent): void {
    this.tableData = this.tableData.map((occurrence) => {
      return {
        ...occurrence,
        toggleBackgroundFade: false,
      };
    });

    this.paginatorChange(event);
  }

  /**
   * Retrieve registrations for a given date
   */
  public getRegistrationsForDate(date: string): RegistrationForDate | undefined {
    return this.registrations.find((r) => r.date === date);
  }

  /**
   * Calculates occurrences based on pool details
   * Sorts the occurrences by currently sorted column & direction
   *
   * @param newPool the latest pool details
   */
  private generateTableData(newPool: PoolDetails): void {
    const updatedTableData = this.generateOccurrences(newPool).sort(
      PoolDetailOccurrenceTableComponent.getSortMethod(this.sortBy)
    );

    if (this.sortDirection === 'desc') {
      updatedTableData.reverse();
    }

    this.tableData = updatedTableData;
  }

  /**
   * Calculate the total capacity for an occurrence
   */
  private getOccurrenceCapacity(occurrence: BaseOccurrenceDetail): number {
    return occurrence.schedule.items.reduce((cap, item) => {
      return cap + item.capacity;
    }, 0);
  }

  /**
   * Construct the list of pool occurrences from the pool details
   */
  private generateOccurrences(pool: PoolDetails): PoolDetailOccurrence[] {
    const occurrences: PoolDetailOccurrence[] = [];
    const { startDate, endDate } = pool;

    // Format start & end dates
    const currentDate = utc(startDate, EXCHANGE_FORMAT).startOf('day');
    const end = utc(endDate, EXCHANGE_FORMAT).clone().startOf('day');

    while (currentDate.isSameOrBefore(end)) {
      // Check for valid pool date
      if (isValidPoolDate(pool, currentDate)) {
        // Find the registrations for the corresponding day
        const fullDayRegistration = this.getRegistrationsForDate(currentDate.format(EXCHANGE_FORMAT));

        // Construct a base occurrence for the day
        const baseOccurrence = getBaseOccurrenceDetails(currentDate.format(EXCHANGE_FORMAT), pool);

        // Get start and end time for the day
        const { poolStartTime, poolEndTime } = getPoolStartEndTime(baseOccurrence.schedule.items);
        const capacity = this.getOccurrenceCapacity(baseOccurrence);
        const registrations = getRegistrationsForTimePeriod(
          poolStartTime,
          poolEndTime,
          fullDayRegistration
        );

        // Add occurrence to array
        occurrences.push({
          date: currentDate.format(EXCHANGE_FORMAT),
          name: pool.name,
          id: pool.id,
          status: PoolDetailOccurrenceTableComponent.getOccurrenceStatus(pool, currentDate),
          startTime: poolStartTime,
          endTime: poolEndTime,
          capacity,
          registrations,
          isRestricted: baseOccurrence.isRestricted,
          isReleased: baseOccurrence.isReleased,
          isOverride: baseOccurrence.isOverride,
          utilization: calculateUtilizationPercentage(registrations, capacity),
          ...(currentDate.format(EXCHANGE_FORMAT) === this.updatedDate ? { toggleBackgroundFade: true } : {}),
          isViewRestriction: false,
        });
      }

      // iterate to next day
      currentDate.add(1, 'days');
    }

    // Reset updatedIndex
    this.updatedDate = null;

    return occurrences;
  }

  public occurrenceDateHasPassed(poolDate: string) {
    return utc(poolDate, EXCHANGE_FORMAT).isBefore(utc(), 'day');
  }

  clickViewRestriction(occurrence: PoolDetailOccurrence) {
    var selectedPoolId = this.pool?.id;
    var occurrenceDate = occurrence?.date;
    occurrence.isLoading = true;
    if (selectedPoolId?.length && occurrenceDate) {
      this.editOccurrencesService.getOccurrenceRestriction(selectedPoolId, occurrenceDate).pipe(take(1)).subscribe((response) => {
        occurrence.isViewRestriction = true;
        occurrence.isRestricted  = response.isRestricted;
        occurrence.isReleased = response.isReleased;
        occurrence.isLoading = false;
      }, () => {
        occurrence.isLoading = false;
      });
    }
  }

  public getOccurrenceRestrictionToolTip(): Observable<string> {
    const occurrenceKey = 'tooltop.message.occurrence.viewoccurrencerestriction';
    let key: TranslationKey = occurrenceKey
    return this.translatePipe.transform(key);
  }
}
