import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { utc } from 'moment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { CapacityTemplateDetails } from 'api/types';
import { CreateCapacityTemplateDay, CreateCapacityTemplateRequestBody, CreateCapacityTemplateResponse, UpdateCapacityTemplateResponse } from 'api/types';
import { Day } from 'constants/days-of-the-week';
import { CapacityTemplatesService } from 'services/api/capacity-templates.service';
import { DrawerStatusService } from 'services/status/drawer-status.service';
import { DisplayableServerError } from 'types/DisplayableServerError';
import { getDisplayableServerError } from 'utils/get-displayable-server-error';
import { invalidCharactersValidator } from 'utils/validators/invalid-characters.validator';
import { percentAllocationValidator } from '../percent-allocation-validator/percent-allocation.validator';
import { TemplateNameErrorHandler } from '../template-name-error-handler/template-name-error-handler.class';

export const TemplateSteps = [
  'form', 'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'
] as const;
export type TemplateStep = typeof TemplateSteps[number];

export type TemplateAction =
  { type: 'edit', id: string, status: CapacityTemplateDetails['status'] } |
  { type: 'new' } |
  { type: 'duplicate' } |
  { type: 'active', id: string, status: CapacityTemplateDetails['status'] }

/**
 *  Manage shared state for the add template flow
 */
@Injectable({
  providedIn: 'root'
})
export class AddTemplateState {
  /**
   * The overarching formGroup for the entire flow
   */
  public formGroup: UntypedFormGroup;

  /**
   * Current step in the drawer screen flow
   */
  public step$ = new BehaviorSubject<TemplateStep>('form');

  /**
   * Template submission success
   */
  public templateSuccess$ = new Subject<{ templateName: string, type: 'new' | 'edit' | 'active' }>();

  /**
   * Type of template action
   * AddTemplateDrawer can be used for new templates, editing existing or duplicating
   */
  public typeOfTemplateAction: TemplateAction = { type: 'new' };

  /**
   * DisplayableServerError from template service
   */
  public displayableServerError: DisplayableServerError | null = null;

  /**
   * Initial values of form fields
   */
  private initialValues: { [key: string]: unknown } = {};

  public initialNameControlValue: string = "";

  public intitalWeeklyAllocation: any = [];

  public intitalSundayAllocation: any = [];

  public intitalMondayAllocation: any = [];

  public intitalTuesdayAllocation: any = [];

  public intitalWednesdayAllocation: any = [];

  public intitalThursdayAllocation: any = [];

  public intitalFridayAllocation: any = [];

  public intitalSaturdayAllocation: any = [];

  public templateNameErrorHandler = new TemplateNameErrorHandler();

  public enableNextBtnManually$ = new Subject<boolean>();

  public constructor(
    private formBuilder: UntypedFormBuilder,
    private capacityTemplateService: CapacityTemplatesService,
    private drawerStatusService: DrawerStatusService,
  ) {
    this.formGroup = this.createMainFormGroup();

    // Save initial values for reset
    this.initialValues = this.formGroup.value;
  }

  /**
   * Create percent field form control with appropriate validators
   *
   * @returns control
   */
  private static percentControl(): UntypedFormControl {
    return new UntypedFormControl('', {
      validators: [
        Validators.min(0),
        Validators.max(100),
      ]
    });
  }

  /**
   * Show the next step in the flow
   */
  public showNextStep(): void {
    const nextIndex = TemplateSteps.indexOf(this.step$.value) + 1;
    if (nextIndex < TemplateSteps.length) {
      this.showStep(TemplateSteps[nextIndex]);
    }
  }

  /**
   * Show the previous step in the flow
   */
  public showPreviousStep(): void {
    const previousIndex = TemplateSteps.indexOf(this.step$.value) - 1;
    if (previousIndex !== -1) {
      this.showStep(TemplateSteps[previousIndex]);
    }
  }

  /**
   * Check if visible form is valid based in step value
   *
   * @returns true if current form is valid
   */
  public currentStepIsValid(): boolean {
    switch (this.step$.value) {
      case 'form':
        return this.firstStep.status === 'VALID';
      default:
        return this.hourlyAllocations(this.step$.value).status === 'VALID' || this.hourlyAllocations(this.step$.value).status === 'DISABLED';
    }
  }

  /**
   * Get the total used percentage for weekly form
   *
   * @returns total the percent used so far
   */
  public weeklyAllocationsTotalUsed(): number {
    if (!this.formGroup?.controls) {
      return 0;
    }
    return this.sumPercentControls(this.weeklyAllocations.controls);
  }

  /**
   * Copy one day allocations to another days allocations
   *
   * @param originDay day that is being copied
   * @param destinationDay day that is getting copied to
   */
  public copyAllocation(originDay: Day, destinationDay: Day): void {
    this.hourlyAllocations(destinationDay).setValue(
      this.hourlyAllocations(originDay).value
    );
    this.hourlySetEqualAllocation(destinationDay).setValue(this.hourlySetEqualAllocation(originDay).value);
    if (this.hourlySetEqualAllocation(originDay).value) {
      this.hourlyAllocations(destinationDay).disable();
    }
    else {
      this.hourlyAllocations(destinationDay).enable();
    }
  }

  /**
   * Getter for first step FormGroup
   *
   * @returns first step FormGroup
   */
  public get firstStep(): UntypedFormGroup {
    return this.formGroup.controls.firstStep as UntypedFormGroup;
  }

  /**
   * Getter for directly access name FormControl
   *
   * @returns first step Name FormControl
   */
  public get nameControl(): UntypedFormControl {
    return this.firstStep.controls.name as UntypedFormControl;
  }

  /**
   * Getter for directly access weekly allocations FormArray
   *
   * @returns weekly allocations FormArray
   */
  public get weeklyAllocations(): UntypedFormArray {
    return this.firstStep.controls.weeklyAllocations as UntypedFormArray;
  }

  public get setEqualAllocation(): UntypedFormControl {
    return this.firstStep.controls.setEqualAllocation as UntypedFormControl;
  }

  public get sundayStep(): UntypedFormGroup {
    return this.formGroup.controls.sunday as UntypedFormGroup;
  }

  public get mondayStep(): UntypedFormGroup {
    return this.formGroup.controls.monday as UntypedFormGroup;
  }

  public get tuesdayStep(): UntypedFormGroup {
    return this.formGroup.controls.tuesday as UntypedFormGroup;
  }

  public get wednesdayStep(): UntypedFormGroup {
    return this.formGroup.controls.wednesday as UntypedFormGroup;
  }

  public get thursdayStep(): UntypedFormGroup {
    return this.formGroup.controls.thursday as UntypedFormGroup;
  }

  public get fridayStep(): UntypedFormGroup {
    return this.formGroup.controls.friday as UntypedFormGroup;
  }

  public get saturdayStep(): UntypedFormGroup {
    return this.formGroup.controls.saturday as UntypedFormGroup;
  }

  public hourlySetEqualAllocation(day: Day): UntypedFormControl {
    if (day == "sunday") {
      return this.sundayStep.controls.setEqualAllocation as UntypedFormControl;
    }
    else if (day == "monday") {
      return this.mondayStep.controls.setEqualAllocation as UntypedFormControl;
    }
    else if (day == "tuesday") {
      return this.tuesdayStep.controls.setEqualAllocation as UntypedFormControl;
    }
    else if (day == "wednesday") {
      return this.wednesdayStep.controls.setEqualAllocation as UntypedFormControl;
    }
    else if (day == "thursday") {
      return this.thursdayStep.controls.setEqualAllocation as UntypedFormControl;
    }
    else if (day == "friday") {
      return this.fridayStep.controls.setEqualAllocation as UntypedFormControl;
    }
    else if (day == "saturday") {
      return this.saturdayStep.controls.setEqualAllocation as UntypedFormControl;
    }
    return this.sundayStep.controls.setEqualAllocation as UntypedFormControl;
  }

  public hourlyAllocations(day: Day): UntypedFormArray {
    if (day == "sunday") {
      return this.sundayStep.controls.hourly as UntypedFormArray;
    }
    else if (day == "monday") {
      return this.mondayStep.controls.hourly as UntypedFormArray;
    }
    else if (day == "tuesday") {
      return this.tuesdayStep.controls.hourly as UntypedFormArray;
    }
    else if (day == "wednesday") {
      return this.wednesdayStep.controls.hourly as UntypedFormArray;
    }
    else if (day == "thursday") {
      return this.thursdayStep.controls.hourly as UntypedFormArray;
    }
    else if (day == "friday") {
      return this.fridayStep.controls.hourly as UntypedFormArray;
    }
    else if (day == "saturday") {
      return this.saturdayStep.controls.hourly as UntypedFormArray;
    }
    return this.sundayStep.controls.hourly as UntypedFormArray;
  }

  public initialHourlyAllocations(day: Day): [] {
    if (day == "sunday") {
      return this.intitalSundayAllocation;
    }
    else if (day == "monday") {
      return this.intitalMondayAllocation;
    }
    else if (day == "tuesday") {
      return this.intitalTuesdayAllocation;
    }
    else if (day == "wednesday") {
      return this.intitalWednesdayAllocation;
    }
    else if (day == "thursday") {
      return this.intitalThursdayAllocation;
    }
    else if (day == "friday") {
      return this.intitalFridayAllocation;
    }
    else if (day == "saturday") {
      return this.intitalSaturdayAllocation;
    }
    return [];
  }

  /**
   * Get total used for a given day
   *
   * @param day the day of the week to get totals for
   * @returns total the percent used so far
   */
  public dailyTotalUsed(day: Day): number {
    return this.sumPercentControls(this.hourlyAllocations(day).controls);
  }

  /**
   * Sets type of template action
   *
   * @param type type of template action
   */
  public setTypeOfTemplateAction(type: TemplateAction): void {
    this.typeOfTemplateAction = type;
  }

  /**
   * Pre-fill form values with all template information
   *
   * @param template full template details
   */
  public updateFormWithEditValues(template: CapacityTemplateDetails): void {
    this.preFillFormWithTemplateData(template);
  }

  /**
   * Submit template action
   */
  public submitTemplate(): void {
    this.drawerStatusService.loading();
    const payload = this.constructSubmissionPayload();
    let templateActionService: Observable<CreateCapacityTemplateResponse | UpdateCapacityTemplateResponse>;

    if (this.typeOfTemplateAction.type === 'edit' || this.typeOfTemplateAction.type === 'active') {
      // Update an existing template
      templateActionService = this.capacityTemplateService.updateCapacityTemplate(
        this.typeOfTemplateAction.id,
        {
          ...payload,
          status: this.typeOfTemplateAction.status
        }
      );
    } else {
      // Create a new template, same logic for brand new template & duplicating an old one
      templateActionService = this.capacityTemplateService.createCapacityTemplate(payload);
    }

    templateActionService
      .pipe(take(1))
      .subscribe(() => {
        this.drawerStatusService.success();
        let type: 'new' | 'edit' | 'active' = 'edit';
        if (this.typeOfTemplateAction.type === 'new') {
          type = 'new';
        }
        else if (this.typeOfTemplateAction.type === 'active') {
          type = 'active';
        }
        else {
          type = 'edit';
        }

        this.templateSuccess$.next({
          templateName: payload.name,
          type: type
        });
        this.resetForm();
      }, (error: unknown) => {
        this.displayableServerError = getDisplayableServerError(error);
        this.drawerStatusService.error();
      });
  }

  /**
   * Resets form back to initial values
   */
  public resetForm(): void {
    this.displayableServerError = null;
    this.showStep('form');
    this.formGroup.reset(this.initialValues);
    this.enableNextBtnManually$.next(false);
  }

  public hasFormValueChanged(): boolean {
    return JSON.stringify(this.formGroup.getRawValue()) !== JSON.stringify(this.initialValues);
  }

  /**
   * Show the given step in the flow
   *
   * @param step the step to show
   */
  private showStep(step: TemplateStep): void {
    this.step$.next(step);
  }

  /**
   * Create the super-form that contains all of the data for the whole flow
   *
   * @returns FormGroup
   */
  private createMainFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      firstStep: this.formBuilder.group({
        name: this.formBuilder.control('', {
          validators: [
            Validators.required,
            invalidCharactersValidator,
            this.templateNameErrorHandler.duplicateNameValidator.bind(this.templateNameErrorHandler)
          ]
        }),

        // High-level allocations for each day in the week
        weeklyAllocations: this.formBuilder.array(
          new Array(7).fill(null).map(AddTemplateState.percentControl),
          { validators: [percentAllocationValidator] }
        ),

        setEqualAllocation: this.formBuilder.control(false)
      }),
      sunday: this.formBuilder.group({
        hourly: this.createDailyAllocationsFormArray(),
        setEqualAllocation: this.formBuilder.control(false)
      }),
      monday: this.formBuilder.group({
        hourly: this.createDailyAllocationsFormArray(),
        setEqualAllocation: this.formBuilder.control(false)
      }),
      tuesday: this.formBuilder.group({
        hourly: this.createDailyAllocationsFormArray(),
        setEqualAllocation: this.formBuilder.control(false)
      }),
      wednesday: this.formBuilder.group({
        hourly: this.createDailyAllocationsFormArray(),
        setEqualAllocation: this.formBuilder.control(false)
      }),
      thursday: this.formBuilder.group({
        hourly: this.createDailyAllocationsFormArray(),
        setEqualAllocation: this.formBuilder.control(false)
      }),
      friday: this.formBuilder.group({
        hourly: this.createDailyAllocationsFormArray(),
        setEqualAllocation: this.formBuilder.control(false)
      }),
      saturday: this.formBuilder.group({
        hourly: this.createDailyAllocationsFormArray(),
        setEqualAllocation: this.formBuilder.control(false)
      }),
    });
  }

  /**
   * Generate a formArray of hourly allocation controls for one day
   *
   * @returns formArray
   */
  private createDailyAllocationsFormArray(): UntypedFormArray {
    // Create controls for each hour of the day
    const controls = new Array(24).fill(null).map(AddTemplateState.percentControl);
    return this.formBuilder.array(controls, [percentAllocationValidator]);
  }

  /**
   * Sums up the values of an array of percent controls
   *
   * @param controls the array of controls
   * @returns sum
   */
  private sumPercentControls(controls: AbstractControl[]): number {
    const sum = controls.reduce((total, control) => {
      return total + parseFloat(control.value || 0);
    }, 0);

    /*
     * Round decimals to hundredths, avoid JavaScript math issues
     * Ex: 0.3 + 0.6 = 0.8999999999999999
     */
    return Number(sum.toFixed(2));
  }

  /**
   * Take template data structure and pre-fill form with all available data
   * Basically the opposite of constructSubmissionPayload
   *
   * @param template CapacityTemplateDetails
   */
  private preFillFormWithTemplateData(template: CapacityTemplateDetails): void {
    this.initialNameControlValue = template.name;
    
    var valueEqualAllocation = 100 / 7;
    this.intitalWeeklyAllocation = template.dailyAllocation?.map((allocation) => (allocation.percent || 0).toFixed(2)) || [];

    this.intitalSundayAllocation = this.getHourlyPercentagesTemplate('sunday', template.dailyAllocation) || [];
    this.intitalMondayAllocation = this.getHourlyPercentagesTemplate('monday', template.dailyAllocation) || [];
    this.intitalTuesdayAllocation = this.getHourlyPercentagesTemplate('tuesday', template.dailyAllocation) || [];
    this.intitalWednesdayAllocation = this.getHourlyPercentagesTemplate('wednesday', template.dailyAllocation) || [];
    this.intitalThursdayAllocation = this.getHourlyPercentagesTemplate('thursday', template.dailyAllocation) || [];
    this.intitalFridayAllocation = this.getHourlyPercentagesTemplate('friday', template.dailyAllocation) || [];
    this.intitalSaturdayAllocation = this.getHourlyPercentagesTemplate('saturday', template.dailyAllocation) || [];
    this.enableNextBtnManually$.next(true);

    this.formGroup.setValue({
      firstStep: {
        name: template.name,
        weeklyAllocations: (template.isPrcntSetEqualAlloc ? (template.dailyAllocation?.map(() => valueEqualAllocation.toString())) : (template.dailyAllocation?.map((allocation) => (allocation.percent || 0).toFixed(2)))) || [],
        setEqualAllocation: template.isPrcntSetEqualAlloc
      },
      sunday: {
        hourly: this.getHourlyPercentagesTemplate('sunday', template.dailyAllocation, true),
        setEqualAllocation: template.dailyAllocation?.find((allocation) => allocation.dayOfWeek === 'sunday')?.isHrPrcntSetEqualAlloc || false
      },
      monday: {
        hourly: this.getHourlyPercentagesTemplate('monday', template.dailyAllocation, true),
        setEqualAllocation: template.dailyAllocation?.find((allocation) => allocation.dayOfWeek === 'monday')?.isHrPrcntSetEqualAlloc || false
      },
      tuesday: {
        hourly: this.getHourlyPercentagesTemplate('tuesday', template.dailyAllocation, true),
        setEqualAllocation: template.dailyAllocation?.find((allocation) => allocation.dayOfWeek === 'tuesday')?.isHrPrcntSetEqualAlloc || false
      },
      wednesday: {
        hourly: this.getHourlyPercentagesTemplate('wednesday', template.dailyAllocation, true),
        setEqualAllocation: template.dailyAllocation?.find((allocation) => allocation.dayOfWeek === 'wednesday')?.isHrPrcntSetEqualAlloc || false
      },
      thursday: {
        hourly: this.getHourlyPercentagesTemplate('thursday', template.dailyAllocation, true),
        setEqualAllocation: template.dailyAllocation?.find((allocation) => allocation.dayOfWeek === 'thursday')?.isHrPrcntSetEqualAlloc || false
      },
      friday: {
        hourly: this.getHourlyPercentagesTemplate('friday', template.dailyAllocation, true),
        setEqualAllocation: template.dailyAllocation?.find((allocation) => allocation.dayOfWeek === 'friday')?.isHrPrcntSetEqualAlloc || false
      },
      saturday: {
        hourly: this.getHourlyPercentagesTemplate('saturday', template.dailyAllocation, true),
        setEqualAllocation: template.dailyAllocation?.find((allocation) => allocation.dayOfWeek === 'saturday')?.isHrPrcntSetEqualAlloc || false
      },
    });

    if (template.isPrcntSetEqualAlloc) {
      this.firstStep.controls.weeklyAllocations.disable();
    }
    else {
      this.firstStep.controls.weeklyAllocations.enable();
    }

    if (template.dailyAllocation?.find((allocation) => allocation.dayOfWeek === 'sunday')?.isHrPrcntSetEqualAlloc) {
      this.hourlyAllocations("sunday").disable();
    }
    else {
      this.hourlyAllocations("sunday").enable();
    }

    if (template.dailyAllocation?.find((allocation) => allocation.dayOfWeek === 'monday')?.isHrPrcntSetEqualAlloc) {
      this.hourlyAllocations("monday").disable();
    }
    else {
      this.hourlyAllocations("monday").enable();
    }

    if (template.dailyAllocation?.find((allocation) => allocation.dayOfWeek === 'tuesday')?.isHrPrcntSetEqualAlloc) {
      this.hourlyAllocations("tuesday").disable();
    }
    else {
      this.hourlyAllocations("tuesday").enable();
    }

    if (template.dailyAllocation?.find((allocation) => allocation.dayOfWeek === 'wednesday')?.isHrPrcntSetEqualAlloc) {
      this.hourlyAllocations("wednesday").disable();
    }
    else {
      this.hourlyAllocations("wednesday").enable();
    }

    if (template.dailyAllocation?.find((allocation) => allocation.dayOfWeek === 'thursday')?.isHrPrcntSetEqualAlloc) {
      this.hourlyAllocations("thursday").disable();
    }
    else {
      this.hourlyAllocations("thursday").enable();
    }

    if (template.dailyAllocation?.find((allocation) => allocation.dayOfWeek === 'friday')?.isHrPrcntSetEqualAlloc) {
      this.hourlyAllocations("friday").disable();
    }
    else {
      this.hourlyAllocations("friday").enable();
    }

    if (template.dailyAllocation?.find((allocation) => allocation.dayOfWeek === 'saturday')?.isHrPrcntSetEqualAlloc) {
      this.hourlyAllocations("saturday").disable();
    }
    else {
      this.hourlyAllocations("saturday").enable();
    }
  }

  /**
   * @param day day of the week to get
   * @param allocations all allocations for the week
   * @returns string array of 24 values representing hourly allocations
   */
  private getHourlyPercentagesTemplate(day: Day, allocations: CapacityTemplateDetails['dailyAllocation'], isCalculateAutoAllocation = false): string[] {
    const hourlyAllocations: string[] = new Array(24).fill('');

    const dailyAllocation = allocations?.find((allocation) => allocation.dayOfWeek === day);
    var valueEqualAllocation = 100 / 24;
    if (dailyAllocation) {
      dailyAllocation.hourlyAllocation.forEach((hourlyAllocation, i) => {
        hourlyAllocations[i] = (isCalculateAutoAllocation && dailyAllocation.isHrPrcntSetEqualAlloc) ? valueEqualAllocation.toString() : ((hourlyAllocation.percent || 0).toFixed(2));
      });
    }

    return hourlyAllocations;
  }

  /**
   * Construct full payload for submitting a new template
   *
   * @returns CreateCapacityTemplateRequestBody
   */
  private constructSubmissionPayload(): CreateCapacityTemplateRequestBody {
    return {
      name: this.firstStep.controls.name.value,
      status: 'active',
      isPrcntSetEqualAlloc: this.firstStep.controls.setEqualAllocation.value,
      dailyAllocation: [
        this.getDailyAllocationPayload('sunday', this.firstStep.controls.weeklyAllocations.value[0], this.sundayStep.controls.hourly.value, this.sundayStep.controls.setEqualAllocation.value),
        this.getDailyAllocationPayload('monday', this.firstStep.controls.weeklyAllocations.value[1], this.mondayStep.controls.hourly.value, this.mondayStep.controls.setEqualAllocation.value),
        this.getDailyAllocationPayload('tuesday', this.firstStep.controls.weeklyAllocations.value[2], this.tuesdayStep.controls.hourly.value, this.tuesdayStep.controls.setEqualAllocation.value),
        this.getDailyAllocationPayload('wednesday', this.firstStep.controls.weeklyAllocations.value[3], this.wednesdayStep.controls.hourly.value, this.wednesdayStep.controls.setEqualAllocation.value),
        this.getDailyAllocationPayload('thursday', this.firstStep.controls.weeklyAllocations.value[4], this.thursdayStep.controls.hourly.value, this.thursdayStep.controls.setEqualAllocation.value),
        this.getDailyAllocationPayload('friday', this.firstStep.controls.weeklyAllocations.value[5], this.fridayStep.controls.hourly.value, this.fridayStep.controls.setEqualAllocation.value),
        this.getDailyAllocationPayload('saturday', this.firstStep.controls.weeklyAllocations.value[6], this.saturdayStep.controls.hourly.value, this.saturdayStep.controls.setEqualAllocation.value),
      ]
    };
  }

  /**
   * Gets payload object for an individual day with allocation information
   *
   * @param day day of the week
   * @param weeklyAllocation weekly allocation from first step of form
   * @param hourlyAllocations hourly allocations for the respective day
   * @returns CreateCapacityTemplateDay
   */
  private getDailyAllocationPayload(
    day: CreateCapacityTemplateDay['dayOfWeek'],
    weeklyAllocation: string,
    hourlyAllocations: [],
    setEqualAllocation: boolean
  ): CreateCapacityTemplateDay {
    return {
      dayOfWeek: day,
      percent: this.parseAllocation(weeklyAllocation),
      isHrPrcntSetEqualAlloc: setEqualAllocation,
      hourlyAllocation: hourlyAllocations.map((allocation, i) => {
        return {
          startTime: this.getHourField(i),
          percent: this.parseAllocation(allocation)
        };
      })
    };
  }

  /**
   * Converts allocation to a number
   *
   * @param allocationString allocation in string format
   * @returns allocation as a number
   */
  private parseAllocation(allocationString: string): number {
    const allocation = parseFloat(allocationString || '0');
    return isNaN(allocation) ? 0 : allocation;
  }

  /**
   * @param hourNumber the hour number
   * @returns label as a formatted label
   */
  private getHourField(hourNumber: number): string {
    return utc().hour(hourNumber).format('HH:00');
  }
}
