import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { ClientInfo, Exam } from 'api/types';
import { AllPoolsFiltersService } from 'services/all-pools-filters.service';
import { ExamsService } from 'services/api/exams.service';
import { AutoCompleteStatusService } from 'services/status/auto-complete-status.service';

/**
 *  Component for filtering by one or more exams
 */
@Component({
  selector: 'app-exams-filter',
  templateUrl: './exams-filter.component.html',
  styleUrls: [ './exams-filter.component.scss' ]
})
export class ExamsFilterComponent implements OnDestroy {
  /**
   * Array of exams that are already being filtered on
   */
  @Input() public alreadySelectedExams: Exam[] = [];

  /**
   * Emit event when exams are selected or deselected
   */
  @Output() public updateSelectedExams = new EventEmitter<Exam[]>();

  /**
   * Exams returned from service based on search criteria
   */
  public examsToDisplay: Exam[] = [];

  /**
   * Array of clients that are currently being filtered.
   */
  private filteredClients: ClientInfo[] = [];

  /**
   * Triggers fetching of exams list
   */
  private startsWith$ = new BehaviorSubject<string>('');

  /**
   * Completes when the component is destroyed.
   */
  private destroyed$ = new Subject();

  public constructor(
    private examService: ExamsService,
    private autoCompleteStatusService: AutoCompleteStatusService,
    allPoolsFiltersService: AllPoolsFiltersService
  ) {
    // Subscribe to filtered client changes
    allPoolsFiltersService.selectedClients$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((selectedClients) => {
        this.filteredClients = selectedClients;
      });

    // Fetch new list of exams whenever startsWith$ is updated
    this.startsWith$.pipe(
      switchMap((startsWith) => {
        this.autoCompleteStatusService.loading();
        return this.getExams(startsWith);
      })
    ).subscribe((exams) => {
      this.examsToDisplay = exams;
      this.autoCompleteStatusService.success();
    }, () => {
      this.examsToDisplay = [];
      this.autoCompleteStatusService.error();
    });
  }

  /**
   * Terminates all subscriptions.
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * @returns number of filtered clients.
   */
  public get numberOfFiltersClients(): number {
    return this.filteredClients.length;
  }

  /**
   * Determines if an exam is selected as a filter
   *
   * @param examId Exam Id to check
   * @returns true if exam is selected as a filter
   */
  public isExamSelected(examId: string): boolean {
    return Boolean(this.alreadySelectedExams.find((exam) => exam.id === examId));
  }

  /**
   * Add or remove exam from working array of exams
   *
   * @param event checkbox event
   * @param examId examId in context
   */
  public updateSelected(event: MatCheckboxChange, examId: string): void {
    if (!event.checked) {
      this.updateSelectedExams.emit(this.alreadySelectedExams.filter((e) => e.id !== examId));
      return;
    }
    const exam = this.examsToDisplay.find((e) => e.id === examId);
    if (exam) {
      this.updateSelectedExams.emit([
        ...this.alreadySelectedExams,
        exam,
      ]);
    }
  }

  /**
   * Trigger refresh of exam list with search term from input
   *
   * @param startsWith startsWith Exam parameter
   */
  public updateExamSearch(startsWith: string): void {
    this.startsWith$.next(startsWith);
  }

  /**
   * Get list of exams based on search term and filtered clients if they exist.
   *
   * @param startsWith search term to query against
   */
  private getExams(startsWith?: string): Observable<Exam[]> {
    this.autoCompleteStatusService.loading();

    const clientIds = this.filteredClients.map((c) => c.id);
    return this.examService.getExams({ limit: 50, startsWith, clientIds });
  }
}
