import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { SortDirection } from '@angular/material/sort';
import { utc } from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GetPoolsParameters, GetPoolsSortBy, PoolInfo, Queue } from 'api/types';
import { DISPLAY_DAY_FORMAT, EXCHANGE_FORMAT } from 'constants/date-formats';
import { PoolClientDisplayPipe } from 'pipes/pool-client-display.pipe';
import { AllPoolDataService } from 'services/all-pool-data.service';
import { AllPoolsFiltersService, DEFAULT_VALUES } from 'services/all-pools-filters.service';
import { formatQueueDisplay } from 'utils/format-queue';
import { PaginatorBase } from '../utils/paginator-base.class';

/**
 *  All pools table component
 */
@Component({
  selector: 'app-all-pools-table',
  templateUrl: './all-pools-table.component.html',
  styleUrls: [ './all-pools-table.component.scss' ],
  providers: [ PoolClientDisplayPipe ],
})
export class AllPoolsTableComponent extends PaginatorBase implements OnInit, OnDestroy {
  /**
   * Queues array for display
   */
  public queues: Queue[] = [];

  /**
   * Table properties
   */
  public tableData: PoolInfo[] = [];
  public tableColumns = [ 'name', 'status', 'client', 'exams', 'queue', 'start-date', 'end-date', 'menu' ];
  public sortBy: GetPoolsSortBy = DEFAULT_VALUES.sortBy;
  public sortDirection: SortDirection = DEFAULT_VALUES.direction;

  /**
   * Internal reference to the page status
   */
  public loading = false;

  /**
   * Completes when component is destroyed
   */
  private destroyed$ = new Subject();

  public constructor(
    private allPoolsFilterService: AllPoolsFiltersService,
    private allPoolsDataService: AllPoolDataService,
  ) {
    super(DEFAULT_VALUES);
  }

  public ngOnInit(): void {
    // Subscribe to pool data updates
    this.allPoolsDataService.allPoolsData$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((allPoolData) => {
        if (allPoolData) {
          const { items, sortBy, direction, limit, offset, total } = allPoolData;

          this.tableData = items;
          this.sortBy = this.convertSortBy(sortBy);
          this.sortDirection = direction;
          this.updatePaginator({ limit, offset, total });
        }
      });

    // Subscribe to page status
    this.allPoolsDataService.fetchingPools$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isFetching) => {
        this.loading = isFetching;
      });

    // Subscribe to queue updates
    this.allPoolsDataService.queues$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((queues) => {
        this.queues = queues;
      });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  // Display queue code
  public getQueueDisplay(pool: PoolInfo): string {
    const queue = this.queues.find((q) => q.id === pool.queueId);
    return formatQueueDisplay(queue);
  }

  /**
   * Update filter service with new params
   *
   * @param col column to sort
   */
  public sort(col: GetPoolsSortBy): void {
    const direction = this.sortDirection === 'asc' ? 'desc' : 'asc';
    const newParams: Partial<GetPoolsParameters> = {
      sortBy: col,
      direction: direction
    };
    this.allPoolsFilterService.updateParams(newParams);
  }

  /**
   * Update filter service with new offsets
   *
   * @param event event from paginator
   */
  public paginatorChange(event: PageEvent): void {
    const newParams: Partial<GetPoolsParameters> = {
      offset: event.pageSize * event.pageIndex,
      limit: event.pageSize
    };
    this.allPoolsFilterService.updateParams(newParams);
  }

  /**
   * @param date pool date in YYYY-MM-DD format
   * @returns date in localized display format
   */
  public formatPoolDate(date: string | null): string {
    return date ? utc(date, EXCHANGE_FORMAT).format(DISPLAY_DAY_FORMAT) : '';
  }

  /**
   * Converts the returned `sort-by` start & end date keys to column keys.
   */
  private convertSortBy(sortBy: string): GetPoolsSortBy {
    if (sortBy === 'startDate') {
      return 'start-date';
    }
    if (sortBy === 'endDate') {
      return 'end-date';
    }
    return sortBy as GetPoolsSortBy;
  }
}
