import { HourlyPoolScheduleItem } from 'api/types';

interface PoolTimings {
  poolStartTime: string;
  poolEndTime: string;
}

/**
 * Using a pool schedule get the start and end time for pool
 *
 * @param scheduleItems the hourly items in the schedule
 * @returns timings the start and end times
 */
export function getPoolStartEndTime(scheduleItems: HourlyPoolScheduleItem[]): PoolTimings {
  if (scheduleItems?.length) {
    return {
      poolStartTime: scheduleItems[ 0 ].startTime || '',
      poolEndTime: scheduleItems[ scheduleItems?.length - 1 ].endTime || ''
    };
  }

  return {
    poolStartTime: '',
    poolEndTime: ''
  };
}
