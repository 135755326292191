/* eslint-disable no-undefined */
import { TitleCasePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Moment, utc } from 'moment';
import { Subject } from 'rxjs';
import { skip, take, takeUntil } from 'rxjs/operators';
import { GetMetricsParameters } from 'api/types';
import { TranslatePipe, TranslationKey, TranslationLookup } from 'pipes/translate.pipe';
import { AppointmentsFiltersService, TimescaleUpdate } from 'services/appointments-filters.service';
import { AppointmentFilters, AppointmentFilterValue, Timescale } from 'types/AppointmentFilters';
import { formatQueueDisplay } from 'utils/format-queue';
import { applyFilterConfig, getFilterConfig } from '../appointments-filters/filter-config';
import { filtersToParams } from '../appointments-filters/filters-to-params';
import { OutageFiltersService } from 'services/outage-filters.service';

/**
 *  Filters for the outages page
 */
@Component({
  selector: 'app-outages-filters',
  templateUrl: './outages-filters.component.html',
  styleUrls: ['./outages-filters.component.scss']
})

export class OutagesFiltersComponent implements OnInit, OnDestroy {
  /**
   * State for all appointment filters
   */
  public filters: AppointmentFilters = getFilterConfig(Timescale.outagesyearly, false);

  /**
   * Formatting function for queue display, passed to queue select component
   */
  public formatQueueOption = formatQueueDisplay;

  /**
   * Date object that is used to determine correct offsets for timezones
   */
  public dateForTimezone: Moment = utc();

  /**
   * The localization keys to look up.
   */
  private translationKeys: TranslationKey[] = [
    'title.daily',
    'title.fifteenMinutes',
    'title.monthly',
    'title.oneDay',
    'title.oneHour',
    'title.sixHours',
    'title.utc',
    'title.weekly',
    'title.outagesmonthly',
    'title.outagesyearly'
  ]

  /**
   * A localized string lookup.
   */
  private translations: TranslationLookup = {};

  /**
   * Terminate subscriptions
   */
  private destroyed$ = new Subject();

  public constructor(
    private outageService: OutageFiltersService,
    private filterService: AppointmentsFiltersService,
    private titleCasePipe: TitleCasePipe,
    private translatePipe: TranslatePipe,
  ) { }

  public ngOnInit(): void {
    this.filterService.params$
      .pipe(take(1))
      .subscribe((params) => {
        // Only set initial filters
        this.setInitialFilters(params);
      });
    /*
     * Change timescale view if filter service timescale is updated
     * Skip the initial emission, initial filters are handled by `setInitialFilters`
     */
    this.filterService.timescale$
      .pipe(takeUntil(this.destroyed$), skip(1))
      .subscribe((updated) => {
        this.updateTimescale(updated);
      });

    // Load the localization translations
    this.translatePipe.loadTranslations(this.translationKeys)
      .pipe(take(1))
      .subscribe((result) => {
        this.translations = result;
      });
  }

  /**
   * Complete all subscriptions
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Translate & format individual timescale within the select
   */
  public formatTimescaleOption(t: Timescale): string {
    const translation = this.translations['title.' + t];
    return this.titleCasePipe.transform(translation);
  }

  /**
   * Update a single filter and trigger fetch of new metrics
   */
  public filterChanged(name: keyof AppointmentFilters, value: AppointmentFilterValue): void {
    if (this.filters[name]) {
      // Update filter config based on the timescale change
      if (name === 'timescale') {
        this.filters = applyFilterConfig(value as Timescale, this.filters);
      }

      if (name === 'startDate' && typeof value === 'string') {
        this.dateForTimezone = utc(value);
      }

      // Apply latest update
      this.filters[name].value = value;
      const filter = filtersToParams(this.filters);
      this.outageService.updateParams(filtersToParams(this.filters));
    }
  }

  /**
   * Update multiple filters based on the new timescale
   * Avoids trigger multiple network calls for consecutive filter changes
   */
  private updateTimescale(changes: TimescaleUpdate): void {
    const newFilters = applyFilterConfig(changes.timescale, this.filters);
    newFilters.timescale.value = changes.timescale;
    newFilters.interval.value = changes.interval;
    newFilters.startDate.value = changes.startDate;

    this.outageService.updateParams(filtersToParams(newFilters));
    this.filters = newFilters;
  }

  /**
   * Set initial value of the filters based on existing params
   */
  private setInitialFilters(params: GetMetricsParameters): void {
    // Set existing queue selection if it exists

    // If timescale is not the default, update the configuration
    const paramTimescale = Timescale.outagesyearly;
    if (this.filters.timescale.value !== paramTimescale) {
      this.filters.timescale.value = paramTimescale;
      this.filters = applyFilterConfig(this.filters.timescale.value, this.filters);
    }

    this.filters.startDate.value = this.filters.startDate.value;
    this.filters.interval.value = this.filters.interval.value;

    this.outageService.updateParams(filtersToParams(this.filters));
  }
}
