<!--
  Add image to tab order with tabindex="0",
  allows for NVDA to readout content during keyboard navigation
-->
<img
  [src]="imageSrc"
  [alt]="content"
  [matTooltip]="content"
  [matTooltipPosition]="position"
  tabindex="0"
/>
