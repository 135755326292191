import { AbstractControl, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

/**
 *  Handle errors from GetCalculation API.
 */
export class GetCalculationsErrorHandler implements ErrorStateMatcher {
  /**
   * Used to show error if GetCalculation API throws an error
   */
  public hasError = false;

  /**
   * Only display error if hasError is true
   */
  public isErrorState(control: UntypedFormControl | null): boolean {
    return Boolean(control?.value) && this.hasError;
  }

  /**
   * Passed as a validator for total appointments control.
   * Used so the parent form is not able to submit without a successful response from getCalculations
   */
  public getCalculationsValidator(control: AbstractControl): ValidationErrors | null {
    if (Boolean(control?.value) && this.hasError) {
      return {
        'get-calculations-error': control.value
      };
    }
    return null;
  }

  /**
   * Set `hasError` flag to show or hide the calculation error.
   */
  public setError(toggle: boolean): void {
    this.hasError = toggle;
  }
}
