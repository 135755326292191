<button class="donwload-template-button" (click)="downloadTemplate()">{{ 'button.downloadTemplate' | translate | async }}</button>
<form id="form" [formGroup]="uploadCapacityForm" autocomplete="off">
  <vue-select
    label="{{ 'title.queues' | translate | async }}"
    formControlName="queueId"
    [required]="true"
    [options]="queues"
    [getOptionDisplay]="getQueueDisplay"
    [errorMatcher]="suppressErrorState"
  ></vue-select>
  <div class="monthSelectorWrapper">
    <label class="label">{{
      'title.datePickerMonthYear' | translate | async
    }}*</label>
    <app-date-selection-filter
      [timescale]="filters.timescale.value"
      [startDate]="filters.startDate.value"
      (change)="dateChanged('startDate', $event.value)"
      groupPosition="last"
      [customStyle]="true"
    ></app-date-selection-filter>
  </div>
  <vue-text-area
    label="{{ 'title.comment' | translate | async }}"
    formControlName="comment"
    [required]="false"
    errorMessage="{{ commentErrorMessageKey() | translate | async }}"
  ></vue-text-area>

  <div class="wrapper" dragAndDrop (fileDropped)="dragDropFile($event)">
    <input
      #uploadElement
      class="upload-input"
      type="file"
      accept=".csv"
      (change)="onBrowseFile($event)"
      formControlName="file"
      [attr.aria-label]="
        'message.setCapacity.fileUpload.uploadCapacityFile' | translate | async
      "
    />
    <!-- clear botton -->
    <div class="clearUpload" *ngIf="status === 'success' || status === 'error'">
      <button
        mat-icon-button
        [attr.aria-label]="
          'message.setCapacity.fileUpload.cancel' | translate | async
        "
        class="upload-bar-cancel"
        (click)="clearUploaded()"
      >
        <mat-icon class="upload-bar-cancel-icon">close</mat-icon>
      </button>
    </div>
    <div class="upload-cloud">
      <img
        *ngIf="status === 'initial'"
        class="upload-cloud-initial"
        src="/assets/images/upload-cloud.svg"
        alt=""
      />
      <img
        *ngIf="status === 'loading'"
        class="upload-cloud-loading"
        src="/assets/images/upload-cloud-blue.svg"
        alt=""
      />
      <img
        *ngIf="status === 'error'"
        class="upload-cloud-error"
        src="/assets/images/upload-cloud-error.svg"
        alt=""
      />
      <img
        *ngIf="status === 'success'"
        class="upload-cloud-success"
        src="/assets/images/upload-cloud-success.svg"
        alt=""
      />
    </div>

    <!-- Initial & Error state -->
    <div
      *ngIf="status === 'initial' || status === 'error'"
      class="upload-content"
    >
      <span>
        {{ 'message.setCapacity.fileUpload.dragAndDrop' | translate | async }}
        <button class="browse-files-button">
          {{ 'message.setCapacity.fileUpload.browseFiles' | translate | async }}
        </button>
      </span>

      <!-- Error messages -->
      <ng-container *ngIf="status === 'error'">
        <div class="upload-content-error" aria-live="polite">
          <ng-container *ngIf="serverErrorMessage">
            {{ serverErrorMessage }}
          </ng-container>
          <ng-container *ngIf="!serverErrorMessage">
            {{ 'message.setCapacity.fileUpload.error' | translate | async }}
          </ng-container>
        </div>
      </ng-container>
    </div>

    <!-- Success state -->
    <div
      *ngIf="status === 'success'"
      class="upload-content upload-content-success"
      aria-live="polite"
    >
      <p>
        <strong class="file-name">{{ fileName }}</strong>
        {{ 'message.setCapacity.fileUpload.success' | translate | async }}
      </p>
      <p>
        {{ 'message.setCapacity.fileUpload.additional' | translate | async }}
        <button class="browse-files-button">
          {{ 'message.setCapacity.fileUpload.browseFiles' | translate | async }}
        </button>
      </p>
    </div>

    <!-- Uploaded state -->
    <div
      *ngIf="status === 'uploaded'"
      class="upload-content upload-content-success"
      aria-live="polite"
    >
      <p>
        <strong class="file-name">{{ fileName }}</strong>
        {{ 'message.setCapacity.fileUpload.uploaded' | translate | async }}
      </p>
      <p>
        {{ 'message.setCapacity.fileUpload.additional' | translate | async }}
        <button class="browse-files-button">
          {{ 'message.setCapacity.fileUpload.browseFiles' | translate | async }}
        </button>
      </p>
    </div>

    <!-- Loading state -->
    <div *ngIf="status === 'loading'" class="upload-content" aria-live="polite">
      {{ 'message.setCapacity.fileUpload.loading' | translate | async }}
      <div class="upload-bar-wrapper">
        <div class="upload-bar">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <button
          mat-icon-button
          [attr.aria-label]="
            'message.setCapacity.fileUpload.cancel' | translate | async
          "
          class="upload-bar-cancel"
          (click)="cancelUpload()"
        >
          <mat-icon class="upload-bar-cancel-icon">close</mat-icon>
        </button>
      </div>
    </div>
  </div>
</form>

<!--uploaded file table-->
<app-capacity-file-table [selectedQueueId]="selectedQueueId"></app-capacity-file-table>