import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { MonthlyDatepickerHeaderComponent } from './monthly-datepicker-header/monthly-datepicker-header.component';
import { FormControl } from '@angular/forms';

/**
 * Selector for monthly timescale, used by `DateSelectionLabelComponent`
 */
@Component({
  selector: 'app-monthly-datepicker',
  templateUrl: './monthly-datepicker.component.html',
  styleUrls: [ './monthly-datepicker.component.scss' ]
})
export class MonthlyDatepickerComponent implements OnChanges{
  @Input() public alertSpecificData: any=null;
  minDate:any;
  maxDate:any;
  /**
   * The current date selected in string format YYYY-MM-DD, eg '2021-03-28'
   */
  @Input() public startDate?: string | null;

  /**
   * Emits the first date within the new month that is selected
   */
  @Output() public monthChange = new EventEmitter<Moment>();

  /**
   * Reference to the material datepicker
   */
  @ViewChild('picker') public picker?: MatDatepicker<Moment>;

  /**
   * Custom header used on the datepicker
   *
   * Only allows navigation through the years
   */
  public monthlyDatepickerHeader = MonthlyDatepickerHeaderComponent;

  // date=new FormControl(new Date());
 

  /**
   * Emit start of the month and close the datepicker when a month is selected
   * 
   *
   */
ngOnChanges(changes: SimpleChanges): void {
  this.minDate=this.alertSpecificData ? this.alertSpecificData?.date :''
  this.maxDate=this.alertSpecificData ? this.alertSpecificData?.endDate :''
}

  public monthSelected(date: Moment): void {
    this.monthChange.emit(date.clone().startOf('month'));

    this.picker?.close();
  }

  /**
   * Opens the datepicker, used by consuming components
   */
  public open(): void {
    this.picker?.open();
  }
}
