import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  Component,
  ComponentRef,
  ElementRef,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AllPoolsFiltersService } from 'services/all-pools-filters.service';
import { VueOverlayOpenDirective } from 'vue/directives/vue-overlay-open/vue-overlay-open.directive';
import { MoreFiltersPaneComponent } from './more-filters-pane/more-filters-pane.component';

/**
 *  Component to display the currently selected "more" filters, and open the edit pane when clicked
 */
@Component({
  selector: 'app-more-filters',
  templateUrl: 'more-filters.component.html',
  styleUrls: [ 'more-filters.component.scss' ]
})
export class MoreFiltersComponent implements OnInit, OnDestroy {
  /**
   * Combined number of selected queue, exams, date filters
   */
  public selectedFiltersCount = 0;

  // Overlay properties
  public overlayRef: OverlayRef;
  public componentRef!: ComponentRef<MoreFiltersPaneComponent>;

  private destroyed$ = new Subject();

  public constructor(
    private overlay: Overlay,
    private hostElement: ElementRef,
    private allPoolsFilterService: AllPoolsFiltersService,
    private vueOverlayOpen: VueOverlayOpenDirective,
  ) {
    // Create overlay
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'multi-select-overlay',
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.hostElement)
        .withPush(false)
        .withFlexibleDimensions(false)
        .withPositions([ {
          overlayX: 'end',
          overlayY: 'top',
          originX: 'end',
          originY: 'bottom'
        }, ])
    });

    // Close overlay for a click on the backdrop
    this.overlayRef.backdropClick()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.closeOverlay();
      });

    // Close overlay on press of escape
    this.overlayRef.keydownEvents()
      .pipe(takeUntil(this.destroyed$))
      .pipe(filter((event) => event.key === 'Escape'))
      .subscribe(() => {
        this.closeOverlay();
      });
  }

  public ngOnInit(): void {
    // Update number of selected filters
    combineLatest([
      this.allPoolsFilterService.selectedExams$,
      this.allPoolsFilterService.selectedQueues$,
      this.allPoolsFilterService.selectedOpenAfterDate$,
      this.allPoolsFilterService.selectedOpenBeforeDate$,
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([ selectedExams, selectedQueues, openAfterDate, openBeforeDate ]) => {
        let filterCount = selectedExams.length + selectedQueues.length;

        // Add dates if they exist
        if (openAfterDate) {
          filterCount++;
        }
        if (openBeforeDate) {
          filterCount++;
        }

        this.selectedFiltersCount = filterCount;
      });
  }

  /**
   * Complete destroyed$ variable to terminate subscriptions
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Create and open MoreFiltersPaneComponent in ComponentPortal
   */
  public open(): void {
    const portal = new ComponentPortal(MoreFiltersPaneComponent);
    this.componentRef = this.overlayRef.attach(portal);

    this.componentRef.instance.closePane$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.closeOverlay();
      });

    // Add vue class name when overlay open
    this.vueOverlayOpen.addClassName();
  }

  /**
   * Close overlay
   */
  private closeOverlay(): void {
    this.vueOverlayOpen.removeClassName();
    this.overlayRef.detach();
  }
}
