import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ChartDataset, ChartOptions } from 'chart.js';
import { Observable, of, Subscription, timer } from 'rxjs';
import { take } from 'rxjs/operators';
import palette from 'components/charts/utils/chart-palette';
import { TimezoneDisplayPipe } from 'pipes/timezone-display.pipe';
import { TranslatePipe, TranslationKey, TranslationLookup } from 'pipes/translate.pipe';
import { SynchedScrollService } from 'services/synched-scroll.service';
import { ChartClickEvent } from 'types/ChartClickEvent';
import { FlaggedCapacityMetricItem } from 'types/FlaggedCapacityMetricItem';
import { LegendCategoryConfig } from 'types/LegendCategoryConfig';
import { Timezone } from 'types/Timezone';
import { formatTime } from 'utils/format-time';
import { CHART_HEIGHT_REMS, COLUMN_WIDTH_REMS, FIRST_COLUMN_WIDTH_REMS } from '../../utils/constants';
import { getYAxisConfig } from '../../utils/y-axis-config';
import { chartOptions } from './appointments-chart-daily.config';
import { formatDatasets } from './utils/format-datasets';

/**
 * Daily variant of the appointments chart
 * ChangeDetection set to OnPush to help manage Angular re-rendering
 */
@Component({
  selector: 'app-appointments-chart-daily',
  templateUrl: './appointments-chart-daily.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppointmentsChartDailyComponent implements OnInit, OnChanges, AfterViewInit {
  /**
   * An array of metrics with the critical flag set
   */
  @Input() public metricItems: FlaggedCapacityMetricItem[] = [];

  /**
   * The timezone selected by the user, if set
   */
  @Input() public timezone?: Timezone | null;

  /**
   * The width data-points should occupy, in rems
   */
  @Input() public columnWidthRems = COLUMN_WIDTH_REMS;

  /**
   * First column width to match
   */
  @Input() public firstColumnWidthRems = FIRST_COLUMN_WIDTH_REMS;

  /**
   * Whether to apply darkened tick lines to the top of the hour
   */
  @Input() public darkenTopOfTheHourTicks = false;

  @ViewChild('popoverTemplate') public popoverTemplate!: TemplateRef<unknown>;

  @ViewChild('scrollContainer') private scrollContainer: ElementRef | null = null;

  /**
   * Chart datasets
   */
  public datasets: ChartDataset[] = [];

  /**
   * Timezone label
   *
   * Observable to allow for dynamic timezone abbreviation
   */
  public timezoneLabel: Observable<string> = of('');

  /**
   * Secondary timezone label
   */
  public secondaryTimezoneLabel = ''

  /**
   * Primary X-axis labels
   */
  public xAxisLabels: string[] = []

  /**
   * Secondary X-axis labels
   */
  public secondaryXAxisLabels: string[] = []

  /**
   * Configuration objects for the legend
   */
  public legendCategories: LegendCategoryConfig[] = []

  /**
   * Chart configuration chartOptions
   */
  public options: ChartOptions = {};

  /**
   * Y-Axis configuration
   */
  public yAxisMax = 0;
  public yAxisStepSize = 0;

  /**
   * Overall width of the chart, irrespective of screen width.  Calculated based on the number of datapoints to show.
   */
  public chartWidthRems = 0;

  /**
   * Overall height of the chart in rems
   */
  public chartHeightRems = CHART_HEIGHT_REMS;

  public popoverEvent?: ChartClickEvent;
  scrollSubscription!: Subscription;

  /**
   * The localization keys to look up.
   */
  private translationKeys: TranslationKey[] = [
    'title.capacity',
    'title.registrations',
    'label.utc.timezone'
  ]

  /**
   * A localized string lookup.
   */
  private translations: TranslationLookup = {};

  @ViewChild('dailyscroll') private dailyscroll?: ElementRef;

  public constructor(
    private scrollService: SynchedScrollService,
    private cdr: ChangeDetectorRef,
    private translatePipe: TranslatePipe,
    private timezoneDisplayPipe: TimezoneDisplayPipe,
  ) {}

  public ngAfterViewInit(): void {
    this.scrollService.registerScrollContainer(this.scrollContainer?.nativeElement);
  }

  public ngOnDestroy(): void {
    this.scrollService.unregisterAll();
    this.scrollSubscription.unsubscribe();
  }

  public ngOnInit(): void {
    this.scrollSubscription = this.scrollService.scrollfromApptTable$.subscribe((value) => {
      if(this.dailyscroll){
        this.dailyscroll.nativeElement.scrollLeft = value;
      }
    });
    // Load the localization translations
    this.translatePipe.loadTranslations(this.translationKeys)
      .pipe(take(1))
      .subscribe((translations) => {
        this.translations = translations;
        this.legendCategories = [
          { label: translations[ 'title.capacity' ], color: palette.midNight, type: 'line' },
          { label: translations[ 'title.registrations' ], color: palette.blue, type: 'bar' }
        ];
      });
  }

  public ngOnChanges(): void {
    this.buildChart();
  }

  public setPopoverEvent(event: ChartClickEvent): void {
    this.popoverEvent = event;
  }

  /**
   * Construct chart inputs
   * Using timer(0) to allow for other UI processing while chart details are constructed
   */
  private buildChart(): void {
    if (!this.metricItems.length) {
      return;
    }

    this.datasets = [];
    timer(0).pipe(take(1)).subscribe(() => {
      const maxValue = this.metricItems.reduce((_max, item) => {
        return Math.max(_max, item.capacity || 0, item.registrations);
      }, 0);

      const { max, stepSize } = getYAxisConfig(maxValue);
      this.yAxisMax = max;
      this.yAxisStepSize = stepSize;
      this.options = chartOptions(max, stepSize, this.darkenTopOfTheHourTicks);
      this.datasets = formatDatasets(this.metricItems, this.translations);
      this.chartWidthRems = this.calculateChartWidth();

      if (this.timezone) {
      // Use dual labeling of the current timezone and UTC
        this.timezoneLabel = this.timezoneDisplayPipe.transform(this.timezone, 'condensed');
        this.secondaryTimezoneLabel = this.translations[ 'label.utc.timezone' ];
        this.xAxisLabels = this.metricItems.map((item) => formatTime(item.timestamp, this.timezone));
        this.secondaryXAxisLabels = this.metricItems.map((item) => formatTime(item.timestamp));
      } else {
      // Only use UTC labels
        this.timezoneLabel = this.translatePipe.transform('label.utc.timezone');
        this.secondaryTimezoneLabel = '';
        this.xAxisLabels = this.metricItems.map((item) => formatTime(item.timestamp));
        this.secondaryXAxisLabels = [];
      }

      this.scrollService.configure(this.columnWidthRems || 0, this.metricItems.length);

      // Set change detection for all updated properties
      this.cdr.detectChanges();
    });
  }

  /**
   * The spacing of each datapoint along the X-axis is controlled by the overall chart width
   *
   * @returns the overall chart width in rems
   */
  private calculateChartWidth(): number {
    return this.metricItems.length * this.columnWidthRems;
  }

  onScrolldaily(event: Event){
    this.scrollService.setChartScroll((event.target as HTMLElement).scrollLeft)
   }
}
