import { Pipe, PipeTransform } from '@angular/core';
import { take } from 'rxjs/operators';
import { TranslatePipe } from './translate.pipe';

interface NeededPoolDetails {
  isReleased?: boolean;
  registrations: number;
  capacity: number;
  utilization: number;
}

/**
 * A pipe to display pool occurrences with standard formatting
 */
@Pipe({
  name: 'poolOccurrenceDisplay'
})
export class PoolOccurrenceDisplayPipe implements PipeTransform {
  /**
   * Translate "Released"
   */
  private translatedReleased = '';

  public constructor(private translatePipe: TranslatePipe) {
    this.translatePipe.transform('label.released')
      .pipe(take(1))
      .subscribe((translation) => {
        this.translatedReleased = translation;
      });
  }

  public transform<P extends NeededPoolDetails>(pool: P, type: 'utilization' | 'capacity' | 'availability'): string {
    if (type === 'capacity') {
      return `${pool.registrations} / ${pool.isReleased ? this.translatedReleased : pool.capacity}`;
    }
    if (type === 'utilization') {
      return pool.isReleased ? '–' : `${(pool.utilization).toFixed(2)}%`;
    }
    if (type === 'availability') {
      let availability = pool.capacity - pool.registrations;
      return pool.isReleased ? this.translatedReleased : `${availability}`;
    }
    return '';
  }
}
