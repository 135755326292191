import { EventEmitter, ViewChild } from '@angular/core';
import { Component, Input, Output } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { Filter } from 'types/Filter';

export type GroupPosition = 'first' | 'last';

/**
 *  Filter component for single select
 */
@Component({
  selector: 'vue-filter-select',
  templateUrl: './vue-filter-select.component.html',
  styleUrls: [ './vue-filter-select.component.scss' ]
})
export class VueFilterSelectComponent<T> {
  /**
   * Filter in context for the select
   */
  @Input() public filter?: Filter<T>

  /**
   * Emits value of selected option
   */
  @Output() public change = new EventEmitter<{ value: T | undefined }>()

  /**
   * Optionally provide a function to format option labels
   */
  @Input() public format?: (option: T) => string

  /**
   * Set as `aria-label` on select element
   */
  @Input() public ariaLabel?: string

  /**
   * Optionally group with neighboring filters
   */
  @Input() public groupPosition?: 'first' | 'last'

  /**
   * When defined includes first option that will reset the select.
   */
  @Input() public resetOptions?: {includeReset: boolean, resetText: string};

  /**
   * Reference to the underlying material select
   */
  @ViewChild(MatSelect) private select?: MatSelect;

  /**
   * Handle selection of value
   */
  public selectionChanged(value: T): void {
    // Reset value of material select
    // eslint-disable-next-line no-undefined
    if (value === undefined && this.select) {
      this.select.value = value;
    }

    this.change.emit({ value });
  }
}
