<mat-form-field [appearance]="appearance">
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    [matDatepicker]="picker"
    [matDatepickerFilter]="boundDateFilter"
    [errorStateMatcher]="errorMatcher"
    [required]="required"
    [min]="getMinDate()"
    [max]="maxDate"
    [placeholder]="placeholder"
    [formControl]="control"
    (dateChange)="changed($event)"
    autocomplete="off"
  >
  <mat-datepicker-toggle *ngIf="!disable" matSuffix [for]="picker">
    <mat-icon matDatepickerToggleIcon>
      <img src="/assets/icons/icon-calendar.svg" alt="">
    </mat-icon>
  </mat-datepicker-toggle>
  <mat-datepicker
    #picker
    panelClass="datepicker-calendar"
  ></mat-datepicker>
  <mat-error class="error">{{ errorMessage }}</mat-error>
</mat-form-field>
