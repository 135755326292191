/* eslint-disable
  @typescript-eslint/explicit-module-boundary-types,
  @typescript-eslint/no-explicit-any
*/
import { Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { ControlContainer, ControlValueAccessor, UntypedFormControl, FormControlDirective } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';

/**
 *  Base class for form fields
 */
@Component({
  selector: '',
  template: ''
})
export class VueFormFieldBaseComponent implements ControlValueAccessor {
  /**
   * Label shown for text field
   */
  @Input() public label = '';

  /**
   * Placeholder for input
   */
  @Input() public placeholder = '';

  /**
   * Shows required asterisk on label
   */
  @Input() public required = false;

  /**
   * Appearance of Material Form Field
   */
  @Input() public appearance: MatFormFieldAppearance = 'outline';

  /**
   * Custom error matcher for when error state should be shown
   */
  @Input() public errorMatcher = new ErrorStateMatcher();

  /**
   * Custom error message
   */
  @Input() public errorMessage = '';

  /**
   * Form control that can be passed to underlying input element
   */
  @Input() public formControl!: UntypedFormControl;

  /**
   * Form control name that can be passed to underlying input element
   */
  @Input() public formControlName!: string;

  @Input() public disable = false;
  /**
   * Child Form Control Directive to get Control Accessor
   */
  @ViewChild(FormControlDirective, { static: true }) public formControlDirective!: FormControlDirective;

  public constructor(private controlContainer: ControlContainer) { }

  /**
   * Pass either FormControl or FormControlName to underlying input element
   */
  /* istanbul ignore next */
  public get control(): UntypedFormControl {
    return this.formControl || this.controlContainer.control?.get(this.formControlName);
  }

  /**
   * Checks validation of control
   */
  /* istanbul ignore next */
  public get invalid(): boolean {
    return this.control ? this.control.invalid : false;
  }

  /* istanbul ignore next */
  public writeValue(obj: any): void {
    this.formControlDirective.valueAccessor?.writeValue(obj);
  }

  /* istanbul ignore next */
  public registerOnChange(fn: any): void {
    this.formControlDirective.valueAccessor?.registerOnChange(fn);
  }
  /* istanbul ignore next */
  public registerOnTouched(fn: any): void {
    this.formControlDirective.valueAccessor?.registerOnTouched(fn);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('disable' in changes) {
      this.setDisabledState(Boolean(changes.disable.currentValue));
    }
  }

  public setDisabledState(isDisabled: boolean): void {
    // eslint-disable-next-line no-unused-expressions
    if (isDisabled && !this.control.disabled) {
      this.control.disable();
    }
    else if (!isDisabled && this.control.disabled) {
      this.control.enable();
    }
    //isDisabled ? this.control.disable() : this.control.enable();
  }
}
