import { ChartDataset } from 'chart.js';
import { barDataset } from 'components/charts/utils/bar-dataset';
import palette from 'components/charts/utils/chart-palette';
import { lineDataset } from 'components/charts/utils/line-dataset';
import { TranslationLookup } from 'pipes/translate.pipe';
import { FlaggedCapacityMetricItem } from 'types/FlaggedCapacityMetricItem';
import { NumericKeys } from 'types/numeric-keys';
import { CAPACITY_ID, REGISTRATIONS_ID } from '../appointments-chart-weekly.config';

/**
 * @param metricItems the metric items to pluck from
 * @param property the property to pluck
 * @returns values the plucked values
 */
function pluckData(
  metricItems: FlaggedCapacityMetricItem[],
  property: NumericKeys<FlaggedCapacityMetricItem>
):
  number[] {
  return metricItems.map((item) => {
    return item[ property ];
  });
}

/**
 * Create Chart JS datasets for the given metricItems
 *
 * @param metricItems the raw items to base chart values on
 * @param barThickness the barThickness property to include
 * @param translations the localization translation lookup
 * @returns array of the chart datasets
 */
export function formatDatasets(
  metricItems: FlaggedCapacityMetricItem[],
  barThickness: number,
  translations: TranslationLookup): ChartDataset[] {
  const barColors = metricItems.map((item) => {
    return item.critical ? palette.red : palette.blue;
  });

  // Retain "null" capacity values – these willl be displayed as gaps in the chart
  const capacityData = metricItems.map((i) => i.capacity);

  const capacityDataset = lineDataset(translations[ 'title.capacity' ], palette.midNight, capacityData, CAPACITY_ID);
  const registrationsData = pluckData(metricItems, 'registrations');
  const registrationsDataset = barDataset(
    translations[ 'title.registrations' ], REGISTRATIONS_ID, barColors, registrationsData, barThickness
  );
  return [ capacityDataset, registrationsDataset ];
}
