<h1 mat-dialog-title>
  {{ "title.unsavedChanges" | translate | async }}
</h1>
<p mat-dialog-content>
  {{ "message.leavePage.confirmation" | translate | async }}
</p>
<div mat-dialog-actions>
  <vue-basic-button (clicked)="data.onLeave()">
    {{ "button.leave" | translate | async }}
  </vue-basic-button>
  <vue-flat-button (clicked)="data.onStay()">
    {{ "button.stay" | translate | async }}
  </vue-flat-button>
</div>
