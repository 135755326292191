<div class="options-wrapper" cdkTrapFocus cdkTrapFocusAutoCapture role="dialog">
    <vue-filter-search
      class="search"
      placeholder="{{ 'label.placeholder.search' | translate | async }}"
      (searchValueChange)="updateClientSearch($event)"
    ></vue-filter-search>
    <app-auto-complete-loader>
      <div class="checkboxes" aria-live="polite">
        <mat-checkbox
          *ngFor="let client of clientsForDisplay"
          class="option"
          tabIndex="0"
          (change)="updateChecked($event, client.id)"
          [checked]="isClientChecked(client.id)"
        >
          {{client.name}}
        </mat-checkbox>
        <p *ngIf="clientsForDisplay.length === 0" class="no-results">
          {{ "message.noResultsToDisplay" | translate | async }}
        </p>
      </div>
    </app-auto-complete-loader>
    <div class="action-buttons-wrapper">
      <vue-basic-button
        class="cancel-button"
        (click)="cancelFilters()"
      >
        {{ "button.cancel" | translate | async }}
      </vue-basic-button>
      <vue-basic-button
        (click)="clearFilters()"
        color="warn"
      >
        {{ "button.clear" | translate | async }}
      </vue-basic-button>
      <vue-flat-button (click)="applyFilters()">
        {{ "button.apply" | translate | async }}
      </vue-flat-button>
    </div>
  </div>
  