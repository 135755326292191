import { Interval, Queue } from 'api/types';
import { Filter } from './Filter';

export enum Timescale {
  daily= 'daily',
  weekly = 'weekly',
  monthly = 'monthly',
  outagesmonthly = 'outagesmonthly',
  outagesyearly = 'outagesyearly'
}

export type AppointmentFilterValue = Queue | Timescale | Interval | string | undefined

export interface AppointmentFilters {
  queue: Filter<Queue>;
  timescale: Filter<Timescale>;
  interval: Filter<Interval>;
  startDate: Filter<string>;
}
