<app-nav-bar
  [userName]="userName"
></app-nav-bar>
<vue-nav-drawer
  [menuOptions]="sideNavMenuOptions"
>
  <div class="router-outlet-container">
    <router-outlet></router-outlet>
  </div>
  <vue-footer></vue-footer>

  <!-- Unsaved changes banner is rendered into this portal -->
  <!-- Logic contained in AppointmentsPageComponent -->
  <div id="unsaved-changes-portal"></div>
</vue-nav-drawer>
<app-privacy-banner></app-privacy-banner>
