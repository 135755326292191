/**
 * Returns true when the application is running in Safari
 */
export function isSafari(): boolean {
  /*
   * Take advantage that Apple Push Notifications Service is only available in Safari
   * and is stored within window.safari.pushNotification
   *
   * https://developer.apple.com/library/mac/documentation/NetworkingInternet/Conceptual/NotificationProgrammingGuideForWebsites/Introduction/Introduction.html
   */
  return 'safari' in window;
}
