import { Component, Input } from '@angular/core';
import { LegendCategoryConfig } from 'types/LegendCategoryConfig';

/**
 *  Displays small, colored line or bar markers suitable for legend symbols
 */
@Component({
  selector: 'app-legend-marker',
  templateUrl: './legend-marker.component.html',
  styleUrls: [ './legend-marker.component.scss' ]
})
export class LegendMarkerComponent {
  @Input() public color?: string;
  @Input() public type?: LegendCategoryConfig['type'];
}
