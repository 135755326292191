<vue-table-container>
  <table mat-table [dataSource]="occurrences">
    <caption class="visually-hidden">
      {{ 'table.caption.occurrences' | translate | async }}
    </caption>

    <!-- Date Column -->
    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef> {{ "title.date" | translate | async }} </mat-header-cell>
      <mat-cell *matCellDef="let occurrence">
        {{displayLocalizedDate(occurrence.date)}}
      </mat-cell>
    </ng-container>

    <!-- Time Column -->
    <ng-container matColumnDef="time">
      <mat-header-cell *matHeaderCellDef> {{ "title.time" | translate | async }} ({{ "title.utc" | translate | async }})
      </mat-header-cell>
      <mat-cell *matCellDef="let occurrence">
        {{occurrence.startTime}} – {{occurrence.endTime}}
         <!--  <vue-slide-toggle [checked]="!occurrence.isRestricted" (change)="checkboxChange(occurrence,$event)">
          </vue-slide-toggle> -->
      </mat-cell>
    </ng-container>

    <!-- Registration Column -->
    <ng-container matColumnDef="registration">
      <mat-header-cell
        *matHeaderCellDef
        [attr.aria-label]="('title.registrations' | translate | async)"
      >
        {{ "title.registrations.abbreviated" | translate | async }}
        <vue-image-tooltip
          *ngIf="displayRegistrationToolTip"
          imageSrc="/assets/icons/icon-exclamation.svg"
          content="{{ 'tooltip.message.registrations' | translate | async }}"
        ></vue-image-tooltip>
      </mat-header-cell>
      <mat-cell *matCellDef="let occurrence">
        {{registrationDisplay(occurrence)}}
      </mat-cell>
    </ng-container>

    <!-- Capacity Column -->
    <ng-container matColumnDef="capacity">
      <mat-header-cell *matHeaderCellDef>
        {{ "title.capacity" | translate | async }}
      </mat-header-cell>
      <mat-cell *matCellDef="let occurrence">
        {{ capacityDisplay(occurrence) | async }}
      </mat-cell>
    </ng-container>

    <!-- res/unres -->
    <ng-container matColumnDef="restriction">
      <mat-header-cell *matHeaderCellDef>
        {{ "title.resUnres" | translate | async }}
      </mat-header-cell>
      <mat-cell *matCellDef="let occurrence; let i = index">
        <mat-icon matTooltip="{{ getOccurrenceRestrictionToolTip() | async }}" style="cursor: pointer;margin-left: -4.2px;"
          *ngIf="occurrenceDateHasPassed(occurrence.date) && !occurrence.isViewRestriction && !occurrence.isLoading"
          (click)="clickViewRestriction(occurrence)">
          autorenew
        </mat-icon>
        <mat-spinner *ngIf="occurrence.isLoading" diameter="18"></mat-spinner>
        
        <vue-slide-toggle *ngIf="!occurrenceDateHasPassed(occurrence.date) || occurrence.isViewRestriction"
          [checked]="occurrence.isRestricted" [disabled]="occurrenceDateHasPassed(occurrence.date) || occurrence.isReleased"
          (change)="checkboxChange(occurrence,$event)">
        </vue-slide-toggle>
        <img *ngIf="!occurrenceDateHasPassed(occurrence.date) || occurrence.isViewRestriction" src="/assets/icons/icon-{{occurrence.isRestricted ? 'lock' : 'unlock'}}.svg" alt=""/>
        <vue-image-tooltip *ngIf="occurrence.isOverride && !occurrenceDateHasPassed(occurrence.date) && !occurrence.isReleased" imageSrc="/assets/icons/icon-exclamation.svg"
        content="{{ 'tooltip.message.override' | translate | async }}"></vue-image-tooltip>
      </mat-cell>
    </ng-container>

    <!-- Menu Column -->
    <ng-container matColumnDef="menu">
      <mat-header-cell *matHeaderCellDef>
        {{ 'table.heading.actions' | translate | async }}
      </mat-header-cell>
      <mat-cell *matCellDef="let occurrence">
        <app-pool-drawer-occurrence-menu
          *ngIf="shouldShowOccurrenceMenu(occurrence)"
          [date]="occurrence.date"
          [isReleased]="occurrence.isReleased"
          [poolDetails]="poolDetails"
          [registrations]="registrations"
          [pageRef]="pageRef"
          [isOverride]="occurrence.isOverride"
          [occurrenceDateHasPassed]="occurrenceDateHasPassed(occurrence.date)"
        ></app-pool-drawer-occurrence-menu>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: tableColumns;"
      [appBackgroundFade]='row.toggleBackgroundFade'
      [ngClass]="{'released': row.isReleased, 'override': row.isOverride && !occurrenceDateHasPassed(row.date)}"
    ></mat-row>

    <tr class="mat-row no-content" *matNoDataRow>
      <td class="mat-cell" colspan="9999">
        {{ 'message.poolOccurrence.noOccurrences' | translate | async }}
      </td>
    </tr>
  </table>
</vue-table-container>
