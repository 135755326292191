import { Component } from '@angular/core';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { GetClientsParameters, Queue } from 'api/types';
import { TranslatePipe } from 'pipes/translate.pipe';
import { QueuesService } from 'services/api/queues.service';
import { Filter } from 'types/Filter';
import { formatQueueDisplay } from 'utils/format-queue';
import { ClientsPageDataService } from 'services/clients-page-data.service';

export interface ClientFilters {
  queue: Filter<Queue>;
}

/**
 *  Filters for the clients page
 */
@Component({
  selector: 'app-clients-filters',
  templateUrl: './clients-filters.component.html',
  styleUrls: [ './clients-filters.component.scss' ]
})

export class ClientsFiltersComponent {
  /**
   * Used to signal the special "All Queues" option
   */
  private static readonly ALL_QUEUES_CODE = 'ALLQUEUES';

  /**
   * Initial search term
   */
  public initialSearchTerm = '';

  /**
   * How the queue should look as a dropdown option
   */
  public getQueueDisplay = formatQueueDisplay;

  /**
   * List of available queues
   */
  public queues: Queue[] = [];

  /**
   * Queue filter
   */
  public filters: ClientFilters = {
    queue: {
      enabled: true,
    }
  };

  public constructor(
    private clientsPageDataService: ClientsPageDataService,
    private queuesService: QueuesService,
    private translatePipe: TranslatePipe,
  ) {
    // Load queues & translation & previous filters
    combineLatest([
      this.queuesService.getQueues(),
      this.translatePipe.loadTranslations([ 'option.allQueues' ]),
      this.clientsPageDataService.params$
    ])
      .pipe(take(1))
      .subscribe(([ queueResponse, translations, params ]) => {
        // Create a filter option for all queues
        const allQueuesOption: Queue = {
          id: 'all',
          code: ClientsFiltersComponent.ALL_QUEUES_CODE,
          name: translations[ 'option.allQueues' ]
        };
        this.initialSearchTerm = params?.searchTerm || '';
        this.queues = [ allQueuesOption, ...queueResponse ];

        // Set initial queue from service or default to all queues
        this.filters.queue.value = this.queues.find((q) => params?.queueIds?.includes(q.id)) ?? allQueuesOption;
        this.filters.queue.options = this.queues;
      });
  }

  /**
   * Update client search param
   */
  public updateSearchParams(inputValue: string): void {
    const newParams: Partial<GetClientsParameters> = {
      searchTerm: inputValue,
    };
    this.clientsPageDataService.updateParams(newParams);
  }

  /**
   * Handle change in queue filter
   *
   * Note: there is only a single filter, so `filterItem` is ignored.
   */
  public filterChanged(filterItem: string, queueInfo?: Queue): void {
    // Pass empty array for all queues
    const ids = !queueInfo || queueInfo.code === ClientsFiltersComponent.ALL_QUEUES_CODE ? [] : [ queueInfo.id ];
    const newParams: Partial<GetClientsParameters> = {
      queueIds: ids,
    };
    this.clientsPageDataService.updateParams(newParams);
  }
}
