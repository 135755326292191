import { formatDate } from '@angular/common';
import { PoolStatus } from 'api/types';

/**
 * Determines if a pool/occurrence is editable or not based on the status
 *
 * @param pool Pool or Occurrence to check
 * @returns true if a pool/occurrence does not have the status of archived or complete
 */
export function isPoolEditable<P extends {status: PoolStatus}>(pool: P): boolean {
  const { status } = pool;
  return ![ 'archived', 'completed' ].includes(status);
}

export function isPoolArchived<P extends {status: PoolStatus}>(pool: P): boolean {
  const { status } = pool;
  return [ 'archived' ].includes(status);
}

export function isPoolEnds<P extends {endDate: string}>(pool: P): boolean {
  const { endDate } = pool;
  
  var currentDate = formatDate(
    new Date().toLocaleString(),
    'yyyy-MM-dd',
    'en-US',
    'UTC'
  );
  
  const StartDateOnly = endDate;

  if (StartDateOnly < currentDate) {
    return true;
  }

  return false;
}


