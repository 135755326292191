<button
  mat-button
  [disableRipple]="disableRipple"
  [disabled]="disabled || loading"
  [color]="color"
  [class.loading]="loading"
  (click)="handleClick($event)"
  [type]="type"
  [attr.form]="form"
>
  <ng-content></ng-content>
  <mat-spinner *ngIf="loading" [diameter]="20"></mat-spinner>
</button>
