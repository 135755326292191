import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Subject } from 'rxjs';
import {
  takeUntil
} from 'rxjs/operators';
import {
  GetAlerts,
  GetAlertsQueryParameters,
  GetAlertsResponse,
  GetAlertsSortBy,
} from 'api/types/endpoints/getAlerts';
import { ALERTS_DEFAULT_VALUES } from 'services/alerts-filters.service';
import { PaginatorBase } from '../utils/paginator-base.class';
import { AlertsFiltersService } from 'services/alerts-filters.service';
import { AlertsPageDataService } from 'services/alerts-page-data.service';
import { SortDirection } from '@angular/material/sort';
import { Queue } from 'api/types';
import { formatQueueDisplay } from 'utils/format-queue';
import { utc } from 'moment';
import { DISPLAY_DAY_FORMAT, EXCHANGE_FORMAT } from 'constants/date-formats';

/**
 * Client Table
 */
@Component({
  selector: 'app-alerts-table',
  templateUrl: './alerts-table.component.html',
  styleUrls: ['./alerts-table.component.scss'],
})
export class AlertsTableComponent
  extends PaginatorBase
  implements OnInit, OnDestroy {
  /**
   * Table properties
   */
  public alertsData: GetAlerts[] = [];
  public tableColumns = [
    'priority',
    'type',
    'queue',
    'createdon',
    'details',
    'status',
    'view'
  ];

  public sortBy: GetAlertsSortBy = ALERTS_DEFAULT_VALUES.sortBy;

  public sortDirection: SortDirection = ALERTS_DEFAULT_VALUES.direction;
  /**
   * Display loading state for table
   */
  public tableLoading = false;
  /**
   * Subject that completes when the component is destroyed
   */
  private destroyed$ = new Subject();

  public totalRecords: number = 0;
  public limit: number = 0;
  public offset: number = 0;
  public queues: Queue[] = [];

  public constructor(
    private alertsFilterService: AlertsFiltersService,
    private alertsPageDataService: AlertsPageDataService
  ) {
    super(ALERTS_DEFAULT_VALUES);
  }

  public ngOnInit(): void {
    this.alertsPageDataService.alertsResponse$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        const { items, limit, offset, total, sortBy, direction } = res;
        this.alertsData = items;
        this.totalRecords = total;
        this.limit = limit;
        this.offset = offset;
        this.sortBy = sortBy;
        this.sortDirection = direction;
        //this.pageIndex = this.getPageIndexForCurrentDate();
        this.updatePaginator({ limit, offset, total });
      });

    this.alertsPageDataService.fetchingAlerts$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isFetching) => {
        this.tableLoading = isFetching;
      });

    this.alertsPageDataService.queues$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((queues) => {
        this.queues = queues;
      });
  }

  public getQueueDisplay(alerts: GetAlerts): string {
    const queue = this.queues.find((q) => q.id === alerts.queueId);
    return formatQueueDisplay(queue);
  }

  /**
   * Terminate all subscriptions.
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Generate table date from templates response
   *
   * @param event event from paginator
   */
  public paginatorChange(event: PageEvent): void {
    const newParams: Partial<GetAlertsResponse> = {
      offset: event.pageSize * event.pageIndex,
      limit: event.pageSize,
    };
    this.alertsFilterService.updateParams(newParams);
    this.alertsPageDataService.searchAlerts();
  }

  public sort(col: GetAlertsSortBy): void {
    const direction = this.sortDirection === 'asc' ? 'desc' : 'asc';
    const newParams: Partial<GetAlertsQueryParameters> = {
      sortBy: col,
      direction: direction
    };
    this.alertsFilterService.updateParams(newParams);
    this.alertsPageDataService.searchAlerts();
  }

  setColorCode(code: string) {
    switch (code) {
      case "1":
        return 'red'
      case "2":
        return 'orange'
      case "3":
        return 'yellow'
      case "4":
        return 'blue'
      default:
        return 'grey'
      // code block
    }
  }

  public formatDate(date: string): string {
    const splitDate = date.split('T');
    const splitTime = splitDate[1];
    const shortTime = splitTime.slice(0, 5);

    return date ? utc(date, EXCHANGE_FORMAT).format(DISPLAY_DAY_FORMAT) + " at " + shortTime : '';
  }
}
