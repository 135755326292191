import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { VUE_DIALOG_CONFIG } from 'vue/utilities/vue-dialog-config';

/**
 *  Component for removing an individual outage
 */
@Component({
  selector: 'app-remove-outage',
  templateUrl: './remove-outage.component.html'
})
export class RemoveOutageComponent {
  public constructor(
  // eslint-disable-next-line @typescript-eslint/no-parameter-properties
    @Inject(MAT_DIALOG_DATA) public data: RemoveOutageInputs,
    private dialogRef: MatDialogRef<RemoveOutageComponent>
  ) {}

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public removeOutage(): void {
    this.data.removeOutageCallback();
    this.closeDialog();
  }
}

export const removeOutageConfig = {
  ...VUE_DIALOG_CONFIG,
};

export interface RemoveOutageInputs {

  /**
   * Title shown on top of dialog
   */
  title: Observable<string>;

  /**
   * Callback used when remove is chosen by the user
   */
  removeOutageCallback: () => void;
}
