import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { utc } from 'moment';
import { take } from 'rxjs/operators';
import { GetMetricsParameters } from 'api/types';
import { EXCHANGE_FORMAT, POPOVER_DETAIL_FORMAT } from 'constants/date-formats';
import { INTERVAL_1HR } from 'constants/intervals';
import { MetricsService } from 'services/api/metrics.service';
import { AppointmentsFiltersService } from 'services/appointments-filters.service';
import { ChartClickEvent } from 'types/ChartClickEvent';
import { FlaggedCapacityMetricItem } from 'types/FlaggedCapacityMetricItem';
import { addCriticalFlags } from 'utils/add-critical-flags';
import palette from '../../charts/utils/chart-palette';

interface HourlyBar {
  label: string;
  color: string;
  widthPercent: number;
}

/**
 * Popover with some details of the current day with Weekly actions
 */
@Component({
  selector: 'app-appt-six-hour-chart-popover',
  templateUrl: './appt-six-hour-chart-popover.component.html',
  styleUrls: [ './appt-six-hour-chart-popover.component.scss' ]
})
export class ApptSixHourChartPopoverComponent implements OnChanges {
  @Input() public metricItems?: FlaggedCapacityMetricItem[]
  @Input() public event?: ChartClickEvent

  public title?: string;

  /**
   * Array of each
   */
  public hourlyBars: HourlyBar[] = [];

  /**
   * Timestamp of the shown content, in UTC format
   */
  public timestamp = '';

  /**
   * Toggles loading spinner
   */
  public loading = true;

  public constructor(
    private getMetricsService: MetricsService,
    private filterService: AppointmentsFiltersService,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    // When a chart event or metric details change rebuild content
    if (changes.event || changes.metricItems) {
      this.buildContent();
    }
  }

  private buildContent(): void {
    this.loading = true;

    if (!this.event || !this.metricItems) {
      return;
    }

    const index = (this.event.active || [])[ 0 ].index;
    const metricItem = this.metricItems[ index ];
    const eventTime = utc(metricItem.timestamp);

    this.timestamp = eventTime.format();

    // We can render the title and link immediately; hourly details require an API call
    this.title = eventTime.format(POPOVER_DETAIL_FORMAT);

    // Fetch hourly details
    const params: GetMetricsParameters = {
      ...this.filterService.getCurrentValue(),
      startDate: eventTime.format(EXCHANGE_FORMAT),
      numberOfDays: 1, // Because the chart's last start time is 18:00, no need to worry about wrapping to the next day
      interval: INTERVAL_1HR,
    };
    this.getMetricsService.getMetrics(params)
      .pipe(take(1))
      .subscribe((metricResponse) => {
        this.loading = false;

        // Slice off the six hours after (and including) the event hour
        const hour = eventTime.hour();
        const hourlyMetrics = addCriticalFlags(metricResponse.metrics[ 0 ].items.slice(hour, hour + 6));
        const maxCapacity = hourlyMetrics.reduce((max, m) => {
          return Math.max(max, m.capacity || 0);
        }, 0);

        this.hourlyBars = hourlyMetrics.map((m) => {
          return {
            color: m.critical ? palette.red : palette.blue,
            widthPercent: (m.capacity || 0) / maxCapacity * 100,
            label: utc(m.timestamp).format('h:mm')
          };
        });
      });
  }
}
