<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

  <ng-container matColumnDef="fileName">
    <th mat-header-cell *matHeaderCellDef>File Name</th>
    <td
      mat-cell
      *matCellDef="let element"
      [matTooltip]="element.tooltip"
      matTooltipClass="multilineTooltip"
      matTooltipPosition="left"
      class="ellipsis"
    >
      {{ element.filename }}
    </td>
  </ng-container>

  <!-- <ng-container matColumnDef="queue">
    <th mat-header-cell *matHeaderCellDef>Queue</th>
    <td
      mat-cell
      *matCellDef="let element"
      [matTooltip]="element.queueName"
      class="ellipsis"
    >
      {{ element.queueName }}
    </td>
  </ng-container> -->

  <ng-container matColumnDef="month_year">
    <th mat-header-cell *matHeaderCellDef>Month/Year</th>
    <td mat-cell *matCellDef="let element">{{ element.month }}/{{ element.year }}</td>
  </ng-container>

  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef>Date</th>
    <td mat-cell *matCellDef="let element">{{ formatDate(element.day) }}</td>
  </ng-container>

  <ng-container matColumnDef="performedBy">
    <th mat-header-cell *matHeaderCellDef>Performed By</th>
    <td mat-cell *matCellDef="let element" [matTooltip]="element.createdBy">
      <div style="display: inline-flex">
        <div class="ellipsis positionAdjust">
          {{ element.createdBy }}
        </div>
        <div class="positionAdjust">
          <mat-icon style="cursor: pointer" (click)="downloadFile(element)"
            >download</mat-icon
          >
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<div *ngIf="loading" class="spinner">
  <mat-spinner diameter="32"></mat-spinner>
</div>

<vue-toast icon="success">
  <span toast-body>{{ toastMessage }}</span>
</vue-toast>
