<vue-filter-select
  ariaLabel="{{ 'label.aria.queue' | translate | async }}"
  [filter]="filters.queue"
  [format]="formatQueueOption.bind(this)"
  (change)="filterChanged('queue', $event.value)"
></vue-filter-select>
<app-date-selection-filter
  [timescale]="monthly"
  [startDate]="filters.startDate.value"
  (change)="filterChanged('startDate', $event.value)"
></app-date-selection-filter>
