import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FetchingPoolStatusService } from 'services/status/fetching-pool-status.service';
import { ERROR } from 'types/RequestStatus';

/**
 * Generic class that provides common functionality to integrate with `FetchingPoolStatusService`
 *
 * Implementing components:
 * - Call `fetchingPoolStatusComplete()` when destroyed
 * - Implement an error message with `showFetchingPoolError` & `retryFetchingPool()`
 */
export class FetchesPoolBaseClass {
  /**
   * Display an error when fetching a pool for editing fails
   */
  public showFetchingPoolError = false;

  /**
   * Completes to cancel fetching pool status subscription
   */
  private closeSubscription$ = new Subject();

  public constructor(private fetchingPoolStatusService: FetchingPoolStatusService) {
    // Listen for fetching pool error
    fetchingPoolStatusService.status$
      .pipe(takeUntil(this.closeSubscription$))
      .subscribe((status) => {
        this.showFetchingPoolError = status === ERROR;
      });
  }

  /**
   * Retries the fetching pool for editing.
   */
  public retryFetchingPool(): void {
    this.fetchingPoolStatusService.retry();
  }

  /**
   * Closes fetching pool status subscription
   */
  public fetchingPoolStatusComplete(): void {
    this.closeSubscription$.next();
    this.closeSubscription$.complete();
  }
}
