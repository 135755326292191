import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatCard } from '@angular/material/card';
import { ScrollUtils } from 'utils/scroll-utils.class';

/**
 * Error Message component that displays a title and message
 */
@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: [ './error-message.component.scss' ]
})
export class ErrorMessageComponent implements AfterViewInit {
  /**
   * Error heading
   */
  @Input() public heading?: string;

  /**
   * Error message
   */
  @Input() public message?: string;

  /**
   * Has an action child
   */
  @Input() public hasAction = false;

  /**
   * Reference to the MatCard element
   */
  @ViewChild(MatCard, { read: ElementRef }) public matCard?: ElementRef;

  public ngAfterViewInit(): void {
    // Scroll error message into view if it is out of view
    if (this.matCard) {
      ScrollUtils.scrollIntoViewIfNeeded(this.matCard.nativeElement);
    }
  }
}
