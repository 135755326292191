<div [ngClass]="loading ? 'header-wrapper center' : 'header-wrapper'">
    <h1 mat-dialog-title class="title">{{ dialogTitle }}</h1>
    <p class="subtitle">{{ dialogSubtitle }}</p>
</div>
<mat-dialog-content class="mat-typography content-wrapper">
    <div class="spinner-wrapper" *ngIf="loading">
        <mat-spinner diameter="32"></mat-spinner>
    </div>
</mat-dialog-content>
<div mat-dialog-actions *ngIf="!loading">
    <vue-basic-button (clicked)="closeDialog()" *ngIf="failure">
        {{ "button.cancel" | translate | async }}
    </vue-basic-button>
    <vue-flat-button (clicked)="retry()" *ngIf="failure">
        {{ "button.retry" | translate | async }}
    </vue-flat-button>
    <vue-flat-button (clicked)="closeDialog()" *ngIf="success">
        {{ "button.close" | translate | async }}
    </vue-flat-button>
</div>
