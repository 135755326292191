import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Observable, Subject, of } from 'rxjs';
import {
  takeUntil
} from 'rxjs/operators';
import {
  GetOutage,
  GetOutageResponse,
} from 'api/types';
import { TranslatePipe, TranslationKey } from 'pipes/translate.pipe';
import { DEFAULT_VALUES } from 'services/outage-filters.service';
import { PaginatorBase } from '../utils/paginator-base.class';
import { TimezoneService } from 'services/timezone.service';
import { Timezone } from 'types/Timezone';
import { OutageFiltersService } from 'services/outage-filters.service';
import { OutagePageDataService } from 'services/outage-page-data.service';
import { IANA_CODE_DISPLAY_KEY } from 'constants/timezones';
import { getOffsetDisplay } from 'utils/format-time';
import { formatDate } from '@angular/common';
import { OutagesService } from 'services/api/outages.service';
import { utc } from 'moment';
import { DISPLAY_DAY_FORMAT, EXCHANGE_FORMAT } from 'constants/date-formats';

/**
 * Client Table
 */
@Component({
  selector: 'app-outages-table',
  templateUrl: './outages-table.component.html',
  styleUrls: ['./outages-table.component.scss'],
})
export class OutagesTableComponent
  extends PaginatorBase
  implements OnInit, OnDestroy {
  /**
   * Timezone label
   *
   * Observable to allow for dynamic timezone abbreviation
   */
  public timezoneLabel: Observable<string> = of('');
  /**
   * Table properties
   */
  public outageData: GetOutage[] = [];
  public tableColumns = [
    'name',
    'startday',
    'startdate',
    'starttime',
    'endday',
    'enddate',
    'endtime',
    'createdby',
    'actions',
  ];

  /**
   * Display loading state for table
   */
  public tableLoading = false;
  /**
   * Subject that completes when the component is destroyed
   */
  private destroyed$ = new Subject();

  public timezone: Timezone | null = null;

  public totalRecords: number = 0;
  public limit: number = 0;
  public offset: number = 0;

  public constructor(
    private outageFilterService: OutageFiltersService,
    private outagePageDataService: OutagePageDataService,
    private translatePipe: TranslatePipe,
    timezoneService: TimezoneService,
    private outageService: OutagesService
  ) {
    super(DEFAULT_VALUES);

    timezoneService.selectedTimezone$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((tz) => {
        this.timezone = tz;
      });

    this.outagePageDataService.outageResponse$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        const { items, limit, offset, total } = res;
        this.outageData = items;
        this.totalRecords = total;
        this.limit = limit;
        this.offset = offset;
        //this.pageIndex = this.getPageIndexForCurrentDate();
        this.updatePaginator({ limit, offset, total });
      });

    this.outagePageDataService.fetchingOutage$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isFetching) => {
        this.tableLoading = isFetching;
      });

    this.outageService.outageSuccess$
      .subscribe(({ outageId, type }) => {
        if (type) {
          if (type === 'delete') {
            var index = this.outageData.findIndex(i => i.id == outageId);
            if (index > -1) {
              this.outageData = this.outageData.filter(i => i.id != outageId);
              
              var total: number = this.totalRecords - 1;
              var limit: number = this.limit;
              var offset = this.offset;
              this.updatePaginator({ limit, offset, total });
            }
          }
        }
      });
  }

  // getPageIndexForCurrentDate() {
  //   let pageIndex = 0;
  //   let currentDate = utc();
  //   let tableDataPastDates = this.outageData.filter(item => utc(item.startdate).format(EXCHANGE_FORMAT) <= currentDate.format(EXCHANGE_FORMAT));
  //   if ((tableDataPastDates.length - this.pageSize) > this.pageSize) {
  //     pageIndex = Math.ceil((tableDataPastDates.length - this.pageSize) / this.pageSize);
  //   }

  //   return pageIndex;
  // }

  public ngOnInit(): void {
  }

  /**
   * Terminate all subscriptions.
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Generate table date from templates response
   *
   * @param event event from paginator
   */
  public paginatorChange(event: PageEvent): void {
    const newParams: Partial<GetOutageResponse> = {
      offset: event.pageSize * event.pageIndex,
      limit: event.pageSize,
    };
    this.outageFilterService.updateParams(newParams);
  }

  /**
   * Returns split time and date from dateTime Object
   */
  public splitDateTime(dateObject: string, type: string) {
    const splitDate = dateObject.split('T');
    const dateOnly = splitDate[0];
    const splitTime = splitDate[1];
    const shortTime = splitTime.slice(0, 5);
    if (type === 'date') {
      return dateOnly;
    } else {
      return shortTime;
    }

    // this.combineDateTime(dateOnly, shortTime);
  }

  public combineDateTime(date: string, time: string) {
    console.log(date + 'T' + time + ':00.000Z');
  }

  public transformDateTime(dateObject: string, type: string) {
    if (this.timezone) {
      const str = new Date(dateObject).toLocaleString('en-US', {
        timeZone: this.timezone.ianaCode,
        hour12: false,
        formatMatcher: "basic"
      });
      const abbr = this.timezoneTranslationKeys(this.timezone);
      this.timezoneLabel = this.translatePipe.transform(abbr.abbrKey);
      const split = str.split(',');

      if (type === 'date') {
        return formatDate(
          str,
          'yyyy-MM-dd',
          'en-US'
        );
      } else {
        return split[1].slice(0, 6);
      }
    } else {
      const splitDate = dateObject.split('T');
      const dateOnly = splitDate[0];
      const splitTime = splitDate[1];
      const shortTime = splitTime.slice(0, 5);

      this.timezoneLabel = this.translatePipe.transform('title.utc');
      if (type === 'date') {
        return dateOnly;
      } else {
        return shortTime;
      }
    }
  }

  private timezoneTranslationKeys(timezone: Timezone): { abbrKey: TranslationKey, ianaCodeKey: TranslationKey } {
    const offset = getOffsetDisplay(timezone.offset);
    const abbrKey = timezone.displayTranslations[`UTC${offset}-abbr`];
    const ianaCodeKey = timezone.displayTranslations[IANA_CODE_DISPLAY_KEY];
    return {
      abbrKey,
      ianaCodeKey,
    };
  }

  public formatDate(date: string | null): string {
    return date ? utc(date, EXCHANGE_FORMAT).format(DISPLAY_DAY_FORMAT) : '';
  }
}
