import { ChartOptions, ScriptableScaleContext } from 'chart.js';
import { TranslationLookup } from 'pipes/translate.pipe';
import { LegendCategoryConfig } from 'types/LegendCategoryConfig';
import { baseChartOptions } from '../utils/base-chart-options';
import palette from '../utils/chart-palette';
import { TICK_EXTENSION_HEIGHT_PX } from '../utils/constants';

export const POOLS_X_AXIS_ID = 'queue-pool-availability';

/**
 * @param yAxisMax the max Y value
 * @param yAxisStepSize the Y axis step size
 * @param firstDaysOfTheWeekIndices data point indices for the first days of the week
 * @returns ChartOptions
 */
export function chartOptions(
  yAxisMax: number, yAxisStepSize: number, firstDaysOfTheWeekIndices: number[]
): ChartOptions {
  const baseConfig = baseChartOptions(yAxisMax, yAxisStepSize);
  return {
    ...baseConfig,
    scales: {
      ...baseConfig.scales,
      [ POOLS_X_AXIS_ID ]: {
        axis: 'x',
        position: 'top', // Extend grid lines above the top x-axis (see also: tickLength)
        ticks: {
          display: false, // Hide actual axis labels
        },
        grid: {
          tickLength: TICK_EXTENSION_HEIGHT_PX, // Amount to extend gridlines above the axis
          borderColor: palette.grayF,
          color: (context: ScriptableScaleContext) => {
            // Mark the first day of the week with a line, other vertical gridlines won't be displayed
            return isFirstDayOfWeek(context, firstDaysOfTheWeekIndices) ? palette.grayD : '';
          },
        },
      },
    }
  };
}

/**
 * @param context the context provided by ChartJS
 * @param firstDaysOfTheWeekIndices data point indices for the first days of the week
 * @returns boolean
 */
function isFirstDayOfWeek(context: ScriptableScaleContext, firstDaysOfTheWeekIndices: number[]): boolean {
  return firstDaysOfTheWeekIndices.includes(context?.index);
}

/**
 * @param includeQueueCapacity whether to display the queue capacity
 * @param translations the localization translation lookup.
 * @returns LegendCategoryConfig[]
 */
export function legendCategories(
  includeQueueCapacity: boolean,
  translations: TranslationLookup): LegendCategoryConfig[] {
  const categories: LegendCategoryConfig[] = [
    { label: translations[ 'label.queueAvailability' ], color: palette.grayE, type: 'bar' },
    { label: translations[ 'label.poolAvailability' ], color: palette.blue, type: 'bar' }
  ];

  if (includeQueueCapacity) {
    categories.push({ label: translations[ 'label.queueCapacity' ], color: palette.midNight, type: 'line' });
  }

  return categories;
}
