import { MatDialogConfig } from '@angular/material/dialog';

/**
 * Default configurations for VUEUI Dialogs
 */
export const VUE_DIALOG_CONFIG: MatDialogConfig = {
  disableClose: true,
  width: '30rem',
  panelClass: [ 'vue-dialog' ],
  autoFocus: false,
};
