<div class="wrapper">
  <div class="search-wrapper">
    <vue-filter-search
      placeholder="{{ 'label.placeholder.searchByPoolNames' | translate | async }}"
      (searchValueChange)="updateSearchParams($event)"
      [inputValue]="initialSearchTerm"
    ></vue-filter-search>
  </div>
  <vue-filter-select
    ariaLabel="{{ 'label.aria.queue' | translate | async }}"
    [filter]="filters.queue"
    [format]="getQueueDisplay.bind(this)"
    (change)="filterChanged('queue', $event.value)"
  ></vue-filter-select>
</div>
