import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import {
  UnsavedChangesComponent,
  UnsavedChangesDialogData
} from 'components/dialogs/unsaved-changes/unsaved-changes.component';
import { VUE_DIALOG_CONFIG } from 'vue/utilities/vue-dialog-config';

/**
 * Service that displays an unsaved changes warning.  Modal content comes from UnsavedChangesComponent.
 */
@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesDialogService {
  public constructor(private dialog: MatDialog) {}

  /**
   * Open the warning dialog
   *
   * @returns {Observable<boolean>} an observable that will emit the user's choice: true -> leave, false -> stay.
   */
  public open(): Observable<boolean> {
    const choseToLeave = new Subject<boolean>();
    const dialogRef = this.dialog.open<UnsavedChangesComponent, UnsavedChangesDialogData>(UnsavedChangesComponent, {
      ...VUE_DIALOG_CONFIG,
      data: {
        onLeave: () => {
          choseToLeave.next(true);
          dialogRef.close();
        },
        onStay: () => {
          choseToLeave.next(false);
          dialogRef.close();
        },
      }
    });
    return choseToLeave;
  }
}
