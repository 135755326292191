import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { ChartDataset, ChartOptions } from 'chart.js';
import { utc } from 'moment';
import { Subject, timer } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import palette from 'components/charts/utils/chart-palette';
import { ABBREV_DAY_FORMAT } from 'constants/date-formats';
import { INTERVAL_1HR, INTERVAL_6HR } from 'constants/intervals';
import { TranslatePipe, TranslationKey, TranslationLookup } from 'pipes/translate.pipe';
import { GetComputedStyleService } from 'services/get-computed-style.service';
import { SynchedScrollService } from 'services/synched-scroll.service';
import { WindowResizeService } from 'services/window-resize.service';
import { ChartClickEvent } from 'types/ChartClickEvent';
import { FlaggedCapacityMetricItem } from 'types/FlaggedCapacityMetricItem';
import { LegendCategoryConfig } from 'types/LegendCategoryConfig';
import { formatTime } from 'utils/format-time';
import { pxToRem } from 'utils/rem-utils';
import { CHART_HEIGHT_REMS, FIRST_COLUMN_WIDTH_REMS } from '../../utils/constants';
import { getYAxisConfig } from '../../utils/y-axis-config';
import { chartOptions } from './appointments-chart-weekly.config';
import { formatDatasets } from './utils/format-datasets';

export const SUPPORTED_INTERVALS = [ INTERVAL_1HR, INTERVAL_6HR ] as const;

// Interval-specific UI configuration
export const DATAPOINT_WIDTH_REMS_1H = 0.75;
export const DATAPOINT_WIDTH_REMS_6H = 2;
interface IntervalConfig {
  [key: string]: { barWidth: number, datapointWidthRems: number, scrollIncrement: number };
}
const INTERVAL_CONFIG: IntervalConfig = {};
INTERVAL_CONFIG[ INTERVAL_1HR ] = {
  datapointWidthRems: DATAPOINT_WIDTH_REMS_1H,
  barWidth: 8,
  scrollIncrement: 24,
};
INTERVAL_CONFIG[ INTERVAL_6HR ] = {
  datapointWidthRems: DATAPOINT_WIDTH_REMS_6H,
  barWidth: 24,
  scrollIncrement: 4,
};

/**
 *  Weekly variant of the appointments chart
 *  ChangeDetection set to OnPush to help manage Angular re-rendering
 */
@Component({
  selector: 'app-appointments-chart-weekly',
  templateUrl: './appointments-chart-weekly.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppointmentsChartWeeklyComponent implements OnInit, OnChanges, AfterViewInit {
  /**
   * An array of metrics with the critical flag set
   */
  @Input() public metricItems: FlaggedCapacityMetricItem[] = [];

  /**
   * An array of date strings representing the days included in YYYY-MM-DD format
   */
  @Input() public days: string[] = [];

  /**
   * The user-selected interval. Only supports 1hr or 6hr, expecting the UI to prevent others
   */
  @Input() public interval: typeof SUPPORTED_INTERVALS[number] = INTERVAL_1HR;

  @ViewChild('scrollContainer') private scrollContainer: ElementRef | null = null;

  /**
   * First column width to match
   */
  public firstColumnWidthRems = FIRST_COLUMN_WIDTH_REMS;

  /**
   * Chart datasets
   */
  public datasets: ChartDataset[] = [];

  /**
   * Configuration objects for the legend
   */
  public legendCategories: LegendCategoryConfig[] = []

  /**
   * Chart configuration options
   */
  public options: ChartOptions = {};

  /**
   * Y-Axis configuration
   */
  public yAxisMax = 0;
  public yAxisStepSize = 0;

  /**
   * Overall width of the chart, irrespective of screen width.  Calculated based on the number of datapoints to show.
   */
  public chartWidthRems = 0;

  /**
   * The width of day labels.  Usually matches config, but can be overridden to fit screen.
   */
  public dayLabelWidthRems = 0;

  /**
   * Overall height of the chart in rems
   */
  public chartHeightRems = CHART_HEIGHT_REMS;

  public popoverEvent?: ChartClickEvent;

  private destroyed = new Subject();

  /**
   * The localization keys to look up.
   */
  private translationKeys: TranslationKey[] = [
    'title.capacity',
    'title.registrations',
  ]

  /**
   * A localized string lookup.
   */
  private translations: TranslationLookup = {};

  public constructor(
    private scrollService: SynchedScrollService,
    private getComputedStyleService: GetComputedStyleService,
    private cdr: ChangeDetectorRef,
    private translatePipe: TranslatePipe,
    windowResize: WindowResizeService,
  ) {
    // Recalculate chart width if window resizes
    windowResize.resized$
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
        this.setChartWidth();
      });
  }

  public ngAfterViewInit(): void {
    this.scrollService.registerScrollContainer(this.scrollContainer?.nativeElement);
  }

  public ngOnDestroy(): void {
    this.scrollService.unregisterAll();
    this.destroyed.next();
    this.destroyed.complete();
  }

  public ngOnInit(): void {
    // Load the localization translations
    this.translatePipe.loadTranslations(this.translationKeys)
      .pipe(take(1))
      .subscribe((translations) => {
        this.translations = translations;
        this.legendCategories = [
          { label: translations[ 'title.capacity' ], color: palette.midNight, type: 'line' },
          { label: translations[ 'title.registrations' ], color: palette.blue, type: 'bar' }
        ];
      });
  }

  public ngOnChanges(): void {
    this.buildChart();
  }

  // Retrieve an interval-specific configuration value
  public getConfigVal(key: keyof IntervalConfig[number]): number {
    return INTERVAL_CONFIG[ this.interval ][ key ];
  }

  public getDayLabels(): string[] {
    return this.days.map((d) => {
      const date = utc(d);
      const dayOfWeek = date.format(ABBREV_DAY_FORMAT);
      const dayOfMonth = date.format('D');
      return `${dayOfWeek} ${dayOfMonth}`;
    });
  }

  public getChartItemLabels(): string[] {
    return this.metricItems.map((m) => {
      return formatTime(m.timestamp);
    });
  }

  public getBottomXAxisLabels(): string[] {
    const labels = this.metricItems.map((m) => {
      return formatTime(m.timestamp);
    });

    // For one-hour mode, only display hours at the day boundary.
    if (this.interval === INTERVAL_1HR) {
      const keepers = [ '0:00', '23:00' ];
      return labels.map((label) => {
        return keepers.includes(label) ? label : ''; // Use an empty string so we can still use empty labels for layout
      });
    }

    return labels;
  }

  public setPopoverEvent(event: ChartClickEvent): void {
    this.popoverEvent = event;
  }

  /**
   * Construct chart inputs
   * Using timer(0) to allow for other UI processing while chart details are constructed
   */
  private buildChart(): void {
    if (!this.metricItems.length || !SUPPORTED_INTERVALS.includes(this.interval)) {
      return;
    }

    timer(0).pipe(take(1)).subscribe(() => {
      const maxValue = this.metricItems.reduce((_max, item) => {
        return Math.max(_max, item.capacity || 0, item.registrations);
      }, 0);

      const { max, stepSize } = getYAxisConfig(maxValue);
      this.yAxisMax = max;
      this.yAxisStepSize = stepSize;
      this.options = chartOptions(max, stepSize);
      this.datasets = formatDatasets(this.metricItems, this.getConfigVal('barWidth'), this.translations);

      this.setChartWidth();
    });
  }

  /**
   * Set chart width, accounting for container size and chart configuration.
   * If chart is narrower than the container allows, stretch it to fit the available space.
   */
  private setChartWidth(): void {
    /**
     * Using timer here to wait a tick to determine ScrollContainer width calculation
     * Otherwise scrollWidthRems can sometimes be half a column width larger than expected
     */
    timer(0).subscribe(() => {
      const configuredChartWidth = this.metricItems.length * this.getConfigVal('datapointWidthRems');
      let appliedDatapointWidthRems: number;

      let scrollWidthRems = 0;
      if (this.scrollContainer?.nativeElement) {
        const scrollContainerStyles = this.getComputedStyleService.getComputedStyle(this.scrollContainer.nativeElement);
        scrollWidthRems = scrollContainerStyles?.width ? pxToRem(parseFloat(scrollContainerStyles.width)) : 0;
      }

      const narrowerThanContainer = configuredChartWidth < scrollWidthRems;
      if (narrowerThanContainer) {
        // Stretch to fit
        this.dayLabelWidthRems = scrollWidthRems / 7;
        this.chartWidthRems = scrollWidthRems;
        appliedDatapointWidthRems = scrollWidthRems / this.metricItems.length;
      } else {
        // Use standard width from configuration
        this.dayLabelWidthRems = configuredChartWidth / 7;
        this.chartWidthRems = configuredChartWidth;
        appliedDatapointWidthRems = configuredChartWidth / this.metricItems.length;
      }

      this.scrollService.configure(
        appliedDatapointWidthRems,
        this.metricItems.length,
        this.getConfigVal('scrollIncrement')
      );

      // Set change detection for all updated properties
      this.cdr.detectChanges();
    });
  }
}
