import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Moment } from 'moment';

/**
 *  VUE date range picker
 */
@Component({
  selector: 'vue-date-range-picker',
  templateUrl: './vue-date-range-picker.component.html',
  styleUrls: [ './vue-date-range-picker.component.scss' ]
})
export class VueDateRangePickerComponent {
  /**
   * Initial start date
   */
  @Input() public initialStartDate: Moment | null = null

  /**
   * Initial end date
   */
  @Input() public initialEndDate: Moment | null = null

  /**
   * Placeholder for the start date input
   */
  @Input() public startDatePlaceholder = ''

  /**
   * Placeholder for the end date input
   */
  @Input() public endDatePlaceholder = ''

  /**
   * Event to emit on start date change
   * Emits null if input is empty
   */
  @Output() public startDateChange = new EventEmitter<Moment | null>();

  /**
   * Event to emit on end date change
   * Emits null if input is empty
   */
  @Output() public endDateChange = new EventEmitter<Moment | null>();
}
