import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetUsersResponse } from 'api/types';
import { CapacityMicroservice } from './microservices/capacity.microservice';

/**
 * API service for getting users
 */
@Injectable({
  providedIn: 'root'
})
export class UsersService {
  public constructor(private api: CapacityMicroservice) { }

  /**
   * Calls GetUsers API
   *
   * @returns GetUsersResponse Observable
   */
  public getUsers(): Observable<GetUsersResponse> {
    const path = 'users';

    return this.api.get<GetUsersResponse>(path);
  }
}
