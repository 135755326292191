import { Component, EventEmitter, Input, OnDestroy, Output, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export type VueDrawerConfig = Omit<MatDialogConfig, 'panelClass'>;

/**
 * Drawer component that uses Material Dialog
 */
@Component({
  selector: 'vue-drawer',
  styleUrls: [ './vue-drawer.component.scss' ],
  template: ''
})
export class VueDrawerComponent implements OnDestroy {
  /**
   * Reference to content of the drawer
   */
  @Input() public contentRef!: TemplateRef<unknown>;

  /**
   * Material Dialog config that overrides default config
   */
  @Input() public drawerConfig: Partial<VueDrawerConfig> = {}

  /**
   * Event emitted after drawer closed
   */
  @Output() public drawerClosed = new EventEmitter<void>();

  /**
   * Reference to the material dialog
   */
  public dialogRef: MatDialogRef<unknown> | undefined;

  private destroyed$ = new Subject();

  /**
   * Default config to pin drawer to right side
   */
  private defaultDrawerConfig: Partial<MatDialogConfig> = {
    autoFocus: false,
    hasBackdrop: true,
    height: '100%',
    maxWidth: '100%',
    panelClass: 'vue-drawer',
    position: {
      right: '0'
    },
    width: '37.5rem'
  }

  public constructor(private dialog: MatDialog) { }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Opens drawer
   */
  public open(): void {
    this.dialogRef = this.dialog.open(this.contentRef, {
      ...this.defaultDrawerConfig,
      ...this.drawerConfig
    });

    this.dialogRef.afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.drawerClosed.emit();
      });
  }

  /**
   * Closes drawer
   */
  public close(): void {
    /* istanbul ignore else */
    if (this.dialogRef) {
      this.dialogRef.addPanelClass('close-vue-drawer');

      // Animation duration defined in stylesheet
      setTimeout(() => {
        this.dialogRef?.close();
      }, 300);
    }
  }
}
