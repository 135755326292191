import { Component, OnDestroy } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { SortDirection } from '@angular/material/sort';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ClientInfo, GetClientsParameters, GetClientsSortBy, GetPoolsParameters, Queue } from 'api/types';
import { TranslatePipe } from 'pipes/translate.pipe';
import { DEFAULT_VALUES } from 'services/clients-filters.service';
import { ClientsPageDataService } from 'services/clients-page-data.service';
import { PaginatorBase } from '../utils/paginator-base.class';
import { ExamsListComponent, ExamsListInputs, examsListConfig } from './exams-list/exams-list.component';
import { MatDialog } from '@angular/material/dialog';
import { LoadingDialogComponent, LoadingDialogInput, loadingDialogConfig } from 'components/dialogs/loading-dialog/loading-dialog.component';
import { AllPoolsFiltersService } from 'services/all-pools-filters.service';
import { Router } from '@angular/router';
import { QueuesService } from 'services/api/queues.service';

/**
 * Client Table
 */
@Component({
  selector: 'app-client-table',
  templateUrl: './client-table.component.html',
  styleUrls: [ './client-table.component.scss' ]
})
export class ClientTableComponent extends PaginatorBase implements OnDestroy {
  /**
   * Table properties
   */
  public clientData: ClientInfo[] = [];
  public tableColumns: GetClientsSortBy[] = [ 'name', 'exam-count', 'has-pools' ];
  public sortBy: GetClientsSortBy = DEFAULT_VALUES.sortBy;
  public sortDirection: SortDirection = DEFAULT_VALUES.direction;

  /**
   * Display loading state for table
   */
  public tableLoading = false;

  /**
   * translations
   */
  public translations: {[key: string]: string} = {};

  /**
   * Subject that completes when the component is destroyed
   */
  private destroyed$ = new Subject();

  private queueIds : string[] | undefined = [];

  public queues: Queue[] = [];

  public constructor(
    private clientsPageDataService: ClientsPageDataService,
    private translatePipe: TranslatePipe,
    private dialog: MatDialog,
    private allPoolsFilterService: AllPoolsFiltersService,
    private router: Router,
    private queuesService: QueuesService
  ) {
    super(DEFAULT_VALUES);

    // Load translations used dynamically in the table
    this.translatePipe.loadTranslations([ 'tooltop.message.client.viewexams', 'tooltop.message.client.viewpools' ])
      .pipe(take(1))
      .subscribe((translations) => {
        this.translations = translations;
      });

    // Update table when data service updates
    this.clientsPageDataService.clientResponse$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        const { items, sortBy, direction, limit, offset, total } = res;
        this.clientData = items;
        this.sortBy = sortBy;
        this.sortDirection = direction;

        this.updatePaginator({ limit, offset, total });
      });

    // Show the table loading when clients are being refreshed
    this.clientsPageDataService.fetchingClients$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((fetchingClients) => {
        this.tableLoading = fetchingClients;
      });

    this.clientsPageDataService.params$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((filters) => {
        this.queueIds = filters.queueIds;
      });

    this.queuesService.getQueues().pipe(take(1)).subscribe((queueResponse) => {
      this.queues = queueResponse;
    });
  }

  /**
   * Terminate all subscriptions.
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Update the sort column
   *
   * @param col the column to sort on
   */
  public sort(col: GetClientsSortBy): void {
    const direction = this.sortDirection === 'asc' ? 'desc' : 'asc';
    const newParams: Partial<GetClientsParameters> = {
      sortBy: col,
      direction: direction
    };
    this.clientsPageDataService.updateParams(newParams);
  }

  /**
   * Generate table date from templates response
   *
   * @param event event from paginator
   */
  public paginatorChange(event: PageEvent): void {
    const newParams: Partial<GetClientsParameters> = {
      offset: event.pageSize * event.pageIndex,
      limit: event.pageSize
    };
    this.clientsPageDataService.updateParams(newParams);
  }

  public openExamsListDialog(client: ClientInfo): void {
    if (client.examCount > 0) {
      const loader = this.dialog.open<LoadingDialogComponent, LoadingDialogInput>(LoadingDialogComponent, {
        ...loadingDialogConfig,
        data: {
          title: 'title.loading',
          subtitle: 'subtitle.pleaseWait',
        },
      });


      loader.close();
      this.dialog.open<ExamsListComponent, ExamsListInputs>(ExamsListComponent, {
        ...examsListConfig,
        data: {
          clientName: client.name,
          exams: client.examNames
        }
      });
    }
  }

  public openPoolsListDialog(client: ClientInfo): void {
    if (client.poolCount > 0) {
      this.router.navigate([`pools/all`]);
      this.allPoolsFilterService.addClientsToFilter([client]);
      const newParams: Partial<GetPoolsParameters> = {
        searchTerm: "",
        status: ["open", "scheduled"],
      };
      this.allPoolsFilterService.updateParams(newParams);

      if (this.queueIds?.length && this.queues) {
        var queueId = this.queueIds[0];
        var selectedQueue = this.queues.find(i => i.id == queueId);
        var queue: Queue = {
          code: selectedQueue?.code ? selectedQueue?.code : '',
          id: queueId,
          name: selectedQueue?.name ? selectedQueue?.name : '',
        }
        this.allPoolsFilterService.updateMoreFilters([], [queue], '', '');
      }
      else
      {
        this.allPoolsFilterService.updateMoreFilters();
      }
    }
  }
}
