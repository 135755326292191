/* eslint-disable no-underscore-dangle */
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ChartDataset, ChartOptions } from 'chart.js';
import { utc } from 'moment';
import { BehaviorSubject, Subject, timer } from 'rxjs';
import { distinctUntilKeyChanged, skip, take, takeUntil } from 'rxjs/operators';
import { PoolCapacityMetric } from 'api/types';
import { ABBREV_MONTH_YEAR_FORMAT, EXCHANGE_FORMAT } from 'constants/date-formats';
import { TranslatePipe, TranslationKey, TranslationLookup } from 'pipes/translate.pipe';
import { GetComputedStyleService } from 'services/get-computed-style.service';
import { PoolsPageService } from 'services/pools-page.service';
import { SynchedScrollService } from 'services/synched-scroll.service';
import { WindowResizeService } from 'services/window-resize.service';
import { ChartClickEvent, isChartClickEvent, UndifferentiatedChartEvent } from 'types/ChartClickEvent';
import { LegendCategoryConfig } from 'types/LegendCategoryConfig';
import { CHART_HEIGHT_REMS, DAY_COLUMN_WIDTH_REMS, FIRST_COLUMN_WIDTH_REMS } from '../utils/constants';
import { getYAxisConfig } from '../utils/y-axis-config';
import { chartOptions, legendCategories } from './pools-chart.config';
import { formatDatasets } from './utils/format-datasets';

/**
 *  Chart for displaying pool details for a date range.
 */
@Component({
  selector: 'app-pools-chart',
  templateUrl: './pools-chart.component.html',
})
export class PoolsChartComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  /**
   * An array of metrics with the critical flag set
   */
  @Input() public metrics: PoolCapacityMetric[] = [];

  /**
   * Emit index that was clicked on chart
   * Corresponds to day of the month
   */
  @Output() public dateIndexClick = new EventEmitter<number>();

  @ViewChild('scrollContainer') private scrollContainer: ElementRef | null = null;

  /**
   * Date determined by PoolFilters to display for YAxis
   */
  public filterDate = '';

  /**
   * Chart datasets
   */
  public datasets: ChartDataset[] = [];

  /**
   * Primary X-axis labels
   */
  public xAxisLabels: string[] = []

  /**
   * Configuration objects for the legend
   */
  public legendCategories: LegendCategoryConfig[] = []

  /**
   * Chart configuration chartOptions
   */
  public options: ChartOptions = {};

  /**
   * Y-Axis configuration
   */
  public yAxisMax = 0;
  public yAxisStepSize = 0;

  /**
   * Chart Height / Widths
   */
  public chartWidthRems = 0;
  public columnWidthRems = DAY_COLUMN_WIDTH_REMS;
  public firstColumnWidthRems = FIRST_COLUMN_WIDTH_REMS;
  public chartHeightRems = CHART_HEIGHT_REMS;

  /**
   * Show chart with queue capacity line or only queue/pool availability
   */
  public showQueueCapacity$ = new BehaviorSubject(true);

  public popoverEvent?: ChartClickEvent;

  private destroyed = new Subject();

  /**
   * A localized string lookup.
   */
  private translations: TranslationLookup = {};

  public constructor(
    private scrollService: SynchedScrollService,
    private getComputedStyleService: GetComputedStyleService,
    private translatePipe: TranslatePipe,
    poolsPageService: PoolsPageService,
    windowResize: WindowResizeService,
  ) {
    // Recalculate chart width if window resizes
    windowResize.resized$
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
        this.setChartWidth();
      });

    // Update Y-Axis date when filter date changes
    poolsPageService.poolsFilters$
      .pipe(takeUntil(this.destroyed), distinctUntilKeyChanged('startDate'))
      .subscribe((poolFilters) => {
        this.filterDate = poolFilters.startDate.value ?
          utc(poolFilters.startDate.value, EXCHANGE_FORMAT).format(ABBREV_MONTH_YEAR_FORMAT) :
          '';
      });
  }

  // Get the indices of all of the first days of the week; useful for chart formatting
  private static firstDayOfTheWeekIndices(metrics: PoolCapacityMetric[]): number[] {
    return metrics.reduce<number[]>((firstDays, metric, i) => {
      if (utc(metric.date).isoWeekday() === 7) {
        firstDays.push(i);
      }
      return firstDays;
    }, []);
  }

  public ngAfterViewInit(): void {
    this.scrollService.registerScrollContainer(this.scrollContainer?.nativeElement);
  }

  public ngOnDestroy(): void {
    this.scrollService.unregisterAll();
    this.destroyed.next();
    this.destroyed.complete();
  }

  public ngOnInit(): void {
    const keys: TranslationKey[] = [
      'label.poolAvailability',
      'label.queueAvailability',
      'label.queueCapacity',
    ];

    this.translatePipe.loadTranslations(keys)
      .pipe(take(1))
      .subscribe((translations) => {
        this.translations = translations;
        this.buildChart();
      });

    this.showQueueCapacity$
      .pipe(skip(1), takeUntil(this.destroyed))
      .subscribe(() => {
        this.setDatasets();
      });
  }

  public ngOnChanges(): void {
    this.buildChart();
  }

  public chartClick(event: UndifferentiatedChartEvent): void {
    if (isChartClickEvent(event) && event.active?.length) {
      this.dateIndexClick.emit(event.active[ 0 ].index);
      this.popoverEvent = event;
    }
  }

  private buildChart(): void {
    if (!this.metrics.length) {
      return;
    }

    this.xAxisLabels = this.metrics.map((_, index) => `${index + 1}`);
    this.setDatasets();
    this.setChartWidth();

    this.scrollService.configure(this.columnWidthRems || 0, this.metrics.length);
  }

  private setDatasets(): void {
    const maxAvailable = this.metrics.reduce((_max, metric) => {
      return Math.max(
        _max,
        (this.showQueueCapacity$.value ? metric.capacity : 0),
        metric.availablePoolCapacity,
        metric.availableQueueCapacity
      );
    }, 0);

    const { max, stepSize } = getYAxisConfig(maxAvailable, 5);
    this.yAxisMax = max;
    this.yAxisStepSize = stepSize;
    this.options = chartOptions(max, stepSize, PoolsChartComponent.firstDayOfTheWeekIndices(this.metrics));
    this.datasets = formatDatasets(this.metrics, this.showQueueCapacity$.value, this.translations);
    this.legendCategories = legendCategories(this.showQueueCapacity$.value, this.translations);
  }

  /**
   * Set chart width, accounting for container size and chart configuration.
   * If chart is narrower than the container allows, stretch it to fit the available space.
   */
  private setChartWidth(): void {
    /**
     * Using timer here to wait a tick to determine ScrollContainer width calculation
     * Otherwise scrollWidthRems can sometimes be half a column width larger than expected
     */
    timer(0).subscribe(() => {
      const contentWidthRems = this.metrics.length * this.columnWidthRems;

      let scrollWidthRems = 0;
      if (this.scrollContainer?.nativeElement) {
        const scrollContainerStyles = this.getComputedStyleService.getComputedStyle(this.scrollContainer.nativeElement);
        scrollWidthRems = scrollContainerStyles?.width ? parseFloat(scrollContainerStyles.width) / 16 : 0;
      }

      const narrowerThanContainer = contentWidthRems < scrollWidthRems;
      if (narrowerThanContainer) {
        // Stretch to fit
        this.columnWidthRems = scrollWidthRems / this.metrics.length;
        this.chartWidthRems = scrollWidthRems;
        this.scrollService.updateItemWidth(this.columnWidthRems);
      } else {
        // Use standard width
        this.chartWidthRems = contentWidthRems;
      }
    });
  }
}
