import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { utc } from 'moment';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import {
  PoolDetails,
  PoolScheduleOverride,
  RegistrationForDate
} from 'api/types';
import { RemoveOccurrenceComponent, removeOccurrenceConfig, RemoveOccurrenceInputs } from 'components/common/pools/remove-occurrence/remove-occurrence.component';
import { LoadingDialogComponent, loadingDialogConfig, LoadingDialogInput } from 'components/dialogs/loading-dialog/loading-dialog.component';
import { EXCHANGE_FORMAT, FULL_MONTH_DAY_YEAR_FORMAT } from 'constants/date-formats';
import { TranslatePipe } from 'pipes/translate.pipe';
import { PoolsService } from 'services/api/pools.service';
import { OccurrenceActionsStatusService } from 'services/status/occurrence-actions-status.service';
import { VueToastComponent } from 'vue/components/vue-toast/vue-toast.component';
import { EditOccurrenceDialogComponent, editOccurrenceDialogConfig, EditOccurrenceDialogInputs } from '../edit-occurrence-dialog/edit-occurrence-dialog.component';

interface RequiredPoolDetails {
  isReleased: boolean;
  name: string;
  id: string;
}

/**
 *  Menu flyout for pool occurrences.
 */
@Component({
  selector: 'app-occurrence-menu',
  templateUrl: './occurrence-menu.component.html',
  styleUrls: ['./occurrence-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OccurrenceMenuComponent<P extends RequiredPoolDetails> implements OnInit {
  /**
   * Pool for respective row in occurrence-table
   */
  @Input() public pool!: P;

  /**
   * Optional registrations for the day of the occurrence,
   * If not defined, the registrations will be fetched before
   * opening the edit occurrence dialog.
   */
  @Input() public registrations?: RegistrationForDate;

  /**
   * Date of pool occurrence
   * Format: YYYY-MM-DD
   */
  @Input() public poolDate!: string;

  @Input() public isOverride!: boolean;

  /**
   * Hides view details menu option
   */
  @Input() public hideViewDetails = false;

  // Events updating occurrence table
  @Output() public occurrenceUpdated = new EventEmitter<PoolScheduleOverride>();
  @Output() public occurrenceReleaseChange = new EventEmitter<boolean>();
  @Output() public occurrenceRemoved = new EventEmitter<void>();
  @Output() public applyDefaultPoolSettings = new EventEmitter<void>();
  /**
   * Toast Reference
   */
  @ViewChild(VueToastComponent) private toast!: VueToastComponent;

  /**
   * If the occurrence date has passed,
   * Hide all editing options
   */
  public occurrenceDateHasPassed = false;

  public constructor(
    private dialog: MatDialog,
    private poolService: PoolsService,
    private translatePipe: TranslatePipe,
    private occurrenceActionsStatusService: OccurrenceActionsStatusService,
  ) { }

  /**
   * Update occurrenceDateHasPassed based on today's date
   */
  public ngOnInit(): void {
    this.occurrenceDateHasPassed = utc(this.poolDate, EXCHANGE_FORMAT).isBefore(utc(), 'day');
  }

  /**
   * Open occurrence edit dialog
   */
  public openOccurrenceEdit(): void {
    // Open "Loading" dialog
    const loader = this.dialog.open<LoadingDialogComponent, LoadingDialogInput>(LoadingDialogComponent, {
      ...loadingDialogConfig,
      data: {
        title: 'title.loading',
        subtitle: 'subtitle.pleaseWait',
      },
    });

    this.occurrenceActionsStatusService.loading(() => {
      this.openOccurrenceEdit();
    });

    this.getDataForEditOccurrence().pipe(take(1)).subscribe(([poolDetails, registrations]) => {
      const formattedDate = this.formatPoolDate();

      loader.close();

      // Open edit dialog with default config + passed in data points
      this.dialog.open<EditOccurrenceDialogComponent, EditOccurrenceDialogInputs>(EditOccurrenceDialogComponent, {
        ...editOccurrenceDialogConfig,
        data: {
          dialogTitle: this.translatePipe.transform(
            'occurrence.edit.dialog.heading.date',
            poolDetails.name,
            formattedDate
          ),
          date: this.poolDate,
          pageRef: 'edit',
          overrideCallback: this.submitOccurrenceEdit.bind(this),
          poolDetails,
          registrations,
        }
      });
    }, () => {
      this.actionFailed();
    });
  }

  /**
   * @param newReleaseStatus boolean if occurrence is released or not
   */
  public updateOccurrenceRelease(newReleaseStatus: boolean): void {
    // Open "Saving" dialog
    this.dialog.open<LoadingDialogComponent, LoadingDialogInput>(LoadingDialogComponent, {
      ...loadingDialogConfig,
      data: {
        title: 'title.saving',
        subtitle: 'subtitle.pleaseWait',
      },
    });

    this.occurrenceActionsStatusService.loading(() => {
      this.updateOccurrenceRelease(newReleaseStatus);
    });

    // Get fresh pool details to ensure nothing is overwritten
    this.getPoolDetailsMinuteWise().pipe(take(1)).subscribe((pool) => {
      let updatedPool = { ...pool };
      const existingOverrideIndex = this.getExistingOverrideIndex(updatedPool);

      // If override exists update the release value
      if (existingOverrideIndex !== -1) {
        updatedPool.scheduleOverrides[existingOverrideIndex].isReleased = newReleaseStatus;
        let scheduleOverrides = JSON.parse(JSON.stringify(updatedPool.scheduleOverrides[existingOverrideIndex]));
        pool.scheduleOverrides = [];
        updatedPool.scheduleOverrides = [];
        pool.scheduleOverrides.push(scheduleOverrides);
        updatedPool.scheduleOverrides.push(scheduleOverrides);
      } else {
        pool.scheduleOverrides = [];
        updatedPool.scheduleOverrides = [];
        const newOverride: PoolScheduleOverride = {
          date: this.poolDate,
          isReleased: newReleaseStatus
        };
        updatedPool = this.addOverride(updatedPool, newOverride);
      }

      // Update the pool
      this.updatePoolMinuteWise(updatedPool, () => {
        // Emit new release status so local state can be updated
        this.occurrenceReleaseChange.emit(newReleaseStatus);
        this.showToast(newReleaseStatus ? 'release' : 'unrelease');
        this.dialog.closeAll();
      });
    });
  }

  /**
   * Open remove occurrence confirmation dialog
   */
  public openRemoveConfirmDialog(): void {
    const formattedDate = this.formatPoolDate();
    this.dialog.open<RemoveOccurrenceComponent, RemoveOccurrenceInputs>(RemoveOccurrenceComponent, {
      ...removeOccurrenceConfig,
      data: {
        title: this.translatePipe.transform('occurrence.remove.dialog.heading.date', this.pool.name, formattedDate),
        removeOccurrenceCallback: () => {
          // Called when a user clicks "remove" on dialog
          this.removeOccurrenceDate();
        }
      }
    });
  }

  /**
   * @returns formatted date in MMMM DD YYYY format
   */
  public formatPoolDate(): string {
    return utc(this.poolDate, EXCHANGE_FORMAT).format(FULL_MONTH_DAY_YEAR_FORMAT);
  }

  /**
   * Close any dialogs and report error using service
   */
  private actionFailed(): void {
    this.dialog.closeAll();
    this.occurrenceActionsStatusService.error();
  }

  /**
   * Remove an occurrence
   */
  private removeOccurrenceDate(): void {
    const loader = this.dialog.open<LoadingDialogComponent, LoadingDialogInput>(LoadingDialogComponent, {
      ...loadingDialogConfig,
      data: {
        title: 'title.saving',
        subtitle: 'subtitle.pleaseWait',
      },
    });

    this.occurrenceActionsStatusService.loading(() => {
      this.removeOccurrenceDate();
    });

    this.getPoolDetails().pipe(take(1)).subscribe((pool) => {
      //  pool.scheduleOverrides = []; only added for pool occurrence patch api
      pool.scheduleOverrides = [];
      const updatedPool = { ...pool };
      const existingOverrideIndex = this.getExistingOverrideIndex(updatedPool);

      // If override exists remove it
      if (existingOverrideIndex !== -1) {
        updatedPool.scheduleOverrides.splice(existingOverrideIndex, 1);
      }

      // Add occurrence to dateExceptions
      updatedPool.dateExceptions = [
        ...(updatedPool.dateExceptions || []),
        this.poolDate,
      ];

      // Update the pool
      this.updatePool(updatedPool, () => {
        loader.close();
        // Emit event so local state can be updated
        this.occurrenceRemoved.emit();
        this.showToast('remove');
      });
    });
  }

  private submitOccurrenceEdit(updatedOverride: PoolScheduleOverride): void {
    // Open "Saving" dialog
    const loader = this.dialog.open<LoadingDialogComponent, LoadingDialogInput>(LoadingDialogComponent, {
      ...loadingDialogConfig,
      data: {
        title: 'title.saving',
        subtitle: 'subtitle.pleaseWait',
      },
    });

    this.occurrenceActionsStatusService.loading(() => {
      this.submitOccurrenceEdit(updatedOverride);
    });

    // Get fresh pool details to ensure nothing is overwritten
    this.getPoolDetailsMinuteWise().pipe(take(1)).subscribe((pool) => {
      //  pool.scheduleOverrides = []; only added for pool occurrence patch api
      pool.scheduleOverrides = [];
      let updatedPool = { ...pool };
      const existingOverrideIndex = this.getExistingOverrideIndex(updatedPool);

      // If override exists replace it, otherwise add it
      if (existingOverrideIndex !== -1) {
        updatedPool.scheduleOverrides[existingOverrideIndex] = updatedOverride;
      } else {
        updatedPool = this.addOverride(pool, updatedOverride);
      }
      // Update the pool
      this.updatePoolMinuteWise(updatedPool, () => {
        loader.close();

        // Emit event so local state can be updated
        this.occurrenceUpdated.emit(updatedOverride);
        this.showToast('edit');
      });
    });
  }

  /**
   * Additional pool and metric data is needed for editing occurrence
   * Get the pool details and then metric data associated with that pool
   *
   * @returns observable an observable that will provide pool details and metrics when all requests complete
   */
  private getDataForEditOccurrence(): Observable<[PoolDetails, RegistrationForDate]> {
    const registrations$ = this.registrations ?
      of(this.registrations) :
      this.poolService.getPoolRegistrations({ id: this.pool.id }).pipe(map((response) => {
        const registrations = response.registrations.find((r) => r.date === this.poolDate);

        return registrations || { date: this.poolDate, items: [] };
      }));

    return forkJoin([
      this.getPoolDetailsMinuteWise(),
      registrations$
    ]);
  }

  /**
   * Fetch the current pool's details
   *
   * @returns Observable<PoolDetails> a single-use observable, already complete with error handling
   */
  private getPoolDetails(): Observable<PoolDetails> {
    return this.poolService.getPoolById({ id: this.pool.id }).pipe(take(1), catchError((err) => {
      // Handle errors in central location
      this.actionFailed();
      return throwError(err);
    }));
  }

  private getPoolDetailsMinuteWise(): Observable<PoolDetails> {
    return this.poolService.getPoolByIdMinuteWise({ id: this.pool.id }, this.poolDate).pipe(take(1), catchError((err) => {
      // Handle errors in central location
      this.actionFailed();
      return throwError(err);
    }));
  }

  /**
   * Submit pool to backend
   *
   * @param pool the updated pool
   * @param completionCallback called on success
   */
  private updatePool(pool: PoolDetails, completionCallback: () => void): void {
    this.poolService.updatePool(pool).pipe(take(1)).subscribe(() => {
      completionCallback();

      // Broadcast the success
      this.occurrenceActionsStatusService.success();
    }, () => {
      this.actionFailed();
    });
  }

  private updatePoolMinuteWise(pool: PoolDetails, completionCallback: () => void): void {
    this.poolService.updatePoolMinuteWise(pool).pipe(take(1)).subscribe(() => {
      completionCallback();

      // Broadcast the success
      this.occurrenceActionsStatusService.success();
    }, () => {
      this.actionFailed();
    });
  }

  private getExistingOverrideIndex(pool: PoolDetails): number {
    return (pool.scheduleOverrides || []).findIndex(
      (override) => override.date === this.poolDate
    );
  }

  private addOverride(pool: PoolDetails, override: PoolScheduleOverride): PoolDetails {
    if (Array.isArray(pool.scheduleOverrides)) {
      pool.scheduleOverrides.push(override);
    } else {
      pool.scheduleOverrides = [override];
    }
    return pool;
  }

  private showToast(type: 'edit' | 'release' | 'remove' | 'unrelease' | 'applypoolsettings'): void {
    this.translatePipe.loadTranslations([
      'toast.heading.occurrence.updated',
      'toast.heading.occurrence.released',
      'toast.heading.occurrence.unreleased',
      'toast.heading.occurrence.removed',
      'toast.heading.occurrence.applypoolsettings'
    ])
      .pipe(take(1))
      .subscribe((translations) => {
        switch (type) {
          case 'edit':
            this.toast.heading = translations['toast.heading.occurrence.updated'];
            break;
          case 'release':
            this.toast.heading = translations['toast.heading.occurrence.released'];
            break;
          case 'unrelease':
            this.toast.heading = translations['toast.heading.occurrence.unreleased'];
            break;
          case 'remove':
            this.toast.heading = translations['toast.heading.occurrence.removed'];
            break;
          case 'applypoolsettings':
            this.toast.heading = translations['toast.heading.occurrence.applypoolsettings'];
            break;
        }
        this.toast.open();
      });
  }

  public applyPoolSettings(): void {
    this.dialog.open<LoadingDialogComponent, LoadingDialogInput>(LoadingDialogComponent, {
      ...loadingDialogConfig,
      data: {
        title: 'title.saving',
        subtitle: 'subtitle.pleaseWait',
      },
    });

    this.occurrenceActionsStatusService.loading(() => {
      this.applyPoolSettings();
    });

    // Get fresh pool details to ensure nothing is overwritten
    this.getPoolDetailsMinuteWise().pipe(take(1)).subscribe((pool) => {
      let updatedPool = { ...pool };
      pool.applyPoolSettings = [this.poolDate];
      updatedPool.applyPoolSettings = [this.poolDate];
      
      // Update the pool
      this.updatePoolMinuteWise(updatedPool, () => {
        // Emit new release status so local state can be updated
        this.applyDefaultPoolSettings.emit();
        this.showToast('applypoolsettings');
        this.dialog.closeAll();
      });
    });
  }
}
