<vue-page-header
  appName="{{ 'title.applicationName' | translate | async }}"
  pageTitle="{{ 'title.templates' | translate | async }}"
  navSection="{{ 'title.appointmentManagement' | translate | async }}"
>
  <app-filter-container>
    <app-template-filters></app-template-filters>
    <app-add-template-drawer></app-add-template-drawer>
  </app-filter-container>
</vue-page-header>
<app-page-body>
  <app-server-error-unsaved
    *ngIf="showServerError"
    (retry)="triggerRetry()"
    [message]="serverErrorMsg"
    [includeUnsaved]="showServerError === 'unsaved'"
  ></app-server-error-unsaved>
  <vue-section>
    <app-templates-table></app-templates-table>
  </vue-section>
</app-page-body>
<vue-toast heading="{{ toastHeadingKey | translate | async }}"  icon="success">
  <span toast-body>{{ toastMessageKey | translate:updatedTemplateName | async }}</span>
</vue-toast>
