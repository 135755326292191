import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { UnsavedChangesDialogService } from 'services/unsaved-changes-dialog.service';

/**
 * Components must implement this interface to use this guard
 */
export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

/**
 * Guard to prevent component from unloading if there are pending changes.
 * Component must implement canDeactivate().
 */
@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  public constructor(private unsavedChangesModalService: UnsavedChangesDialogService) {}

  /**
   * If there are pending changes, confirm before allowing deactivation
   *
   * @param component the component trying to deactivate
   * @returns {boolean | Observable<boolean>} either an immediate yes/no response or an observable for delayed responses
   */
  public canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    return component.canDeactivate() ?
      true : this.unsavedChangesModalService.open();
  }
}
