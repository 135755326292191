<vue-flat-button (clicked)="drawer.open()">{{ "title.addPool" | translate | async }}</vue-flat-button>
<vue-drawer #drawer [contentRef]="addPool" [drawerConfig]="drawerConfig" (drawerClosed)="reset()">
  <ng-template #addPool>
    <app-drawer-header
      (close)="closeDrawerIfUnchanged()"
      [disableClose]="status === 'loading'"
      [progress]="{totalSteps: 2, currentStep: step === 'form' ? 1 : 2}"
    >
      {{ "title.addPool" | translate | async }}
    </app-drawer-header>
    <div
      matDialogContent
      class="mat-typography"
      [class.loading]="status === 'loading'"
    >
      <app-error-message
        *ngIf="status === 'error'"
        [heading]="displayableServerError?.title"
        [message]="displayableServerError?.message"
      ></app-error-message>
      <app-add-pool-form
        *ngIf="step === 'form'"
      ></app-add-pool-form>
      <app-pool-drawer-occurrence-table
        *ngIf="step === 'occurrence'"
        [poolDetails]="getPoolDetailsForOccurrenceTable()"
        [registrations]="poolMetrics"
        [totalCapacity]="totalCapacity"
        [displayRegistrationToolTip]="true"
        [pageRef] = "pageRef"
        (unsavedChangesEvent)="setUnsavedChangesFlag()"
      ></app-pool-drawer-occurrence-table>
    </div>
    <div matDialogActions>
      <ng-container *ngIf="step === 'form'">
        <vue-basic-button (clicked)="closeDrawerIfUnchanged()" [disabled]="status === 'loading'">
          {{ "button.cancel" | translate | async }}
        </vue-basic-button>
        <vue-flat-button type="submit" form="form" [disabled]="!formIsValid" [loading]="status === 'loading'">
          {{ "button.next" | translate | async }}
        </vue-flat-button>
      </ng-container>
      <ng-container *ngIf="step === 'occurrence'">
        <vue-basic-button (clicked)="showForm()" [disabled]="status === 'loading'">
          {{ "button.back" | translate | async }}
        </vue-basic-button>
        <vue-flat-button (clicked)="savePool()" [loading]="status === 'loading'">
          {{ "button.save" | translate | async }}
        </vue-flat-button>
      </ng-container>
    </div>
  </ng-template>
</vue-drawer>
