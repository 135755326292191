import { Component } from '@angular/core';

/**
 *  Detail content.  Pairs with VueDetailHeader.
 */
@Component({
  selector: 'vue-detail-content',
  templateUrl: './vue-detail-content.component.html',
  styleUrls: [ './vue-detail-content.component.scss' ]
})
export class VueDetailContentComponent {}
