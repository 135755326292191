import { Component, Input } from '@angular/core';
import { LegendCategoryConfig } from 'types/LegendCategoryConfig';

/**
 *  Component to display a custom legend for a chart.  Allows more control than stock ChartJS legends.
 */
@Component({
  selector: 'app-chart-legend',
  templateUrl: './chart-legend.component.html',
  styleUrls: [ './chart-legend.component.scss' ]
})
export class ChartLegendComponent {
  @Input() public categories: LegendCategoryConfig[] = [];
}
