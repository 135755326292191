import { Component, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { utc } from 'moment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { mergeMap, take, takeUntil } from 'rxjs/operators';
import { GetPoolRegistrationsResponse, PoolDetails, PoolScheduleOverride, RegistrationForDate } from 'api/types';
import { isSameSchedule } from 'components/common/pools/utils/is-same-schedule';
import { EXCHANGE_FORMAT } from 'constants/date-formats';
import { PoolsService } from 'services/api/pools.service';
import { EditPoolOccurrencesService } from 'services/edit-pool-occurrences.service';
import { EditPoolService } from 'services/edit-pool.service';
import { DrawerStatusService } from 'services/status/drawer-status.service';
import { FetchingPoolStatusService } from 'services/status/fetching-pool-status.service';
import { UnsavedChangesDialogService } from 'services/unsaved-changes-dialog.service';
import { DisplayableServerError } from 'types/DisplayableServerError';
import { RequestStatus } from 'types/RequestStatus';
import { getDisplayableServerError } from 'utils/get-displayable-server-error';
import { VueDrawerComponent, VueDrawerConfig } from 'vue/components/vue-drawer/vue-drawer.component';
import { ArchivePoolComponent, archivePoolConfig, ArchivePoolInputs } from './archive-pool/archive-pool.component';
import { releaseAllOccurrenceConfig, ReleaseAllOccurrencesComponent, ReleaseAllOccurrencesInputs } from './release-all-occurrences/release-all-occurrences.component';

type EditPoolStep = 'edit-form' | 'edit-occurrence';

/**
 *  Drawer that houses the edit pool flow
 */
@Component({
  selector: 'app-edit-pool-drawer',
  styleUrls: [ './edit-pool-drawer.component.scss' ],
  templateUrl: './edit-pool-drawer.component.html',
})
export class EditPoolDrawerComponent {
  /**
   * ID of pool to edit
   */
  @Input() public poolId!: string

  /**
   * Toggle to show button that can open drawer
   * If false, the consuming component will have to call open() directly
   */
  @Input() public showOpenButton = true;

  /**
   * Reference to underlying drawer
   */
  @ViewChild(VueDrawerComponent) public drawer!: VueDrawerComponent;

  /**
   * Initial details of pool
   */
  public pool: PoolDetails = {} as PoolDetails;

  public initialpool: PoolDetails = {} as PoolDetails;
  public initialscheduleOverrides = [];


  /**
   * Registrations for the pool
   */
  public registrations: RegistrationForDate[] = [];

  /**
   * Tracks validity of the edit pool form
   */
  public formIsValid = true;

  /**
   * Which step of the edit pool flow is shown
   */
  public step = new BehaviorSubject<EditPoolStep>('edit-form');

  /**
   * Flag to show unsaved changes dialog
   */
  public unsavedChanges = false;

  /**
   * Custom config passed to drawer component
   */
  public drawerConfig: VueDrawerConfig = {
    disableClose: true,
  }

  /**
   * Loading status for the drawer
   */
  public status: RequestStatus = 'initial';

  /**
   * DisplayableServerError displayed by error message component
   */
  public serverError: DisplayableServerError | null = null;

  /**
   * Track component destroyed state
   */
  private destroyed$ = new Subject();

  public pageRef = 'edit';

  public constructor(
    private poolService: PoolsService,
    private dialog: MatDialog,
    private editOccurrenceService: EditPoolOccurrencesService,
    private unsavedChangesDialogService: UnsavedChangesDialogService,
    private editPoolService: EditPoolService,
    private drawerStatusService: DrawerStatusService,
    private fetchingPoolStatusService: FetchingPoolStatusService
  ) {
    this.drawerStatusService.status$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((status) => {
        this.status = status;
      });
  }

  /**
   * Terminate subscriptions
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Rest drawer back to initial state
   */
  public resetDrawer(): void {
    this.step.next('edit-form');
    this.pool = {} as PoolDetails;
    this.registrations = [];
    this.drawerStatusService.reset();
  }

  /**
   * Close & reset drawer
   */
  public closeDrawer(): void {
    this.drawer.close();
    this.resetDrawer();
  }

  /**
   * Fetches pool details and then opens drawer
   */
  public open(): void {
    this.fetchingPoolStatusService.loading(() => {
      this.open();
    });

    this.getPoolDetails()
      .pipe(take(1))
      .subscribe((pool) => {
        this.pool = pool;
        this.initialpool = JSON.parse(JSON.stringify(pool));
        this.initialscheduleOverrides = JSON.parse(JSON.stringify(pool.scheduleOverrides));
        this.drawer.open();
        this.fetchingPoolStatusService.success();
      }, () => {
        this.fetchingPoolStatusService.error();
      });
  }

  /**
   * Updates local variable of pool form validity
   *
   * @param isValid if edit pool form is valid
   */
  public updateFormValidity(isValid: boolean): void {
    this.formIsValid = isValid;
  }

  /**
   * Shows unsaved change dialog or edit pool form
   */
  public showForm(): void {
    this.drawerStatusService.reset();
    this.serverError = null;

    this.pool.scheduleOverrides = JSON.parse(JSON.stringify(this.initialscheduleOverrides));

    if (this.unsavedChanges) {
      this.openUnsavedChangesDialog();
    } else {
      this.registrations = [];
      this.step.next('edit-form');
    }
  }

  public get displayableServerError(): DisplayableServerError | null | undefined {
    return this.editPoolService.displayableServerError || this.serverError;
  }

  /**
   * Updates pool details and fetches capacity metrics
   *
   * @param pool updated pool from form
   */
  public updatePoolDetails(pool: PoolDetails): void {
    this.drawerStatusService.loading();
    this.pool = pool;
    this.getRegistrations(pool)
      .pipe(take(1))
      .subscribe((registrations) => {
        this.registrations = registrations.registrations;
        this.step.next('edit-occurrence');
        this.drawerStatusService.success();
      }, (error: unknown) => {
        this.serverError = getDisplayableServerError(error);
        this.drawerStatusService.error();
      });
  }

  /**
   * Saves updated state of pool
   */
  public savePool(): void {
    this.drawerStatusService.loading();
    const updatedPool = this.constructPayload();
    this.poolService.updatePool(updatedPool)
      .pipe(take(1))
      .subscribe(() => {
        this.editPoolService.emitPoolUpdate(this.pool.name, 'updated');
        this.drawer.close();
        this.drawerStatusService.success();
      }, (error: unknown) => {
        this.serverError = getDisplayableServerError(error);
        this.drawerStatusService.error();
      });
  }

  /**
   * Opens archive dialog to confirm the archive with user
   */
  public openArchiveDialog(): void {
    this.dialog.open<ArchivePoolComponent, ArchivePoolInputs>(ArchivePoolComponent, {
      ...archivePoolConfig,
      data: {
        poolName: this.pool.name,
        archivePoolCallback: () => {
          // Called when a user clicks "archive" on dialog
          this.archivePool();
        }
      }
    });
  }

  /**
   * Opens release dialog to confirm releasing all occurrences with user
   */
  public openReleaseDialog(): void {
    this.dialog.open<ReleaseAllOccurrencesComponent, ReleaseAllOccurrencesInputs>(
      ReleaseAllOccurrencesComponent, {
      ...releaseAllOccurrenceConfig,
      data: {
        poolName: this.pool.name,
        releaseOccurrencesCallback: () => {
          // Called when a user clicks "release" on dialog
          this.releaseAllOccurrences();
        }
      }
    });
  }

  /**
   * Updates flag to show unsaved changed dialog
   */
  public setUnsavedChangesFlag(): void {
    this.unsavedChanges = true;
  }

  /**
   * Opens unsaved changed dialog
   * Changes will be lost when user continues to form
   */
  public openUnsavedChangesDialog(): void {
    this.unsavedChangesDialogService.open().pipe(take(1)).subscribe((choseToLeave) => {
      if (choseToLeave) {
        this.unsavedChanges = false;
        this.showForm();
      }
    });
  }

  /**
   * Saves changes to pool that have been made then archives pool
   */
  private archivePool(): void {
    this.drawerStatusService.loading();
    const updatedPool = this.constructPayload();
    this.poolService.updatePool(updatedPool)
      .pipe(mergeMap(
        () => this.poolService.updatePoolStatus(this.pool.id, 'archived')
      ))
      .pipe(take(1))
      .subscribe(() => {
        this.editPoolService.emitPoolUpdate(this.pool.name, 'archived');
        this.drawer.close();
        this.drawerStatusService.success();
      }, (error: unknown) => {
        this.serverError = getDisplayableServerError(error);
        this.drawerStatusService.error();
      });
  }

  /**
   * Save changes to pool and then release all occurrences
   */
  private releaseAllOccurrences(): void {
    this.drawerStatusService.loading();
    const updatedPool = this.constructPayload();
    this.poolService.updatePool(updatedPool)
      .pipe(mergeMap(
        () => {
          return this.poolService.updatePoolStatus(this.pool.id, 'released');
        }
      ))
      .pipe(take(1))
      .subscribe(() => {
        this.editPoolService.emitPoolUpdate(this.pool.name, 'released');
        this.drawer.close();
        this.drawerStatusService.success();
      }, (error: unknown) => {
        this.serverError = getDisplayableServerError(error);
        this.drawerStatusService.error();
      });
  }

  private constructPayload(): PoolDetails {
    /**
     * Merge together exceptions from existing pool and any new ones
     * Format: YYYY-MM-DD
     */
    const exceptions: string[] = [
      ...this.editOccurrenceService.occurrenceRemovedDates$.value,
      ...this.pool.dateExceptions
    ].map((d) => utc(d).format(EXCHANGE_FORMAT));

    /**
     * Get all of the unique overrides
     * Includes new and existing overrides
     */
    const overrides = this.getAllOverrides();

    const applyPoolSettings: string[] = [
      ...this.editOccurrenceService.applyPoolSettingsDates$.value
    ].map((d) => utc(d).format(EXCHANGE_FORMAT));

    return {
      ...this.pool,
      dateExceptions: exceptions,
      scheduleOverrides: overrides,
      applyPoolSettings: applyPoolSettings
    };
  }

  /**
   * Constructs overrides that deviate from the pool schedule, release or restriction
   * Then merges existing overrides, with new overrides taking precedence
   *
   * @returns Array of overrides
   */
  private getAllOverrides(): PoolScheduleOverride[] {
    const { schedule } = this.pool;
    const { scheduleOverrides$ } = this.editOccurrenceService;

    // Get updated overrides that deviate from pool
    let updatedOverrides = scheduleOverrides$.value.filter((override) => {
      return Boolean(
        !isSameSchedule(schedule.items, override.schedule?.items) ||
        override.isReleased ||
        (typeof override.isRestricted !== 'undefined')
      );
    });

    /**
     * Get details of existing overrides
     * Override properties are optional, so they shouldn't be discarded if they haven't been touched
     */
    updatedOverrides = updatedOverrides.map((override) => {
      const existingOverride = (this.pool.scheduleOverrides || []).find((o) => o.date === override.date);
      if (existingOverride) {
        return {
          ...existingOverride,
          ...override
        };
      }
      return override;
    });

    // Get existing overrides that weren't edited

    // const existingOverrides = [ ...(this.pool.scheduleOverrides || []) ].filter((override) => {

    //   return !updatedOverrides.find((updated) => updated.date === override.date);

    // });

    return [ ...updatedOverrides ];
  }

  /**
   * Fetch registrations for the given pools
   */
  private getRegistrations(pool: PoolDetails): Observable<GetPoolRegistrationsResponse> {
    return this.poolService.getPoolRegistrations({ id: pool.id });
  }

  /**
   * Fetch pool details for the given pool id
   */
  private getPoolDetails(): Observable<PoolDetails> {
    return this.poolService.getPoolById({ id: this.poolId });
  }
}
