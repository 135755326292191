import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { utc } from 'moment';
import { Observable, Subject } from 'rxjs';
import { distinctUntilKeyChanged, take, takeUntil } from 'rxjs/operators';
import {
  CreatePoolResponse,
  PoolCapacityDetail,
  PoolCapacityMetric,
} from 'api/types';
import { FetchesPoolBaseClass } from 'components/common/fetches-pool-base/fetches-pool-base.class';
import { EditPoolToastComponent } from 'components/common/pools/edit-pool-toast/edit-pool-toast.component';
import { ABBREV_MONTH_YEAR_FORMAT, DISPLAY_DAY_FORMAT, EXCHANGE_FORMAT } from 'constants/date-formats';
import { TranslatePipe } from 'pipes/translate.pipe';
import { PoolsService } from 'services/api/pools.service';
import { EditPoolService } from 'services/edit-pool.service';
import { PoolsPageService } from 'services/pools-page.service';
import { FetchingPoolStatusService } from 'services/status/fetching-pool-status.service';
import { OccurrenceActionsStatusService } from 'services/status/occurrence-actions-status.service';
import { PageStatusService } from 'services/status/page-status.service';
import { ERROR, LOADING } from 'types/RequestStatus';
import { VueToastComponent } from 'vue/components/vue-toast/vue-toast.component';

/**
 *  Pools page
 */
@Component({
  selector: 'app-pools-page',
  templateUrl: './pools-page.component.html',
  styleUrls: [ './pools-page.component.scss' ]
})
export class PoolsPageComponent extends FetchesPoolBaseClass implements OnInit, OnDestroy {
  @ViewChild(VueToastComponent) public toast!: VueToastComponent;
  @ViewChild(EditPoolToastComponent) public editPoolToast!: EditPoolToastComponent;

  public poolMetrics: PoolCapacityMetric[] = [];

  /**
   * Pool Name passed to success toast
   */
  public newPoolName = '';

  /**
   * Pool ID passed to success toast
   */
  public newPoolId = '';

  /**
   * Title to display above occurrence table
   */
  public occurrenceSectionTitle = '';

  /**
   * Passed to pool occurrence table
   */
  public poolsForSelectedDate: PoolCapacityDetail[] = [];

  /**
   * Date selected from chart
   * Format: YYYY-MM-DD
   */
  public selectedDate = '';

  /**
   * Month selected by PoolsFilter
   */
  public filterDate = '';

  /**
   * Display an error when an occurrence action fails
   */
  public showOccurrenceActionError = false;

  /**
   * Track page loading state internally
   */
  public pageLoading = false;

  /**
   * Cancel subscriptions onDestroy
   */
  private destroyed$ = new Subject();

  /**
   * Index of the currently selected index for the date shown in the occurrence table
   */
  private selectedIndex: number | null = null;

  public constructor(
    private poolsPageService: PoolsPageService,
    private poolsService: PoolsService,
    private router: Router,
    private editPoolService: EditPoolService,
    private translatePipe: TranslatePipe,
    private occurrenceActionsStatusService: OccurrenceActionsStatusService,
    private pageStatusService: PageStatusService,
    fetchesPoolStatusService: FetchingPoolStatusService,
  ) {
    super(fetchesPoolStatusService);
  }

  public ngOnInit(): void {
    this.poolsPageService.getQueues();
    
    // Listen for fresh batches of pool metrics
    this.poolsPageService.poolCapacityMetrics$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((metrics) => {
        this.poolMetrics = metrics;

        if (this.selectedIndex !== null) {
          this.poolsForSelectedDate = this.poolMetrics[ this.selectedIndex ].details;
        }
      });

    // Listen for startDate changes
    this.poolsPageService.poolsFilters$
      .pipe(takeUntil(this.destroyed$), distinctUntilKeyChanged('startDate'))
      .subscribe((poolFilters) => {
        this.filterDate = poolFilters.startDate.value ?
          utc(poolFilters.startDate.value, EXCHANGE_FORMAT).format(ABBREV_MONTH_YEAR_FORMAT) :
          '';
        this.poolsForSelectedDate = [];
        this.selectedIndex = null;
        this.updateSectionTitle(-1);
      });

    // Listen for pool edits
    this.editPoolService.poolUpdated$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((poolUpdated) => {
        this.poolsPageService.updatePoolData();
        this.editPoolToast.openToast(poolUpdated.poolName, poolUpdated.status);
      });

    // Listen for occurrence action errors
    this.occurrenceActionsStatusService.status$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((status) => {
        this.showOccurrenceActionError = status === ERROR;
      });

    // Listen for page status changes
    this.pageStatusService.status$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((status) => {
        this.pageLoading = status === LOADING;
      });
  }

  /**
   * Terminate all subscriptions.
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.fetchingPoolStatusComplete();
  }

  /**
   * Retries the failed occurrence action.
   */
  public retryOccurrenceAction(): void {
    this.occurrenceActionsStatusService.retry();
  }

  /**
   * Success handler for pool creation, triggers page refresh.
   */
  public poolCreated(response: CreatePoolResponse): void {
    this.newPoolId = response.id;

    this.poolsService.getPoolById({ id: response.id })
      .pipe(take(1))
      .subscribe((pool) => {
        this.newPoolName = pool.name;
        this.toast.open();

        // Update / reset the rest of the data for the page
        this.poolsForSelectedDate = [];
        this.selectedIndex = null;
        this.poolsPageService.updatePoolData();
      });
  }

  /**
   * Navigates to the pool details of the newly created pool.
   */
  public viewNewPool(): void {
    this.toast.close();
    this.router.navigate([ `pools/${this.newPoolId}` ]);
  }

  /**
   * Chart click handler
   *
   * @param dateIndex index of selected date on chart
   */
  public dateIndexClick(dateIndex: number): void {
    this.selectedIndex = dateIndex;
    this.selectedDate = this.poolMetrics[ dateIndex ].date;
    this.poolsForSelectedDate = this.poolMetrics[ dateIndex ].details;
    this.updateSectionTitle(dateIndex);
  }

  /**
   * Gets formatted date and section title for occurrence section
   * If date formatting fails, sets back to default text
   *
   * @param index date index based on 0, ex: 0 is the 1st of the month
   */
  private updateSectionTitle(index: number): void {
    let observable$: Observable<string>;

    if (index !== -1 && this.poolMetrics[ index ] && this.poolMetrics[ index ].date) {
      const date = utc(this.poolMetrics[ index ].date, EXCHANGE_FORMAT).format(DISPLAY_DAY_FORMAT);
      observable$ = this.translatePipe.transform('title.pools.occurrences.date', date);
    } else {
      observable$ = this.translatePipe.transform('title.pools.occurrences');
    }

    observable$.pipe(take(1)).subscribe((translation) => {
      this.occurrenceSectionTitle = translation;
    });
  }
}
