import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';

export type VueToastConfig = Partial<MatSnackBarConfig>;

/**
 * A component to display arbitrary "toast" messages at the bottom of the screen.
 */
@Component({
  selector: 'vue-toast',
  templateUrl: './vue-toast.component.html',
  styleUrls: [ './vue-toast.component.scss' ]
})
export class VueToastComponent {
  @Input() public message?: string = '';

  /**
   * Heading of the toast
   */
  @Input() public heading?: string;

  /**
   * Icon to be shown on the side of the toast
   */
  @Input() public icon?: 'success' | 'warn';

  /**
   * Configuration object passed to underlying toast component
   */
  @Input() public config?: VueToastConfig;

  /**
   * Includes a close icon to dismiss the toast
   */
  @Input() public includeDismiss = false;

  /**
   * Reference to the template that is displayed within the toast
   */
  @ViewChild('toastTemplate') public toastTemplate!: TemplateRef<unknown>;

  /**
   * Reference to to the toast when it is opened
   */
  public toastRef?: MatSnackBarRef<unknown>;

  /**
   * Default configuration for the toast, which can be overrode by `config` input.
   */
  private defaultConfig: VueToastConfig = {
    horizontalPosition: 'center',
    duration: 10000,
    politeness: 'polite',
  }

  public constructor(private toast: MatSnackBar) {}

  /**
   * Open toast
   */
  public open(): void {
    this.toastRef = this.toast.openFromTemplate(this.toastTemplate, {
      ...this.defaultConfig,
      ...this.config
    });
  }

  /**
   * Close toast
   */
  public close(): void {
    this.toastRef?.dismiss();
  }
}
