import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PoolInfo } from 'api/types';
import { isPoolEditable, isPoolArchived, isPoolEnds } from 'components/common/pools/utils/is-pool-editable';
import { LoadingDialogComponent, LoadingDialogInput, loadingDialogConfig } from 'components/dialogs/loading-dialog/loading-dialog.component';
import { EditPoolDrawerComponent } from 'components/drawers/edit-pool-drawer/edit-pool-drawer.component';
import { take } from 'rxjs/operators';
import { PoolsService } from 'services/api/pools.service';
import { EditPoolService } from 'services/edit-pool.service';
import { ChangePoolNameComponent, changePoolNameModalInputs, changePoolNameModalConfig } from 'components/common/pools/change-poolname/change-poolname.component';
import { TranslatePipe } from 'pipes/translate.pipe';

/**
 *  Menu for pool actions on the all pools page.  Allows viewing details and editing the pool.
 */
@Component({
  selector: 'app-all-pools-menu',
  templateUrl: './all-pools-menu.component.html',
  styleUrls: ['./all-pools-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AllPoolsMenuComponent {
  /**
   * Pool for respective row in all pools table
   */
  @Input() public pool!: PoolInfo;

  /**
   * Reference to editDrawer for pools menu.
   * The reference will be undefined if poolIsEditable evaluates to false
   */
  @ViewChild(EditPoolDrawerComponent) public editDrawer?: EditPoolDrawerComponent;

  /**
   * Show Edit pool menu item a pool that is not archived or complete
   *
   * @returns true if a pool does not have the status of archived or complete
   */
  public isPoolEditable = isPoolEditable;

  public isPoolArchived = isPoolArchived;

  public isPoolEnds = isPoolEnds;

  public constructor(
    private dialog: MatDialog,
    private poolService: PoolsService,
    private editPoolService: EditPoolService,
    private translatePipe: TranslatePipe
  ) {
  }

  /**
   * Opens Edit Pool Drawer
   */
  public openEditDrawer(): void {
    this.editDrawer?.open();
  }

  public poolUnarchived(): void {
    const loader = this.dialog.open<LoadingDialogComponent, LoadingDialogInput>(LoadingDialogComponent, {
      ...loadingDialogConfig,
      data: {
        title: 'title.loading',
        subtitle: 'subtitle.pleaseWait',
      },
    });

    this.poolService.getPoolNames({ startsWith: this.pool.name.replace(/^\s+/g, '').replace(/\s+$/g, '') })
      .pipe(take(1))
      .subscribe((pools) => {
        const duplicateName = Boolean(pools.find((p) => p.name.toLowerCase() === this.pool.name.toString().replace(/^\s+/g, '').replace(/\s+$/g, '').toLowerCase()));
        
        if (duplicateName) {
          loader.close();

          let dialogRef = this.dialog.open<ChangePoolNameComponent, changePoolNameModalInputs>(ChangePoolNameComponent, {
            ...changePoolNameModalConfig,
            data: {
              title: this.translatePipe.transform('message.pool.change.poolname.modal'),
              poolName: this.pool.name,
              poolId: this.pool.id
            }
          });
          
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.editPoolService.emitPoolUpdate(result.updatedPoolName, 'unarchived');
            }
          });
        }
        else {
          this.poolService.unarchivedPool({ id: this.pool.id }, null)
            .pipe(take(1))
            .subscribe(() => {
              loader.close();
              this.editPoolService.emitPoolUpdate(this.pool.name, 'unarchived');
            }, (error: unknown) => {
              loader.close();
            });
        }
      }, () => {
      });
  }
}
