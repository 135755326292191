import { Component, Input } from '@angular/core';
import { SupportedChartType } from 'types/SupportedChartType';

/**
 * Displays chart elements with markers, labels and quantities.  Intended for use in chart popovers.
 *
 */
@Component({
  selector: 'app-chart-elements-details',
  templateUrl: './chart-elements-details.component.html',
  styleUrls: [ './chart-elements-details.component.scss' ]
})
export class ChartElementsDetailsComponent {
  @Input() public details: {
    type: SupportedChartType;
    color: string;
    label: string;
    quantity: string;
  }[] = [];
}
