<mat-dialog-actions style="float: right;">
  <button mat-button (click)="onCloseClick($event,'close')"><mat-icon>close</mat-icon></button>
</mat-dialog-actions>
<div class="container">
  <vue-page-header  pageTitle="{{ 'title.allPools' | translate | async }}">
      <div class="filter-container">
      <app-alert-consumption-table-filter [alertFilterStatus]="this.data" (changesData)="getdatafromfilter($event)"></app-alert-consumption-table-filter>
      <vue-flat-button style="position: relative; left: 100px;" [disabled]="setValuetoButton"
        (click)="onCloseClick($event,'')">{{ 'label.confirm' |
        translate | async }}</vue-flat-button>
      </div>
  </vue-page-header>
  <app-add-alerts-consumption-table></app-add-alerts-consumption-table>
</div>