import { ChartOptions, ScriptableScaleContext } from 'chart.js';
import palette from 'components/charts/utils/chart-palette';
import { baseBarXScale } from '../../utils/base-bar-x-scale';
import { baseChartOptions } from '../../utils/base-chart-options';

export const CAPACITY_ID = 'capacity';
export const REGISTRATIONS_ID = 'registrations';

/**
 * @param yAxisMax the highest value to present on the chart's Y axis
 * @param yAxisStepSize the increments to use between ticks in the Y axis
 * @param darkenTopOfTheHourTicks optionally emphasize top-of-the-hour gridlines
 * @returns chartOptions valid chart options
 */
export function chartOptions(yAxisMax: number, yAxisStepSize: number, darkenTopOfTheHourTicks: boolean): ChartOptions {
  const baseConfig = baseChartOptions(yAxisMax, yAxisStepSize);
  const baseXScale = baseBarXScale();
  return {
    ...baseConfig,
    scales: {
      ...baseConfig.scales,
      [ CAPACITY_ID ]: {
        axis: 'x',
        display: false,
      },
      [ REGISTRATIONS_ID ]: {
        ...baseXScale,
        grid: {
          ...baseXScale.grid,
          color: (context: ScriptableScaleContext) => {
            // Mark the top-of-the-hour with darker lines
            return darkenTopOfTheHourTicks && isHourTick(context) ? palette.grayD : palette.grayF;
          },
        }
      },
    }
  };
}

/**
 * @param context the context object supplied by Charts JS library
 * @returns boolean true if the datapoint represents an hour
 */
function isHourTick(context: ScriptableScaleContext): boolean {
  return Boolean(context?.tick?.label?.includes(':00'));
}
