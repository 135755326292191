<div mat-dialog-title>
  <h1>
    {{ data.clientName }} Exams
  </h1>
  <button mat-button (click)="closeDialog()" [attr.aria-label]="'label.aria.closeDialog' | translate | async">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <ol class="list">
    <li *ngFor="let n of exams">{{n}}</li>
  </ol>
</div>

<div mat-dialog-actions>
  <vue-flat-button (clicked)="closeDialog()">
    {{ "button.ok" | translate | async }}
  </vue-flat-button>
</div>