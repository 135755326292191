import { Component, Input } from '@angular/core';

/**
 * Wrapper component that lays out the lower parts of a chart section; everything below the legend.
 *
 * Expects interior markup like this:
 *
 *  <app-custom-y-axis></app-custom-y-axis>
 *  <div class="scroll-container" #scrollContainer>
 *     <app-custom-x-axis></app-custom-x-axis>
 *     <app-chart-canvas-wrapper>
 *      <canvas baseChart></canvas>
 *    </app-chart-canvas-wrapper>
 *  </div>
 *  <app-synched-scroll-button direction="forward"></app-synched-scroll-button>
 *
 * Development note: We may ultimately want to require those parts via explicit template slots.
 *
 */
@Component({
  selector: 'app-chart-with-custom-y-axis',
  templateUrl: './chart-with-custom-y-axis.component.html',
  styleUrls: [ './chart-with-custom-y-axis.component.scss' ],
})
export class ChartWithCustomYAxisComponent {
  /**
   * Whether to display the top X-axis headers in a compact layout
   */
  @Input() public compactHeader = false;
}
