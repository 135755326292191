import { Component, HostBinding, Input } from '@angular/core';
import { pxToRem } from 'utils/rem-utils';
import { TICK_EXTENSION_HEIGHT_PX } from '../utils/constants';

/**
 * Wrapper component that compensates for the extra whitespace around the ChartJS canvas.
 * Allows the chart within to "bleed" to all edges.
 *
 * This component assumes that the chart will have at least one x scale displayed that extends above the main chart.
 * See base-bar-x-scale.tx, for example.
 *
 */
@Component({
  selector: 'app-chart-canvas-wrapper',
  templateUrl: './chart-canvas-wrapper.component.html',
  styleUrls: [ './chart-canvas-wrapper.component.scss' ],
})
export class ChartCanvasWrapperComponent {
  /**
   * Desired width of the chart canvas in rems.
   *
   * HostBinding is used to set the desired width on the host element. The inner element handles padding compensation.
   */
  @HostBinding('style.width.rem') @Input() public chartWidthRems = 0;

  /**
   * Desired height of the chart canvas in rems.
   *
   * HostBinding is used to set the desired height on the host element. The inner element handles padding compensation.
   */
  @HostBinding('style.height.rem') @Input() public chartHeightRems = 0;

  /**
   * Adjust the top of the container to account for the ticks that extend about the top axis.
   * Setting via TypeScript to hook into the shared constant.
   */
  public topAdjustment = `${-1 * pxToRem(TICK_EXTENSION_HEIGHT_PX)}`;
}
