/* eslint-disable
  @typescript-eslint/no-use-before-define,
  @typescript-eslint/explicit-module-boundary-types,
  @typescript-eslint/no-explicit-any,
  @typescript-eslint/member-ordering
*/
import { Component, EventEmitter, forwardRef, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatRadioChange, MatRadioGroup } from '@angular/material/radio';
import { noop } from 'rxjs';

export interface WithTemplateRef {
  selectedTemplateRef?: TemplateRef<unknown>;
}

/**
 *  Component to allow a list of radio buttons that each can contain arbitrary templates for subcomponents
 */
@Component({
  selector: 'vue-radio-group',
  templateUrl: './vue-radio-group.component.html',
  styleUrls: [ './vue-radio-group.component.scss' ],
  providers: [ {
    provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => VueRadioGroupComponent), multi: true
  } ]
})
export class VueRadioGroupComponent<T extends WithTemplateRef> implements ControlValueAccessor {
  /** The position of the label relative to the radio button */
  @Input() public labelPosition: 'before' | 'after' = 'after';

  /** If form field is required */

  @Input() public required = false;

  /** Color of the form field */
  @Input() public color: 'primary' | 'accent' | 'warn' = 'primary';

  /** Current value of the radio group */
  @Input() public value?: T;

  /** Array of items that represent all of the radio buttons */
  @Input() public items: T[] = [];

  /** How to display the label of the radio button */
  @Input() public getItemDisplay: (item: T) => string = (item) => String(item);

  /** Emits when the value of the radio group changes */
  @Output() public change: EventEmitter<MatRadioChange> = new EventEmitter();

  /** Reference to the material radio group */
  @ViewChild(MatRadioGroup) public radioGroup!: MatRadioGroup;

  // ControlValueAccessor functions
  private onChangeCallback: (_: any) => void = noop;
  private onTouchedCallback = noop;

  /**
   * Update parent form of new value
   */
  public changed(event: MatRadioChange): void {
    this.onChangeCallback(event.value);
    this.change.emit(event);
  }

  /**
   * Set value from parent form
   */
  public writeValue(value: T): void {
    this.value = value;
  }

  /**
   * Stores the change callback from parent form
   */
  public registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  /**
   * Stores the touch callback from parent form
   */
  public registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }
}
