import {
  Component,
  OnInit
} from '@angular/core';
import { AlertsFiltersService } from 'services/alerts-filters.service';
import { AlertsPageDataService } from 'services/alerts-page-data.service';

@Component({
  selector: 'app-Alerts-page',
  templateUrl: './Alerts-page.component.html',
})
export class AlertsPageComponent implements OnInit {
  constructor(
    private alertsFiltersService: AlertsFiltersService,
    private alertsPageDataService: AlertsPageDataService
  ) { }

  ngOnInit(): void {
    this.alertsPageDataService.searchAlerts();
  }

  public resetFilter(): void {
    this.alertsFiltersService.resetFilters();
    this.searchAlerts();
  }

  public searchAlerts(): void {
    this.alertsPageDataService.searchAlerts();
  }
}
