import { Component, Input } from '@angular/core';

/**
 * Standard structure for chart popovers.
 * Optional title in the header
 * The "body" & actions of the popover should be passed in as a children
 */
@Component({
  selector: 'app-base-chart-popover',
  templateUrl: './base-chart-popover.component.html',
  styleUrls: [ './base-chart-popover.component.scss' ]
})
export class BaseChartPopoverComponent {
  @Input() public title?: string;
}
