import { Component } from '@angular/core';

/**
 * A simple, presentational component to style a section's header actions
 */
@Component({
  selector: 'vue-section-header-actions',
  template: '<ng-content></ng-content>',
  styleUrls: [ './vue-section-header-actions.component.scss' ]
})
export class VueSectionHeaderActionsComponent {}
