import { ConnectedOverlayPositionChange, FlexibleConnectedPositionStrategy, OverlayRef } from '@angular/cdk/overlay';
import { fromEvent, Observable, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { VueOverlayOpenDirective } from 'vue/directives/vue-overlay-open/vue-overlay-open.directive';

import { VuePopoverConfig } from './vue-popover-config';

/**
 * Reference to a popover opened via the Popover service.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class VuePopoverRef<T = any> {
  public config: VuePopoverConfig
  private afterClosedSubject = new Subject<T>();
  private overlayRef: OverlayRef
  private positionStrategy: FlexibleConnectedPositionStrategy

  public constructor(
    overlayRef: OverlayRef,
    positionStrategy: FlexibleConnectedPositionStrategy,
    config: VuePopoverConfig,
    private vueOverlayOpen: VueOverlayOpenDirective,
  ) {
    this.overlayRef = overlayRef;
    this.positionStrategy = positionStrategy;
    this.config = config;

    if (!config.disableClose) {
      this.overlayRef.backdropClick()
        .pipe(take(1))
        .subscribe(() => {
          this.close();
        });

      this.overlayRef.keydownEvents()
        .pipe(filter((event) => event.key === 'Escape'))
        .pipe(take(1)).subscribe(() => {
          this.close();
        });

      // Close after clicking anywhere. (Wait a tick for the _opening_ click to bubble through.)
      setTimeout(() => {
        fromEvent(document, 'click')
          .pipe(take(1))
          .subscribe(() => {
            this.close();
          });
      });
    }
  }

  public close(dialogResult?: T): void {
    this.afterClosedSubject.next(dialogResult);
    this.afterClosedSubject.complete();

    // Only dispose of the overlayRef if it is attached
    if (this.overlayRef.hasAttached()) {
      this.overlayRef.dispose();

      this.vueOverlayOpen.removeClassName();
    }
  }

  public afterClosed(): Observable<T> {
    return this.afterClosedSubject.asObservable();
  }

  public positionChanges(): Observable<ConnectedOverlayPositionChange> {
    return this.positionStrategy.positionChanges;
  }
}
