<div [formGroup]="addPoolconsumptionrunfrequency">
  <h4>{{ "label.run_fequency" | translate | async }}</h4>
  <div formArrayName="Time" *ngFor="let data of runFrequrncyGetter.controls; let i=index">
    <div [formGroupName]="i">
      <div class="innerclassthros">
        <mat-form-field appearance="outline" id="input_RunFrequency">
          <mat-label>{{ "label.time" | translate | async }} </mat-label>
          <mat-select formControlName="Time" (click)="getutcData()">
            <mat-option *ngFor="let dat of patchData" [value]="dat">{{dat}}({{ "title.utc" | translate | async}})</mat-option>
          </mat-select>
          <span
            *ngIf="editData(i).controls['Time'].errors && (editData(i).controls['Time'].dirty ||  editData(i).controls['Time'].touched )"></span>
        </mat-form-field>
        <mat-form-field appearance="outline" id="input_RunFrequency">
          <mat-label>{{ "label.prioritys" | translate | async }} </mat-label>
          <mat-select formControlName="Priority">
            <mat-option [value]="data" *ngFor="let data of priorityOption">{{data}}</mat-option>
          </mat-select>
          <span
            *ngIf="editData(i).controls['Priority'].errors && (editData(i).controls['Priority'].dirty ||  editData(i).controls['Priority'].touched )"></span>
        </mat-form-field>
        <mat-form-field appearance="outline" id="input_RunFrequency">
          <mat-label>{{ "label.critical" | translate | async }}</mat-label>
          <mat-select formControlName="Critical">
            <mat-option value="{{data}}" *ngFor="let data of option">{{data}}</mat-option>
          </mat-select>
          <span
            *ngIf="editData(i).controls['Critical'].errors && (editData(i).controls['Critical'].dirty ||  editData(i).controls['Critical'].touched )"></span>
        </mat-form-field>
        <span class="crossButton" (click)="removeRunfrequencyListitems(i)">&cross;</span>
      </div>
      <div class="day-holder">
        <div>
          <p>{{"label.days" | translate | async }}</p>
          <mat-radio-group *ngIf="this.runFrequrncyGetter.length-1 == i" formControlName="weekSelection"
            (change)="getDatafromDailytask($event, i)">
            <div>
              <mat-radio-button class="example-margin" value="daily">{{'button.everyday' | translate | async
                }}</mat-radio-button>
            </div>
            <div>
              <mat-radio-button class="example-margin" value="weekly">{{'button.weekly' | translate | async
                }}</mat-radio-button>
            </div>
          </mat-radio-group>
        </div>
        <br>
        <span *ngFor="let day of editData(i).controls['weekDays'].value">
          <input type="checkbox" [value]="day.value" [id]="i+day.value" [checked]="day.checked"
            (click)="checkboxChange(i,day.value)">
          <label [for]="i+day.value"> {{ day.value.slice(0, 1).toLocaleUpperCase() }} </label>
        </span>
        <span style="margin-left: 21px;" class="error"
          *ngIf=" editData(i).controls['weekDays']?.hasError('containsTest')"><small>{{ 'messages.weekdays' | translate | async }}</small></span>
      </div>
    </div>
  </div>
  <div class="Extrabox">
    <button (click)="pushDatainGetter()" [disabled]="isformDirty()" mat-button color="primary" [matTooltip]="this.runFrequrncyGetter.value.length != maxnumber ? '': updatedtooltip "><span>+</span>&nbsp;{{
      'button.add_another_run_frequency' | translate | async }}
    </button>
  </div>

  <!-- <div class="error" *ngIf="editData(i).controls['Time'].errors &&
        (editData(i).controls['Time'].dirty &&  editData(i).controls['Time'].touched )"><small>{{ 'messages.invalid' |
          translate | async }}
            !</small></div> -->