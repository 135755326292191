<mat-card
  aria-live="polite"
  [class.has-action]="hasAction"
>
  <div>
    <img src="assets/icons/icon-bell-red.svg" alt="">
  </div>
  <h3 *ngIf="heading;else defaultHeading">
    {{heading}}
  </h3>
  <ng-template #defaultHeading>
    <h3>{{ 'error.title.server' | translate | async }}</h3>
  </ng-template>
  <p *ngIf="message;else defaultMessage">
    {{message}}
  </p>
  <ng-template #defaultMessage>
    <p>{{ 'error.message.server' | translate | async }}</p>
  </ng-template>
  <span *ngIf="hasAction">
    <ng-content></ng-content>
  </span>
</mat-card>
