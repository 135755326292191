<mat-form-field appearance="outline" [class.error-spacing]="!!invalidRegex">
  <img class="search-icon" src="../../../assets/icons/icon-search.svg" alt="" />
  <input
    matInput
    #inputModel="ngModel"
    class="search-input"
    type="text"
    autocomplete="off"
    (ngModelChange)="valueChange($event)"
    [placeholder]="placeholder"
    [ngModel]="inputValue"
    [errorStateMatcher]="errorMatcher"
  >
  <button
    *ngIf="inputModel.control.value"
    mat-icon-button
    matSuffix
    (click)="clearInput()"
    [attr.aria-label]="'label.aria.clearSearchValue' | vueTranslate | async"
  >
    <mat-icon>cancel</mat-icon>
  </button>
  <mat-error>{{ 'error.invalidCharacter' | vueTranslate | async }}</mat-error>
</mat-form-field>
