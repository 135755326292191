/* eslint-disable
  @typescript-eslint/no-use-before-define,
  @typescript-eslint/explicit-module-boundary-types,
  @typescript-eslint/no-explicit-any
*/
import { AfterViewInit, Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';
import { noop } from 'rxjs';

/**
 * VueUI implementation of a slide-style on/off switch
 */
@Component({
  selector: 'vue-slide-toggle',
  templateUrl: './vue-slide-toggle.component.html',
  styleUrls: [ './vue-slide-toggle.component.scss' ],
  providers: [ {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => VueSlideToggleComponent),
    multi: true
  } ]
})
export class VueSlideToggleComponent implements ControlValueAccessor, AfterViewInit {
  @Input() public checked = false;
  @Input() public disabled = false;
  @Input() public color: 'primary' | 'accent' | 'warn' = 'primary';
  @Input() public labelPosition: 'before' | 'after' = 'after'
  @Input() public formControlName!: string;
  @Output() public change: EventEmitter<MatSlideToggleChange> = new EventEmitter();
  @Output() public blur: EventEmitter<Event> = new EventEmitter();
  @Output() public focus: EventEmitter<Event> = new EventEmitter();
  @ViewChild('matSlideToggle') public matSlideToggle?: MatSlideToggle;

  // ControlValueAccessor functions
  private onChangeCallback: (_: any) => void = noop;
  private onTouchedCallback = noop;

  public ngAfterViewInit(): void {
    // Add focus and blur events to matSlideToggle
    const hiddenInput = this.matSlideToggle?._inputElement?.nativeElement;
    if (hiddenInput) {
      hiddenInput.addEventListener('blur', (event) => {
        this.blur.emit(event);
      });
      hiddenInput.addEventListener('focus', (event) => {
        this.focus.emit(event);
      });
    }
  }

  /**
   * Handle the change event
   *
   * @param changeEvent the event issued from the material component
   */
  public changed(changeEvent: MatSlideToggleChange): void {
    this.onChangeCallback(changeEvent.checked);
    this.change.emit(changeEvent);
  }

  /**
   * Provide callback for the parent form to update values
   *
   * @param value the updated value to set
   */
  public writeValue(value: any): void {
    this.checked = Boolean(value);
  }

  /**
   * Register onchange handler for parent form
   *
   * @param fn the form's callback
   */
  public registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  /**
   * Register OnTouched handler for parent form
   *
   * @param fn the form's callback
   */
  public registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }
}
