import { Component, Input, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { HelpUrlService } from 'services/api/help-url.service';
import { AuthService } from 'services/authentication/auth.service';

/**
 * App-wide header nav bar
 */
@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: [ './nav-bar.component.scss' ]
})
export class NavBarComponent implements OnInit {
  /**
   * Name of the logged in user
   */
  @Input() public userName = ''

  /**
   * Help URL for OnVUE Capacity
   */
  public helpURL: string | null = null;

  public constructor(
    private authService: AuthService,
    private helpUrlService: HelpUrlService,
  ) {}

  public ngOnInit(): void {
    // Fetch Help Url
    this.helpUrlService.getHelpUrl()
      .pipe(take(1))
      .subscribe((helpURL) => {
        this.helpURL = helpURL;
      });
  }

  /**
   * Logs the user out via the AuthService
   */
  public logout(): void {
    this.authService.logout();
  }
}
