<div
  class="totals"
  [ngClass]="{'warn': total > 100}"
  aria-live="polite"
>
  <div class="total-used">
    {{total | number :'1.2-2'}}%
  </div>
  <div
    *ngIf="total != 100"
    class="total-remaining"
  >
    <strong>{{100 - total | number :'1.2-2'}}%</strong> {{ "label.addTemplate.remaining" | translate | async }}
  </div>
</div>
