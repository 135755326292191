import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { DATE_PARSE_ERROR, END_CHRONOLOGICAL_ORDER, EXCEEDS_END_DATE, INVALID_DATE, NON_SATURDAY_DATE, NON_SUNDAY_DATE, PAST_DATE, START_CHRONOLOGICAL_ORDER } from 'constants/datepicker-errors';
import { TranslationKey } from './translate.pipe';

/**
 * Converts an datepicker error to a translation key
 */
@Pipe({
  name: 'datepickerError',
})
export class DatepickerErrorPipe implements PipeTransform {
  /**
   * Coupling of error keys with the respective translation key
   *
   * Important: Order of error keys is the priority of the error to be shown.
   */
  private errorTranslations: {[key: string]: TranslationKey} = {
    [ INVALID_DATE ]: 'datepicker.error.invalid-date',
    [ DATE_PARSE_ERROR ]: 'datepicker.error.invalid-date',
    [ PAST_DATE ]: 'datepicker.error.past-date',
    [ END_CHRONOLOGICAL_ORDER ]: 'datepicker.error.end-date-before-start',
    [ START_CHRONOLOGICAL_ORDER ]: 'datepicker.error.start-date-after-end',
    [ NON_SATURDAY_DATE ]: 'datepicker.error.non-saturday-date',
    [ NON_SUNDAY_DATE ]: 'datepicker.error.non-sunday-date',
    [ EXCEEDS_END_DATE ]: 'datepicker.error.exceeds-end-date',
  }

  /**
   * Return the translation key for a given datepicker error
   *
   * Returns null if a defined error is not found
   */
  public transform(errors: ValidationErrors | null): TranslationKey | null {
    if (!errors) {
      return null;
    }

    // Check for error with one of the defined keys
    const errorTranslation = Object.entries(this.errorTranslations)
      .find(([ errorKey ]) => Boolean(errors[ errorKey ]));

    // Return first error key found
    return errorTranslation?.[1] ?? null;
  }
}
