import { Injectable } from '@angular/core';
import { Moment } from 'moment';
import { BehaviorSubject } from 'rxjs';
import { timezones } from 'constants/timezones';
import { Timezone } from 'types/Timezone';

export type ChangedOffsetResult = {
  changed: true;
  startOfDayOffset: number;
  endOfDayOffset: number;
} | {
  changed: false;
}

/**
 * Service that provides timezones
 */
@Injectable({
  providedIn: 'root'
})
export class TimezoneService {
  /**
   * Observable that contains the selected timezone so it is persisted across the application
   */
  public selectedTimezone$ = new BehaviorSubject<Timezone | null>(null);

  /**
   * Determines if a timezone change occurs for the given day in UTC & timezone,
   *
   * If there is an offset change, both the `startOfDayOffset` & `endOfDayOffset` are returned with the `changed` value
   */
  public doesOffsetChangeOnDay(date: Moment, tz: Timezone): ChangedOffsetResult {
    const startOfDay = date.clone().utc().startOf('d').toDate();
    const endOfDay = date.clone().utc().endOf('d').toDate();

    const startOfDayOffset = this.getTimezoneOffset(tz.ianaCode, startOfDay);
    const endOfDayOffset = this.getTimezoneOffset(tz.ianaCode, endOfDay);

    if (startOfDayOffset === endOfDayOffset) {
      return { changed: false };
    }

    return { changed: true, startOfDayOffset, endOfDayOffset };
  }

  /**
   * Get a list of timezones relative to the start of the supplied date in UTC
   */
  public generateTimezones(date: Moment): Timezone[] {
    const startOfDay = date.clone().utc().startOf('d');

    return timezones.map((tz) => {
      return {
        ...tz,
        offset: this.getTimezoneOffset(tz.ianaCode, startOfDay.toDate()),
      };
    });
  }

  /**
   * Updates selected timezone
   */
  public updateSelectedTimezone(tz: Timezone | null): void {
    this.selectedTimezone$.next(tz);
  }

  /**
   * Return the offset for the given date & timezone
   */
  private getTimezoneOffset(tz: Timezone['ianaCode'], date: Date): number {
    // en-GB locale includes the offset in the date format expect for Europe/London timezone
    const d = new Intl.DateTimeFormat('en-GB', {
      timeZone: tz,
      timeZoneName: 'short'
    }).format(date);

    const transformedPartialOffsets = d.replace(':30', '.5').replace(':45', '.75');

    return d.includes('BST') ?

      // Replace BST offset for Europe/London
      parseFloat(transformedPartialOffsets.split('BST')[ 1 ] || '1') :

      // By default look for GMT offset
      parseFloat(transformedPartialOffsets.split('GMT')[ 1 ] || '0');
  }
}
