import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import translationJSON from '../data/translation.json';

export interface TranslationLookup { [key: string]: string }
export type TranslationKey = keyof typeof translationJSON;

/**
 * Pipe used to translate text.
 *
 * Usage:
 * Default: `{{ 'translation.key' | translate | async }}` in template
 * Can be used through depending injection directly into classes
 */
@Pipe({ name: 'translate' })
export class TranslatePipe implements PipeTransform {
  /**
   * Gets the translation from local file based on input key.
   *
   * @param key the translation key.
   * @param replacementTexts optional array of text to placed within the translated text.
   * Replaces the same index of {i} within the translated string
   * @returns translation
   */
  public transform(key: TranslationKey | null, ...replacementTexts: (string | number)[]): Observable<string> {
    if (key === null) {
      return of('');
    }

    let translatedText = translationJSON[ key ] || '';

    if (replacementTexts.length) {
      replacementTexts.forEach((replacement, index) => {
        translatedText = translatedText.replace(`{${index}}`, `${replacement}`);
      });
    }

    return of(translatedText);
  }

  /**
   * Loads a set of string translations used for localization.
   *
   * @param keys The translation keys to look up.
   * @returns A lookup containing translation values.
   */
  public loadTranslations(keys: TranslationKey[]): Observable<TranslationLookup> {
    const result: TranslationLookup = {};
    keys.forEach((key) => {
      const translatedText = translationJSON[ key ] || '';
      result[ key ] = translatedText;
    });
    return of(result);
  }
}
