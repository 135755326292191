import { Injectable } from '@angular/core';
import { GetCapacityTemplatesParameters, GetCapacityTemplatesResponse } from 'api/types/endpoints/getCapacityTemplates';
import { Observable } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import {
  CapacityTemplateDetails,
  CreateCapacityTemplateRequestBody,
  CreateCapacityTemplateResponse,
  GetCapacityTemplateCalculationResponse,
  UpdateCapacityTemplateBody,
  UpdateCapacityTemplateResponse
} from 'api/types';
import { createQueryString } from 'utils/create-query-string';
import { CapacityMicroservice } from './microservices/capacity.microservice';
import { GetTemplateNameParameters, GetTemplateNameResponse } from 'api/types/endpoints/getTemplateName';

/**
 * API service for the CapacityTemplates domain
 */
@Injectable({
  providedIn: 'root'
})
export class CapacityTemplatesService {
  /**
   * Default params that can be overridden by input
   */
  private defaultParams: GetCapacityTemplatesParameters = {
    limit: 25,
    offset: 0,
    sortBy: 'name',
    direction: 'asc'
  }

  public constructor(private api: CapacityMicroservice) { }

  /**
   * Calls GetTemplates API
   *
   * @param params optional params for get capacity templates call
   * @returns GetCapacityTemplatesResponse Observable
   */
  public getCapacityTemplates(
    params?: Partial<GetCapacityTemplatesParameters>): Observable<GetCapacityTemplatesResponse> {
    const queryString = createQueryString({
      ...this.defaultParams,
      ...params
    });
    const path = `capacity-templates${queryString}`;

    return this.api.get<GetCapacityTemplatesResponse>(path);
  }

  /**
   * Calls GetCapacityTemplateCalculations API
   *
   * @param id template id
   * @param totalCapacity total capacity for appointment allocation
   * @returns GetCapacityTemplatesResponse Observable
   */
  public getCalculations(id: string, totalCapacity: number): Observable<GetCapacityTemplateCalculationResponse> {
    const queryString = createQueryString({
      totalCapacity
    });
    const path = `capacity-templates/${id}/calculations${queryString}`;

    return this.api.get<GetCapacityTemplateCalculationResponse>(path);
  }

  /**
   * Calls CapacityTemplateById API
   *
   * @param id Id of template
   * @returns CapacityTemplateDetails Observable
   */
  public getCapacityTemplateById(id: string): Observable<CapacityTemplateDetails> {
    const path = `capacity-templates/${id}`;

    return this.api.get<CapacityTemplateDetails>(path);
  }

  /**
   * Calls UpdateCapacityTemplates API
   *
   * @param id ID of template to change
   * @param status 'active' | 'archived'
   * @returns UpdateCapacityTemplateResponse
   */
  public updateCapacityTemplateStatus(
    id: string,
    status: 'active' | 'archived'
  ): Observable<UpdateCapacityTemplateResponse> {
    const path = `capacity-templates/${id}`;

    return this.getCapacityTemplateById(id).pipe(take(1)).pipe(
      mergeMap((template) => {
        const updatedTemplate = {
          ...template,
          status
        };
        return this.api.patch<UpdateCapacityTemplateResponse, UpdateCapacityTemplateBody>(path, updatedTemplate);
      })
    );
  }

  /**
   * Calls UpdateCapacityTemplate API
   *
   * @param id ID of template to update
   * @param template CapacityTemplateDetails
   * @returns UpdateCapacityTemplateResponse
   */
  public updateCapacityTemplate(
    id: string,
    template: UpdateCapacityTemplateBody
  ): Observable<UpdateCapacityTemplateResponse> {
    const path = `capacity-templates/${id}`;

    return this.api.patch<UpdateCapacityTemplateResponse, UpdateCapacityTemplateBody>(path, template);
  }

  /*
   * Creates capacity template
   *
   * @param payload CreateCapacityTemplateRequestBody
   * @returns CreateCapacityTemplateResponse Observable
   */
  public createCapacityTemplate(
    payload: CreateCapacityTemplateRequestBody
  ): Observable<CreateCapacityTemplateResponse> {
    return this.api.post<CreateCapacityTemplateResponse, CreateCapacityTemplateRequestBody>(
      'capacity-templates', payload
    );
  }

  public getTemplateNames(params?: Partial<GetTemplateNameParameters>): Observable<GetTemplateNameResponse> {
    const queryString = createQueryString({
      startsWith: '',
      ...params
    });
    const path = `templates/names${queryString}`;

    return this.api.get<GetTemplateNameResponse>(path);
  }
}
