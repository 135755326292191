import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { DailyCapacityCalculations } from 'api/types';
import { dayNumber } from 'constants/days-of-the-week';
import { TranslatePipe, TranslationKey } from 'pipes/translate.pipe';
import { VUE_DIALOG_CONFIG } from 'vue/utilities/vue-dialog-config';

/**
 * Component that displays a days worth of hourly allocations
 */
@Component({
  selector: 'app-hourly-allocation-dialog',
  templateUrl: './hourly-allocation-dialog.component.html',
  styleUrls: [ './hourly-allocation-dialog.component.scss' ],
})
export class HourlyAllocationDialogComponent implements AfterViewInit {
  /**
   * Reference to the title element
   */
  @ViewChild('heading') public headingElement?: ElementRef<HTMLHeadingElement>;

  /**
   * Current day of week index showing on the dialog
   * 0 = sunday, 1= monday etc, 6 = saturday. Will never be higher than 6
   */
  public currentDayIndex = 0;

  /**
   * The title to be displayed at the top of the dialog
   */
  public dialogTitle = '';

  private titleTranslations: TranslationKey[] = [
    'title.hourlyAllocations.sunday',
    'title.hourlyAllocations.monday',
    'title.hourlyAllocations.tuesday',
    'title.hourlyAllocations.wednesday',
    'title.hourlyAllocations.thursday',
    'title.hourlyAllocations.friday',
    'title.hourlyAllocations.saturday',
  ];

  public constructor(
    // eslint-disable-next-line @typescript-eslint/no-parameter-properties
    @Inject(MAT_DIALOG_DATA) public data: HourlyAllocationDialogInputs,
    private dialogRef: MatDialogRef<HourlyAllocationDialogComponent>,
    private translatePipe: TranslatePipe
  ) {
    this.updateDialogTitle();
  }

  public ngAfterViewInit(): void {
    // set focus to heading element
    this.headingElement?.nativeElement.focus();
  }

  /**
   * Closes dialog
   */
  public closeDialog(): void {
    this.dialogRef.close();
  }

  /**
   * Updates the day of the week to the next one and then makes a call to update
   * dialog title. If the current day index is 6 (AKA saturday) this function
   * just closes the dialog, as there are no more days of the week to show
   */
  public next(): void {
    if (this.currentDayIndex < 6) {
      this.currentDayIndex += 1;
      this.updateDialogTitle();
    } else {
      this.closeDialog();
    }
  }

  /**
   * Updates the day of the week to the previous one and then makes a call to update
   * dialog title
   */
  public previous(): void {
    this.currentDayIndex -= 1;
    this.updateDialogTitle();
  }

  /**
   * Updates the title to be shown on the dialog when the day of the week changes
   */
  public updateDialogTitle(): void {
    const dayOfWeek =
      this.data.dailyCalculations[ this.currentDayIndex ].dayOfWeek;
    const dayNum = dayNumber[ dayOfWeek ];

    this.translatePipe
      .transform(this.titleTranslations[ dayNum ])
      .pipe(take(1))
      .subscribe((title) => {
        this.dialogTitle = title;
      });
  }
}

/**
 * Config to be passed to the dialog when opening it from the template form
 */
export const hourlyAllocationDialogConfig = {
  ...VUE_DIALOG_CONFIG,
  width: '24rem',
};

export interface HourlyAllocationDialogInputs {

  /**
   * Array of hourly calculations for each day of the week
   */
  dailyCalculations: DailyCapacityCalculations;
}
