<form [formGroup]="copyPoolconsumptionform">
    <!-- {{copyPoolconsumptionform.value |json}} -->
    <vue-text-input
    label="{{ 'label.alerts' | translate | async }}"
    formControlName="getDatafromAlert"
    [required]="true"
    [errorMatcher]="poolNameErrorMatcher"
    errorMessage="{{ poolNameErrorMessageKey() | translate | async }}"
  ></vue-text-input>
   
    <app-add-pool-consumption-searchpool  formControlName="search_pool" [copyAlertFormData]="copyAlertFormData"></app-add-pool-consumption-searchpool>
    <app-add-pool-consumption-threshold formControlName="Threshold" [copyAlertFormData]="copyAlertFormData"></app-add-pool-consumption-threshold>
    <app-add-pool-consumption-runfrequency formControlName="run_Frequency" [copyAlertFormData]="copyAlertFormData"></app-add-pool-consumption-runfrequency>
  
  </form>