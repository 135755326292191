<div class="options-wrapper" cdkTrapFocus cdkTrapFocusAutoCapture role="dialog">
  <vue-filter-search
    *ngIf="includeOptionSearch"
    class="search"
    [placeholder]="searchPlaceholder"
    (searchValueChange)="filterDisplayedOptions($event)"
  ></vue-filter-search>
  <div class="checkboxes">
    <mat-checkbox
      *ngFor="let option of displayedOptions"
      class="option"
      tabIndex="0"
      (change)="updateChecked($event, option.value)"
      [checked]="option.checked"
    >
      {{option.display}}
    </mat-checkbox>
    <p *ngIf="displayedOptions.length === 0" class="no-results">
      {{ "message.noResultsToDisplay" | translate | async }}
    </p>
  </div>
  <div class="action-buttons-wrapper">
    <vue-basic-button
      (click)="cancelFilters()"
      class="cancel-button"
    >
      {{ "button.cancel" | translate | async }}
    </vue-basic-button>
    <vue-basic-button
      (click)="clearFilters()"
      color="warn"
    >
      {{ "button.clear" | translate | async }}
    </vue-basic-button>
    <vue-flat-button (click)="applyFilters()">
      {{ "button.apply" | translate | async }}
    </vue-flat-button>
  </div>
</div>
