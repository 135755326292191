import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { utc } from 'moment';
import { RegistrationForDate } from 'api/types';
import { EditOccurrenceDialogComponent, editOccurrenceDialogConfig, EditOccurrenceDialogInputs } from 'components/common/pools/edit-occurrence-dialog/edit-occurrence-dialog.component';
import { RemoveOccurrenceComponent, removeOccurrenceConfig, RemoveOccurrenceInputs } from 'components/common/pools/remove-occurrence/remove-occurrence.component';
import { PoolDialogComponent, poolOccurrenceConfig } from 'components/dialogs/pool-dialog/pool-dialog.component';
import { RequiredDetailsForOccurrence } from 'components/tables/pool-drawer-occurrence-table/pool-drawer-occurrence-table.component';
import { DISPLAY_DAY_FORMAT, EXCHANGE_FORMAT } from 'constants/date-formats';
import { TranslatePipe } from 'pipes/translate.pipe';
import { EditPoolOccurrencesService } from 'services/edit-pool-occurrences.service';

/**
 *  Menu flyout for pool occurrences with edit, release, unrelease and remove actions.
 */
@Component({
  selector: 'app-pool-drawer-occurrence-menu',
  templateUrl: './pool-drawer-occurrence-menu.component.html',
  styleUrls: ['./pool-drawer-occurrence-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PoolDrawerOccurrenceMenuComponent {
  /**
   * Date
   * Format: YYYY-MM-DD
   */
  @Input() public date = '';

  /**
   * True when an occurrence is already released
   */
  @Input() public isReleased!: boolean;

  /**
   * Details about the occurrence
   */
  @Input() public poolDetails!: RequiredDetailsForOccurrence;

  /**
   * Registration metrics for entire pool
   */
  @Input() public registrations: RegistrationForDate[] = [];

  @Input() public pageRef!:string;

  @Input() public isOverride!: boolean;

  @Input() public occurrenceDateHasPassed!: boolean;

  private editOccurrenceLimit = 14;
  public constructor(
    private dialog: MatDialog,
    private editOccurrenceService: EditPoolOccurrencesService,
    private translatePipe: TranslatePipe,
  ) { }

  /**
   * Opens dialog to edit a single occurrence
   */
  public openOccurrenceEdit(): void {
    const formattedDate = utc(this.date, EXCHANGE_FORMAT).format(DISPLAY_DAY_FORMAT);

    const existingOverride = this.editOccurrenceService.getOverride(this.date);

    /*
     * Create pool details with unsaved override if it exists
     * Other overrides other than the date aren't needed for editing an occurrence
     */
    if (this.validateOccurrenceLimit()) {
      const pool: RequiredDetailsForOccurrence = {
        ...this.poolDetails,
        scheduleOverrides: existingOverride ?
          [existingOverride] :
          this.poolDetails.scheduleOverrides
      };

      this.dialog.open<EditOccurrenceDialogComponent, EditOccurrenceDialogInputs>(EditOccurrenceDialogComponent, {
        ...editOccurrenceDialogConfig,
        data: {
          dialogTitle: this.translatePipe.transform('occurrence.edit.dialog.heading', formattedDate),
          date: this.date,
          pageRef: this.pageRef,
          overrideCallback: (updatedOverride) => {
            this.editOccurrenceService.addOverride(updatedOverride);
          },
          poolDetails: pool,
          registrations: this.registrations.find((r) => r.date === this.date) || { date: this.date, items: [] },
        }
      });
    }
  }

  /**
   * Opens dialog to remove the occurrence from the pool schedule
   */
  public removePoolOccurrence(): void {
    const formattedDate = utc(this.date, EXCHANGE_FORMAT).format(DISPLAY_DAY_FORMAT);
    this.dialog.open<RemoveOccurrenceComponent, RemoveOccurrenceInputs>(RemoveOccurrenceComponent, {
      ...removeOccurrenceConfig,
      data: {
        title: this.translatePipe.transform('occurrence.remove.dialog.heading', formattedDate),
        removeOccurrenceCallback: () => {
          this.editOccurrenceService.removeOccurrenceDate(this.date);
        }
      }
    });
  }

  /**
   * Release the occurrence
   */
  public releaseOccurrence(): void {
    if (this.validateOccurrenceLimit()) {
      this.editOccurrenceService.releaseOccurrence(this.date);
    }
  }

  /**
   * Unrelease the occurrence
   */
  public unreleaseOccurrence(): void {
    if (this.validateOccurrenceLimit()) {
      this.editOccurrenceService.unreleaseOccurrence(this.date);
    }
  }

  public validateOccurrenceLimit(): boolean {
    const existingOverride = this.editOccurrenceService.getOverride(this.date);
    if (this.editOccurrenceService.scheduleOverrides$.value.length > this.editOccurrenceLimit && !existingOverride) {
      this.dialog.open(PoolDialogComponent, {
        ...poolOccurrenceConfig
      });
      return false;
    }
    return true;
  }

  /**
   * @param date date to localize in YYYY-MM-DD format
   * @returns localized date through moments locale
   */
  public displayLocalizedDate(date: string): string {
    return utc(date, EXCHANGE_FORMAT).format(DISPLAY_DAY_FORMAT);
  }

  public applyPoolSettings(): void {
    this.editOccurrenceService.applyPoolSettingsDates(this.date);
  }
}
