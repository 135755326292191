<vue-page-header
  appName="{{ 'title.applicationName' | translate | async }}"
  pageTitle="{{ 'title.overview' | translate | async }}"
  navSection="{{ 'title.appointmentManagement' | translate | async }}"
>
  <app-filter-container>
    <app-appointments-filters
      [queues]="queues"
    ></app-appointments-filters>
    <app-set-capacity-drawer
      (success)="refreshMetricsData$.next()"
    ></app-set-capacity-drawer>
  </app-filter-container>
</vue-page-header>
<app-page-body>
  <vue-section [loading]="pageLoading">
    <app-appointments-chart-daily
      *ngIf="timescale() === 'daily'"
      [timezone]="timezone"
      [metricItems]="criticalMetricItems"
      [firstColumnWidthRems]="firstColumnWidthRems"
      [columnWidthRems]="columnWidthRems"
      [darkenTopOfTheHourTicks]="intervalIs15min()"
    ></app-appointments-chart-daily>
    <app-appointments-chart-weekly
      *ngIf="timescale() === 'weekly'"
      [metricItems]="criticalMetricItems"
      [days]="days"
      [interval]="interval"
    ></app-appointments-chart-weekly>
    <app-appointments-chart-monthly
      *ngIf="timescale() === 'monthly'"
      [metricItems]="metricItems"
      [selectedMonth]="selectedMonth"
    ></app-appointments-chart-monthly>
  </vue-section>
  <ng-container *ngIf="timescale() === 'daily'">
    <vue-section-header>
      <h2 class="mat-title">{{ "title.appointmentCapacity" | translate | async }}</h2>
      <vue-section-header-actions></vue-section-header-actions>
    </vue-section-header>
    <app-server-error-unsaved
      *ngIf="showNetworkError" (retry)="retryInlineSave()"
      [message]="inlineErrorMessage"
    ></app-server-error-unsaved>
    <vue-section [loading]="pageLoading">
      <app-appointments-table
        [timezone]="timezone"
        [metricItems]="metricItems"
        [firstColumnWidthRems]="firstColumnWidthRems"
        [columnWidthRems]="columnWidthRems"
      ></app-appointments-table>
    </vue-section>
  </ng-container>
</app-page-body>
<ng-template cdk-portal>
  <app-capacity-unsaved-banner
    *ngIf="timescale() === 'daily'"
  ></app-capacity-unsaved-banner>
</ng-template>
