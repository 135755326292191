import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { utc } from 'moment';
import { PoolCapacityMetric } from 'api/types';
import { ABBREV_DAY_FORMAT, EXCHANGE_FORMAT, FULL_MONTH_DAY_YEAR_FORMAT } from 'constants/date-formats';
import { ChartClickEvent } from 'types/ChartClickEvent';
import { isSupportedChartType, SupportedChartType } from 'types/SupportedChartType';

/**
 * Popover with details of the active time in the chart.  Includes a link to view daily details
 */
@Component({
  selector: 'app-pools-chart-popover',
  templateUrl: './pools-chart-popover.component.html',
})
export class PoolsChartPopoverComponent implements OnInit, OnChanges {
  @Input() public metricItems?: PoolCapacityMetric[]
  @Input() public event?: ChartClickEvent

  public title?: string;

  public details: {
    type: SupportedChartType;
    color: string;
    label: string;
    quantity: string;
  }[] = [];

  public ngOnInit(): void {
    this.buildContent();
  }

  public ngOnChanges(): void {
    this.buildContent();
  }

  private buildContent(): void {
    if (!this.event || !this.metricItems) {
      return;
    }
    const chart = this.event.event.chart;
    const index = (this.event.active || [])[0].index;
    const datasets = chart.data.datasets;
    const metricItem = this.metricItems[index];

    this.title = utc(metricItem.date, EXCHANGE_FORMAT).utc().format(
      `${ABBREV_DAY_FORMAT}, ${FULL_MONTH_DAY_YEAR_FORMAT}`
    );
    /* this.details = (this.event.active || []).map((active) => {
      const { datasetIndex } = active;
      const dataset = datasets[ datasetIndex ];
      const { data } = dataset;
      const label = dataset.label || '';
      const type: SupportedChartType = isSupportedChartType(dataset.type) ? dataset.type : 'line';
      const quantity = String(data[ index ]);
      const color = Array.isArray(dataset.backgroundColor) ? dataset.backgroundColor[ index ] : dataset.backgroundColor;

      return { type, color, label, quantity };
    }); */

    this.details = datasets.map((data) => {
      const label = data.label || '';
      const type: SupportedChartType = isSupportedChartType(data.type) ? data.type : 'line';
      const quantity = String(data.data[index]);
      const color = Array.isArray(data.backgroundColor) ? data.backgroundColor[index] : data.backgroundColor;

      return { type, color, label, quantity };
    })

  }
}
