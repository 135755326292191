/**
 * Convert rem values to pixels, based on the browser's current base font size
 *
 * @param rem the number in rem units
 * @returns px the number in pixel units
 */
export function remToPx(rem: number): number {
  return rem * baseFontSize();
}

/**
 * Convert pixel values to rems, based on the browser's current base font size
 *
 * @param px the number in pixel units
 * @returns rems the number in rem units
 */
export function pxToRem(px: number): number {
  return px / baseFontSize();
}

/**
 * Get the current base font size reported by the browser
 *
 * @returns fontSize the base font size
 */
function baseFontSize(): number {
  return parseFloat(getComputedStyle(document.documentElement).fontSize);
}
