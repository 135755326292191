<mat-form-field [appearance]="appearance" class="compact">
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    vueNumberInput
    type="text"
    mask="separator.2"
    (blur)="handleBlur()"
    [id]="id"
    [restrictDecimals]="false"
    [formControl]="control"
    [placeholder]="placeholder"
    [required]="required"
    [errorStateMatcher]="errorMatcher"
  >
  <mat-error *ngIf="errorMessage && invalid">
    {{errorMessage}}
  </mat-error>
</mat-form-field>
