import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { VuePopoverArrowDirective } from './vue-popover-arrow.directive';
import { VuePopoverComponent } from './vue-popover/vue-popover.component';

/**
 * Module to organize popover-related components, directives and services
 */
@NgModule({
  declarations: [ VuePopoverComponent, VuePopoverArrowDirective ],
  imports: [
    CommonModule,
    OverlayModule,
    PortalModule,
    A11yModule,
  ],
})
export class VuePopoverModule { }
