import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslationKey } from 'pipes/translate.pipe';
import { VUE_DIALOG_CONFIG } from 'vue/utilities/vue-dialog-config';

/**
 * Component that shows a loading spinner on a modal
 */
@Component({
  selector: 'app-loading-dialog',
  templateUrl: './loading-dialog.component.html',
  styleUrls: [ './loading-dialog.component.scss' ]
})
export class LoadingDialogComponent {
  /**
   * The title to be displayed at the top of the dialog
   */
  public dialogTitle: TranslationKey = 'title.loading';

  /**
   * The subtitle to be displayed at the top of the dialog
   */
  public dialogSubtitle?: TranslationKey;

  public constructor(
  // eslint-disable-next-line @typescript-eslint/no-parameter-properties
    @Inject(MAT_DIALOG_DATA) public data: LoadingDialogInput,
    private dialogRef: MatDialogRef<LoadingDialogComponent>
  ) {}

  /**
   * Closes dialog
   */
  public closeDialog(): void {
    this.dialogRef.close();
  }
}

/**
 * Config to be passed to the dialog when opening it from a popover
 */
export const loadingDialogConfig = {
  ...VUE_DIALOG_CONFIG,
};

export interface LoadingDialogInput {

  /**
   * Title for the dialog
   */
  title?: TranslationKey;

  /**
   * Subtitle for the dialog
   */
  subtitle?: TranslationKey;
}
