import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * Display a warning dialog when there are unsaved changes that could be lost.
 */
@Component({
  selector: 'app-unsaved-changes',
  templateUrl: './unsaved-changes.component.html'
})

export class UnsavedChangesComponent {
  /**
   * The passed-in data from the Dialog component
   */
  public data: UnsavedChangesDialogData;

  public constructor(
  @Inject(MAT_DIALOG_DATA) data: UnsavedChangesDialogData,
  ) {
    this.data = data;
  }
}

export interface UnsavedChangesDialogData {
  onLeave: () => void;
  onStay: () => void;
}
