import { Injectable } from '@angular/core';
import { EnvironmentService } from 'services/environment.service';
import { WindowLocationService } from 'services/window-location.service';

interface CookieOptions {
  expires: Date;
  domain: string;
  path: string;
}

/**
 * Service for setting and retrieving cookies from the document
 */
@Injectable({
  providedIn: 'root'
})
export class CookieService {
  /**
   * Regular expression to parse the domain out of the hostname.
   * The hostname could have a subdomain which isn't included when cookies are set.
   *
   * Ex: https://onvuecapacityctt.pearsonvue.com
   * Regular expression will match on: pearsonvue.com
   */
  // eslint-disable-next-line no-useless-escape
  private readonly domainRegex = new RegExp('([a-z0-9]+)\.([a-z]+)$');

  public constructor(
    private environmentService: EnvironmentService,
    private windowLocationService: WindowLocationService,
  ) {}

  /**
   * Sets a cookie on the document with the provided key and value and adds any provided options
   */
  public setCookie(key: string, value: string, options?: Partial<CookieOptions>): void {
    let cookie = `${key}=${value};`;

    if (options?.path) {
      cookie += ` path=${options.path};`;
    }

    if (options?.expires) {
      cookie += ` expires=${options.expires.toUTCString()};`;
    }

    if (options?.domain) {
      cookie += ` domain=${options.domain};`;
    }

    this.addCookieToDocument(cookie);
  }

  /**
   * Returns the value of the cookie with the specified key or undefined if it doesn't exist
   */
  public getCookie(key: string): string | undefined {
    const cookies = this.getAllCookies();
    return cookies[ key ];
  }

  /**
   * Parses cookies from the document into key value pairs
   */
  public getAllCookies(): {[key: string]: string} {
    const cookieObject: {[key: string]: string} = {};

    document.cookie.split(';').forEach((cookie) => {
      const cookieTuple = cookie.split('=').map((c) => c.trim());

      // Make sure tuple is well formed (when there are no cookies, parsing can result in [''])
      if (cookieTuple[ 0 ] && cookieTuple.length === 2) {
        cookieObject[ cookieTuple[ 0 ] ] = cookieTuple[ 1 ];
      }
    });

    return cookieObject;
  }

  /**
   * Removes a cookie
   */
  public removeCookie(key: string): void {
    const { includeFakePortalCookies } = this.environmentService.environment();

    if (includeFakePortalCookies) {
      document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
    } else {
      /*
       * When fake portal cookies aren't used, assume the app is running from the portal.
       * The portal sets the cookies with a domain and the domain needs to be specified when deleting them,
       * otherwise the deletion doesn't occur.
       */
      const domain = this.windowLocationService.getHostname().match(this.domainRegex)?.[0] ?? '';

      document.cookie = `${key}=; domain=${domain}; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
    }
  }

  /**
   * Adds cookie to `document.cookie`
   */
  private addCookieToDocument(cookie: string): void {
    document.cookie = cookie;
  }
}
