import { AbstractControl, ValidationErrors } from '@angular/forms';
import { BulkEditException } from 'api/types';

/**
 * Validator for DailyTimeExceptions
 * Control must contain time, count, and type
 *
 * @param control control in context to validate
 * @returns error object if control fails validation, null if succeeds
 */
export function dailyExceptionValidator(control: AbstractControl): ValidationErrors | null {
  // Empty array is valid, no daily exceptions
  if (control.value.length === 0) {
    return null;
  }

  const errorObject: {[key: string]: BulkEditException} = {};

  control.value.forEach((exception: BulkEditException, i: number) => {
    // Check for missing time, type and count
    if (exception.time === null ||
        typeof exception.capacity.count !== 'number' ||
        exception.capacity.type === null
    ) {
      errorObject[ `incomplete-exception-${i}` ] = exception;
    }
  });

  return Object.keys(errorObject).length ? errorObject : null;
}
