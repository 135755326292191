import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CreateOutageResponse } from 'api/types/endpoints/createOutage';
import { EditOutagePayload } from 'api/types/endpoints/editOutage';
import { GetOutageByIdQueryParameters, GetOutageByIdResponse } from 'api/types/endpoints/getOutageById';
import { formatDateForPayload } from 'components/common/pools/utils/format-date-for-payload';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { OutagesService } from 'services/api/outages.service';
import { DisplayableServerError } from 'types/DisplayableServerError';
import { invalidCharactersValidator } from 'utils/validators/invalid-characters.validator';
import { pastDateValidator } from 'utils/validators/past-date.validator';

const emptyResponse: GetOutageByIdResponse = {
  id: '',
  name: '',
  status: '',
  startdate: '',
  starttime: '',
  enddate: '',
  endtime: '',
  createdby: ''
};

@Injectable({
  providedIn: 'root',
})
export class EditOutageState {
  /**
   * Edit outage form group
   */
  public editOutageForm: UntypedFormGroup;

  public displayableServerError: DisplayableServerError | null = null;

  private initFormValues: { [key: string]: any };

  constructor(private formBuilder: FormBuilder,
    private outageService: OutagesService) {
    // Edit form
    this.editOutageForm = this.createOutageForm();
    this.initFormValues = this.editOutageForm.getRawValue();
  }

  public resetState(): void {
    const outages = this.editOutageForm.get('outages') as FormArray;

    if (outages.length > 1) {
      for (var i = outages.length - 1; i > 0; i--) {
        outages.removeAt(i)
      }
    }
    this.editOutageForm.reset(this.initFormValues);
  }

  /**
   * Creates edit outage form group
   */
  private createOutageForm(): UntypedFormGroup {
    return this.formBuilder.group({
      outages: this.formBuilder.array([this.createOutageFormGroup()])
    });
  }

  public createOutageFormGroup(): UntypedFormGroup {
    return new FormGroup({
      'outageName': new UntypedFormControl('', {
        validators: [
          Validators.required,
          invalidCharactersValidator,
        ],
      }),
      'startDate': new UntypedFormControl('', { validators: [Validators.required, pastDateValidator], updateOn: 'blur' }),
      'startTime': new UntypedFormControl('', Validators.required),
      'endDate': new UntypedFormControl('', { validators: [Validators.required, pastDateValidator], updateOn: 'blur' }),
      'endTime': new UntypedFormControl('', Validators.required),
    })
  }

  public submitOutage(id: string): Observable<CreateOutageResponse> {
    const payload = this.constructPoolPayload();
    return this.outageService.editOutage(id, payload);
  }

  private constructPoolPayload(): EditOutagePayload {
    var controls = (this.editOutageForm.get('outages') as FormArray).controls;
    var objOutage = {
      name: "",
      status: "active",
      startdate: "",
      starttime: "",
      enddate: "",
      endtime: "",
    };

    controls?.forEach((control: any) => {
      objOutage =
      {
        name: control.controls['outageName'].value.trim(),
        status: "active",
        startdate: formatDateForPayload(control.controls['startDate'].value),
        starttime: control.controls['startTime'].value,
        enddate: formatDateForPayload(control.controls['endDate'].value),
        endtime: control.controls['endTime'].value,
      }
    });

    return objOutage;
  }
}

