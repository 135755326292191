<div class="restriction">
  <vue-image-tooltip
    imageSrc="/assets/icons/icon-{{isOccurrenceRestricted ? 'lock-dark' : 'unlock-light'}}.svg"
    content="{{ (isOccurrenceRestricted ? 'tooltip.message.occurrence.restricted' : 'tooltip.message.occurrence.unrestricted') | translate | async }}"></vue-image-tooltip>
  <span class="separator">/</span>
  <vue-image-tooltip
    imageSrc="/assets/icons/icon-{{isPoolRestricted ? 'lock-dark' : 'unlock-light'}}.svg"
    content="{{ (isPoolRestricted ? 'tooltip.message.pool.restricted' : 'tooltip.message.pool.unrestricted') | translate | async }}"
  ></vue-image-tooltip>
  <vue-image-tooltip *ngIf="isOccurrenceOverride && !occurrenceDateHasPassed" imageSrc="/assets/icons/icon-exclamation.svg"
        content="{{ 'tooltip.message.override' | translate | async }}"></vue-image-tooltip>
</div>
