<form id="form" [formGroup]="poolReleaseForm" autocomplete="off">
  <vue-slide-toggle
    [checked]="!!poolReleaseForm.controls.type.value"
    (change)="toggleAutomaticRelease($event)"
  >
    {{ "label.editPool.automaticallyReleasePool" | translate | async }}
  </vue-slide-toggle>
  <div
    *ngIf="poolReleaseForm.controls.type.value"
    class="release-type"
  >
    <vue-radio-group
      [items]="radioItems"
      [value]="getSelectedType()"
      [getItemDisplay]="getRadioDisplay"
      (change)="setAutoReleaseType($event)"
    >
    </vue-radio-group>
  </div>
  <ng-template #timeRef>
    <div class="time-release">
      <vue-numeric-input
        formControlName="time"
      ></vue-numeric-input>
      <vue-select
        formControlName="interval"
        [options]="autoReleaseIntervalOptions"
        [getOptionDisplay]="releaseIntervalDisplay.bind(this)"
      ></vue-select>
      <span>{{ "label.editPool.beforeOccurrence" | translate | async }}</span>
      <vue-select
        formControlName="before"
        [options]="autoReleaseStartOptions"
        [getOptionDisplay]="releaseStartDisplay.bind(this)"
      ></vue-select>
    </div>
  </ng-template>
  <ng-template #dateRef>
    <div class="date-release">
      <vue-datepicker
        placeholder="{{ 'placeholder.datepicker' | translate | async }}"
        formControlName="date"
        [minDateIsToday]="true"
        [maxDate]="poolEndDate ?? null"
        [errorMatcher]="datePickerErrorMatcher"
        [errorMessage]="poolReleaseForm.controls.date.errors | datepickerError | translate | async"
      ></vue-datepicker>
    </div>
  </ng-template>
</form>
