<vue-table-container [loading]="tableLoading">
  <table mat-table [dataSource]="poolConsumptionData">

    <caption class="visually-hidden">
      {{ 'table.caption.allPoolConsumption' | translate | async }}
    </caption>

    <ng-container matColumnDef="alertName">
      <th mat-header-cell *matHeaderCellDef [tableHeaderAriaSort]="{sortDirection, isSorted: sortBy === 'alertname' }">
        <app-sortable-table-header [tableHasData]="!!poolConsumptionData.length" (sortColumn)="sort('alertname')">
          Alert Name
        </app-sortable-table-header>
      </th>
      <mat-cell *matCellDef="let poolconsumption">
        {{poolconsumption.alertName}}
        <vue-image-tooltip *ngIf="poolconsumption.status == 'inactive'" imageSrc="/assets/icons/icon-exclamation.svg"
          content="{{ 'tooltip.message.poolconsumption.inactive' | translate | async }}"></vue-image-tooltip>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="poolName">
      <th mat-header-cell *matHeaderCellDef [tableHeaderAriaSort]="{sortDirection, isSorted: sortBy === 'poolname' }">
        <app-sortable-table-header [tableHasData]="!!poolConsumptionData.length" (sortColumn)="sort('poolname')">
          Pool Name
        </app-sortable-table-header>
      </th>
      <mat-cell *matCellDef="let poolconsumption" [matTooltip]="poolconsumption.poolName">
        <span class="ellipsis">
          {{poolconsumption.poolName}}
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="queue">
      <th mat-header-cell *matHeaderCellDef>
        Queue
      </th>
      <mat-cell *matCellDef="let poolconsumption" [matTooltip]="getQueueDisplay(poolconsumption)">
        <span class="ellipsis">
          {{ getQueueDisplay(poolconsumption) }}
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="createdBy">
      <th mat-header-cell *matHeaderCellDef [tableHeaderAriaSort]="{sortDirection, isSorted: sortBy === 'createdby' }">
        <app-sortable-table-header [tableHasData]="!!poolConsumptionData.length" (sortColumn)="sort('createdby')">
          Created By
        </app-sortable-table-header>
      </th>
      <mat-cell *matCellDef="let poolconsumption">
        <div>
          {{poolconsumption.createdBy}}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="createdOn">
      <th mat-header-cell *matHeaderCellDef [tableHeaderAriaSort]="{sortDirection, isSorted: sortBy === 'createdon' }">
        <app-sortable-table-header [tableHasData]="!!poolConsumptionData.length" (sortColumn)="sort('createdon')">
          Created On
        </app-sortable-table-header>
      </th>
      <mat-cell *matCellDef="let poolconsumption">
        <div>
          {{formatDate(poolconsumption.createdOn)}}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="modifiedBy">
      <th mat-header-cell *matHeaderCellDef>
        Modified By
      </th>
      <mat-cell *matCellDef="let poolconsumption">
        <div>
          {{poolconsumption.modifiedBy}}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="modifiedOn">
      <th mat-header-cell *matHeaderCellDef>
        Modified On
      </th>
      <mat-cell *matCellDef="let poolconsumption">
        <div>
          {{formatDate(poolconsumption.modifiedOn)}}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="menu">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'table.heading.actions' | translate | async }}
      </th>
      <mat-cell *matCellDef="let poolconsumption">
        <app-pool-consumption-menu [poolconsumption]="poolconsumption"
          (poolConsumptionUpdated)="poolConsumptionUpdated()"></app-pool-consumption-menu>
      </mat-cell>
    </ng-container>

    <!-- Populate table with data -->
    <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
    <mat-row *matRowDef="let poolconsumption; columns: tableColumns; let i = index"
      [ngClass]="{'inactive': poolconsumption.status == 'inactive'}"></mat-row>

    <!-- No data available -->
    <tr class="mat-row no-content" *matNoDataRow>
      <td class="mat-cell" colspan="9999">
        {{ 'message.poolconsumptions.noPoolConsumption' | translate | async }}
      </td>
    </tr>
  </table>

  <mat-paginator *ngIf="poolConsumptionData.length !== 0" (page)="paginatorChange($event)" previousPageIndex="0"
    [length]="totalItemCount" [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
    [showFirstLastButtons]="showFirstLastButtons"></mat-paginator>
</vue-table-container>