<button
  mat-button
  vueTableMenuOpen
  vueOverlayOpen
  class="menu-trigger"
  [matMenuTriggerFor]="templateMenu"
>
  <mat-icon>more_horiz</mat-icon>
</button>
<mat-menu
  #templateMenu="matMenu"
  class="template-menu"
  xPosition="before"
>
  <button *ngIf="template.status === 'active'"
    mat-menu-item
    (click)="editTemplate()"
  >
    {{ "button.edit" | translate | async }}
  </button>
  <button mat-menu-item (click)="duplicateTemplate()">
    {{ "button.duplicate" | translate | async }}
  </button>
  <button
    *ngIf="template.status === 'active'"
    mat-menu-item
    (click)="updateTemplateStatusConfirmation('archived')"
  >
    {{ "button.archive" | translate | async }}
  </button>
  <button
    *ngIf="template.status === 'archived'"
    mat-menu-item
    (click)="activeTemplate()"
  >
    {{ "button.activate" | translate | async }}
  </button>
</mat-menu>
<vue-toast icon="success">
  <span toast-body>{{toastMessage}}</span>
</vue-toast>
