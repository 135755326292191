<vue-page-header
  appName="{{ 'title.applicationName' | translate | async }}"
  pageTitle="{{ 'title.alertdetils' | translate | async }}"
  navSection="{{ 'title.appointmentManagement' | translate | async }}"
  [enableBack]="enableBack"
>
</vue-page-header>

<app-page-body>
  <vue-flat-button [ngClass]="this.isCalendarDetails.key.status == 1 ? 'reslolveButtonenable' : 'reslolveButtondisable' " style="position: absolute;
right: 51px;
top: 55px;
z-index: 1;" (click)="resolvedByid()" >{{ 'title.resolve' | translate | async }}</vue-flat-button>
  <vue-section [loading]="pageLoading">
    <app-app-alert-resolve-chart-monthly (setIntialdata)="getselectedValue($event)"
      [metricItems]="metricItems"
      [alertSpecificData]="alertSpecificData"
      [selectedMonth]="selectedMonth"
      [getCalendarDetails]="isCalendarDetails"
    ></app-app-alert-resolve-chart-monthly>
  
  </vue-section>
</app-page-body>