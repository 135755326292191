/* istanbul ignore file */
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

/**
 * Retrieve actual style values for an element using getComputedStyle
 */
@Injectable({
  providedIn: 'root'
})
export class GetComputedStyleService {
  public constructor(@Inject(DOCUMENT) private document: Document) {}

  /**
   * Use the browser to fetch computed styles for an element
   *
   * @param elem the element
   * @returns styles the dictionary of styles
   */
  public getComputedStyle(elem: Element): CSSStyleDeclaration | null {
    if (!this.document.defaultView || !elem) {
      return null;
    }
    return this.document.defaultView.getComputedStyle(elem);
  }
}
