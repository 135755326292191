/* eslint-disable @typescript-eslint/member-ordering */
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { PoolCapacityDetail } from 'api/types';
import { calculateUtilizationPercentage } from 'components/common/pools/utils/calculate-utilization-percentage';
import { TranslatePipe } from 'pipes/translate.pipe';
import { OccurrenceActionsStatusService } from 'services/status/occurrence-actions-status.service';
import { PoolCapacityOccurrence } from 'types/Occurrence';
import { byNumber, byString } from 'utils/sort-utils';
import { ClientSidePaginatedTable } from '../utils/client-side-paginated-table.class';
import { utc } from 'moment';
import { EXCHANGE_FORMAT } from 'constants/date-formats';

const tableColumns = [ 'pool', 'time', 'restriction', 'capacity', 'availability', 'utilization', 'menu' ] as const;
const sortableColumns = [ 'pool', 'time', 'utilization' ] as const;
type SortBy = typeof sortableColumns[number];

/**
 *  Pool occurrence table
 */
@Component({
  selector: 'app-occurrence-table',
  templateUrl: './occurrence-table.component.html',
  styleUrls: [ './occurrence-table.component.scss' ],
})
export class OccurrenceTableComponent
  extends ClientSidePaginatedTable<PoolCapacityOccurrence, SortBy> implements OnChanges {
  @Input() public pools: PoolCapacityDetail[] = [];

  /**
   * Pool Date
   * Format: YYYY-MM-DD
   */
  @Input() public poolDate = '';

  /*
   * Table attributes
   */
  public tableColumns = tableColumns;

  /**
   * Name of the pool occurrence that has been updated.
   * Used for `togglingBackgroundFade` when table data is generated
   */
  private updatedPoolName: string | null = null;

  public constructor(
    private translatePipe: TranslatePipe,
    private occurrenceActionsStatusService: OccurrenceActionsStatusService
  ) {
    super('pool');
  }

  /**
   * Based on the column to be sorted, return the proper sort method.
   */
  private static getSortMethod(column: SortBy): (a: PoolCapacityOccurrence, b: PoolCapacityOccurrence) => number {
    switch (column) {
      case 'time':
        return byString((o: PoolCapacityOccurrence) => o.startTime);
      case 'utilization':
        return byNumber((o: PoolCapacityOccurrence) => o.utilization);
      default:
        return byString((o: PoolCapacityOccurrence) => o.name);
    }
  }

  /**
   * Occurrence updated handler
   * Sets `updatedPoolName` to highlight the row on next render
   */
  public occurrenceUpdated(name: string): void {
    this.updatedPoolName = name;
  }

  /**
   * Regenerate table data based on new pools value
   *
   * @param changes SimpleChanges
   */
  public ngOnChanges(changes: SimpleChanges): void {
    /* istanbul ignore else */
    if ('pools' in changes) {
      // Reset the page index whenever data changes
      this.generateTableData(changes.pools.currentValue);

      // Reset pagination if table data no longer fits the current parameters
      if (this.tableData.length <= (this.pageIndex * this.pageSize)) {
        this.resetPagination();
      }

      // Clear any previous errors
      this.occurrenceActionsStatusService.reset();
    }
  }

  /**
   * Call correct sort function based on column
   *
   * @param column to sort on
   * @param sortDirection the direction to sort
   */
  public sortByColumn(column: SortBy, sortDirection: SortDirection): void {
    const updatedData = this.tableData.sort(
      OccurrenceTableComponent.getSortMethod(column)
    );

    if (sortDirection === 'desc') {
      updatedData.reverse();
    }

    this.tableData = updatedData;

    // Always reset the page index when sorting
    this.resetPagination();
  }

  // Calculates pool utilization and sorts based on pool name
  private generateTableData(newPools: PoolCapacityDetail[]): void {
    const updatedTableData = newPools.map((pool) => {
      return {
        ...pool,
        utilization: pool.isReleased ? 0 : calculateUtilizationPercentage(pool.registrations, pool.capacity),
        toggleBackgroundFade: Boolean(this.updatedPoolName && pool.name === this.updatedPoolName)
      };
    }).sort(
      OccurrenceTableComponent.getSortMethod(this.sortBy)
    );

    if (this.sortDirection === 'desc') {
      updatedTableData.reverse();
    }

    this.updatedPoolName = null;

    this.tableData = updatedTableData;
  }

  public occurrenceDateHasPassed() {
    return utc(this.poolDate, EXCHANGE_FORMAT).isBefore(utc(), 'day');
  }
}
