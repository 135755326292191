<vue-flat-button
  *ngIf="showOpenButton"
  (clicked)="open()"
>
  {{ "label.poolsOverview.editPool" | translate | async }}
</vue-flat-button>
<vue-drawer #drawer [contentRef]="editPool" [drawerConfig]="drawerConfig" (drawerClosed)="resetDrawer()">
  <ng-template #editPool>
    <app-drawer-header
      (close)="closeDrawer()"
      [disableClose]="status === 'loading'"
      [progress]="{currentStep: step.value === 'edit-form' ? 1 : 2, totalSteps: 2}"
    >
      {{ "label.editPool.drawer.title" | translate | async }}
    </app-drawer-header>
    <div
      matDialogContent
      class="mat-typography"
      [class.loading]="status === 'loading'"
    >
      <app-error-message
        *ngIf="status === 'error'"
        [heading]="displayableServerError?.title"
        [message]="displayableServerError?.message"
      ></app-error-message>
      <app-edit-pool-form
        *ngIf="step.value === 'edit-form'"
        [initialpoolDetails] = "initialpool"
        [pool]="pool"
        (formValidityChange)="updateFormValidity($event)"
        (formSubmitted)="updatePoolDetails($event)"
      ></app-edit-pool-form>
      <ng-container *ngIf="step.value === 'edit-occurrence'">
       <app-pool-drawer-occurrence-table
         [poolDetails]="pool"
         [poolId]="pool.id"
         [initialpoolDetails] = "initialpool"
         [registrations]="registrations"
         [pageRef]="pageRef"
         (unsavedChangesEvent)="setUnsavedChangesFlag()"
        ></app-pool-drawer-occurrence-table>
        <div class="pool-options">
          <vue-basic-button (clicked)="openReleaseDialog()">
            {{ "label.editPool.releaseAllOccurrences" | translate | async }}
          </vue-basic-button>
          <vue-basic-button (clicked)="openArchiveDialog()" color="warn">
           {{ "title.archivePool" | translate | async }}
          </vue-basic-button>
        </div>
      </ng-container>
    </div>
    <div matDialogActions>
      <ng-container *ngIf="step.value === 'edit-form'">
        <vue-basic-button (clicked)="closeDrawer()" [disabled]="status === 'loading'">
          {{ "button.cancel" | translate | async }}
        </vue-basic-button>
        <vue-flat-button [disabled]="!formIsValid" type="submit" form="form" [loading]="status === 'loading'">
          {{ "button.next" | translate | async }}
        </vue-flat-button>
      </ng-container>
      <ng-container *ngIf="step.value === 'edit-occurrence'">
        <vue-basic-button
          (clicked)="showForm()"
          [disabled]="status === 'loading'"
        >
          {{ "button.back" | translate | async }}
        </vue-basic-button>
        <vue-flat-button
          (clicked)="savePool()"
          [loading]="status === 'loading'"
        >
          {{ "button.save" | translate | async }}
        </vue-flat-button>
      </ng-container>
    </div>
  </ng-template>
</vue-drawer>
