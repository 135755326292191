import { animate, AnimationTriggerMetadata, style, transition, trigger } from '@angular/animations';

/**
 * Returns common fadeInOut animation definition
 */
export function fadeInOut(): AnimationTriggerMetadata {
  return trigger('fadeInOut', [
    transition(':enter', [
      style({ opacity: 0 }),

      // Waiting a little before fading in prevents some layout jank
      animate('0.1s 0.2s', style({ opacity: 1 }))
    ]),
    transition(':leave', [
      animate('0.2s', style({ opacity: 0 }))
    ])
  ]);
}
