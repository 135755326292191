import { Injectable } from '@angular/core';
import { utc } from 'moment';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ClientInfo, Exam, GetPoolsParameters, PoolStatus, Queue } from 'api/types';
import { ChipContent } from 'components/common/dismissible-chip/dismissible-chip.component';
import { DISPLAY_DAY_FORMAT, EXCHANGE_FORMAT } from 'constants/date-formats';
import { TranslatePipe, TranslationKey } from 'pipes/translate.pipe';
import { AddAlertPoolDataService } from './add-alert-pool-data.service';

export const DEFAULT_VALUES: GetPoolsParameters = {
  limit: 25,
  offset: 0,
  sortBy: 'name',
  direction: 'asc',
  status: ['open', 'scheduled',]
};

type GetPoolsKey = keyof GetPoolsParameters;
export interface GetPoolsFilterChipType {
  filterKey: GetPoolsKey;
  value: GetPoolsParameters[GetPoolsKey];
}

export type GetPoolFilterChip = ChipContent<GetPoolsFilterChipType>;

@Injectable({
  providedIn: 'root'
})
export class AllAlertFiltersService {
  
   /**
   * GetPools params that are passed to the service
   * Source of truth for filters on screen
   */
   private readonly params = new BehaviorSubject<GetPoolsParameters>(DEFAULT_VALUES);

   /**
    * Array of chip objects that are used to display DismissibleChips
    */
   private readonly selectedFilters = new BehaviorSubject<GetPoolFilterChip[]>([]);
 
   /**
    * Array of the selected clients
    * In sync with the clientIds in params
    */
   private readonly selectedClients = new BehaviorSubject<ClientInfo[]>([]);
 
   /**
    * Array of the selected exams
    * In sync with the examIds in params
    */
   private readonly selectedExams = new BehaviorSubject<Exam[]>([]);
 
   /**
    * Array of the selected queues
    * In sync with the queueIds in params
    */
   private readonly selectedQueues = new BehaviorSubject<Queue[]>([]);
 
   /**
    * Open after date
    * In sync with the after date in params
    */
   private readonly selectedOpenAfterDate = new BehaviorSubject<string | null>(null);
 
   /**
    * Open before date
    * In sync with the before date in params
    */
   private readonly selectedOpenBeforeDate = new BehaviorSubject<string | null>(null);
 
   // Public observables for consumers
   public params$ = this.params.asObservable();
   public selectedFilters$ = this.selectedFilters.asObservable();
   public selectedClients$ = this.selectedClients.asObservable();
   public selectedExams$ = this.selectedExams.asObservable();
   public selectedQueues$ = this.selectedQueues.asObservable();
   public selectedOpenAfterDate$ = this.selectedOpenAfterDate.asObservable();
   public selectedOpenBeforeDate$ = this.selectedOpenBeforeDate.asObservable();
 
   /**
    * Keys for the translated chip labels
    */
   private translationKeys: TranslationKey[] = [
     'label.status',
     'label.queue',
     'label.exam',
     'label.client',
     'label.openAfter',
     'label.openBefore',
     'label.archived',
     'label.open',
     'label.scheduled',
     'label.completed',
   ];
 
   /**
    * Translated chip labels
    */
   private translations: {[key: string]: string} = {}
 
   /**
    * @param translatePipe TranslatePipe
    */
   public constructor(
    private getDialogueClosedData: AddAlertPoolDataService,
     translatePipe: TranslatePipe,
   ) {
     translatePipe.loadTranslations(this.translationKeys)
       .pipe(take(1))
       .subscribe((translations) => {
         this.translations = translations;
 
         // Construct initial filters
         this.constructSelectedFilters(this.getNextParamsBase());
       });
 
     // Prevent handler from being called in the component's context
     this.updateParams = this.updateParams.bind(this);
   }
 
   /**
    * Update filter state
    *
    * @param changes updated filter params
    */
   public updateParams(changes: Partial<GetPoolsParameters>): void {
     const nextParams = { ...this.getNextParamsBase(), ...changes };
     this.params.next(nextParams);
 
     // Only update selected filters if one of their value has changed
     if (this.shouldUpdateSelectedFilters(changes)) {
       this.constructSelectedFilters(nextParams);
     }
   }
 
   /**
    * Add clients to params and selectedFilters
    *
    * @param clients selected clients
    */
   public addClientsToFilter(clients: ClientInfo[]): void {
     this.selectedClients.next(clients);
     const clientIds = clients.map((c) => c.id);
     const nextParams = { ...this.getNextParamsBase(), clientIds };
     this.params.next(nextParams);
    
     
 
     this.constructSelectedFilters(nextParams);
   }
 
   public updateMoreFilters(
   
     exams: Exam[] = [],
     queues: Queue[] = [],
     afterDate = '',
     beforeDate = '',
   ): void {

    this.getDialogueClosedData.getFilterSubchildChanges(true)
     this.selectedExams.next(exams);
     this.selectedQueues.next(queues);
     this.selectedOpenAfterDate.next(afterDate || null);
     this.selectedOpenBeforeDate.next(beforeDate || null);
 
     const examIds = exams.map((e) => e.id);
     const queueIds = queues.map((q) => q.id);
 
     const nextParams = {
       ...this.getNextParamsBase(),
       examIds,
       queueIds,
       after: afterDate,
       before: beforeDate
     };
 
     this.params.next(nextParams);
     this.constructSelectedFilters(nextParams);
   }
 
   /**
    * Removes status from filters
    *
    * @param status status to remove
    */
   public removeStatus(status: PoolStatus): void {
     this.updateParams({
       status: (this.params.getValue().status || []).filter((s) => s !== status)
     });
   }
 
   /**
    * Removes clientIds from filters
    *
    * @param clientId id of client to remove
    */
   public removeClient(clientId: string): void {
     this.updateParams({
       clientIds: (this.params.getValue().clientIds || []).filter((c) => c !== clientId)
     });
     this.selectedClients.next([ ...this.selectedClients.getValue().filter((c) => c.id !== clientId) ]);
   }
 
   /**
    * Removes examIds from filters
    *
    * @param examId id of exam to remove
    */
   public removeExam(examId: string): void {
     this.updateParams({
       examIds: (this.params.getValue().examIds || []).filter((e) => e !== examId)
     });
     this.selectedExams.next([ ...this.selectedExams.getValue().filter((e) => e.id !== examId) ]);
   }
 
   /**
    * Removes queueIds from filters
    *
    * @param queueId id of queue to remove
    */
   public removeQueue(queueId: string): void {
     this.updateParams({
       queueIds: (this.params.getValue().queueIds || []).filter((e) => e !== queueId)
     });
     this.selectedQueues.next([ ...this.selectedQueues.getValue().filter((e) => e.id !== queueId) ]);
   }
 
   /**
    * Removes date from filters
    *
    * @param type remove after or before date
    */
   public removeDate(type: 'after' |'before'): void {
     const nextParams = { ...this.params.getValue(), [ type ]: '' };
     this.updateParams(nextParams);
 
     if (type === 'after') {
       this.selectedOpenAfterDate.next(null);
     } else {
       this.selectedOpenBeforeDate.next(null);
     }
   }
 
   /**
    * Returns the base object to be used when updating params
    * Resets offset to zero so the first page of results is shown
    *
    * @returns the base object when updating params
    */
   private getNextParamsBase(): GetPoolsParameters {
     return {
       ...this.params.getValue(),
      
       offset: 0,
     };
     
   }
 
   /**
    * Determines if the selected filters should be updated based on keys of the changes object.
    * Avoids re-rendering selectedFilters when they haven't changed
    *
    * @param changes changes being applied to filters
    * @returns true if selectedFilters should be re-constructed
    */
   private shouldUpdateSelectedFilters(changes: Partial<GetPoolsParameters>): boolean {
     return Boolean(Object.keys(changes).filter((key) => {
       return !this.ignoredKeysForSelectedFilters(key);
     }).length);
   }
 
   /**
    * Keys of GetPoolsParameters that are ignored in shown filters
    *
    * @param key key to check
    * @returns true if key can be ignored in selected filters
    */
   private ignoredKeysForSelectedFilters(key: string): boolean {
     return [ 'limit', 'offset', 'sortBy', 'direction', 'searchTerm' ].includes(key);
   }
 
   /**
    * Constructs object array to be passed to the FilterByComponent
    * Updated every time a filter is updated
    *
    * @param params updated params
    */
   private constructSelectedFilters(params: GetPoolsParameters): void {

     const selectedFilters: GetPoolFilterChip[] = [];
     (Object.keys(params) as (keyof GetPoolsParameters)[])
       .filter((key) => {
         // filter out params that shouldn't be shown as filtered
         return !this.ignoredKeysForSelectedFilters(key);
       })
       .forEach((key) => {
         // Create chip objects for respective key
         switch (key) {
           case 'status':
             selectedFilters.push(...this.getStatusChipObjects(params[ key ]));
             break;
           case 'clientIds':
             selectedFilters.push(...this.getClientChipObjects(params[ key ]));
             break;
           case 'examIds':
             selectedFilters.push(...this.getExamChipObjects(params[ key ]));
             break;
           case 'queueIds':
             selectedFilters.push(...this.getQueueChipObjects(params[ key ]));
             break;
           case 'after':
           case 'before':
             if (params[ key ]) {
               selectedFilters.push(this.getDateChipObject(key, params[ key ]));
             }
             break;
         }
       });
       
       
     this.selectedFilters.next(selectedFilters);

   }
 
   /**
    * Creates array of chip objects for status filters
    *
    * @param statuses selected statuses
    * @returns Array of objects for status for chip components
    */
   private getStatusChipObjects(statuses?: PoolStatus[]): GetPoolFilterChip[] {
     return (statuses || []).map((status) => {
       return {
        
         content: this.translations[ `label.${status}` ],
         contentLabel: this.translations[ 'label.status' ],
         item: {
           filterKey: 'status',
           value: status
         }
       };
     });
   }
 
   /**
    * Creates array of chip objects for client filters
    *
    * @param clientIds selected client ids
    * @returns Array of client objects for chip components
    */
   private getClientChipObjects(clientIds?: GetPoolsParameters['clientIds']): GetPoolFilterChip[] {
     return (clientIds || []).map((clientId) => {
       const client = this.selectedClients.getValue().find((c) => c.id === clientId);
       return {
         content: client?.name || '',
         contentLabel: this.translations[ 'label.client' ],
         item: {
           filterKey: 'clientIds',
           value: clientId
         }
       };
     });
   }
 
   /**
    * Creates array of chip objects for exam filters
    *
    * @param examIds selected exam ids
    * @returns Array of exam objects for chip components
    */
   private getExamChipObjects(examIds?: GetPoolsParameters['examIds']): GetPoolFilterChip[] {
     return (examIds || []).map((examId) => {
       const exam = this.selectedExams.getValue().find((e) => e.id === examId);
       return {
         content: exam?.name || '',
         contentLabel: this.translations[ 'label.exam' ],
         item: {
           filterKey: 'examIds',
           value: examId
         }
       };
     });
   }
 
   /**
    * Creates array of chip objects for queue filters
    *
    * @param queueIds selected queue ids
    * @returns Array of queue objects for chip components
    */
   private getQueueChipObjects(queueIds?: GetPoolsParameters['queueIds']): GetPoolFilterChip[] {
     return (queueIds || []).map((queueId) => {
       const queue = this.selectedQueues.getValue().find((q) => q.id === queueId);
       return {
         content: queue?.name || '',
         contentLabel: this.translations[ 'label.queue' ],
         item: {
           filterKey: 'queueIds',
           value: queue?.id
         }
       };
     });
   }
 
   /**
    * Creates chip objects for after or before date filter
    *
    * @param key 'after' | 'before'
    * @param date after or before date in YYYY-MM-DD format
    * @returns Array of date objects for chip components
    */
   private getDateChipObject(
     key: 'after' | 'before',
     date?: GetPoolsParameters['after'] | GetPoolsParameters['before']
   ): GetPoolFilterChip {
     return {
       content: date ? utc(date, EXCHANGE_FORMAT).format(DISPLAY_DAY_FORMAT) : '',
       contentLabel: key === 'after' ? this.translations[ 'label.openAfter' ] : this.translations[ 'label.openBefore' ],
       item: {
         filterKey: key,
         value: date
       }
     };
   }

   
}
