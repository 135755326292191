import { ActiveElement, Chart, ChartEvent, ChartOptions } from 'chart.js';
import palette from './chart-palette';

/**
 * Returns base charting options
 * Any values can be changed
 * Usually xAxes configuration objects are needed
 *
 * @param yAxisMax Max number shown on the Y axis
 * @param yAxisStepSize Increment of each Y axis step
 * @returns chart options
 */
export function baseChartOptions(yAxisMax: number, yAxisStepSize: number): ChartOptions {
  return {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false },
    },
    scales: {
      y: {
        min: 0,
        max: yAxisMax,
        ticks: {
          display: false, // Don't display Y-Axis labels
          stepSize: yAxisStepSize,
        },
        grid: {
          drawTicks: false, // Hide stub lines that extend past axis line
          color: palette.grayF,
          borderColor: palette.grayF,
        }
      }
    },
    elements: { line: { tension: 0, fill: false } },
    animation: false,
    onHover: (event: ChartEvent, elements: ActiveElement[], chart: Chart): void => {
      chart.canvas.style.cursor = elements.length ? 'pointer' : 'default';
    }
  };
}
