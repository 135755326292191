<div class="buttons">
  <button
    class="arrow"
    (click)="previousAction($event)"
    [disabled]="activeButton === 0"
    attr.aria-label="{{ 'label.aria.previousAction' | translate | async }}"
  >
    <vue-arrow direction="backward"></vue-arrow>
  </button>
  <ng-container *ngFor="let popoverAction of popoverActions; let i = index">
    <button
      *ngIf="activeButton === i"
      aria-live="polite"
      (click)="popoverAction.action()"
    >
      {{ popoverAction.translationKey | translate | async }}
    </button>
  </ng-container>
  <button
    class="arrow"
    (click)="nextAction($event)"
    [disabled]="activeButton === 1"
    attr.aria-label="{{ 'label.aria.nextAction' | translate | async }}"
  >
    <vue-arrow direction="forward"></vue-arrow>
  </button>
</div>
