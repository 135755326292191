import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Output,
} from '@angular/core';

/**
 *  Directive that lets an element accept files via drag-and-drop
 */
@Directive({
  selector: '[dragAndDrop]',
})
export class DragAndDropDirective {
  @HostBinding('class.file-over') public fileOver = false;
  @Output() public fileDropped = new EventEmitter<File>();

  /**
   * Dragover listener
   *
   * @param event the drag event
   */
  @HostListener('dragover', [ '$event' ]) public onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = true;
  }

  /**
   * Dragleave listener
   *
   * @param event the drag event
   */
  @HostListener('dragleave', [ '$event' ]) public onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = false;
  }

  /**
   * Drop listener
   *
   * @param event the drag event
   */
  @HostListener('drop', [ '$event' ]) public ondrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = false;
    const files = event.dataTransfer?.files;
    if (!files || files.length < 1) {
      return;
    }
    this.fileDropped.emit(files[ 0 ]);
  }
}
