import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { timer } from 'rxjs';
import { InactivityDialogComponent, InactivityDialogComponentData } from 'components/dialogs/inactivity-dialog/inactivity-dialog.component';
import { VUE_DIALOG_CONFIG } from 'vue/utilities/vue-dialog-config';
import { ActivityDetectorService } from './activity-detector.service';

/**
 *  Service that logs user out after too much inactivity. Prompts with warning before logging out.
 */
@Injectable({
  providedIn: 'root'
})
export class AutoLogoutService {
  /**
   * Duration of inactivity before displaying warning dialog, in milliseconds
   * Set at 14 minutes, InactivityDialogComponent is displayed for 1 minute to hit the 15 minute idle timeout.
   */
  public static WARNING_THRESHOLD_MILLIS = 14 * 60 * 1000

  /**
   * How frequently to check the user activity
   */
  public static CHECK_INTERVAL_MILLIS = 1000

  /**
   * Flag to indicate whether the warning dialog is open
   */
  private warningDialogDisplayed = false;

  public constructor(
    private dialog: MatDialog,
    private activityDetectorService: ActivityDetectorService,
  ) {}

  /**
   * Start checking for user activity.
   *
   * If activity is not detected for WARNING_THRESHOLD_MILLIS, display a warning dialog.
   */
  public init(): void {
    this.activityDetectorService.init();

    // Timer runs for the life of the app
    timer(AutoLogoutService.CHECK_INTERVAL_MILLIS, AutoLogoutService.CHECK_INTERVAL_MILLIS)
      .subscribe(() => this.checkActivity());
  }

  private checkActivity(): void {
    const lastActivity = this.activityDetectorService.lastActivityTimestamp();
    const now = Date.now();
    const inactivityDuration = now - lastActivity;
    const pastWarningThreshold = inactivityDuration > AutoLogoutService.WARNING_THRESHOLD_MILLIS;

    if (pastWarningThreshold) {
      this.displayWarningDialog();
    }
  }

  private displayWarningDialog(): void {
    if (this.warningDialogDisplayed) {
      return;
    }
    this.warningDialogDisplayed = true;

    const dialogRef = this.dialog.open<InactivityDialogComponent, InactivityDialogComponentData>(
      InactivityDialogComponent, {
        ...VUE_DIALOG_CONFIG,
        data: {
          continueClicked: () => {
            // Reset the dialog state
            dialogRef.close();
            this.warningDialogDisplayed = false;

            // Note: we do not need to actively refresh any tokens - that is handled by the API request interceptor
          }
        }
      });
  }
}
