/* eslint-disable @typescript-eslint/no-explicit-any */
import { AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * @param control the form control to validate
 * @returns errors any validation errors found
 */
export function poolScheduleValidator(control: AbstractControl): ValidationErrors | null {
  const errorObject: {[key: string]: any} = {};

  if (!control.value.items || control.value.items.length === 0) {
    errorObject[ 'missing-hourly-items' ] = control.value;
  }

  if (Array.isArray(control.value.items)) {
    const allValid = control.value.items.reduce((accum: boolean, item: any) => {
      return Boolean(accum && item.startTime && item.endTime && (typeof item.capacity === 'number'));
    }, true);

    if (!allValid) {
      errorObject[ 'invalid-hourly-entry' ] = control.value;
    }
  }

  // return null for success
  return Object.keys(errorObject).length ? errorObject : null;
}
