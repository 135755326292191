import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { PageStatusService } from 'services/status/page-status.service';
import { LOADING, RequestStatus } from 'types/RequestStatus';
import { fadeInOut } from 'utils/fade-in-out.animation';

/**
 * A simple, presentational component to define the body area of a page
 *
 * Also responsible for hiding content during page load or error states
 */
@Component({
  selector: 'app-page-body',
  templateUrl: './page-body.component.html',
  styleUrls: [ './page-body.component.scss' ],
  animations: [ fadeInOut() ],
})
export class PageBodyComponent implements OnDestroy {
  /**
   * Internal reference to the page status
   */
  public status: RequestStatus = 'initial';

  /**
   * Flag to track whether the page has already loaded
   */
  private loadingCount = 0;

  /**
   * Track destroyed for cleanup
   */
  private destroyed$ = new Subject();

  public constructor(private pageStatusService: PageStatusService) {
    pageStatusService.status$
      .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
      .subscribe((status) => {
        /*
         * Only display loading effect on initial page load
         * Subsequent loading treatments should be handled by lower-level components
         */
        if (status === LOADING) {
          this.loadingCount++;

          if (this.loadingCount === 1) {
            this.status = LOADING;
          }
        } else {
          this.status = status;
        }
      });
  }

  /**
   * Call the retry function registered with the service
   */
  public retry(): void {
    this.pageStatusService.retry();
  }

  /**
   * Resets the loading count to zero so consuming components can show full page loader when needed.
   */
  public resetLoadingCount(): void {
    this.loadingCount = 0;
  }

  /**
   * Clean up on destroy
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
