import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Queue } from 'api/types';
import { AllPoolDataService } from 'services/all-pool-data.service';
import { formatQueueDisplay } from 'utils/format-queue';

/**
 *  Filter component for selecting one or more queues
 */
@Component({
  selector: 'app-queue-filter',
  templateUrl: './queue-filter.component.html',
  styleUrls: [ './queue-filter.component.scss' ]
})
export class QueueFilterComponent implements OnInit, OnDestroy {
  /**
   * Array of queues that are already being filtered on
   */
  @Input() public alreadySelectedQueues: Queue[] = [];

  /**
   * Emit event when queues are selected or deselected
   */
  @Output() public updateSelectedQueues = new EventEmitter<Queue[]>();

  /**
   * Queues to display
   */
  public queues: Queue[] = [];

  /**
   * Display Queue Code (Queue Name)
   */
  public formatQueueDisplay = formatQueueDisplay;

  private destroyed$ = new Subject();

  public constructor(private allPoolsData: AllPoolDataService) { }

  public ngOnInit(): void {
    this.allPoolsData.queues$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((queues) => {
        this.queues = [ ...queues ];
      });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Add or remove queue from working array of queues
   *
   * @param event checkbox event
   * @param queueId queueId in context
   */
  public updateSelected(event: MatCheckboxChange, queueId: string): void {
    if (!event.checked) {
      this.updateSelectedQueues.emit(this.alreadySelectedQueues.filter((q) => q.id !== queueId));
      return;
    }
    const queue = this.queues.find((q) => q.id === queueId);
    if (queue) {
      this.updateSelectedQueues.emit([
        ...this.alreadySelectedQueues,
        queue,
      ]);
    }
  }

  /**
   * Determines if a queue is selected as a filter
   *
   * @param queueId Queue Id to check
   * @returns true if queue is selected as a filter
   */
  public isQueueSelected(queueId: string): boolean {
    return Boolean(this.alreadySelectedQueues.find((q) => q.id === queueId));
  }
}
