import { Component, EventEmitter, Input, Output } from '@angular/core';
import { focusOnElement } from 'utils/focus-on-element';

/**
 *  Drawer header with standardized styling and a progress bar
 */
@Component({
  selector: 'app-drawer-header',
  templateUrl: './drawer-header.component.html',
  styleUrls: [ './drawer-header.component.scss' ]
})
export class DrawerHeaderComponent {
  /**
   * Progress of the steps within the drawer
   */
  @Input() public progress?: {currentStep: number, totalSteps: number};

  /**
   * Disables close button
   */
  @Input() public disableClose = false;

  /**
   * Event emitted when the close icon is clicked
   */
  @Output() public close = new EventEmitter<void>();

  /**
   * Returns the progress complete out of 100
   */
  public getProgress(): number {
    if (this.progress) {
      return Math.round((this.progress.currentStep / this.progress.totalSteps) * 100);
    }
    return 0;
  }

  /**
   * Focuses on the drawer title
   *
   * Used by consuming drawers to move the keyboard focus to the top of the drawer
   */
  public focusOnTitle(): void {
    focusOnElement('.drawer-title-container');
  }
}
