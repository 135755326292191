import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CreateCandidatesExportRequestBody, CreateCandidatesExportResponse, GetCandidatesExportParams, GetCandidatesExportResponse } from 'api/types';
import { CapacityMicroservice } from './microservices/capacity.microservice';

/**
 * API service for candidates
 */
@Injectable({
  providedIn: 'root'
})
export class CandidatesService {
  // Init with empty values; the filter component will populate them later
  private readonly status = new BehaviorSubject<GetCandidatesExportResponse>({ status: 'created' })

  // eslint-disable-next-line @typescript-eslint/member-ordering, no-invalid-this
  public status$ = this.status.asObservable()

  public constructor(private api: CapacityMicroservice) {
    // Prevent handler from being called in the component's context
    this.updateStatus = this.updateStatus.bind(this);
  }

  public updateStatus(newValue: GetCandidatesExportResponse): void {
    this.status.next(newValue);

    // Reset status back to created so it triggers a load when user starts different download
    if (newValue.status === 'success' || newValue.status === 'failure') {
      this.status.next({ status: 'created' });
    }
  }

  /**
   * Calls CreateCandidatesExport API
   *
   * @param payload the request body to send with the request
   * @returns CreateCandidatesExportResponse Observable
   */
  public createCandidatesExport(
    payload: CreateCandidatesExportRequestBody): Observable<CreateCandidatesExportResponse> {
    const path = 'candidates';
    return this.api.post<CreateCandidatesExportResponse, CreateCandidatesExportRequestBody>(path, payload);
  }

  /**
   * Calls GetCandidatesExport API
   *
   * @param params params for GetCandidatesExport call
   * @returns GetCandidatesExportResponse Observable
   */
  public getCandidatesExport(params: GetCandidatesExportParams): Observable<GetCandidatesExportResponse> {
    const path = `candidates/${params.requestId}`;

    return this.api.get<GetCandidatesExportResponse>(path);
  }
}
