<div class="filter-wrapper">
  <div class="search-wrapper">
    <vue-filter-search
      placeholder="{{ 'label.placeholder.searchTemplates' | translate | async }}"
      (searchValueChange)="search($event)"
      [inputValue]="filters.search.value || ''"
      [invalidRegex]="invalidRegex"
    ></vue-filter-search>
  </div>
  <vue-filter-select
    ariaLabel="{{ 'label.aria.templateStatus' | translate | async }}"
    [filter]="filters.status"
    [format]="displayStatus"
    (change)="statusChanged($event.value)"
  ></vue-filter-select>
</div>
