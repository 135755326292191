<ng-template #toastTemplate>
  <div class="vue-toast-container">
    <img *ngIf="icon === 'success'" src="assets/icons/green-check-mark.svg" alt="">
    <img *ngIf="icon === 'warn'" src="assets/icons/yellow-exclamation.svg" alt="">
    <div class="body">
      <h4 *ngIf="heading">{{heading}}</h4>
      <ng-content *ngIf="!message" select="[toast-body]"></ng-content>
      <span *ngIf="message">{{message}}</span>
    </div>
    <ng-content select="[toast-action]"></ng-content>
    <div *ngIf="includeDismiss" class="dismiss-wrapper">
      <button
        mat-icon-button
        [attr.aria-label]="'label.aria.dismissToast' | vueTranslate | async"
        (click)="close()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</ng-template>
