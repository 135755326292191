import { ChartDataset } from 'chart.js';
import { PoolCapacityMetric } from 'api/types';
import { barDataset } from 'components/charts/utils/bar-dataset';
import { lineDataset } from 'components/charts/utils/line-dataset';
import { TranslationLookup } from 'pipes/translate.pipe';
import { NumericKeys } from 'types/numeric-keys';
import palette from '../../utils/chart-palette';
import {
  POOLS_X_AXIS_ID
} from '../pools-chart.config';

/**
 * Select values for a given property from metric items
 *
 * @param metricItems the items to pluck values from
 * @param property the property to pluck
 * @returns values an array of the plucked values
 */
function pluckData(
  metricItems: PoolCapacityMetric[],
  property: NumericKeys<PoolCapacityMetric>
): number[] {
  return metricItems.map((item) => {
    return item[ property ];
  });
}

/**
 * Create Chart JS datasets for the given metricItems
 *
 * @param metricItems the raw items to base chart values on
 * @param showQueueCapacity whether to include the queue capacity dataset
 * @param translations the localization translation lookup
 * @returns array of the datasets
 */
export function formatDatasets(
  metricItems: PoolCapacityMetric[],
  showQueueCapacity: boolean,
  translations: TranslationLookup): ChartDataset[] {
  return [
    barDataset(
      translations[ 'label.poolAvailability' ],
      POOLS_X_AXIS_ID,
      palette.blue,
      pluckData(metricItems, 'availablePoolCapacity')
    ),
    barDataset(
      translations[ 'label.queueAvailability' ],
      POOLS_X_AXIS_ID,
      palette.grayE,
      pluckData(metricItems, 'availableQueueCapacity')
    ),
    ...(showQueueCapacity ? [ lineDataset(
      translations[ 'label.queueCapacity' ],
      palette.midNight,
      pluckData(metricItems, 'capacity')
    ) ] : [])
  ];
}
