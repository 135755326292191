import { utc } from 'moment';
import { GetMetricsParameters } from 'api/types';
import { DEFAULT_TIMESCALE_VALUES } from 'services/appointments-filters.service';
import { AppointmentFilters, Timescale } from 'types/AppointmentFilters';

/**
 * Convert filter states to getMetricParams
 *
 * @param filters the current appointment filters state
 * @returns params GetMetricsParams that represent the filter state
 */
export function filtersToParams(filters: AppointmentFilters): GetMetricsParameters {
  const { queue, startDate, interval } = filters;

  return {
    startDate: startDate.value || '',
    numberOfDays: getNumberOfDays(filters.timescale.value || null, filters.startDate.value || null),
    interval: interval.value ? interval.value : DEFAULT_TIMESCALE_VALUES.interval,
    queueId: queue.value?.id,
  };
}

/**
 * @param timescale the Timescale in question
 * @param startDate the start date - only relevant for monthly timescale
 * @returns numberOfDays the number of days to display
 */
export function getNumberOfDays(timescale: Timescale | null, startDate: string | null): number {
  if (!timescale || !startDate) {
    return 1;
  }

  switch (timescale) {
    case Timescale.weekly:
      return 7;
    case Timescale.monthly:
      return utc(startDate).daysInMonth();
    default:
      return 1;
  }
}

/**
 * @returns the timescale associated with the number of days
 * - 1 -> daily
 * - 7 -> weekly
 * - all other numbers -> monthly
 */
export function getTimescale(numberOfDays: number): Timescale {
  switch (numberOfDays) {
    case 1:
      return Timescale.daily;
    case 7:
      return Timescale.weekly;
    default:
      return Timescale.monthly;
  }
}
