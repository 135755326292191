import { Component, Input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

/**
 * Tooltip that is presented with an image
 */
@Component({
  selector: 'vue-image-tooltip',
  templateUrl: './vue-image-tooltip.component.html',
  styleUrls: [ './vue-image-tooltip.component.scss' ]
})
export class VueImageTooltipComponent {
  /**
   * Content for the tooltip
   */
  @Input() public content!: string;

  /**
   * Path to image for the tooltip
   */
  @Input() public imageSrc!: string;

  /**
   * Prioritized position of the tooltip
   */
  @Input() public position: MatTooltip['position'] = 'above';
}
