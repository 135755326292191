import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

/**
 * Simple wrapper for window.location functionality.
 */
@Injectable({
  providedIn: 'root'
})
export class WindowLocationService {
  public constructor(
    @Inject(DOCUMENT) private document: Document,
  ) {}

  /**
   * Set the window.location.href property, triggering navigation to a new page
   *
   * @param href the href to set
   */
  public setHref(href: string): void {
    this.document.location.href = href;
  }

  /**
   * Returns the document hostname
   */
  public getHostname(): string {
    return this.document.location.hostname;
  }
}
