import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { AddOutageState } from './add-outage-state/add-outage-state.service';
import { FormArray, FormGroup, UntypedFormGroup } from '@angular/forms';
import { DatePickerErrorMatcher } from 'utils/error-matchers/date-picker.error-matcher';
import { SuppressErrorMatcher } from 'utils/error-matchers/suppress.error-matcher';
import { AddUTCToTimePipe } from 'pipes/add-utc-to-time.pipe';
import { UTC_TIMES } from 'data/utc-times';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DisplayableServerError } from 'types/DisplayableServerError';
import { getDisplayableServerError } from 'utils/get-displayable-server-error';
import { RequestStatus } from 'types/RequestStatus';
import { OutagesService } from 'services/api/outages.service';
import { removeUTCTimesForOutage } from 'utils/time-utils';
import { UnsavedChangesDialogService } from 'services/unsaved-changes-dialog.service';

@Component({
  selector: 'add-outages-page',
  templateUrl: './add-outages-page.component.html',
  styleUrls: ['./add-outages-page.component.scss']
})
export class AddOutagesPageComponent implements OnInit, OnDestroy {

  /**
   * Track page loading state internally
   */
  public pageLoading = false;

  /**
   * Observable completes when component destroyed
   */
  private destroyed$ = new Subject();

  /**
  * Error matcher for datepicker components
  */
  public datePickerErrorMatcher = new DatePickerErrorMatcher();

  public suppressErrorState = new SuppressErrorMatcher();

  public utcStartTimes = [...UTC_TIMES];

  public utcEndTimes = [...UTC_TIMES];

  public formIsValid = false;

  private error?: DisplayableServerError | null;

  public status: RequestStatus = 'initial';

  public constructor(
    private addOutageState: AddOutageState,
    private addUTCToTimePipe: AddUTCToTimePipe,
    private router: Router,
    private outageService: OutagesService,
    private unsavedChangesDialogService: UnsavedChangesDialogService
  ) {

    this.addOutageState.addOutageForm.statusChanges
      .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
      .subscribe((status) => {
        this.formIsValid = status === 'VALID';
      });
  }

  /**
   * Fetch pool details and subscribe to updates
   */
  public ngOnInit(): void {
    this.addOutageState.resetState();
  }

  /**
   * Terminate subscriptions
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public validateOutage(): void {
  }

  /**
 * @returns addOutageState.addOutageForm
 */
  public get addOutageForm() {
    return this.addOutageState.addOutageForm;
  }

  /**
 * @param time time
 * @returns time with (UTC) added
 */
  public addUTCToTime(time: string): string {
    return this.addUTCToTimePipe.transform(time);
  }

  public addOutageFormGroup() {
    this.pageLoading = true;
    const outages = this.addOutageForm.get('outages') as FormArray
    outages.push(this.addOutageState.createOutageFormGroup())
    this.pageLoading = false;
  }

  public removeOutageFormGroup(i: number, outage: any) {
    const outages = this.addOutageForm.get('outages') as FormArray
    if (outages.length > 1) {
      outages.removeAt(i)
    } else {
      outages.reset()
    }
  }

  getControls() {
    return (this.addOutageForm.get('outages') as FormArray).controls;
  }

  getControlValue(control: any, controlName: any) {
    return control.controls[controlName].value;
  }

  getControlError(control: any, controlName: any) {
    return control.controls[controlName].error;
  }

  public saveOutage(): void {
    this.status = 'loading';

    this.addOutageState.submitOutage()
      .pipe(take(1))
      .subscribe(() => {
        this.error = null;
        this.status = 'success';
        this.outageService.outageSuccess$.next({
          outageId: '',
          type: 'new'
        });

        this.router.navigate([`outages`]);
      }, (error: unknown) => {
        this.error = getDisplayableServerError(error);
        this.status = 'error';
      });
  }

  public closeAddOutage(): void {
    this.router.navigate([`outages`]);
  }

  public get displayableServerError(): DisplayableServerError | null | undefined {
    return this.addOutageState.displayableServerError || this.error;
  }

  // public getUtcEndTimes(value: any) {
  //   return removeUTCTimesForOutage(value, 'before');
  // }

  // public getUtcStartTimes(value: any) {
  //   return removeUTCTimesForOutage(value, 'after');
  // }

  /**
   * Closes the drawer if there hasn't been any form changes, otherwise prompts user.
   */
  public closeDrawerIfUnchanged(): void {
    if (this.addOutageState.hasFormValueChanged()) {
      this.unsavedChangesDialogService.open().pipe(take(1)).subscribe((choseToLeave) => {
        if (choseToLeave) {
          this.closeAddOutage();
        }
      });
    } else {
      this.closeAddOutage();
    }
  }
}
