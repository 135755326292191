<div class="wrapper">
  <div class="form-fields-wrapper">
    <div class="margin-separator search-filter-wrapper">
      <vue-filter-search
        placeholder="{{ 'label.placeholder.searchActivity' | translate | async }}"
        (searchValueChange)="updateSearchParams($event)"
        [inputValue]="initialFilters.searchTerm"
        [invalidRegex]="invalidRegex"
      ></vue-filter-search>
    </div>
    <div class="margin-separator">
      <app-filter-multi-select
        placeholder="{{ 'label.placeholder.performedBy' | translate | async }}"
        searchPlaceholder="{{ 'label.placeholder.searchUsers' | translate | async }}"
        [options]="multiSelectOptions"
        (change)="filteredUsersUpdated($event)">
      </app-filter-multi-select>
    </div>
    <vue-date-range-picker
      startDatePlaceholder="{{ 'label.placeholder.start' | translate | async }}"
      endDatePlaceholder="{{ 'label.placeholder.end' | translate | async }}"
      (startDateChange)="filterDateChanged($event, 'start')"
      (endDateChange)="filterDateChanged($event, 'end')"
      [initialStartDate]="initialFilters.startDate"
      [initialEndDate]="initialFilters.endDate"
    ></vue-date-range-picker>
  </div>
  <div class="chips-wrapper" *ngIf="selectedUsersChips.length !== 0">
    <span class="chips-label">{{ 'label.filteredBy' | translate | async }}</span>
    <app-dismissible-chip
      *ngFor="let user of selectedUsersChips"
      class="chip"
      [chipItem]="user"
      (dismissed)="selectedUserRemoved($event)">
    </app-dismissible-chip>
  </div>
</div>
