import { Component, Input } from '@angular/core';
import { Direction } from 'types/Direction';

/**
 *  Displays a "caret" style arrow forward or backward
 */
@Component({
  selector: 'vue-arrow',
  templateUrl: './vue-arrow.component.html',
  styleUrls: [ './vue-arrow.component.scss' ]
})
export class VueArrowComponent {
  @Input() public direction: Direction = 'forward'
}
