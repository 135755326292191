import { Component } from '@angular/core';
import moment from 'moment';

/**
 * Footer for terms, privacy and copyright.
 */
@Component({
  selector: 'vue-footer',
  templateUrl: './vue-footer.component.html',
  styleUrls: [ './vue-footer.component.scss' ]
})
export class VueFooterComponent {
  /** Returns the current year */
  public year(): string {
    return `${moment().year()}`;
  }
}
