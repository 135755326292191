import { Component } from '@angular/core';

/**
 *  Pools recent activity page
 */
@Component({
  selector: 'app-pools-recent-activity-page',
  templateUrl: './pools-recent-activity-page.component.html',
})
export class PoolsRecentActivityPageComponent {}
