<div class="checkboxes">
  <mat-checkbox
    *ngFor="let queue of queues"
    class="option"
    tabIndex="0"
    (change)="updateSelected($event, queue.id)"
    [checked]="isQueueSelected(queue.id)"
  >
    {{formatQueueDisplay(queue)}}
  </mat-checkbox>
</div>
