import { Component, Input } from '@angular/core';

/**
 * Displays a number in a styled "indicator" oval
 */
@Component({
  selector: 'vue-number-indicator',
  templateUrl: './vue-number-indicator.component.html',
  styleUrls: [ './vue-number-indicator.component.scss' ]
})
export class VueNumberIndicatorComponent {
  /**
   * Number to show
   */
  @Input() public number = 0;
}
