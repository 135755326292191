import { Injectable } from '@angular/core';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

const DEBOUNCE_MILLIS = 300;

/**
 * Wraps and debounces window resize events
 */
@Injectable({
  providedIn: 'root'
})
export class WindowResizeService {
  public resized$: Subject<void> = new Subject();

  private resizeSubscription?: Subscription;

  private destroyed: Subject<boolean> = new Subject<boolean>();

  public constructor() {
    const resizeObservable = fromEvent(window, 'resize');
    this.resizeSubscription = resizeObservable
      .pipe(debounceTime(DEBOUNCE_MILLIS), takeUntil(this.destroyed))
      .subscribe(() => {
        this.resized$.next();
      });
  }
}
