<vue-page-header
  appName="{{ 'title.applicationName' | translate | async }}"
  pageTitle="{{ 'title.allPools' | translate | async }}"
  navSection="{{ 'title.pools' | translate | async }}"
>
  <app-filter-container>
    <app-all-pools-filters></app-all-pools-filters>
    <app-add-pool-drawer (success)="poolCreated($event)"></app-add-pool-drawer>
  </app-filter-container>
</vue-page-header>
<app-page-body>
  <app-server-error-unsaved
    *ngIf="showFetchingPoolError"
    [includeUnsaved]="false"
    (retry)="retryFetchingPool()"
  ></app-server-error-unsaved>
  <vue-section>
    <app-all-pools-table></app-all-pools-table>
  </vue-section>
  <vue-toast
    icon="success"
    heading="{{ 'label.poolsOverview.createPool.toast.heading' | translate | async }}"
  >
    <span toast-body>
      {{ 'label.poolsOverview.createPool.toast.body' | translate:newPoolName | async }}
    </span>
    <a (click)="viewNewPool()" toast-action>
      {{ 'button.viewPool' | translate | async }}
    </a>
  </vue-toast>
  <app-edit-pool-toast></app-edit-pool-toast>
</app-page-body>
