import { EventEmitter } from '@angular/core';
import { Component, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslatePipe } from 'pipes/translate.pipe';

export interface ChipContent<ItemType> {
  content: string;
  contentLabel?: string;
  item: ItemType;
}

/**
 * Dismissible chip component.
 */
@Component({
  selector: 'app-dismissible-chip',
  templateUrl: './dismissible-chip.component.html',
  styleUrls: [ './dismissible-chip.component.scss' ]
})
export class DismissibleChipComponent<ItemType> {
  /**
   * Item that holds the content for the chip to show
   */
  @Input() public chipItem: ChipContent<ItemType> = {} as ChipContent<ItemType>;

  /**
   * Event to emit on chip dismissal
   */
  @Output() public dismissed = new EventEmitter<ItemType>();

  public constructor(private translatePipe: TranslatePipe) {}

  /**
   * Returns the alt text for the chip, a combination of the label & content
   */
  public getAltText(): Observable<string> {
    const label = this.chipItem.contentLabel;
    const content = this.chipItem.content;
    const currentContent = label === '' ? content : label + ' ' + content;

    return this.translatePipe.transform('label.aria.removeFilter', currentContent);
  }
}
