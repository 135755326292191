
import { HourlyPoolScheduleItem } from 'api/types';

/**
 * Compares two pool schedules determine if they're the same
 *
 * @param poolSchedule1 Pool schedule to compare
 * @param poolSchedule2 Pool schedule to compare
 * @returns true if both are the same schedule
 */
export function isSameSchedule(
  poolSchedule1: HourlyPoolScheduleItem[],
  poolSchedule2?: HourlyPoolScheduleItem[]
): boolean {
  if (!poolSchedule2 || poolSchedule2?.length !== poolSchedule1.length) {
    return false;
  }

  let sameSchedule = true;
  for (const [ index, item ] of poolSchedule1.entries()) {
    const overrideItem = poolSchedule2[ index ];
    if (item.startTime !== overrideItem.startTime ||
        item.endTime !== overrideItem.endTime ||
        item.capacity !== overrideItem.capacity
    ) {
      sameSchedule = false;
      break;
    }
  }
  return sameSchedule;
}
