import { Component, ElementRef, EventEmitter, OnInit, Output, Input, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Queue } from 'api/types';
import { AddPoolState } from 'components/drawers/add-pool-drawer/add-pool-state/add-pool-state.service';
import { getFilterConfig } from 'components/filters/appointments-filters/filter-config';
import moment, { Moment, utc } from 'moment';
import { Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { CapacityService } from 'services/api/capacity.service';
import { AppointmentsFiltersService } from 'services/appointments-filters.service';
import { DrawerStatusService } from 'services/status/drawer-status.service';
import {
  AppointmentFilters,
  AppointmentFilterValue,
  Timescale,
} from 'types/AppointmentFilters';
import { RequestStatus } from 'types/RequestStatus';
import { SuppressErrorMatcher } from 'utils/error-matchers/suppress.error-matcher';
import { formatQueueDisplay } from 'utils/format-queue';
import { getDisplayableServerError } from 'utils/get-displayable-server-error';
import {
  INVALID_CHARACTERS_ERROR,
  invalidCharactersValidator,
} from 'utils/validators/invalid-characters.validator';

/**
 * Upload Capacity file that defines a capacity template
 */
@Component({
  selector: 'app-capacity-file-upload',
  templateUrl: './capacity-file-upload.component.html',
  styleUrls: ['./capacity-file-upload.component.scss'],
})
export class CapacityFileUploadComponent implements OnInit, OnDestroy {
  /**
   * Reference to file input element
   */
  @ViewChild('uploadElement')
  public inputElement?: ElementRef<HTMLInputElement>;

  /**
   * Emit event when status of form changes
   * True is emitted if the form is valid, false if not
   */
  @Output() public formStatusChange = new EventEmitter<boolean>();

  /**
   * Emits when an upload has completed successfully.
   */
  @Output() public success = new EventEmitter<void>();

  /**
   * Status of the upload request;
   */
  public status: RequestStatus = 'initial';

  /**
   * The error message returned from an httpError
   */
  public serverErrorMessage = '';

  /**
   * Observable that is triggered when cancelling pending requests
   */
  public cancelUpload$ = new Subject();

  /**
   * The name of the file being uploaded
   */
  public fileName = '';
  /**
   * Terminate subscriptions
   */
  private destroyed$ = new Subject();

  public filters: AppointmentFilters = getFilterConfig(Timescale.monthly, true);

  public dateForTimezone: Moment = utc();

  public uploadCapacityForm: UntypedFormGroup;

  public getQueueDisplay = formatQueueDisplay;

  public suppressErrorState = new SuppressErrorMatcher();

  public uploadDate: string = '';

  public selectedQueueName: string = '';

  @Input() public triggerSave: boolean = false;

  public uploadSubscription!: Subscription;

  public selectedFile!: any;

  public selectedQueueId!: any;

  public constructor(
    private capacityService: CapacityService,
    private formBuilder: UntypedFormBuilder,
    private addPoolState: AddPoolState,
    private filterService: AppointmentsFiltersService,
    private drawerStatusService: DrawerStatusService
  ) {
    this.uploadCapacityForm = this.createForm();
  }

  public ngOnInit(): void {
    this.filterService.params$
      .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
      .subscribe((updated) => {
        console.log(updated);
        const date: Moment = utc();
        this.uploadDate = date.format('DD MM YYYY');
        this.uploadCapacityForm.controls.fileDate.setValue(
          date.format('YYYY-MM')
        );
      });

    this.uploadCapacityForm.controls.queueId.valueChanges.subscribe((value) => {
      if (value) {
        this.selectedQueueName = value?.name;
        this.selectedQueueId = value?.id;
        this.validateForm();
      }
    });
    this.uploadCapacityForm.controls.comment.valueChanges.subscribe((value) => {
      if (value) {
        this.validateForm();
      }
    });
    this.uploadSubscription =
      this.capacityService.capacityUploadSaveTrigger$.subscribe(
        (value: string) => {
          if (value && value === 'uploadFile') {
            // call upload method
            console.log('call save method');
            this.uploadFile();
          }
        }
      );
  }

  /**
   * @returns addPoolState.queues
   */
  public get queues(): Queue[] {
    return this.addPoolState.queues;
  }

  /**
   * Pass file from input change event to file upload function
   *
   * @param event (input change event)
   */
  public onBrowseFile(event: Event): void {
    this.selectedFile = null;
    const target = event.target as HTMLInputElement;
    if (target.files && target.files[0]) {
      //below is dummy code
      console.log(this.uploadCapacityForm.value);
      this.selectedFile = target.files[0];
      this.fileName = target.files[0].name;
      this.status = 'success';
      this.validateForm();
    }
  }

  /**
   * Handle uploading file
   *
   * @param file (File object to upload)
   */
  public dragDropFile(file: File): void {
    this.selectedFile = null;
    this.fileName = file.name;
    this.selectedFile = file;
    this.status = 'success';
    this.validateForm();
  }

  public uploadFile() {
    this.status = 'loading';
    this.drawerStatusService.loading();
    this.validateForm();
    const payload = new FormData();
    payload.append('file', this.selectedFile, this.fileName);
    payload.append(
      'queueName',
      this.uploadCapacityForm.controls.queueId.value.name
    );
    payload.append(
      'fileUploaddate',
      this.uploadCapacityForm.controls.fileDate.value
    );
    payload.append('comment', this.uploadCapacityForm.controls.comment.value);

    this.capacityService
      .uploadCapacity(payload)
      .pipe(take(1), takeUntil(this.cancelUpload$)) // Allow the upload to be cancelled
      .subscribe(
        () => {
          this.status = 'uploaded';
          this.clearInputValue();
          this.formStatusChange.emit(true);
          //on success emit success for closing dialog
          this.capacityService.setTriggerValue('uploaded');
          this.success.emit();
        },
        (error: unknown) => {
          this.serverErrorMessage =
            getDisplayableServerError(error)?.message || '';
          this.clearInputValue();
          this.status = 'error';
          this.capacityService.setTriggerValue('error');
          this.drawerStatusService.reset();
        }
      );
  }

  /**
   * Cancel uploading file and clear input value
   */
  public cancelUpload(): void {
    this.clearInputValue();
    this.status = 'initial';
    this.cancelUpload$.next(true);
    this.selectedFile = null;
  }

  /**
   * Clear input value
   * Avoids the case were the same file is chosen but the change event doesn't fire
   */
  private clearInputValue(): void {
    if (this.inputElement) {
      this.inputElement.nativeElement.value = '';
    }
  }

  private createForm(): UntypedFormGroup {
    return this.formBuilder.group({
      queueId: new UntypedFormControl(null, Validators.required),
      fileDate: new UntypedFormControl('', Validators.required),
      comment: new UntypedFormControl('', [
        Validators.maxLength(250),
        invalidCharactersValidator
      ]),
      file: new UntypedFormControl('', Validators.required),
    });
  }

  public dateChanged(
    name: keyof AppointmentFilters,
    value: AppointmentFilterValue
  ): void {
    console.log('name', name);
    console.log('value', value);
    if (this.filters[name]) {
      if (name === 'startDate' && typeof value === 'string') {
        this.dateForTimezone = utc(value);
        const date = this.dateForTimezone.format('YYYY-MM');
        this.uploadCapacityForm.controls.fileDate.setValue(date);
        this.validateForm();
      }
      // Apply latest update
      this.filters[name].value = value;
    }
  }

  public ngOnDestroy(): void {
    console.log('destroyed');
    this.destroyed$.next();
    this.destroyed$.complete();
    this.uploadSubscription.unsubscribe();
  }

  public clearUploaded(): void {
    this.clearInputValue();
    this.status = 'initial';
    this.cancelUpload$.next(true);
    this.uploadCapacityForm.controls.file.reset();
    this.selectedFile = null;
    this.validateForm();
  }

  validateForm() {
    if (
      this.uploadCapacityForm.controls.queueId.value?.name &&
      this.uploadCapacityForm.controls.fileDate.value &&
      (this.uploadCapacityForm.controls.file.value || this.selectedFile)
      && this.uploadCapacityForm.controls.comment.valid && (this.status == 'uploaded' || this.status == 'success')
    ) {
      console.log('valid');
      this.formStatusChange.emit(true);
      this.capacityService.setTriggerValue('valid');
    } else {
      this.formStatusChange.emit(false);
      this.capacityService.setTriggerValue('invalid');
    }
  }

  public commentErrorMessageKey() {
    if (
      this.uploadCapacityForm.controls.comment.hasError(
        INVALID_CHARACTERS_ERROR
      )
    ) {
      return 'error.invalidCharacter';
    }
    if (this.uploadCapacityForm.controls.comment.hasError('maxlength')) {
      return 'error.maxlength';
    }
    return null;
  }

  public downloadTemplate(): void {
    const path = 'assets/capacityFileTemplate/Capacity file upload Template.csv';
    window.open(path);
  }
}
