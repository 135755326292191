<vue-chip [color]="chipColor">
  <ng-container *ngIf="status === 'open'">
    {{ 'label.open' | translate | async }}
  </ng-container>
  <ng-container *ngIf="status === 'archived'">
    {{ 'label.archived' | translate | async }}
  </ng-container>
  <ng-container *ngIf="status === 'scheduled'">
    {{ 'label.scheduled' | translate | async }}
  </ng-container>
  <ng-container *ngIf="status === 'completed'">
    {{ 'label.completed' | translate | async }}
  </ng-container>
</vue-chip>
