<app-chart-legend [categories]="legendCategories"></app-chart-legend>
<app-chart-with-custom-y-axis [compactHeader]="true">
  <app-custom-y-axis
    [widthRems]="firstColumnWidthRems"
    [max]="yAxisMax"
    [stepSize]="yAxisStepSize"
    [chartHeightRems]="chartHeightRems">
  </app-custom-y-axis>
  <div class="scroll-container" #scrollContainer>
    <app-custom-x-axis
      [labels]="getDayLabels()"
      [columnWidthRems]="dayLabelWidthRems"
      [ngStyle]="{ 'width.rem': chartWidthRems }">
    </app-custom-x-axis>
    <app-chart-canvas-wrapper [chartWidthRems]="chartWidthRems" [chartHeightRems]="chartHeightRems">
      <canvas
        baseChart
        [datasets]="datasets"
        [labels]="getChartItemLabels()"
        [options]="options"
        [vueChartPopover]="popoverTemplate"
        (popoverWillOpen)="setPopoverEvent($event)"
        [attr.aria-label]="'label.aria.chartShowingAppointmentCapacity' | translate | async"
        role="img">
      </canvas>
    </app-chart-canvas-wrapper>
    <app-custom-bottom-x-axis-labels [labels]="getBottomXAxisLabels()" [chartWidthRems]="chartWidthRems">
    </app-custom-bottom-x-axis-labels>
  </div>
  <app-synched-scroll-button direction="forward"></app-synched-scroll-button>
</app-chart-with-custom-y-axis>
<ng-template #popoverTemplate>
  <app-appt-detail-chart-popover
    *ngIf="popoverEvent && interval === '1hr'"
    [event]="popoverEvent"
    [metricItems]="metricItems"
    [showWeeklyActions]="true"
  ></app-appt-detail-chart-popover>
  <app-appt-six-hour-chart-popover
    *ngIf="popoverEvent && interval === '6hr'"
    [event]="popoverEvent"
    [metricItems]="metricItems"
  ></app-appt-six-hour-chart-popover>
</ng-template>
