/* eslint-disable complexity */
import { Directive, HostListener, Input, OnInit } from '@angular/core';

/**
 * Regular expression to match on any number character
 */
const numberRegex = '[0-9]';

/**
 * Regular expression to match on '.' character
 *
 * Note: prefixed with or operator
 */
const decimalRegex = '|[.]';

/**
 * Regular expression to match on '-' character
 *
 * Note: prefixed with or operator
 */
const negativeRegex = '|[-]';

/**
 * Extend native HTML input of type number functionality
 */
@Directive({
  selector: '[vueNumberInput]'
})
export class VueNumberInputDirective implements OnInit {
  /**
   * Restrict decimals
   */
  @Input() public restrictDecimals = true;

  /**
   * Restrict negative values
   */
  @Input() public restrictNegatives = true;

  /**
   * Maximum number of characters allowed
   */
  @Input() public maxLength?: number;

  /**
   * Regex applied to input keys values
   * Default: only accepts number keys
   */
  private inputRegex = new RegExp(`(${numberRegex})`)

  /**
   * Codes for each number
   */
  private numberCodes: readonly string[] = [
    'Digit0', 'Digit1', 'Digit2', 'Digit3',
    'Digit4', 'Digit5', 'Digit6', 'Digit7',
    'Digit8', 'Digit9', 'Numpad0', 'Numpad1',
    'Numpad2', 'Numpad3', 'Numpad4', 'Numpad5',
    'Numpad6', 'Numpad6', 'Numpad7', 'Numpad8',
    'Numpad9'
  ];

  /**
   * Prevents input when false is returned
   * Keypress is only fired when a key produces a character value: alphabetic, numeric, and punctuation keys.
   *
   * NOTE: Allow event when event.metaKey is true. Ctrl or cmd key is pressed when the keypress event is fired,
   * allow native browser behavior in these scenarios. Commands like select all do not work in Safari without it.
   */
  @HostListener('keypress', [ '$event' ]) public onKeyPress(event: KeyboardEvent): boolean {
    return event.metaKey ||
      (this.inputRegex.test(event.key) &&
        (!this.exceedsMaxLength(event) || this.hasSelectedText())
      );
  }

  /**
   * Prevents input from incrementing / decrementing
   * Keydown is fired for all key events
   */
  @HostListener('keydown', [ '$event' ]) public onKeyDown(event: KeyboardEvent): boolean {
    return !this.exceedsMaxLength(event) || this.hasSelectedText();
  }

  /**
   * Remove invalid characters and trim to proper length when a user pastes data.
   */
  @HostListener('paste', [ '$event' ]) public paste(event: ClipboardEvent): void {
    // Check if input is the host element or a child of the host element
    const input = event.target as HTMLInputElement | null;

    // Check for input element
    if (!input) {
      // eslint-disable-next-line no-console
      console.warn('No input found for VueNumberInputDirective');
      return;
    }

    let data = event.clipboardData?.getData('text');

    // Check for paste data
    if (!data) return;

    event.stopPropagation();
    event.preventDefault();

    // Match on all characters, replacing those that don't pass `inputRegex.test`
    data = data.replace(/./g, (char) => {
      if (this.inputRegex.test(char)) {
        return char;
      }

      return '';
    });

    // Cut the pasted data at the maxLength
    if (data.length > (this.maxLength || Number.MAX_SAFE_INTEGER)) {
      data = data.slice(0, this.maxLength);
    }

    input.value = data;

    // Dispatch input event so any listeners can process
    input.dispatchEvent(new Event('input'));
  }

  public ngOnInit(): void {
    // Construct regex string
    const decimalRegexString = this.restrictDecimals ? '' : decimalRegex;
    const negativeRegexString = this.restrictNegatives ? '' : negativeRegex;

    this.inputRegex = new RegExp(`(${numberRegex}${decimalRegexString}${negativeRegexString})`);
  }

  /**
   * Returns true if the new input value exceeds the defined `maxLength`.
   */
  private exceedsMaxLength(event: KeyboardEvent): boolean {
    // eslint-disable-next-line no-undefined
    if (this.maxLength === undefined) {
      return false;
    }

    const inputValue = (event.target as HTMLInputElement)?.value ?? '';

    // Stop from incrementing past length limit with arrow keys
    if (event.code === 'ArrowUp') {
      const num = Number(inputValue) + 1;
      return this.maxLength < String(num).length;
    }

    // Add 1 to input length to account for the event character
    let inputLength = inputValue.length;
    inputLength++;

    // Check for length of input, while allowing other keys to behave properly
    return this.maxLength < inputLength && (this.numberCodes.includes(event.code));
  }

  /**
   * Returns true when text is selected.
   * Native browser implementation will replace the selected text with the new value.
   *
   * Always returns an empty string in FireFox: https://bugzilla.mozilla.org/show_bug.cgi?id=85686
   */
  private hasSelectedText(): boolean {
    return Boolean(window.getSelection()?.toString());
  }
}
