import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop, Observable } from 'rxjs';
import { Day, daysOfTheWeek } from 'constants/days-of-the-week';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { TranslatePipe, TranslationKey } from 'pipes/translate.pipe';

@Component({
  selector: 'app-pool-days-of-week-with-restriction',
  templateUrl: './pool-days-of-week-with-restriction.component.html',
  styleUrls: ['./pool-days-of-week-with-restriction.component.scss'],
 providers: [{
    provide: NG_VALUE_ACCESSOR, useExisting: PoolDaysOfWeekWithRestrictionComponent, multi: true
  }]
})
export class PoolDaysOfWeekWithRestrictionComponent implements ControlValueAccessor {
  @Input() restrictedDays!: number[];
  public daysOfWeek: { value: Day, checked: boolean }[];

  constructor(private translatePipe: TranslatePipe){
    this.daysOfWeek = daysOfTheWeek.map((day, i) => {
      return {
        value: day,
        checked: true,
      };
    });
  }

  // Form functions
  private onChangeCallback: (_: any) => void = noop;
  private onTouchedCallback = noop;

  // Handle occurrence change
  public checkboxChange(value: any, event: MatSlideToggleChange): void {
    this.daysOfWeek.forEach((day) => {
      if (day.value === value) {
        day.checked = event.checked;
      }
    });
    this.onTouchedCallback();
    this.onChangeCallback(this.getSelectedDays());
  }

  // Update value from parent
  public writeValue(selectedDays: any): void {
    this.daysOfWeek = this.daysOfWeek.map((day) => {
      return {
        ...day,
        checked: selectedDays.length > 0 ? selectedDays.find((i: any) => i.value === day.value).checked : true
      };
    });
  }

  // Update parent form value
  public registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  // Update parent form control has been touched
  public registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  private getSelectedDays(): any {
    return this.daysOfWeek;
  }

  public getToolTip(isRestricted: boolean): Observable<string> {
    const restricted = 'tooltip.message.pool.restrictedOccurrence';
    const unrestricted = 'tooltop.message.pool.unrestrictedOccurrence';
    const key: TranslationKey = isRestricted ? restricted : unrestricted;
    return this.translatePipe.transform(key);
  }
}
