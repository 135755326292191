interface PaginatorProperties {
  limit: number;
  offset: number;
  total?: number;
}

/**
 * Class to maintain Paginator properties.
 * Properties should be updated from API responses
 */
export class PaginatorBase {
  /**
   * Total number of items in the table
   */
  public totalItemCount = 0;

  /**
   * Current page of the paginator
   */
  public pageIndex: number;

  /**
   * Number of items shown in a single page the table
   */
  public pageSize: number;

  /**
   * Options for `pageSize`
   */
  public pageSizeOptions: number[] = [ 5, 10, 15, 20, 25 ];

  /**
   * Show first and last buttons in Paginator
   */
  public showFirstLastButtons = true;

  public constructor(defaultValues: PaginatorProperties, pageSizeOptions?: number[]) {
    // Set default values
    const { offset, limit, total } = defaultValues;

    this.pageIndex = this.getPageIndex(offset, limit);
    this.pageSize = limit;
    this.totalItemCount = total ?? 0;

    if (typeof pageSizeOptions !== 'undefined') {
      this.pageSizeOptions = pageSizeOptions;
    }
  }

  /**
   * Update all paginator properties
   */
  public updatePaginator({ offset, limit, total }: Required<PaginatorProperties>): void {
    this.pageIndex = this.getPageIndex(offset, limit);
    this.pageSize = limit;
    this.totalItemCount = total;
  }

  /**
   * Calculate the page index based on the offset & limit.
   * Using `isFinite` to be defensive against bad attributes from API.
   */
  private getPageIndex(offset: number, limit: number): number {
    return isFinite(offset / limit) ? offset / limit : 0;
  }
}
