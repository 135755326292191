import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetActivityPathParameters, GetActivityQueryParameters, GetActivityResponse } from 'api/types';
import { createQueryString } from 'utils/create-query-string';
import { CapacityMicroservice } from './microservices/capacity.microservice';

type QueryParams = Partial<GetActivityQueryParameters>;
type PathParams = GetActivityPathParameters;

/**
 *  API service for activity related API calls
 */
@Injectable({
  providedIn: 'root'
})
export class ActivityService {
  /**
   * Default params that can be overridden by input
   */
  private defaultParams: GetActivityQueryParameters = {
    limit: 25,
    offset: 0,
    sortBy: 'activity',
    direction: 'asc'
  }

  public constructor(private api: CapacityMicroservice) { }

  /**
   * Calls GetActivity API
   *
   * @param pathParams required path params for get activity call
   * @param queryParams optional query params for get activity call
   * @returns GetActivityResponse Observable
   */
  public getActivity(pathParams: PathParams, queryParams?: QueryParams): Observable<GetActivityResponse> {
    const queryString = createQueryString({
      ...this.defaultParams,
      ...queryParams
    });
    const path = `activity/${pathParams.type}${queryString}`;

    return this.api.get<GetActivityResponse>(path);
  }
}
