import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoadingDialogComponent, loadingDialogConfig, LoadingDialogInput } from 'components/dialogs/loading-dialog/loading-dialog.component';
import { AppointmentsInlineCapacityService, MAX_NUMBER_OF_EDITS } from 'services/appointments-inline-capacity.service';
import { SUCCESS } from 'types/RequestStatus';
import { VueToastComponent } from 'vue/components/vue-toast/vue-toast.component';

/**
 * Inline edit banner that shows how many unsaved changes are made.
 * Allows for canceling those changes or saving them.
 * Displays max edit information when approaching the max. Not responsible for _enforcing_ the max.
 *
 * Used via portal in AppointmentsPageComponent.
 */
@Component({
  selector: 'app-capacity-unsaved-banner',
  templateUrl: './capacity-unsaved-banner.component.html',
  styleUrls: [ './capacity-unsaved-banner.component.scss' ],
})
export class CapacityUnsavedBannerComponent implements OnInit, OnDestroy {
  /**
   * Reference to success toast component
   * To be shown on completion of edit requests
   */
  @ViewChild(VueToastComponent) public toast!: VueToastComponent;

  /**
   * Tracks number of inline edited capacities
   */
  public numberOfEdited = 0;

  /**
   * Observable that terminates all subscriptions when complete
   */
  private destroyed$ = new Subject();

  public MAX_NUMBER_OF_EDITS = 0;

  public constructor(
    private inlineCapacityEditService: AppointmentsInlineCapacityService,
    private dialog: MatDialog
  ) {
    this.MAX_NUMBER_OF_EDITS = MAX_NUMBER_OF_EDITS;
  }  

  /**
   * Subscribe to the number of edited capacities and submission success
   */
  public ngOnInit(): void {
    this.inlineCapacityEditService.numberOfEditedControls$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((numberOfEdited) => {
        this.numberOfEdited = numberOfEdited;
      });

    this.inlineCapacityEditService.editSubmissionStatus$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((status) => {
        if (status === SUCCESS) {
          this.toast.open();
        } else {
          this.toast.close();
        }
        this.dialog.closeAll();
      });
  }

  /**
   * Terminate all subscriptions
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Getter for numberOfEditedString.  After 10 edits, start displaying the maximum, eg 10/15
   *
   * @returns string
   */
  public get numberOfEditedString(): string {
    return this.approachingMaxEdits ? `${this.numberOfEdited}/${MAX_NUMBER_OF_EDITS}` : String(this.numberOfEdited);
  }

  /**
   * Let UI know when it should start displaying max edit information
   *
   * @returns boolean
   */
  public get approachingMaxEdits(): boolean {
    return this.numberOfEdited >= MAX_NUMBER_OF_EDITS - 5;
  }

  /**
   * Reset Capacity changes to their original value
   */
  public resetCapacities(): void {
    this.inlineCapacityEditService.resetCapacities();
  }

  /**
   * Trigger a save of the edited capacity fields
   */
  public saveEditedCapacities(): void {
    // Open "Saving" dialog
    this.dialog.open<
    LoadingDialogComponent,
    LoadingDialogInput
    >(LoadingDialogComponent, {
      ...loadingDialogConfig,
      data: {
        title: 'title.saving',
        subtitle: 'subtitle.pleaseWait',
      },
    });

    this.inlineCapacityEditService.saveEditedCapacities();
  }
}
