<div class="header-wrapper">
  <h1
    #heading
    mat-dialog-title
    class="title"
    aria-live="assertive"
    tabindex="-1"
  >
    {{ dialogTitle }}
  </h1>
  <button
    class="close-button"
    (click)="closeDialog()"
    [attr.aria-label]="'label.aria.closeHourlyAllocationDialog' | translate | async"
  >
    <img
      class="close-x"
      src="/assets/icons/icon-grey-close-x.svg"
      alt=""
    />
  </button>
</div>
<mat-dialog-content class="mat-typography content-wrapper">
  <app-hourly-allocation-table
    [dailyCapacityCalculation]="data.dailyCalculations[currentDayIndex]"
  ></app-hourly-allocation-table>
  <div
    *ngIf="(data.dailyCalculations[currentDayIndex]?.hourlyCalculations || []).length"
    class="total-row"
  >
    <p>{{ 'title.total' | translate | async }}</p>
    <p class="total">{{ (data.dailyCalculations[currentDayIndex].capacity || 0) | number}}</p>
  </div>
</mat-dialog-content>
<div mat-dialog-actions class="footer">
  <vue-basic-button *ngIf="currentDayIndex > 0" (clicked)="previous()">
    {{ 'button.previous' | translate | async }}
  </vue-basic-button>
  <vue-flat-button (clicked)="next()">
    {{ currentDayIndex === 6 ? ('button.done' | translate | async) : ('button.next' | translate | async) }}
  </vue-flat-button>
</div>
