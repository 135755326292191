<div class="y-axis">
  <app-synched-scroll-button direction="backward" *ngIf="!isDaily"></app-synched-scroll-button>
  <app-table-header-cell
    [label]="label"
    [secondaryLabel]="secondaryLabel"
    [alignLeft]="true"
    class="header">
  </app-table-header-cell>
  <div class="ticks" [ngStyle]="{'height.rem': chartHeightRems}">
    <div class="tick" *ngFor="let label of tickLabels" [ngStyle]="{'height.rem': tickHeightRems}">
      <div class="tick-label">{{label | number}}</div>
      <div class="tick-line"></div>
    </div>
  </div>
</div>
<div class="shadow"></div>
