import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AutoCompleteStatusService } from 'services/status/auto-complete-status.service';
import { LOADING } from 'types/RequestStatus';
import { fadeInOut } from 'utils/fade-in-out.animation';

/**
 * Loading component for auto-completes
 * Implementing components should integrate with AutoCompleteStatusService,
 * and set the loading/success/error state when appropriate.
 *
 * @example
 * <auto-complete-input />
 * <app-auto-complete-loader>
 *   ...auto-complete-items
 * </app-auto-complete-loader
 */
@Component({
  selector: 'app-auto-complete-loader',
  templateUrl: './auto-complete-loader.component.html',
  styleUrls: [ './auto-complete-loader.component.scss' ],
  animations: [ fadeInOut() ]
})
export class AutoCompleteLoaderComponent implements OnDestroy {
  /**
   * Class level state if auto complete status is loading
   */
  public loading = false;

  /**
   * Completes when component is destroyed
   */
  private destroyed$ = new Subject();

  public constructor(autoCompleteStatusService: AutoCompleteStatusService) {
    // Subscribe to status changes
    autoCompleteStatusService.status$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((status) => {
        this.loading = status === LOADING;
      });
  }

  /**
   * Complete destroyed subject
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
