import { AfterContentInit, Component, Input } from '@angular/core';
import { ChartDataset, ChartOptions } from 'chart.js';
import { utc } from 'moment';
import { take } from 'rxjs/operators';
import { GetMetricsParameters, MetricsResultItem } from 'api/types';
import { ABBREV_DAY_FORMAT, DISPLAY_DAY_FORMAT, EXCHANGE_FORMAT } from 'constants/date-formats';
import { INTERVAL_1DAY, INTERVAL_1HR } from 'constants/intervals';
import { TranslatePipe, TranslationKey, TranslationLookup } from 'pipes/translate.pipe';
import { MetricsService } from 'services/api/metrics.service';
import { AppointmentsFiltersService } from 'services/appointments-filters.service';
import { Timescale } from 'types/AppointmentFilters';
import { LegendCategoryConfig } from 'types/LegendCategoryConfig';
import { addCriticalFlags } from 'utils/add-critical-flags';
import { pxToRem } from 'utils/rem-utils';
import { basePopoverChartOptions } from '../../charts/utils/base-popover-chart-options';
import palette from '../../charts/utils/chart-palette';
import { CAPACITY_ID, getChartDatasets, REGISTRATIONS_ID } from './appt-weekly-summary-popover.datasets';

// Add 5 percent to chart max to ensure no rendered lines are clipped
export const MAX_DATA_BUFFER = 1.05;
const CHART_WIDTH_REMS = pxToRem(220);

/**
 *  Popover for displaying appointment info for the week.
 *  Displayed when a weekly summary cell on the appointment monthly chart is clicked
 */
@Component({
  selector: 'app-appt-weekly-summary-popover',
  templateUrl: './appt-weekly-summary-popover.component.html',
  styleUrls: [ './appt-weekly-summary-popover.component.scss' ]
})
export class ApptWeeklySummaryPopoverComponent implements AfterContentInit {
  /**
   * Date string to display details for in YYYY-MM-HH
   */
  @Input() public date?: string;

  public loading = true;

  /**
   * Chart dimensions
   */
  public chartWidthRems = CHART_WIDTH_REMS;

  /**
   * For very short charts, the height must be set in px, directly on the canvas.
   */
  public chartHeightPx = 80;

  /**
   * Account for internal chart padding
   */
  public canvasWrapperStyle = {
    'width.rem': CHART_WIDTH_REMS + pxToRem(7),
    'margin-left.rem': pxToRem(-7),
  }

  /**
   * Chart configuration
   */
  public chartDatasets: ChartDataset[] = [];
  public chartLabels: string[] = [];
  public chartOptions: ChartOptions = {};
  public bottomXAxisLabels: string[] = [];

  /**
   * Legend configuration
   */
  public legendItems: LegendCategoryConfig[] = []

  /**
   * A localized string lookup.
   */
  private translations: TranslationLookup = {};

  public constructor(
    private getMetricsService: MetricsService,
    private filterService: AppointmentsFiltersService,
    private translatePipe: TranslatePipe,
  ) {}

  public ngOnInit(): void {
    const keys: TranslationKey[] = [
      'title.capacity',
      'title.critical',
      'title.registrations',
    ];

    // Load the localization translations
    this.translatePipe.loadTranslations(keys)
      .pipe(take(1))
      .subscribe((translations) => {
        this.translations = translations;
        this.legendItems = [
          { type: 'bar', color: palette.blue, label: translations[ 'title.registrations' ] },
          { type: 'bar', color: palette.red, label: translations[ 'title.critical' ] },
        ];
      });
  }

  // AfterContentInit ensures container layout is stable before rendering chart
  public ngAfterContentInit(): void {
    this.buildContent();
  }

  /**
   * Switches the view to a weekly view of the week clicked on
   */
  public goToWeeklyView(): void {
    this.filterService.updateTimescale({
      timescale: Timescale.weekly,
      interval: INTERVAL_1HR,
      startDate: this.date ?? utc().format(EXCHANGE_FORMAT)
    });
  }

  private buildContent(): void {
    this.loading = true;

    if (!this.date) {
      return;
    }

    // Fetch hourly details
    const params: GetMetricsParameters = {
      ...this.filterService.getCurrentValue(),
      startDate: this.date,
      numberOfDays: 7,
      interval: INTERVAL_1DAY,
    };
    this.getMetricsService.getMetrics(params).pipe(take(1)).subscribe((metrics) => {
      this.loading = false;
      this.buildChart(metrics);
    });
  }

  /**
   * Date formatted to be read by a screen reader
   */
  public get screenReaderDate(): string {
    const startOfWeek = utc(this.date).startOf('week').format(DISPLAY_DAY_FORMAT);
    const endOfWeek = utc(this.date).endOf('week').format(DISPLAY_DAY_FORMAT);
    return `${startOfWeek} - ${endOfWeek}`;
  }

  private buildChart(metricResults: MetricsResultItem): void {
    const metricItems = metricResults.metrics;
    const flaggedMetrics = addCriticalFlags(metricItems.map((m) => m.items[ 0 ]));
    const maxCapacity = flaggedMetrics.reduce((max, m) => {
      return Math.max(max, m.capacity || 0);
    }, 0) * MAX_DATA_BUFFER;

    this.chartOptions = basePopoverChartOptions(maxCapacity, CAPACITY_ID, REGISTRATIONS_ID);
    this.chartDatasets = getChartDatasets(flaggedMetrics, this.translations);
    this.chartLabels = flaggedMetrics.map((m) => utc(m.timestamp).format(ABBREV_DAY_FORMAT));
    this.bottomXAxisLabels = this.chartLabels;
  }
}
