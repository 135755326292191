/* eslint-disable @typescript-eslint/member-ordering, no-invalid-this */
import { Injectable, OnDestroy } from '@angular/core';
import { utc } from 'moment';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { filter, skip, switchMap, take, takeUntil } from 'rxjs/operators';
import { GetPoolCapacityMetricsParameters, GetPoolsParameters, GetPoolsResponse, PoolCapacityMetric, Queue } from 'api/types';
import { formatDateForPayload } from 'components/common/pools/utils/format-date-for-payload';
import { getInitialPoolsFilter, PoolsFilters, PoolsFiltersValue } from 'components/filters/pools-filters/pools-filters.config';
import { SUCCESS } from 'types/RequestStatus';
import { PoolsService } from './api/pools.service';
import { QueuesService } from './api/queues.service';
import { OccurrenceActionsStatusService } from './status/occurrence-actions-status.service';
import { PageStatusService } from './status/page-status.service';

export const DEFAULT_VALUES: GetPoolsParameters = {
  limit: 5,
  offset: 0,
  sortBy: 'name',
  direction: 'asc'
};

/**
 * Service to provide state and utility functions to all components on the pools page
 */
@Injectable({
  providedIn: 'root'
})
export class PoolsPageService implements OnDestroy {
  // Internal sources of truth for page state
  private openPoolsResponse = new BehaviorSubject<GetPoolsResponse>({ ...DEFAULT_VALUES, items: [], total: 0 });
  private poolCapacityMetrics = new BehaviorSubject<PoolCapacityMetric[]>([]);
  private poolsFilters = new BehaviorSubject<PoolsFilters>(getInitialPoolsFilter());
  private openPoolsTableParams = new BehaviorSubject<GetPoolsParameters>(DEFAULT_VALUES);

  /**
   * Cancel subscriptions on destroy
   */
  private destroyed$ = new Subject();

  /**
   * Observable array of open pools to be shown in the open pools table
   */
  public openPoolsResponse$ = this.openPoolsResponse.asObservable();

  /**
   * Observable array of metrics for the selected queue and month
   */
  public poolCapacityMetrics$ = this.poolCapacityMetrics.asObservable();

  /**
   * Filters (queue & month/year) for the pools overview page
   */
  public poolsFilters$ = this.poolsFilters.asObservable();

  /**
   * Params for the open pools table, sorted column, direction, offset and limit
   */
  public openPoolsTableParams$ = this.openPoolsTableParams.asObservable();

  /**
   * Internal state and public observable for fetching metrics states
   */
  private fetchingMetrics = new Subject<boolean>();
  public fetchingMetrics$ = this.fetchingMetrics.asObservable();

  /**
   * Subject that emits when pool details should be fetched
   */
  private fetchPoolsData$ = new Subject<void>();

  public constructor(
    private poolsService: PoolsService,
    private queueService: QueuesService,
    private pageStatusService: PageStatusService,
    private occurrenceActionsStatusService: OccurrenceActionsStatusService,
  ) {
    // Fetch pool data
    this.fetchPoolsData$
      .pipe(takeUntil(this.destroyed$))
      .pipe(switchMap(() => { // switchMap will cancel any in-flight API requests
        return this.fetchPoolsPageData();
      }))
      .subscribe((responses) => {
        this.fetchPageDataSuccess(responses);
      }, () => {
        this.pageStatusService.error();
      });

    // Update page data when filters change
    this.poolsFilters
      .pipe(filter((poolsFilters) => Boolean(poolsFilters.queue.value?.id)))
      .subscribe(() => {
        this.fetchPoolsData$.next();
      });

    /**
     * If an occurrence action succeeds, update the data
     */
    this.occurrenceActionsStatusService.status$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((status) => {
        if (status === SUCCESS) {
          this.updatePoolMetricData();
        }
      });

    /**
     * When pools params change, re-fetch open pools
     * Skipping first emission because initial page load will load the pools
     */
    this.openPoolsTableParams
      .pipe(skip(1))
      .subscribe(() => {
        this.fetchingMetrics.next(true);
        this.getOpenPools()
          .pipe(take(1))
          .subscribe((openPools) => {
            this.openPoolsResponse.next(openPools);
            this.fetchingMetrics.next(false);
          });
      });
  }

  /**
   * Cancel subscriptions
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Updates filters observable based on name value pair
   *
   * @param name name of filter that has changed
   * @param value new value of changed filter
   */
  public updatePoolFilter(name: keyof PoolsFilters, value: PoolsFiltersValue): void {
    this.poolsFilters.next({
      ...this.poolsFilters.value,
      [ name ]: {
        ...this.poolsFilters.value[ name ],
        value
      }
    });
  }

  /**
   * Updates params observable based on name value pair
   *
   * @param params updated params for get pools call
   */
  public updatePoolParams(params: Partial<GetPoolsParameters>): void {
    this.openPoolsTableParams.next({
      ...this.openPoolsTableParams.getValue(),
      offset: 0,
      ...params
    });
  }

  /**
   * Trigger the fetching of pool page data
   */
  public updatePoolData(): void {
    this.fetchPoolsData$.next();
  }

  /**
   * Update metric data
   * Used to refresh chart and details after an update occurs
   */
  private updatePoolMetricData(): void {
    this.getPoolMetrics()
      .pipe(take(1))
      .subscribe((metrics) => {
        this.poolCapacityMetrics.next(metrics);
      });
  }

  /*
   * Fetches all initial data to paint the pools dashboard.
   * Also manages page loading and error status.
   */
  private fetchPoolsPageData(): Observable<[GetPoolsResponse, PoolCapacityMetric[]]> {
    this.pageStatusService.loading(() => {
      this.fetchPoolsData$.next();
    });

    return forkJoin([
      this.getOpenPools(),
      this.getPoolMetrics(),
    ]);
  }

  /**
   * Success handler for fetchPoolsPageData
   */
  private fetchPageDataSuccess([ poolResponse, metrics ]: [GetPoolsResponse, PoolCapacityMetric[]]): void {
    this.openPoolsResponse.next(poolResponse);
    this.poolCapacityMetrics.next(metrics);
    this.pageStatusService.success();
  }

  /**
   * Uses selected queue, month, and open pools table params to fetch list of pools
   *
   * @returns Observable to GetPoolsResponse
   */
  private getOpenPools(): Observable<GetPoolsResponse> {
    const { startOfMonth, endOfMonth } = this.getMonthDetails();
    const { queue } = this.poolsFilters.value;

    const poolsParams: GetPoolsParameters = {
      ...this.openPoolsTableParams.value,
      after: startOfMonth,
      before: endOfMonth,
      queueIds: queue.value?.id ? [ queue.value.id ] : [],
      status: [ 'open', 'scheduled', 'completed' ]
    };
    return this.poolsService.getPools(poolsParams);
  }

  /**
   * Uses current values of queue and selected month to fetch metrics
   *
   * @returns Observable to metric array
   */
  private getPoolMetrics(): Observable<PoolCapacityMetric[]> {
    const { startOfMonth, numberOfDays } = this.getMonthDetails();
    const { queue } = this.poolsFilters.value;
    const metricParams: GetPoolCapacityMetricsParameters = {
      startDate: startOfMonth,
      queueId: queue.value?.id || '',
      numberOfDays: numberOfDays
    };
    return this.poolsService.getPoolCapacityMetrics(metricParams);
  }

  /**
   * @returns Observable to queue array
   */
  public getQueues(): void {
    this.queueService.getQueues().pipe(take(1))
      .subscribe((queues) => {
        this.poolsFilters.next({
          ...this.poolsFilters.value,
          queue: {
            ...this.poolsFilters.value.queue,
            options: queues,
            value: QueuesService.getDefaultQueue(queues)
          }
        });
      });
  }

  /**
   * Get month details for service params
   *
   * @returns object containing the start of month, end of month and the number of days in the month
   */
  private getMonthDetails(): {startOfMonth: string, endOfMonth: string, numberOfDays: number} {
    const { startDate } = this.poolsFilters.value;

    const startOfMonth = utc(startDate.value).startOf('month');
    const endOfMonth = utc(startDate.value).endOf('month');
    const numberOfDays = endOfMonth.date();
    return {
      startOfMonth: formatDateForPayload(startOfMonth),
      endOfMonth: formatDateForPayload(endOfMonth),
      numberOfDays
    };
  }
}
