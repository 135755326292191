import { ChartOptions, ScriptableScaleContext } from 'chart.js';
import { baseChartOptions } from 'components/charts/utils/base-chart-options';
import palette from 'components/charts/utils/chart-palette';
import { baseBarXScale } from '../../utils/base-bar-x-scale';

export const CAPACITY_ID = 'capacity';
export const REGISTRATIONS_ID = 'registrations';

/**
 * Get the chart options
 *
 * @param yAxisMax the max Y value
 * @param yAxisStepSize the step size
 * @returns ChartOptions
 */
export function chartOptions(yAxisMax: number, yAxisStepSize: number): ChartOptions {
  const baseConfig = baseChartOptions(yAxisMax, yAxisStepSize);
  const baseXScale = baseBarXScale();
  return {
    ...baseConfig,
    scales: {
      ...baseConfig.scales,
      [ CAPACITY_ID ]: {
        axis: 'x',
        display: false,
      },
      [ REGISTRATIONS_ID ]: {
        ...baseXScale,
        grid: {
          ...baseXScale.grid,
          color: (context: ScriptableScaleContext) => {
            // Mark the first hour in the day hour with a line
            return isFirstHourInDay(context) ? palette.grayD : '';
          },
        }
      },
    }
  };
}

/**
 * Determine if the given context is the first hour of the day
 *
 * @param context the data point context provided by ChartJS
 * @returns Boolean
 */
function isFirstHourInDay(context: ScriptableScaleContext): boolean {
  return Boolean(context?.tick?.label === '0:00');
}
