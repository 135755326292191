import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { HelpUrlService } from 'services/api/help-url.service';
import { AppConfigService } from '../app-config.service';
import { AuthErrorDialogService } from './auth-error-dialog.service';
import { AuthService } from './auth.service';
import { RefreshTokenService } from './refresh-token.service';

/**
 *  Intercept API calls and
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   * Flag to prevent duplicating refreshToken calls
   */
  private isRefreshingToken = false;

  /**
   * Observable that emits the fresh tokens after a refresh. In the case of overlapping requests, using a single
   * instance lets us complete multiple requests with the same refresh call.
   */
  private freshToken$ = new Subject<string>();

  public constructor(
    private authService: AuthService,
    private appConfigService: AppConfigService,
    private refreshTokenService: RefreshTokenService,
    private authErrorDialogService: AuthErrorDialogService,
  ) {}

  // Add auth token to request
  private static addToken(req: HttpRequest<unknown>, token: string): HttpRequest<unknown> {
    return req.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
  }

  /**
   * Intercept requests
   *
   * @param request the HttpRequest
   * @param next the next HttpHandler in the stack
   * @returns Observable<HttpEvent<unknown>> to pass down the interceptor chain
   */
  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.shouldAuthorize(request)) {
      // Refresh token if necessary, this process handles authorizing on its own
      if (this.shouldRefresh()) {
        return this.refresh(request, next);
      }

      return this.authorize(request, next);
    }

    // No work needed, just pass on the request!
    return next.handle(request);
  }

  /**
   * Add Authorization header and execute request.  If 401 returns, try refreshing token.
   */
  private authorize(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Add Auth header and Perform initial request with Authorization header added
    return next.handle(AuthInterceptor.addToken(request, this.authService.getAuthToken()))
      .pipe(
        catchError((error: HttpErrorResponse) => {
          // If unauthorized, attempt a refresh
          return error.status === 401 ? this.refresh(request, next) : throwError(error);
        })
      );
  }

  /**
   * Refresh the token, then execute the original request
   */
  private refresh(originalRequest: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      this.refreshTokenService.refresh().pipe(take(1)).subscribe((result) => {
        this.isRefreshingToken = false;

        // Update stored values
        this.authService.storeRefreshedTokens(result);

        // Emit the token
        this.freshToken$.next(result.encodedToken);
      }, () => {
        this.isRefreshingToken = false;
        this.authErrorDialogService.open();
      });
    }

    // The subject will emit the token once we are done refreshing
    return this.freshToken$.pipe(
      take(1),
      switchMap((freshToken: string) => {
        // Now we can add the token and make the original request
        return next.handle(AuthInterceptor.addToken(originalRequest, freshToken));
      })
    );
  }

  /**
   * Determine if we should refresh before issuing the current request.
   */
  private shouldRefresh(): boolean {
    return this.authService.authTokenIsStale();
  }

  /**
   * Determine if the current request needs Authorization headers.
   * Only microservices & help calls are intercepted.
   */
  private shouldAuthorize(request: HttpRequest<unknown>): boolean {
    return request.url.includes(this.appConfigService.getCapacityMicroserviceURL()) ||
      request.url.includes(this.appConfigService.getPoolsMicroserviceURL()) ||
      request.url.includes(HelpUrlService.GET_APPLICATIONS_PATH);
  }
}
