import { Pipe, PipeTransform } from '@angular/core';
import { take } from 'rxjs/operators';
import { TranslatePipe } from './translate.pipe';

/**
 *
 */
@Pipe({
  name: 'addUtcToTime'
})
export class AddUTCToTimePipe implements PipeTransform {
  private translatedUTC = '';

  public constructor(translatedPipe: TranslatePipe) {
    translatedPipe.transform('title.utc')
      .pipe(take(1))
      .subscribe((translation) => {
        this.translatedUTC = translation;
      });
  }

  /**
   * Adds translated (UTC) to the end of string
   *
   * @param time a time string, eg '13:45'
   * @returns formattedTime the time string  with ` (UTC)` appended
   */
  public transform(time: string): string {
    return `${time} (${this.translatedUTC})`;
  }
}
