<app-error-message *ngIf="status === 'error'" [heading]="displayableServerError?.title"
  [message]="displayableServerError?.message"></app-error-message>
<h1 mat-dialog-title>{{data.title | async}}</h1>
<div mat-dialog-content style="overflow: hidden;padding-top: 1%;">
  <form id="form" [formGroup]="changePoolNameForm" (ngSubmit)="validatePoolName()" autocomplete="off">
    <div>
      <vue-text-input label="{{ 'title.poolName' | translate | async }}" formControlName="poolName" [required]="true"
        [errorMatcher]="poolNameErrorMatcher"
        errorMessage="{{ poolNameErrorMessageKey() | translate | async }}"></vue-text-input>
    </div>
    <div mat-dialog-actions>
      <vue-basic-button (clicked)="closeDialog()">
        {{ "button.cancel" | translate | async }}
      </vue-basic-button>
      <vue-flat-button [disabled]="!formIsValid" type="submit" form="form" [loading]="isLoading">
        {{ "button.unarchive" | translate | async }}
      </vue-flat-button>
    </div>
  </form>
</div>