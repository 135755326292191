import { Directive, Injectable } from '@angular/core';
import { DateRange, MAT_DATE_RANGE_SELECTION_STRATEGY, MatDateRangeSelectionStrategy } from '@angular/material/datepicker';
import { Moment } from 'moment';

/**
 * Implement a custom selection strategy for datepickers so a full week is previewed on hover
 */
@Injectable()
export class WeekSelectionStrategy implements MatDateRangeSelectionStrategy<Moment> {
  public selectionFinished(date: Moment): DateRange<Moment> {
    // return the beginning and end of the week when a user selects a single date
    const start = date.clone().startOf('week');
    const end = date.clone().endOf('week');

    return new DateRange<Moment>(start, end);
  }

  public createPreview(activeDate: Moment | null): DateRange<Moment> {
    // return date range of the beginning & and end of week based on the active date
    if (activeDate) {
      const start = activeDate.clone().startOf('week');
      const end = activeDate.clone().endOf('week');

      return new DateRange<Moment>(start, end);
    }

    // when active date is falsy, return empty range
    return new DateRange<Moment>(null, null);
  }
}

/**
 * Provide `WeekSelectionStrategy` to a datepicker via a directive
 */
@Directive({
  selector: '[appDatepickerWeekRange]',
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: WeekSelectionStrategy
    }
  ]
})
export class DatepickerWeekRangeDirective {}
