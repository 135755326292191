import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MultiSelectOption } from '../filter-multi-select.component';

/**
 *  Options pane used by the FilterMultiSelect component
 */
@Component({
  selector: 'app-options-pane',
  templateUrl: './options-pane.component.html',
  styleUrls: [ './options-pane.component.scss' ]
})
export class OptionsPaneComponent implements OnInit {
  /**
   * Array of options to display in the pane
   */
  @Input() public options: MultiSelectOption[] = [];

  /**
   * Placeholder for search input when `includeOptionSearch` is true
   */
  @Input() public searchPlaceholder = '';

  /**
   * Toggles the visibility of the search input within the `OptionsPane`
   */
  @Input() public includeOptionSearch = true;

  /**
   * Emits array of selected options
   */
  @Output() public checkboxChange: EventEmitter<MultiSelectOption[]> = new EventEmitter<MultiSelectOption[]>();

  /**
   * Emits when the pane overlay should be closed & detached
   */
  @Output() public closePane: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Array of selected options when option pane is opened.
   *
   * Used to revert back to the original list when selections are canceled.
   */
  public checkedOptions: MultiSelectOption[] = [];

  /**
   * Working list of selected options
   */
  public pendingCheckedOptions: MultiSelectOption[] = [];

  /**
   * All options to be displayed
   */
  public displayedOptions: MultiSelectOption[] = [];

  // Set initial properties of options
  public ngOnInit(): void {
    this.displayedOptions = this.options;
    this.pendingCheckedOptions = this.options.filter((option) => option.checked);
   
  }

  /**
   * Add or remove option from `pendingCheckedOptions`
   */
  public updateChecked(event: MatCheckboxChange, id: string): void {
    if (!event.checked) {
      this.pendingCheckedOptions = this.pendingCheckedOptions.filter((item) => item.value !== id);
      return;
    }
    const newVal = this.options.find((item) => item.value === id);
    if (newVal) {
      this.pendingCheckedOptions.push(newVal);
    }
  }

  /**
   * Filter displayed options based on input value
   */
  public filterDisplayedOptions(inputValue: string): void {
    const lowerCaseInput = inputValue.toLowerCase();
    this.displayedOptions = [];
    this.options.forEach((option) => {
      const lowerCaseDisplay = option.display.toLowerCase();
      if (lowerCaseDisplay.includes(lowerCaseInput)) {
        this.displayedOptions.push(option);
      }
    });
  }

  /**
   * Revert `pendingCheckedOptions` to original checked options and close the pane.
   */
  public cancelFilters(): void {
    this.pendingCheckedOptions = this.checkedOptions;
    this.closePane.emit();
  }

  /**
   * Set checked options to the pending options, emit the change and close the pane.
   */
  public applyFilters(): void {
    this.checkedOptions = this.pendingCheckedOptions;
    this.checkboxChange.emit(this.checkedOptions);
    this.closePane.emit();
  }

  /**
   * Clear all selected filters
   */
  public clearFilters(): void {
    this.pendingCheckedOptions = [];
    this.displayedOptions = this.displayedOptions.map((option) => {
      return {
        ...option,
        checked: false,
      };
    });
  }
}
