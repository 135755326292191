<app-base-chart-popover>
  <div class="legend">
    <div class="legend-item" *ngFor="let item of legendItems">
      <app-legend-marker [color]="item.color" [type]="item.type"></app-legend-marker>
      {{item.label}}
    </div>
  </div>
  <div class="canvas-wrapper" [ngStyle]="canvasWrapperStyle">
    <canvas
      baseChart
      [height]="chartHeightPx"
      [datasets]="chartDatasets"
      [labels]="chartLabels"
      [options]="chartOptions"
      [attr.aria-label]="'label.aria.chartShowingAppointmentCapacity.selectedDate' | translate: screenReaderDate | async"
      role="img"
    ></canvas>
  </div>
  <app-custom-bottom-x-axis-labels [labels]="bottomXAxisLabels" [chartWidthRems]="chartWidthRems">
  </app-custom-bottom-x-axis-labels>
  <ng-container popover-action>
    <button (click)="goToWeeklyView()">
      {{ 'button.weeklyView' | translate | async }}
    </button>
  </ng-container>
</app-base-chart-popover>
