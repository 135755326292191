<div class="options-wrapper" cdkTrapFocus cdkTrapFocusAutoCapture role="dialog">
  <vue-filter-search
    class="search"
    placeholder="{{ 'label.placeholder.searchTemplates' | translate | async }}"
    (searchValueChange)="updateTemplateSearch($event)"
  ></vue-filter-search>
  <app-auto-complete-loader>
    <ul *ngIf="options && options.length > 0" class="options-list" #optionsList>
      <li
        *ngFor="let option of options; let i = index"
      >
        <div
          role="option"
          tabindex="0"
          class="option"
          [ngClass]="'option-index-' + i"
          [class.selected]="selectedValue === option.id"
          (click)="selectionUpdate(option)"
          (keyup.enter)="selectionUpdate(option)"
          (keyup.space)="selectionUpdate(option)"
          (keyup.arrowup)="changeFocus(i - 1, $event)"
          (keyup.arrowdown)="changeFocus(i + 1, $event)"
          [innerHTML]="option.name | boldMatchingText: searchTerm"
        ></div>
      </li>
    </ul>
    <div class="empty-state" *ngIf="options && options.length === 0">
      {{ "message.noResultsToDisplay" | translate | async }}
    </div>
  </app-auto-complete-loader>
</div>
