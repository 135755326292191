/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { Component, Inject, Input } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { VueFormFieldBaseComponent } from '../../utilities/vue-form-field-base/vue-form-field-base.component';

/**
 * VueUI implementation of a material select component
 */
@Component({
  selector: 'vue-select',
  templateUrl: './vue-select.component.html',
  styleUrls: [ './vue-select.component.scss' ],
  providers: [ {
    provide: NG_VALUE_ACCESSOR, useExisting: VueSelectComponent, multi: true
  } ]
})
export class VueSelectComponent<T> extends VueFormFieldBaseComponent {
  /**
   * Options to display for select
   */
  @Input() public options: T[] | null = [];

  /**
   *  How to display an individual option
   *
   *  @param option the raw option data
   *  @returns string the display string
   */
  @Input() public getOptionDisplay: (option: T) => string = (option) => String(option);

  /**
   * Test cases need injected container
   */
  public constructor(@Inject(ControlContainer) controlContainer: ControlContainer) {
    super(controlContainer);
  }
}
