<h1 mat-dialog-title>
  {{ 'title.archivePool' | translate | async }}
</h1>
<p mat-dialog-content>
  {{ "message.archivePool.confirmation" | translate:data.poolName | async }}
</p>
<div mat-dialog-actions>
  <vue-basic-button (clicked)="closeDialog()">
    {{ "button.cancel" | translate | async }}
  </vue-basic-button>
  <vue-flat-button (clicked)="archivePool()">
    {{ "button.archive" | translate | async }}
  </vue-flat-button>
</div>
