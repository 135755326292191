/* eslint-disable
  @typescript-eslint/no-use-before-define,
  @typescript-eslint/no-explicit-any,
  @typescript-eslint/explicit-module-boundary-types
*/
import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatTooltip } from '@angular/material/tooltip';
import { noop, Observable } from 'rxjs';
import { TranslatePipe, TranslationKey } from 'pipes/translate.pipe';

/**
 * Form controls for setting pool restriction options.
 */
@Component({
  selector: 'app-pool-restriction',
  templateUrl: './pool-restriction.component.html',
  styleUrls: [ './pool-restriction.component.scss' ],
  providers: [ {
    provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(
      /* istanbul ignore next */
      () => PoolRestrictionComponent
    ), multi: true
  } ]
})
export class PoolRestrictionComponent implements ControlValueAccessor {
  // Shows "occurrence" text rather than pool
  @Input() public isOccurrence = false;

  // Reference to the tooltip
  @ViewChild('tooltip') public tooltip?: MatTooltip;

  // Toggle shows "on" if pool is not restricted
  public isRestricted = true;

  // Form functions
  private onChangeCallback: (_: any) => void = noop;
  private onTouchedCallback = noop;

  public constructor(private translatePipe: TranslatePipe) {}

  // Gets the label to display
  public getLabel(): Observable<string> {
    const occurrenceKey = 'label.poolOccurrence.unrestrictOccurrence';
    const restrictedOccurrenceKey = 'label.poolOccurrence.restrictOccurrence';
    const poolKey = 'label.editPool.unrestrictPool';
    const restrictedPoolKey = 'label.editPool.restrictPool';
    let key: TranslationKey = this.isOccurrence ? occurrenceKey : poolKey;
    if (key === poolKey) {
      key = this.isRestricted ? restrictedPoolKey : poolKey;
    }
    if (key === occurrenceKey) {
      key = this.isRestricted ? restrictedOccurrenceKey : occurrenceKey;
    }

    return this.translatePipe.transform(key);
  }

  public getToolTip(): Observable<string> {
    const occurrenceKey = 'tooltop.message.pool.unrestrictedOccurrence';
    const restrictedOccurrenceKey = 'tooltip.message.pool.restrictedOccurrence';
    const poolKey = 'tooltip.message.unrestrict';
    const restrictedPoolKey = 'tooltip.message.restrict';
    let key: TranslationKey = this.isOccurrence ? occurrenceKey : poolKey;
    if (key === poolKey) {
      key = this.isRestricted ? restrictedPoolKey : poolKey;
    }
    if (key === occurrenceKey) {
      key = this.isRestricted ? restrictedOccurrenceKey : occurrenceKey;
    }

    return this.translatePipe.transform(key);
  }

  // Show the tooltip then the toggle is focused.  (Hiding happens automatically.)
  public focus(): void {
    this.tooltip?.show();
  }

  // Handle restriction change
  public restrictionChange(event: MatSlideToggleChange): void {
    this.isRestricted = event.checked;

    this.onTouchedCallback();
    this.onChangeCallback(this.isRestricted);
  }

  // Update value from parent
  public writeValue(restricted?: boolean): void {
    this.isRestricted = restricted ?? this.isRestricted;
  }

  // Update parent form value
  public registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  // Update parent form control has been touched
  public registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }
}
