import { Component, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { CreatePoolResponse } from 'api/types/endpoints/createPool';
import { Subject } from 'rxjs';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { CapacityMetric } from 'api/types';
import { RequiredDetailsForOccurrence } from 'components/tables/pool-drawer-occurrence-table/pool-drawer-occurrence-table.component';
import { EXCHANGE_FORMAT } from 'constants/date-formats';
import { DrawerStatusService } from 'services/status/drawer-status.service';
import { UnsavedChangesDialogService } from 'services/unsaved-changes-dialog.service';
import { DisplayableServerError } from 'types/DisplayableServerError';
import { RequestStatus } from 'types/RequestStatus';
import { getDisplayableServerError } from 'utils/get-displayable-server-error';
import { VueDrawerComponent, VueDrawerConfig } from 'vue/components/vue-drawer/vue-drawer.component';
import { AddPoolState, PoolStep } from './add-pool-state/add-pool-state.service';

/**
 *  Drawer for the add pools workflow.
 */
@Component({
  selector: 'app-add-pool-drawer',
  templateUrl: './add-pool-drawer.component.html',
  styleUrls: [ './add-pool-drawer.component.scss' ]
})
export class AddPoolDrawerComponent implements OnDestroy {
  /**
   * Event emitted after the success of a pool addition
   * emits pool response, which only contains the pool id
   */
  @Output() public success = new EventEmitter<CreatePoolResponse>();

  /**
   * Reference to the vue drawer component
   */
  @ViewChild('drawer') public drawer!: VueDrawerComponent;

  /**
   * Custom config passed to drawer component
   */
  public drawerConfig: VueDrawerConfig = {
    disableClose: true,
  }

  /**
   * Status of add-pool-form
   */
  public formIsValid = false;

  /**
   * Current step in drawer
   */
  public step: PoolStep = 'form'

  /**
   * Flag to show unsaved changes dialog
   */
  public unsavedChanges = false;

  /**
   * Loading status for the drawer
   */
  public status: RequestStatus = 'initial';

  /**
   * Error from form submission
   */
  private error?: DisplayableServerError | null;

  /**
   * Termination for subscriptions
   */
  private destroyed$ = new Subject();

  public pageRef = 'create';

  public constructor(
    private addPoolState: AddPoolState,
    private drawerStatusService: DrawerStatusService,
    private unsavedChangesDialogService: UnsavedChangesDialogService,
  ) {
    // Update form validity (used for disabling action buttons)
    this.addPoolState.addPoolForm.statusChanges
      .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
      .subscribe((status) => {
        this.formIsValid = status === 'VALID';
      });

    this.addPoolState.step$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((step) => {
        this.step = step;
      });

    // Update local status property
    this.drawerStatusService.status$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((status) => {
        this.status = status;
      });
  }

  /**
   * Terminate subscriptions and reset error/loading states
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Returns either error from pool state or local error
   */
  public get displayableServerError(): DisplayableServerError | null | undefined {
    return this.addPoolState.displayableServerError || this.error;
  }

  /**
   * Show unsaved changes dialog if there are any unsaved changes
   * Otherwise navigate back to the form
   */
  public showForm(): void {
    if (this.unsavedChanges) {
      this.openUnsavedChangesDialog();
    } else {
      // Clear any errors from step 2 before navigating back
      this.error = null;
      this.drawerStatusService.reset();
      this.addPoolState.showStep('form');
    }
  }

  /**
   * Close the drawer and reset the form state
   */
  public closeDrawer(): void {
    this.drawer.close();
    this.reset();
  }

  /**
   * Resets drawer to original state
   */
  public reset(): void {
    this.addPoolState.resetState();
    this.drawerStatusService.reset();
  }

  /**
   * Save pool!
   */
  public savePool(): void {
    this.drawerStatusService.loading();
    this.addPoolState.submitPool()
      .pipe(take(1))
      .subscribe((response) => {
        this.closeDrawer();
        this.error = null;
        this.drawerStatusService.success();
        this.success.emit(response);
      }, (error: unknown) => {
        this.error = getDisplayableServerError(error);
        this.drawerStatusService.error();
      });
  }

  /**
   * Updates flag to show unsaved changed dialog
   */
  public setUnsavedChangesFlag(): void {
    this.unsavedChanges = true;
  }

  /**
   * Opens unsaved changed dialog
   * Changes will be lost when user continues to form
   */
  public openUnsavedChangesDialog(): void {
    this.unsavedChangesDialogService.open().pipe(take(1)).subscribe((choseToLeave) => {
      if (choseToLeave) {
        this.error = null;
        this.unsavedChanges = false;
        this.showForm();
      }
    });
  }

  /**
   * Closes the drawer if there hasn't been any form changes, otherwise prompts user.
   */
  public closeDrawerIfUnchanged(): void {
    if (this.addPoolState.hasFormValueChanged()) {
      this.unsavedChangesDialogService.open().pipe(take(1)).subscribe((choseToLeave) => {
        if (choseToLeave) {
          this.closeDrawer();
        }
      });
    } else {
      this.closeDrawer();
    }
  }

  /**
   * Metrics to estimate registrations that are passed to occurrence table
   *
   * @returns metrics the current pool metrics
   */
  public get poolMetrics(): CapacityMetric[] {
    return this.addPoolState.poolMetrics;
  }

  /**
   * Total capacity to be shown for every occurrence in the occurrence table
   *
   * @returns totalCapacity the current totalCapacity
   */
  public get totalCapacity(): number {
    return this.addPoolState.totalCapacity;
  }

  /**
   * Generate pool details from add pool form for occurrence table
   *
   * @returns required details for occurrence table
   */
  public getPoolDetailsForOccurrenceTable(): RequiredDetailsForOccurrence {
    const {
      isRestricted,
      daysOfWeek,
      daysOfWeekWithRestriction,
      startDate,
      endDate,
      dateExceptions,
      dateAdditions,
      poolSchedule
    } = this.addPoolState.addPoolForm.value;
    return {
      isRestricted,
      daysOfWeek,
      daysOfWeekWithRestriction,
      dateExceptions: (dateExceptions || []),
      dateAdditions: (dateAdditions || []),
      startDate: startDate.format(EXCHANGE_FORMAT),
      endDate: endDate.format(EXCHANGE_FORMAT),
      schedule: poolSchedule,
      scheduleOverrides: []
    };
  }
}
