<div class="wrapper">
  <div class="header-wrapper">
    <img class="pearson-logo" src="/assets/images/pearson-logo.svg" alt="">
    <div class="header-text-wrapper">
      <p class="subheader">{{ "title.application" | translate | async }}</p>
      <p class="header">{{ "title.applicationName" | translate | async }}</p>
    </div>
  </div>
  <div class="profile-wrapper">
    <!-- aria-label is dynamic so a screen reader doesn't read out the link if it doesn't have an `href` -->
    <a
      class="help-link"
      [attr.href]="helpURL"
      [attr.aria-label]="!!helpURL ? ('label.aria.helpLink' | translate | async) : undefined"
      target="blank"
    >
      <img class="icon" src="/assets/icons/icon-question-mark.svg" alt="">
    </a>
    <button mat-button [matMenuTriggerFor]="profileMenu">
      <img class="icon" src="/assets/icons/icon-person.svg" alt="">
      <p>{{ userName }}</p>
    </button>
  </div>
</div>

<mat-menu #profileMenu="matMenu" class="profile-menu">
  <button mat-menu-item (click)="logout()">{{ "button.logout" | translate | async }}</button>
</mat-menu>
