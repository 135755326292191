import { utc } from 'moment';
import { Timezone } from 'types/Timezone';

/** Default to showing XX:XX so it is a very apparent to the user that something is incorrect */
const ERROR_FORMAT = 'XX:XX';

/**
 * Format a timestamp for display
 *
 * @param timestamp A timestamp string, eg '2021-03-28T06:00:00Z'
 * @param timezone An optional timezone to apply.  Uses UTC by default.
 * @returns display A formatted 24-hour time string, eg '18:45'
 */
export function formatTime(timestamp: string, timezone?: Timezone | null): string {
  try {
    const parts = new Intl.DateTimeFormat('en-US', {
      timeZone: timezone?.ianaCode ?? 'etc/utc',
      hour: 'numeric',
      minute: 'numeric',
    }).formatToParts(utc(timestamp).toDate());
    return formatDateTimeParts(parts);
  } catch {
    // Intl.DateTimeFormat can throw errors for invalid timezones if they're passed
    return ERROR_FORMAT;
  }
}

/**
 * Formats DateTimeFormatPart array into a 24 hour time
 */
function formatDateTimeParts(parts: Intl.DateTimeFormatPart[]): string {
  const hour = parts.find((p) => p.type === 'hour');
  const minutes = parts.find((p) => p.type === 'minute');
  const period = parts.find((p) => p.type === 'dayPeriod');

  // eslint-disable-next-line no-undefined
  if (hour !== undefined && minutes !== undefined && period !== undefined) {
    let formattedHour = hour.value;

    // Convert hours to 24 hour format
    if (period.value === 'PM' && formattedHour !== '12') {
      formattedHour = `${parseInt(formattedHour, 10) + 12}`;
    } else if (period.value === 'AM' && formattedHour === '12') {
      formattedHour = '0';
    }

    return `${formattedHour}:${minutes.value}`;
  }

  /*
   * This shouldn't happen, but by the nature of the `parts.find` there is a chance that
   * one of values isn't found, handling it just to be safe
   */
  return ERROR_FORMAT;
}

/**
 * Convert minutes offset in decimal format to standard number of minutes.
 * All values are strings.
 * Ex:
 * - timezone +1.25 -> 25
 * - timezone +4.5 -> 30
 * - timezone -2.75 -> 45
 *
 * @param minutesOffsetDecimal - decimal minute representation of offset, eg 25 / 7 / 75
 * @returns minutesOffset - number of minutes
 */
export function getMinutesOffset(minutesOffsetDecimal: string): string {
  switch (minutesOffsetDecimal) {
    case '25':
      return '15';
    case '5':
      return '30';
    case '75':
      return '45';
    default:
      return '00';
  }
}

/**
 * Formats a timezone offset.
 *
 * Offsets greater or equal to zero will be prefixed with `+`
 * Offsets less than zero will be prefixed with `-`
 */
export function getOffsetDisplay(offset: number): string {
  return offset >= 0 ? `+${offset}` : `${offset}`;
}
