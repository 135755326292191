import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { VUE_DIALOG_CONFIG } from 'vue/utilities/vue-dialog-config';

/**
 *  Component for removing an individual template
 */
@Component({
  selector: 'app-remove-template',
  templateUrl: './remove-template.component.html'
})
export class RemoveTemplateComponent {
  public constructor(
  // eslint-disable-next-line @typescript-eslint/no-parameter-properties
    @Inject(MAT_DIALOG_DATA) public data: RemoveTemplateInputs,
    private dialogRef: MatDialogRef<RemoveTemplateComponent>
  ) {}

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public removeTemplate(): void {
    this.data.removeTemplateCallback();
    this.closeDialog();
  }
}

export const removeTemplateConfig = {
  ...VUE_DIALOG_CONFIG,
};

export interface RemoveTemplateInputs {

  /**
   * Title shown on top of dialog
   */
  title: Observable<string>;

  /**
   * Callback used when remove is chosen by the user
   */
  removeTemplateCallback: () => void;
}
