<div class="datepicker-header">
  <div class="date-label">{{ getYearLabel() }}</div>
  <div class="year-selector" >
    <button
      mat-icon-button
      attr.aria-label="{{ 'label.aria.datepicker.previous.year' | translate | async }}"
      (click)="previousClicked()"
    >
      <mat-icon>
        <div class="arrow left"></div>
      </mat-icon>
    </button>
    <button
      mat-icon-button
      attr.aria-label="{{ 'label.aria.datepicker.next.year' | translate | async }}"
      (click)="nextClicked()"
    >
      <mat-icon>
        <div class="arrow right"></div>
      </mat-icon>
    </button>
  </div>
</div>
