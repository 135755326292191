import { CapacityMetricItem } from 'api/types';
import { FlaggedCapacityMetricItem } from 'types/FlaggedCapacityMetricItem';

// Ratio of registrations to capacity above this will be flagged
export const CRITICAL_RATIO_THRESHOLD = 0.85;

/**
 * Add critical flags to an array of CapacityMetricItems
 *
 * @param metricItems the items to decorate
 * @returns flaggedItems the flagged items
 */
export function addCriticalFlags(metricItems: CapacityMetricItem[]): FlaggedCapacityMetricItem[] {
  return metricItems.map((metricItem) => {
    const { registrations, capacity } = metricItem;
    return { ...metricItem, critical: isCritical(registrations, capacity || 0) };
  });
}

/**
 * Determine if a registration/capacity ratio is critical
 *
 * @param registrations the number of registrations
 * @param capacity the amount of open capacity
 * @returns isCritical whether the ratio is above the threshold
 */
function isCritical(registrations: number, capacity: number): boolean {
  return registrations / capacity > CRITICAL_RATIO_THRESHOLD;
}
