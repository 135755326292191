<form [formGroup]="addPoolconsumptionform">
  <!-- {{addPoolconsumptionform.value |json}} -->
  <vue-text-input
    label="{{ 'label.alerts' | translate | async }}"
    formControlName="getDatafromAlert"
    [required]="true"
    [errorMatcher]="poolNameErrorMatcher"
    errorMessage="{{ poolNameErrorMessageKey() | translate | async }}"
  ></vue-text-input>
  <app-add-pool-consumption-searchpool  formControlName="search_pool"></app-add-pool-consumption-searchpool>
  <app-add-pool-consumption-threshold formControlName="Threshold"></app-add-pool-consumption-threshold>
  <app-add-pool-consumption-runfrequency formControlName="run_Frequency"></app-add-pool-consumption-runfrequency>
</form>