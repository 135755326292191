<vue-table-container>
  <table mat-table [dataSource]="(dailyCapacityCalculation.hourlyCalculations || [])">
    <caption class="visually-hidden">
      {{ 'table.caption.hourlyAllocation' | translate | async }}
    </caption>

    <!-- Time column -->
    <ng-container matColumnDef="startTime">
      <mat-header-cell *matHeaderCellDef>
        {{ 'title.time.utc' | translate | async }}
      </mat-header-cell>
      <mat-cell *matCellDef="let hour">
        <time>{{ hour.startTime }}</time>
      </mat-cell>
    </ng-container>

    <!-- Capacity column -->
    <ng-container matColumnDef="capacity">
      <mat-header-cell *matHeaderCellDef>
        {{ 'title.capacity' | translate | async }}
      </mat-header-cell>
      <mat-cell *matCellDef="let hour">
        <span class="allocation-value">{{ hour.capacity | number }}</span>
      </mat-cell>
    </ng-container>

    <!-- Populate table with data -->
    <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: tableColumns"></mat-row>

     <!-- No data available -->
     <tr class="mat-row no-content" *matNoDataRow>
      <td class="mat-cell" colspan="9999">
        {{ 'message.allocations.noAllocations' | translate | async }}
      </td>
    </tr>
  </table>
</vue-table-container>
