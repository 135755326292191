import { Component, Input } from '@angular/core';

export type VueChipColor = 'green' | 'blue' | 'gray';

/**
 *  "Chip" that wraps a label in a pill-shaped container.
 */
@Component({
  selector: 'vue-chip',
  templateUrl: './vue-chip.component.html',
  styleUrls: [ './vue-chip.component.scss' ]
})
export class VueChipComponent {
  /**
   * Determines the color of the chip
   */
  @Input() public color: VueChipColor = 'green'
}
