import { Injectable } from '@angular/core';
import { utc } from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { PoolScheduleOverride } from 'api/types';
import { EXCHANGE_FORMAT } from 'constants/date-formats';
import { GetOccurrenceRestrictionResponse } from 'api/types/endpoints/getOccurenceRestriction';
import { PoolsMicroservice } from './api/microservices/pools.microservice';

/**
 * Handles state of occurrence edits made in the second step of the
 * add pool form or the edit pool form.
 */
@Injectable({
  providedIn: 'root'
})

export class EditPoolOccurrencesService {
  // Overrides that are made from editing an occurrence
  public scheduleOverrides$: BehaviorSubject<PoolScheduleOverride[]> = new BehaviorSubject<PoolScheduleOverride[]>([]);

  /**
   * Occurrences that are removed via the occurrence table
   * Will not show up on the add pool form but will be sent as a dateException
   * Format YYYY-MM-DD
   */
  public occurrenceRemovedDates$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  public applyPoolSettingsDates$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  public constructor(private api: PoolsMicroservice) { }
  
  /**
   * Adds date to running list of dates to be removed as exceptions
   *
   * @param date date to remove in EXCHANGE_FORMAT
   */
  public removeOccurrenceDate(date: string): void {
    const formattedDate = utc(date).format(EXCHANGE_FORMAT);
    const currentRemovalDates = this.occurrenceRemovedDates$.value;
    this.occurrenceRemovedDates$.next([ ...currentRemovalDates, formattedDate ]);
  }

  public applyPoolSettingsDates(date: string): void {
    const formattedDate = utc(date).format(EXCHANGE_FORMAT);
    const currentapplyPoolSettingDates = this.applyPoolSettingsDates$.value;
    this.applyPoolSettingsDates$.next([ ...currentapplyPoolSettingDates, formattedDate ]);
    this.removeOverride(date);
  }

  public removeApplyPoolSettingsDate(date: string): void {
    const currentapplyPoolSettingDates = this.applyPoolSettingsDates$.value;
    if (currentapplyPoolSettingDates.length) {
      const index = currentapplyPoolSettingDates.indexOf(date, 0);
      if (index > -1) {
        currentapplyPoolSettingDates.splice(index, 1);
      }
    }

    this.applyPoolSettingsDates$.next([...currentapplyPoolSettingDates]);
  }

  /**
   * Adds override to running list to be submitted
   *
   * @param newOverride override to be added
   */
  public addOverride(newOverride: PoolScheduleOverride): void {
    const overrideIndex = this.scheduleOverrides$.value.findIndex(
      (override) => override.date === newOverride.date
    );
    const newOverrides = [ ...this.scheduleOverrides$.value ];

    if (overrideIndex !== -1) {
      newOverrides[ overrideIndex ] = newOverride;
      this.scheduleOverrides$.next(newOverrides);
    } else {
      newOverrides.push(newOverride);
      this.scheduleOverrides$.next(newOverrides);
    }

    this.removeApplyPoolSettingsDate(newOverride.date);
  }

  /**
   * Releases an occurrence for desired date
   * Adds a schedule override if one does not exist
   *
   * @param date of released occurrence
   */
  public releaseOccurrence(date: string): void {
    const existingOverride = this.getOverride(date);

    if (existingOverride) {
      existingOverride.isReleased = true;
      this.addOverride(existingOverride);
    } else {
      const scheduleOverride: PoolScheduleOverride = {
        date: date,
        isReleased: true,
      };
      this.addOverride(scheduleOverride);
    }
  }

  /**
   * Marks an override as not released
   *
   * @param date date of occurrence
   */
  public unreleaseOccurrence(date: string): void {
    const existingOverride = this.getOverride(date);
    if (existingOverride) {
      existingOverride.isReleased = false;
      this.addOverride(existingOverride);
    } else {
      // Add new override
      this.addOverride({
        date: date,
        isReleased: false
      });
    }
  }

  /**
   * Returns existing override if defined
   *
   * @param date date of desired override
   * @returns Override if found, else null
   */
  public getOverride(date: string): PoolScheduleOverride | null {
    const overrideDate = utc(date, EXCHANGE_FORMAT);
    const overrideIndex = this.scheduleOverrides$.value.findIndex((scheduleOverride) =>
      utc(scheduleOverride.date, EXCHANGE_FORMAT).isSame(overrideDate, 'day')
    );
    return overrideIndex !== -1 ? { ...this.scheduleOverrides$.value[ overrideIndex ] } : null;
  }

  // If a user goes back to the add pool form, all occurrence changes should be tossed
  public clearPoolOccurrenceData(): void {
    this.scheduleOverrides$.next([]);
    this.occurrenceRemovedDates$.next([]);
    this.applyPoolSettingsDates$.next([]);
  }

  public getOccurrenceRestriction(poolId: string, occurrenceDate: string): Observable<GetOccurrenceRestrictionResponse> {
    const path = `pools/${poolId}/${occurrenceDate}`;
    return this.api.get<GetOccurrenceRestrictionResponse>(path);
  }

  public removeOverride(date: string): void {
    const overrideIndex = this.scheduleOverrides$.value.findIndex(
      (override) => override.date === date
    );
    const Overrides = [ ...this.scheduleOverrides$.value ];

    if (overrideIndex !== -1) {
      Overrides.splice(overrideIndex, 1);
      this.scheduleOverrides$.next(Overrides);
    } 
  }
}
