import { Component, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { VueFormFieldBaseComponent } from 'vue/utilities/vue-form-field-base/vue-form-field-base.component';

@Component({
  selector: 'vue-text-area',
  templateUrl: './vue-text-area.component.html',
  styleUrls: ['./vue-text-area.component.scss'],
  providers: [ {
    provide: NG_VALUE_ACCESSOR, useExisting: VueTextAreaComponent, multi: true
  } ]
})
export class VueTextAreaComponent extends VueFormFieldBaseComponent { }
