import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import VueTranslationJSON from '../data/translation.json';

export type VueTranslationKey = keyof typeof VueTranslationJSON;

/**
 * Pipe used to translate text for VUEUI components.
 *
 * Usage:
 * Default: `{{ 'translation.key' | vueTranslate | async }}` in template
 * Can be used through depending injection directly into classes
 */
@Pipe({
  name: 'vueTranslate'
})
export class VueTranslatePipe implements PipeTransform {
  /**
   * Gets the translation from local file based on input key.
   *
   * @param key the translation key.
   * @param replacementText optional text to placed within the translated text.
   * @returns translation
   */
  public transform(key: VueTranslationKey, replacementText?: string): Observable<string> {
    let translatedText = VueTranslationJSON[ key ] || '';

    if (replacementText) {
      translatedText = translatedText.replace('{0}', `${replacementText}`);
    }

    return of(translatedText);
  }
}
