<div class="innerclassthros" [formGroup]="addPoolConsumptionThreshld">
  <h4>{{ 'label.threshold & priority' | translate | async }}</h4>
  <div formArrayName="Threshold" *ngFor="let data of thresholdcontrolsGetter.controls; let i=index">
    <div [formGroupName]="i" style="display: flex;">
      <mat-form-field appearance="outline" id="percentage">
        <input matInput placeholder="%" formControlName="percentage">
        <span
          *ngIf="editData(i).controls['percentage'].errors &&(editData(i).controls['percentage'].dirty || editData(i).controls['percentage'].touched)"></span>
      </mat-form-field>
      <mat-form-field appearance="outline" id="input_Priority">
        <mat-label>{{'label.priority' | translate | async }}</mat-label>
        <mat-select formControlName="priority">
          <mat-option value="{{data}}" *ngFor="let data of option">{{data}}</mat-option>
        </mat-select>
        <span
          *ngIf="editData(i).controls['priority'].errors && (editData(i).controls['priority'].dirty ||  editData(i).controls['priority'].touched)"></span>
      </mat-form-field>
      <mat-form-field appearance="outline" id="input_Email">
        <input matInput placeholder="Email" formControlName="email" type="email">
        <span
          *ngIf="editData(i).controls['email'].errors && (editData(i).controls['email'].dirty ||  editData(i).controls['email'].touched )"></span>
      </mat-form-field>
      <span mat-button class="crossButton" (click)="removedatafromArrayList(i)">&cross;</span>
    </div>
    <div class="error" *ngIf=" editData(i).controls['percentage'].hasError('invalidValue')">
      <small>{{ 'messages.value' | translate | async }}</small>
    </div>
    <div class="error" *ngIf=" editData(i).controls['percentage'].errors?.pattern">
      <small>{{ 'messages.errorMessges' | translate | async }}</small>
    </div>
  </div>
</div>
<div class="Extrabox">
  <button (click)="UpdateValueinthreshold()" [disabled]="isformDirty()" mat-button color="primary"
    [matTooltip]="this.thresholdcontrolsGetter.value.length != maxnumber ? '': updatedtooltip "><span>+</span>&nbsp;{{'label.add_another_threshold_Priority'
    | translate | async }}</button>
</div>