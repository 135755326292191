import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { PoolInfo } from 'api/types';
import { isPoolEditable } from 'components/common/pools/utils/is-pool-editable';
import { EditPoolDrawerComponent } from 'components/drawers/edit-pool-drawer/edit-pool-drawer.component';

/**
 *  Open pools menu.  Allows viewing details and editing.
 */
@Component({
  selector: 'app-open-pools-menu',
  templateUrl: './open-pools-menu.component.html',
  styleUrls: [ './open-pools-menu.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class OpenPoolsMenuComponent {
  /**
   * Pool for respective row in occurrence-table
   */
  @Input() public pool!: PoolInfo;

  /**
   * Reference to editDrawer for pools menu.
   * The reference will be undefined if poolIsEditable evaluates to false
   */
  @ViewChild(EditPoolDrawerComponent) public editDrawer?: EditPoolDrawerComponent;

  /**
   * Show Edit pool menu item a pool that is not archived or complete
   *
   * @returns true if a pool does not have the status of archived or complete
   */
  public isPoolEditable = isPoolEditable;

  /**
   * Opens Edit Pool Drawer
   */
  public openEditDrawer(): void {
    this.editDrawer?.open();
  }
}
