import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IANA_CODE_DISPLAY_KEY } from 'constants/timezones';
import { Timezone } from 'types/Timezone';
import { getMinutesOffset, getOffsetDisplay } from 'utils/format-time';
import { TranslatePipe, TranslationKey } from './translate.pipe';

/**
 * A pipe to display timezones with standard formatting
 */
@Pipe({
  name: 'timezoneDisplay'
})
export class TimezoneDisplayPipe implements PipeTransform {
  public constructor(private translatePipe: TranslatePipe) {}

  /**
   * Transform a timezone into a displayable format
   */
  public transform(timezone: Timezone, type: 'full' | 'condensed' = 'full'): Observable<string> {
    return type === 'condensed' ? this.condensedDisplay(timezone) : this.fullDisplay(timezone);
  }

  /**
   * Returns the full translated text for the display of a timezone:
   *
   * Ex: `America/Chicago (UTC-5 CDT)`
   */
  private fullDisplay(timezone: Timezone): Observable<string> {
    const { abbrKey, ianaCodeKey } = this.timezoneTranslationKeys(timezone);

    // Load translated copy for the timezone and return the formatted result
    return this.translatePipe.loadTranslations([
      'title.utc',
      abbrKey,
      ianaCodeKey,
    ]).pipe(map((translations) => {
      const utc = translations[ 'title.utc' ];
      const abbr = translations[ abbrKey ];
      const iana = translations[ ianaCodeKey ];

      return `${iana} (${utc}${timezone.offset >= 0 ? '+' : ''}${timezone.offset} ${abbr})`;
    }));
  }

  /**
   * Returns a condensed display of a timezone that only includes an abbreviation and offset
   *
   * Ex: `EDT -4:00` or `AEST 10:00`
   */
  private condensedDisplay(timezone: Timezone): Observable<string> {
    const { abbrKey } = this.timezoneTranslationKeys(timezone);

    const offset = `${timezone.offset}`.split('.');
    let minutes = '00';

    if (offset.length > 1) {
      minutes = getMinutesOffset(offset[ 1 ]);
    }

    return this.translatePipe.transform(abbrKey).pipe(map((abbreviation) => {
      return `${abbreviation} ${offset[ 0 ]}:${minutes}`;
    }));
  }

  /**
   * Returns an object with the correct translation keys for a timezone.
   *
   * Abbreviation keys are calculated and stored based on the offset, see `src/app/types/Timezone.ts`
   */
  private timezoneTranslationKeys(timezone: Timezone): {abbrKey: TranslationKey, ianaCodeKey: TranslationKey} {
    const offset = getOffsetDisplay(timezone.offset);
    const abbrKey = timezone.displayTranslations[ `UTC${offset}-abbr` ];
    const ianaCodeKey = timezone.displayTranslations[ IANA_CODE_DISPLAY_KEY ];
    return {
      abbrKey,
      ianaCodeKey,
    };
  }
}
