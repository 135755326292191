import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PoolConsumptionInfo } from 'api/types/endpoints/getPoolConsumption';
import { UpdatePoolConStatusType } from 'api/types/endpoints/updatePoolConsumptionStatus';
import { ConfirmationModalComponent, ConfirmationModalInputs, confirmationModalConfig } from 'components/common/poolconsumption/confirmation-modal/confirmation-modal.component';
import { CopyModalComponent, CopyModalInputs, copyModalConfig } from 'components/common/poolconsumption/copy-modal/copy-modal.component';
import { isPoolConsumptionEnds } from 'components/common/pools/utils/is-poolconsumption-editable';
import { LoadingDialogComponent, LoadingDialogInput, loadingDialogConfig } from 'components/dialogs/loading-dialog/loading-dialog.component';
import { CopyPoolConsumptionDrawerComponent } from 'components/drawers/copy-pool-consumption-drawer/copy-pool-consumption-drawer.component';
import { FULL_MONTH_DAY_YEAR_FORMAT } from 'constants/date-formats';
import { utc } from 'moment';
import { TranslatePipe } from 'pipes/translate.pipe';
import { take } from 'rxjs/operators';
import { AllAlertDataService } from 'services/all-alert-data.service';
import { AlertsService } from 'services/api/alerts.service';
import { PoolConsumptionService } from 'services/api/poolconsumption.service';
import { getDisplayableServerError } from 'utils/get-displayable-server-error';
import { VueToastComponent } from 'vue/components/vue-toast/vue-toast.component';

type Type = 'activate' | 'deactivate' | 'delete';

@Component({
  selector: 'app-pool-consumption-menu',
  templateUrl: './pool-consumption-menu.component.html',
  styleUrls: ['./pool-consumption-menu.component.scss']
})
export class PoolConsumptionMenuComponent {
  copyAlertFormData: any
  hide: any = "hide"
  @Input() public poolconsumption!: PoolConsumptionInfo;

  @ViewChild(VueToastComponent) public toast!: VueToastComponent;
  @ViewChild(CopyPoolConsumptionDrawerComponent) public editDrawer!: CopyPoolConsumptionDrawerComponent

  /**
   * Emit event when a poolconsumption has been updated
   */
  @Output() public poolConsumptionUpdated = new EventEmitter<void>();
  queues: any
  showViewInToast = false;

  public constructor(
    private translatePipe: TranslatePipe,
    private dialog: MatDialog,
    private poolConsumptionService: PoolConsumptionService,
    private allPoolsDataService: AllAlertDataService,
    private alertsService: AlertsService
  ) {

  }

  public isPoolConsumptionEnds(): boolean {
    return isPoolConsumptionEnds(this.poolconsumption);
  }

  public ngOnInit(): void {
    this.allPoolsDataService.queues$.subscribe((queues) => {
      this.queues = queues

    });
  }

  public updateStatusPoolConsumption(type: Type): void {
    this.poolConsumptionService.showSaving();
    var status: UpdatePoolConStatusType = "active";

    if (type === "deactivate") {
      status = "inactive";
    }

    this.poolConsumptionService.updatePoolConStatus(this.poolconsumption.id, status)
      .pipe(take(1))
      .subscribe(() => {
        this.poolConsumptionService.hideSaving();
        this.poolConsumptionService.hideServerError();
        this.showToast(type);
        this.poolConsumptionUpdated.emit();
      }, (error: unknown) => {
        this.poolConsumptionService.hideSaving();
        this.poolConsumptionService.showServerError(getDisplayableServerError(error)?.message ?? '');
        this.setStatusRetrySubscription(type);
      });
  }

  /**
  * Subscribe to attemptRetry$ observable to retry updating template status
  * Update status poolconsumption page service with our subscription to cancel any others
  *
  * @param status status to retry update with
  */
  private setStatusRetrySubscription(type: Type): void {
    const retrySubscription = this.poolConsumptionService.attemptRetry$
      .pipe(take(1))
      .subscribe(() => {
        this.updateStatusPoolConsumption(type);
      });
    this.poolConsumptionService.setRetrySubscription(retrySubscription);
  }

  public deletePoolConsumption(type: Type): void {
    this.poolConsumptionService.showSaving();

    this.poolConsumptionService.deletePoolConById({ id: this.poolconsumption.id })
      .pipe(take(1))
      .subscribe(() => {
        this.poolConsumptionService.hideSaving();
        this.poolConsumptionService.hideServerError();
        this.showToast(type);
        this.poolConsumptionUpdated.emit();
      }, (error: unknown | null) => {
        this.poolConsumptionService.hideSaving();
        this.poolConsumptionService.showServerError(getDisplayableServerError(error)?.message ?? '');
      });
  }

  public showConfirmationModal(type: Type): void {
    this.dialog.open<ConfirmationModalComponent, ConfirmationModalInputs>(ConfirmationModalComponent, {
      ...confirmationModalConfig,
      data: {
        title: type === "deactivate" ? this.translatePipe.transform('poolconsumption.deactivate.dialog.heading', this.poolconsumption.alertName) : this.translatePipe.transform('poolconsumption.delete.dialog.heading', this.poolconsumption.alertName),
        message: type === "deactivate" ? this.translatePipe.transform('message.poolconsumption.deactivate.confirmation', this.poolconsumption.alertName) : this.translatePipe.transform('message.poolconsumption.delete.confirmation', this.poolconsumption.alertName),
        confirmationType: type,
        actionCallback: () => {
          if (type === "deactivate") {
            this.updateStatusPoolConsumption(type);
          }
          else {
            this.deletePoolConsumption(type);
          }
        }
      }
    });
  }

  public showCopyModal(): void {
    const loader = this.dialog.open<LoadingDialogComponent, LoadingDialogInput>(LoadingDialogComponent, {
      ...loadingDialogConfig,
      data: {
        title: 'title.loading',
        subtitle: 'subtitle.pleaseWait',
      },
    });

    this.poolConsumptionService.getPoolConsumptionById({ id: this.poolconsumption.id })
      .pipe(take(1))
      .subscribe((data: any) => {
        loader.close();

        let dialogRef = this.dialog.open<CopyModalComponent, CopyModalInputs>(CopyModalComponent, {
          ...copyModalConfig,
          data: {
            title: this.translatePipe.transform('message.poolconsumption.copy.modal', this.poolconsumption.alertName),
            poolName: data.poolDetails.poolName,
            formattedPoolStartDate: utc(data.poolDetails.poolStartDate).format(FULL_MONTH_DAY_YEAR_FORMAT),
            formattedPoolEndDate: utc(data.poolDetails.poolEndDate).format(FULL_MONTH_DAY_YEAR_FORMAT),
            poolStartTime: data.poolDetails.poolStartTime,
            poolEndTime: data.poolDetails.poolEndTime,
            threshold: data.threshold.items,
            runFrequency: data.runFrequency.items,
            status: data.poolDetails.poolStatus,
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.createDuplicateAlertConsumption(data, result);
          }
        });
      }, () => {
      });
  }

  private showToast(type: Type): void {
    this.alertsService.alertsSuccess.next({ configurationId: this.poolconsumption.id, configurationName: this.poolconsumption.alertName, type: type });
  }

  createDuplicateAlertConsumption(Data: any, Status: any) {
    this.copyAlertFormData = { 'Status': Status, "Data": Data, 'queue': this.queues }
    this.editDrawer.OpenDrawer()
  }
}
