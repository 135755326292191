import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoadingDialogComponent, loadingDialogConfig, LoadingDialogInput } from 'components/dialogs/loading-dialog/loading-dialog.component';
import { LOADING } from 'types/RequestStatus';
import { BaseStatus } from './status.class';

/**
 * Service to provide status when fetching pool details
 */
@Injectable({
  providedIn: 'root'
})
export class FetchingPoolStatusService extends BaseStatus implements OnDestroy {
  /**
   * Reference to loading dialog ref
   */
  private loadingDialogRef: MatDialogRef<LoadingDialogComponent> | null = null;

  /**
   * Terminates when service is destroyed
   */
  private destroyed$ = new Subject();

  public constructor(private dialog: MatDialog) {
    super();

    // Open or close loader based on status
    this.status$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((status) => {
        if (status === LOADING) {
          this.openLoader();
        } else {
          this.closeLoader();
        }
      });
  }

  /**
   * Complete destroyed subject
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Opens loading dialog
   */
  private openLoader(): void {
    this.loadingDialogRef = this.dialog.open<LoadingDialogComponent, LoadingDialogInput>(LoadingDialogComponent, {
      ...loadingDialogConfig,
      data: {
        subtitle: 'subtitle.pleaseWait',
      },
    });
  }

  /**
   * Closes loading dialog
   */
  private closeLoader(): void {
    this.loadingDialogRef?.close();
    this.loadingDialogRef = null;
  }
}
