import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GetPoolConsumptionParameters } from 'api/types/endpoints/getPoolConsumption';

export const DEFAULT_VALUES: GetPoolConsumptionParameters = {
  limit: 25,
  offset: 0,
  sortBy: 'alertname',
  direction: 'asc'
};

/**
 * Service that distributes filters (which results in GetClients params) for the pool consumption page.
 */
@Injectable({
  providedIn: 'root'
})
export class PoolConsumptionFiltersService {
  public constructor() {
  }
}
