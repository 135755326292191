<button
  [ngClass]="{'wrapper': true, 'week': isWeek, 'outside-month': isOutsideMonth }"
  (click)="openPopover()"
>
  <p [ngClass]="{'date': true, 'today': isTodaysDate}" aria-hidden="true">
    {{ titleDisplay }}
  </p>
  <p class="visually-hidden">
    {{ screenReaderLabel }}
  </p>
  <dl class="key-value-pairs">
    <div class="pair">
      <dt>{{ "title.utilization" | translate | async }}</dt>&nbsp;
      <dd><b>{{ utilization }}</b></dd>
    </div>

    <div class="pair">
      <dt>{{ "title.capacity" | translate | async }}</dt>&nbsp;
      <dd><b>{{ metricItem.capacity | number }}</b></dd>
    </div>

    <div class="pair">
      <dt>{{ "title.availability" | translate | async }}</dt>&nbsp;
      <dd><b>{{ (metricItem.capacity || 0) - metricItem.registrations | number }}</b></dd>
    </div>
  </dl>
</button>

<ng-template #dailyPopoverTemplate>
  <app-appt-monthly-chart-popover
    *ngIf="!isWeek"
    [date]="popoverDate"
  ></app-appt-monthly-chart-popover>

  <app-appt-weekly-summary-popover
    *ngIf="isWeek"
    [date]="popoverDate"
  ></app-appt-weekly-summary-popover>
</ng-template>
