/**
 * Utility for sorting arbitrary objects by a string property.
 *
 * Example usage: arr.sort(byString((item) => item.someString));
 *
 * @param getString a function for retrieving the string property from the object
 * @returns function a sorting function
 */
export function byString <T>(getString: (object: T) => string): (a: T, b: T) => number {
  return (objectA: T, objectB: T) => {
    return getString(objectA).localeCompare(getString(objectB));
  };
}

/**
 * Utility for sorting arbitrary objects by a number property.
 *
 * Example usage: arr.sort(byNumber((item) => item.someNumber));
 *
 * @param getNumber a function for retrieving the number property from the object
 * @returns function a sorting function
 */
export function byNumber <T>(getNumber: (object: T) => number): (a: T, b: T) => number {
  return (objectA: T, objectB: T) => {
    return getNumber(objectA) - getNumber(objectB);
  };
}

/**
 * Utility for sorting arbitrary objects by a date string property.
 *
 * Example usage: arr.sort(getDateString((item) => item.someDateString));
 *
 * @param getDateString a function for retrieving the date string property from the object
 * @returns function a sorting function
 */
export function byDateString <T>(getDateString: (object: T) => string): (a: T, b: T) => number {
  return (objectA: T, objectB: T) => {
    return new Date(getDateString(objectA)).getTime() - new Date(getDateString(objectB)).getTime();
  };
}
