<h1 mat-dialog-title>{{data.title | async}}</h1>
<p mat-dialog-content>
  {{ "message.removeTemplate.confirmation" | translate | async }}
</p>
<div mat-dialog-actions>
  <vue-basic-button (clicked)="closeDialog()">
    {{ "button.cancel" | translate | async }}
  </vue-basic-button>
  <vue-flat-button (clicked)="removeTemplate()">
    {{ "button.archive" | translate | async }}
  </vue-flat-button>
</div>
