/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-invalid-this */
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { CapacityTemplateDetails } from 'api/types';
import { LoadingDialogComponent, loadingDialogConfig, LoadingDialogInput } from 'components/dialogs/loading-dialog/loading-dialog.component';

/**
 * Template page service common logic across the template page
 */
@Injectable({
  providedIn: 'root'
})
export class TemplatePageService {
  private hasError = new BehaviorSubject<{ message: string, type: 'generic' | 'unsaved' | null }>({ message: '', type: null });

  /**
   * Observable that emits current error state
   */
  public showServerError$ = this.hasError.asObservable();

  /**
   * Observable that emits when a retry of a failed action should occur
   */
  public attemptRetry$ = new Subject();

  /**
   * Observable that emits when a template should be edited
   */
  public editTemplate$ = new Subject<CapacityTemplateDetails>();

  /**
   * Observable that emits when a template should be duplicated
   */
  public duplicateTemplate$ = new Subject<CapacityTemplateDetails>();

  /**
   * Keep track of subscriptions to attemptRetry$
   * There should only be one subscription at a time so only the most
   * recent retry should occur
   */
  private retrySubscription?: Subscription;

  public activeTemplate$ = new Subject<CapacityTemplateDetails>();

  public constructor(
    private dialog: MatDialog,
  ) {}

  /**
   * Show server error and hide saving dialog
   *
   * @param isGenericError optional boolean that will show generic error if true
   */
  public showServerError(errorMessage: string = '', isGenericError?: boolean): void {
    this.hideSaving();
    this.hasError.next({ message: errorMessage, type: isGenericError ? 'generic' : 'unsaved' });
  }

  /**
   * Hides server error
   */
  public hideServerError(): void {
    this.hasError.next({ message: '', type: null });
  }

  /**
   * Shows saving dialog
   */
  public showSaving(): void {
    this.openSavingDialog();
  }

  /**
   * Hides saving dialog
   */
  public hideSaving(): void {
    this.dialog.closeAll();
  }

  /**
   * Shows loading dialog
   */
  public showLoading(): void {
    this.openLoadingDialog();
  }

  /**
   * Hides loading dialog
   */
  public hideLoading(): void {
    this.dialog.closeAll();
  }

  /**
   * Emit attemptRetry$ observable
   */
  public triggerRetry(): void {
    this.attemptRetry$.next();
  }

  /**
   * Emit observable that a template should be edited
   *
   * @param template template to edit
   */
  public emitEditTemplate(template: CapacityTemplateDetails): void {
    this.editTemplate$.next(template);
  }

  /**
   * Emit observable that a template should be duplicated
   *
   * @param template template to edit
   */
  public emitDuplicateTemplate(template: CapacityTemplateDetails): void {
    this.duplicateTemplate$.next(template);
  }

  public emitActiveTemplate(template: CapacityTemplateDetails): void {
    this.activeTemplate$.next(template);
  }

  /**
   * Cancel any existing subscriptions to attemptRetry$
   * Then set the new one
   *
   * @param newSubscription incoming subscription
   */
  public setRetrySubscription(newSubscription: Subscription): void {
    if (this.retrySubscription) {
      this.retrySubscription.unsubscribe();
    }
    this.retrySubscription = newSubscription;
  }

  /**
   * Open Saving Dialog
   */
  private openSavingDialog(): void {
    this.dialog.open<LoadingDialogComponent, LoadingDialogInput>(
      LoadingDialogComponent, {
        ...loadingDialogConfig,
        data: {
          title: 'title.saving',
          subtitle: 'subtitle.pleaseWait',
        },
      });
  }

  /**
   * Open Loading Dialog
   */
  private openLoadingDialog(): void {
    this.dialog.open<LoadingDialogComponent, LoadingDialogInput>(
      LoadingDialogComponent, { ...loadingDialogConfig, data: {} });
  }
}
