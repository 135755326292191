<mat-form-field [appearance]="appearance">
    <mat-label>{{ label }}</mat-label>
    <textarea 
      matInput 
      type="text" 
      [formControl]="control"
      [placeholder]="placeholder"
      [required]="required"
      [errorStateMatcher]="errorMatcher"
    ></textarea>
    <mat-error *ngIf="errorMessage && invalid">
      {{errorMessage}}
    </mat-error>
  </mat-form-field>