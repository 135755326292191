import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { take } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import {
  TranslatePipe,
  TranslationLookup,
} from 'pipes/translate.pipe';
import { VueToastComponent } from 'vue/components/vue-toast/vue-toast.component';
import { AlertsService } from 'services/api/alerts.service';
import { DisplayableServerError } from 'types/DisplayableServerError';
import { RequestStatus } from 'types/RequestStatus';
import { PoolConsumptionPageDataService } from 'services/poolconsumption-page-data.service';
import { PoolConsumptionService } from 'services/api/poolconsumption.service';
import { Router } from '@angular/router';
import { AddAlertPoolDataService } from 'services/add-alert-pool-data.service';

type Type = 'activate' | 'deactivate' | 'delete';
/**
 *  Poolconsumption page
 */
@Component({
  selector: 'app-poolconsumption-page',
  templateUrl: './poolconsumption-page.component.html',
})
export class PoolConsumptionPageComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(VueToastComponent) public toast!: VueToastComponent;
  private destroyed$ = new Subject();
  private subscription!: Subscription;
  public toastMessage = '';
  private error?: DisplayableServerError | null;
  public status: RequestStatus = 'initial';
  configurationId: any;
  getviewConfigurationStatus: any = true;
  showViewInToast = false;  
  private translations: TranslationLookup = {};
  constructor(
    private alertsService: AlertsService,
    private translatePipe: TranslatePipe,
    private router: Router,
    private poolConsumptionService: PoolConsumptionService,
    private poolConsumptionPageDataService: PoolConsumptionPageDataService,
    private addAlertConsumptionCreate: AddAlertPoolDataService,
  ) { }

  ngOnInit(): void {
    this.subscription = this.addAlertConsumptionCreate.copyaddconsumptions$.subscribe((ele: any) => {
      if (ele != null) {
        if (ele?.key != null) {
          this.getaddPoolconsumption(ele);
        }
      }

    })
    this.translatePipe.loadTranslations(
      [
        'title.poolconsumption.activate',
        'title.poolconsumption.deactivate',
        'title.poolconsumption.delete',
        'message.poolconsumption.activate',
        'message.poolconsumption.deactivate',
        'message.poolconsumption.delete',
        'title.poolconsumption.create',
        'message.poolconsumption.create'
      ])
      .pipe(take(1))
      .subscribe((translations) => {
        this.translations = translations;
      });

    this.poolConsumptionPageDataService.fetchPoolConsumptionData();
  }

  ngAfterViewInit(): void {
    this.alertsService.alertsSuccess$
      .subscribe(({ configurationId,configurationName, type }) => {
        this.configurationId = configurationId;
        this.showToast(configurationName, type)
      });
  }

  public get displayableServerError(): DisplayableServerError | null | undefined {
    return this.error;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.subscription.unsubscribe();

  }

  private showToast(configurationName: string, type: Type): void {
    this.showViewInToast = false;
    if (!this.toast) {
      return;
    }

    if (type === 'activate') {
      this.showViewInToast = true;
      this.toast.heading = this.translations['title.poolconsumption.activate'];
      this.toastMessage = this.translations['message.poolconsumption.activate'].replace('{0}', configurationName);;
    } else if (type === 'deactivate') {
      this.showViewInToast = true;
      this.toast.heading = this.translations['title.poolconsumption.deactivate'];
      this.toastMessage = this.translations['message.poolconsumption.deactivate'].replace('{0}', configurationName);;
    } else {
      this.toast.heading = this.translations['title.poolconsumption.delete'];
      this.toastMessage = this.translations['message.poolconsumption.delete'].replace('{0}', configurationName);;
    }

    this.toast.open();
  }

  getaddPoolconsumption(event: any) {
    this.configurationId = event.id ? event.id : event.key
    this.poolConsumptionService.getPoolConsumptionById({ id: event.id ? event.id : event.key }).subscribe((ele: any) => {
      this.showViewInToast = true;
      this.toast.heading = this.translations['title.poolconsumption.create']
      this.toastMessage = this.translations['message.poolconsumption.create'].replace('{0}', ele.name);
      this.toast.open()
      this.poolConsumptionPageDataService.fetchPoolConsumptionData();
    })
  }

  public viewNewPool(): void {
    this.toast.close();
    this.router.navigate([`alerts/configurations/poolconsumption/${this.configurationId}`]);
  }
}
