import { Component, ElementRef, Input, OnChanges } from '@angular/core';
import { NgStyleExpression } from 'types/NgStyleExpression';

/**
 *  General purpose table container
 */
@Component({
  selector: 'vue-table-container',
  templateUrl: './vue-table-container.component.html',
  styleUrls: [ './vue-table-container.component.scss' ],
})
export class VueTableContainerComponent implements OnChanges {
  /**
   * Optionally trigger loading state
   */
  @Input() public loading = false;

  /**
   * Top and height style properties for the spinner container.
   * Calculated on change, based on measurements of the table body.
   */
  public spinnerContainerStyle: NgStyleExpression = {}

  /**
   * @param elementRef ElementRef
   */
  public constructor(private elementRef: ElementRef) {}

  /*
   * Position the spinner container precisely over the table body.
   * This keeps the spinner centered vertically, whether table headers or pagination are present or not.
   */
  public ngOnChanges(): void {
    const tbody = this.elementRef.nativeElement.querySelector('tbody');
    if (tbody) {
      this.spinnerContainerStyle = {
        'top.px': tbody.offsetTop,
        'height.px': tbody.offsetHeight,
      };
    }
  }
}
