import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { utc } from 'moment';
import { Observable, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { PoolDetails, PoolScheduleOverride } from 'api/types';
import { getBaseOccurrenceDetails, getRestrictionByDate } from 'components/common/pools/utils/get-base-occurrence-details';
import { getPoolStartEndTime } from 'components/common/pools/utils/get-pool-start-end-time';
import { ContainsRegistrationMetric, getRegistrationsForTimePeriod } from 'components/common/pools/utils/get-registrations-for-time-period';
import { isValidPoolDate } from 'components/common/pools/utils/is-valid-pool-date';
import { DISPLAY_DAY_FORMAT, EXCHANGE_FORMAT } from 'constants/date-formats';
import { TranslatePipe, TranslationKey } from 'pipes/translate.pipe';
import { EditPoolOccurrencesService } from 'services/edit-pool-occurrences.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { getOverride } from 'components/common/pools/utils/get-override';

interface Occurrence {
  date: string;
  startTime: string;
  endTime: string;
  registrations: number;
  capacity: number;
  isRestricted: boolean;
  isReleased?: boolean;
  toggleBackgroundFade?: boolean;
  isViewRestriction?: boolean;
  isLoading?: boolean;
  isOverride?: boolean;
}

export type RequiredDetailsForOccurrence = Pick<PoolDetails,
  'isRestricted' | 'dateAdditions' | 'dateExceptions' |
  'daysOfWeek' | 'daysOfWeekWithRestriction' | 'startDate' | 'endDate' | 'schedule' | 'scheduleOverrides'
>

/**
 *  Pool occurrence table
 */
@Component({
  selector: 'app-pool-drawer-occurrence-table',
  templateUrl: './pool-drawer-occurrence-table.component.html',
  styleUrls: [ './pool-drawer-occurrence-table.component.scss' ]
})
export class PoolDrawerOccurrenceTableComponent implements OnInit, OnDestroy {
  /**
   * Metrics for entire pool:
   * Either estimated by capacity-metrics within add-pool-drawer
   * Or specific registrations within edit-pool-drawer
   */
  @Input() public registrations!: ContainsRegistrationMetric[];

  /**
   * Pool details needed to paint the occurrence table
   */
  @Input() public poolDetails!: RequiredDetailsForOccurrence;
  
  @Input() public poolId: string | undefined = "";

  @Input() public initialpoolDetails!: RequiredDetailsForOccurrence;

  /**
   * Total capacity assigned in Add Pool Form
   * Edit Pool Form will show individual capacities
   */
  @Input() public totalCapacity?: number

  /**
   * Shows tooltip that registrations are only estimates and won't be applied
   */
  @Input() public displayRegistrationToolTip = false;

  @Input() public pageRef!:string;

  /**
   * Emit event so parent knows that a change has been made
   */
  @Output() public unsavedChangesEvent = new EventEmitter<void>();

  /**
   * Reference to the occurrence table
   */
  @ViewChild(MatTable) public table?: MatTable<Occurrence>;

  /**
   * Columns of the table
   */
  public tableColumns = [ 'date', 'time', 'registration', 'capacity', 'restriction', 'menu' ];

  /**
   * Array of occurrences.
   * Populated by `setInitialOccurrenceData()`
   */
  public occurrences: Occurrence[] = [];

  /**
   * Completed when component is destroyed.
   */
  private destroyed = new Subject();

  private scheduleOverrides : PoolScheduleOverride[] = [];
  private isUpdateTableView = true;

  public constructor(
    private editOccurrencesService: EditPoolOccurrencesService,
    private translatePipe: TranslatePipe
  ) {}

  public ngOnInit(): void {
    // Construct all occurrences based on the pool details
    this.setInitialOccurrenceData();

    // Alter occurrences based on updated overrides
    this.editOccurrencesService.scheduleOverrides$
      .pipe(takeUntil(this.destroyed))
      .subscribe((overrides) => {
         this.scheduleOverrides = overrides;
         this.updateOccurrenceWithOverrides(overrides);
         this.isUpdateTableView = true;
      });

    // Remove dates from an occurrence
    this.editOccurrencesService.occurrenceRemovedDates$
      .pipe(takeUntil(this.destroyed))
      .subscribe((removedDates) => {
        this.removeDatesFromOccurrences(removedDates);
      });

    this.editOccurrencesService.applyPoolSettingsDates$
      .pipe(takeUntil(this.destroyed))
      .subscribe((applyPoolSettingDates) => {
        this.applyPoolSettings(applyPoolSettingDates);
      });
  }

  /**
   * Complete all subscriptions & clear occurrence data
   */
  public ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
    this.editOccurrencesService.clearPoolOccurrenceData();
  }

  /**
   * @param occurrence Occurrence
   * @returns return zero if occurrence is released
   */
  public registrationDisplay(occurrence: Occurrence): number {
    return occurrence.isReleased ? 0 : occurrence.registrations;
  }

  /**
   * @param occurrence Occurrence
   * @returns Observable to capacity display for an occurrence
   */
  public capacityDisplay(occurrence: Occurrence): Observable<number | string> {
    return this.translatePipe.transform('label.released')
      .pipe(take(1))
      .pipe(map((released) => {
        return occurrence.isReleased ? released : occurrence.capacity;
      }));
  }

  /**
   * @param date date to localize in YYYY-MM-DD format
   * @returns localized date through moments locale
   */
  public displayLocalizedDate(date: string): string {
    return utc(date, EXCHANGE_FORMAT).format(DISPLAY_DAY_FORMAT);
  }

  /**
   * Only show the occurrence menu if the occurrence date is today or in the future
   *
   * @param occurrence current occurrence
   * @returns true if occurrence is today or in the future
   */
  public shouldShowOccurrenceMenu(occurrence: Occurrence): boolean {
    return utc(occurrence.date, EXCHANGE_FORMAT).isSameOrAfter(utc(), 'day');
  }

  /**
   * Returns an observable for translated tool tip text
   */
  public toolTipText(occurrence: Occurrence): Observable<string> {
    const key: TranslationKey = occurrence.isRestricted ?
      'tooltip.message.occurrence.restricted' :
      'tooltip.message.occurrence.unrestricted';

    return this.translatePipe.transform(key);
  }

  /**
   * Re-render the table
   */
  private updateTableView(): void {
    this.table?.renderRows();
  }

  /**
   * Update occurrence data with override data rather than the data from the add pool form
   */
  private updateOccurrenceWithOverrides(overrides: PoolScheduleOverride[]): void {
    for (const override of overrides) {
      const overrideIndex = this.occurrences.findIndex((occurrence) =>
        utc(occurrence.date, EXCHANGE_FORMAT).isSame(utc(override.date, EXCHANGE_FORMAT))
      );
      if (overrideIndex !== -1) {
        const occurrence = { ...this.occurrences[ overrideIndex ] };

        occurrence.isOverride = true;

        // If either `isReleased` or `isRestricted` is undefined use the occurrence value
        occurrence.isReleased = override.isReleased ?? occurrence.isReleased;
        occurrence.isRestricted = ((override.isRestricted != undefined) ? (override.isRestricted): (occurrence.isRestricted));

        // Set start and end time if schedule is available
        if (override.schedule) {
          const { poolStartTime, poolEndTime } = getPoolStartEndTime(override.schedule.items);
          occurrence.startTime = poolStartTime;
          occurrence.endTime = poolEndTime;
        }

        // Get registrations of schedule
        occurrence.registrations = this.getRegistrations(override.date, occurrence.startTime, occurrence.endTime);

        // Get capacity
        occurrence.capacity = this.getCapacity(override, occurrence);

        // If value of override has changed, toggle background fade
        const hasAnyValueChanged = this.occurrenceHasChanged(occurrence, overrideIndex);

        // Overwrite occurrence data with override data
        this.occurrences[ overrideIndex ] = {
          ...occurrence,
          toggleBackgroundFade: hasAnyValueChanged
        };

        if (hasAnyValueChanged) {
          this.unsavedChangesEvent.emit();
        }
      }
    }
    if (this.isUpdateTableView) {
      this.updateTableView();
    }
  }

  /**
   * Returns the registrations the override based on the pool start and end times.
   */
  private getRegistrations(date: string, startTime: string, endTime: string): number {
    const dateRegistrations = this.registrations.find((metric) =>
      metric.date === date
    );
    return getRegistrationsForTimePeriod(startTime, endTime, dateRegistrations);
  }

  /**
   * Retrieves a capacity from the override schedule when available, otherwise the occurrence.
   */
  private getCapacity(override: PoolScheduleOverride, occurrence: Occurrence): number {
    if (override.schedule) {
      return override.schedule.items.reduce((total, current) => {
        return total + current.capacity;
      }, 0);
    }
    return occurrence.capacity;
  }

  /**
   * Remove the dates from the occurrence list
   */
  private removeDatesFromOccurrences(dates: string[]): void {
    this.occurrences = this.occurrences.filter((occurrence) => {
      return !dates.includes(occurrence.date);
    });
    this.updateTableView();
  }

  private applyPoolSettings(dates: string[]): void {
    for (const date of dates) {
    const occurrence = this.occurrences.find((occurrence) =>
        utc(occurrence.date, EXCHANGE_FORMAT).isSame(utc(date, EXCHANGE_FORMAT))
      );
      if (occurrence) {
        const { poolStartTime, poolEndTime } = getPoolStartEndTime(this.poolDetails.schedule.items);
        const scheduledCapacity = this.poolDetails.schedule.items.reduce((acc, item) => {
          return acc + item.capacity;
        }, 0);

        occurrence.isReleased = false;
        occurrence.isRestricted = getRestrictionByDate(occurrence.date, this.poolDetails, this.poolDetails.daysOfWeek);
        occurrence.isOverride = false;
        occurrence.startTime = poolStartTime;
        occurrence.endTime = poolEndTime;
        occurrence.capacity = scheduledCapacity;
      } 
    }
    this.updateTableView();
  }

  /**
   * Check for any changed values between a new occurrence and the existing occurrence
   */
  private occurrenceHasChanged(newOccurrence: Occurrence, occurrenceIndex: number): boolean {
    return Boolean(
      newOccurrence.startTime !== this.occurrences[ occurrenceIndex ].startTime ||
      newOccurrence.endTime !== this.occurrences[ occurrenceIndex ].endTime ||
      newOccurrence.capacity !== this.occurrences[ occurrenceIndex ].capacity ||
      newOccurrence.isRestricted !== this.occurrences[ occurrenceIndex ].isRestricted ||
      newOccurrence.isReleased !== Boolean(this.occurrences[ occurrenceIndex ].isReleased)
    );
  }

  /**
   * Construct initial occurrence array based on the pool details
   */
  private setInitialOccurrenceData(): void {
    // if (this.initialpoolDetails) {
    //   if (this.initialpoolDetails.isRestricted != this.poolDetails.isRestricted) {
    //     if (this.poolDetails?.scheduleOverrides?.length) {
    //       this.poolDetails?.scheduleOverrides.forEach(item => {
    //         if (utc(item.date, EXCHANGE_FORMAT).isSameOrAfter(utc(), 'day')) {
    //           item.isRestricted = this.poolDetails.isRestricted;
    //         }
    //       });
    //     }
    //   }
    // }

    const { startDate, endDate } = this.poolDetails;

    // Clone startDate & endDate to make sure form data doesn't change
    const currentDate = utc(startDate).startOf('day');
    const end = utc(endDate).startOf('day');

    while (currentDate.isSameOrBefore(end)) {
      if (isValidPoolDate(this.poolDetails, currentDate)) {
        // Get base occurrence
        const baseOccurrence = getBaseOccurrenceDetails(currentDate.format(EXCHANGE_FORMAT), this.poolDetails);
        const { poolStartTime, poolEndTime } = getPoolStartEndTime(baseOccurrence.schedule.items);

        // Calculate capacity from pool schedule
        const scheduledCapacity = baseOccurrence.schedule.items.reduce((acc, item) => {
          return acc + item.capacity;
        }, 0);

        const registrations = this.getRegistrations(currentDate.format(EXCHANGE_FORMAT), poolStartTime, poolEndTime);

        this.occurrences.push({
          date: currentDate.format(EXCHANGE_FORMAT),
          startTime: poolStartTime,
          endTime: poolEndTime,
          capacity: typeof this.totalCapacity !== 'undefined' ? this.totalCapacity : scheduledCapacity,
          registrations,
          isRestricted: baseOccurrence.isRestricted,
          isReleased: baseOccurrence.isReleased,
          isViewRestriction: false,
          isOverride: baseOccurrence.isOverride
        });
      }
      currentDate.add(1, 'days');
    }
  }

  public checkboxChange(value: any, event: MatSlideToggleChange): void {
    let override: PoolScheduleOverride = {
      date: value.date
    };

    const currentOverride = this.scheduleOverrides?.find((override) => override.date === value.date);
    if (!currentOverride) {
      const existingOverride = getOverride(value.date, this.poolDetails);
      if (existingOverride) {
        override = existingOverride;
      }
    }
    else {
      override = currentOverride;
    }

    value.isRestricted = event.checked;
    value.isOverride = true;
    override.isRestricted = event.checked;
    this.isUpdateTableView = false;
    this.editOccurrencesService.addOverride(override);
  }

  public occurrenceDateHasPassed(poolDate: string) {
    return utc(poolDate, EXCHANGE_FORMAT).isBefore(utc(), 'day');
  }

  clickViewRestriction(occurrence: Occurrence) {
    var selectedPoolId = this.poolId;
    var occurrenceDate = occurrence?.date;
    occurrence.isLoading = true;
    if (selectedPoolId?.length && occurrenceDate) {
      this.editOccurrencesService.getOccurrenceRestriction(selectedPoolId, occurrenceDate).pipe(take(1)).subscribe((response) => {
        occurrence.isViewRestriction = true;
        occurrence.isRestricted = response.isRestricted;
        occurrence.isReleased = response.isReleased;
        occurrence.isLoading = false;
      }, () => {
        occurrence.isLoading = false;
      });
    }
  }

  public getOccurrenceRestrictionToolTip(): Observable<string> {
    const occurrenceKey = 'tooltop.message.occurrence.viewoccurrencerestriction';
    let key: TranslationKey = occurrenceKey
    return this.translatePipe.transform(key);
  }
}
