<vue-table-container [loading]="loading">
  <table mat-table [dataSource]="tableData">
    <caption class="visually-hidden">
      {{ 'table.caption.templates' | translate | async }}
    </caption>

    <!-- Template Name -->
    <ng-container matColumnDef="name">
      <th
        mat-header-cell
        *matHeaderCellDef
        [tableHeaderAriaSort]="{sortDirection, isSorted: sortBy === 'name' }"
      >
        <app-sortable-table-header
          [tableHasData]="!!tableData.length"
          (sortColumn)="sort('name')"
        >
          {{ 'title.template' | translate | async }}
        </app-sortable-table-header>
      </th>
      <mat-cell *matCellDef="let template">
        {{template.name}}
        <vue-chip
          *ngIf="template.status === 'archived'"
          color="gray"
        >
          {{ 'label.archived' | translate | async }}
        </vue-chip>
      </mat-cell>
    </ng-container>

    <!-- Date Created -->
    <ng-container matColumnDef="created-at">
      <th
        mat-header-cell
        *matHeaderCellDef
        [tableHeaderAriaSort]="{sortDirection, isSorted: sortBy === 'created-at' }"
      >
        <app-sortable-table-header
          [tableHasData]="!!tableData.length"
          (sortColumn)="sort('created-at')"
        >
          {{ 'title.dateCreated' | translate | async }}
        </app-sortable-table-header>
      </th>
      <mat-cell *matCellDef="let template">
        {{formatCreatedAtDate(template.createdAt)}}
      </mat-cell>
    </ng-container>

    <!-- Created By -->
    <ng-container matColumnDef="created-by">
      <th
        mat-header-cell
        *matHeaderCellDef
        [tableHeaderAriaSort]="{sortDirection, isSorted: sortBy === 'created-by' }"
      >
        <app-sortable-table-header
          [tableHasData]="!!tableData.length"
          (sortColumn)="sort('created-by')"
        >
          {{ 'title.createdBy' | translate | async }}
        </app-sortable-table-header>
      </th>
      <mat-cell *matCellDef="let template;">
        {{template.createdBy}}
      </mat-cell>
    </ng-container>

    <!-- Action Menu -->
    <ng-container matColumnDef="menu">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'table.heading.actions' | translate | async }}
      </th>
      <mat-cell *matCellDef="let template; let i = index;">
        <app-template-menu
          [template]="template"
          (templateStatusUpdated)="templateStatusUpdated()"
        ></app-template-menu>
      </mat-cell>
    </ng-container>

    <!-- Populate table with data -->
    <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
    <mat-row
      *matRowDef="let template; columns: tableColumns; let i = index"
      [appBackgroundFade]='i === highlightedRow'
    ></mat-row>

    <!-- No data available -->
    <tr class="mat-row no-content" *matNoDataRow>
      <td class="mat-cell" colspan="9999">
        {{ 'message.template.noTemplates' | translate | async }}
      </td>
    </tr>
  </table>

  <mat-paginator
    #paginator
    *ngIf="tableData.length !== 0"
    previousPageIndex="0"
    (page)="paginatorChange($event)"
    [length]="totalItemCount"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    [showFirstLastButtons]="showFirstLastButtons"
  ></mat-paginator>
</vue-table-container>
