/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { SortDirection } from '@angular/material/sort';
import { utc } from 'moment';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GetPoolsParameters, GetPoolsSortBy, PoolInfo } from 'api/types';
import { DISPLAY_DAY_FORMAT, EXCHANGE_FORMAT } from 'constants/date-formats';
import { PoolClientDisplayPipe } from 'pipes/pool-client-display.pipe';
import { TranslatePipe } from 'pipes/translate.pipe';
import { DEFAULT_VALUES, PoolsPageService } from 'services/pools-page.service';
import { PaginatorBase } from '../utils/paginator-base.class';

/**
 * Open pools table
 */
@Component({
  selector: 'app-open-pools-table',
  templateUrl: './open-pools-table.component.html',
  styleUrls: [ './open-pools-table.component.scss' ],
  providers: [ PoolClientDisplayPipe ]
})
export class OpenPoolsTableComponent extends PaginatorBase implements OnInit, OnDestroy {
  /**
   * Table properties
   */
  public tableData: PoolInfo[] = [];
  public sortBy: GetPoolsSortBy = DEFAULT_VALUES.sortBy;
  public sortDirection: SortDirection = DEFAULT_VALUES.direction;
  public tableColumns = [ 'name', 'client', 'exams', 'startDate', 'endDate', 'restriction', 'menu' ];

  /**
   * Table loading state
   */
  public loading = false;

  /**
   * Observable that completes when subscriptions should terminate
   */
  private destroyed$ = new Subject();

  public constructor(
    private poolsPageService: PoolsPageService,
    private translatePipe: TranslatePipe,
  ) {
    super(DEFAULT_VALUES);
  }

  /**
   * Subscribe to openPools, the params, and the fetchingPools$ flag
   */
  public ngOnInit(): void {
    this.poolsPageService.openPoolsResponse$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((openPoolsResponse) => {
        const { items, limit, offset, total } = openPoolsResponse;

        this.generateTableData(items);
        this.updatePaginator({ limit, offset, total });
      });

    this.poolsPageService.openPoolsTableParams$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((params) => {
        const { sortBy, direction } = params;

        this.sortBy = sortBy;
        this.sortDirection = direction;
      });

    this.poolsPageService.fetchingMetrics$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isFetching) => {
        this.loading = isFetching;
      });
  }

  /**
   * Terminate all subscriptions
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Update pools service with new params
   *
   * @param col column to sort
   */
  public sort(col: GetPoolsSortBy): void {
    const direction = this.sortDirection === 'asc' ? 'desc' : 'asc';
    const newParams: Partial<GetPoolsParameters> = {
      sortBy: col,
      direction: direction
    };
    this.poolsPageService.updatePoolParams(newParams);
  }

  /**
   * Gets the alt text to display for the restriction column
   *
   * @param isRestricted A flag indicating whether or not the pool is restricted.
   * @returns The alt text to display.
   */
  public getRestrictionAltText(isRestricted: boolean): Observable<string> {
    return isRestricted ?
      this.translatePipe.transform('message.pool.restricted') :
      this.translatePipe.transform('message.pool.unrestricted');
  }

  /**
   * @param date pool date in YYYY-MM-DD format
   * @returns date in localized display format
   */
  public formatPoolDate(date: string | null): string {
    return date ? utc(date, EXCHANGE_FORMAT).format(DISPLAY_DAY_FORMAT) : '';
  }

  /*
   * Update filter service with new offsets
   *
   * @param event event from paginator
   */
  public paginatorChange(event: PageEvent): void {
    const newParams: Partial<GetPoolsParameters> = {
      offset: event.pageSize * event.pageIndex,
      limit: event.pageSize
    };
    this.poolsPageService.updatePoolParams(newParams);
  }

  /**
   * Structures table data format from new pool data
   *
   * @param newPools Array of open pools
   */
  private generateTableData(newPools: PoolInfo[]): void {
    this.tableData = newPools.map((pool) => {
      return {
        ...pool,
      };
    });
  }
}
