import { ChartDataset } from 'chart.js';
import { TranslationLookup } from 'pipes/translate.pipe';
import { FlaggedCapacityMetricItem } from 'types/FlaggedCapacityMetricItem';
import { NumericKeys } from 'types/numeric-keys';
import { barDataset } from '../../charts/utils/bar-dataset';
import palette from '../../charts/utils/chart-palette';
import { lineDataset } from '../../charts/utils/line-dataset';

export const REGISTRATIONS_ID = 'registrations';
export const CAPACITY_ID = 'capacity';

/**
 * Select values for a given property from metric items
 *
 * @param metricItems the items to pluck values from
 * @param property the property to pluck
 * @returns values an array of the plucked values
 */
function pluckData(
  metricItems: FlaggedCapacityMetricItem[],
  property: NumericKeys<FlaggedCapacityMetricItem>
):
  number[] {
  return metricItems.map((item) => {
    return item[ property ];
  });
}

/**
 * Get chart datasets from the given metricItems
 *
 * @param metricItems the items to base the datasets on
 * @param translations a localized string translation lookup
 * @returns datasets an array of chart datasets
 */
export function getChartDatasets(
  metricItems: FlaggedCapacityMetricItem[],
  translations: TranslationLookup): ChartDataset[] {
  const barColors = metricItems.map((item) => {
    return item.critical ? palette.red : palette.blue;
  });

  // Retain "null" capacity values – these will be displayed as gaps in the chart
  const capacityData = metricItems.map((i) => i.capacity);
  const capacityDataset = lineDataset(translations[ 'title.capacity' ], palette.midNight, capacityData, CAPACITY_ID);

  // Use finer lines
  capacityDataset.borderWidth = 2;
  capacityDataset.pointRadius = 0;

  const registrationsData = pluckData(metricItems, 'registrations');
  const registrationsDataset = barDataset(
    translations[ 'title.registrations' ], REGISTRATIONS_ID, barColors, registrationsData, 24
  );
  return [ capacityDataset, registrationsDataset ];
}
