import { Pipe, PipeTransform } from '@angular/core';
import { EXCHANGE_FORMAT, FULL_DAY_FORMAT } from 'constants/date-formats';
import { utc } from 'moment';

@Pipe({
  name: 'dateToDay',
})
export class DateToDayPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    const englishDate = utc(value, EXCHANGE_FORMAT).clone().locale('en');
    return englishDate.format(FULL_DAY_FORMAT);
  }
}
