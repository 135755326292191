import { BarElement, Chart, ChartEvent, LineElement } from 'chart.js';

// The type signature chartClicked returns, which is very loose
export interface UndifferentiatedChartEvent {
  event?: ChartEvent;
  // eslint-disable-next-line @typescript-eslint/ban-types
  active?: {}[];
}

// A more useful type signature that we can cast to using isChartClickEvent
export interface ChartClickEvent {
  event: ChartEvent & { chart: Chart };

  /**
   * Array of a single data point from each dataset from the chart
   */
  active: {

    /**
     * Index of the individual event relative to the full array in a single dataset.
     * Note: All objects in the active array should have the same index
     */
    index: number;

    /** Index of the dataset relative to array of data passed to the chart */
    datasetIndex: number;

    /** Bar and Line charts are the only utilized chart types */
    element: BarElement | LineElement;
  }[];
}

/**
 * Type guard to convert UndifferentiatedChartEvents to ChartClickEvents
 *
 * @param event a generic chart event
 * @returns typedEvent the event as a ChartClickEvent (or false if not convertible)
 */
export function isChartClickEvent(event: UndifferentiatedChartEvent): event is ChartClickEvent {
  return 'chart' in (event as ChartClickEvent).event;
}
