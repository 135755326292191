<vue-page-header
  appName="{{ 'title.applicationName' | translate | async }}"
  pageTitle="{{ 'title.recentActivity' | translate | async }}"
  navSection="{{ 'title.pools' | translate | async }}"
>
  <app-filter-container>
    <app-pools-recent-activity-filters></app-pools-recent-activity-filters>
  </app-filter-container>
</vue-page-header>
<app-page-body>
  <vue-section>
    <app-pools-recent-activity-table></app-pools-recent-activity-table>
  </vue-section>
</app-page-body>
