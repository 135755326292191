import { Component, Input } from '@angular/core';
import { COLUMN_WIDTH_REMS } from '../utils/constants';

/**
 *  Display a custom X axis above the chart, in a style that matches our table headers.
 */
@Component({
  selector: 'app-custom-x-axis',
  templateUrl: './custom-x-axis.component.html',
  styleUrls: [ './custom-x-axis.component.scss' ]
})
export class CustomXAxisComponent {
  /**
   * Labels to display
   */
  @Input() public labels: string[] = [];

  /**
   * Secondary labels to display, optionally
   */
  @Input() public secondaryLabels: string[] = [];

  /**
   * The width data-points should occupy, in rems
   */
  @Input() public columnWidthRems = COLUMN_WIDTH_REMS;
}
