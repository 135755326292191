import { Component, OnDestroy } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { SortDirection } from '@angular/material/sort';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Queue } from 'api/types';
import { GetPoolConsumptionParameters, GetPoolConsumptionSortBy } from 'api/types/endpoints/getPoolConsumption';;
import { DEFAULT_VALUES } from 'services/poolconsumption-filters.service';
import { PaginatorBase } from '../utils/paginator-base.class';
import { QueuesService } from 'services/api/queues.service';
import { PoolConsumptionInfo } from 'api/types/endpoints/getPoolConsumption';
import { PoolConsumptionPageDataService } from 'services/poolconsumption-page-data.service';
import { formatQueueDisplay } from 'utils/format-queue';
import { utc } from 'moment';
import { DISPLAY_DAY_FORMAT, EXCHANGE_FORMAT } from 'constants/date-formats';

/**
 * Pool Consumption Table
 */
@Component({
  selector: 'app-poolconsumption-table',
  templateUrl: './poolconsumption-table.component.html',
  styleUrls: ['./poolconsumption-table.component.scss']
})
export class PoolConsumptionTableComponent extends PaginatorBase implements OnDestroy {
  /**
   * Table properties
   */
  public poolConsumptionData: PoolConsumptionInfo[] = [];
  public tableColumns = ['alertName', 'poolName', 'queue', 'createdBy', 'createdOn', 'modifiedBy', 'modifiedOn', 'menu'];
  public sortBy: GetPoolConsumptionSortBy = DEFAULT_VALUES.sortBy;
  public sortDirection: SortDirection = DEFAULT_VALUES.direction;

  /**
   * Display loading state for table
   */
  public tableLoading = false;

  /**
   * translations
   */
  public translations: { [key: string]: string } = {};

  /**
   * Subject that completes when the component is destroyed
   */
  private destroyed$ = new Subject();

  public queues: Queue[] = [];

  public constructor(
    private poolConsumptionPageDataService: PoolConsumptionPageDataService,
    private queuesService: QueuesService
  ) {
    super(DEFAULT_VALUES);

    // Update table when data service updates
    this.poolConsumptionPageDataService.poolConsumptionResponse$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        const { items, sortBy, direction, limit, offset, total } = res;
        this.poolConsumptionData = items;
        this.sortBy = sortBy;
        this.sortDirection = direction;

        this.updatePaginator({ limit, offset, total });
      });

    // Show the table loading when pool consumption are being refreshed
    this.poolConsumptionPageDataService.fetchingPoolConsumption$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((fetchingPoolConsumption) => {
        this.tableLoading = fetchingPoolConsumption;
      });

    this.queuesService.getQueues().pipe(take(1)).subscribe((queueResponse) => {
      this.queues = queueResponse;
    });
  }

  public getQueueDisplay(alerts: PoolConsumptionInfo): string {
    const queue = this.queues.find((q) => q.id === alerts.queueId);
    return formatQueueDisplay(queue);
  }

  /**
   * Terminate all subscriptions.
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Update the sort column
   *
   * @param col the column to sort on
   */
  public sort(col: GetPoolConsumptionSortBy): void {
    const direction = this.sortDirection === 'asc' ? 'desc' : 'asc';
    const newParams: Partial<GetPoolConsumptionParameters> = {
      sortBy: col,
      direction: direction
    };
    this.poolConsumptionPageDataService.updateParams(newParams);
  }

  /**
   * Generate table date from templates response
   *
   * @param event event from paginator
   */
  public paginatorChange(event: PageEvent): void {
    const newParams: Partial<GetPoolConsumptionParameters> = {
      offset: event.pageSize * event.pageIndex,
      limit: event.pageSize
    };
    this.poolConsumptionPageDataService.updateParams(newParams);
  }

  /**
   * Refresh data when a poolconsumption has been activate, deactivate or delete
   */
  public poolConsumptionUpdated(): void {
    this.poolConsumptionPageDataService.fetchPoolConsumptionData();
  }

  public formatDate(date: string): string {
    if (date?.length) {
      const splitDate = date.split('T');
      const splitTime = splitDate[1];
      const shortTime = splitTime?.slice(0, 5);

      return date ? utc(date, EXCHANGE_FORMAT).format(DISPLAY_DAY_FORMAT) + " at " + shortTime : '';
    }
    else {
      return "";
    }
  }
}
