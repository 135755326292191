import { ChartDataset, Color } from 'chart.js';

/**
 * Create a line dataset for use in a chart
 *
 * @param label the axis label to use
 * @param color the primary color for display
 * @param data the dataPoints to display
 * @param xAxisID the X axis ID
 * @returns dataset a line dataset
 */
export function lineDataset(
  label: string,
  color: Color,
  data: ChartDataset['data'],
  xAxisID?: string): ChartDataset<'line'> {
  return {
    type: 'line',
    label: label,
    data: data,
    pointRadius: 1,
    borderColor: color,
    backgroundColor: color,
    pointBackgroundColor: color,
    pointBorderColor: color,
    pointHoverBackgroundColor: 'white',
    pointHoverBorderColor: color,
    pointHoverRadius: 3,
    pointHoverBorderWidth: 2,
    pointHitRadius: 25,
    xAxisID,
  };
}
