<vue-table-container [loading]="tableLoading">
  <table mat-table [dataSource]="outageData">
    <caption class="visually-hidden">
      {{
      'table.caption.allClients' | translate | async
      }}
    </caption>

    <!-- Outage Name -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>

      <mat-cell *matCellDef="let element"
        [matTooltip]="element.name">
        <span class="ellipsis">
          {{ element?.name }}</span>
      </mat-cell>
    </ng-container>

    <!-- outage startday-->
    <ng-container matColumnDef="startday">
      <th mat-header-cell *matHeaderCellDef>Start Day</th>
      <mat-cell *matCellDef="let element">{{
        transformDateTime(element?.startdate,'date') | dateToDay
        }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="startdate">
      <th mat-header-cell *matHeaderCellDef>Start Date</th>
      <mat-cell *matCellDef="let element">{{ formatDate(transformDateTime(element?.startdate,'date')) }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="starttime">
      <th mat-header-cell *matHeaderCellDef>Start Time</th>
      <mat-cell *matCellDef="let element">{{ transformDateTime(element?.startdate,'time')}} {{ timezoneLabel | async
        }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="endday">
      <th mat-header-cell *matHeaderCellDef>End Day</th>
      <mat-cell *matCellDef="let element">{{
        transformDateTime(element?.enddate,'date') | dateToDay
        }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="enddate">
      <th mat-header-cell *matHeaderCellDef>End Date</th>
      <mat-cell *matCellDef="let element">{{ formatDate(transformDateTime(element?.enddate,'date')) }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="endtime">
      <th mat-header-cell *matHeaderCellDef>End Time</th>
      <mat-cell *matCellDef="let element">{{transformDateTime(element?.enddate ,'time') }} {{ timezoneLabel | async
        }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="createdby">
      <th mat-header-cell *matHeaderCellDef>created By</th>
      <mat-cell *matCellDef="let element">{{ element?.createdby }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <mat-cell *matCellDef="let element">
        <app-outage-menu [outage]="element" [timezone]="timezone"></app-outage-menu>
      </mat-cell>
    </ng-container>

    <!-- Populate table with data -->
    <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: tableColumns; let i = index"></mat-row>

    <!-- No data available -->
    <tr class="mat-row no-content" *matNoDataRow>
      <td class="mat-cell" colspan="9999">
        {{ 'message.outage.noOutage' | translate | async }}
      </td>
    </tr>
  </table>

  <mat-paginator *ngIf="outageData.length !== 0" (page)="paginatorChange($event)" previousPageIndex="0"
    [length]="totalItemCount" [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
    [showFirstLastButtons]="showFirstLastButtons"></mat-paginator>
</vue-table-container>