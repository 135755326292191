<div class="exam-filter">
  <vue-filter-search
    class="search"
    placeholder="{{ 'label.placeholder.search' | translate | async }}"
    (searchValueChange)="updateExamSearch($event)"
  ></vue-filter-search>
  <p *ngIf="numberOfFiltersClients !== 0" class="selected-clients">
    <span
      *ngIf="numberOfFiltersClients === 1"
      [innerHTML]="'message.filteredClients.singular' | translate:numberOfFiltersClients | async"
    ></span>
    <span
      *ngIf="numberOfFiltersClients > 1"
      [innerHTML]="'message.filteredClients.plural' | translate:numberOfFiltersClients | async"
    ></span>
  </p>
  <app-auto-complete-loader>
    <div class="checkboxes">
      <mat-checkbox
        *ngFor="let exam of examsToDisplay"
        class="option"
        tabIndex="0"
        (change)="updateSelected($event, exam.id)"
        [checked]="isExamSelected(exam.id)"
      >
        {{exam.name}}
      </mat-checkbox>
      <p *ngIf="examsToDisplay.length === 0" class="no-results">
        <ng-container *ngIf="numberOfFiltersClients === 0">
          {{ "message.clientMustBeSelected" | translate | async }}
        </ng-container>

        <ng-container *ngIf="numberOfFiltersClients > 0">
          {{ "message.noResultsToDisplay" | translate | async }}
        </ng-container>
      </p>
    </div>
  </app-auto-complete-loader>
</div>
